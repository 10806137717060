export const defaultValues = {
  pageDetailsSection: {
    title: '',
    description: '',
  },
  heroSection: {
    title: '',
    titleColor: '#ffffff',
    content: '',
    contentColor: '#ffffff',
    imageUrl: '',
    buttonText: '',
    buttonUrl: '',
    buttonColor: '#ffffff',
    buttonTextColor: '#ffffff',
    linkTextColor: '#ffffff',
    linkText: '',
    linkUrl: '',
    linkColor: '#ffffff',
    backgroundColor: '#ffffff',
  },
  productsSection: {
    title: '',
    content: '',
    backgroundColor: '#ffffff',
    items: [
      {
        title: '',
        content: '',
        imageUrl: '',
        linkText: '',
        linkUrl: '',
        linkColor: '#ffffff',
      },
    ],
  },
  getStartedSection: {
    title: '',
    words: [
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: '',
      },
      {
        text: '',
      },
    ],
    imageUrl: '',
    buttonText: '',
    buttonUrl: '',
    buttonColor: '#ffffff',
  },
  statisticsSection: {
    title: '',
    content: '',
    backgroundColor: '#ffffff',
    items: [
      {
        quantity: 0,
        title: '',
        isRating: false,
        imageUrl: '',
      },
    ],
  },
  guaranteeSection: {
    title: '',
    imageUrl: '',
    backgroundColor: '#ffffff',
    content: '',
    buttonText: '',
    buttonUrl: '',
  },
  productCategoriesSection: {
    title: '',
    subtitle: '',
    content: '',
    imagePosition: 'right',
    imageUrl: '',
    backgroundColor: '#ffffff',
    categories: {
      header: '',
      items: [
        {
          name: '',
          description: '',
          color: '#ffffff',
        },
      ],
    },
    buttonText: '',
    buttonUrl: '',
  },
  appsUrlsSection: {
    title: '',
    imageUrl: '',
    content: '',
    playStoreUrl: '',
    appStoreUrl: '',
  },
  academySection: {
    title: '',
    subtitle: '',
    content: '',
    imagePosition: 'left',
    imageUrl: '',
    buttonText: '',
    buttonUrl: '',
    buttonColor: '#ffffff',
    backgroundColor: '#ffffff',
  },
};
