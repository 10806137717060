import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Toolbar,
} from '@material-ui/core';

import NewSubscriptionHistory from './newSubscriptionHistory/newSubscriptionHistory';
import RenewedSubscriptionHistory from './renewedSubscriptionHistory/renewedSubscriptionHistory';
import CanceledSubscriptionHistory from './canceledSubscriptionHistory/canceledSubscriptionHistory';
import './subscriptionHistory.scss';

function SubscriptionHistory({ reports }) {
  const [totalNewSubscriptionCount, setTotalNewSubscriptionCount] = useState(0);
  const [totalCanceledSubscriptionCount, setTotalCanceledSubscriptionCount] = useState(0);
  const [totalRenewedSubscriptionCount, setTotalRenewedSubscriptionCount] = useState(0);

  useEffect(() => {
    let newSubscriptionCount = 0;
    let canceledSubscriptionCount = 0;
    let renewalCount = 0;

    reports.forEach(r => {
      newSubscriptionCount += r.newSubscriptionCount;
      canceledSubscriptionCount += r.canceledSubscriptionCount;
      renewalCount += r.renewalCount;
    });

    setTotalNewSubscriptionCount(newSubscriptionCount);
    setTotalCanceledSubscriptionCount(canceledSubscriptionCount);
    setTotalRenewedSubscriptionCount(renewalCount);
  }, [reports]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="SubscriptionHistory">
      <div className="sub-title-container">
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="subtitle1">
            Subscriptions
          </Typography>
        </Toolbar>
      </div>

      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                New Subscriptions
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {totalNewSubscriptionCount}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Canceled Subscriptions
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {totalCanceledSubscriptionCount}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Renewed Subscriptions
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {totalRenewedSubscriptionCount}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <NewSubscriptionHistory reports={reports} />
      <RenewedSubscriptionHistory reports={reports} />
      <CanceledSubscriptionHistory reports={reports} />
    </div>
  );
}

export default SubscriptionHistory;
