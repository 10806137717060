import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { ColorPicker, useColor, toColor } from '../../../shared/colorPalette';
import '../../../shared/colorPalette/css/styles.css';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Popover,
  Divider,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import './editHeaderBanner.scss';

const priorityAmounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function EditHeaderBanner({
  closeModal,
  save,
  bannerData,
}) {
  const [banner, setBanner] = useState({
    name: 'New Banner',
    fontColor: '#FAF7E8',
    backgroundColor: '#294634',
    items: [],
    showOnAllPages: true,
    animationDuration: 100,
    duplicates: 3,
    pageUrlConditions: [],
    priority: 1,
    live: false,
  });
  const [backgroundColor, setBackgroundColor] = useColor('hex', '#294634');
  const [fontColor, setFontColor] = useColor('hex', '#FAF7E8');
  const [backgroundColorAnchorElem, setBackgroundColorAnchorElem] = useState(null);
  const [fontColorAnchorElem, setFontColorAnchorElem] = useState(null);

  useEffect(() => {
    setBanner(cloneDeep(bannerData));
    setBackgroundColor(toColor('hex', bannerData.backgroundColor));
    setFontColor(toColor('hex', bannerData.fontColor));
  }, [bannerData]);

  const addItem = () => {
    const items = [
      ...banner.items,
      {
        text: '',
        link: '',
      },
    ];

    setBanner({ ...banner, items });
  };

  const addPageCondition = () => {
    const pageUrlConditions = [
      ...banner.pageUrlConditions,
      {
        condition: 'contains',
        text: '',
      },
    ];

    setBanner({ ...banner, pageUrlConditions });
  };

  const renderTextContainers = () => {
    const textContainers = [];

    for (let i = 0; i <= banner.duplicates; i++) {
      textContainers.push(
        <span className="marquee-text-container" key={`text-container-${i}`}>
          {banner.items.map((item, j) => {
            return (
              <span className="marquee-text" key={`text-item-${i}-${j}`}>
                <a href={item.link} target="_blank">{item.text}</a>
              </span>
            );
          })}
        </span>
      );
    }

    return textContainers;
  };

  const backgroundColorOpen = Boolean(backgroundColorAnchorElem);
  const backgroundColorId = backgroundColorOpen ? 'background-color-popover' : undefined;
  const fontColorOpen = Boolean(fontColorAnchorElem);
  const fontColorId = fontColorOpen ? 'font-color-popover' : undefined;

  return (
    <div className="EditHeaderBanner">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Banner
          </Typography>
          <Button color="inherit" onClick={() => save(banner)}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div
        className="banner-preview"
        style={{
          backgroundColor: banner.backgroundColor || 'gray',
          color: banner.fontColor || '#000',
        }}
      >
        <div className="banner-scroll">
          <div className="banner-scroll-title">
            <div style={{animationDuration: `${banner.animationDuration}s`}}>
              {renderTextContainers()}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={banner.live}
              onChange={(e) => setBanner({ ...banner, live: e.target.checked })}
              name="live"
              color="primary"
            />
          }
          label="Live"
        />

        <TextField
          label="Name (for internal use)"
          style={{ marginTop: 20 }}
          value={banner.name}
          onChange={(e) => setBanner({ ...banner, name: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Priority"
          style={{ marginTop: 20 }}
          margin="dense"
          value={banner.priority || priorityAmounts[priorityAmounts.length - 1]}
          onChange={(e) => setBanner({ ...banner, priority: e.target.value })}
          SelectProps={{ renderValue: value => value }}
          fullWidth
          variant="outlined"
          select
        >
          {priorityAmounts.map(amount => {
            return <MenuItem key={`priority-amount-${amount}`} value={amount}>{amount}</MenuItem>;
          })}
        </TextField>

        <div className="banner-settings-container">
          <div className="banner-setting-container">
            <label>Animation Duration</label>
            <p>{banner.animationDuration}s</p>

            <div className="banner-setting-buttons-container">
              <div
                className="banner-setting-button"
                onClick={() => {
                  let animationDuration = banner.animationDuration - 5;

                  if (animationDuration < 0) {
                    animationDuration = 0;
                  }

                  setBanner({ ...banner, animationDuration: animationDuration });
                }}
              >-</div>
              <div
                className="banner-setting-button increase"
                onClick={() => setBanner({ ...banner, animationDuration: banner.animationDuration + 5 })}
              >+</div>
            </div>
          </div>

          <div className="banner-setting-container">
            <label>Repeat</label>
            <p>{banner.duplicates}</p>

            <div className="banner-setting-buttons-container">
              <div
                className="banner-setting-button"
                onClick={() => {
                  let duplicates = banner.duplicates - 1;

                  if (duplicates < 0) {
                    duplicates = 0;
                  }

                  setBanner({ ...banner, duplicates: duplicates });
                }}
              >-</div>
              <div
                className="banner-setting-button increase"
                onClick={() => setBanner({ ...banner, duplicates: banner.duplicates + 1 })}
              >+</div>
            </div>
          </div>

          <div
            className="color-container"
            onClick={(e) => setBackgroundColorAnchorElem(e.currentTarget)}
          >
            <p>Background Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.backgroundColor }}
            ></div>
          </div>

          <Popover
            id={backgroundColorId}
            open={backgroundColorOpen}
            anchorEl={backgroundColorAnchorElem}
            onClose={() => setBackgroundColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={backgroundColor}
              onChange={setBackgroundColor}
              onChangeComplete={(color) => setBanner({ ...banner, backgroundColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>

          <div
            className="color-container last"
            onClick={(e) => setFontColorAnchorElem(e.currentTarget)}
          >
            <p>Text Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.fontColor }}
            ></div>
          </div>

          <Popover
            id={fontColorId}
            open={fontColorOpen}
            anchorEl={fontColorAnchorElem}
            onClose={() => setFontColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={fontColor}
              onChange={setFontColor}
              onChangeComplete={(color) => setBanner({ ...banner, fontColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>
        </div>

        <div className="section-container">
          <div className="section-label">Items</div>

          <div className="right-container">
            <Button variant="contained" onClick={addItem} color="primary" size="small">
              Add Item
            </Button>
          </div>

          {banner.items.map((item, i) => {
            return (
              <div key={`banner-item-${i}`}>
                {i === 0 ? null :
                  <div className="banner-item-divider">
                    <Divider/>
                  </div>
                }

                <div className="banner-item-row">
                  <div className="banner-item-inputs-container">
                    <TextField
                      label={`Item ${i + 1}`}
                      value={item.text}
                      onChange={(e) => {
                        const items = [ ...banner.items ];

                        items[i] = {
                          ...items[i],
                          text: e.target.value,
                        };

                        setBanner({
                          ...banner,
                          items,
                        });
                      }}
                      margin="dense"
                      variant="outlined"
                      type="text"
                      className="day-text-field"
                    />

                    <TextField
                      label={`Item ${i + 1} Link`}
                      value={item.link}
                      onChange={(e) => {
                        const items = [ ...banner.items ];

                        items[i] = {
                          ...items[i],
                          link: e.target.value,
                        };

                        setBanner({
                          ...banner,
                          items,
                        });
                      }}
                      margin="dense"
                      variant="outlined"
                      type="text"
                      className="day-text-field"
                    />
                  </div>

                  <div className="delete-banner-item-container">
                    <IconButton onClick={() => {
                      const items = [ ...banner.items ];

                      items.splice(i, 1);

                      setBanner({
                        ...banner,
                        items,
                      });
                    }} edge="start" color="inherit" aria-label="Edit">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="section-container">
          <div className="section-label">Pages</div>

          <div className="pages-header-container">
            <FormControlLabel
              control={
                <Switch
                  checked={banner.showOnAllPages}
                  onChange={(e) => setBanner({ ...banner, showOnAllPages: e.target.checked })}
                  name="show-on-all-pages"
                  color="primary"
                />
              }
              label="Show On All Pages"
            />

            {banner.showOnAllPages ? null :
              <div>
                <Button variant="contained" onClick={addPageCondition} color="primary" size="small">
                  Add Condition
                </Button>
              </div>
            }
          </div>

          {banner.showOnAllPages ? null :
            <>
              {banner.pageUrlConditions.map((item, i) => {
                return (
                  <div key={`banner-item-${i}`}>
                    {i === 0 ? null :
                      <div className="banner-item-divider">
                        OR
                      </div>
                    }

                    <div className="banner-item-row">
                      <div className="banner-item-inputs-container">
                        <TextField
                          label="Condition"
                          margin="dense"
                          value={item.condition}
                          onChange={(e) => {
                            const pageUrlConditions = [ ...banner.pageUrlConditions ];

                            pageUrlConditions[i] = {
                              ...pageUrlConditions[i],
                              condition: e.target.value,
                            };

                            setBanner({
                              ...banner,
                              pageUrlConditions,
                            });
                          }}
                          SelectProps={{ renderValue: value => <span style={{textTransform: 'capitalize'}}>{value}</span> }}
                          fullWidth
                          variant="outlined"
                          select
                        >
                          <MenuItem value="contains">Contains</MenuItem>
                          <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                      </div>

                      <div className="banner-item-inputs-container">
                        <TextField
                          label={`URL text`}
                          value={item.text}
                          onChange={(e) => {
                            const pageUrlConditions = [ ...banner.pageUrlConditions ];

                            pageUrlConditions[i] = {
                              ...pageUrlConditions[i],
                              text: e.target.value,
                            };

                            setBanner({
                              ...banner,
                              pageUrlConditions,
                            });
                          }}
                          margin="dense"
                          variant="outlined"
                          type="text"
                          className="day-text-field"
                        />
                      </div>

                      <div className="delete-banner-item-container">
                        <IconButton onClick={() => {
                          const pageUrlConditions = [ ...banner.pageUrlConditions ];

                          pageUrlConditions.splice(i, 1);

                          setBanner({
                            ...banner,
                            pageUrlConditions,
                          });
                        }} edge="start" color="inherit" aria-label="Edit">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default EditHeaderBanner
