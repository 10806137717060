import React, { useState } from 'react';

import DangerouslySetHtmlContent from '../shared/DangerouslySetHtmlContent';

const styles = {
  dropdownIcon: {
    fontSize: 25,
    lineHeight: 0,
    alignSelf: 'center',
    marginTop: -5,
  },
};

function Accordion({ content, customStyles }) {
  const [expandedState, setExpandedState] = useState(content.items.map(i => {
    return false;
  }));

  return (
    <>
      <div style={{ ...content.containerStyles, ...customStyles }} className={content.customClasses} id={content.htmlID}>
        {content.items.map((item, i) => {
          return (
            <div key={`accordion-item-${i}`} style={content.itemStyles}>
              <div
                style={{...content.headerStyles, borderRadius: content.itemStyles.borderRadius || 0}}
                onClick={() => {
                  const expandedStateCopy = [ ...expandedState ];
                  expandedStateCopy[i] = !expandedStateCopy[i];
                  setExpandedState(expandedStateCopy);
                }}
              >
                <div style={{ marginRight: 10, alignSelf: 'center' }}>{item.title}</div>
                <div style={{...styles.dropdownIcon, color: content.headerStyles.color || '#333'}}>
                  {expandedState[i] ? '-' : '+'}
                </div>
              </div>
              {!expandedState[i] ? null :
                <div style={{...content.contentStyles, borderBottomLeftRadius: content.itemStyles.borderRadius || 0, borderBottomRightRadius: content.itemStyles.borderRadius || 0}}>
                  <DangerouslySetHtmlContent html={item.content} />
                </div>
              }
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Accordion;
