import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './appWelcomeMessages.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AppWelcomeMessages() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [messages, setMessages] = useState([]);
  const [editMessage, setEditMessage] = useState({
    text: '',
    active: false,
  });
  const [editMessageIndex, setEditMessageIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const fetchWelcomeMessages = async () => {
      try {
        const messagesSnapshot = await firebase.firestore().collection('config').doc('app-welcome-messages').get();
        const messagesData = messagesSnapshot.data();

        setMessages(messagesData.list);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error retrieving the welcome messages, please try again.');
      }
    };

    fetchWelcomeMessages();
  }, []);

  const saveMessage = async () => {
    try {
      const list = [ ...messages ];
      list[editMessageIndex] = editMessage;

      await firebase.firestore().collection('config').doc('app-welcome-messages').update({
        list,
      });

      setMessages(list);
      setEditMessageIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this welcome message, please try again.');
    }
  };

  const deleteMessage = async () => {
    setLoading(true);

    try {
      const list = [ ...messages ];
      list.splice(deleteIndex, 1);

      await firebase.firestore().collection('config').doc('app-welcome-messages').update({
        list,
      });

      setMessages(list);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this welcome message, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="AppWelcomeMessages">
      {renderLoading()}
      <Card style={{width: '100%'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            App Welcome Messages
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMessage({
                  text: '',
                  active: false,
                });
                setEditMessageIndex(messages.length);
              }}
            >
              Add New
            </Button>
          </div>
        </Toolbar>

        <List>
          {messages.map((message, i) => {
            return (
              <div key={`message-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={message.text}
                    secondary={
                      <span><strong>Status:</strong> {message.active ? 'Active' : 'Disabled'}</span>
                    }
                  />

                  <IconButton
                    onClick={() => {
                      setEditMessage({ ...message });
                      setEditMessageIndex(i);
                    }}
                    edge="start"
                    color="inherit"
                    aria-label="Edit"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setDeleteIndex(i);
                    }}
                    color="inherit"
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={editMessageIndex !== -1} onClose={() => setEditMessageIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>{editMessageIndex === messages.length ? 'Add' : 'Edit'} Message</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={editMessage.active}
                onChange={(e) => {
                  setEditMessage({
                    ...editMessage,
                    active: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Active"
          />

          <TextField
            label="Text"
            value={editMessage.text}
            onChange={(e) => {
              setEditMessage({
                ...editMessage,
                text: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{marginBottom: 10}}
          />

          <DialogContentText style={{margin: 0}}><small>Reference User's name: {'{{name}}'}</small></DialogContentText>
          <DialogContentText style={{margin: 0}}><small>Reference Dog's name: {'{{dogname}}'}</small></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveMessage} color="secondary">
            Save
          </Button>
          <Button variant="contained" onClick={() => setEditMessageIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Message?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this message?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteMessage} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AppWelcomeMessages;
