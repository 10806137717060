import React, { Fragment, useState, useEffect } from 'react';
import firebase, { firestore } from 'firebase/app';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Sounds(props) {
  const [addCategory, setAddCategory] = useState(false)
  const [addNewSound, setAddNewSound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sounds, setSounds] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newSound, setNewSound] = useState({
    iconSource: '',
    iconUrl: '',
    source: '',
    label: '',
    free: false
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newSelectedCategory, setNewSelectedCategory] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [soundIndex, setSoundIndex] = useState(0);
  const [deleteSoundOpen, setDeleteSoundOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    const getSounds = async () => {
      try {
        const soundsRef = firebase.firestore().collection('training-sounds').doc('sounds');
        const soundsData = (await (soundsRef.get())).data();
        setSounds(soundsData);

        const availableCategoriesRef = firebase.firestore().collection('training-sounds').doc('categories');
        const categoriesData = (await (availableCategoriesRef.get())).data();
        const sortedCategories = categoriesData.categories.sort((a, b) => {
          const catA = a.toLowerCase();
          const catB = b.toLowerCase();
          if (catA < catB) {
            return -1;
          }
          if (catA > catB) {
            return 1;
          }
          return 0;
        })
        setAvailableCategories(sortedCategories);

        setLoading(false);
      } catch (error) {
        setModalTitle('Error');
        setModalText('Error retrieving sounds data. Refresh the page and try again.');
        console.log('Error retrieving sounds data.', error);
        setLoading(false);
      }
    }

    getSounds();
  }, [])

  const addNewCategory = async () => {
    setLoading(true);
    const db = firebase.firestore();
    try {
      const categoriesRef = db.collection('training-sounds').doc('categories');
      if(availableCategories.includes(newCategory)) {
        setLoading(false);
        setModalTitle('Notice');
        setModalText(`Category "${newCategory}" already exists.`);
        return;
      }
      categoriesRef.update({ categories: firebase.firestore.FieldValue.arrayUnion(newCategory.toLowerCase()) });
      setAvailableCategories([...availableCategories, newCategory.toLowerCase()]);
      setNewCategory('');
      setAddCategory(false);
      setLoading(false);
    } catch (error) {
      setModalTitle('Error');
      setModalText('Error adding new category, please try again');
      console.log('Error adding new category, please try again', error);
      setNewCategory('');
      setAddCategory(false);
      setLoading(false);
    }
  }

  const saveNewSound = async () => {
    setLoading(true);
    const db = firebase.firestore();
    try {
      const soundsRef = db.collection('training-sounds').doc('sounds');
      const category = newSelectedCategory;
      await soundsRef.update({ [category]: firebase.firestore.FieldValue.arrayUnion(newSound) });
      if(sounds[category]) {
        const updatedCategory = sounds[category].concat(newSound);
        setSounds({ ...sounds, [category]: updatedCategory });
      } else {
        setSounds({ ...sounds, [category]: [newSound]});
      }
      setLoading(false);
      handleSoundModalClose();
    } catch (error) {
      setModalTitle('Error');
      setModalText('Failed to add new sound, please try again.');
      console.log('Failed to add new sound, please try again.', error);
      setLoading(false);
      handleSoundModalClose();
    }
  }

  const updateSound = async () => {
    setLoading(true);
    const db = firebase.firestore();
    try {
      const soundsRef = db.collection('training-sounds').doc('sounds');
      if(newSelectedCategory && selectedCategory !== newSelectedCategory) {
        const oldCategorySounds = sounds[selectedCategory].slice(0);
        oldCategorySounds.splice(soundIndex, 1);
        await soundsRef.update({ [selectedCategory]: oldCategorySounds, [newSelectedCategory]: firebase.firestore.FieldValue.arrayUnion(newSound) });
        const updatedCategory = sounds[newSelectedCategory].concat(newSound);
        setSounds({ ...sounds, [selectedCategory]: oldCategorySounds, [newSelectedCategory]: updatedCategory });
        setLoading(false);
        handleSoundModalClose();
        return;
      }

      const category = newSelectedCategory || selectedCategory;
      const updatedSounds = sounds[category].slice(0);
      updatedSounds.splice(soundIndex, 1, newSound);
      await soundsRef.update({ [category]: updatedSounds })
      setSounds({ ...sounds, [category]: updatedSounds });
      setLoading(false);
      handleSoundModalClose();
    } catch (error) {
      setModalTitle('Error');
      setModalText('Could not update sound, please try again');
      console.log('Could not update sound, please try again', error);
      setLoading(false);
      handleSoundModalClose();
    }
  }

  const deleteSound = async () => {
    setLoading(true);
    const db = firebase.firestore();
    try {
      const soundsRef = db.collection('training-sounds').doc('sounds');
      const category = selectedCategory;
      const updatedSounds = sounds[category].slice(0);
      updatedSounds.splice(soundIndex, 1);
      await soundsRef.update({ [category]: updatedSounds });
      setSounds({ ...sounds, [category]: updatedSounds });
      setLoading(false);
      setDeleteSoundOpen(false);
    } catch (error) {
      setModalTitle('Error');
      setModalText('Could not delete sound, please try again');
      console.log('Could not delete sound, please try again', error);
      setLoading(false);
      setDeleteSoundOpen(false);
    }
  }

  const handleSoundModalClose = () => {
    setAddNewSound(false);
    setIsUpdate(false);
    setSelectedCategory('');
    setNewSelectedCategory('');
    setNewSound({
      iconSource: '',
      iconUrl: '',
      source: '',
      label: '',
      free: false
    });
  }

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div>
      {renderLoading()}
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Sounds
          </Typography>
          <div>
            {addCategory ?
              <>
                <TextField
                  label="New Category"
                  value={newCategory}
                  onChange={(e) => {
                    setNewCategory(e.target.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  style={{marginRight: 10}}
                />
                <Button style={{marginRight: 10}} onClick={() => {
                  setNewCategory('');
                  setAddCategory(false);
                }} color="secondary" variant="contained">Cancel</Button>
                <Button style={{marginRight: 10}} onClick={() => addNewCategory(true)} color="primary" variant="contained" disabled={!newCategory.length}>Save</Button>
              </>
              : <Button style={{marginRight: 15}} onClick={() => setAddCategory(true)} color="primary" variant="outlined">Add Category</Button>
            }
            <IconButton
              edge="start"
              color="inherit"
              onClick={(event) => {
                setAddNewSound(true);
              }}
              aria-label="Add"
            >
              <AddIcon />
            </IconButton>
          </div>
        </Toolbar>
          {availableCategories.map((category, i) => {
            return (
                <Accordion key={category + i} defaultExpanded={i === 0 ? true : false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{borderBottom: '4px solid #e8eaf6'}}>
                    <Typography>{category.toUpperCase()}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{display: 'inline'}}>
                    {sounds[category] && sounds[category].map((sound, j) => {
                      return (
                          <List key={sound + j}>
                            <ListItem>
                              <ListItemText
                                primary={sound.label}
                                secondary={
                                  <span>
                                    <span style={{ margin: 0 }}><strong>Source:</strong> {sound.source}</span>
                                    <br></br>
                                    <span style={{ margin: 0 }}><strong>Icon Source:</strong> {sound.iconSource}</span>
                                    <br></br>
                                    <span style={{ margin: 0 }}><strong>New Icon Source:</strong> {sound.iconUrl}</span>
                                  </span>
                                }
                              />
                              <IconButton aria-label="Edit" onClick={() => {
                                setIsUpdate(true);
                                setSelectedCategory(category);
                                setSoundIndex(j);
                                setNewSound(sound);
                                setAddNewSound(true);
                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton aria-label="Delete" onClick={() => {
                                setSelectedCategory(category);
                                setSoundIndex(j);
                                setNewSound(sound);
                                setDeleteSoundOpen(true);
                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                          </List>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
            )
          })}
      </Card>

      <Dialog
        open={addNewSound}
        onClose={handleSoundModalClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>{isUpdate ? 'Update Sound' : 'Add New Sound'}</DialogTitle>
        <DialogContent>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={newSelectedCategory || selectedCategory}
            onChange={(e) => {
              setNewSelectedCategory(e.target.value);
            }}
            displayEmpty
            style={{ width: '100%', marginBottom: 5 }}
          >
            <MenuItem value="" disabled>Category</MenuItem>
            {availableCategories.map((category, i) => {
              return (
                <MenuItem key={category + i} value={category}>{category.toUpperCase()}</MenuItem>
              )
            })}
          </Select>
          <TextField
            style={{marginBottom: 5}}
            label="Icon URL"
            value={newSound.iconSource}
            onChange={(e) => {
              setNewSound({
                ...newSound,
                iconSource: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            style={{marginBottom: 5}}
            label="New Icon URL"
            value={newSound.iconUrl || ''}
            onChange={(e) => {
              setNewSound({
                ...newSound,
                iconUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            style={{marginBottom: 5}}
            label="Label"
            value={newSound.label}
            onChange={(e) => {
              setNewSound({
                ...newSound,
                label: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
          <TextField
            style={{marginBottom: 5}}
            label="Source"
            value={newSound.source}
            onChange={(e) => {
              setNewSound({
                ...newSound,
                source: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newSound.free}
                onChange={(e) => {
                  setNewSound({
                    ...newSound,
                    free: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Free Sound"
          />
        </DialogContent>
        <DialogActions>
          {isUpdate ? 
          <Button variant="contained" onClick={updateSound} color="primary" disabled={(!newSound.iconSource && !newSound.iconUrl) || !selectedCategory || !newSound.source || !newSound.label}>
            Update
          </Button> : 
          <Button variant="contained" onClick={saveNewSound} color="primary" disabled={(!newSound.iconSource && !newSound.iconUrl) || !newSelectedCategory || !newSound.source || !newSound.label}>
            Submit
          </Button>
          }
          <Button variant="contained" onClick={handleSoundModalClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteSoundOpen} onClose={() => setDeleteSoundOpen(false)} TransitionComponent={Transition}>
        <DialogTitle>Deleting Sound</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to permanently delete this sound?</Typography>
          <Typography variant="h4">Label: {newSound.label}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setDeleteSoundOpen(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={() => deleteSound()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Sounds;
