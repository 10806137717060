import React from 'react';

import './dashboard.scss';

function Dashboard({}) {
  return (
    <div className="PartnersDashboard">
      Partners Dashboard
    </div>
  );
}

export default Dashboard;
