import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';

import './productTagPage.scss';
import DeletableChip from '../../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProductTagPage({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [productPageTag, setProductPageTag] = useState({
    active: false,
    tagName: '',
    pageTitle: '',
    pageDescription: '',
    headerImageUrl: '',
    headerBackgroundColor: '',
    headerTextColor: '',
    path: '',
    pageMetaTitle: '',
    pageMetaDescription: '',
    products: [],
    additionalSections: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [sectionDeleteIndex, setSectionDeleteIndex] = useState(-1);

  useState(() => {
    const fetchProductPageTag = async () => {
      try {
        const id = match.params.id;

        if (id === undefined) {
          history.replace('/shop/product-tag-pages');
          return;
        }

        const db = firebase.firestore();
        const querySnapshot = await db.collection('product-tag-pages').doc(id).get();
        const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
        const productPageTagData = querySnapshot.data();
        const shopSettingsData = shopSettingsSnapshot.data();
  
        if (!productPageTagData) {
          history.replace('/shop/product-tag-pages');
          return;
        }

        setProductPageTag({
          ...productPageTagData,
          additionalSections: productPageTagData.additionalSections || [],
        });
        setSimplifiedProducts(shopSettingsData.simplifiedProducts.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
    
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
    
          return 0;
        }));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching the Product Tag Page. Refresh the page and try again.');
      }
    };

    fetchProductPageTag();
  }, []);

  const saveProductTagPage = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      const id = match.params.id;
      const productTagPageSnapshot = await db.collection('product-tag-pages').where('path', '==', productPageTag.path).get();

      if (productTagPageSnapshot.docs.length && productTagPageSnapshot.docs[0].id !== id) {
        setLoading(false);
        setModalTitle('Notice!');
        setModalText('There is another product tag page with the provided URL path. Please a unique URL path and try again.');
        return;
      }

      await db.collection('product-tag-pages').doc(id).update({
        ...productPageTag,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred saving this Product Tag Page, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ProductTagPage">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/shop/product-tag-pages">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: '10px', marginBottom: '15px' }}
            startIcon={<ArrowBackIcon />}
          >
            All Product Tag Pages
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            <strong>{productPageTag.tagName}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={saveProductTagPage}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <div className="form-field-container">
            <FormControlLabel
              control={
                <Switch
                  checked={productPageTag.active}
                  onChange={(e) => {
                    setProductPageTag({
                      ...productPageTag,
                      active: e.target.checked,
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Tag Name"
              value={productPageTag.tagName}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  tagName: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Page Title"
              value={productPageTag.pageTitle}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  pageTitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Page Description"
              value={productPageTag.pageDescription}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  pageDescription: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Header Image URL"
              value={productPageTag.headerImageUrl}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  headerImageUrl: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Header Background Color (use hex, rgb or rgba)"
              value={productPageTag.headerBackgroundColor}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  headerBackgroundColor: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Header Text Color (use hex, rgb or rgba)"
              value={productPageTag.headerTextColor}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  headerTextColor: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Page Meta Title"
              value={productPageTag.pageMetaTitle}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  pageMetaTitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <TextField
              label="Page Meta Description"
              value={productPageTag.pageMetaDescription}
              onChange={(e) => {
                setProductPageTag({
                  ...productPageTag,
                  pageMetaDescription: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-field-container">
            <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, width: '100%'}}>
              <InputLabel>URL Path</InputLabel>
              <OutlinedInput
                value={productPageTag.path}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                  setProductPageTag({
                    ...productPageTag,
                    path: value,
                  });
                }}
                margin="dense"
                label="URL Path"
                variant="outlined"
                type="text"
                startAdornment={<InputAdornment style={{marginRight: 0}} position="start">shop/tag/</InputAdornment>}
              />
              <FormHelperText><small>URL must be unique</small></FormHelperText>
            </FormControl>
          </div>

          <div className="products-container">
            <FormControl fullWidth style={{ marginTop: -22 }}>
              <InputLabel htmlFor="select-multiple-chip">Products</InputLabel>
              <Select
                multiple
                value={productPageTag.products}
                onChange={(e) => {
                  setProductPageTag({
                    ...productPageTag,
                    products: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {selected.map(value => {
                      const product = simplifiedProducts.find(p => p.id === value) || {};
                      return (
                        <DeletableChip
                          key={value}
                          label={product.name}
                          style={{margin: 2}}
                          color="primary"
                          onDelete={() => {
                            const index = productPageTag.products.indexOf(value);
                            const products = productPageTag.products.slice();
                            products.splice(index, 1);
                            setProductPageTag({
                              ...productPageTag,
                              products,
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {simplifiedProducts.filter(product => {
                  return !productPageTag.products.includes(product.id);
                }).map(product => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="section-container">
            <div className="section-label">Additional Product Sections</div>

            <div className="section-header">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  const additionalSections = [
                    ...productPageTag.additionalSections,
                    {
                      title: '',
                      description: '',
                      products: [],
                    },
                  ];

                  setProductPageTag({
                    ...productPageTag,
                    additionalSections,
                  });
                }}
              >
                Add Section
              </Button>
            </div>

            {productPageTag.additionalSections.map((section, i) => {
              return (
                <div
                  key={`additional-section-${i}`}
                  className="additional-section"
                >
                  <Divider/>

                  <div className="additional-section-row">
                    <div className="additional-section-inputs-container">
                      <div className="form-field-container">
                        <TextField
                          label="Section Title"
                          value={section.title}
                          onChange={(e) => {
                            const additionalSections = [ ...productPageTag.additionalSections ];

                            additionalSections[i] = {
                              ...additionalSections[i],
                              title: e.target.value,
                            };

                            setProductPageTag({
                              ...productPageTag,
                              additionalSections,
                            });
                          }}
                          variant="outlined"
                          margin="dense"
                          type="text"
                          className="day-text-field"
                        />

                        <TextField
                          label="Section Description"
                          value={section.description}
                          onChange={(e) => {
                            const additionalSections = [ ...productPageTag.additionalSections ];

                            additionalSections[i] = {
                              ...additionalSections[i],
                              description: e.target.value,
                            };

                            setProductPageTag({
                              ...productPageTag,
                              additionalSections,
                            });
                          }}
                          variant="outlined"
                          margin="dense"
                          type="text"
                          className="day-text-field"
                        />
                      </div>

                      <div className="products-container">
                        <FormControl fullWidth style={{ marginTop: -22 }}>
                          <InputLabel htmlFor="select-multiple-chip">Section Products</InputLabel>
                          <Select
                            multiple
                            value={section.products}
                            onChange={(e) => {
                              const additionalSections = [ ...productPageTag.additionalSections ];

                              additionalSections[i] = {
                                ...additionalSections[i],
                                products: e.target.value,
                              };

                              setProductPageTag({
                                ...productPageTag,
                                additionalSections,
                              });
                            }}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={selected => (
                              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {selected.map(value => {
                                  const product = simplifiedProducts.find(p => p.id === value) || {};
                                  return (
                                    <DeletableChip
                                      key={value}
                                      label={product.name}
                                      style={{margin: 2}}
                                      color="primary"
                                      onDelete={() => {
                                        const additionalSections = [ ...productPageTag.additionalSections ];

                                        const index = section.products.indexOf(value);
                                        const products = section.products.slice();
                                        products.splice(index, 1);

                                        additionalSections[i] = {
                                          ...additionalSections[i],
                                          products,
                                        };

                                        setProductPageTag({
                                          ...productPageTag,
                                          additionalSections,
                                        });
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {simplifiedProducts.filter(product => {
                              return !section.products.includes(product.id);
                            }).map(product => (
                              <MenuItem key={product.id} value={product.id}>
                                {product.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="additional-section-buttons-container">
                      {i !== 0 ?
                        <IconButton edge="start" color="inherit" onClick={() => {
                          const additionalSections = [ ...productPageTag.additionalSections ];

                          const itemToMoveUp = section;
                          const itemToMoveDown = additionalSections[i - 1];

                          additionalSections[i] = itemToMoveDown;
                          additionalSections[i - 1] = itemToMoveUp;

                          setProductPageTag({
                            ...productPageTag,
                            additionalSections,
                          });
                        }} aria-label="Move Up">
                          <ArrowUpwardIcon />
                        </IconButton> :
                        <div style={{ width: '36px' }}></div>
                      }

                      {(i !== productPageTag.additionalSections.length - 1) ?
                        <IconButton edge="start" color="inherit" onClick={() => {
                          const additionalSections = [ ...productPageTag.additionalSections ];

                          const itemToMoveDown = section;
                          const itemToMoveUp = additionalSections[i + 1];

                          additionalSections[i] = itemToMoveUp;
                          additionalSections[i + 1] = itemToMoveDown;

                          setProductPageTag({
                            ...productPageTag,
                            additionalSections,
                          });
                        }} aria-label="Move Down">
                          <ArrowDownwardIcon />
                        </IconButton> :
                        <div style={{ width: '36px' }}></div>
                      }

                      <IconButton color="inherit" onClick={() => setSectionDeleteIndex(i)} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={sectionDeleteIndex !== -1} onClose={() => setSectionDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Section?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this section?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const additionalSections = [ ...productPageTag.additionalSections ];
              
              additionalSections.splice(sectionDeleteIndex, 1);

              setProductPageTag({
                ...productPageTag,
                additionalSections,
              });
              setSectionDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setSectionDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => setModalText('')} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setModalText('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductTagPage;
