import React, { useEffect } from 'react';
import { useProductOptionsForm } from './useProductPage';
import { FormProvider } from 'react-hook-form';
import { ButtonAddCartForm } from './components/buttonAddCartForm';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { ButtonBuyNowForm } from './components/buttonBuyNowForm';

export const ProductOptionsForm = ({ onSubmit, defaultValues }) => {
  console.log({ defaultValues });
  const formMethods = useProductOptionsForm(defaultValues);
  const { handleSubmit, setValue } = formMethods;

  useEffect(() => {
    if (defaultValues) {
      setValue('buttonAddCart', defaultValues.buttonAddCart);
      setValue('buttonBuyNow', defaultValues.buttonBuyNow);
    }
  }, [defaultValues, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toolbar
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          <strong>Product Options</strong>
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          type="submit"
        >
          Save
        </Button>
      </Toolbar>

      <FormProvider {...formMethods}>
        <ButtonAddCartForm />
        <ButtonBuyNowForm />
      </FormProvider>
    </form>
  );
};
