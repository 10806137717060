import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './courseModules.scss';
import DeletableChip from '../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

function CourseModules({ modules, updateModules, products, categories, sections, isAcademyPlus }) {
  const [module, setModule] = useState({
    name: '',
    description: '',
    mainVideoThumbnail: '',
    mainVideoUrl: '',
    minutes: '',
    seconds: '',
    sections: [],
    products: [],
    category: '',
  });
  const [moduleIndex, setModuleIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [hideListEditors, setHideListEditors] = useState(false);

  const moveItemUp = (i) => {
    const updatedModules = [ ...modules ];
    const itemToMoveUp = updatedModules[i];
    const itemToMoveDown = updatedModules[i - 1];
    updatedModules[i] = itemToMoveDown;
    updatedModules[i - 1] = itemToMoveUp;
    updateModules(updatedModules);
  };

  const moveItemDown = (i) => {
    const updatedModules = [ ...modules ];
    const itemToMoveUp = updatedModules[i + 1];
    const itemToMoveDown = updatedModules[i];
    updatedModules[i + 1] = itemToMoveDown;
    updatedModules[i] = itemToMoveUp;
    updateModules(updatedModules);
  };

  const renderReorderArrows = (i) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemUp(i) }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    if (i !== modules.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemDown(i) }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    return arrowItems;
  };

  return (
    <div className="CourseModules">
      <div className="section-container">
        <div className="section-label">Modules</div>

        <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: 10}}>
          <Button
            variant="contained"
            onClick={() => {
              setModule({
                id: uuidv4(),
                mainVideoThumbnail: '',
                mainVideoUrl: '',
                name: '',
                description: '',
                minutes: '',
                seconds: '',
                sections: [],
                products: [],
                category: '',
              });
              setModuleIndex(modules.length);
            }}
            color="primary"
          >
            Add Module
          </Button>
        </div>

        <List>
          {modules.map((m, i) => {
            return (
              <div key={`module-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={m.name} secondary={m.description} />

                  {renderReorderArrows(i)}

                  <IconButton
                    color="inherit"
                    aria-label="Edit"
                    onClick={() => {
                      setModule(cloneDeep(m));
                      setModuleIndex(i);
                    }}
                  >
                    <EditIcon style={{color: '#000'}} />
                  </IconButton>
                  <IconButton color="inherit" onClick={() => setDeleteIndex(i)} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>

      <Dialog
        fullScreen
        open={moduleIndex !== -1}
        onClose={() => setModuleIndex(-1)}
        TransitionComponent={Transition}
      >
        <div>
          <AppBar style={{position: 'relative'}}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => setModuleIndex(-1)} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{
                marginLeft: '10px',
                flex: 1,
                color: 'white'
              }}>
                Edit Module
              </Typography>
              <Button
                color="inherit"
                onClick={() => {
                  const updatedModules = [ ...modules ];
                  updatedModules[moduleIndex] = cloneDeep(module);

                  updateModules(updatedModules);
                  setModuleIndex(-1);
                }}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>

          <div className="container" style={{marginTop: 30, marginBottom: 30}}>
            {!isAcademyPlus ? null :
              <FormControlLabel
                control={
                  <Switch
                    checked={module.isFreePreview || false}
                    onChange={(e) => {
                      setModule({
                        ...module,
                        isFreePreview: e.target.checked,
                      });
                    }}
                    name="isFreePreview"
                    color="primary"
                  />
                }
                label="Free Preview"
              />
            }

            <TextField
              label="Name"
              value={module.name}
              onChange={(e) => {
                setModule({
                  ...module,
                  name: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              className="day-text-field condensed-text-field"
            />

            <div style={{marginTop: 10, marginBottom: 20}}>
              <Typography style={{marginBottom: 5, fontSize: 13, color: 'rgba(0, 0, 0, 0.54)'}}>Description</Typography>
              <ReactQuill
                theme="snow"
                value={module.description}
                onChange={(text) => {
                  setModule({
                    ...module,
                    description: text,
                  });
                }}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                  ],
                }}
                formats={[
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet',
                  'link',
                ]}
              />
            </div>

            <TextField
              label="Minutes"
              value={module.minutes}
              onChange={(e) => {
                setModule({
                  ...module,
                  minutes: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              className="day-text-field condensed-text-field"
            />

            <TextField
              label="Seconds"
              value={module.seconds}
              onChange={(e) => {
                setModule({
                  ...module,
                  seconds: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              className="day-text-field condensed-text-field"
            />

            <TextField
              label="Main Video Thumbnail URL"
              value={module.mainVideoThumbnail}
              onChange={(e) => {
                setModule({
                  ...module,
                  mainVideoThumbnail: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              className="day-text-field condensed-text-field"
            />

            <TextField
              label="Main Video URL"
              value={module.mainVideoUrl}
              onChange={(e) => {
                setModule({
                  ...module,
                  mainVideoUrl: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              className="day-text-field condensed-text-field"
            />

            <FormControl style={{ margin: 5, width: '100%' }}>
              <InputLabel id="primary-category">
                Category
              </InputLabel>
              <Select
                label="Primary category"
                value={module.category || ''}
                onChange={(e) => {
                  setModule({
                    ...module,
                    category: e.target.value,
                  });
                }}
              >
                <MenuItem value="">None</MenuItem>
                {categories.map(category => {
                  return <MenuItem key={`category-${category}`} value={category}>{category}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <FormControl style={{ margin: 5, width: '100%' }}>
              <InputLabel htmlFor="select-section">Section</InputLabel>
              <Select
                value={module.section || ''}
                onChange={(e) => {
                  setModule({
                    ...module,
                    section: e.target.value,
                  });
                }}
                input={<Input id="select-section" />}
              >
                <MenuItem value="">None</MenuItem>
                {sections.map(section => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ margin: 5, width: '100%' }}>
              <InputLabel htmlFor="select-multiple-chip">Related Products</InputLabel>
              <Select
                multiple
                value={module.products}
                onChange={(e) => {
                  setModule({
                    ...module,
                    products: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <DeletableChip
                        key={value.id}
                        label={value.name}
                        style={{ margin: 2 }}
                        color="primary"
                        onDelete={() => {
                          const index = module.products.indexOf(value);
                          const products = module.products.slice();

                          products.splice(index, 1);

                          setModule({
                            ...module,
                            products,
                          });
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {products.filter(product => {
                  return !module.products.includes(product.id);
                }).map(product => (
                  <MenuItem key={product.id} value={product}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{marginTop: 20}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, alignItems: 'center'}}>
                <Typography className="section-label">Sections</Typography>

                <Button
                  variant="contained"
                  onClick={() => {
                    setModule({
                      ...module,
                      sections: [
                        ...module.sections,
                        {
                          videoUrl: '',
                          text: '',
                        },
                      ],
                    });
                  }}
                  color="primary"
                >
                  Add Section
                </Button>
              </div>

              <List>
                {module.sections.map((section, i) => {
                  return (
                    <div key={`section-${i}`}>
                      <Divider />
                      <ListItem>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <div style={{ marginRight: 10, flex: 1 }}>
                            <TextField
                              label="Video URL"
                              value={section.videoUrl}
                              onChange={(e) => {
                                const sections = [ ...module.sections ];

                                sections[i] = {
                                  ...sections[i],
                                  videoUrl: e.target.value,
                                };

                                setModule({
                                  ...module,
                                  sections,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              className="day-text-field condensed-text-field"
                            />

                            <div style={{marginTop: 10, marginBottom: 20}}>
                              <Typography style={{marginBottom: 5, fontSize: 13, color: 'rgba(0, 0, 0, 0.54)'}}>Details</Typography>
                              {hideListEditors ? null :
                                <ReactQuill
                                  theme="snow"
                                  value={section.text}
                                  onChange={(text) => {
                                    const sections = [ ...module.sections ];

                                    sections[i] = {
                                      ...sections[i],
                                      text,
                                    };

                                    setModule({
                                      ...module,
                                      sections,
                                    });
                                  }}
                                  modules={{
                                    toolbar: [
                                      ['bold', 'italic', 'underline', 'strike'],
                                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                      ['link'],
                                    ],
                                  }}
                                  formats={[
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet',
                                    'link',
                                  ]}
                                />
                              }
                            </div>
                          </div>

                          <IconButton
                            color="inherit"
                            onClick={() => {
                              setHideListEditors(true);

                              setTimeout(() => {
                                const sections = [ ...module.sections ];
                                sections.splice(i, 1);
                                setModule({
                                  ...module,
                                  sections,
                                });
                                setHideListEditors(false);
                              }, 0);
                            }}
                            aria-label="Delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </ListItem>
                    </div>
                  );
                })}
              </List>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Module?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {deleteIndex === -1 ? 'this module?' : <strong>{modules[deleteIndex].name}</strong>}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const updatedModules = [ ...modules ];
              updatedModules.splice(deleteIndex, 1);
              updateModules(updatedModules);
              setDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseModules;
