import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Divider,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import PageBuilder from '../../shared/pageBuilder';
import PagePreview from '../../shared/pageBuilder/components/preview';
import './cartCustomContent.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cloneDeep = require('lodash.clonedeep');

function CartCustomContent(props) {
  const [loading, setLoading] = useState(true);
  const [leftData, setLeftData] = useState({
    customCSS: '',
    editorData: {
      type: 'top-layer',
      children: [],
    },
  });
  const [rightData, setRightData] = useState({
    customCSS: '',
    editorData: {
      type: 'top-layer',
      children: [],
    },
  });
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [editLeftData, setEditLeftData] = useState(false);
  const [editRightData, setEditRightData] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const db = firebase.firestore();

    try {
      const contentSnapshot = await db.collection('cart-content').get();
      const templatesQuerySnapshot = await db.collection('global-templates').get();

      const templates = templatesQuerySnapshot.docs.map(doc => {
        const data = doc.data();

        if (data.children && typeof data.children === 'string') {
          data.children = JSON.parse(data.children);
        }

        return data;
      });

      let right;
      let left;

      contentSnapshot.docs.forEach((doc) => {
        if (doc.id === 'right') {
          right = doc.data();
        }

        if (doc.id === 'left') {
          left = doc.data();
        }
      });

      const onlyGlobalTemplates = templates.filter(t => {
        return t.global;
      });
      const updatedLeftEditorData = updateGlobalTemplates(left.editorData, onlyGlobalTemplates);
      const updatedRightEditorData = updateGlobalTemplates(right.editorData, onlyGlobalTemplates);

      left.editorData = updatedLeftEditorData;
      right.editorData = updatedRightEditorData;

      setGlobalTemplates(templates);
      setLeftData(left);
      setRightData(right);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving cart content. Refresh the page and try again.');
    }
  };

  const updateGlobalTemplates = (data, templates) => {
    if (data.global) {
      const foundIndex = templates.findIndex(t => {
        return t.id === data.id;
      });

      if (foundIndex !== -1) {
        const templateCopy = cloneDeep(templates[foundIndex]);
        for (let key in templateCopy) {
          data[key] = templateCopy[key];
        }
      }
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        updateGlobalTemplates(children[i], templates);
      }
    }

    return data;
  };

  const findAndSaveGlobalTemplates = (data, templatesToSave) => {
    if (data.global) {
      const dataToSave = {
        ...data,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      if (!dataToSave.created) {
        dataToSave.created = dataToSave.updated;
      }

      templatesToSave[dataToSave.id] = dataToSave;
    }
    
    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        findAndSaveGlobalTemplates(children[i], templatesToSave);
      }
    }

    return templatesToSave;
  };

  const saveColumns = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const leftTemplatesToSave = findAndSaveGlobalTemplates(leftData.editorData, {});
      const rightTemplatesToSave = findAndSaveGlobalTemplates(rightData.editorData, {});
      const sharedTemplates = [];
      const templatesToSave = {
        ...leftTemplatesToSave,
        ...rightTemplatesToSave,
      };

      for (let key in templatesToSave) {
        sharedTemplates.push(key);
        try {
          let globalTemplatesCopy = [ ...globalTemplates ];
          const foundIndex = globalTemplates.findIndex(t => {
            return `${t.id}` === `${key}`;
          });
    
          if (foundIndex !== -1) {
            globalTemplatesCopy[foundIndex] = templatesToSave[key];
          } else {
            globalTemplatesCopy.push(templatesToSave[key]);
            globalTemplatesCopy = [ templatesToSave[key], ...globalTemplatesCopy ];
          }
    
          setGlobalTemplates(globalTemplatesCopy);

          await firebase.firestore().collection('global-templates').doc(templatesToSave[key].id).set({
            ...templatesToSave[key],
            children: JSON.stringify(templatesToSave[key].children),
          });
        } catch (e) {
          console.log('error saving template', e);
        }
      }

      const leftDataToSave = {
        ...leftData,
        sharedTemplates,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };
      const rightDataToSave = {
        ...rightData,
        sharedTemplates,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await db.collection('cart-content').doc('left').update(leftDataToSave);
      await db.collection('cart-content').doc('right').update(rightDataToSave);
      const savedLeftData = (await db.collection('cart-content').doc('left').get()).data();
      const savedRightData = (await db.collection('cart-content').doc('right').get()).data();

      const onlyGlobalTemplates = [];

      for (let key in templatesToSave) {
        onlyGlobalTemplates.push(templatesToSave[key]);
      }

      const updatedLeftEditorData = updateGlobalTemplates(savedLeftData.editorData, onlyGlobalTemplates);
      const updatedRightEditorData = updateGlobalTemplates(savedRightData.editorData, onlyGlobalTemplates);

      setLeftData({
        ...savedLeftData,
        editorData: updatedLeftEditorData,
      });
      setRightData({
        ...savedRightData,
        editorData: updatedRightEditorData,
      });
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The cart content has been successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the cart content. Please try again.');
    }
  };

  const saveModule = async (moduleToSave) => {
    const dataToSave = {
      ...moduleToSave,
      created: Date.now(),
      updated: Date.now(),
      lastUpdatedBy: firebase.auth().currentUser.email,
    };

    // Don't save globals it here. Only save globals on page save
    if (dataToSave.global) {
      setGlobalTemplates([ dataToSave, ...globalTemplates ]);
      setModalTitle('Success!');
      setModalText(`The ${moduleToSave.type} was added an will be saved when saving the cart content.`);
    } else {
      console.log('module', moduleToSave);
      const db = firebase.firestore();
      setLoading(true);

      try {
        await db.collection('global-templates').doc(dataToSave.id).set({
          ...dataToSave,
          children: JSON.stringify(dataToSave.children),
        });

        setGlobalTemplates([ dataToSave, ...globalTemplates ]);

        setLoading(false);
        setModalTitle('Success!');
        setModalText(`The ${moduleToSave.type} was saved successfully.`);
      } catch (e) {
        console.log('error', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`There was an error saving this ${moduleToSave.type}. Please try again.`);
      }
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CartCustomContent">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between',}}>
          <Typography variant="h6">
            Checkout Content
          </Typography>

          <Button
            variant="contained"
            onClick={() => { saveColumns() }}
            color="secondary"
            size="small"
          >
            Save
          </Button>
        </Toolbar>
        <Divider />
        <div className="content-container">
          <div className="content-inner">
            <div className="left">
              <Button
                variant="contained"
                onClick={() => { setEditLeftData(true) }}
                color="primary"
                size="small"
              >
                Edit Left Column
              </Button>

              <div className="preview">
                <style>{leftData.customCSS}</style>
                <PagePreview items={leftData.editorData.children} />
              </div>
            </div>
            <div className="right">
              <Button
                variant="contained"
                onClick={() => { setEditRightData(true) }}
                color="primary"
                size="small"
              >
                Edit Right Column
              </Button>

              <div className="preview">
                <style>{rightData.customCSS}</style>
                <PagePreview items={rightData.editorData.children} />
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Dialog fullScreen open={editLeftData} onClose={() => { setEditLeftData(false) }} TransitionComponent={Transition}>
        <PageBuilder
          content={leftData.editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            setLeftData({
              ...leftData,
              editorData: content,
            });
          }}
          close={() => { setEditLeftData(false) }}
          css={leftData.customCSS}
          updateCSS={(customCSS) => {
            setLeftData({
              ...leftData,
              customCSS,
            });
          }}
        />
      </Dialog>

      <Dialog fullScreen open={editRightData} onClose={() => { setEditRightData(false) }} TransitionComponent={Transition}>
        <PageBuilder
          content={rightData.editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            setRightData({
              ...rightData,
              editorData: content,
            });
          }}
          close={() => { setEditRightData(false) }}
          css={rightData.customCSS}
          updateCSS={(customCSS) => {
            setRightData({
              ...rightData,
              customCSS,
            });
          }}
        />
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CartCustomContent;
