import React from 'react';
import './academy-section.scss';

export const AcademySection = ({ data }) => {
  const {
    title,
    subtitle,
    backgroundColor,
    imageUrl,
    imagePosition,
    content,
    buttonText,
    buttonColor,
  } = data;

  return (
    <section className="AcademyContainer" style={{ backgroundColor }}>
      {title ? <h3 className="AcademyTitle">{title}</h3> : null}
      <div className="grid">
        <div
          className="gridItem"
          style={{ order: imagePosition === 'right' ? 0 : 1 }}
        >
          <div className="contentContainer">
            {subtitle ? <h4 className="AcademySubtitle">{subtitle}</h4> : null}
            <p className="AcademyContent">{content}</p>

            <button
              className="AcademyButton"
              style={{
                color: buttonColor,
                border: `2px solid ${buttonColor}`,
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>

        <div
          className="gridItem"
          style={{ order: imagePosition === 'right' ? 1 : 0 }}
        >
          <div className="imageContainer">
            <img className="image" alt={imageUrl} src={imageUrl} />
          </div>
        </div>
      </div>
    </section>
  );
};
