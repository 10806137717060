import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import axios from 'axios';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import './subscriptionForecast.scss';
import { config } from '../../../config';
import DeletableChip from '../../shared/deletableChip';
import SubscriptionRevenueCountChart from './subscriptionRevenueCountChart/subscriptionRevenueCountChart';
import SubscriptionForecastByProduct from './subscriptionForecastByProduct/subscriptionForecastByProduct';
import SubscriptionForecastByCategory from './subscriptionForecastByCategory/subscriptionForecastByCategory';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SubscriptionForecast({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState('all');

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const oneYearFromNow = moment().add(1, 'year').valueOf();
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/subscriptions/forecast`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const filteredSubscriptions = response.data.filter(sub => {
        return +sub.nextPaymentDate <= oneYearFromNow;
      });

      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();

      const uniqueCategories = [];

      const simplifiedProductData = shopSettingsSnapshot.data().simplifiedProducts.map(p => {
        p.categories.forEach(c => {
          if (!uniqueCategories.includes(c)) {
            uniqueCategories.push(c);
          }
        });

        return {
          name: p.name,
          id: p.id,
        };
      });

      setSubscriptions(filteredSubscriptions);
      setProducts(simplifiedProductData);
      setCategories(uniqueCategories);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving active subscriptions. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SubscriptionForecast">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Subscription Forecast
          </Typography>
        </Toolbar>

        <Divider/>

        <div className="content">
          <ToggleButtonGroup
            color="primary"
            value={productTypeFilter}
            exclusive
            onChange={(e, value) => {
              setProductTypeFilter(value);
            }}
            size="small"
            className="product-type-filter"
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="physical">Physical</ToggleButton>
            <ToggleButton value="digital">Digital</ToggleButton>
          </ToggleButtonGroup>

          <SubscriptionRevenueCountChart
            subscriptions={subscriptions.filter(s => {
              if (productTypeFilter === 'all') {
                return true;
              } else if (productTypeFilter === 'physical' && Array.isArray(s.products) && s.products[0] && !s.products[0].isDigital) {
                return true;
              } else if (productTypeFilter === 'digital' && Array.isArray(s.products) && s.products[0] && s.products[0].isDigital) {
                return true;
              }

              return false;
            })}
          />

          <div className="sub-title-container">
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(0, 0, 0, 0.07)', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                Filter by Product
              </Typography>
            </Toolbar>
          </div>

          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Selected Products</InputLabel>
            <Select
              multiple
              value={selectedProducts}
              onChange={(e) => { setSelectedProducts(e.target.value) }}
              input={<Input />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value.id}
                      label={value.name}
                      style={{margin: 2}}
                      color="primary"
                      onDelete={() => {
                        const index = selectedProducts.indexOf(value);

                        if (index === -1) {
                          return;
                        }

                        const products = [ ...selectedProducts ];

                        products.splice(index, 1);

                        setSelectedProducts(products);
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {products.filter(product => {
                const found = selectedProducts.find(p => p.id === product.id);
                return !found;
              }).map(product => (
                <MenuItem key={product.id} value={product}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!selectedProducts.length ? null :
            <SubscriptionForecastByProduct
              selectedProducts={selectedProducts}
              subscriptions={subscriptions}
            />
          }

          <div className="sub-title-container">
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(0, 0, 0, 0.07)', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                Filter by Category
              </Typography>
            </Toolbar>
          </div>

          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Selected Categories</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={(e) => { setSelectedCategories(e.target.value) }}
              input={<Input />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value}
                      label={value}
                      style={{margin: 2}}
                      color="primary"
                      onDelete={() => {
                        const index = selectedCategories.indexOf(value);

                        if (index === -1) {
                          return;
                        }

                        const categories = [ ...selectedCategories ];

                        categories.splice(index, 1);

                        setSelectedCategories(categories);
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {categories.filter(category => {
                return !selectedCategories.includes(category);
              }).map(category => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!selectedCategories.length ? null :
            <SubscriptionForecastByCategory
              selectedCategories={selectedCategories}
              subscriptions={subscriptions}
            />
          }
        </div>
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionForecast;
