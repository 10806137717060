import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';

import './user.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function User(props) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [emailVerifyConfirmationOpen, setEmailVerifyConfirmationOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [editAddressType, setEditAddressType] = useState('');
  const [editAddress, setEditAddress] = useState({
    firstName: '',
    lastName: '',
    address: '',
    addressSecondary: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });
  const [memberships, setMemberships] = useState([]);
  const [editMembershipIndex, setEditMembershipIndex] = useState(-1);
  const [addMembershipOpen, setAddMembershipOpen] = useState(false);
  const [allMemberships, setAllMemberships] = useState([]);
  const [selectedAddMembership, setSelectedAddMembership] = useState('');
  const [editEmailOpen, setEditEmailOpen] = useState(false);
  const [updatedUserEmail, setUpdatedUserEmail] = useState('');
  const [orders, setOrders] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [rewardsEntries, setRewardsEntries] = useState([]);
  const [addStoreCreditOpen, setAddStoreCreditOpen] = useState(false);
  const [storeCreditAmountToAdd, setStoreCreditAmountToAdd] = useState('');

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    const id = props.match.params.id;

    if (id === undefined) {
      props.history.replace('/manage-users/orders');
      return;
    }

    fetchUserRewards(id);

    try {
      const db = firebase.firestore();
      const userSnapshot = await db.collection('users').doc(id).get();
      const userData = userSnapshot.data();

      const getUser = firebase.functions().httpsCallable('getUser');
      const result = await getUser({ userId: id });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      if (result && result.data && result.data.user) {
        const resultUser = result.data.user;
        userData.disabled = resultUser.disabled;
        userData.emailVerified = resultUser.emailVerified;
        userData.metadata = resultUser.metadata;
      }

      const membershipsSnapshot = await db.collection('user-memberships').where('userId', '==', id).get();
      const membershipsData = [];

      if (membershipsSnapshot.docs.length) {
        for (let i = 0; i < membershipsSnapshot.docs.length; i++) {
          const membership = membershipsSnapshot.docs[i];
          const data = membership.data();

          data.id = membership.id;

          try {
            const membershipSnapshot = await db.collection('memberships').doc(data.membership).get();
            const membershipData = membershipSnapshot.data();

            if (membershipData) {
              data.name = membershipData.name;
            }
          } catch (e) {
            console.log('error getting membership', e);
          }

          membershipsData.push(data);
        }
      }

      if (!userData.billing) {
        userData.billing = {
          firstName: '',
          lastName: '',
          address: '',
          addressSecondary: '',
          city: '',
          zip: '',
          country: 'US',
          state: 'AL',
        };
      }

      if (!userData.shipping) {
        userData.shipping = {
          firstName: '',
          lastName: '',
          address: '',
          addressSecondary: '',
          city: '',
          zip: '',
          country: 'US',
          state: 'AL',
        };
      }

      const subscriptionSnapshot = await db.collection('subscriptions').where('userId', '==', id).get();
      const orderSnapshot = await db.collection('orders-v2').where('userId', '==', id).get();

      const subscriptionsData = subscriptionSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created.seconds - a.created.seconds;
      });

      const ordersData = orderSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created.seconds - a.created.seconds;
      });

      setUser({
        ...userData,
        id: userSnapshot.id,
      });
      setMemberships(membershipsData);
      setOrders(ordersData);
      setSubscriptions(subscriptionsData);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching the user. Refresh and try again.');
    }
  };

  const fetchUserRewards = async (userId) => {
    try {
      const userRewardsSnapshot = await firebase.firestore().collection('user-rewards').doc(userId).get();
      const userRewardsData = userRewardsSnapshot.data() || {
        earnings: [],
        spent: [],
      };

      let earningsMap = {};
      let entries = [];

      userRewardsData.earnings.forEach(earning => {
        entries.push({
          ...earning,
          type: 'earning',
        });

        earningsMap[earning.id] = {
          dollarsPerPoint: parseFloat(earning.dollarsPerPoint),
        };
      });

      userRewardsData.spent.forEach(s => {
        entries.push({
          ...s,
          type: 'spent',
        });
      });

      entries = entries.sort((a, b) => {
        return b.created - a.created;
      });

      let balance = 0;

      for (let i = entries.length - 1; i >= 0; i--) {
        const entry = entries[i];

        if (entry.type === 'earning') {
          const amount = earningsMap[entry.id].dollarsPerPoint * entry.points;

          balance += amount;
          entries[i].balance = balance;
          entries[i].amount = amount;
        } else {
          const amount = earningsMap[entry.earningsId].dollarsPerPoint * entry.pointsUsed;

          balance -= amount;
          entries[i].balance = balance;
          entries[i].amount = amount;
          entries[i].dollarsPerPoint = earningsMap[entry.earningsId].dollarsPerPoint;
          entries[i].points = entry.pointsUsed;
        }
      }

      setRewardsEntries(entries);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('Unable to retrieve user rewards. Please try again.');
    }
  };

  const verifyEmail = async () => {
    setLoading(true);

    try {
      const verifyUserEmail = firebase.functions().httpsCallable('verifyUserEmail');
      const result = await verifyUserEmail({ userId: user.id });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      setUser({
        ...user,
        emailVerified: true,
      });
      setLoading(false);
      setEmailVerifyConfirmationOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error verifying the user\'s email. Please try again.');
    }
  };

  const submitPasswordReset = async () => {
    setLoading(true);

    try {
      const resetUserPassword = firebase.functions().httpsCallable('resetUserPassword');
      const result = await resetUserPassword({ userId: user.id, password: newPassword });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      setLoading(false);
      closeResetPassword();
      setModalTitle('Success!');
      setModalText('User password has been updated!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error resetting the user\'s password. Please try again.');
    }
  };

  const closeResetPassword = () => {
    setResetPasswordOpen(false);
    setNewPassword('');
  };

  const submitEditAddress = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const dataToUpdate = {
        [editAddressType]: editAddress,
      };

      await db.collection('users').doc(user.id).update(dataToUpdate);

      setUser({
        ...user,
        ...dataToUpdate,
      });
      setLoading(false);
      setEditAddressType('');
    } catch (e) {
      console.log('address error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the address. Please try again.');
    }
  };

  const submitUpdateMembership = async (isActive) => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const dataToUpdate = {
        active: !isActive,
        expires: isActive ? Date.now() : null,
        updatedBy: firebase.auth().currentUser.email,
        updated: moment().valueOf(),
      };

      await db.collection('user-memberships').doc(memberships[editMembershipIndex].id).update(dataToUpdate);

      const membershipsCopy = [ ...memberships ];
      membershipsCopy[editMembershipIndex] = {
        ...membershipsCopy[editMembershipIndex],
        ...dataToUpdate,
      };

      setMemberships(membershipsCopy);
      setLoading(false);
      setEditMembershipIndex(-1);
    } catch (e) {
      setLoading(false);
      setEditMembershipIndex(-1);
      setModalTitle('Error:');
      setModalText('There was an error updating the membership. Please try again.');
    }
  };

  const submitAddMembership = async () => {
    const db = firebase.firestore();
    setAddMembershipOpen(false)
    setLoading(true);

    try {
      const dataToAdd = {
        membership: selectedAddMembership,
        userId: user.id,
        active: true,
        expires: null,
        updatedBy: firebase.auth().currentUser.email,
        updated: moment().valueOf(),
        created: moment().valueOf(),
      };

      const membershipData = await db.collection('user-memberships').add(dataToAdd);

      let name = '';

      allMemberships.forEach(m => {
        if (m.id === selectedAddMembership) {
          name = m.name;
        }
      });

      setMemberships([
        ...memberships,
        {
          ...dataToAdd,
          id: membershipData.id,
          name,
        },
      ]);
      setLoading(false);
    } catch (e) {
      console.log('error in add membership', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the membership. Please try again.');
    }
  };

  const submitUpdateEmail = async () => {
    setLoading(true);

    try {
      const updateUserEmail = firebase.functions().httpsCallable('updateUserEmail');
      const result = await updateUserEmail({ userId: user.id, email: updatedUserEmail });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      await firebase.firestore().collection('users').doc(user.id).update({
        email: updatedUserEmail,
      });

      setUser({
        ...user,
        email: updatedUserEmail,
      });
      setLoading(false);
      setUpdatedUserEmail('');
      setEditEmailOpen(false);
      setModalTitle('Success!');
      setModalText('User email has been updated!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the user\'s email. Please try again.');
    }
  };

  const submitAddStoreCredit = async () => {
    setLoading(true);

    try {
      const amount = parseFloat(storeCreditAmountToAdd);
      const dollarsPerPoint = 0.01;
      const points = amount / dollarsPerPoint;
      const created = moment().valueOf();

      const userRewardsSnapshot = await firebase.firestore().collection('user-rewards').doc(user.id).get();
      const userRewardsData = userRewardsSnapshot.data() || {
        earnings: [],
        spent: [],
      };

      const id = `earnings-${userRewardsData.earnings.length}`;

      userRewardsData.earnings.push({
        id,
        type: 'store-credit',
        points,
        dollarsPerPoint,
        created,
        expires: null,
      });

      await firebase.firestore().collection('user-rewards').doc(user.id).set(userRewardsData);

      const updatedRewardsEntries = [ ...rewardsEntries ];

      let balance = 0;

      if (updatedRewardsEntries.length) {
        balance = updatedRewardsEntries[0].balance;
      }

      balance += amount;

      updatedRewardsEntries.unshift({
        amount,
        balance,
        created,
        dollarsPerPoint,
        expires: null,
        id,
        points,
        type: 'store-credit',
      });

      setRewardsEntries(updatedRewardsEntries);
      setLoading(false);
      setAddStoreCreditOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding store credit to this user. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const renderEntryAmount = (entry) => {
    const isEarning = entry.type === 'earning';
    const sign = isEarning ? '+' : '-';
    const entryColor = isEarning ? '#2BB673' : '#ff3312';

    return (
      <span style={{ color: entryColor }}>
        {sign}${entry.amount.toFixed(2)}
      </span>
    );
  };

  return (
    <div className="User">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/manage-users/users">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Users
          </Button>
        </Link>
      </div>

      {!user ? null :
        <div>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography style={{marginRight: 10}}>
              Name: <strong>{user.userName || 'Name not set'}</strong>
            </Typography>

            <Button 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => { setResetPasswordOpen(true) }}
            >
              Reset Password
            </Button>
          </Toolbar>

          <div className="content-container">
            <Typography variant="body2">
              <strong>ID:</strong> {user.id}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <span><strong>Email:</strong> {user.email} <EditIcon style={{fontSize: 14, cursor: 'pointer'}} onClick={() => {
                setEditEmailOpen(true);
                setUpdatedUserEmail(user.email);
              }} /></span>
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Email Verified:</strong> {user.emailVerified ? 'Yes' : <span>No <span className="verify-email" onClick={() => { setEmailVerifyConfirmationOpen(true) }}>Verify</span></span>}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>User Disabled:</strong> {user.disabled ? 'Yes' : 'No'}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>User Created:</strong> {(user.metadata && user.metadata.creationTime) ? moment(user.metadata.creationTime).format('lll') : 'Not specified'}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Last Sign In:</strong> {(user.metadata && user.metadata.lastSignInTime) ? moment(user.metadata.lastSignInTime).format('lll') : 'Not specified'}
            </Typography>

            <div className="addresses-container">
              <Typography variant="body1" style={{marginTop: 20, marginBottom: 10}}>
                <strong>Addresses</strong>
              </Typography>
              <div className="addresses">
                <div className="address-container">
                  <Typography variant="body2" style={{marginBottom: 10}}>
                    <strong>Billing: </strong>
                    <EditIcon
                      style={{fontSize: 14, cursor: 'pointer'}}
                      onClick={() => {
                        setEditAddressType('billing');
                        setEditAddress({
                          ...user.billing,
                        });
                      }}
                    />
                  </Typography>
                  <Typography variant="body2">{user.billing.firstName} {user.billing.lastName}</Typography>
                  <Typography variant="body2">{user.billing.address} {user.billing.addressSecondary ? user.billing.addressSecondary : ''}</Typography>
                  <Typography variant="body2">{!user.billing.city ? null : <span>{user.billing.city}, {user.billing.state} {user.billing.zip}</span>}</Typography>
                  <Typography variant="body2">{user.billing.country}</Typography>
                </div>
                <div className="address-container">
                  <Typography variant="body2" style={{marginBottom: 10}}>
                    <strong>Shipping: </strong>
                    <EditIcon
                      style={{fontSize: 14, cursor: 'pointer'}}
                      onClick={() => {
                        setEditAddressType('shipping');
                        setEditAddress({
                          ...user.shipping,
                        });
                      }}
                    />
                  </Typography>
                  <Typography variant="body2">{user.shipping.firstName} {user.shipping.lastName}</Typography>
                  <Typography variant="body2">{user.shipping.address} {user.shipping.addressSecondary ? user.shipping.addressSecondary : ''}</Typography>
                  <Typography variant="body2">{!user.shipping.city ? null : <span>{user.shipping.city}, {user.shipping.state} {user.shipping.zip}</span>}</Typography>
                  <Typography variant="body2">{user.shipping.country}</Typography>
                </div>
              </div>
            </div>

            <div className="memberships-container">
              <Typography variant="body1" style={{marginTop: 20, marginBottom: 10}}>
                <strong>Memberships</strong>
              </Typography>

              {memberships.map((m, i) => {
                return (
                  <div key={`membership-${i}`} className="membership-row">
                    <div>
                      <span>{m.name} ({m.active ? 'Active' : 'Inactive'}) </span>
                      <EditIcon
                        style={{fontSize: 14, cursor: 'pointer'}}
                        onClick={() => {
                          setEditMembershipIndex(i);
                        }}
                      />
                    </div>
                  </div>
                );
              })}

              <Button
                variant="contained"
                size="small"
                onClick={async () => {
                  if (!allMemberships.length) {
                    setLoading(true);
                    try {
                      const snapshot = await firebase.firestore().collection('memberships').get();
                      const membershipData = snapshot.docs.map(doc => {
                        return {
                          id: doc.id,
                          name: doc.data().name,
                        };
                      });

                      setAllMemberships(membershipData);
                      setSelectedAddMembership(membershipData[0].id);
                      setLoading(false);
                    } catch (e) {
                      setLoading(false);
                      setModalTitle('Error:');
                      setModalText('There was an error fetching memberships. Please try again.');
                    }
                  } else {
                    setSelectedAddMembership(allMemberships[0].id);
                  }

                  setAddMembershipOpen(true);
                }}
                color="primary"
              >
                Add Membership
              </Button>
            </div>

            <div style={{ marginTop: 30 }}>
              <Divider/>

              <div>
                <div>
                  <Typography variant="h6" style={{marginBottom: 10, marginTop: 20}}>
                    <strong>Orders:</strong>
                  </Typography>

                  <TableContainer component={Paper}>
                    <Table aria-label="orders table">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Order</strong></TableCell>
                          <TableCell><strong>Status</strong></TableCell>
                          <TableCell><strong>User Email</strong></TableCell>
                          <TableCell><strong>User ID</strong></TableCell>
                          <TableCell><strong>Amount</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders.map((o, i) => (
                          <TableRow key={o.id}>
                            <TableCell>
                              <div><strong>ID:</strong> <Link to={`/shop/orders/${o.id}`}><span style={{color: 'blue'}}>{o.id}</span></Link></div>
                              <div style={{marginTop: 5}}><strong>Date:</strong> {moment(o.created.seconds * 1000).format('lll')}</div>
                            </TableCell>
                            <TableCell>{o.status}</TableCell>
                            <TableCell>{o.userEmail}</TableCell>
                            <TableCell>{o.userId}</TableCell>
                            <TableCell>${o.total}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 30 }}>
              <Divider />

              <div>
                <Typography variant="h6" style={{marginBottom: 10, marginTop: 20}}>
                  <strong>Subscriptions:</strong>
                </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="subscriptions table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>ID</strong></TableCell>
                        <TableCell><strong>Created</strong></TableCell>
                        <TableCell><strong>Next Payment Date</strong></TableCell>
                        <TableCell><strong>Interval</strong></TableCell>
                        <TableCell align="right"><strong>Total</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriptions.map((s, i) => (
                        <TableRow key={`subscription-${i}`}>
                          <TableCell>
                            <Link to={`/shop/subscriptions/${s.id}`}>
                              <span style={{color: 'blue'}}>{s.id}</span>
                            </Link>
                          </TableCell>
                          <TableCell>{moment(s.created.seconds * 1000).format('lll')}</TableCell>
                          <TableCell>{s.active ? moment(s.nextPaymentDate).format('MMM Do, YYYY') : 'Suspended'}</TableCell>
                          <TableCell>Every {s.interval} {`${s.period}${s.interval === 1 ? '' : 's'}`}</TableCell>
                          <TableCell align="right">${s.total.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div style={{ marginTop: 30 }}>
              <Divider />

              <div>
                <div className="rewards-header-container">
                  <Typography variant="h6" style={{marginBottom: 10, marginTop: 20}}>
                    <strong>Rewards:</strong>
                  </Typography>

                  <Button 
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setStoreCreditAmountToAdd('');
                      setAddStoreCreditOpen(true);
                    }}
                  >
                    Add Store Credit
                  </Button>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="rewards table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Date</strong></TableCell>
                        <TableCell><strong>Points</strong></TableCell>
                        <TableCell><strong>Dollars per Point</strong></TableCell>
                        <TableCell><strong>Amount</strong></TableCell>
                        <TableCell><strong>Balance</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rewardsEntries.map((entry, i) => {
                        return (
                          <TableRow key={`reward-entry-${i}`}>
                            <TableCell>{moment(entry.created).format('lll')}</TableCell>
                            <TableCell>{Math.round(entry.points)}</TableCell>
                            <TableCell>${entry.dollarsPerPoint}</TableCell>
                            <TableCell>{renderEntryAmount(entry)}</TableCell>
                            <TableCell>${entry.balance.toFixed(2)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      }

      <Dialog fullWidth maxWidth="sm" open={addMembershipOpen} onClose={() => { setAddMembershipOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Membership</DialogTitle>
        <DialogContent>
          <div>
            <FormControl variant="outlined" margin="dense" className="day-text-field">
              <InputLabel>Select Membership</InputLabel>
              <Select
                value={selectedAddMembership}
                onChange={(e) => {
                  setSelectedAddMembership(e.target.value);
                }}
                label="Select Membership"
              >
                {allMemberships.map(m => {
                  return <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitAddMembership} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setAddMembershipOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={editMembershipIndex !== -1} onClose={() => { setEditMembershipIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Edit Membership</DialogTitle>
        <DialogContent>
          {!memberships[editMembershipIndex] ? null :
            <div>
              <Typography variant="body2">
                <strong>Status:</strong> {memberships[editMembershipIndex].active ? 'Active' : 'Inactive'}
              </Typography>
              {memberships[editMembershipIndex].active ? null :
                <Typography variant="body2" style={{marginTop: 10}}>
                  <strong>Expires:</strong> {moment(memberships[editMembershipIndex].expires).format('lll')}
                </Typography>
              }
              <Typography variant="body2" style={{marginTop: 10}}>
                <strong>Created:</strong> {moment(memberships[editMembershipIndex].created).format('lll')}
              </Typography>
              <Typography variant="body2" style={{marginTop: 10}}>
                <strong>Updated:</strong> {moment(memberships[editMembershipIndex].updated).format('lll')}
              </Typography>
              <Typography variant="body2" style={{marginTop: 10}}>
                <strong>Last Updated By:</strong> {memberships[editMembershipIndex].updatedBy}
              </Typography>
            </div>
          }
        </DialogContent>
        <DialogActions>
          {!memberships[editMembershipIndex] ? null :
            <Button variant="contained" onClick={() => { submitUpdateMembership(memberships[editMembershipIndex].active) }} color="secondary">
              {memberships[editMembershipIndex].active ? 'Deactivate' : 'Activate'}
            </Button>
          }
          <Button variant="contained" onClick={() => { setEditMembershipIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={emailVerifyConfirmationOpen} onClose={() => { setEmailVerifyConfirmationOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <Typography>Verify this user's email address?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={verifyEmail} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setEmailVerifyConfirmationOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={resetPasswordOpen} onClose={closeResetPassword} TransitionComponent={Transition}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <div>
            <FormControl variant="outlined" className="day-text-field">
              <InputLabel htmlFor="outlined-adornment-amount">New Password</InputLabel>
              <OutlinedInput
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                label="New Password"
                variant="outlined"
                type={passwordInputType}
                className="day-text-field"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        const type = passwordInputType === 'text' ? 'password' : 'text';
                        setPasswordInputType(type);
                      }}
                      onMouseDown={(event) => { event.preventDefault() }}
                    >
                      {passwordInputType === 'text' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={!newPassword} variant="contained" onClick={submitPasswordReset} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={closeResetPassword} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!editAddressType} onClose={() => { setEditAddressType('') }} TransitionComponent={Transition}>
        <DialogTitle>Edit {editAddressType} address</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={editAddress.firstName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                firstName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Last Name"
            value={editAddress.lastName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                lastName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Primary Address"
            value={editAddress.address}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                address: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Secondary Address"
            value={editAddress.addressSecondary}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                addressSecondary: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="City"
            value={editAddress.city}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                city: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="State"
            value={editAddress.state}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                state: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Zip"
            value={editAddress.zip}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                zip: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Country"
            value={editAddress.country}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                country: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitEditAddress} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditAddressType('') }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={editEmailOpen} onClose={() => { setEditEmailOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Update User Email</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={updatedUserEmail}
            onChange={(e) => {
              setUpdatedUserEmail(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitUpdateEmail} color="primary" disabled={!emailRegex.test(updatedUserEmail)}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditEmailOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addStoreCreditOpen} onClose={() => { setAddStoreCreditOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Store Credit</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Amount</InputLabel>
            <OutlinedInput
              value={storeCreditAmountToAdd}
              onChange={(e) => { setStoreCreditAmountToAdd(e.target.value) }}
              margin="dense"
              label="Amount"
              variant="outlined"
              type="number"
              className="day-text-field"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitAddStoreCredit} color="primary" disabled={!storeCreditAmountToAdd}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddStoreCreditOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default User;
