import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  ListItemText,
  ListItem,
  Select,
  Slide,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import './globalBlocks.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GlobalBlocks(props) {
  const [loading, setLoading] = useState(true);
  const [modalText, setModalText] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [pages, setPages] = useState([]);
  const [template, setTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  useEffect(() => {
    fetchPages(); // TODO:  paginate this
  }, []);

  const fetchPages = async () => {
    const db = firebase.firestore();

    try {
      const templatesQuerySnapshot = await firebase.firestore().collection('global-templates').where('global', '==', true).get();
      const querySnapshot = await db.collection('pages').get();
      const content = querySnapshot.docs.map(doc => {
        const docData = doc.data();

        return {
          ...docData,
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created - a.created;
      });
      const templates = templatesQuerySnapshot.docs.map(doc => {
        const docData = doc.data();
        console.log(docData);

        return {
          ...docData,
          id: doc.id,
        };
      });

      if (templates.length) {
        setSelectedTemplate(templates[0].id);
      }

      setPages(content);
      setTemplate(templates);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred fetching templates. Refresh the page and try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div className="Pages">
      {renderLoading()}
      <Card style={{ marginTop: 20 }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="product-group-discount">
              Global Blocks
            </InputLabel>
            <Select onChange={(evt) => setSelectedTemplate(evt.target.value)} value={selectedTemplate}>
              <MenuItem value="">
                <em>Select Template</em>
              </MenuItem>
              {template.map((t, i) => {
                return <MenuItem key={i} value={t.id}>{t.title}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Toolbar>
        {selectedTemplate === '' ? null : pages.map((p, i) => {
          return (
            p.sharedTemplates.includes(selectedTemplate) ?
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={p.title}
                    secondary={
                      <span>
                        <span style={{ marginRight: 10 }}><strong>ID:</strong> {p.id}</span>
                        <span style={{ marginRight: 10 }}><strong>Last updated:</strong> {moment(p.updated).calendar()}</span>
                        <span style={{ marginRight: 10 }}><strong>Last updated by:</strong> {p.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                </ListItem>
              </div> : null
          );
        })}
      </Card>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GlobalBlocks;
