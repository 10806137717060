import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { ColorPicker } from '../../../../../colorPicker/colorPicker';
import { useProductsCategoriesFields } from './useProductsCategoriesFields';

export const ProductsCategoriesItems = ({ control, errors }) => {
  const {
    productsCategoriesItems,
    appendProductCategoryItem,
    removeProductCategoryItem,
    moveProductCategoryItem,
  } = useProductsCategoriesFields(control);

  return (
    <div className="section-container" style={{ margin: 0 }}>
      <div className="section-label">Categories Items</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 8px',
        }}
      >
        {productsCategoriesItems?.map((item, index) => (
          <Card
            key={item.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              alignItems: 'center',
            }}
            variant="outlined"
          >
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>
                <strong>Item {index + 1}</strong>
              </span>

              <Box
                sx={{
                  gap: '8px',
                  display: 'flex',
                }}
              >
                {index !== productsCategoriesItems.length - 1 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveProductCategoryItem(index, index + 1)}
                  >
                    <ArrowDownIcon />
                  </IconButton>
                ) : null}

                {index !== 0 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveProductCategoryItem(index, index - 1)}
                  >
                    <ArrowUpIcon />
                  </IconButton>
                ) : null}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  {index === productsCategoriesItems.length - 1 &&
                  productsCategoriesItems.length < 4 ? (
                    <IconButton
                      color="inherit"
                      onClick={() => appendProductCategoryItem()}
                      aria-label="Add Menu Item"
                    >
                      <AddIcon />
                    </IconButton>
                  ) : null}

                  {index !== 0 ? (
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      onClick={() => removeProductCategoryItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
            </Toolbar>

            <Divider />
            <CardContent>
              <Controller
                name={`productCategoriesSection.categories.items.${index}.name`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Title"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors?.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.name,
                      )}
                      helperText={
                        errors.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.name?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`productCategoriesSection.categories.items.${index}.description`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Description"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.description,
                      )}
                      helperText={
                        errors.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.description?.message
                      }
                    />
                  );
                }}
              />

              <Controller
                name={`productCategoriesSection.categories.items.${index}.color`}
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      label="Text Color"
                      field={field}
                      error={Boolean(
                        errors.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.color,
                      )}
                      helperText={
                        errors.productCategoriesSection?.categories?.items?.[
                          index
                        ]?.color?.message
                      }
                    />
                  );
                }}
              />
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
};
