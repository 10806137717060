import React from 'react';

function Link({ content, children, customStyles }) {
  return (
    <>
      <a
        style={{
          position: 'relative',
          ...content.styles,
          backgroundImage: content.styles.backgroundImage ? `url(${content.styles.backgroundImage})` : '',
          ...customStyles,
          flexDirection: 'column',
        }}
        className={content.customClasses}
        id={content.htmlID}
        href={content.href}
        target="_blank"
      >
        <div style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: content.backgroundOverlayColor || 'rgba(0, 0, 0, 0)'
        }}></div>
        {children}
      </a>
    </>
  );
}

export default Link;
