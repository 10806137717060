import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { connect } from 'react-redux';
import {
  CircularProgress,
  Slide,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Toolbar,
  Divider,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { setSubscriptionsCount } from '../../../actions';
import './subscriptions.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function Subscriptions(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchItem, setSearchItem] = useState('id');
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedResults, setSearchedResults] = useState([]);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    const db = firebase.firestore();

    try {
      let subscriptionsCount = 0;

      if (!props.counts.subscriptionsCount) {
        const queries = [];

        for (let i = 0; i < 10; i++) {
          queries.push(db.collection('counters').doc(`subscriptions-${i}`).get());
        }

        const result = await Promise.all(queries);

        result.forEach(r => {
          subscriptionsCount += r.data().count;
        });

        props.setSubscriptionsCount(subscriptionsCount);
      } else {
        subscriptionsCount = props.counts.subscriptionsCount;
      }

      console.log('subscriptionsCount', subscriptionsCount);

      setNumberOfPages(Math.ceil(subscriptionsCount / 10));

      const subscriptionsSnapshot = await db.collection('subscriptions').orderBy('created', 'desc').limit(pageCount).get();
      const mappedSubscriptions = subscriptionsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setSubscriptions(mappedSubscriptions);
      setFirst(subscriptionsSnapshot.docs[0]);
      setLast(subscriptionsSnapshot.docs[subscriptionsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching subscriptions. Please try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let subscriptionsSnapshot;
      let newPage = page;

      if (value === 'next') {
        subscriptionsSnapshot = await db.collection('subscriptions').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        subscriptionsSnapshot = await db.collection('subscriptions').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedSubscriptions = subscriptionsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setSubscriptions(mappedSubscriptions);
      setFirst(subscriptionsSnapshot.docs[0]);
      setLast(subscriptionsSnapshot.docs[subscriptionsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving subscriptions. Please try again.');
    }
  };

  const search = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      let foundSubscriptions = [];
      let searchedItemText = '';

      if (searchItem === 'id') {
        const subscriptionSnapshot = await db.collection('subscriptions').doc(searchText).get();
        const data = subscriptionSnapshot.data();

        if (data) {
          foundSubscriptions.push({
            ...data,
            id: subscriptionSnapshot.id,
          });
        }

        searchedItemText = 'Subscription ID';
      } else if (searchItem === 'email') {
        const subscriptionsSnapshot = await db.collection('subscriptions').where('email', '==', searchText).get();
        const subscriptionsEmailSnapshot = await db.collection('subscriptions').where('email', '==', searchText).get();

        const subscriptionIds = [];

        foundSubscriptions = subscriptionsSnapshot.docs.map(doc => {
          subscriptionIds.push(doc.id);

          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        subscriptionsEmailSnapshot.docs.forEach(doc => {
          if (!subscriptionIds.includes(doc.id)) {
            foundSubscriptions.push({
              ...doc.data(),
              id: doc.id,
            });
          }
        });

        searchedItemText = 'Email';
      } else if (searchItem === 'userId') {
        const subscriptionsSnapshot = await db.collection('subscriptions').where('userId', '==', searchText).get();

        foundSubscriptions = subscriptionsSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        searchedItemText = 'User ID';
      }

      setSearchedResults(foundSubscriptions);
      setSearchTerm(searchText);
      setSearchedItem(searchedItemText);
      setLoading(false);
      setSearchOpen(false);
    } catch (e) {
      console.log('search error', e);
      setLoading(false);
      setSearchOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error performing the subscriptions search. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Subscriptions">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Subscriptions
          </Typography>
          {!searchTerm ?
            <IconButton edge="start" color="inherit" onClick={() => {
              setSearchItem('id');
              setSearchText('');
              setSearchOpen(true);
            }} aria-label="Delete">
              <SearchIcon />
            </IconButton> :
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchedItem}: {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                setSearchedItem('');
                setSearchedResults([]);
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm ?
          <div>
            {!searchedResults.length ?
              <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
                No results match your search
              </div> :
              <TableContainer component={Paper}>
                <Table aria-label="search subscriptions table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Status</strong></TableCell>
                      <TableCell><strong>ID</strong></TableCell>
                      <TableCell><strong>User</strong></TableCell>
                      <TableCell><strong>Total</strong></TableCell>
                      <TableCell><strong>Start Date:</strong></TableCell>
                      <TableCell><strong>Next Renewal:</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResults.map((s, i) => (
                      <TableRow key={s.id}>
                        <TableCell><span style={{color: s.active ? '#7ED242' : 'red'}}>{s.active ? 'Active' : 'Inactive'}</span></TableCell>
                        <TableCell>{s.id}</TableCell>
                        <TableCell>
                          <div><strong>Email:</strong> {s.email}</div>
                          <div style={{marginTop: 5}}><strong>ID:</strong> {s.userId}</div>
                        </TableCell>
                        <TableCell>
                          ${(+s.total).toFixed(2)}
                          <small> every {s.interval === 1 ? '' : s.interval} {s.period}{s.interval === 1 ? '' : 's'}</small>
                        </TableCell>
                        <TableCell>{moment(s.created.seconds * 1000).format('lll')}</TableCell>
                        <TableCell>{moment(s.nextPaymentDate).format('MMM Do, YYYY')}</TableCell>
                        <TableCell padding="checkbox">
                          <Link to={`/shop/subscriptions/${s.id}`}>
                            <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div> :
          <TableContainer component={Paper}>
            <Table aria-label="subscriptions table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell><strong>User</strong></TableCell>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell><strong>Start Date:</strong></TableCell>
                  <TableCell><strong>Next Renewal:</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((s, i) => (
                  <TableRow key={s.id}>
                    <TableCell><span style={{color: s.active ? '#7ED242' : 'red'}}>{s.active ? 'Active' : 'Inactive'}</span></TableCell>
                    <TableCell>{s.id}</TableCell>
                    <TableCell>
                      <div><strong>Email:</strong> {s.email}</div>
                      <div style={{marginTop: 5}}><strong>ID:</strong> {s.userId}</div>
                    </TableCell>
                    <TableCell>
                      ${(+s.total).toFixed(2)}
                      <small> every {s.interval === 1 ? '' : s.interval} {s.period}{s.interval === 1 ? '' : 's'}</small>
                    </TableCell>
                    <TableCell>{moment(s.created.seconds * 1000).format('lll')}</TableCell>
                    <TableCell>{moment(s.nextPaymentDate).format('MMM Do, YYYY')}</TableCell>
                    <TableCell padding="checkbox">
                      <Link to={`/shop/subscriptions/${s.id}`}>
                        <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Divider/>

        {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Subscriptions</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <FormControl variant="outlined" margin="dense" className="day-text-field">
              <InputLabel>Search Field</InputLabel>
              <Select
                value={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value)
                }}
                label="Search Field"
              >
                <MenuItem value={'id'}>Subscription ID</MenuItem>
                <MenuItem value={'email'}>Email</MenuItem>
                <MenuItem value={'userId'}>User ID</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counts: state.counts,
  };
};

export default connect(mapStateToProps, { setSubscriptionsCount })(Subscriptions);
