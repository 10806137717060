import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  Input,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ChipInput from 'material-ui-chip-input';

import DeletableChip from '../../../shared/deletableChip';
import './editFreeProduct.scss';

const ruleTypes = [
  'Always',
  'Coupon Code',
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
  'Cart Contains Subscription to One of These Products',
  'Cart Contains Subscription to Product in These Categories',
  'Cart Contains Subscription to Product with These Tags',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Subtotal is Less Than',
  'Subtotal is Greater Than',
];
const showProductTypes = [
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Cart Contains Subscription to One of These Products',
];
const showCategoryTypes = [
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
  'Cart Contains Subscription to Product in These Categories',
];
const showTagTypes = [
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
  'Cart Contains Subscription to Product with These Tags',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditFreeProduct(props) {
  const [product, setProduct] = useState(props.product);

  return (
    <div className="EditFeaturedProduct">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Free Product
          </Typography>
          <Button color="inherit" onClick={() => { props.save(product) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={product.active}
              onChange={(e) => {
                setProduct({
                  ...product,
                  active: e.target.checked,
                });
              }}
              name="active"
              color="primary"
            />
          }
          label="Active"
        />

        <FormControlLabel
          control={
            <Switch
              checked={product.automaticallyAddToCart}
              onChange={(e) => {
                setProduct({
                  ...product,
                  automaticallyAddToCart: e.target.checked,
                });
              }}
              name="automaticallyAddToCart"
              color="primary"
            />
          }
          label="Automatically Add To Cart"
        />

        <FormControlLabel
          control={
            <Switch
              checked={product.addToSubscription || false}
              onChange={(e) => {
                const updated = {
                  ...product,
                  addToSubscription: e.target.checked,
                };

                if (!e.target.checked && product.subscriptionOnly) {
                  updated.subscriptionOnly = false;
                }

                setProduct(updated);
              }}
              name="addToSubscription"
              color="primary"
            />
          }
          label="Add to Subscription"
        />

        {!product.addToSubscription ? null :
          <FormControlLabel
            control={
              <Switch
                checked={product.subscriptionOnly || false}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    subscriptionOnly: e.target.checked,
                  });
                }}
                name="subscriptionOnly"
                color="primary"
              />
            }
            label="Subscription Only"
          />
        }

        <FormControlLabel
          control={
            <Switch
              checked={product.disableOnWebsite || false}
              onChange={(e) => {
                setProduct({
                  ...product,
                  disableOnWebsite: e.target.checked,
                });
              }}
              name="disableOnWebsite"
              color="primary"
            />
          }
          label="Disable on Website"
        />

        <FormControl style={{width: '100%', marginTop: 20}}>
          <InputLabel>Product Options</InputLabel>
          <Select
            multiple
            value={product.productOptions}
            onChange={(e) => {
              setProduct({
                ...product,
                productOptions: e.target.value,
              });
            }}
            input={<Input />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value.id}
                    label={value.name}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = product.productOptions.indexOf(value);

                      const productOptions = [ ...product.productOptions ];

                      productOptions.splice(index, 1);

                      setProduct({
                        ...product,
                        productOptions,
                      });
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.simplifiedProducts.filter(simpleProduct => {
              const found = product.productOptions.find(p => p.id === simpleProduct.id);
              return !found;
            }).map(simpleProduct => (
              <MenuItem key={simpleProduct.id} value={simpleProduct}>
                {simpleProduct.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="section-container">
          <div className="section-label">Rules</div>
          <div className="rules-button-row">
            <Button color="primary" onClick={() => {
              const rules = [ ...product.rules ];

              rules.push({
                type: 'group',
                rules: [{
                  type: ruleTypes[0],
                  products: [],
                  cartSubTotal: '',
                  tags: [],
                  categories: [],
                  couponCodes: [],
                }],
              });

              setProduct({
                ...product,
                rules,
              });
            }} variant="contained">
              Add Rule Group
            </Button>
          </div>

          {product.rules.map((group, i) => {
            return (
              <div key={`rule-group-${i}`}>
                {i !== 0 && <div className="condition-text">OR</div>}

                <div className="rule-group">
                  {product.rules.length > 1 &&
                    <Button color="secondary" size="small" onClick={() => {
                      const rules = [ ...product.rules ];

                      rules.splice(i, 1);

                      setProduct({
                        ...product,
                        rules,
                      });
                    }} variant="contained">
                      Remove Group
                    </Button>
                  }
                  {group.rules.map((rule, j) => {
                    return (
                      <div key={`rule-${i}-${j}`}>
                        {j !== 0 && <div className="condition-text">AND</div>}
                        <div className="form-row">
                          {group.rules.length > 1 &&
                            <IconButton
                              onClick={() => {
                                const rules = [ ...group.rules ];

                                rules.splice(j, 1);

                                const productRules = [ ...product.rules ];
                                productRules[i].rules = rules;

                                setProduct({
                                  ...product,
                                  rules: productRules,
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                            <InputLabel>Rule Type</InputLabel>
                            <Select
                              value={rule.type}
                              onChange={(e) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  type: e.target.value,
                                };

                                const productRules = [ ...product.rules ];
                                productRules[i].rules = rules;

                                setProduct({
                                  ...product,
                                  rules: productRules,
                                });
                              }}
                              label="Rule Type"
                            >
                              {ruleTypes.map((ruleType, k) => {
                                return <MenuItem key={`rule-type-${i}-${j}-${k}`} value={ruleType}>{ruleType}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>

                          {rule.type.includes('Subtotal') &&
                            <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                              <OutlinedInput
                                value={rule.cartSubTotal}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    cartSubTotal: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                margin="dense"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{maxWidth: 180, marginRight: 15}}
                              />
                            </FormControl>
                          }

                          {(showProductTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Products</InputLabel>
                              <Select
                                multiple
                                value={rule.products}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    products: e.target.value,
                                  };

                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;

                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value.id}
                                        label={value.name}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.products.indexOf(value);

                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const products = [ ...rule.products ];

                                          products.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            products,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.simplifiedProducts.filter(product => {
                                  const found = rule.products.find(p => p.id === product.id);
                                  return !found;
                                }).map(product => (
                                  <MenuItem key={product.id} value={product}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showCategoryTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Categories</InputLabel>
                              <Select
                                multiple
                                value={rule.categories}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    categories: e.target.value,
                                  };

                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;

                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.categories.indexOf(value);

                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const categories = [ ...rule.categories ];

                                          categories.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            categories,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.productCategories.filter(category => {
                                  return !rule.categories.includes(category);
                                }).map(category => (
                                  <MenuItem key={category} value={category}>
                                    {category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showTagTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Tags</InputLabel>
                              <Select
                                multiple
                                value={rule.tags}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    tags: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.tags.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const tags = [ ...rule.tags ];

                                          tags.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            tags,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.productTags.filter(tag => {
                                  return !rule.tags.includes(tag);
                                }).map(tag => (
                                  <MenuItem key={tag} value={tag}>
                                    {tag}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(rule.type === 'Coupon Code') &&
                            <ChipInput
                              style={{flex: 1}}
                              defaultValue={rule.couponCodes}
                              onChange={(chips) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  couponCodes: chips,
                                };
              
                                const productRules = [ ...product.rules ];
                                productRules[i].rules = rules;
            
                                setProduct({
                                  ...product,
                                  rules: productRules,
                                });
                              }}
                              margin="dense"
                              allowDuplicates={false}
                              fullWidth={true}
                              label="Coupon Codes"
                              helperText='Press "enter" after each code as you type'
                            />
                          }
                        </div>
                      </div>
                    );
                  })}

                  <Button color="primary" size="small" onClick={() => {
                    const rules = [ ...group.rules ];

                    rules.push({
                      type: ruleTypes[0],
                      products: [],
                      cartSubTotal: 0,
                      tags: [],
                      categories: [],
                      couponCodes: [],
                    });

                    const productRules = [ ...product.rules ];
                    productRules[i].rules = rules;

                    setProduct({
                      ...product,
                      rules: productRules,
                    });
                  }} variant="contained">
                    Add Condition
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditFreeProduct;
