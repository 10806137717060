import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './editGroup.scss';
import { config } from '../../../../config';
import DeletableChip from '../../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditGroup({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [group, setGroup] = useState({
    id: 0,
    live: false,
    isDefault: false,
    name: '',
    description: '',
    imageUrl: '',
    topics: [],
    created: new Date(),
    deleted: null,
  });
  const [topics, setTopics] = useState([]);
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [newTopicName, setNewTopicName] = useState('');

  useEffect(() => {
    fetchGroup();
  }, []);

  const fetchGroup = async () => {
    try {
      const id = match.params.id;

      if (id === undefined) {
        history.replace('/community/groups');
        return;
      }

      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios(`${config.gateway}/community-service/api/v1/groups/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (!response.data.id) {
        history.replace('/community/groups');
        return;
      }

      const topicsResponse = await axios(`${config.gateway}/community-service/api/v1/topics`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setGroup(response.data);
      setTopics(topicsResponse.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred fetching post content. Refresh the page and try again.');
    }
  };

  const saveGroup = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios(`${config.gateway}/community-service/api/v1/groups/${group.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          ...group,
          topics: group.topics.map(topic => topic.id),
        },
      });

      setLoading(false);
      setModalTitle('Success!');
      setModalText('This group was saved successfully.');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred saving this post. Refresh the page and try again.');
    }
  };

  const addTopic = async () => {
    const topicToAdd = newTopicName.trim();
    const existingTopic = topics.find(topic => topic.title === topicToAdd);

    if (existingTopic) {
      setModalTitle('Notice!');
      setModalText('A topic with this name already exists.');
      return;
    }

    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios(`${config.gateway}/community-service/api/v1/topics`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          title: topicToAdd,
        },
      });

      const topicsCopy = [ ...topics, response.data ];

      setTopics(topicsCopy);
      setGroup({
        ...group,
        topics: [ ...topics, response.data ],
      });
      setLoading(false);
      setShowAddTopic(false);
      setNewTopicName('');
      setModalTitle('Success!');
      setModalText('The topic was created successfully.');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding this topic. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditGroup">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/community/groups">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Groups
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>{group.name || 'Unnamed Group'}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={saveGroup}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <FormControlLabel
            control={
              <Switch
                checked={group.live}
                onChange={(e) => {
                  setGroup({
                    ...group,
                    live: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Live"
          />

          <FormControlLabel
            control={
              <Switch
                checked={group.isDefault}
                onChange={(e) => {
                  setGroup({
                    ...group,
                    isDefault: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Default Group"
          />

          <div className="form-row">
            <TextField
              label="Name"
              value={group.name}
              onChange={(e) => {
                setGroup({
                  ...group,
                  name: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Description"
              value={group.description}
              onChange={(e) => {
                setGroup({
                  ...group,
                  description: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Image URL"
              value={group.imageUrl}
              onChange={(e) => {
                setGroup({
                  ...group,
                  imageUrl: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="topics-container">
            <FormControl style={{ marginTop: -22, width: '99%' }}>
              <InputLabel htmlFor="select-multiple-chip">Topics</InputLabel>
              <Select
                multiple
                value={group.topics}
                onChange={(e) => {
                  setGroup({
                    ...group,
                    topics: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <DeletableChip
                        key={value.id}
                        label={value.title}
                        style={{ margin: 2 }}
                        color="primary"
                        onDelete={() => {
                          const index = group.topics.findIndex(topic => topic.id === value.id);

                          if (index === -1) {
                            return;
                          }

                          const topicsCopy = group.topics.slice();
                          topicsCopy.splice(index, 1);
                          setGroup({
                            ...group,
                            topics: topicsCopy,
                          });
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {topics.filter(topic => {
                  const found = group.topics.find(t => t.id === topic.id);
                  return !found;
                }).map(topic => (
                  <MenuItem key={topic.id} value={topic}>
                    {topic.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="add-topic-button" onClick={() => {
              setShowAddTopic(true);
              setNewTopicName('');
            }}><small>+ Add new topic</small></div>
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={showAddTopic} onClose={() => setShowAddTopic(false)} TransitionComponent={Transition}>
        <DialogTitle>Add Topic</DialogTitle>
        <DialogContent>
          <TextField
            label="Topic Name"
            value={newTopicName}
            onChange={(e) => setNewTopicName(e.target.value)}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => addTopic()} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => setShowAddTopic(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditGroup;
