import React, { useState } from 'react';
import {
  Button,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import './quizModule.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function QuizModule({ questions, passingScore, onChange }) {
  const [deleteIndex, setDeleteIndex] = useState(-1);

  return (
    <div className="QuizModule">
      <TextField
        style={{marginTop: 20}}
        label="Correct Answers Required to Pass"
        value={passingScore}
        onChange={(e) => {
          let strVal = e.target.value;

          if (strVal === '') {
            onChange('passingScore', '');
          } else {
            let value = Math.round(strVal);

            if (value < 0) {
              value = value * -1;
            }

            if (value === 0) {
              value = '';
            }

            onChange('passingScore', value);
          }
        }}
        margin="dense"
        type="number"
        variant="outlined"
        className="day-text-field condensed-text-field"
      />

      <div className="section-container">
        <div className="section-label">Quiz Questions</div>
        <div className="right-container">
          <Button
            variant="contained"
            onClick={() => {
              const updatedQuestions = [ ...questions ];

              updatedQuestions.push({
                id: Date.now(),
                type: 'multiple-choice',
                question: '',
                answers: [{
                  answer: 'Answer 1',
                  correct: true,
                }],
              });

              onChange('questions', updatedQuestions);
            }}
            color="primary"
          >
            Add Question
          </Button>
        </div>

        <div>
          {questions.map((question, i) => {
            return (
              <div key={`question-${i}`}>
                <Divider/>

                <div className="question-container">
                  <div className="question-inputs-container">
                    <TextField
                      label={`Question ${i + 1}`}
                      value={question.question}
                      onChange={(e) => {
                        const updatedQuestions = [ ...questions ];
                        const updatedQuestion = {
                          ...updatedQuestions[i],
                          question: e.target.value,
                        };

                        updatedQuestions[i] = updatedQuestion;

                        onChange('questions', updatedQuestions);
                      }}
                      margin="dense"
                      variant="outlined"
                      className="day-text-field"
                    />

                    <div className="answers-container">
                      {question.answers.map((answer, j) => {
                        return (
                          <div className="answer-container" key={`answer-${i}-${j}`}>
                            <p className="answer-letter">
                              {String.fromCharCode(j + 1 + 64)}.
                            </p>
                            <TextField
                              label={`Answer ${j + 1}`}
                              value={answer.answer}
                              onChange={(e) => {
                                const answers = [ ...question.answers ];

                                const updatedAnswer = {
                                  ...answer,
                                  answer: e.target.value,
                                };

                                answers[j] = updatedAnswer;

                                const updatedQuestions = [ ...questions ];
                                const updatedQuestion = {
                                  ...updatedQuestions[i],
                                  answers,
                                };

                                updatedQuestions[i] = updatedQuestion;

                                onChange('questions', updatedQuestions);
                              }}
                              margin="dense"
                              variant="outlined"
                              className="day-text-field"
                            />

                            <div className="question-button-container">
                              {j !== 0 ? null :
                                <p className="correct-text">Correct</p>
                              }

                              <Checkbox
                                checked={answer.correct}
                                onChange={(e) => {
                                  if (question.answers.length < 2 || !e.target.checked) {
                                    return;
                                  }

                                  const answers = question.answers.map((a, k) => {
                                    return {
                                      ...a,
                                      correct: k === j,
                                    };
                                  });

                                  const updatedQuestions = [ ...questions ];
                                  const updatedQuestion = {
                                    ...updatedQuestions[i],
                                    answers,
                                  };

                                  updatedQuestions[i] = updatedQuestion;

                                  onChange('questions', updatedQuestions);
                                }}
                                color="primary"
                                inputProps={{ 'aria-label': 'correct answer checkbox' }}
                              />
                            </div>

                            <div className="question-button-container">
                              <IconButton
                                color="inherit"
                                onClick={() => {
                                  const answers = [ ...question.answers ];

                                  answers.splice(j, 1);

                                  const updatedQuestions = [ ...questions ];
                                  const updatedQuestion = {
                                    ...updatedQuestions[i],
                                    answers,
                                  };

                                  updatedQuestions[i] = updatedQuestion;

                                  onChange('questions', updatedQuestions);
                                }}
                                aria-label="Delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="question-buttons-container">
                    <div className="question-button-container">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          const answers = [ ...question.answers ];

                          const selectedIndex = answers.findIndex(a => a.correct);

                          const answer = {
                            answer: `Answer ${answers.length + 1}`,
                            correct: selectedIndex === -1 ? true : false,
                          };

                          answers.push(answer);

                          const updatedQuestions = [ ...questions ];
                          const updatedQuestion = {
                            ...updatedQuestions[i],
                            answers,
                          };

                          updatedQuestions[i] = updatedQuestion;

                          onChange('questions', updatedQuestions);
                        }}
                        color="secondary"
                      >
                        Add Answer
                      </Button>
                    </div>

                    <div className="question-button-container">
                      <IconButton color="inherit" onClick={() => setDeleteIndex(i)} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Question?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this question?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const updatedQuestions = [ ...questions ];
              
              updatedQuestions.splice(deleteIndex, 1);

              onChange('questions', updatedQuestions);
              setDeleteIndex(-1);
            }}
            color="primary"
          >
            Confirm
          </Button>

          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default QuizModule;
