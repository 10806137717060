import React from 'react';
import {
  TextField,
} from '@material-ui/core';

import './videoModule.scss';

function VideoModule({
  videoUrl,
  videoThumbnail,
  onChange,
}) {
  return (
    <div className="VideoModule section-container">
      <div className="section-label">Video Details</div>
      <TextField
        label="Video URL"
        value={videoUrl}
        onChange={(e) => onChange('videoUrl', e.target.value)}
        margin="dense"
        variant="outlined"
        className="day-text-field condensed-text-field"
      />

      <TextField
        label="Video Thumbnail"
        value={videoThumbnail}
        onChange={(e) => onChange('videoThumbnail', e.target.value)}
        margin="dense"
        variant="outlined"
        className="day-text-field condensed-text-field"
      />
    </div>
  );
}

export default VideoModule;
