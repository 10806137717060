import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Toolbar,
  Divider,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './header.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Header(props) {
  const [loading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState({
    navItems: [],
  });

  const intitalProductHeaderData = {
    trainTitle: '',
    trainDescription: '',
    trainProducts: [],
    engageTitle: '',
    engageDescription: '',
    engageProducts: [],
    rewardTitle: '',
    rewardDescription: '',
    rewardProducts: [],
    healthTitle: '',
    healthDescription: '',
    healthProducts: [],
    homeTitle: '',
    homeDescription: '',
    homeProducts: [],
    enableSaleLink: false,
    saleLinkUrl: '',
  };

  const [topLevelNavDeleteIndex, setTopLevelNavDeleteIndex] = useState(-1);
  const [productHeaderData, setProductHeaderData] = useState(
    intitalProductHeaderData,
  );
  const [selectedProduct, setSelectedProduct] = useState({});
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [addProductType, setAddProductType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const productTypeKeys = [
    'trainProducts',
    'engageProducts',
    'rewardProducts',
    'healthProducts',
    'homeProducts',
  ];
  const productTypeTitlesMap = {
    engageProducts: 'Engage Product',
    healthProducts: 'Health Product',
    rewardProducts: 'Reward Product',
    trainProducts: 'Train Product',
    homeProducts: 'Home Product',
  };

  const productTypeTitle = {
    engageProducts: productHeaderData.engageTitle,
    healthProducts: productHeaderData.healthTitle,
    rewardProducts: productHeaderData.rewardTitle,
    trainProducts: productHeaderData.trainTitle,
    homeProducts: productHeaderData.homeTitle,
  };

  const productTypeDescription = {
    engageProducts: productHeaderData.engageDescription,
    healthProducts: productHeaderData.healthDescription,
    rewardProducts: productHeaderData.rewardDescription,
    trainProducts: productHeaderData.trainDescription,
    homeProducts: productHeaderData.homeDescription,
  };

  useEffect(() => {
    fetchHeaderContent();
  }, []);

  const fetchHeaderContent = async () => {
    try {
      const shopPageDataSnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('shop-settings')
        .get();
      const querySnapshot = await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('header')
        .get();
      const content = querySnapshot.data();
      setProductHeaderData(content);
      setSimplifiedProducts(
        shopPageDataSnapshot
          .data()
          .simplifiedProducts.filter((p) => {
            return p.active;
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),
      );
      setLoading(false);
    } catch (e) {
      window.alert(
        'An error occurred fetching header content. Refresh the page and try again.',
      );
    }
  };

  const handleAddProductClick = (type) => {
    let product = {};

    for (let i = 0; i < simplifiedProducts.length; i++) {
      const simplified = simplifiedProducts[i];
      const foundIndex = productHeaderData[type].findIndex(
        (p) => p.id === simplified.id,
      );

      if (foundIndex === -1) {
        product = simplified;
        break;
      }
    }

    setSelectedProduct(product);
    setAddProductType(type);
  };

  const addProduct = () => {
    const products = [
      ...productHeaderData[addProductType],
      { ...selectedProduct },
    ];

    setProductHeaderData({
      ...productHeaderData,
      [addProductType]: products,
    });
    setSelectedProduct('');
    setAddProductType('');
  };

  const removeProduct = (type, i) => {
    const products = [...productHeaderData[type]];
    products.splice(i, 1);

    setProductHeaderData({
      ...productHeaderData,
      [type]: products,
    });
  };

  const save = async () => {
    setLoading(true);

    try {
      await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('header')
        .update(productHeaderData);

      setLoading(false);
      setModalTitle('Success!');
      setModalText(
        'The header products have been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'There was an error saving the header products, please try again.',
      );
    }
  };

  const changeHandler = (value, type) => {
    if (type === 'trainProductsT') {
      setProductHeaderData({ ...productHeaderData, trainTitle: value });
    }

    if (type === 'trainProductsD') {
      setProductHeaderData({ ...productHeaderData, trainDescription: value });
    }

    if (type === 'rewardProductsT') {
      setProductHeaderData({ ...productHeaderData, rewardTitle: value });
    }

    if (type === 'rewardProductsD') {
      setProductHeaderData({ ...productHeaderData, rewardDescription: value });
    }

    if (type === 'engageProductsT') {
      setProductHeaderData({ ...productHeaderData, engageTitle: value });
    }

    if (type === 'engageProductsD') {
      setProductHeaderData({ ...productHeaderData, engageDescription: value });
    }

    if (type === 'healthProductsT') {
      setProductHeaderData({ ...productHeaderData, healthTitle: value });
    }

    if (type === 'healthProductsD') {
      setProductHeaderData({ ...productHeaderData, healthDescription: value });
    }

    if (type === 'homeProductsT') {
      setProductHeaderData({ ...productHeaderData, homeTitle: value });
    }

    if (type === 'homeProductsD') {
      setProductHeaderData({ ...productHeaderData, homeDescription: value });
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textAlign: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#fff', top: '50%', position: 'absolute' }}
        />
      </div>
    );
  };

  const renderTables = () => {
    const tables = [];

    for (let i = 0; i < productTypeKeys.length; i++) {
      const key = productTypeKeys[i];
      tables.push(
        <TableContainer
          component={Paper}
          key={key}
          style={{ marginBottom: 20 }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flex: 1,
              }}
            >
              {productTypeTitlesMap[key]}s
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddProductClick(key)}
              // disabled={productHeaderData[key].length > 3}
            >
              Add {productTypeTitlesMap[key]}
            </Button>
          </Toolbar>
          <Divider />
          <TextField
            style={{ margin: 10 }}
            label="Title"
            value={productTypeTitle[key]}
            onChange={(e) => {
              changeHandler(e.target.value, `${key}T`);
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
          <TextField
            style={{ margin: 10 }}
            label="Description"
            value={productTypeDescription[key]}
            variant="outlined"
            onChange={(e) => {
              changeHandler(e.target.value, `${key}D`);
            }}
            margin="dense"
            type="text"
            className="day-text-field"
          />
          {!productHeaderData[key] ? (
            <Toolbar>
              <Typography>
                No {productTypeTitlesMap[key]}s have been added.
              </Typography>
            </Toolbar>
          ) : (
            <Table
              aria-label={`${productTypeTitlesMap[key]} table`}
              style={{ width: '100%' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>SKU</strong>
                  </TableCell>
                  <TableCell>
                    <strong>ID</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Categories</strong>
                  </TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productHeaderData[key].map((product, i) => (
                  <TableRow key={`${key}-${product.id}`}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>{product.id}</TableCell>
                    <TableCell>
                      {product.categories.length ? (
                        product.categories.join(', ')
                      ) : (
                        <span style={{ color: 'orange' }}>None Specified</span>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const products = [...productHeaderData[key]];

                            const itemToMoveUp = product;
                            const itemToMoveDown = products[i - 1];

                            products[i] = itemToMoveDown;
                            products[i - 1] = itemToMoveUp;

                            setProductHeaderData({
                              ...productHeaderData,
                              [key]: products,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowUpIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === productHeaderData[key].length - 1 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const products = [...productHeaderData[key]];

                            const itemToMoveDown = product;
                            const itemToMoveUp = products[i + 1];

                            products[i] = itemToMoveUp;
                            products[i + 1] = itemToMoveDown;

                            setProductHeaderData({
                              ...productHeaderData,
                              [key]: products,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowDownIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        edge="start"
                        style={{ marginLeft: 10 }}
                        color="inherit"
                        onClick={() => removeProduct(key, i)}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>,
      );
    }

    return tables;
  };

  return (
    <div className="WebsiteContentHeader">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/website-content/dashboard">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: '10px', marginBottom: '15px' }}
            startIcon={<ArrowBackIcon />}
          >
            Dashboard
          </Button>
        </Link>
      </div>
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Header</Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={save}
          >
            Save
          </Button>
        </Toolbar>
        <Divider />
        <CardContent>
          <div className="section-container">
            <div className="section-label">Header Sale Link</div>

            <FormControlLabel
              style={{ marginTop: 8, marginBottom: 4 }}
              control={
                <Switch
                  checked={productHeaderData.enableSaleLink || false}
                  onChange={(e) => {
                    setProductHeaderData({
                      ...productHeaderData,
                      enableSaleLink: e.target.checked,
                    });
                  }}
                  name="hasCustomThankYouPage"
                  color="primary"
                />
              }
              label="Enable Header Sale Link"
            />

            <TextField
              style={{ marginTop: 10 }}
              label="Sale Link URL"
              value={productHeaderData.saleLinkUrl || ''}
              onChange={(e) => {
                setProductHeaderData({
                  ...productHeaderData,
                  saleLinkUrl: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
          {renderTables()}
        </CardContent>
      </Card>
      <Dialog
        open={topLevelNavDeleteIndex !== -1}
        onClose={() => {
          setTopLevelNavDeleteIndex(-1);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Nav Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this nav item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const navItems = [...headerData.navItems];

              navItems.splice(topLevelNavDeleteIndex, 1);

              setHeaderData({
                ...headerData,
                navItems,
              });

              setTopLevelNavDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setTopLevelNavDeleteIndex(-1);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={!!addProductType}
        onClose={() => {
          setAddProductType('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add {productTypeTitlesMap[addProductType]}</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel>Product</InputLabel>
            <Select
              style={{ width: '100%' }}
              value={selectedProduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
              }}
              label="Product"
            >
              {simplifiedProducts
                .filter((product) => {
                  if (!addProductType) {
                    return false;
                  }

                  const foundIndex = productHeaderData[
                    addProductType
                  ].findIndex((p) => p.id === product.id);

                  return foundIndex === -1;
                })
                .map((product, i) => {
                  return (
                    <MenuItem key={`option-${i}`} value={product}>
                      {product.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addProduct} color="secondary">
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddProductType('');
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={!!modalText}
        onClose={() => {
          setModalText('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Header;
