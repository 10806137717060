import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import serviceCategories from './serviceCategories/serviceCategories';
import ServiceCategory from './serviceCategories/serviceCategory/serviceCategory';

function Services() {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/services/dashboard" component={Dashboard} />
        <Route path="/services/service-categories/:id" component={ServiceCategory} />
        <Route path="/services/service-categories" component={serviceCategories} />
        <Route component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default Services;
