import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { red } from '@material-ui/core/colors';

import './weeklyChallengePosts.scss';
import { config } from '../../../../config';
import WeeklyChallengePostMenu from './weeklyChallengePostMenu/weeklyChallengePostMenu';

const pageCount = 10;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WeeklyChallengePosts({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [challenge, setChallenge] = useState({
    title: '',
    description: '',
    imageUrl: '',
    videoUrl: '',
    live: false,
    endDate: moment().endOf('week').toDate(),
    webPath: '',
    steps: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [posts, setPosts] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [postToDisapproveIndex, setPostToDisapproveIndex] = useState(-1);
  const [postToMarkLiveIndex, setPostToMarkLiveIndex] = useState(-1);

  useEffect(() => {
    const fetchWeeklyChallenge = async () => {
      const id = match.params.id;
      const db = firebase.firestore();

      if (id === undefined) {
        history.replace('/academy-content/weekly-challenges');
        return;
      }

      try {
        const challengeSnapshot = await db.collection('weekly-challenges').doc(id).get();
        const challengeData = challengeSnapshot.data();

        if (!challengeData) {
          history.replace('/academy-content/weekly-challenges');
          return;
        }

        const postsSnapshot = await db.collection('challenge-posts').where('challengeId', '==', id).where('status', '!=', '').orderBy('status').orderBy('created', 'desc').limit(pageCount).get();
        const challengePosts = postsSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        const countResponse = await axios.get(`${config.functions}/webApi/challenge/${id}/posts/count`);
        const count = countResponse.data.count;
        const challengePages = Math.ceil(count / pageCount);

        setNumberOfPages(challengePages);
        setChallengeId(id);
        setChallenge({
          ...challengeData,
          endDate: moment(challengeData.endDate).toDate(),
        });
        setPosts(challengePosts);
        setFirst(postsSnapshot.docs[0]);
        setLast(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
        setLoading(false);
      } catch (e) {
        console.log(e)
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error retrieving this weekly challenge, please try again.');
      }
    };

    fetchWeeklyChallenge();
  }, []);

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let querySnapshot;
      let newPage = page;

      if (value === 'next') {
        querySnapshot = await db.collection('challenge-posts').where('challengeId', '==', challengeId).where('status', '!=', '').orderBy('status').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        querySnapshot = await db.collection('challenge-posts').where('challengeId', '==', challengeId).where('status', '!=', '').orderBy('status').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedPosts = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setPosts(mappedPosts);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving post data. Please try again.');
    }
  };

  const updatePostStatus = async (status) => {
    setLoading(true);
    const index = status === 'live' ? postToMarkLiveIndex : postToDisapproveIndex;

    try {
      const postId = posts[index].id;

      await firebase.firestore().collection('challenge-posts').doc(postId).update({
        status,
      });

      const postsCopy = [ ...posts ];
      postsCopy[index] = {
        ...postsCopy[index],
        status,
      };

      setPosts(postsCopy);
      setPostToMarkLiveIndex(-1);
      setPostToDisapproveIndex(-1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred updating the status of this post, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="WeeklyChallengePosts">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/academy-content/weekly-challenges">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Challenges
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            <strong>{challenge.title || 'Unnamed Challenge'}</strong> Posts
          </Typography>
        </Toolbar>

        <div className="content">
          <div className="posts-container">
            {posts.map((post, i) => {
              return (
                <Card
                  key={`post-${post.id}`}
                  className="post-container"
                >
                  <CardHeader
                    avatar={
                      post.userImage ?
                      <img
                        src={post.userImage}
                        className="post-profile-picture"
                      /> :
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="user">
                        {post.userName[0]}
                      </Avatar>
                    }
                    action={
                      <WeeklyChallengePostMenu
                        isLive={post.status === 'live'}
                        onStatusChangeClicked={() => {
                          if (post.status === 'live') {
                            setPostToDisapproveIndex(i);
                          } else {
                            setPostToMarkLiveIndex(i);
                          }
                        }}
                      />
                    }
                    title={post.userName}
                    subheader={moment(post.created).format('lll')}
                  />

                  {!post.text ? null :
                    <>
                      <Divider/>
                      <CardContent>
                        <Typography variant="body2">
                          {post.text}
                        </Typography>
                      </CardContent>
                    </>
                  }

                  {!(post.videoThumbnailUrl && post.videoUrl) ? null :
                    <div className="video-container">
                      <iframe
                        src={`${post.videoUrl.replace('https://iframe.mediadelivery.net/play/', 'https://iframe.mediadelivery.net/embed/')}?autoplay=false&responsive=true`}
                        loading="lazy"
                        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
                        allowFullScreen={true}
                      ></iframe>
                    </div>
                  }

                  {!post.imageUrl ? null :
                    <CardMedia
                      component="img"
                      image={post.imageUrl}
                      alt={post.userName}
                    />
                  }

                  <Divider/>

                  <CardActions>
                    <div><strong>Status:</strong> <span className="post-status">{post.status.split('-').join(' ')}</span></div>
                  </CardActions>
                </Card>
              );
            })}

            {!numberOfPages ? null :
              <div className="pagination-container">
                <div>
                  <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                    Back
                  </Button>
                  <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
                  <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                    Next
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={postToDisapproveIndex !== -1} onClose={() => setPostToDisapproveIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Disapprove Post?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to disapprove this post?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => updatePostStatus('disapproved')} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setPostToDisapproveIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={postToMarkLiveIndex !== -1} onClose={() => setPostToMarkLiveIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Mark Post Live?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to mark this post as live?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => updatePostStatus('live')} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setPostToMarkLiveIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WeeklyChallengePosts;
