import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Slide,
  Card,
  CardContent,
} from '@material-ui/core';

import './dashboard.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

const startToday = new Date();
startToday.setHours(0, 0, 0, 0);

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [averageNumberOfOrdersPerCustomer, setAverageNumberOfOrdersPerCustomer] = useState(0);
  const [averagePurchaseValue, setAveragePurchaseValue] = useState(0);
  const [averageLifetimeValue, setAverageLifetimeValue] = useState(0);
  const [averageTimeBetweenFirstAndLastOrder, setAverageTimeBetweenFirstAndLastOrder] = useState(0);
  const [startDate, setStartDate] = useState(startToday);
  const [endDate, setEndDate] = useState(endToday);

  useEffect(() => {
    fetchCustomerAverages(moment().startOf('day').valueOf(), moment().endOf('day').valueOf());
  }, []);

  const fetchCustomerAverages = async (start, end) => {
    setLoading(true);

    try {
      const getCustomerAverages = firebase.functions().httpsCallable('getCustomerAverages');
      const result = await getCustomerAverages({ start, end });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      const {
        averageNumberOfOrdersPerCustomer,
        averagePurchaseValue,
        averageLifetimeValue,
        averageTimeBetweenFirstAndLastOrder
      } = result.data;

      const duration = moment.duration(averageTimeBetweenFirstAndLastOrder, 'milliseconds');

      setAverageNumberOfOrdersPerCustomer(averageNumberOfOrdersPerCustomer);
      setAveragePurchaseValue(averagePurchaseValue);
      setAverageLifetimeValue(averageLifetimeValue);
      setAverageTimeBetweenFirstAndLastOrder(duration.asDays());
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving customer averages. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="UserDashboard">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          User Dashboard
        </Typography>
      </Toolbar>

      <div className="content">
        <div style={{display: 'flex', position: 'relative', zIndex: 1000, marginBottom: 30}}>
          <div className="date-picker-container">
            <Typography>
              <strong>
                <small>Start</small>
              </strong>
            </Typography>
            <DatePicker
              popperPlacement="top-start"
              selected={startDate}
              onChange={(date) => {
                date.setHours(0, 0, 0, 0);
                setStartDate(date);
              }}
              maxDate={endToday}
            />
          </div>
          <div className="date-divider" style={{marginTop: 14}}>-</div>
          <div className="date-picker-container">
            <Typography>
              <strong>
                <small>End</small>
              </strong>
            </Typography>
            <DatePicker
              popperPlacement="top-end"
              selected={endDate}
              onChange={(date) => {
                date.setHours(23, 59, 59, 999);
                setEndDate(date);
              }}
              maxDate={endToday}
            />
          </div>

          <div className="date-submit-button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const start = moment(startDate).valueOf();
                const end = moment(endDate).valueOf();

                fetchCustomerAverages(start, end);
              }}
            >
              Submit
            </Button>
          </div>
        </div>

        <Typography variant="subtitle2">Customer Average</Typography>

        <div className="card-row">
          <div className="card-container">
            <Card>
              <CardContent>
                <Typography variant="subtitle1">
                  Lifetime Value:
                </Typography>
                <div className="card-spacing"></div>
                <Typography variant="h6">
                  {formatter.format(averageLifetimeValue)}
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className="card-container">
            <Card>
              <CardContent>
                <Typography variant="subtitle1">
                  Order Value:
                </Typography>
                <div className="card-spacing"></div>
                <Typography variant="h6">
                  {formatter.format(averagePurchaseValue)}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="card-row">
          <div className="card-container">
            <Card>
              <CardContent>
                <Typography variant="subtitle1">
                  Lifetime Orders:
                </Typography>
                <div className="card-spacing"></div>
                <Typography variant="h6">
                  {averageNumberOfOrdersPerCustomer.toFixed(1)}
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className="card-container">
            <Card>
              <CardContent>
                <Typography variant="subtitle1">
                  Average Time Between First and Last Order:
                </Typography>
                <div className="card-spacing"></div>
                <Typography variant="h6">
                  {averageTimeBetweenFirstAndLastOrder.toFixed(1)} days
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
