import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

import AcademyLandingModal from './academyLandingModal';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MembershipProducts() {
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addProductName, setAddProductName] = useState('');
  const [addProductId, setAddProductId] = useState('');
  const [loading, setLoading] = useState(true);
  const [academyLandingContent, setAcademyLandingContent] = useState([]);
  const [showEditAcademyLandingPage, setShowEditAcademyLandingPage] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    fetchProducts(db);
    fetchAcademyLandingContent(db);
  }, []);

  const fetchProducts = async (db) => {
    try {
      const querySnapshot = await db.collection('membership-content').get();
      const products = querySnapshot.docs.map(doc => {
        return doc.data();
      }).sort((a, b) => a.position - b.position);

      setProducts(products);
      setLoading(false);
    } catch (e) {
      console.log(e.toString());
    }
  };

  const fetchAcademyLandingContent = async (db) => {
    try {
      const querySnapshot = await db.collection('academy-landing-page').doc('content').get();
      
      setAcademyLandingContent(querySnapshot.data().sections);
    } catch (e) {
      console.log(e.toString());
    }
  };

  const saveAcademyLandingContent = async (sections) => {
    const db = firebase.firestore();
    setShowEditAcademyLandingPage(false);
    setLoading(true);

    try {
      await db.collection('academy-landing-page').doc('content').update({
        sections,
      });

      setAcademyLandingContent(sections);
    } catch (e) {
      console.log(e.toString());
      window.alert('An error occurred saving Academy Landing Page content. Please try again.');
    }

    setLoading(false);
  };

  const addCourse = () => {
    const updatedProducts = cloneDeep(products);
    const productToAdd = {
      id: addProductId,
      name: addProductName,
      backgroundImage: '',
      description: '',
      modules: [],
      purchaseDetails: [],
      regularPrice: 0,
      salePrice: 0,
      enabled: false,
      position: products.length,
    };

    updatedProducts.push(productToAdd);
    setProducts(updatedProducts);
    setAddProductId('');
    setAddProductName('');
    setShowAddModal(false);

    const db = firebase.firestore();
    db.collection('membership-content').doc(productToAdd.id).set(productToAdd);
  };

  const moveItemUp = async (i) => {
    setLoading(true);
    const updatedProducts = cloneDeep(products);
    updatedProducts[i].position = i - 1;
    updatedProducts[i - 1].position = i;
    const itemToMoveUp = updatedProducts[i];
    const itemToMoveDown = updatedProducts[i - 1];
    updatedProducts[i] = itemToMoveDown;
    updatedProducts[i - 1] = itemToMoveUp;
    const db = firebase.firestore();
    await db.collection('membership-content').doc(itemToMoveUp.id).update(itemToMoveUp);
    await db.collection('membership-content').doc(itemToMoveDown.id).update(itemToMoveDown);
    setProducts(updatedProducts);
    setLoading(false);
  };

  const moveItemDown = async (i) => {
    setLoading(true);
    const updatedProducts = cloneDeep(products);
    updatedProducts[i].position = i + 1;
    updatedProducts[i + 1].position = i;
    const itemToMoveUp = updatedProducts[i + 1];
    const itemToMoveDown = updatedProducts[i];
    updatedProducts[i + 1] = itemToMoveDown;
    updatedProducts[i] = itemToMoveUp;
    const db = firebase.firestore();
    await db.collection('membership-content').doc(itemToMoveUp.id).update(itemToMoveUp);
    await db.collection('membership-content').doc(itemToMoveDown.id).update(itemToMoveDown);
    setProducts(updatedProducts);
    setLoading(false);
  };

  const renderReorderArrows = (i) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemUp(i) }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    if (i !== products.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemDown(i) }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    return arrowItems;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}

      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Academy Content
          </Typography>
        </Toolbar>

        <Divider/>

        <div style={{textAlign: 'left', padding: 20}}>
          <Button 
            style={{ marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => { setShowAddModal(true) }}
          >
            Add Course
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { setShowEditAcademyLandingPage(true) }}
          >
            Edit Academy Landing Page
          </Button>
        </div>

        <List>
          {products.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.name} secondary={`ID: ${p.id}`} />

                  {renderReorderArrows(i)}

                  <Link edge="start" to={`/academy-content/member-content/${p.id}`}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon style={{color: '#000'}} />
                    </IconButton>
                  </Link>
                  {/* <IconButton edge="start" color="inherit" onClick={() => {  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={showAddModal} onClose={() => { setShowAddModal(false) }} TransitionComponent={Transition}>
        <div style={{padding: '20px'}}>
          <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Add Course</p>
          <TextField
            style={{minWidth: '225px'}}
            label="Course Name"
            value={addProductName}
            onChange={(e) => { setAddProductName(e.target.value) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            style={{minWidth: '225px'}}
            label="Course ID"
            value={addProductId}
            helperText="Only numbers allowed"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/gi, '');
              setAddProductId(value);
            }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <hr/>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!addProductId || !addProductName}
            onClick={addCourse}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddModal(false) }}
          >cancel</Button>
        </div>
      </Dialog>

      <Dialog fullScreen open={showEditAcademyLandingPage} onClose={() => { setShowEditAcademyLandingPage(false) }} TransitionComponent={Transition}>
        <AcademyLandingModal
          closeModal={() => { setShowEditAcademyLandingPage(false) }}
          saveContent={saveAcademyLandingContent}
          landingPageContent={academyLandingContent}
        />
      </Dialog>
    </div>
  );
}

export default MembershipProducts;
