import React from 'react';

import './editModal.scss';

function EditModal({ open, close, title, children, buttons }) {
  if (!open) {
    return <div></div>;
  }

  return (
    <div className="EditModal">
      <div className="modal-body">
        <div className="modal-inner">
          <div className="heading">
            <button className="close-button" onClick={close}>
              Done
            </button>

            {!title ? null : <h3 className="title">{title}</h3>}
          </div>

          <div className="modal-content" key="children-container">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
