import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './ordoroOrderFailures.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OrdoroOrderFailures({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [failedOrders, setFailedOrders] = useState([]);

  useEffect(() => {
    const failedOrdoroOrdersUnsubscribe = firebase.firestore().collection('failed-ordoro-orders').where('resolved', '==', false).onSnapshot((failedOrdoroOrdersSnapshot) => {
      const failedOrdersDocs = failedOrdoroOrdersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setFailedOrders(failedOrdersDocs);
      setLoading(false);
    });

    return failedOrdoroOrdersUnsubscribe;
  }, []);

  const submitOrder = async (orderId) => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios(`${config.gateway}/cs-service/api/v1/order/${orderId}/submit-to-ordoro`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error submitting this order to Ordoro, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="OrdoroOrderFailures">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Ordoro Order Failures
          </Typography>
        </Toolbar>

        {!failedOrders.length ?
          <>
            <Divider/>
            <CardContent>
              <Typography>All orders are up to date!</Typography>
            </CardContent>
          </> :
          <List>
            {failedOrders.map((order, i) => {
              return (
                <div key={order.id}>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary={<span><strong>Order ID:</strong> {order.id}</span>}
                      secondary={<span><strong>Failure Date:</strong> {moment(order.created).format('MMMM Do YYYY, h:mm a')}</span>}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => submitOrder(order.id)}
                    >
                      Submit to Ordoro
                    </Button>
                  </ListItem>
                </div>
              );
            })}
          </List>
        }
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OrdoroOrderFailures;
