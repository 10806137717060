import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

function FormDateInput({ fields, onChange }) {
  return (
    <div className="FormDateInput">
      <div className="form-input-row">
        <TextField
          label="Label"
          value={fields.label}
          onChange={(e) => {
            onChange({
              ...fields,
              label: e.target.value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <FormControl
          variant="outlined"
          margin="dense"
          className="day-text-field"
        >
          <InputLabel>
            Required
          </InputLabel>
          <Select
            value={fields.required}
            onChange={(e) => {
              onChange({
                ...fields,
                required: e.target.value,
              });
            }}
            label="Required"
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>

      <TextField
        label="Helper Text"
        value={fields.helperText}
        onChange={(e) => {
          onChange({
            ...fields,
            helperText: e.target.value,
          });
        }}
        variant="outlined"
        margin="dense"
        type="text"
        className="day-text-field"
      />

      <div>
        <FormControlLabel
          control={
            <Switch
              checked={fields.saveAnswerAsVariable}
              onChange={(e) => {
                const data = {
                  ...fields,
                  saveAnswerAsVariable: e.target.checked,
                };

                if (e.target.checked && !fields.variableName) {
                  data.variableName = `field-${Date.now()}`;
                }

                onChange(data);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Save as Variable"
        />

        {!fields.saveAnswerAsVariable ? null :
          <TextField
            label="Variable Name"
            value={fields.variableName}
            onChange={(e) => {
              onChange({
                ...fields,
                variableName: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        }
      </div>
    </div>
  );
}

export default FormDateInput;
