import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import {
  CircularProgress,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { allCountries, unitedStates, canada } from '../../statesAndCountries';
import './createOrder.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addressFields = [
  {field: 'firstName', display: 'First Name'},
  {field: 'lastName', display: 'Last Name'},
  {field: 'address', display: 'Address'},
  {field: 'addressSecondary', display: 'Secondary Address'},
  {field: 'city', display: 'City'},
  {field: 'zip', display: 'Zip'},
];

function CreateOrder(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState({
    products: [],
    email: '',
    customerId: '',
    subtotal: 0,
    shippingAmount: 0,
    taxAmount: 0,
    total: 10.00,
    isSubscription: false,
    subscriptionPeriod: 'week',
    subscriptionInterval: 1,
    billing: {
      address: '',
      addressSecondary: '',
      city: '',
      country: 'US',
      firstName: '',
      lastName: '',
      state: 'AL',
      zip: '',
    },
    shipping: {
      address: '',
      addressSecondary: '',
      city: '',
      country: 'US',
      firstName: '',
      lastName: '',
      state: 'AL',
      zip: '',
    },
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [manuallyEnterAddresses, setManuallyEnterAddresses] = useState(false);
  const [billingStates, setBillingStates] = useState(unitedStates);
  const [shippingStates, setShippingStates] = useState(unitedStates);
  const [noteToCustomer, setNoteToCustomer] = useState('');

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const db = firebase.firestore();

    try {
      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const productData = shopSettingsSnapshot.data().simplifiedProducts;

      setProducts(productData);
      setOrder({
        ...order,
        products: [{
          product: productData[0],
          quantity: 1,
          pricePerItem: 10.00,
          total: 10.00,
        }],
      });
      setLoading(false);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error. Refresh and try again.');
    }
  };

  useEffect(() => {
    const calculatedSubtotal = order.products.reduce((acc, curr) => {
      return acc += (+curr.pricePerItem * curr.quantity);
    }, 0);

    setOrder({
      ...order,
      subtotal: calculatedSubtotal,
      total: (calculatedSubtotal + +order.shippingAmount + +order.taxAmount),
    });
  }, [order.products, order.shippingAmount, order.taxAmount]);

  const addProduct = () => {
    setOrder({
      ...order,
      products: [
        ...order.products,
        {
          product: products[0],
          quantity: 1,
          pricePerItem: 10.00,
          total: 10.00,
        },
      ],
    });
  };

  const submit = async () => {
    setShowConfirmationModal(false);
    setLoading(true);
    const db = firebase.firestore();

    try {
      const products = order.products.map(p => {
        return {
          id: p.product.id,
          name: p.product.name,
          quantity: +p.quantity,
          sku: p.product.sku,
          originalPrice: p.product.price,
          paidPrice: (+p.pricePerItem).toFixed(2),
          isDigital: p.product.isDigital,
          categories: p.categories || [],
          path: p.path || '',
          imageUrl: p.image ? p.image.url : '',
        };
      });

      const subscriptions = [];

      if (order.isSubscription) {
        const subscription = {
          interval: order.subscriptionInterval,
          period: order.subscriptionPeriod,
          products: products.map(p => {
            return {
              isDigital: p.isDigital,
              name: p.name,
              price: p.paidPrice,
              productId: p.id,
              quantity: p.quantity,
              sku: p.sku,
              categories: p.categories || [],
              path: p.path || '',
              imageUrl: p.imageUrl || '',
            };
          }),
          subtotal: +(order.subtotal.toFixed(2)),
          taxAmount: +((+order.taxAmount).toFixed(2)),
          shippingAmount: +((+order.shippingAmount).toFixed(2)),
        };

        subscriptions.push(subscription);
      }

      const orderToCreate = {
        status: 'CUSTOM_ORDER_CREATED',
        emailSent: false,
        userId: order.customerId || '',
        userEmail: order.email,
        email: order.email,
        phone: '',
        paymentType: '',
        products,
        couponsUsed: [],
        total: order.total.toFixed(2),
        subtotal: order.subtotal.toFixed(2),
        taxAmount: (+order.taxAmount).toFixed(2),
        shippingAmount: (+order.shippingAmount).toFixed(2),
        billing: {
          address: '',
          addressSecondary: '',
          city: '',
          country: '',
          firstName: '',
          lastName: '',
          state: '',
          zip: '',
        },
        shipping: {
          address: '',
          addressSecondary: '',
          city: '',
          country: '',
          firstName: '',
          lastName: '',
          state: '',
          zip: '',
        },
        subscriptions,
        created: firebase.firestore.Timestamp.fromDate(new Date()),
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        thankYouPage: 'thank-you',
      };

      if (!order.total) {
        orderToCreate.status = 'PENDING_ONE_CLICK_UPSELL';
        orderToCreate.oneClickUpsellExpiration = moment().valueOf();
        orderToCreate.emailSent = true;
      }

      if (manuallyEnterAddresses) {
        orderToCreate.billing = order.billing;
        orderToCreate.shipping = order.shipping;
      }

      const note = noteToCustomer.trim();

      if (note) {
        orderToCreate.noteToCustomer = noteToCustomer;
      }

      if (order.customerId) {
        const userSnapshot = await db.collection('users').doc(order.customerId).get();
        const userData = userSnapshot.data();

        if (!userData) {
          setLoading(false);
          setModalTitle('Error:');
          setModalText('The specified user was not found.');
          return;
        }

        if (userData.billing && !manuallyEnterAddresses) {
          orderToCreate.billing = {
            address: userData.billing.address || orderToCreate.billing.address,
            addressSecondary: userData.billing.addressSecondary || orderToCreate.billing.addressSecondary,
            city: userData.billing.city || orderToCreate.billing.city,
            country: userData.billing.country || orderToCreate.billing.country,
            firstName: userData.billing.firstName || orderToCreate.billing.firstName,
            lastName: userData.billing.lastName || orderToCreate.billing.lastName,
            state: userData.billing.state || orderToCreate.billing.state,
            zip: userData.billing.zip || orderToCreate.billing.zip,
          };
        }

        if (userData.shipping && !manuallyEnterAddresses) {
          orderToCreate.shipping = {
            address: userData.shipping.address || orderToCreate.shipping.address,
            addressSecondary: userData.shipping.addressSecondary || orderToCreate.shipping.addressSecondary,
            city: userData.shipping.city || orderToCreate.shipping.city,
            country: userData.shipping.country || orderToCreate.shipping.country,
            firstName: userData.shipping.firstName || orderToCreate.shipping.firstName,
            lastName: userData.shipping.lastName || orderToCreate.shipping.lastName,
            state: userData.shipping.state || orderToCreate.shipping.state,
            zip: userData.shipping.zip || orderToCreate.shipping.zip,
          };
        }
      }

      const createdOrder = await db.collection('orders-v2').add(orderToCreate);

      setLoading(false);
      props.history.push(`/shop/orders/${createdOrder.id}`);
    } catch (e) {
      console.log('submit error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error submitting the order. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CreateOrder">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/shop/orders">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Orders
          </Button>
        </Link>
      </div>

      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6" style={{marginRight: 10}}>
          Create Order
        </Typography>

        <Button 
          variant="contained"
          color="secondary"
          size="small"
          disabled={!order.email || !order.products.length}
          onClick={() => { setShowConfirmationModal(true) }}
        >
          Submit
        </Button>
      </Toolbar>

      <div className="content-container">
        <div>
          <TextField
            value={order.email}
            onChange={(e) => {
              setOrder({
                ...order,
                email: e.target.value,
              });
            }}
            label="Customer Email (required)"
            variant="outlined"
            margin="dense"
            type="email"
            style={{marginBottom: 20}}
            className="day-text-field"
          />
        </div>

        <div>
          <TextField
            value={order.customerId}
            onChange={(e) => {
              setOrder({
                ...order,
                customerId: e.target.value,
              });
            }}
            label="Customer ID (optional)"
            variant="outlined"
            margin="dense"
            type="text"
            style={{marginBottom: 20}}
            className="day-text-field"
          />
        </div>

        <div>
          <TextField
            value={noteToCustomer}
            onChange={(e) => setNoteToCustomer(e.target.value)}
            label="Note To Customer (optional)"
            variant="outlined"
            margin="dense"
            type="text"
            style={{marginBottom: 20}}
            className="day-text-field"
            multiline
            rows="4"
          />
        </div>

        <div style={{marginBottom: 10}}>
          <FormControlLabel
            control={
              <Switch
                checked={order.isSubscription}
                onChange={(e) => {
                  setOrder({
                    ...order,
                    isSubscription: e.target.checked,
                  });
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Is Subscription"
          />
        </div>

        {!order.isSubscription ? null :
          <div style={{display: 'flex', marginBottom: 20}}>
            <FormControl margin="dense" variant="outlined" style={{flex: 1, marginRight: 20}}>
              <InputLabel>Subscription Interval</InputLabel>
              <Select
                label="Subscription Interval"
                value={order.subscriptionInterval}
                onChange={(e) => {
                  setOrder({
                    ...order,
                    subscriptionInterval: e.target.value,
                  });
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="dense" variant="outlined" style={{flex: 1}}>
              <InputLabel>Subscription Period</InputLabel>
              <Select
                label="Subscription Period"
                value={order.subscriptionPeriod}
                onChange={(e) => {
                  setOrder({
                    ...order,
                    subscriptionPeriod: e.target.value,
                  });
                }}
              >
                <MenuItem value={'day'}>Days</MenuItem>
                <MenuItem value={'week'}>Weeks</MenuItem>
                <MenuItem value={'month'}>Months</MenuItem>
              </Select>
            </FormControl>
          </div>
        }

        <Button 
          variant="contained"
          color="primary"
          size="small"
          style={{marginRight: '10px', marginBottom: '15px'}}
          onClick={addProduct}
        >
          Add Product
        </Button>
        <TableContainer component={Paper}>
          <Table aria-label="order products table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell><strong>Product</strong></TableCell>
                <TableCell><strong>Quantity</strong></TableCell>
                <TableCell><strong>Price Per Item</strong></TableCell>
                <TableCell align="right"><strong>Total</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.products.map((p, i) => (
                <TableRow key={`product-${i}`}>
                  <TableCell padding="checkbox">
                    <IconButton onClick={() => {
                      const products = [ ...order.products ];
                      products.splice(i, 1);

                      setOrder({
                        ...order,
                        products,
                      });
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                      <Select
                        value={p.product}
                        onChange={(e) => {
                          const products = [ ...order.products ];
                          products[i].product = e.target.value;

                          setOrder({
                            ...order,
                            products,
                          });
                        }}
                      >
                        {products.map((p, j) => {
                          return <MenuItem key={`product-option-${i}-${j}`} value={p}>{p.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={p.quantity}
                      onChange={(e) => {
                        const products = [ ...order.products ];
                        products[i].quantity = e.target.value;

                        products[i].total = (+products[i].pricePerItem * +products[i].quantity);

                        setOrder({
                          ...order,
                          products,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="number"
                    />
                  </TableCell>
                  <TableCell>
                    <div className="input-container">
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        style={{minWidth: 90}}
                        allowNegative={false}
                        value={p.pricePerItem}
                        onValueChange={(values) => {
                          const products = [ ...order.products ];
                          products[i].pricePerItem = values.value;

                          products[i].total = (+products[i].pricePerItem * +products[i].quantity);

                          setOrder({
                            ...order,
                            products,
                          });
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="right">${p.total.toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <div className="totals-container">
                    <div className="totals-inner">
                      <div className="total-line">
                        <p>Subtotal:</p>
                        <p>${order.subtotal.toFixed(2)}</p>
                      </div>
                      <div className="total-line">
                        <p>Shipping:</p>
                        <p>
                          <NumberFormat
                            decimalScale={2}
                            style={{width: 50}}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={order.shippingAmount}
                            onValueChange={(values) => {
                              setOrder({
                                ...order,
                                shippingAmount: values.value,
                              });
                            }}
                          />
                        </p>
                      </div>
                      <div className="total-line">
                        <p>Tax:</p>
                        <p>
                          <NumberFormat
                            decimalScale={2}
                            style={{width: 50}}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={order.taxAmount}
                            onValueChange={(values) => {
                              setOrder({
                                ...order,
                                taxAmount: values.value,
                              });
                            }}
                          />
                        </p>
                      </div>
                      <div className="total-line grand-total">
                        <p>Total:</p>
                        <p>${order.total.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{marginTop: '20px'}}>
          <div style={{marginBottom: 10}}>
            <FormControlLabel
              control={
                <Switch
                  checked={manuallyEnterAddresses}
                  onChange={(e) => {
                    setManuallyEnterAddresses(e.target.checked);
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Manually enter addresses"
            />
          </div>

          {!manuallyEnterAddresses ? null : <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                Addresses
              </Typography>
              <div className="addresses-container">
                <div className="address-container">
                  <Typography gutterBottom variant="body1">
                    Billing
                  </Typography>
                  {addressFields.map(field => {
                    return (
                      <div key={`billing-${field.field}`}>
                        <TextField
                          value={order.billing[field.field]}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              billing: {
                                ...order.billing,
                                [field.field]: e.target.value,
                              },
                            });
                          }}
                          label={field.display}
                          variant="outlined"
                          margin="dense"
                          type="text"
                          style={{marginBottom: 10}}
                          className="day-text-field"
                        />
                      </div>
                    );
                  })}
                  <FormControl margin="dense" variant="outlined" style={{marginBottom: 10, width: '100%'}}>
                    <InputLabel>Country</InputLabel>
                    <Select
                      label="Country"
                      value={order.billing.country}
                      onChange={(e) => {
                        const value = e.target.value;
                        const updatedOrder = {
                          ...order,
                          billing: {
                            ...order.billing,
                            country: value,
                          },
                        };

                        if (value === 'US') {
                          setBillingStates(unitedStates);
                          updatedOrder.billing.state = unitedStates[0].value;
                        } else if (value === 'CA') {
                          setBillingStates(canada);
                          updatedOrder.billing.state = canada[0].value;
                        } else {
                          updatedOrder.billing.state = '';
                        }

                        setOrder(updatedOrder);
                      }}
                    >
                      {allCountries.map(country => {
                        return <MenuItem key={`billing-country-${country.value}`} value={country.value}>{country.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  {!(order.billing.country === 'US' || order.billing.country === 'CA') ? null :
                    <FormControl margin="dense" variant="outlined" style={{marginBottom: 10, width: '100%'}}>
                      <InputLabel>State</InputLabel>
                      <Select
                        label="State"
                        value={order.billing.state}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing: {
                              ...order.billing,
                              state: e.target.value,
                            },
                          });
                        }}
                      >
                        {billingStates.map(state => {
                          return <MenuItem key={`billing-state-${state.value}`} value={state.value}>{state.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  }
                </div>
                <div className="address-container">
                  <Typography gutterBottom variant="body1">
                    Shipping
                  </Typography>
                  {addressFields.map(field => {
                    return (
                      <div key={`shipping-${field.field}`}>
                        <TextField
                          value={order.shipping[field.field]}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              shipping: {
                                ...order.shipping,
                                [field.field]: e.target.value,
                              },
                            });
                          }}
                          label={field.display}
                          variant="outlined"
                          margin="dense"
                          type="text"
                          style={{marginBottom: 10}}
                          className="day-text-field"
                        />
                      </div>
                    );
                  })}
                  <FormControl margin="dense" variant="outlined" style={{marginBottom: 10, width: '100%'}}>
                    <InputLabel>Country</InputLabel>
                    <Select
                      label="Country"
                      value={order.shipping.country}
                      onChange={(e) => {
                        const value = e.target.value;
                        const updatedOrder = {
                          ...order,
                          shipping: {
                            ...order.shipping,
                            country: value,
                          },
                        };

                        if (value === 'US') {
                          setShippingStates(unitedStates);
                          updatedOrder.shipping.state = unitedStates[0].value;
                        } else if (value === 'CA') {
                          setShippingStates(canada);
                          updatedOrder.shipping.state = canada[0].value;
                        } else {
                          updatedOrder.shipping.state = '';
                        }

                        setOrder(updatedOrder);
                      }}
                    >
                      {allCountries.map(country => {
                        return <MenuItem key={`shipping-country-${country.value}`} value={country.value}>{country.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  {!(order.shipping.country === 'US' || order.shipping.country === 'CA') ? null :
                    <FormControl margin="dense" variant="outlined" style={{marginBottom: 10, width: '100%'}}>
                      <InputLabel>State</InputLabel>
                      <Select
                        label="State"
                        value={order.shipping.state}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            shipping: {
                              ...order.shipping,
                              state: e.target.value,
                            },
                          });
                        }}
                      >
                        {shippingStates.map(state => {
                          return <MenuItem key={`shipping-state-${state.value}`} value={state.value}>{state.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  }
                </div>
              </div>
            </CardContent>
          </Card>}
        </div>
      </div>

      <Dialog open={showConfirmationModal} onClose={() => { setShowConfirmationModal(false) }} TransitionComponent={Transition}>
        <DialogTitle>Submit Order?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to submit this order?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submit} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setShowConfirmationModal(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateOrder;
