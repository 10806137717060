import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from 'react-datepicker';

import './accountCredits.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AccountCredits() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [accountCredits, setAccountCredits] = useState([]);
  const [editAccountCredit, setEditAccountCredit] = useState({
    enabled: false,
    name: '',
    amount: 5,
    expiresOnDate: false,
    expiresAfterNumberOfDays: 5,
    expirationDate: moment().valueOf(),
    newAccountsOnly: true,
    lastUpdated: moment().valueOf(),
    lastUpdatedBy: '',
    createdDate: moment().valueOf(),
  });
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const fetchAccountCredits = async () => {
      try {
        const creditsSnapshot = await firebase.firestore().collection('account-credits').orderBy('createdDate', 'desc').get();
        const credits = creditsSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        setAccountCredits(credits);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error retrieving account credits. Refresh the page and try again.');
      }
    };

    fetchAccountCredits();
  }, []);

  const save = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const dataToSave = {
        ...editAccountCredit,
        lastUpdated: moment().valueOf(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };
      const updatedAccountCredits = [ ...accountCredits ];

      if (editIndex === accountCredits.length) {
        const doc = await firebase.firestore().collection('account-credits').add(dataToSave);
        dataToSave.id = doc.id;
        updatedAccountCredits.unshift(dataToSave);
      } else {
        const id = dataToSave.id;
        const updateData = { ...dataToSave };
        delete updateData['id'];
        await firebase.firestore().collection('account-credits').doc(id).update(dataToSave);
        updatedAccountCredits[editIndex] = dataToSave;
      }

      setAccountCredits(updatedAccountCredits);
      setEditIndex(-1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this credit, please try again.');
    }
  };

  const deleteCredit = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const id = accountCredits[deleteIndex].id;
      await firebase.firestore().collection('account-credits').doc(id).delete();

      const updatedAccountCredits = [ ...accountCredits ];
      updatedAccountCredits.splice(deleteIndex, 1);

      setAccountCredits(updatedAccountCredits);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this credit, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="AccountCredits">
      {renderLoading()}
      <Card>
        <Toolbar className="toolbar">
          <Typography variant="h6">
            Account Credits
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              setEditAccountCredit({
                enabled: false,
                name: '',
                expiresOnDate: false,
                expiresAfterNumberOfDays: 5,
                expirationDate: moment().add(10, 'days').valueOf(),
                amount: 5,
                newAccountsOnly: true,
                lastUpdated: moment().valueOf(),
                lastUpdatedBy: '',
                createdDate: moment().valueOf(),
              });
              setEditIndex(accountCredits.length);
            }}
            color="secondary"
            size="small"
          >
            Add New
          </Button>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table aria-label="account credits table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Enabled</strong></TableCell>
                <TableCell><strong>Last Updated</strong></TableCell>
                <TableCell><strong>Amount</strong></TableCell>
                <TableCell><strong>New Accounts Only</strong></TableCell>
                <TableCell><strong>Expires</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountCredits.map((c, i) => (
                <TableRow key={`credit-${i}`}>
                  <TableCell><strong>{c.name}</strong></TableCell>
                  <TableCell>{c.enabled ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <div>{moment(c.lastUpdated).format('MMMM Do YYYY, h:mm a')}</div>
                    <div>by {c.lastUpdatedBy}</div>
                  </TableCell>
                  <TableCell>${(+c.amount).toFixed(2)}</TableCell>
                  <TableCell>{c.newAccountsOnly ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {c.expiresOnDate ? moment(c.expirationDate).format('MMMM Do YYYY, h:mm a') : `After ${c.expiresAfterNumberOfDays} days`}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      style={{marginLeft: 10}}
                      color="inherit"
                      onClick={() => {
                        setEditAccountCredit({ ...c });
                        setEditIndex(i);
                      }} 
                      aria-label="Edit"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      style={{marginLeft: 10}}
                      color="inherit"
                      onClick={() => setDeleteIndex(i)} 
                      aria-label="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editIndex !== -1}
        onClose={() => setEditIndex(-1)}
        TransitionComponent={Transition}
      >
        <DialogTitle>{editIndex === accountCredits.length ? 'Create' : 'Edit'} Credit</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={editAccountCredit.enabled}
                onChange={(e) => {
                  setEditAccountCredit({
                    ...editAccountCredit,
                    enabled: e.target.checked,
                  });
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Enabled"
          />

          <TextField
            label="Name (used internally)"
            value={editAccountCredit.name}
            onChange={(e) => {
              setEditAccountCredit({
                ...editAccountCredit,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />

          <FormControl variant="outlined" style={{marginBottom: 15, marginTop: 15}} className="day-text-field">
            <InputLabel>Credit Amount</InputLabel>
            <OutlinedInput
              value={editAccountCredit.amount}
              onChange={(e) => {
                setEditAccountCredit({
                  ...editAccountCredit,
                  amount: e.target.value,
                });
              }}
              margin="dense"
              label="Credit Amount"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              className="day-text-field"
            />
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                checked={editAccountCredit.newAccountsOnly}
                onChange={(e) => {
                  setEditAccountCredit({
                    ...editAccountCredit,
                    newAccountsOnly: e.target.checked,
                  });
                }}
                name="newAccountsOnly"
                color="primary"
              />
            }
            label="Only enable for new accounts"
            className="day-text-field"
          />

          <FormControlLabel
            control={
              <Switch
                checked={editAccountCredit.expiresOnDate}
                onChange={(e) => {
                  setEditAccountCredit({
                    ...editAccountCredit,
                    expiresOnDate: e.target.checked,
                  });
                }}
                name="expiresOnDate"
                color="primary"
              />
            }
            label="Expires on a specific date"
          />

          {editAccountCredit.expiresOnDate ?
            <>
              <DatePicker
                selected={new Date(editAccountCredit.expirationDate)}
                onChange={(date) => {
                  setEditAccountCredit({
                    ...editAccountCredit,
                    expirationDate: moment(date).valueOf(),
                  });
                }}
              />
            </> :
            <TextField
              label="Number of Days to Use"
              value={editAccountCredit.expiresAfterNumberOfDays}
              onChange={(e) => {
                setEditAccountCredit({
                  ...editAccountCredit,
                  expiresAfterNumberOfDays: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              type="number"
              className="day-text-field"
              style={{marginBottom: 15, marginTop: 15}}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={save} color="secondary">
            Save
          </Button>
          <Button variant="contained" onClick={() => setEditIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Page?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {deleteIndex === -1 ? 'this credit?' : <strong>{accountCredits[deleteIndex].name}</strong>}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteCredit} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => setModalText('')} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setModalText('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AccountCredits;
