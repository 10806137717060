import React, { useState, useEffect, useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

import EditModal from './shared/editModal';
import Modal from './shared/modal';
import Row from './row';
import TextItem from './textItem';
import Text from './text';
import Button from './button';
import Image from './image';
import CustomCode from './customCode';
import Accordion from './accordion';
import Tabs from './tabs';
import Link from './link';
import { ItemTypes } from './shared/itemTypes';
import moveIcon from '../icons/move.png';
import leftArrow from '../icons/left.png';
import rightArrow from '../icons/right.png';
import editArrow from '../icons/edit.png';
import deleteIcon from '../icons/delete.png';
import copyIcon from '../icons/copy.png';

const cloneDeep = require('lodash.clonedeep');

const acceptedTypes = [
  ItemTypes.ROW,
  ItemTypes.H1,
  ItemTypes.H2,
  ItemTypes.H3,
  ItemTypes.H4,
  ItemTypes.H5,
  ItemTypes.H6,
  ItemTypes.TEXT,
  ItemTypes.BUTTON,
  ItemTypes.IMAGE,
  ItemTypes.TABS,
  ItemTypes.ACCORDION,
  ItemTypes.CODE,
  ItemTypes.LINK,
];

const textTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const styles = {
  container: {
    border: '1px dashed #00a0d2',
    position: 'relative',
    height: '100%',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: 'lightblue',
  },
  toolbarItem: {
    height: 15,
    width: 15,
    margin: 5,
    cursor: 'pointer',
  },
  image: {
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

function Column({ content, updateContent, leftClicked, rightClicked, deleteItem, saveModule, copyClicked }) {
  const [, drag] = useDrag({
    item: { ...content },
    end(item, monitor) {
      if (!monitor.didDrop()) {
        return;
      }

      deleteItem();
    },
  });
  const innerRef = useRef(null);
  const childRef = useRef(null);
  const [showTools, setShowTools] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [{ isOverCurrent }, drop] = useDrop({
    accept: acceptedTypes,
    drop(item, monitor) {
      if (monitor.didDrop()) {
        return;
      }

      updateContent({
        ...content,
        children: [
          ...content.children,
          {
            ...item,
          },
        ],
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.onmouseenter = (e) => {
        setShowTools(true);
      };

      innerRef.current.onmouseleave = (e) => {
        setShowTools(false);
      };

      childRef.current.onmouseenter = (e) => {
        setShowTools(false);
      };

      childRef.current.onmouseleave = (e) => {
        setShowTools(true);
      };
    }
  }, [innerRef, childRef]);

  let borderStyle = 'dashed';
  if (isOverCurrent) {
    borderStyle = 'solid';
  }

  const renderType = (item, i) => {
    if (item.type === 'row') {
      return (
        <Row
          content={item}
          saveModule={saveModule}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (textTypes.includes(item.type)) {
      return (
        <TextItem
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'text') {
      return (
        <Text
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'button') {
      return (
        <Button
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'image') {
      return (
        <Image
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'code') {
      return (
        <CustomCode
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'accordion') {
      return (
        <Accordion
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'tabs') {
      return (
        <Tabs
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
        />
      );
    }

    if (item.type === 'link') {
      return (
        <Link
          content={item}
          updateContent={(updatedItem) => { handleUpdateItem(updatedItem, i) }}
          upClicked={() => { handleUpClicked(item, i) }}
          downClicked={() => { handleDownClicked(item, i) }}
          deleteItem={() => { handleItemDelete(i) }}
          copyClicked={() => { handleCopy(item, i) }}
          saveModule={saveModule}
        />
      );
    }

    return <div></div>;
  };

  const handleUpdateItem = (updatedItem, i) => {
    const children = [ ...content.children ];

    children[i] = updatedItem;

    const updated = {
      ...content,
      children,
    };

    updateContent(updated);
  };

  const handleCopy = (item, i) => {
    const children = [ ...content.children ];
    const rowClone = cloneDeep(item);

    children.splice(i + 1, 0, rowClone);

    const updated = {
      ...content,
      children,
    };

    updateContent(updated);
  };

  const handleItemDelete = (i) => {
    const children = [ ...content.children ];

    children.splice(i, 1);

    const updated = {
      ...content,
      children,
    };

    updateContent(updated);
  };

  const handleUpClicked = (item, i) => {
    if (i !== 0) {
      const children = [ ...content.children ];

      const itemToMoveUp = { ...item };
      const itemToMoveDown = { ...children[i - 1] };

      children[i] = itemToMoveDown;
      children[i - 1] = itemToMoveUp;

      const updated = {
        ...content,
        children,
      };

      updateContent(updated);
    }
  };

  const handleDownClicked = (item, i) => {
    if (i !== content.children.length - 1) {
      const children = [ ...content.children ];

      const itemToMoveDown = { ...item };
      const itemToMoveUp = { ...children[i + 1] };

      children[i] = itemToMoveUp;
      children[i + 1] = itemToMoveDown;

      const updated = {
        ...content,
        children,
      };

      updateContent(updated);
    }
  };

  const delayedDelete = () => {
    setTimeout(() => {
      deleteItem();
    }, 50);
  };

  return (
    <div ref={drop} style={{ ...styles.container, borderStyle, padding: !content.children.length ? '15px' : '0' }}>
      <div
        ref={innerRef}
        style={{ padding: '5px', height: '100%' }}
      >
        {!showTools ? null :
          <div style={styles.inner}>
            <div style={styles.toolbar}>
              <div style={styles.toolbarItem} ref={drag}>
                <img style={styles.image} src={moveIcon} alt="move" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { copyClicked() }}>
                <img style={styles.image} src={copyIcon} alt="copy" />
              </div>
              <div style={styles.toolbarItem} onClick={leftClicked}>
                <img style={styles.image} src={leftArrow} alt="left arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={rightClicked}>
                <img style={styles.image} src={rightArrow} alt="right arrow" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={editArrow} alt="edit" onClick={() => { setEditModalOpen(true) }} />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={deleteIcon} alt="delete" onClick={() => { setDeleteModalOpen(true) }} />
              </div>
            </div>
          </div>
        }
        <div ref={childRef} style={{
          position: 'relative',
          ...content.styles,
          backgroundImage: content.styles.backgroundImage ? `url(${content.styles.backgroundImage})` : '',
        }}>
          <div style={{ ...styles.overlay, backgroundColor: content.backgroundOverlayColor || 'rgba(0, 0, 0, 0)' }}></div>
          {content.children.map((item, i) => {
            return (
              <div key={`child-${i}`}>
                {renderType(item, i)}
              </div>
            );
          })}
        </div>
      </div>

      <EditModal
        open={editModalOpen}
        close={() => { setEditModalOpen(false) }}
        title={`Edit ${content.title}`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            setEditModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setEditModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="input-container">
            <label>Background Overlay Color</label>
            <input
              value={content.backgroundOverlayColor}
              onChange={(e) => {
                updateContent({
                  ...content,
                  backgroundOverlayColor: e.target.value,
                });
              }}
            />
          </div>
          {content.editableStyles.map((editable, i) => {
            return (
              <div key={`editable-${i}`} className="input-container">
                <label>{editable.label}</label>
                {editable.inputType === 'select' ?
                  <select
                    value={content.styles[editable.type]}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  >
                    {editable.options.map((option, i) => {
                      return <option key={i} value={option}>{option}</option>;
                    })}
                  </select> :
                  <input
                    value={content.styles[editable.type]}
                    placeholder={editable.placeholder}
                    type={editable.inputType || 'text'}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  />
                }
              </div>
            );
          })}
          <div className="input-container">
            <label>Displayed Device Widths</label>
            <select
              value={content.visibilityClassName}
              onChange={(e) => {
                updateContent({
                  ...content,
                  visibilityClassName: e.target.value
                });
              }}
            >
              <option value={'page-builder-always-visible'}>Always</option>
              <option value={'page-builder-large-visible'}>Large Devices Only</option>
              <option value={'page-builder-large-medium-visible'}>Large & Medium Devices Only</option>
              <option value={'page-builder-medium-visible'}>Medium Devices Only</option>
              <option value={'page-builder-medium-small-visible'}>Medium & Small Devices Only</option>
              <option value={'page-builder-small-visible'}>Small Devices Only</option>
            </select>
          </div>
          <div className="input-container">
            <label>Class Names</label>
            <input
              value={content.customClasses}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customClasses: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Inline Styles</label>
            <input
              value={content.customStyles}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customStyles: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>ID</label>
            <input
              value={content.htmlID}
              onChange={(e) => {
                updateContent({
                  ...content,
                  htmlID: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Is Link</label>
            <select
              value={content.isLink || false}
              onChange={(e) => {
                updateContent({
                  ...content,
                  isLink: !content.isLink,
                });
              }}
            >
               <option value={false}>False</option>
               <option value={true}>True</option>
            </select>
          </div>
          {!content.isLink ? null :
            <div className="input-container">
              <label>Link URL</label>
              <input
                value={content.url || ''}
                onChange={(e) => {
                  updateContent({
                    ...content,
                    url: e.target.value,
                  });
                }}
              />
            </div>  
          }
        </div>
      </EditModal>

      <Modal
        open={deleteModalOpen}
        close={() => { setDeleteModalOpen(false) }}
        title={`Delete ${content.title}?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            delayedDelete();
            setDeleteModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this?</div>
        </div>
      </Modal>
    </div>
  );
};

export default Column;
