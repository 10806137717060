import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Slide,
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FreeCoursesV2({}) {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addCourseName, setAddCourseName] = useState('');
  const [addCourseWebPath, setAddCourseWebPath] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    fetchFreeCourses();
  }, []);

  const fetchFreeCourses = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('free-courses-v2').get();
      const courses = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).sort((a, b) => a.position - b.position);

      setCourses(courses);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving free courses, please try again.');
    }
  };

  const addCourse = async () => {
    setLoading(true);

    try {
      const id = uuidv4();
      const updatedCourses = cloneDeep(courses);
      const courseToAdd = {
        id,
        name: addCourseName,
        webPath: addCourseWebPath,
        backgroundImageSmall: '',
        backgroundImageMedium: '',
        backgroundImageLarge: '',
        backgroundImageWithText: '',
        description: '',
        modules: [],
        enabled: false,
        position: courses.length,
      };

      const db = firebase.firestore();
      await db.collection('free-courses-v2').doc(id).set(courseToAdd);

      updatedCourses.push(courseToAdd);
      setCourses(updatedCourses);
      setAddCourseName('');
      setShowAddModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this free course, please try again.');
    }
  };

  const isNewUrlUnique = () => {
    return courses.findIndex(c => c.webPath === addCourseWebPath) === -1;
  };

  const moveItemUp = async (i) => {
    setLoading(true);
    const updatedCourses = cloneDeep(courses);
    updatedCourses[i].position = i - 1;
    updatedCourses[i - 1].position = i;
    const itemToMoveUp = updatedCourses[i];
    const itemToMoveDown = updatedCourses[i - 1];
    updatedCourses[i] = itemToMoveDown;
    updatedCourses[i - 1] = itemToMoveUp;

    try {
      const db = firebase.firestore();
      await db.collection('free-courses-v2').doc(itemToMoveUp.id).update(itemToMoveUp);
      await db.collection('free-courses-v2').doc(itemToMoveDown.id).update(itemToMoveDown);
      setCourses(updatedCourses);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the free courses. Please refresh and try again.');
    }
  };

  const moveItemDown = async (i) => {
    setLoading(true);
    const updatedCourses = cloneDeep(courses);
    updatedCourses[i].position = i + 1;
    updatedCourses[i + 1].position = i;
    const itemToMoveUp = updatedCourses[i + 1];
    const itemToMoveDown = updatedCourses[i];
    updatedCourses[i + 1] = itemToMoveDown;
    updatedCourses[i] = itemToMoveUp;

    try {
      const db = firebase.firestore();
      await db.collection('free-courses-v2').doc(itemToMoveUp.id).update(itemToMoveUp);
      await db.collection('free-courses-v2').doc(itemToMoveDown.id).update(itemToMoveDown);
      setCourses(updatedCourses);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the free courses. Please refresh and try again.');
    }
  };

  const renderReorderArrows = (i) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemUp(i) }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    if (i !== courses.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemDown(i) }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    return arrowItems;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}

      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Free Courses
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            onClick={() => { setShowAddModal(true) }}
          >
            Add Course
          </Button>
        </Toolbar>

        <List>
          {courses.map((course, i) => {
            return (
              <div key={`course-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={course.name} secondary={course.description} />

                  {renderReorderArrows(i)}

                  <Link edge="start" to={`/academy-content/free-courses-v2/${course.id}`}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon style={{color: '#000'}} />
                    </IconButton>
                  </Link>
                  {/* <IconButton edge="start" color="inherit" onClick={() => {  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={showAddModal} onClose={() => { setShowAddModal(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Course</DialogTitle>

        <DialogContent>
          <TextField
            label="Course Name"
            value={addCourseName}
            onChange={(e) => { setAddCourseName(e.target.value) }}
            margin="dense"
            variant="outlined"
            className="day-text-field"
          />

          <FormControl variant="outlined" style={{marginTop: 18, marginBottom: 4, width: '100%'}}>
            <InputLabel>URL Path</InputLabel>
            <OutlinedInput
              value={addCourseWebPath}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setAddCourseWebPath(value);
              }}
              margin="dense"
              label="URL Path"
              variant="outlined"
              type="text"
              error={!isNewUrlUnique()}
              startAdornment={<InputAdornment style={{marginRight: 0}} position="start">course/</InputAdornment>}
            />
            {isNewUrlUnique() ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!addCourseName || !addCourseWebPath || !isNewUrlUnique()}
            onClick={addCourse}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddModal(false) }}
          >cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FreeCoursesV2;
