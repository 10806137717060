import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { connect } from 'react-redux';
import {
  CircularProgress,
  Slide,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Toolbar,
  Divider,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { setUsersCount } from '../../../actions';
import './users.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Users(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchItem, setSearchItem] = useState('id');
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedResults, setSearchedResults] = useState([]);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const db = firebase.firestore();

    try {
      let usersCount = 0;

      if (!props.counts.usersCount) {
        const queries = [];

        for (let i = 0; i < 10; i++) {
          queries.push(db.collection('counters').doc(`users-${i}`).get());
        }

        const result = await Promise.all(queries);

        result.forEach(r => {
          usersCount += r.data().count;
        });

        props.setUsersCount(usersCount);
      } else {
        usersCount = props.counts.usersCount;
      }

      setNumberOfPages(Math.ceil(usersCount / 10));

      const usersSnapshot = await db.collection('users').orderBy('email', 'desc').limit(pageCount).get();
      const mappedUsers = usersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setUsers(mappedUsers);
      setFirst(usersSnapshot.docs[0]);
      setLast(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching orders. Please try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let usersSnapshot;
      let newPage = page;

      if (value === 'next') {
        usersSnapshot = await db.collection('users').orderBy('email', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        usersSnapshot = await db.collection('users').orderBy('email', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedUsers = usersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setUsers(mappedUsers);
      setFirst(usersSnapshot.docs[0]);
      setLast(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving users. Please try again.');
    }
  };

  const search = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      let foundUsers = [];
      let searchedItemText = '';

      if (searchItem === 'id') {
        const usersSnapshot = await db.collection('users').doc(searchText).get();
        const data = usersSnapshot.data();

        if (data) {
          foundUsers.push({
            ...data,
            id: usersSnapshot.id,
          });
        }

        searchedItemText = 'User ID';
      } else if (searchItem === 'email') {
        const email = searchText.trim().toLowerCase();
        const usersSnapshot = await db.collection('users').where('email', '==', email).get();

        foundUsers = usersSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        searchedItemText = 'Email';
      }

      setSearchedResults(foundUsers);
      setSearchTerm(searchText);
      setSearchedItem(searchedItemText);
      setLoading(false);
      setSearchOpen(false);
    } catch (e) {
      console.log('search error', e);
      setLoading(false);
      setSearchOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error searching users. Please try again.');
    }
  };

  const submitNewUser = async () => {
    setLoading(true);

    const email = newUserEmail.trim().toLowerCase();

    try {
      const createUser = firebase.functions().httpsCallable('createUser');
      const result = await createUser({ email, password: newUserPassword });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      if (!result || !result.data || !result.data.uid) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error adding this user. Check that the user does not already exist and try again.');
        return;
      }

      await firebase.firestore().collection('users').doc(result.data.uid).set({
        allowNotifications: false,
        completedBehaviors: [],
        completedDays: [],
        billing: {
          firstName: '',
          lastName: '',
          address: '',
          addressSecondary: '',
          city: '',
          zip: '',
          country: 'US',
          state: '',
        },
        shipping: {
          firstName: '',
          lastName: '',
          address: '',
          addressSecondary: '',
          city: '',
          zip: '',
          country: 'US',
          state: '',
        },
        dogName: '',
        email,
        selectedPath: 'full',
        userName: '',
      });

      setLoading(false);
      closeAddUserModal();
      props.history.push(`/manage-users/users/${result.data.uid}`);
    } catch (e) {
      setLoading(false);
      closeAddUserModal();
      setModalTitle('Error:');
      setModalText('There was an error adding this user. Check that the user does not already exist and try again.');
    }
  };

  const closeAddUserModal = () => {
    setAddUserOpen(false);
    setNewUserEmail('');
    setNewUserPassword('');
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Users">
      {renderLoading()}
      <div style={{marginBottom: 10}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setNewUserPassword('');
            setNewUserEmail('');
            setAddUserOpen(true);
          }}
        >
          Add User
        </Button>
      </div>

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Users
          </Typography>
          {!searchTerm ?
            <IconButton edge="start" color="inherit" onClick={() => {
              setSearchItem('id');
              setSearchText('');
              setSearchOpen(true);
            }} aria-label="Delete">
              <SearchIcon />
            </IconButton> :
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchedItem}: {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                setSearchedItem('');
                setPasswordInputType('password');
                setSearchedResults([]);
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm ?
          <div>
            {!searchedResults.length ?
              <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
                No results match your search
              </div> :
              <TableContainer component={Paper}>
                <Table aria-label="search users table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Email</strong></TableCell>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>User ID</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResults.map((u, i) => (
                      <TableRow key={u.id}>
                        <TableCell>{u.email}</TableCell>
                        <TableCell>{u.userName}</TableCell>
                        <TableCell>{u.id}</TableCell>
                        <TableCell padding="checkbox">
                          <Link to={`/manage-users/users/${u.id}`}>
                            <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div> :
          <TableContainer component={Paper}>
            <Table aria-label="users table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>User ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((u, i) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>{u.userName}</TableCell>
                    <TableCell>{u.id}</TableCell>
                    <TableCell padding="checkbox">
                      <Link to={`/manage-users/users/${u.id}`}>
                        <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Divider/>

        {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Users</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <FormControl variant="outlined" margin="dense" className="day-text-field">
              <InputLabel>Search Field</InputLabel>
              <Select
                value={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value)
                }}
                label="Search Field"
              >
                <MenuItem value={'id'}>User ID</MenuItem>
                <MenuItem value={'email'}>Email</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addUserOpen} onClose={closeAddUserModal} TransitionComponent={Transition}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              label="Email"
              value={newUserEmail}
              onChange={(e) => {
                setNewUserEmail(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginBottom: 15}}
            />

            <FormControl variant="outlined" margin="dense" style={{marginBottom: 15}} className="day-text-field">
              <InputLabel htmlFor="outlined-adornment-amount">Password</InputLabel>
              <OutlinedInput
                value={newUserPassword}
                onChange={(e) => {
                  setNewUserPassword(e.target.value);
                }}
                label="Password"
                variant="outlined"
                type={passwordInputType}
                className="day-text-field"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        const type = passwordInputType === 'text' ? 'password' : 'text';
                        setPasswordInputType(type);
                      }}
                      onMouseDown={(event) => { event.preventDefault() }}
                    >
                      {passwordInputType === 'text' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitNewUser} disabled={!newUserPassword || !emailRegex.test(newUserEmail)} color="secondary">
            Add
          </Button>
          <Button variant="contained" onClick={closeAddUserModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counts: state.counts,
  };
};

export default connect(mapStateToProps, { setUsersCount })(Users);
