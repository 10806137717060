import React, { useState } from 'react';
import {
  Button,
  Dialog,
  Slide,
  List,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import EditShippingZone from './editShippingZone/editShippingZone';
import './shippingZones.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ShippingZones(props) {
  const [editIndex, setEditIndex] = useState(-1);
  const [editZone, setEditZone] = useState({});
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const calculateNumberOfRegions = (zone) => {
    let amount = 0;

    for (let key in zone.regions) {
      if (zone.regions[key].states) {
        for (let state in zone.regions[key].states) {
          amount += 1;
        }
      }
    }

    return amount;
  };

  return (
    <div className="ShippingZones">
      <div className="add-container">
        <Button 
          variant="contained"
          color="primary"
          style={{marginRight: '10px', marginBottom: '5px'}}
          onClick={() => {
            setEditIndex(props.zones.length);
            setEditZone({
              name: 'New Shipping Zone',
              methodTitle: '',
              regions: {},
              classes: [],
              shippingDiscountTiers: [],
              priority: 1,
            });
          }}
        >
          Add Zone
        </Button>
      </div>

      <div>
        <List>
          {props.zones.map((z, i) => {
            return (
              <div key={`zone-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={z.name} secondary={<>
                    <strong>Method</strong> <span className="list-value">{z.methodTitle}</span>
                    <strong>Number of Regions:</strong> <span className="list-value">{calculateNumberOfRegions(z)}</span>
                    <strong>Priority</strong> <span className="list-value">{z.priority || 10}</span>
                  </>} />

                  <IconButton color="inherit" aria-label="Edit" onClick={() => {
                    setEditZone(z);
                    setEditIndex(i);
                  }}>
                    <EditIcon style={{color: '#000'}} />
                  </IconButton>
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => { setDeleteIndex(i); }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>

      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={editIndex !== -1}
        onClose={() => { setEditIndex(-1) }}
      >
        <EditShippingZone
          zone={editZone}
          regions={props.regions}
          close={() => { setEditIndex(-1) }}
          classes={props.classes}
          saveZone={(zone) => {
            const zones = [ ...props.zones ];

            if (zones[editIndex]) {
              zones[editIndex] = zone;
            } else {
              zones.unshift(zone);
            }

            props.save(zones);
            setEditIndex(-1);
          }}
        />
      </Dialog>

      <Dialog
        open={deleteIndex !== -1}
        onClose={() => { setDeleteIndex(-1) }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this shipping zone?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const zones = [ ...props.zones ];

            zones.splice(deleteIndex, 1);

            props.save(zones);
            setDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShippingZones;
