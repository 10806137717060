import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  Input,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import DeletableChip from '../../../shared/deletableChip';
import './editFeaturedProduct.scss';

const ruleTypes = [
  'Always',
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Subtotal is Less Than',
  'Subtotal is Greater Than',
];
const showProductTypes = [
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
];
const showCategoryTypes = [
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
];
const showTagTypes = [
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const priorityAmounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function EditFeaturedProduct(props) {
  const [product, setProduct] = useState(props.product);

  return (
    <div className="EditFeaturedProduct">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {(product.selectedProduct && product.selectedProduct.name) || ''}
          </Typography>
          <Button color="inherit" onClick={() => { props.save(product) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={product.active}
              onChange={(e) => {
                setProduct({
                  ...product,
                  active: e.target.checked,
                });
              }}
              name="active"
              color="primary"
            />
          }
          label="Active"
        />

        <TextField
          label="Featured Product"
          style={{ marginTop: 20 }}
          margin="dense"
          value={product.selectedProduct.name}
          onChange={(e) => {
            setProduct({
              ...product,
              selectedProduct: e.target.value,
            });
          }}
          SelectProps={{ renderValue: value => value }}
          fullWidth
          variant="outlined"
          select
        >
          {props.simplifiedProducts.map((simplifiedProduct, i) => {
            return <MenuItem key={`featured-product-${i}`} value={simplifiedProduct}>{simplifiedProduct.name}</MenuItem>;
          })}
        </TextField>

        {!props.isOrderBump ? null :
          <div>
            <FormControl variant="outlined" style={{marginTop: 20, marginBottom: 4}}>
              <InputLabel htmlFor="outlined-adornment-amount">Discounted Price</InputLabel>
              <OutlinedInput
                value={product.discountPrice}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    discountPrice: e.target.value,
                  });
                }}
                margin="dense"
                label="Discounted Price"
                variant="outlined"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                style={{maxWidth: 180, marginRight: 15}}
              />
            </FormControl>

            <TextField
              label="Title"
              value={product.title}
              onChange={(e) => {
                setProduct({
                  ...product,
                  title: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 20}}
            />

            <TextField
              label="Description"
              value={product.description}
              onChange={(e) => {
                setProduct({
                  ...product,
                  description: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              multiline
              rows={6}
              style={{marginTop: 20}}
            />

            <TextField
              label="Priority"
              style={{ marginTop: 20 }}
              margin="dense"
              value={product.priority || priorityAmounts[priorityAmounts.length - 1]}
              onChange={(e) => {
                setProduct({
                  ...product,
                  priority: e.target.value,
                });
              }}
              SelectProps={{ renderValue: value => value }}
              fullWidth
              variant="outlined"
              select
            >
              {priorityAmounts.map(amount => {
                return <MenuItem key={`priority-amount-${amount}`} value={amount}>{amount}</MenuItem>;
              })}
            </TextField>
          </div>
        }

        <div className="section-container">
          <div className="section-label">Rules</div>
          <div className="rules-button-row">
            <Button color="primary" onClick={() => {
              const rules = [ ...product.rules ];

              rules.push({
                type: 'group',
                rules: [{
                  type: ruleTypes[0],
                  products: [],
                  cartSubTotal: '',
                  tags: [],
                  categories: [],
                }],
              });

              setProduct({
                ...product,
                rules,
              });
            }} variant="contained">
              Add Rule Group
            </Button>
          </div>

          {product.rules.map((group, i) => {
            return (
              <div key={`rule-group-${i}`}>
                {i !== 0 && <div className="condition-text">OR</div>}

                <div className="rule-group">
                  {product.rules.length > 1 &&
                    <Button color="secondary" size="small" onClick={() => {
                      const rules = [ ...product.rules ];

                      rules.splice(i, 1);

                      setProduct({
                        ...product,
                        rules,
                      });
                    }} variant="contained">
                      Remove Group
                    </Button>
                  }
                  {group.rules.map((rule, j) => {
                    return (
                      <div key={`rule-${i}-${j}`}>
                        {j !== 0 && <div className="condition-text">AND</div>}
                        <div className="form-row">
                          {group.rules.length > 1 &&
                            <IconButton
                              onClick={() => {
                                const rules = [ ...group.rules ];

                                rules.splice(j, 1);

                                const productRules = [ ...product.rules ];
                                productRules[i].rules = rules;

                                setProduct({
                                  ...product,
                                  rules: productRules,
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                            <InputLabel>Rule Type</InputLabel>
                            <Select
                              value={rule.type}
                              onChange={(e) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  type: e.target.value,
                                };

                                const productRules = [ ...product.rules ];
                                productRules[i].rules = rules;

                                setProduct({
                                  ...product,
                                  rules: productRules,
                                });
                              }}
                              label="Rule Type"
                            >
                              {ruleTypes.map((ruleType, k) => {
                                return <MenuItem key={`rule-type-${i}-${j}-${k}`} value={ruleType}>{ruleType}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>

                          {rule.type.includes('Subtotal') &&
                            <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                              <OutlinedInput
                                value={rule.cartSubTotal}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    cartSubTotal: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                margin="dense"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{maxWidth: 180, marginRight: 15}}
                              />
                            </FormControl>
                          }

                          {(showProductTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Products</InputLabel>
                              <Select
                                multiple
                                value={rule.products}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    products: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value.id}
                                        label={value.name}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.products.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const products = [ ...rule.products ];

                                          products.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            products,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.simplifiedProducts.filter(product => {
                                  const found = rule.products.find(p => p.id === product.id);
                                  return !found;
                                }).map(product => (
                                  <MenuItem key={product.id} value={product}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showCategoryTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Categories</InputLabel>
                              <Select
                                multiple
                                value={rule.categories}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    categories: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.categories.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const categories = [ ...rule.categories ];

                                          categories.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            categories,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.productCategories.filter(category => {
                                  return !rule.categories.includes(category);
                                }).map(category => (
                                  <MenuItem key={category} value={category}>
                                    {category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showTagTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Tags</InputLabel>
                              <Select
                                multiple
                                value={rule.tags}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    tags: e.target.value,
                                  };
              
                                  const productRules = [ ...product.rules ];
                                  productRules[i].rules = rules;
              
                                  setProduct({
                                    ...product,
                                    rules: productRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.tags.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const tags = [ ...rule.tags ];

                                          tags.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            tags,
                                          };

                                          const productRules = [ ...product.rules ];
                                          productRules[i].rules = rules;

                                          setProduct({
                                            ...product,
                                            rules: productRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {props.productTags.filter(tag => {
                                  return !rule.tags.includes(tag);
                                }).map(tag => (
                                  <MenuItem key={tag} value={tag}>
                                    {tag}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }
                        </div>
                      </div>
                    );
                  })}

                  <Button color="primary" size="small" onClick={() => {
                    const rules = [ ...group.rules ];

                    rules.push({
                      type: ruleTypes[0],
                      products: [],
                      cartSubTotal: 0,
                      tags: [],
                      categories: [],
                    });

                    const productRules = [ ...product.rules ];
                    productRules[i].rules = rules;

                    setProduct({
                      ...product,
                      rules: productRules,
                    });
                  }} variant="contained">
                    Add Condition
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditFeaturedProduct;
