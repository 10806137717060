export const defaultPage = {
  backgroundImage: '',
  formBackgroundImage: '',
  backgroundColor: '#FAF7E8',
  formBackgroundColor: '#FAF7E8',
  inputBackground: '#FAF7E8',
  fontColor: '#592E2C',
  layout: 'center', // left, center, right
  boxShadowEnabled: false,
  disablePadding: false,
  questions: [],
};
