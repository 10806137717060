import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  Button,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './serviceCategories.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ServiceCategories(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [serviceCategories, setServiceCategories] = useState([]);
  const [addNewServiceCategory, setAddNewServiceCategory] = useState(false);
  const [addModalTitle, setAddModalTitle] = useState('Add New');
  const [newServiceCategory, setNewServiceCategory] = useState({
    title: '',
    description: '',
    path: '',
    imageUrl: '',
    live: false,
    services: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('service-categories').get();
      const content = querySnapshot.docs.map(doc => {
        const docData = doc.data();

        return {
          ...docData,
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created - a.created;
      });

      setServiceCategories(content);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred fetching the service categories. Refresh the page and try again.');
    }
  };

  const saveNewServiceCategory = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const createdServiceCategory = await db.collection('service-categories').add(newServiceCategory);

      setLoading(false);
      closeAddNewServiceCategory();
      props.history.push(`/services/service-categories/${createdServiceCategory.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new service category. Please try again.');
    }
  };

  const closeAddNewServiceCategory = () => {
    setAddNewServiceCategory(false);
  };

  const deleteServiceCategory = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('service-categories').doc(serviceCategories[deleteIndex].id).delete();

      const updatedServiceCategories = [ ...serviceCategories ];
      updatedServiceCategories.splice(deleteIndex, 1);

      setServiceCategories(updatedServiceCategories);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this service category. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ServiceCategories">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Service Categories
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => {
            setNewServiceCategory({
              title: '',
              description: '',
              path: '',
              imageUrl: '',
              live: false,
              services: [],
              created: Date.now(),
              updated: Date.now(),
              lastUpdatedBy: firebase.auth().currentUser.email,
            });
            setAddModalTitle('Add New');
            setAddNewServiceCategory(true);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>
        <List>
          {serviceCategories.map((service, i) => {
            return (
              <div key={service.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={service.title}
                    secondary={
                      <span>
                        <span style={{marginRight: 10}}><strong>ID:</strong> {service.id}</span>
                        <span style={{marginRight: 10}}><strong>Last updated:</strong> {moment(service.updated).calendar()}</span>
                        <span style={{marginRight: 10}}><strong>Last updated by:</strong> {service.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                  <Link edge="start" to={`/services/service-categories/${service.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton edge="start" color="inherit" onClick={() => setDeleteIndex(i)} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={addNewServiceCategory} onClose={closeAddNewServiceCategory} TransitionComponent={Transition}>
        <DialogTitle>{addModalTitle} Service Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newServiceCategory.title}
            onChange={(e) => {
              setNewServiceCategory({
                ...newServiceCategory,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={newServiceCategory.description}
            onChange={(e) => {
              setNewServiceCategory({
                ...newServiceCategory,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Image URL"
            value={newServiceCategory.imageUrl}
            onChange={(e) => {
              setNewServiceCategory({
                ...newServiceCategory,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newServiceCategory.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewServiceCategory({
                ...newServiceCategory,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewServiceCategory} color="primary" disabled={!newServiceCategory.title || !newServiceCategory.description || !newServiceCategory.imageUrl || !newServiceCategory.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={closeAddNewServiceCategory} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Service Category?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete <strong>{deleteIndex === -1 ? '' : serviceCategories[deleteIndex].title}</strong>?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => deleteServiceCategory()} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ServiceCategories;
