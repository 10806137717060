import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Card,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  List,
  Divider,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import './sideBars.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SideBars(props) {
  const [loading, setLoading] = useState(true);
  const [sidebars, setSidebars] = useState([]);
  const [newSidebar, setNewSidebar] = useState({
    description: '',
    editorData: {
      type: 'top-layer',
      children: [],
    },
    sharedTemplates: [],
    customCSS: '',
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [addModalTitle, setAddModalTitle] = useState('Add New');
  const [addNewSidebar, setAddNewSidebar] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    getSidebars();
  }, []);

  const getSidebars = async () => {
    const db = firebase.firestore();

    try {
      const sidebarsSnapshot = await db.collection('sidebars').get();
      const sidebarData = sidebarsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setSidebars(sidebarData);
      setLoading(false);
    } catch (e) {
      console.log('error ', e);
      setModalTitle('Error:');
      setModalText('Error getting sidebars. Refresh the page and try again.');
    }
  };

  const saveNewSidebar = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const createdSidebar = await db.collection('sidebars').add(newSidebar);

      setLoading(false);
      closeAddNewSidebar();
      props.history.push(`/blog-content/side-bars/${createdSidebar.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new post. Please try again.');
    }
  };

  const closeAddNewSidebar = () => {
    setAddNewSidebar(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SideBars">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Sidebars
          </Typography>
          <IconButton edge="start" color="inherit" onClick={(event) => {
            setNewSidebar({
              title: '',
              description: '',
              editorData: {
                type: 'top-layer',
                children: [],
              },
              sharedTemplates: [],
              customCSS: '',
              created: Date.now(),
              updated: Date.now(),
              lastUpdatedBy: '',
            });
            setAddModalTitle('Add New');
            setAddNewSidebar(true);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {sidebars.map((s, i) => {
            return (
              <div key={s.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={s.description} secondary={`ID: ${s.id}`} />

                  <Link edge="start" to={`/blog-content/side-bars/${s.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={addNewSidebar} onClose={closeAddNewSidebar} TransitionComponent={Transition}>
        <DialogTitle>{addModalTitle} Sidebar</DialogTitle>
        <DialogContent>
          <TextField
            style={{minWidth: 250}}
            label="Description (used internally)"
            value={newSidebar.description}
            onChange={(e) => {
              setNewSidebar({
                ...newSidebar,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewSidebar} color="primary" disabled={!newSidebar.description}>
            Submit
          </Button>
          <Button variant="contained" onClick={closeAddNewSidebar} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SideBars;
