import React from 'react';
import './shops-section.scss';
import ChewyLogo from '../../../../../../images/logos-white/chewy-logo.png';
import AmazonLogo from '../../../../../../images/logos-white/amazon-logo.png';
import PetcoLogo from '../../../../../../images/logos-white/petco-logo.png';
import PetsmartLogo from '../../../../../../images/logos-white/petsmart-logo.png';
import EarthwiseLogo from '../../../../../../images/logos-white/earthwise-logo.png';

export const ShopsSection = () => {
  return (
    <section className="ShopContainer">
      <p className="title">Find Pupford wherever you shop</p>
      <div className="logosContainer">
        <img className="logo" alt="chewy-logos" src={ChewyLogo} />
        <img className="logo" alt="petco-logo" src={PetcoLogo} />
        <img className="logo" alt="amazon-logo" src={AmazonLogo} />
        <img className="logo" alt="petsmart-logo" src={PetsmartLogo} />
        <img className="logo" alt="earthwhise-logo" src={EarthwiseLogo} />
      </div>
    </section>
  );
};
