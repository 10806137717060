import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './productTagPages.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const defaultHeaderBackgroundColor = '#ffb4ab';
const defaultHeaderTextColor = '#592e2c';

function ProductTagPages({ history }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [productPageTags, setProductPageTags] = useState([]);
  const [newProductPageTag, setNewProductPageTag] = useState({
    active: false,
    tagName: '',
    pageTitle: '',
    pageDescription: '',
    headerImageUrl: '',
    headerBackgroundColor: defaultHeaderBackgroundColor,
    headerTextColor: defaultHeaderTextColor,
    path: '',
    pageMetaTitle: '',
    pageMetaDescription: '',
    products: [],
    additionalSections: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [showNewProductPageTag, setShowNewProductPageTag] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const getFreeProducts = async () => {
      try {
        const querySnapshot = await firebase.firestore().collection('product-tag-pages').get();
        const productPageTags = querySnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        setProductPageTags(productPageTags);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching Product Tag Pages. Refresh the page and try again.');
      }
    };

    getFreeProducts();
  }, []);

  const saveNewPage = async () => {
    setLoading(true);

    try {
      const createdPageTag = await firebase.firestore().collection('product-tag-pages').add(newProductPageTag);

      setLoading(false);
      setShowNewProductPageTag(false);
      history.push(`/shop/product-tag-pages/${createdPageTag.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new page. Please try again.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);

    try {
      const id = productPageTags[deleteIndex].id;
      await firebase.firestore().collection('product-tag-pages').doc(id).delete();

      const updatedProductPageTags = [ ...productPageTags ];
      updatedProductPageTags.splice(deleteIndex, 1);

      setProductPageTags(updatedProductPageTags);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this page. Please try again.');
    }
  };

  const isNewUrlUnique = () => {
    return productPageTags.findIndex(tag => tag.path === newProductPageTag.path) === -1;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ProductTagPages">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Product Tag Pages
          </Typography>
          <IconButton
            edge="start"
            color="inherit" onClick={() => {
              setNewProductPageTag({
                active: false,
                tagName: '',
                pageTitle: '',
                pageDescription: '',
                headerImageUrl: '',
                headerBackgroundColor: defaultHeaderBackgroundColor,
                headerTextColor: defaultHeaderTextColor,
                pageMetaTitle: '',
                pageMetaDescription: '',
                path: '',
                products: [],
                additionalSections: [],
                created: Date.now(),
                updated: Date.now(),
                lastUpdatedBy: firebase.auth().currentUser.email,
              });
              setShowNewProductPageTag(true);
            }}
            aria-label="Delete"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <Table aria-label="product tag pages table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Tag Name</strong></TableCell>
              <TableCell><strong>Product Count</strong></TableCell>
              <TableCell><strong>Updated</strong></TableCell>
              <TableCell><strong>Created</strong></TableCell>
              <TableCell padding="checkbox"></TableCell>
              <TableCell padding="checkbox"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productPageTags.map((tag, i) => (
              <TableRow key={tag.id}>
                <TableCell>
                  <div>{tag.tagName}</div>
                  <div><small><strong>ID:</strong> {tag.id}</small></div>
                </TableCell>
                <TableCell>{tag.products.length}</TableCell>
                <TableCell>
                  <div>{moment(tag.updated).calendar()}</div>
                  <div>By: {tag.lastUpdatedBy}</div>
                </TableCell>
                <TableCell>{moment(tag.created).calendar()}</TableCell>
                <TableCell padding="checkbox">
                  <Link to={`/shop/product-tag-pages/${tag.id}`}>
                    <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => setDeleteIndex(i)} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={showNewProductPageTag} onClose={() => setShowNewProductPageTag(false)} TransitionComponent={Transition}>
        <DialogTitle>Add Product Tag Page</DialogTitle>
        <DialogContent>
          <TextField
            label="Tag Name"
            value={newProductPageTag.tagName}
            onChange={(e) => {
              setNewProductPageTag({
                ...newProductPageTag,
                tagName: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Title"
            value={newProductPageTag.pageTitle}
            onChange={(e) => {
              setNewProductPageTag({
                ...newProductPageTag,
                pageTitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Description"
            value={newProductPageTag.pageDescription}
            onChange={(e) => {
              setNewProductPageTag({
                ...newProductPageTag,
                pageDescription: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Meta Title"
            value={newProductPageTag.pageMetaTitle}
            onChange={(e) => {
              setNewProductPageTag({
                ...newProductPageTag,
                pageMetaTitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Meta Description"
            value={newProductPageTag.pageMetaDescription}
            onChange={(e) => {
              setNewProductPageTag({
                ...newProductPageTag,
                pageMetaDescription: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, width: '100%'}}>
            <InputLabel>URL Path</InputLabel>
            <OutlinedInput
              value={newProductPageTag.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setNewProductPageTag({
                  ...newProductPageTag,
                  path: value,
                });
              }}
              margin="dense"
              label="URL Path"
              variant="outlined"
              type="text"
              error={!isNewUrlUnique()}
              startAdornment={<InputAdornment style={{marginRight: 0}} position="start">shop/tag/</InputAdornment>}
            />
            {isNewUrlUnique() ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={saveNewPage}
            color="primary"
            disabled={!newProductPageTag.tagName || !newProductPageTag.pageTitle || !newProductPageTag.pageDescription || !newProductPageTag.pageMetaTitle || !newProductPageTag.pageMetaDescription || !newProductPageTag.path || !isNewUrlUnique()}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={() => setShowNewProductPageTag(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Product Tag Page?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this product tag page?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => setModalText('')} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setModalText('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductTagPages;
