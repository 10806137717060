import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Toolbar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import './courseSection.scss';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CourseSection(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [course, setCourse] = useState({
    id: '',
    name: '',
    webPath: '',
    backgroundImage: '',
    description: '',
    sections: [],
    enabled: false,
    position: 0,
  });
  const [section, setSection] = useState({
    title: '',
    imageUrl: '',
    backgroundImage: '',
    description: '',
    modules: [],
    categories: [''],
    id: '',
  });
  const [addModuleTitle, setAddModuleTitle] = useState('');
  const [addModuleType, setAddModuleType] = useState('video');
  const [addModuleOpen, setAddModuleOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    const db = firebase.firestore();

    try {
      const courseId = props.match.params.id;
      const doc = await db.collection('associate-training-courses').doc(courseId).get();
      const course = doc.data();

      if (!course) {
        props.history.push(`/partners/courses/${props.match.params.id}`);
        return;
      }

      const currentSection = course.sections[props.match.params.sectionIndex];

      if (!currentSection) {
        props.history.push(`/partners/courses/${props.match.params.id}`);
        return;
      }

      setSection(cloneDeep(currentSection));
      setCourse(course);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this section, please try again.');
    }
  };

  const saveCourse = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      const courseCopy = cloneDeep(course);

      courseCopy.sections[props.match.params.sectionIndex] = section;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setCourse(courseCopy);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('This section has been saved!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this section, please try again.');
    }
  };

  const submitAddModule = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      const courseCopy = cloneDeep(course);
      const sectionCopy = cloneDeep(section);

      const moduleData = {
        title: addModuleTitle,
        type: addModuleType,
        description: '',
        notes: '',
        links: [],
        id: Date.now(),
      };

      if (addModuleType === 'video') {
        moduleData.videoThumbnail = '';
        moduleData.videoUrl = '';
      }

      if (addModuleType === 'quiz') {
        moduleData.questions = [];
        moduleData.passingScore = 1;
      }

      if (addModuleType === 'article') {
        moduleData.editorData = '';
      }

      sectionCopy.modules.push(moduleData);

      courseCopy.sections[props.match.params.sectionIndex] = sectionCopy;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setSection(sectionCopy);
      setCourse(courseCopy);
      setAddModuleOpen(false);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The module has been added!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the module, please try again.');
    }
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const moveItemUp = async (i) => {
    setLoading(true);
    const courseCopy = cloneDeep(course);
    const modules = cloneDeep(section.modules);
    const itemToMoveUp = modules[i];
    const itemToMoveDown = modules[i - 1];

    modules[i] = itemToMoveDown;
    modules[i - 1] = itemToMoveUp;

    const sectionCopy = {
      ...section,
      modules,
    };
    
    try {
      const db = firebase.firestore();

      courseCopy.sections[props.match.params.sectionIndex] = sectionCopy;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setSection(sectionCopy);
      setCourse(courseCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this section, please try again.');
    }
  };

  const moveItemDown = async (i) => {
    setLoading(true);
    const courseCopy = cloneDeep(course);
    const modules = cloneDeep(section.modules);
    const itemToMoveDown = modules[i];
    const itemToMoveUp = modules[i + 1];

    modules[i] = itemToMoveUp;
    modules[i + 1] = itemToMoveDown;

    const sectionCopy = {
      ...section,
      modules,
    };
    
    try {
      const db = firebase.firestore();

      courseCopy.sections[props.match.params.sectionIndex] = sectionCopy;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setSection(sectionCopy);
      setCourse(courseCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this section, please try again.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    const courseCopy = cloneDeep(course);
    const modules = cloneDeep(section.modules);

    modules.splice(deleteIndex, 1);

    const sectionCopy = {
      ...section,
      modules,
    };
    
    try {
      const db = firebase.firestore();

      courseCopy.sections[props.match.params.sectionIndex] = sectionCopy;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setSection(sectionCopy);
      setCourse(courseCopy);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting the module, please try again.');
    }
  };

  const handleTextChange = (name) => event => {
    setSection({
      ...section,
      [name]: event.target.value,
    });
  };

  const renderBreadcrumbs = () => {
    if (!course.name) {
      return null;
    }

    return (
      <p>
        <Link to={`/partners/courses`} style={{color: 'blue', textDecoration: 'none'}}>All Courses</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        <Link to={`/partners/courses/${course.id}`} style={{color: 'blue', textDecoration: 'none'}}>{course.name}</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        {section.title}
      </p>
    );
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CourseSection">
      {renderLoading()}
      {renderBreadcrumbs()}

      <Card>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {section.title ? `Section: ${section.title}` : 'Loading Section...'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveCourse() }}
          >
            Save
          </Button>
        </Toolbar>

        <Divider/>

        <CardContent>
          <TextField
            label="Section Title"
            value={section.title}
            onChange={handleTextChange('title')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Description"
            value={section.description}
            onChange={handleTextChange('description')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <div style={{marginTop: 25, marginBottom: 20}}>
            <Divider/>
          </div>

          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="subtitle2">
                <span style={{fontSize: 20}}>Modules</span>
              </Typography>

              <Button 
                variant="contained"
                color="primary"
                style={{marginBottom: '5px'}}
                onClick={() => {
                  setAddModuleTitle('');
                  setAddModuleType('video');
                  setAddModuleOpen(true);
                }}
              >
                Add Module
              </Button>
            </div>

            <List>
              {section.modules.map((m, i) => {
                return (
                  <div key={`module-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={m.title}
                        secondary={
                          <>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Type: </span>
                              <span style={{textTransform: 'capitalize'}}>{m.type}</span>
                            </span>
                          </>
                        }
                      />

                      {renderUpArrow(i)}

                      {renderDownArrow(i, section.modules.length - 1)}

                      <Link to={`/partners/courses/${props.match.params.id}/section/${props.match.params.sectionIndex}/module/${i}`} style={{color: 'rgba(0, 0, 0, .5)', textDecoration: 'none'}}>
                        <IconButton color="inherit" aria-label="Edit Module">
                          <EditIcon />
                        </IconButton>
                      </Link>

                      <IconButton color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Module?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this module?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="primary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={addModuleOpen}
        onClose={() => { setAddModuleOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Module</DialogTitle>
        <DialogContent>
          <TextField
            label="Module Title"
            value={addModuleTitle}
            onChange={(e) => {
              setAddModuleTitle(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <FormControl margin="dense" variant="outlined" className="day-text-field condensed-text-field">
            <InputLabel>Module Type</InputLabel>
            <Select
              value={addModuleType}
              onChange={(e) => {
                setAddModuleType(e.target.value);
              }}
              label="Module Type"
            >
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="quiz">Quiz</MenuItem>
              <MenuItem value="article">Article</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitAddModule}
            color="secondary"
            disabled={!addModuleTitle}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => { setAddModuleOpen(false) }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseSection;
