import {
  SET_CURRENT_USER_ACCESS,
} from './types';

export const setCurrentUserAccess = (roles) => {
  return {
    type: SET_CURRENT_USER_ACCESS,
    payload: roles,
  };
};
