import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import SwipeableViews from 'react-swipeable-views';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import OrderBumpPerformance from './orderBumpPerformance/orderBumpPerformance';
import EditFeaturedProduct from '../cartFeaturedProducts/editFeaturedProduct/editFeaturedProduct';
import './orderBumps.scss';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function OrderBumps(props) {
  const swipeableViewsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [orderBumps, setOrderBumps] = useState([]);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [editOrderBump, setEditOrderBump] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [tabValue, setTabValue] = useState(0);
  const [hasShownPerformance, setHasShownPerformance] = useState(false);
  const [ordersLength, setOrdersLength] = useState(0);

  useEffect(() => {
    getOrderBumps();
  }, []);

  const getOrderBumps = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();

      setOrderBumps(querySnapshot.data().orderBumps);
      setSimplifiedProducts(querySnapshot.data().simplifiedProducts);
      setProductCategories(querySnapshot.data().productCategories);
      setProductTags(contentQuerySnapshot.data().tags);
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.alert('An error occurred fetching Order Bumps. Refresh the page and try again.');
    }
  };

  useEffect(() => {
    if (swipeableViewsRef.current) {
      swipeableViewsRef.current
        .getChildContext()
        .swipeableViews.slideUpdateHeight();
    }
  }, [tabValue, orderBumps, ordersLength]);

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const saveOrderBump = async (orderBump) => {
    setEditing(false);
    setLoading(true);

    const orderBumpsCopy = [ ...orderBumps ];

    if (editIndex !== -1) {
      orderBumpsCopy[editIndex] = orderBump;
    } else {
      orderBumpsCopy.push({
        ...orderBump,
        id: uuidv4(),
      });
    }

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        orderBumps: orderBumpsCopy,
      });

      setOrderBumps(orderBumpsCopy);
      setLoading(false);
      setEditIndex(-1);
    } catch (e) {
      setLoading(false);
      setEditIndex(-1);
      console.log(e);
      window.alert('An error occurred saving Order Bumps. Refresh the page and try again.');
    }
  };

  const deleteOrderBump = async () => {
    setLoading(true);

    const products = [ ...orderBumps ];

    products.splice(deleteIndex, 1);

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        orderBumps: products,
      });

      setOrderBumps(products);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      console.log(e);
      window.alert('An error occurred deleting the Order Bump. Refresh the page and try again.');
    }
  };

  return (
    <div className="OrderBumps">
      {renderLoading()}
      <Card>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
            if (newValue === 1 && !hasShownPerformance) {
              setHasShownPerformance(true);
            }
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="order bump tabs"
        >
          <Tab label="All Order Bumps" {...a11yProps(0)} />
          <Tab label="Performance" {...a11yProps(1)} />
        </Tabs>
        <Divider />
        <SwipeableViews
          index={tabValue}
          onChangeIndex={(index) => { setTabValue(index) }}
          ref={swipeableViewsRef}
          animateHeight={true}
        >
          <div value={tabValue} index={0}>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h6">
                Order Bumps
              </Typography>
              <IconButton edge="start" color="inherit" onClick={() => {
                if (!simplifiedProducts.length) {
                  return;
                }

                setEditOrderBump({
                  active: false,
                  selectedProduct: simplifiedProducts[0],
                  rules: [],
                  title: '',
                  discountPrice: 9.99,
                  description: '',
                  priority: 1,
                });
                setEditIndex(-1);
                setEditing(true);
              }} aria-label="Add">
                <AddIcon />
              </IconButton>
            </Toolbar>
            <List>
              {orderBumps.map((p, i) => {
                return (
                  <div key={`order-bump-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={p.selectedProduct.name}
                        secondary={
                          <span>Status:
                            <span style={{color: p.active ? 'rgb(126, 210, 66)' : 'red'}}>
                              {p.active ? ' Active' : ' Disabled'}
                            </span>
                            <span style={{marginLeft: 10}}>
                              ID: {p.id}
                            </span>
                          </span>
                        }
                      />
                      <IconButton edge="start" style={{marginRight: 15}} color="inherit" onClick={() => {
                        setEditOrderBump(p);
                        setEditIndex(i);
                        setEditing(true);
                      }} aria-label="Edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
          <div value={tabValue} index={1}>
            {!hasShownPerformance ? null :
              <div>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6">
                    Order Bump Performance
                  </Typography>
                </Toolbar>
                <Divider/>
                <OrderBumpPerformance setOrdersLength={(newLength) => { setOrdersLength(newLength) }}/>
              </div>
            }
          </div>
        </SwipeableViews>
      </Card>

      <Dialog fullScreen open={editing} onClose={() => {
        setEditing(false);
        setEditIndex(-1);
      }} TransitionComponent={Transition}>
        <EditFeaturedProduct
          closeModal={() => {
            setEditing(false);
            setEditIndex(-1);
          }}
          save={saveOrderBump}
          product={editOrderBump}
          simplifiedProducts={simplifiedProducts}
          productCategories={productCategories}
          productTags={productTags}
          isOrderBump={true}
        ></EditFeaturedProduct>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Order Bump?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Order Bump?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteOrderBump} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OrderBumps;
