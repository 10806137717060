import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  CircularProgress,
  Toolbar,
  Typography,
  FormControlLabel,
  Switch,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './settings.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Settings(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [settings, setSettings] = useState({
    actions: [],
    enabled: true,
    detailsPath: '',
    pointsName: '',
    pointsCurrencyDisplay: '',
    pointsPerDollar: 0,
    dollarsPerPoint: 0,
    tiers: [],
    pointsPerReferral: 0,
    dollarsPerReferralPoint: 0,
    referralTiers: [],
    referralFirstOrderDiscountPercentage: 0,
    pointsPerReview: 0,
    dollarsPerReviewPoint: 0,
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [editTier, setEditTier] = useState({
    name: '',
    dollarsPerPoint: 0.01,
    dollarsSpentToActive: 500,
    pointsPerDollar: 10,
    enabled: true,
  });
  const [editTierOpen, setEditTierOpen] = useState(false);
  const [deleteReferralIndex, setDeleteReferralIndex] = useState(-1);
  const [editReferralIndex, setEditReferralIndex] = useState(-1);
  const [editReferralTier, setEditReferralTier] = useState({
    name: '',
    dollarsPerReferralPoint: 0.01,
    referralsToActivate: 10,
    pointsPerReferral: 10,
    enabled: true,
  });
  const [editReferralTierOpen, setEditReferralTierOpen] = useState(false);
  const [addUserActionModalOpen, setAddUserActionModalOpen] = useState(false);
  const [editUserActionIndex, setEditUserActionIndex] = useState(-1);
  const [userActionToAdd, setUserActionToAdd] = useState({
    id: `action-${Date.now()}`,
    enabled: false,
    type: 'link',
    name: '',
    description: '',
    thumbnailUrl: '',
    linkUrl: '',
    points: '',
    dollarsPerPoint: '',
  });
  const [actionDeleteIndex, setActionDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const db = firebase.firestore();

    try {
      const settingsSnapshot = await db.collection('config').doc('rewards').get();

      setSettings(settingsSnapshot.data());
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching reward settings. Refresh the page and try again.');
    }
  };

  const saveSettings = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const dataToSave = {
        enabled: settings.enabled,
        pointsName: settings.pointsName,
        detailsPath: settings.detailsPath,
        pointsCurrencyDisplay: settings.pointsCurrencyDisplay,
        pointsPerDollar: parseFloat(settings.pointsPerDollar),
        dollarsPerPoint: parseFloat(settings.dollarsPerPoint),
        tiers: settings.tiers.map(tier => {
          return {
            name: tier.name,
            dollarsPerPoint: parseFloat(tier.dollarsPerPoint),
            dollarsSpentToActive: parseFloat(tier.dollarsSpentToActive),
            pointsPerDollar: parseFloat(tier.pointsPerDollar),
            enabled: tier.enabled,
          };
        }),
        pointsPerReferral: parseFloat(settings.pointsPerReferral),
        dollarsPerReferralPoint: parseFloat(settings.dollarsPerReferralPoint),
        referralTiers: settings.referralTiers.map(tier => {
          return {
            name: tier.name,
            dollarsPerReferralPoint: parseFloat(tier.dollarsPerReferralPoint),
            referralsToActivate: parseFloat(tier.referralsToActivate),
            pointsPerReferral: parseFloat(tier.pointsPerReferral),
            enabled: tier.enabled,
          };
        }),
        referralFirstOrderDiscountPercentage: parseFloat(settings.referralFirstOrderDiscountPercentage),
        pointsPerReview: parseFloat(settings.pointsPerReview),
        dollarsPerReviewPoint: parseFloat(settings.dollarsPerReviewPoint),
        actions: settings.actions,
        enableAppReferralAction: settings.enableAppReferralAction,
        appReferralTitle: settings.appReferralTitle,
        pointsPerAppReferral: parseFloat(settings.pointsPerAppReferral),
        dollarsPerAppReferralPoint: parseFloat(settings.dollarsPerAppReferralPoint),
        enableAppReviewAction: settings.enableAppReviewAction,
        rateAppTitle: settings.rateAppTitle,
        pointsPerAppReview: parseFloat(settings.pointsPerAppReview),
        dollarsPerAppReviewPoint: parseFloat(settings.dollarsPerAppReviewPoint),
        enableChewyReviews: settings.enableChewyReviews,
        chewyReviewTitle: settings.chewyReviewTitle,
        chewyReviewAmount: parseFloat(settings.chewyReviewAmount),
        chewyNextAllowedRenewalDate: moment(settings.chewyNextAllowedRenewalDate || Date.now()).endOf('day').valueOf(),
        enablePetsmartReviews: settings.enablePetsmartReviews,
        petsmartReviewTitle: settings.petsmartReviewTitle,
        petsmartReviewAmount: parseFloat(settings.petsmartReviewAmount),
        petsmartNextAllowedRenewalDate: moment(settings.petsmartNextAllowedRenewalDate || Date.now()).endOf('day').valueOf(),
      };

      await db.collection('config').doc('rewards').update(dataToSave);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving reward settings. Please try again.');
    }
  };

  const handleAddAction = () => {
    const actions = [ ...settings.actions ];

    if (editUserActionIndex !== -1) {
      actions[editUserActionIndex] = {
        ...userActionToAdd,
        points: parseFloat(userActionToAdd.points),
        dollarsPerPoint: parseFloat(userActionToAdd.dollarsPerPoint),
      };
    } else {
      actions.push({
        ...userActionToAdd,
        points: parseFloat(userActionToAdd.points),
        dollarsPerPoint: parseFloat(userActionToAdd.dollarsPerPoint),
      });
    }

    setSettings({
      ...settings,
      actions,
    });

    setAddUserActionModalOpen(false);
    setEditUserActionIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="RewardsSettings">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Reward Settings
        </Typography>

        <Button size="small" variant="contained" onClick={saveSettings} color="secondary">
          Save
        </Button>
      </Toolbar>

      <div className="content">
        <div className="row">
          <div>
            <FormControlLabel
              style={{marginBottom: 15, marginTop: 0}}
              control={
                <Switch
                  checked={settings.enabled}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      enabled: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Rewards Program Enabled"
            />
          </div>
        </div>

        <div className="row">
          <TextField
            label="Reward Program Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={settings.detailsPath}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setSettings({
                ...settings,
                detailsPath: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="row">
          <TextField
            label="Reward Points Display Name (singular)"
            value={settings.pointsName}
            onChange={(e) => {
              setSettings({
                ...settings,
                pointsName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Points Currency Display"
            value={settings.pointsCurrencyDisplay}
            onChange={(e) => {
              setSettings({
                ...settings,
                pointsCurrencyDisplay: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              User Action Settings
            </Typography>
          </Toolbar>
        </div>

        <div className="row">
          <div>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                User Actions
              </Typography>

              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setUserActionToAdd({
                    id: `action-${Date.now()}`,
                    enabled: false,
                    type: 'link',
                    name: '',
                    description: '',
                    thumbnailUrl: '',
                    linkUrl: '',
                    points: '',
                    dollarsPerPoint: '',
                  });
                  setAddUserActionModalOpen(true);
                }}
              >
                Add User Action
              </Button>
            </Toolbar>
            <TableContainer>
              <Table aria-label="settings table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Active</strong></TableCell>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Points Earned</strong></TableCell>
                    <TableCell><strong>Dollar Amount / Point</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.actions.map((action, i) => (
                    <TableRow key={`setting-row-${i}`}>
                      <TableCell>{action.enabled ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <strong>{action.name}</strong>
                      </TableCell>
                      <TableCell>{action.points}</TableCell>
                      <TableCell>${action.dollarsPerPoint}</TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setEditUserActionIndex(i);
                          setUserActionToAdd({ ...action });
                          setAddUserActionModalOpen(true);
                        }} aria-label="Edit Action">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setActionDeleteIndex(i);
                        }} aria-label="Delete Action">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className="row">
          <div className="section-container">
            <div className="section-label">Chewy Reviews</div>

            <FormControlLabel
              style={{marginBottom: 15, marginTop: 0}}
              control={
                <Switch
                  checked={settings.enableChewyReviews || false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      enableChewyReviews: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!settings.enableChewyReviews ? null :
              <>
                <TextField
                  label="Display Name"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.chewyReviewTitle || ''}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      chewyReviewTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />
      
                <TextField
                  label="Displayed Reward Amount"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.chewyReviewAmount || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      chewyReviewAmount: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />

                <label>Users can only submit new reviews after this date</label>
                <DatePicker
                  selected={settings.chewyNextAllowedRenewalDate ? new Date(settings.chewyNextAllowedRenewalDate) : new Date()}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const chewyNextAllowedRenewalDate = moment(date).valueOf();

                    setSettings({
                      ...settings,
                      chewyNextAllowedRenewalDate,
                    });
                  }}
                />
              </>
            }
          </div>
        </div>

        <div className="row">
          <div className="section-container">
            <div className="section-label">PetSmart Reviews</div>

            <FormControlLabel
              style={{marginBottom: 15, marginTop: 0}}
              control={
                <Switch
                  checked={settings.enablePetsmartReviews || false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      enablePetsmartReviews: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!settings.enablePetsmartReviews ? null :
              <>
                <TextField
                  label="Display Name"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.petsmartReviewTitle || ''}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      petsmartReviewTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />
      
                <TextField
                  label="Displayed Reward Amount"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.petsmartReviewAmount || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      petsmartReviewAmount: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />

                <label>Users can only submit new reviews after this date</label>
                <DatePicker
                  selected={settings.petsmartNextAllowedRenewalDate ? new Date(settings.petsmartNextAllowedRenewalDate) : new Date()}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const petsmartNextAllowedRenewalDate = moment(date).valueOf();

                    setSettings({
                      ...settings,
                      petsmartNextAllowedRenewalDate,
                    });
                  }}
                />
              </>
            }
          </div>
        </div>

        <div className="row">
          <div className="section-container">
            <div className="section-label">App Referrals</div>

            <FormControlLabel
              style={{marginBottom: 15, marginTop: 0}}
              control={
                <Switch
                  checked={settings.enableAppReferralAction || false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      enableAppReferralAction: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!settings.enableAppReferralAction ? null :
              <>
                <TextField
                  label="Display Name"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.appReferralTitle || ''}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      appReferralTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <TextField
                  label="Points Earned"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.pointsPerAppReferral || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      pointsPerAppReferral: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />
      
                <TextField
                  label="Dollars per Point"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.dollarsPerAppReferralPoint || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      dollarsPerAppReferralPoint: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />
              </>
            }
          </div>
        </div>

        <div className="row">
          <div className="section-container">
            <div className="section-label">App Review</div>

            <FormControlLabel
              style={{marginBottom: 15, marginTop: 0}}
              control={
                <Switch
                  checked={settings.enableAppReviewAction || false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      enableAppReviewAction: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!settings.enableAppReviewAction ? null :
              <>
                <TextField
                  label="Display Name"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.rateAppTitle || ''}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      rateAppTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <TextField
                  label="Points Earned"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.pointsPerAppReview || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      pointsPerAppReview: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />
      
                <TextField
                  label="Dollars per Point"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  value={settings.dollarsPerAppReviewPoint || 0}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');

                    setSettings({
                      ...settings,
                      dollarsPerAppReviewPoint: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                />
              </>
            }
          </div>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Purchase Settings
            </Typography>
          </Toolbar>
        </div>

        <div className="row">
          <TextField
            label="Points Earned / Dollar Spent"
            value={settings.pointsPerDollar}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                pointsPerDollar: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollar Amount / Point"
            value={settings.dollarsPerPoint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                dollarsPerPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </div>

        <div className="row">
          <div>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                Reward Tiers
              </Typography>

              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setEditIndex(-1);
                  setEditTier({
                    name: `Tier ${settings.tiers.length + 1}`,
                    dollarsPerPoint: 0.01,
                    dollarsSpentToActive: 500,
                    pointsPerDollar: 10,
                    enabled: true,
                  });
                  setEditTierOpen(true);
                }}
                color="primary"
              >
                Add Tier
              </Button>
            </Toolbar>
            <TableContainer>
              <Table aria-label="settings table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Amount Spent</strong></TableCell>
                    <TableCell><strong>Points Earned / Dollar Spent</strong></TableCell>
                    <TableCell><strong>Dollar Amount / Point</strong></TableCell>
                    <TableCell><strong>Active</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.tiers.map((tier, i) => (
                    <TableRow key={`setting-row-${i}`}>
                      <TableCell>
                        <strong>{tier.name}</strong>
                      </TableCell>
                      <TableCell>{formatter.format(tier.dollarsSpentToActive)}</TableCell>
                      <TableCell>{tier.pointsPerDollar}</TableCell>
                      <TableCell>${tier.dollarsPerPoint}</TableCell>
                      <TableCell>{tier.enabled ? 'Yes' : 'No'}</TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setEditIndex(i);
                          setEditTier({ ...tier });
                          setEditTierOpen(true);
                        }} aria-label="Edit Tier">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setDeleteIndex(i);
                        }} aria-label="Delete Tier">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Referral Settings
            </Typography>
          </Toolbar>
        </div>

        <div className="row">
          <TextField
            label="Points Earned / Referral"
            value={settings.pointsPerReferral}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                pointsPerReferral: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollar Amount / Point"
            value={settings.dollarsPerReferralPoint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                dollarsPerReferralPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </div>

        <div className="row">
          <TextField
            label="Referral First Order Discount Percentage"
            value={settings.referralFirstOrderDiscountPercentage}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                referralFirstOrderDiscountPercentage: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </div>

        <div className="row">
          <div>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                Reward Tiers
              </Typography>

              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setEditReferralIndex(-1);
                  setEditReferralTier({
                    name: '',
                    dollarsPerReferralPoint: 0.01,
                    referralsToActivate: 10,
                    pointsPerReferral: 10,
                    enabled: true,
                  });
                  setEditReferralTierOpen(true);
                }}
                color="primary"
              >
                Add Tier
              </Button>
            </Toolbar>
            <TableContainer>
              <Table aria-label="settings table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Referrals to Activate</strong></TableCell>
                    <TableCell><strong>Points Earned / Referral</strong></TableCell>
                    <TableCell><strong>Dollar Amount / Point</strong></TableCell>
                    <TableCell><strong>Active</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.referralTiers.map((tier, i) => (
                    <TableRow key={`referral-setting-row-${i}`}>
                      <TableCell>
                        <strong>{tier.name}</strong>
                      </TableCell>
                      <TableCell>{tier.referralsToActivate}</TableCell>
                      <TableCell>{tier.pointsPerReferral}</TableCell>
                      <TableCell>${tier.dollarsPerReferralPoint}</TableCell>
                      <TableCell>{tier.enabled ? 'Yes' : 'No'}</TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setEditReferralIndex(i);
                          setEditReferralTier({ ...tier });
                          setEditReferralTierOpen(true);
                        }} aria-label="Edit Tier">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => {
                          setDeleteReferralIndex(i);
                        }} aria-label="Delete Tier">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Review Settings
            </Typography>
          </Toolbar>
        </div>

        <div className="row">
          <TextField
            label="Points Earned / Review"
            value={settings.pointsPerReview || 0}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                pointsPerReview: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollar Amount / Point"
            value={settings.dollarsPerReviewPoint || 0}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setSettings({
                ...settings,
                dollarsPerReviewPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={addUserActionModalOpen}
        onClose={() => {
          setAddUserActionModalOpen(false);
          setEditUserActionIndex(-1);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{editUserActionIndex !== -1 ? 'Edit' : 'Add'} User Action</DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{marginBottom: 15, marginTop: 0}}
            control={
              <Switch
                checked={userActionToAdd.enabled}
                onChange={(e) => {
                  setUserActionToAdd({
                    ...userActionToAdd,
                    enabled: e.target.checked,
                  });
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Active"
          />

          <FormControl
            style={{ marginTop: 10, marginBottom: 10 }}
            variant="outlined"
            margin="dense"
            className="day-text-field"
          >
            <InputLabel id="action-type">
              Type
            </InputLabel>
            <Select
              value={userActionToAdd.type}
              onChange={(e) => {
                setUserActionToAdd({
                  ...userActionToAdd,
                  type: e.target.value,
                });
              }}
              label="Type"
            >
              <MenuItem value="link">Link</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Name"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.name}
            onChange={(e) => {
              setUserActionToAdd({
                ...userActionToAdd,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description (optional)"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.description}
            onChange={(e) => {
              setUserActionToAdd({
                ...userActionToAdd,
                description: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Thumbnail URL (optional)"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.thumbnailUrl}
            onChange={(e) => {
              setUserActionToAdd({
                ...userActionToAdd,
                thumbnailUrl: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Link URL"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.linkUrl}
            onChange={(e) => {
              setUserActionToAdd({
                ...userActionToAdd,
                linkUrl: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Points Earned"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.points}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setUserActionToAdd({
                ...userActionToAdd,
                points: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollars per Point"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={userActionToAdd.dollarsPerPoint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setUserActionToAdd({
                ...userActionToAdd,
                dollarsPerPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleAddAction}
            color="secondary"
            disabled={!userActionToAdd.name || !userActionToAdd.linkUrl || !userActionToAdd.dollarsPerPoint || !userActionToAdd.points}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddUserActionModalOpen(false);
              setEditUserActionIndex(-1);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={actionDeleteIndex !== -1} onClose={() => { setActionDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete {actionDeleteIndex === -1 ? '' : settings.actions[actionDeleteIndex].name} Action?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the <strong>{actionDeleteIndex === -1 ? '' : settings.actions[actionDeleteIndex].name}</strong> Action? You must hit "SAVE" after deleting for these changes to take effect.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const actions = [ ...settings.actions ];
              actions.splice(actionDeleteIndex, 1);

              setSettings({
                ...settings,
                actions,
              });
              setActionDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setActionDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={editTierOpen} onClose={() => { setEditTierOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>{editIndex === -1 ? 'Add' : 'Edit '}{editIndex === -1 ? '' : settings.tiers[editIndex].name} Purchase Tier</DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{marginBottom: 15, marginTop: 0}}
            control={
              <Switch
                checked={editTier.enabled}
                onChange={(e) => {
                  setEditTier({
                    ...editTier,
                    enabled: e.target.checked,
                  });
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Active"
          />

          <TextField
            label="Name"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editTier.name}
            onChange={(e) => {
              setEditTier({
                ...editTier,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Amount Spent"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editTier.dollarsSpentToActive}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditTier({
                ...editTier,
                dollarsSpentToActive: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Points Earned / Dollar Spent"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editTier.pointsPerDollar}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditTier({
                ...editTier,
                pointsPerDollar: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollar Amount / Point"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editTier.dollarsPerPoint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditTier({
                ...editTier,
                dollarsPerPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const tiers = [ ...settings.tiers ];

              if (editIndex === -1) {
                tiers.push(editTier);
              } else {
                tiers[editIndex] = editTier;
              }

              setSettings({
                ...settings,
                tiers,
              });
              setEditTierOpen(false);
            }}
            color="secondary"
            disabled={!editTier.name || !editTier.dollarsPerPoint || !editTier.dollarsSpentToActive || !editTier.pointsPerDollar}
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setEditTierOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={editReferralTierOpen} onClose={() => { setEditReferralTierOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>{editReferralIndex === -1 ? 'Add' : 'Edit '}{editReferralIndex === -1 ? '' : settings.referralTiers[editReferralIndex].name} Referral Tier</DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{marginBottom: 15, marginTop: 0}}
            control={
              <Switch
                checked={editReferralTier.enabled}
                onChange={(e) => {
                  setEditReferralTier({
                    ...editReferralTier,
                    enabled: e.target.checked,
                  });
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Active"
          />

          <TextField
            label="Name"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editReferralTier.name}
            onChange={(e) => {
              setEditReferralTier({
                ...editReferralTier,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Referrals to Active"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editReferralTier.referralsToActivate}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditReferralTier({
                ...editReferralTier,
                referralsToActivate: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Points Earned / Referral"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editReferralTier.pointsPerReferral}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditReferralTier({
                ...editReferralTier,
                pointsPerReferral: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />

          <TextField
            label="Dollar Amount / Point"
            style={{ marginTop: 10, marginBottom: 10 }}
            value={editReferralTier.dollarsPerReferralPoint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');

              setEditReferralTier({
                ...editReferralTier,
                dollarsPerReferralPoint: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const referralTiers = [ ...settings.referralTiers ];

              if (editReferralIndex === -1) {
                referralTiers.push(editReferralTier);
              } else {
                referralTiers[editReferralIndex] = editReferralTier;
              }

              setSettings({
                ...settings,
                referralTiers,
              });
              setEditReferralTierOpen(false);
            }}
            color="secondary"
            disabled={!editReferralTier.name || !editReferralTier.dollarsPerReferralPoint || !editReferralTier.referralsToActivate || !editReferralTier.pointsPerReferral}
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setEditReferralTierOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete {deleteIndex === -1 ? '' : settings.tiers[deleteIndex].name} Tier?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the <strong>{deleteIndex === -1 ? '' : settings.tiers[deleteIndex].name}</strong> Tier? You must hit "SAVE" after deleting for these changes to take effect.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const tiers = [ ...settings.tiers ];
              tiers.splice(deleteIndex, 1);

              setSettings({
                ...settings,
                tiers,
              });
              setDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteReferralIndex !== -1} onClose={() => { setDeleteReferralIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete {deleteReferralIndex === -1 ? '' : settings.referralTiers[deleteReferralIndex].name} Tier?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the <strong>{deleteReferralIndex === -1 ? '' : settings.referralTiers[deleteReferralIndex].name}</strong> Tier? You must hit "SAVE" after deleting for these changes to take effect.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const referralTiers = [ ...settings.referralTiers ];
              referralTiers.splice(deleteReferralIndex, 1);

              setSettings({
                ...settings,
                referralTiers,
              });
              setDeleteReferralIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteReferralIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Settings;
