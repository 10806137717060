import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import NumberFormat from 'react-number-format';
import {
  Button,
  Slide,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';

import './editOrder.scss';
import { config } from '../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditOrder(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [order, setOrder] = useState(null);
  const [editAddressType, setEditAddressType] = useState('');
  const [editAddress, setEditAddress] = useState({
    firstName: '',
    lastName: '',
    address: '',
    addressSecondary: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [maxRefundAmount, setMaxRefundAmount] = useState(0);
  const [disableRefunds, setDisableRefunds] = useState(false);
  const [amountRefunded, setAmountRefunded] = useState('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [resendOrderConfirmationOpen, setResendOrderConfirmationOpen] = useState(false);
  const [upsellPayments, setUpsellPayments] = useState([]);
  const [upsellRefundIndex, setUpsellRefundIndex] = useState(-1);

  useEffect(() => {
    getOrder();
  }, []);

  useEffect(() => {
    if (order) {
      const oneClickUpsellAmounts = [];
  
      let orderTotal = +order.total;
  
      order.products.forEach(p => {
        if (p.isOneClickUpsell) {
          oneClickUpsellAmounts.push(+p.paidPrice);
          orderTotal -= +p.paidPrice;
        }
      });
  
      let maxAmount = (Math.round((orderTotal + Number.EPSILON) * 100) / 100);

      if (order.refunds && order.refunds.length) {
        let totalRefunds = 0;
        let totalOriginalOrderRefunds = 0;
  
        order.refunds.forEach(r => {
          totalRefunds += +r.amount;

          if (!oneClickUpsellAmounts.includes(+r.amount)) {
            totalOriginalOrderRefunds += +r.amount;
          }
        });
  
        setAmountRefunded(totalRefunds.toFixed(2));
  
        if (totalOriginalOrderRefunds >= +maxAmount) {
          setDisableRefunds(true);
        } else {
          setDisableRefunds(false);
        }
      } else if (!order.paypalPaymentId && !order.stripeChargeId && !order.afterpayPaymentId && !order.stripePaymentIntentId) {
        setDisableRefunds(true);
        setAmountRefunded('');
      } else {
        setDisableRefunds(false);
        setAmountRefunded('');
      }

      if (order.paypalUpsellPayments) {
        const upsellPaymentsWithRefunds = order.paypalUpsellPayments.map((p, i) => {
          const amount = oneClickUpsellAmounts[i];
          let hasBeenRefunded = false;

          if (order.refunds && order.refunds.length) {
            const refund = order.refunds.find(r => {
              return +r.amount === +amount;
            });

            if (refund) {
              hasBeenRefunded = true;
            }
          }

          return {
            payment: p,
            amount,
            hasBeenRefunded,
          };
        });

        setUpsellPayments(upsellPaymentsWithRefunds);
      }

      if (order.stripeUpsellPayments) {
        const upsellPaymentsWithRefunds = order.stripeUpsellPayments.map((p, i) => {
          const amount = oneClickUpsellAmounts[i];
          let hasBeenRefunded = false;

          if (order.refunds && order.refunds.length) {
            const refund = order.refunds.find(r => {
              return +r.amount === +amount;
            });

            if (refund) {
              hasBeenRefunded = true;
            }
          }

          return {
            payment: p,
            amount,
            hasBeenRefunded,
          };
        });

        setUpsellPayments(upsellPaymentsWithRefunds);
      }
    }
  }, [order]);

  const getOrder = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/shop/orders');
        return;
      }

      const orderSnapshot = await db.collection('orders-v2').doc(id).get();
      const orderData = orderSnapshot.data();

      setOrder({
        ...orderData,
        id: orderSnapshot.id,
      });

      if (orderData.subscriptions && orderData.subscriptions.length) {
        const subscriptionsSnapshot = await db.collection('subscriptions').where('parentOrderId', '==', orderSnapshot.id).get();
        const subscriptionsData = subscriptionsSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        console.log(subscriptionsData);

        setSubscriptions(subscriptionsData);
      }

      setLoading(false);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching the order. Please try again.');
    }
  };

  const submitEditAddress = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const dataToUpdate = {
        [editAddressType]: editAddress,
      };

      await db.collection('orders-v2').doc(order.id).update(dataToUpdate);

      setOrder({
        ...order,
        ...dataToUpdate,
      });
      setLoading(false);
      setEditAddressType('');
    } catch (e) {
      console.log('address error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the address. Please try again.');
    }
  };

  const submitRefundForUpsell = async () => {
    const amount = upsellPayments[upsellRefundIndex].amount;

    setLoading(true);

    try {
      let refunds = [];

      if (order.refunds) {
        refunds = [ ...order.refunds ];
      }

      const token = await firebase.auth().currentUser.getIdToken();

      const orderPaymentType = order.paymentType;

      if (orderPaymentType === 'stripe' || orderPaymentType === 'applePay') {
        const refundData = {
          amount: Number(((+amount).toFixed(2)).replace('.', '')),
        }

        let refundUrl;
        
        if (orderPaymentType === 'stripe') {
          refundUrl = `${config.gateway}/order-service/api/v1/admin/stripe/charges/refund`;
          refundData.charge = upsellPayments[upsellRefundIndex].payment;
        } else if (orderPaymentType === 'applePay') {
          refundUrl = `${config.gateway}/order-service/api/v1/admin/stripe/payment-intent/refund`;
          refundData.paymentIntent = upsellPayments[upsellRefundIndex].payment;
        }

        const stripeResult = await axios.post(refundUrl, refundData, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (stripeResult.data.status && stripeResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(stripeResult.data.message);
          return;
        }

        refunds.push({
          ...stripeResult.data,
          amount: (+amount).toFixed(2),
        });
      } else if (orderPaymentType === 'paypal') {
        const saleId = upsellPayments[upsellRefundIndex].payment;
        const payPalResult = await axios.post(`${config.gateway}/order-service/api/v1/admin/paypal/sales/${saleId}/refund`, {
          amount: (+amount).toFixed(2),
          currency: 'USD',
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (payPalResult.data.status && payPalResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(payPalResult.data.message);
          return;
        }

        refunds.push({
          ...payPalResult.data,
          amount: (+amount).toFixed(2),
        });
      }

      const updatedUpsellPayments = [ ...upsellPayments ];

      updatedUpsellPayments[upsellRefundIndex] = {
        ...updatedUpsellPayments[upsellRefundIndex],
        hasBeenRefunded: true,
      };

      setUpsellPayments(updatedUpsellPayments);

      const latestRefundDate = moment().valueOf();

      await firebase.firestore().collection('orders-v2').doc(order.id).update({
        refunds,
        latestRefundDate,
      });

      setOrder({
        ...order,
        refunds,
        latestRefundDate,
      });
      setUpsellRefundIndex(-1);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The refund has been submitted.');
    } catch (e) {
      console.log('error in refund', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error submitting the refund. Please try again.');
    }
  };

  const submitRefund = async () => {
    console.log(refundAmount);
    console.log(Number(((+refundAmount).toFixed(2)).replace('.', '')));

    setLoading(true);

    try {
      let refunds = [];

      if (order.refunds) {
        refunds = [ ...order.refunds ];
      }

      const token = await firebase.auth().currentUser.getIdToken();

      if (order.paymentType === 'stripe') {
        const stripeResult = await axios.post(`${config.gateway}/order-service/api/v1/admin/stripe/charges/refund`, {
          amount: Number(((+refundAmount).toFixed(2)).replace('.', '')),
          charge: order.stripeChargeId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (stripeResult.data.status && stripeResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(stripeResult.data.message);
          return;
        }

        refunds.push({
          ...stripeResult.data,
          amount: (+refundAmount).toFixed(2),
        });
      } else if (order.paymentType === 'applePay') {
        const stripeResult = await axios.post(`${config.gateway}/order-service/api/v1/admin/stripe/payment-intent/refund`, {
          amount: Number(((+refundAmount).toFixed(2)).replace('.', '')),
          paymentIntent: order.stripePaymentIntentId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (stripeResult.data.status && stripeResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(stripeResult.data.message);
          return;
        }

        refunds.push({
          ...stripeResult.data,
          amount: (+refundAmount).toFixed(2),
        });
      } else if (order.paymentType === 'paypal') {
        const saleId = order.paypalPaymentId;
        const payPalResult = await axios.post(`${config.gateway}/order-service/api/v1/admin/paypal/sales/${saleId}/refund`, {
          amount: (+refundAmount).toFixed(2),
          currency: 'USD',
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (payPalResult.data.status && payPalResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(payPalResult.data.message);
          return;
        }

        refunds.push({
          ...payPalResult.data,
          amount: (+refundAmount).toFixed(2),
        });
      } else if (order.paymentType === 'afterpay') {
        const refundId = `${order.id}-${refunds.length + 1}`;
        const afterpayResult = await axios.post(`${config.gateway}/order-service/api/v1/admin/afterpay/payment/${order.afterpayPaymentId}/refund`, {
          amount: {
            amount: (+refundAmount).toFixed(2),
            currency: 'USD',
          },
          requestId: refundId,
          merchantReference: refundId,
          refundMerchantReference: refundId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (afterpayResult.data.status && afterpayResult.data.status === 'error') {
          setLoading(false);
          setModalTitle('Error:');
          setModalText(afterpayResult.data.message);
          return;
        }

        refunds.push({
          ...afterpayResult.data,
          amount: (+refundAmount).toFixed(2),
        });
      }

      const latestRefundDate = moment().valueOf();

      await firebase.firestore().collection('orders-v2').doc(order.id).update({
        refunds,
        latestRefundDate,
      });

      setOrder({
        ...order,
        refunds,
        latestRefundDate,
      });
      setRefundModalOpen(false);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The refund has been submitted.');
    } catch (e) {
      console.log('error in refund', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error submitting the refund. Please try again.');
    }
  };

  const markAsCanceled = async () => {
    setCancelModalOpen(false);
    setLoading(true);

    try {
      await firebase.firestore().collection('orders-v2').doc(order.id).update({
        status: 'CANCELED',
      });

      setOrder({
        ...order,
        status: 'CANCELED',
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred marking order as canceled. Please try again.');
    }
  };

  const sendOrderConfirmationEmail = async () => {
    setResendOrderConfirmationOpen(false);
    setLoading(true);

    try {
      const result = await axios.post(`${config.functions}/resendOrderConfirmationEmail`, {
        orderId: order.id,
      });

      setLoading(false);

      if (result.data.error) {
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      setModalTitle('Success!');
      setModalText('The confirmation email for this order has been sent.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred sending the order confirmation email. Please try again.');
    }
  };

  const renderPaymentDetails = () => {
    switch (order.paymentType) {
      case 'stripe':
        return <span> Stripe ({order.stripeChargeId})</span>;
      case 'paypal':
        return <span> PayPal ({order.paypalPaymentId})</span>;
      case 'afterpay':
        return <span> Afterpay ({order.afterpayPaymentId})</span>;
      case 'applePay':
        return <span> Apple Pay - Stripe ({order.stripePaymentIntentId})</span>;
      default:
        return '';
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditOrder">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/shop/orders">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Orders
          </Button>
        </Link>

        <div>
          <div className="order-primary-actions">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{marginBottom: '15px'}}
              disabled={disableRefunds}
              onClick={() => {
                const oneClickUpsellAmounts = [];
                let orderTotal = +order.total;

                order.products.forEach(p => {
                  if (p.isOneClickUpsell) {
                    oneClickUpsellAmounts.push(+p.paidPrice);
                    orderTotal -= +p.paidPrice;
                  }
                });

                let maxAmount = (Math.round((orderTotal + Number.EPSILON) * 100) / 100);

                if (order.refunds && order.refunds.length) {
                  let totalOriginalOrderRefunds = 0;

                  order.refunds.forEach(r => {

                    if (!oneClickUpsellAmounts.includes(+r.amount)) {
                      totalOriginalOrderRefunds += +r.amount;
                    }
                  });

                  maxAmount = maxAmount - totalOriginalOrderRefunds;
                }

                setMaxRefundAmount(maxAmount);
                setRefundAmount(maxAmount);
                setRefundModalOpen(true);
              }}
            >
              Refund{upsellPayments.length ? ' Original Order' : ''}
            </Button>

            {!order || order.status === 'COMPLETED' || order.status === 'CANCELED' ? null : 
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{marginRight: '10px', marginBottom: '15px'}}
                onClick={() => { setCancelModalOpen(true) }}
              >
                Cancel
              </Button>
            }
          </div>

          <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            {upsellPayments.map((p, i) => {
              return (
                <Button
                  key={p.payment}
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{marginBottom: '15px', marginLeft: 10}}
                  disabled={p.hasBeenRefunded}
                  onClick={() => {
                    setUpsellRefundIndex(i);
                  }}
                >
                  Refund Upsell #{i + 1} (${(+p.amount).toFixed(2)})
                </Button>
              );
            })}
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{marginBottom: '15px'}}
              onClick={() => { setResendOrderConfirmationOpen(true) }}
            >
              Resend Confirmation Email
            </Button>
          </div>
        </div>
      </div>

      {!order ? null :
        <div>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography style={{marginRight: 10}}>
              Order: <strong>{order.id}</strong>
            </Typography>

            <Typography variant="body2">
              <strong>Date:</strong> {moment(order.created.seconds * 1000).format('lll')}
            </Typography>
          </Toolbar>

          <div className="content-container">
            <Typography variant="body2">
              <strong>Payment:</strong>
              {renderPaymentDetails()}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Status:</strong> {order.status}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10}}>
              <strong>Customer Email:</strong> {order.userEmail}
            </Typography>
            <Typography variant="body2" style={{marginTop: 10, marginBottom: 20}}>
              <strong>Customer ID:</strong> {order.userId}
            </Typography>

            <Divider/>

            <div className="addresses-row">
              <div className="address-container">
                <Typography variant="body2" style={{marginBottom: 10}}>
                  <strong>Billing: </strong>
                  <EditIcon
                    style={{fontSize: 14, cursor: 'pointer'}}
                    onClick={() => {
                      setEditAddressType('billing');
                      setEditAddress({
                        ...order.billing,
                      });
                    }}
                  />
                </Typography>
                <Typography variant="body2">{order.billing.firstName} {order.billing.lastName}</Typography>
                <Typography variant="body2">{order.billing.address} {order.billing.addressSecondary ? order.billing.addressSecondary : ''}</Typography>
                <Typography variant="body2">{!order.billing.city ? null : <span>{order.billing.city}, {order.billing.state} {order.billing.zip}</span>}</Typography>
                <Typography variant="body2">{order.billing.country}</Typography>
              </div>
              <div className="address-container">
                <Typography variant="body2" style={{marginBottom: 10}}>
                  <strong>Shipping: </strong>
                  <EditIcon
                    style={{fontSize: 14, cursor: 'pointer'}}
                    onClick={() => {
                      setEditAddressType('shipping');
                      setEditAddress({
                        ...order.shipping,
                      });
                    }}
                  />
                </Typography>
                <Typography variant="body2">{order.shipping.firstName} {order.shipping.lastName}</Typography>
                <Typography variant="body2">{order.shipping.address} {order.shipping.addressSecondary ? order.shipping.addressSecondary : ''}</Typography>
                <Typography variant="body2">{!order.shipping.city ? null : <span>{order.shipping.city}, {order.shipping.state} {order.shipping.zip}</span>}</Typography>
                <Typography variant="body2">{order.shipping.country}</Typography>
              </div>
            </div>

            <Divider/>

            {order.refunds && order.refunds.length &&
              <>
                <div className="refunds-container">
                  <Typography variant="body2" style={{marginBottom: 10}}>
                    <strong>Refunds:</strong> ${amountRefunded} refunded
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label="order refunds table">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Date</strong></TableCell>
                          <TableCell><strong>Amount</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.refunds.map((r, i) => (
                          <TableRow key={`refund-${i}`}>
                            <TableCell>{moment(r.create_time || r.refundedAt || (r.created * 1000)).format('lll')}</TableCell>
                            <TableCell>${r.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Divider/>
              </>
            }

            <div className="products-container">
              <Typography variant="body2" style={{marginBottom: 10}}>
                <strong>Products:</strong>
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="order products table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Product</strong></TableCell>
                      <TableCell align="center"><strong>Quantity</strong></TableCell>
                      <TableCell align="right"><strong>Price</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.products.map((p, i) => (
                      <TableRow key={`product-${i}`}>
                        <TableCell>
                          <div><strong>Name:</strong> {p.name}</div>
                          <div style={{marginTop: 5}}><strong>ID:</strong> {p.id}</div>
                          {!p.selectedBundleProducts ? null :
                            <div style={{marginTop: 5}}><strong>Products Included:</strong>
                              {p.selectedBundleProducts.map((b, i) => (
                                <div key={`bundle-product-${i}`} style={{marginTop: 5, marginLeft: 5}}>{i + 1}. {b.name}</div> ))
                              }
                            </div>
                          }
                        </TableCell>
                        <TableCell align="center">{p.quantity}</TableCell>
                        <TableCell align="right">${p.paidPrice}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <div className="totals-container">
                          <div className="totals-inner">
                            <div className="total-line">
                              <p>Subtotal:</p>
                              <p>${order.subtotal}</p>
                            </div>
                            <div className="total-line">
                              <p>Shipping:</p>
                              <p>${order.shippingAmount}</p>
                            </div>
                            <div className="total-line">
                              <p>Tax:</p>
                              <p>${order.taxAmount}</p>
                            </div>
                            {!order.rewardsDollarsUsed ? null :
                              <div className="total-line rewards-line">
                                <p>Rewards Dollars:</p>
                                <p>- ${(+order.rewardsDollarsUsed).toFixed(2)}</p>
                              </div>
                            }
                            <div className="total-line grand-total">
                              <p>Total:</p>
                              <p>${order.total}</p>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {!subscriptions.length ? null :
              <>
                <Divider />
                <div className="subscriptions-container">
                  <Typography variant="body2" style={{marginBottom: 10}}>
                    <strong>Subscriptions:</strong>
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label="order products table">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>ID</strong></TableCell>
                          <TableCell><strong>Created</strong></TableCell>
                          <TableCell><strong>Next Payment Date</strong></TableCell>
                          <TableCell><strong>Interval</strong></TableCell>
                          <TableCell align="right"><strong>Total</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriptions.map((s, i) => (
                          <TableRow key={`subscription-${i}`}>
                            <TableCell>
                              <Link to={`/shop/subscriptions/${s.id}`}>
                                <span style={{color: 'blue'}}>{s.id}</span>
                              </Link>
                            </TableCell>
                            <TableCell>{moment(s.created.seconds * 1000).format('lll')}</TableCell>
                            <TableCell>{s.active ? moment(s.nextPaymentDate).format('MMM Do, YYYY') : 'Suspended'}</TableCell>
                            <TableCell>Every {s.interval} {`${s.period}${s.interval === 1 ? '' : 's'}`}</TableCell>
                            <TableCell align="right">${s.total.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            }

          </div>
        </div>
      }

      <Dialog open={!!editAddressType} onClose={() => { setEditAddressType('') }} TransitionComponent={Transition}>
        <DialogTitle>Edit {editAddressType} address</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={editAddress.firstName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                firstName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Last Name"
            value={editAddress.lastName}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                lastName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Primary Address"
            value={editAddress.address}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                address: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Secondary Address"
            value={editAddress.addressSecondary}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                addressSecondary: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="City"
            value={editAddress.city}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                city: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="State"
            value={editAddress.state}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                state: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Zip"
            value={editAddress.zip}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                zip: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
          <TextField
            label="Country"
            value={editAddress.country}
            onChange={(e) => {
              setEditAddress({
                ...editAddress,
                country: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitEditAddress} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditAddressType('') }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={refundModalOpen} onClose={() => { setRefundModalOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Refund Order</DialogTitle>
        <DialogContent>
          <div className="input-container">
            <label>Refund Amount</label>
            <NumberFormat
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
              value={refundAmount}
              onValueChange={(values) => {
                setRefundAmount(values.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={
              !refundAmount ||
              (+refundAmount > +maxRefundAmount) ||
              (order.paymentType === 'afterpay' && order.products.length === 1 && +refundAmount !== +maxRefundAmount)
            }
            onClick={submitRefund} color="secondary"
          >
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setRefundModalOpen(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={upsellRefundIndex !== -1} onClose={() => { setUpsellRefundIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Refund Upsell #{upsellRefundIndex + 1}?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to refund this upsell for ${upsellRefundIndex !== -1 ? (+upsellPayments[upsellRefundIndex].amount).toFixed(2) : ''}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitRefundForUpsell} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setUpsellRefundIndex(-1) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelModalOpen} onClose={() => { setCancelModalOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Cancel Order?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to cancel this order? This will need to be manually canceled in Ordoro and TaxJar.</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={markAsCanceled} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setCancelModalOpen(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resendOrderConfirmationOpen} onClose={() => { setResendOrderConfirmationOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Resend Confirmation Email?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want resend the confirmation email for this order?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={sendOrderConfirmationEmail} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setResendOrderConfirmationOpen(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditOrder;
