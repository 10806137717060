import React, {
  useState,
  useEffect,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const cloneDeep = require('lodash.clonedeep');

function CheckoutProducts() {
  const [products, setProducts] = useState([]);
  const [checkoutProducts, setCheckoutProducts] = useState([]);
  const [docId, setDocId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('products').get();
        const products = [];
        querySnapshot.docs[0].data().detailed.forEach(p => {
          if (p.variations.length) {
            p.variations.forEach(v => {

              if (v.attributes && v.attributes[0]) {
                v.name = `${p.name} - ${v.attributes[0].option}`;
                v.parentId = p.id;
                if (v.attributes.length === 2) {
                  v.name += ` - ${v.attributes[1].option}`;
                }
              } else {
                v.name = p.name;
              }
              
              products.push(v);
            });
          } else {
            products.push(p);
          }
        });
        setProducts(products);
        console.log(products);
      } catch (e) {
        console.log(e.toString());
      }
    }
    fetchProducts();
  }, []);

  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('checkout-products').get();
        setCheckoutProducts(querySnapshot.docs[0].data().products);
        setDocId(querySnapshot.docs[0].id);
        console.log(querySnapshot.docs[0].data().products);
      } catch (e) {
        console.log(e.toString());
      }
    }
    fetchProducts();
  }, []);

  const saveProducts = async () => {
    const db = firebase.firestore();
    const copiedProducts = cloneDeep(checkoutProducts);

    try {
      await db.collection('checkout-products').doc(docId).update({
        'products': copiedProducts,
      });

      setMessage('Checkout Products Saved!')
      setOpen(true);
    } catch (e) {
      setMessage('Error: Changes Not saved.')
      setOpen(true);
    }
  };

  const addProduct = () => {
    const clone = cloneDeep(checkoutProducts);
    clone.push({
      productId: '',
      parentId: '',
      shippingClass: '',
      title: '',
      image: '',
      description: '',
      originalPrice: 9.99,
      salePrice: 7.99,
      name: '',
    });
    setCheckoutProducts(clone);
  };

  const handleChange = (e, i, type) => {
    const clone = cloneDeep(checkoutProducts);
    clone[i][type] = e.target.value;

    if (type === 'productId') {
      const found = products.find(p => p.id === e.target.value);
      clone[i].shippingClass = found.shipping_class;
      clone[i].name = found.name;
      if (found.parentId) {
        clone[i].parentId = found.parentId;
      } else {
        clone[i].parentId = '';
      }
    }

    setCheckoutProducts(clone);
  };

  const removeProduct = (i) => {
    const clone = cloneDeep(checkoutProducts);
    clone.splice(i, 1);
    setCheckoutProducts(clone);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Checkout Products
          </Typography>
          <div>
            <Button variant="contained" color="default" onClick={addProduct} style={{marginRight: '10px'}}>
              Add Product
            </Button>
            <Button variant="contained" color="primary" onClick={saveProducts}>
              Save
            </Button>
          </div>
        </Toolbar>
        <CardContent style={{textAlign: 'left'}}>
          {checkoutProducts.map((p, i) => {
            return (
              <div key={`${p.productId}-${i}`} style={{paddingBottom: '10px'}}>
                <hr/>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <h4>Product {i + 1}</h4>

                  <Button color="primary" onClick={() => { removeProduct(i) }}>
                    Remove
                  </Button>
                </div>

                <FormControl style={{width: '49%', marginRight: '2%'}}>
                  <InputLabel>
                    Product
                  </InputLabel>
                  <Select
                    style={{width: '100%'}}
                    value={checkoutProducts[i].productId}
                    onChange={(e) => { handleChange(e, i, 'productId') }}
                  >
                    <MenuItem value="">
                      <em>Select Product</em>
                    </MenuItem>
                    {products.map((op, j) => {
                      return <MenuItem key={`option-${i}-${j}`} value={op.id}>{op.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <TextField
                  label="Image URL"
                  value={checkoutProducts[i].image}
                  onChange={(e) => { handleChange(e, i, 'image') }}
                  margin="none"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  style={{width: '49%'}}
                />

                <TextField
                  label="Title"
                  value={checkoutProducts[i].title}
                  onChange={(e) => { handleChange(e, i, 'title') }}
                  margin="normal"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <TextField
                  label="Original Display Price"
                  value={checkoutProducts[i].originalPrice}
                  onChange={(e) => { handleChange(e, i, 'originalPrice') }}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  style={{width: '49%', marginRight: '2%'}}
                  className="day-text-field"
                />

                <TextField
                  label="Sale Price"
                  value={checkoutProducts[i].salePrice}
                  onChange={(e) => { handleChange(e, i, 'salePrice') }}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  style={{width: '49%'}}
                  className="day-text-field"
                />

                <TextField
                  label="Description"
                  value={checkoutProducts[i].description}
                  onChange={(e) => { handleChange(e, i, 'description') }}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                  multiline
                  rows="4"
                />

              </div>
            );
          })}
        </CardContent>
      </Card>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default CheckoutProducts;
