import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  Divider,
  IconButton,
  ListItemText,
  DialogContentText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './shippingClasses.scss';

function ShippingClasses(props) {
  const [editIndex, setEditIndex] = useState(-1);
  const [editClass, setEditClass] = useState({});
  const [showHasProductsWarning, setShowHasProductsWarning] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const duplicateId = () => {
    return props.classes.find((c, i) => {
      return c.id === editClass.id && editIndex !== i;
    });
  };

  return (
    <div className="ShippingClasses">
      <div className="add-container">
        <Button 
          variant="contained"
          color="primary"
          style={{marginRight: '10px', marginBottom: '5px'}}
          onClick={() => {
            setEditIndex(props.classes.length);
            setEditClass({
              name: '',
              id: '',
              description: '',
              productCount: 0,
            });
          }}
        >
          Add Class
        </Button>
      </div>

      <div>
        <List>
          {props.classes.map((c, i) => {
            return (
              <div key={c.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={c.name} secondary={<>
                    <strong>ID:</strong> <span className="list-value">{c.id}</span>
                    <strong>Description:</strong> <span className="list-value">{c.description}</span>
                    <strong>Product Count:</strong> <span className="list-value">{c.productCount}</span>
                  </>} />

                  <IconButton color="inherit" aria-label="Edit" onClick={() => {
                    setEditClass({ ...c });
                    setEditIndex(i);
                  }}>
                    <EditIcon style={{color: '#000'}} />
                  </IconButton>
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => {
                    if (c.productCount) {
                      setShowHasProductsWarning(true);
                      return;
                    }

                    setDeleteIndex(i);
                  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>

      <Dialog
        open={editIndex !== -1}
        onClose={() => { setEditIndex(-1) }}
      >
        <DialogTitle>{!props.classes[editIndex] ? 'Add' : 'Edit'} Shipping Class</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={editClass.name}
            onChange={(e) => {
              setEditClass({
                ...editClass,
                name: e.target.value,
              });
            }}
            margin="normal"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
          <TextField
            label="ID"
            value={editClass.id}
            onChange={(e) => {
              setEditClass({
                ...editClass,
                id: e.target.value,
              });
            }}
            margin="normal"
            variant="outlined"
            type="text"
            className="day-text-field"
            helperText={duplicateId() ? 'Must be unique' : ''}
            error={!!duplicateId()}
            disabled={!!editClass.productCount}
          />
          <TextField
            label="Description"
            value={editClass.description}
            onChange={(e) => {
              setEditClass({
                ...editClass,
                description: e.target.value,
              });
            }}
            margin="normal"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!editClass.name || !editClass.id || !editClass.description || !!duplicateId()}
            variant="contained"
            onClick={() => {
              props.save(editClass, editIndex);
              setEditIndex(-1);
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setEditIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showHasProductsWarning}
        onClose={() => { setShowHasProductsWarning(false) }}
      >
        <DialogTitle>Notice!</DialogTitle>
        <DialogContent>
          <DialogContentText>This shipping class is assigned to at least one product and cannot be deleted.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setShowHasProductsWarning(false) }} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteIndex !== -1}
        onClose={() => { setDeleteIndex(-1) }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete {deleteIndex === -1 ? '' : props.classes[deleteIndex].name}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            props.delete(deleteIndex);
            setDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShippingClasses;
