import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

import OneClickUpsellsPerformance from './oneClickUpsellsPerformance/oneClickUpsellsPerformance';
import './oneClickUpsells.scss';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function OneClickUpsells(props) {
  const swipeableViewsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [upsells, setUpsells] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [tabValue, setTabValue] = useState(0);
  const [hasShownPerformance, setHasShownPerformance] = useState(false);
  const [ordersLength, setOrdersLength] = useState(0);

  useEffect(() => {
    fetchOneClickUpsells();
  }, []);

  const fetchOneClickUpsells = async () => {
    try {
      const upsellsSnapshot = await firebase.firestore().collection('one-click-upsells').get();
      const upsellData = upsellsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setUpsells(upsellData);
    } catch (e) {
      console.log('e', e)
      window.alert('There was an error fetching One Click Upsells. Refresh the page and try again.');
    }

    setLoading(false);
  };

  useEffect(() => {
    if (swipeableViewsRef.current) {
      swipeableViewsRef.current
        .getChildContext()
        .swipeableViews.slideUpdateHeight();
    }
  }, [tabValue, upsells, ordersLength]);

  const addUpsell = async (data) => {
    setLoading(true);
    try {
      const upsell = await firebase.firestore().collection('one-click-upsells').add(data);

      setLoading(false);
      props.history.replace(`/shop/one-click-upsells/${upsell.id}`);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      window.alert('There was an error creating On Click Upsell. Please try again.');
    }
  };

  const deleteUpsell = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('one-click-upsells').doc(upsells[deleteIndex].id).delete();

      const upsellsCopy = [ ...upsells ];

      upsellsCopy.splice(deleteIndex, 1);
      setUpsells(upsellsCopy);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      console.log(e);
      window.alert('An error occurred deleting the Upsell. Refresh the page and try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="OneClickUpsells">
      {renderLoading()}
      <Card>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
            if (newValue === 1 && !hasShownPerformance) {
              setHasShownPerformance(true);
            }
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="one click upsell tabs"
        >
          <Tab label="All One Click Upsells" {...a11yProps(0)} />
          <Tab label="Performance" {...a11yProps(1)} />
        </Tabs>
        <Divider />
        <SwipeableViews
          index={tabValue}
          onChangeIndex={(index) => { setTabValue(index) }}
          ref={swipeableViewsRef}
          animateHeight={true}
        >
          <div value={tabValue} index={0}>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h6">
                One Click Upsells
              </Typography>
              <IconButton edge="start" color="inherit" onClick={() => {
                const firstPagePath = `offer-${Date.now()}`;

                addUpsell({
                  name: '',
                  description: '',
                  active: false,
                  pagePaths: [firstPagePath],
                  pages: [{
                    path: firstPagePath,
                    editorData: {
                      type: 'top-layer',
                      children: [],
                    },
                    sharedTemplates: [],
                    customCSS: '',
                    products: [],
                    acceptNextStep: 1,
                    rejectNextStep: 1,
                  }],
                  rules: [],
                  priority: 1,
                  lastModified: Date.now(),
                  lastModifiedBy: firebase.auth().currentUser.email,
                });
              }} aria-label="Add">
                <AddIcon />
              </IconButton>
            </Toolbar>

            <List>
              {upsells.map((p, i) => {
                return (
                  <div key={`upsell-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={p.name}
                        secondary={
                          <span>
                            <span style={{marginTop: 5, marginBottom: 5, display: 'block', marginRight: 10}}>
                              <span><span><span style={{fontWeight: 'bold'}}>Status:</span> <span style={{color: p.active ? 'rgb(126, 210, 66)' : 'red', marginRight: 10}}>{p.active ? 'Active' : 'Disabled'}</span></span> <span style={{fontWeight: 'bold'}}>Priority:</span> <span style={{marginRight: 10}}>{p.priority}</span> <span style={{fontWeight: 'bold'}}>Last Modified:</span> <span style={{marginRight: 10}}>{moment(p.lastModified).calendar()}</span> <span style={{fontWeight: 'bold'}}>Last Modified By:</span> {p.lastModifiedBy}</span>
                            </span>

                            <span style={{marginRight: 10}}>
                              {p.description}
                            </span>
                            
                            <span style={{marginRight: 10}}>
                              ID: {p.id}
                            </span>
                          </span>
                        }
                      />
                      <Link to={`/shop/one-click-upsells/${p.id}`}>
                        <IconButton edge="start" style={{marginRight: 15, color: '#000'}} aria-label="Edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
          <div value={tabValue} index={1}>
            {!hasShownPerformance ? null :
              <div>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6">
                    Upsell Performance
                  </Typography>
                </Toolbar>
                <Divider/>
                <OneClickUpsellsPerformance setOrdersLength={(newLength) => { setOrdersLength(newLength) }}/>
              </div>
            }
          </div>
        </SwipeableViews>
      </Card>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }}>
        <DialogTitle>Delete Upsell?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this upsell?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteUpsell} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OneClickUpsells;
