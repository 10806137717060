import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import SwipeableViews from 'react-swipeable-views';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TextField,
  Tabs,
  Tab,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

import DeletableChip from '../../shared/deletableChip';
import './rolesAndPermissions.scss';
import { config } from '../../../config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function RolesAndPermissions(props) {
  const swipeableViewsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [accessLevelResources, setAccessLevelResources] = useState([]);
  const [roles, setRoles] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [editGroupIndex, setEditGroupIndex] = useState(-1);
  const [editGroup, setEditGroup] = useState({
    value: '',
    name: '',
    hasAccessTo: [],
  });
  const [removeAdminIndex, setRemoveAdminIndex] = useState(-1);
  const [editAdminIndex, setEditAdminIndex] = useState(-1);
  const [editAdmin, setEditAdmin] = useState({
    email: '',
    roles: [],
    id: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (swipeableViewsRef.current) {
      swipeableViewsRef.current
        .getChildContext()
        .swipeableViews.slideUpdateHeight()
    }
  }, [tabValue, admins, roles]);

  const fetchData = async () => {
    const db = firebase.firestore();

    try {
      const rolesSnapshot = await db.collection('user-roles').doc('roles').get();
      const accessLevelsSnapshot = await db.collection('user-roles').doc('access-levels').get();
      const adminsSnapshot = await db.collection('users').where('role', '==', 'Writer').get();

      const mappedAdmins = adminsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          roles: data.roles || [],
        };
      });

      const rolesArr = rolesSnapshot.data().groups.map(group => {
        return {
          ...group,
          name: group.value.replace(/-/g, ' '),
        };
      });

      setRoles(rolesArr.sort((a, b) => a.name >= b.name ? 1 : -1 ));
      setAccessLevelResources(accessLevelsSnapshot.data().resources.sort((a, b) => a >= b ? 1 : -1 ));
      setAdmins(mappedAdmins.sort((a, b) => a.email >= b.email ? 1 : -1 ));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching Roles and Permissions data.');
    }
  };

  const saveGroup = async () => {
    setLoading(true);

    try {
      const groupsCopy = [ ...roles ];
      groupsCopy[editGroupIndex] = editGroup;
      groupsCopy.sort((a, b) => a.name >= b.name ? 1 : -1 );

      await firebase.firestore().collection('user-roles').doc('roles').update({
        groups: groupsCopy,
      });

      setRoles(groupsCopy);
      setLoading(false);
      closeEditGroup();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the admin group. Please try again.');
    }

    setLoading(false);
  };

  const closeEditGroup = () => {
    setEditGroup({
      value: '',
      name: '',
      hasAccessTo: [],
    });
    setEditGroupIndex(-1);
  };

  const addNewAdmin = async () => {
    const existingAdminIndex = admins.findIndex(admin => admin.email === newAdminEmail);

    if (existingAdminIndex !== -1) {
      setShowAddAdmin(false);
      setModalTitle('Error:');
      setModalText('The specified user already has admin access.');
      return;
    }

    setLoading(true);

    try {
      const snapshot = await firebase.firestore().collection('users').where('email', '==', newAdminEmail).get();

      if (!snapshot.docs.length) {
        setLoading(false);
        setShowAddAdmin(false);
        setModalTitle('Error:');
        setModalText('There specified user was not found.');
        return;
      }

      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/roles/${snapshot.docs[0].id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: [],
      });

      await firebase.firestore().collection('users').doc(snapshot.docs[0].id).update({
        role: 'Writer',
        roles: [],
      });

      setAdmins([
        ...admins,
        {
          ...snapshot.docs[0].data(),
          id: snapshot.docs[0].id,
          role: 'Writer',
          roles: [],
        },
      ]);
      setNewAdminEmail('');
      setLoading(false);
      setShowAddAdmin(false);
    } catch (e) {
      setLoading(false);
      setShowAddAdmin(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the admin. Please try again.');
    }
  };

  const removeAdmin = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/roles/${admins[removeAdminIndex].id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: [],
      });

      await firebase.firestore().collection('users').doc(admins[removeAdminIndex].id).update({
        role: '',
        roles: [],
      });

      const adminsCopy = [ ...admins ];
      adminsCopy.splice(removeAdminIndex, 1);

      setAdmins(adminsCopy);
      setLoading(false);
      setRemoveAdminIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error removing the admin. Please try again.');
      setRemoveAdminIndex(-1);
    }
  };

  const submitEditAdmin = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/roles/${editAdmin.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: editAdmin.roles,
      });

      await firebase.firestore().collection('users').doc(editAdmin.id).update({
        roles: editAdmin.roles,
      });

      const adminsCopy = [ ...admins ];
      adminsCopy[editAdminIndex] = {
        ...adminsCopy[editAdminIndex],
        roles: editAdmin.roles,
      };

      setAdmins(adminsCopy);
      setLoading(false);
      closeEditAdmin();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the admin. Please try again.');
      closeEditAdmin();
    }
  };

  const closeEditAdmin = () => {
    setEditAdminIndex(-1);
    setEditAdmin({
      email: '',
      roles: [],
      id: '',
    });
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="RolesAndPermissions">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Roles & Permissions
        </Typography>
      </Toolbar>

      <Tabs
        value={tabValue}
        onChange={(e, newValue) => {
          setTabValue(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="roles and permissions tabs"
        style={{backgroundColor: '#fff', borderLeft: '1px solid rgba(0, 0, 0, 0.12)', borderRight: '1px solid rgba(0, 0, 0, 0.12)'}}
      >
        <Tab label="Admin Groups" {...a11yProps(0)} />
        <Tab label="Admin Users" {...a11yProps(1)} />
      </Tabs>
      <Divider />
      <SwipeableViews
        index={tabValue}
        onChangeIndex={(index) => { setTabValue(index) }}
        ref={swipeableViewsRef}
        animateHeight={true}
      >
        <div value={tabValue} index={0}>
          <div className="content">
            <TableContainer component={Paper}>
              <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: 0, borderBottomWidth: '1px', borderStyle: 'solid'}}>
                <Typography variant="subtitle1">
                  Admin Groups
                </Typography>

                <IconButton
                  edge="start"
                  style={{color: '#000'}}
                  aria-label="add"
                  onClick={() => {
                    setEditGroup({
                      value: '',
                      name: '',
                      hasAccessTo: [],
                    });
                    setEditGroupIndex(roles.length);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Table aria-label="admins table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Group</strong></TableCell>
                    <TableCell><strong>Access</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map((role, i) => (
                    <TableRow key={`role-${i}`}>
                      <TableCell><div style={{textTransform: 'capitalize'}}>{role.name}</div></TableCell>
                      <TableCell>
                        {!role.hasAccessTo.length ?
                          'None Assigned' :
                          role.hasAccessTo.map((level, i) => {
                            return (
                              <span key={level} style={{textTransform: 'capitalize'}}>{level.replace(/-/g, ' ')}{i !== role.hasAccessTo.length - 1 && ', '}</span>
                            );
                          })
                        }
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          edge="start"
                          style={{marginLeft: 10, color: '#000'}}
                          aria-label="edit"
                          onClick={() => {
                            setEditGroup(role);
                            setEditGroupIndex(i);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div value={tabValue} index={1}>
          <div className="content">
            <TableContainer component={Paper}>
              <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: 0, borderBottomWidth: '1px', borderStyle: 'solid'}}>
                <Typography variant="subtitle1">
                  Admin Users
                </Typography>

                <IconButton
                  edge="start"
                  style={{color: '#000'}}
                  aria-label="add"
                  onClick={() => {
                    setNewAdminEmail('');
                    setShowAddAdmin(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Table aria-label="admins table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Email</strong></TableCell>
                    <TableCell><strong>User ID</strong></TableCell>
                    <TableCell><strong>Roles</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map((admin, i) => (
                    <TableRow key={admin.id}>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.id}</TableCell>
                      <TableCell>
                        {!admin.roles.length ?
                          'None Assigned' :
                          admin.roles.map((role, i) => {
                            return (
                              <span key={role} style={{textTransform: 'capitalize'}}>{role.replace(/-/g, ' ')}{i !== admin.roles.length - 1 && ', '}</span>
                            );
                          })
                        }
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          edge="start"
                          style={{marginLeft: 10, color: '#000'}}
                          aria-label="edit"
                          onClick={() => {
                            setEditAdminIndex(i);
                            setEditAdmin({
                              id: admin.id,
                              email: admin.email,
                              roles: admin.roles,
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} aria-label="remove" onClick={() => { setRemoveAdminIndex(i) }}>
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </SwipeableViews>

      <Dialog fullWidth maxWidth="sm" open={editGroupIndex !== -1} onClose={closeEditGroup} TransitionComponent={Transition}>
        <DialogTitle>{editGroupIndex === roles.length ? 'Add' : 'Edit'} Group</DialogTitle>
        <DialogContent>
          <TextField
            label="Group Name"
            helperText="Only letters, numbers and spaces allowed"
            value={editGroup.name}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-zA-Z ]/gi, '');
              setEditGroup({
                ...editGroup,
                name: value,
                value: value.toLowerCase().replace(/ /g, '-'),
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />

          <FormControl style={{ margin: 5, width: '99%'}}>
            <InputLabel htmlFor="select-multiple-chip">Access Resources</InputLabel>
            <Select
              multiple
              value={editGroup.hasAccessTo}
              onChange={(e) => {
                setEditGroup({
                  ...editGroup,
                  hasAccessTo: e.target.value,
                });
              }}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value}
                      label={value.replace(/-/g, ' ')}
                      style={{margin: 2, textTransform: 'capitalize'}}
                      color="primary"
                      onDelete={() => {
                        const index = editGroup.hasAccessTo.indexOf(value);

                        if (index === -1) {
                          return;
                        }

                        const hasAccessTo = [ ...editGroup.hasAccessTo ];

                        hasAccessTo.splice(index, 1);

                        setEditGroup({
                          ...editGroup,
                          hasAccessTo,
                        });
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {accessLevelResources.filter(resource => {
                return !editGroup.hasAccessTo.includes(resource);
              }).map(resource => (
                <MenuItem key={resource} value={resource} style={{textTransform: 'capitalize'}}>
                  {resource.replace(/-/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={saveGroup}
            disabled={!editGroup.name || !!roles.find((role, i) => {
              return role.name === editGroup.name.toLowerCase() && i !== editGroupIndex;
            })}
            color="secondary"
          >
            Submit
          </Button>
          <Button variant="contained" onClick={closeEditGroup} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={showAddAdmin} onClose={() => { setShowAddAdmin(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Admin</DialogTitle>
        <DialogContent>
          <Typography>Enter the email address associated with the account you would like to give admin access to. If the user does not already have an account, you can create one for them in the "Users" tab.</Typography>

          <TextField
            label="User Email"
            value={newAdminEmail}
            onChange={(e) => {
              setNewAdminEmail(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewAdmin} disabled={!emailRegex.test(newAdminEmail)} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setShowAddAdmin(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={editAdminIndex !== -1} onClose={closeEditAdmin} TransitionComponent={Transition}>
        <DialogTitle>Edit <strong>{editAdmin.email}</strong></DialogTitle>
        <DialogContent>
          <FormControl style={{ margin: 5, width: '99%'}}>
            <InputLabel htmlFor="select-multiple-chip">Admin Roles</InputLabel>
            <Select
              multiple
              value={editAdmin.roles}
              onChange={(e) => {
                setEditAdmin({
                  ...editAdmin,
                  roles: e.target.value,
                });
              }}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value}
                      label={value.replace(/-/g, ' ')}
                      style={{margin: 2, textTransform: 'capitalize'}}
                      color="primary"
                      onDelete={() => {
                        const index = editAdmin.roles.indexOf(value);

                        if (index === -1) {
                          return;
                        }

                        const adminRoles = [ ...editAdmin.roles ];

                        adminRoles.splice(index, 1);

                        setEditAdmin({
                          ...editAdmin,
                          roles: adminRoles,
                        });
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {roles.filter(role => {
                return !editAdmin.roles.includes(role.value);
              }).map(role => (
                <MenuItem key={role.value} value={role.value} style={{textTransform: 'capitalize'}}>
                  {role.value.replace(/-/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitEditAdmin} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={closeEditAdmin} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={removeAdminIndex !== -1} onClose={() => { setRemoveAdminIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Remove Admin?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove this admin? This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={removeAdmin} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setRemoveAdminIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RolesAndPermissions;
