import React, { useState, useEffect } from 'react';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Button,
  Typography,
  Card,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './insuranceClaims.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function InsuranceClaims(props) {
  const [loading, setLoading] = useState(true);
  const [claimData, setClaimData] = useState({});
  const [claims, setClaims] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [currentClaimIndex, setCurrentClaimIndex] = useState(-1);

  useEffect(() => {
    fetchClaims();
  }, []);

  const fetchClaims = async () => {
    const db = firebase.firestore();

    try {
      const dataSnapshot = await db.collection('shipping-claims').doc('data').get();
      const claimsSnapshot = await db.collection('shipping-claims').orderBy('created', 'desc').limit(pageCount).get();

      const claimsData = dataSnapshot.data();
      const claimPages = Math.ceil(claimsData.count / 10);

      const mappedClaims = claimsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setNumberOfPages(claimPages);
      setClaimData(claimsData);
      setClaims(mappedClaims);
      setFirst(claimsSnapshot.docs[0]);
      setLast(claimsSnapshot.docs[claimsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving claim data. Refresh the page and try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let claimsSnapshot;
      let newPage = page;

      if (value === 'next') {
        claimsSnapshot = await db.collection('shipping-claims').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        claimsSnapshot = await db.collection('shipping-claims').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedClaims = claimsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setClaims(mappedClaims);
      setFirst(claimsSnapshot.docs[0]);
      setLast(claimsSnapshot.docs[claimsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving claim data. Please try again.');
    }
  };

  const updateClaim = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('shipping-claims').doc(claims[currentClaimIndex].id).update({
        resolved: !claims[currentClaimIndex].resolved,
      });

      const claimsCopy = [ ...claims ];
      claimsCopy[currentClaimIndex] = {
        ...claimsCopy[currentClaimIndex],
        resolved: !claims[currentClaimIndex].resolved,
      };

      setClaims(claimsCopy);
      setLoading(false);
      setCurrentClaimIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this claim. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="InsuranceClaims">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Insurance Claims
          </Typography>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table aria-label="search orders table">
            <TableHead>
              <TableRow>
                <TableCell><strong>User</strong></TableCell>
                <TableCell><strong>Order ID</strong></TableCell>
                <TableCell><strong>Content</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Waiting Since</strong></TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claims.map((c, i) => (
                <TableRow key={c.id}>
                  <TableCell>
                    <div><strong>Email:</strong> {c.email}</div>
                    <div style={{marginTop: 5}}><strong>ID:</strong> {c.userId}</div>
                  </TableCell>
                  <TableCell>{c.orderId}</TableCell>
                  <TableCell>
                    <div className="reason-cell">
                      {c.reason}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{color: c.resolved ? '#7ED242' : 'red'}}>
                      {c.resolved ? 'Resolved' : 'Waiting'}
                    </div>
                  </TableCell>
                  <TableCell>{moment(c.created).fromNow()}</TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      style={{marginLeft: 10}}
                      color="default"
                      aria-label="edit"
                      onClick={() => setCurrentClaimIndex(i)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!numberOfPages ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog fullWidth maxWidth="sm" open={currentClaimIndex !== -1} onClose={() => { setCurrentClaimIndex(-1) }} TransitionComponent={Transition}>
        {currentClaimIndex === -1 ? null :
          <DialogContent>
            <DialogContentText><strong>Claim ID: {claims[currentClaimIndex].id}</strong></DialogContentText>
            <DialogContentText><strong>Date:</strong> {moment(claims[currentClaimIndex].created).format('lll')}</DialogContentText>
            <DialogContentText>
              <strong>Status: </strong>
              <span style={{color: claims[currentClaimIndex].resolved ? '#7ED242' : 'red'}}>
                {claims[currentClaimIndex].resolved ? 'Resolved' : 'Waiting'}
              </span>
            </DialogContentText>
            <DialogContentText><strong>Customer Email:</strong> {claims[currentClaimIndex].email}</DialogContentText>
            <DialogContentText><strong>Customer ID:</strong> {claims[currentClaimIndex].userId}</DialogContentText>
            <DialogContentText><strong>Order ID:</strong> {claims[currentClaimIndex].orderId}</DialogContentText>
            <DialogContentText><strong>Content:</strong></DialogContentText>
            <DialogContentText>{claims[currentClaimIndex].reason}</DialogContentText>
            <DialogContentText><strong>Updated:</strong> {moment(claims[currentClaimIndex].updated).format('lll')}</DialogContentText>
            <DialogContentText><strong>Last Updated By:</strong> {claims[currentClaimIndex].lastUpdatedBy}</DialogContentText>
          </DialogContent>
        }
        <DialogActions>
          <Button variant="contained" onClick={updateClaim} color="primary">
            Mark {currentClaimIndex !== -1 && claims[currentClaimIndex].resolved ? 'Unresolved' : 'Resolved'}
          </Button>
          <Button variant="contained" onClick={() => { setCurrentClaimIndex(-1) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InsuranceClaims;
