import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { ColorPicker, useColor, toColor } from '../../../shared/colorPalette';
import '../../../shared/colorPalette/css/styles.css';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Popover,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './editHeaderBanner.scss';

const priorityAmounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function EditHeaderBannerV2({
  closeModal,
  save,
  bannerData,
}) {
  const [banner, setBanner] = useState({
    name: '',
    backgroundColor: '#294634',
    imageUrl: '',
    text: '',
    textColor: '#294634',
    buttonText: '',
    buttonTextColor: '#294634',
    buttonBackgroundColor: '#FAF7E8',
    buttonUrl: '',
    priority: 1,
    live: false,
  });
  const [backgroundColor, setBackgroundColor] = useColor('hex', '#294634');
  const [textColor, setTextColor] = useColor('hex', '#FAF7E8');
  const [buttonTextColor, setButtonTextColor] = useColor('hex', '#294634');
  const [buttonBackgroundColor, setButtonBackgroundColor] = useColor('hex', '#FAF7E8');
  const [backgroundColorAnchorElem, setBackgroundColorAnchorElem] = useState(null);
  const [textColorAnchorElem, setTextColorAnchorElem] = useState(null);
  const [buttonTextColorAnchorElem, setButtonTextColorAnchorElem] = useState(null);
  const [buttonBackgroundColorAnchorElem, setButtonBackgroundColorAnchorElem] = useState(null);

  useEffect(() => {
    setBanner(cloneDeep(bannerData));
    setBackgroundColor(toColor('hex', bannerData.backgroundColor));
    setTextColor(toColor('hex', bannerData.textColor));
    setButtonTextColor(toColor('hex', bannerData.buttonTextColor));
    setButtonBackgroundColor(toColor('hex', bannerData.buttonBackgroundColor));
  }, [bannerData]);

  const backgroundColorOpen = Boolean(backgroundColorAnchorElem);
  const backgroundColorId = backgroundColorOpen ? 'background-color-popover' : undefined;
  const textColorOpen = Boolean(textColorAnchorElem);
  const textColorId = textColorOpen ? 'text-color-popover' : undefined;
  const buttonTextColorOpen = Boolean(buttonTextColorAnchorElem);
  const buttonTextColorId = buttonTextColorOpen ? 'button-text-color-popover' : undefined;
  const buttonBackgroundColorOpen = Boolean(buttonBackgroundColorAnchorElem);
  const buttonBackgroundColorId = buttonBackgroundColorOpen ? 'button-background-color-popover' : undefined;

  return (
    <div className="EditHeaderBanner">
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Banner
          </Typography>
          <Button color="inherit" onClick={() => save(banner)}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div
        className="banner-preview"
        style={{
          backgroundColor: banner.backgroundColor || 'gray',
          color: banner.textColor || '#000',
        }}
      >
        <div className="banner-scroll" style={{ display: 'flex', justifyContent: 'center', height: 70 }}>
          <div className="banner-title" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'BuenosAires, sans-serif', maxWidth: '1240px', marginLeft: 20, marginRight: 20 }}>
            <img src={banner.imageUrl} alt="banner" height={48} style={{ marginTop: 2 }} />
            <p style={{ fontSize: 15, width: '60%' }}>{banner.text}</p>
            <button style={{ backgroundColor: banner.buttonBackgroundColor || '#FAF7E8', color: banner.buttonTextColor || '#000', height: 50, borderRadius: 15 }}>{banner.buttonText}</button>
          </div>
        </div>
      </div>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={banner.live}
              onChange={(e) => setBanner({ ...banner, live: e.target.checked })}
              name="live"
              color="primary"
            />
          }
          label="Live"
        />

        <TextField
          label="Name (for internal use)"
          style={{ marginTop: 20 }}
          value={banner.name}
          onChange={(e) => setBanner({ ...banner, name: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Priority"
          style={{ marginTop: 20 }}
          margin="dense"
          value={banner.priority || priorityAmounts[priorityAmounts.length - 1]}
          onChange={(e) => setBanner({ ...banner, priority: e.target.value })}
          SelectProps={{ renderValue: value => value }}
          fullWidth
          variant="outlined"
          select
        >
          {priorityAmounts.map(amount => {
            return <MenuItem key={`priority-amount-${amount}`} value={amount}>{amount}</MenuItem>;
          })}
        </TextField>

        <TextField
          label="Image URL"
          style={{ marginTop: 20 }}
          value={banner.imageUrl}
          onChange={(e) => setBanner({ ...banner, imageUrl: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <div className="banner-settings-container">
          <div
            className="color-container last"
            onClick={(e) => setBackgroundColorAnchorElem(e.currentTarget)}
          >
            <p>Background Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.backgroundColor }}
            ></div>
          </div>

          <Popover
            id={backgroundColorId}
            open={backgroundColorOpen}
            anchorEl={backgroundColorAnchorElem}
            onClose={() => setBackgroundColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={backgroundColor}
              onChange={setBackgroundColor}
              onChangeComplete={(color) => setBanner({ ...banner, backgroundColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>
        </div>

        <TextField
          label="Banner Text"
          style={{ marginTop: 20 }}
          value={banner.text}
          onChange={(e) => setBanner({ ...banner, text: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <div className="banner-settings-container">
          <div
            className="color-container last"
            onClick={(e) => setTextColorAnchorElem(e.currentTarget)}
          >
            <p>Text Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.textColor }}
            ></div>
          </div>

          <Popover
            id={textColorId}
            open={textColorOpen}
            anchorEl={textColorAnchorElem}
            onClose={() => setTextColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={textColor}
              onChange={setTextColor}
              onChangeComplete={(color) => setBanner({ ...banner, textColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>
        </div>

        <TextField
          label="Button Text"
          style={{ marginTop: 20 }}
          value={banner.buttonText}
          onChange={(e) => setBanner({ ...banner, buttonText: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Button URL"
          style={{ marginTop: 20 }}
          value={banner.buttonUrl}
          onChange={(e) => setBanner({ ...banner, buttonUrl: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <div className="banner-settings-container">

          <div
            className="color-container"
            onClick={(e) => setButtonBackgroundColorAnchorElem(e.currentTarget)}
          >
            <p>Button Background Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.buttonBackgroundColor }}
            ></div>
          </div>

          <Popover
            id={buttonBackgroundColorId}
            open={buttonBackgroundColorOpen}
            anchorEl={buttonBackgroundColorAnchorElem}
            onClose={() => setButtonBackgroundColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={buttonBackgroundColor}
              onChange={setButtonBackgroundColor}
              onChangeComplete={(color) => setBanner({ ...banner, buttonBackgroundColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>

          <div
            className="color-container last"
            onClick={(e) => setButtonTextColorAnchorElem(e.currentTarget)}
          >
            <p>Button Text Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.buttonTextColor }}
            ></div>
          </div>

          <Popover
            id={buttonTextColorId}
            open={buttonTextColorOpen}
            anchorEl={buttonTextColorAnchorElem}
            onClose={() => setButtonTextColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={buttonTextColor}
              onChange={setButtonTextColor}
              onChangeComplete={(color) => setBanner({ ...banner, buttonTextColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default EditHeaderBannerV2;
