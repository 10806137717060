import React from 'react';

import './dashboard.scss';

function Dashboard(props) {
  return (
    <div className="MarketingContentDashboard">
      Marketing Tools
    </div>
  );
}

export default Dashboard;
