import React, { useState } from 'react';

import Modal from './buttonPreviewModal';
import DangerouslySetHtmlContent from '../shared/DangerouslySetHtmlContent';

function Button({ content, customStyles }) {
  const [modalOpen, setModalOpen] = useState(false);

  if (content.clickAction === 'link') {
    return (
      <a href={content.link} target={content.openLinkInNewTab ? '_blank' : ''} rel={content.linkNoFollow ? 'nofollow' : ''}>
        <button style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
      </a>
    );
  }

  if (content.clickAction === 'modal') {
    return (
      <>
        <button onClick={() => { setModalOpen(true) }} style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>

        <Modal
          open={modalOpen}
          close={() => { setModalOpen(false) }}
          title=""
          buttons={[]}
        >
          <DangerouslySetHtmlContent html={content.html} />
        </Modal>
      </>
    );
  }

  return (
    <button style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
  );
}

export default Button;
