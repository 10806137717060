import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import DatePicker from 'react-datepicker';
import {
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';

import './subscriptionForecastByProduct.scss';

const start = moment().toDate();
start.setHours(0, 0, 0, 0);

const end = moment().add(1, 'month').endOf('month').toDate();
end.setHours(23, 59, 59, 999);

function SubscriptionForecastByProduct({ selectedProducts, subscriptions }) {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dateInterval, setDateInterval] = useState('day');
  const [subscriptionsByProduct, setSubscriptionsByProduct] = useState([]);
  const [productNamesToDisplay, setProductNamesToDisplay] = useState([]);
  const [productDataToDisplay, setProductDataToDisplay] = useState([]);
  const [productQuantitiesToDisplay, setProductQuantitiesToDisplay] = useState([]);

  useEffect(() => {
    const productSubscriptionMap = {};

    selectedProducts.forEach(p => {
      productSubscriptionMap[p.id] = {
        name: p.name,
        subscriptions: [],
        totalRevenueForPeriod: 0,
        totalProductsForPeriod: 0,
      };
    });

    const maxSubscriptionDate = moment(endDate).valueOf();

    subscriptions.forEach(sub => {
      sub.products.forEach(p => {
        if (productSubscriptionMap.hasOwnProperty(p.productId)) {
          productSubscriptionMap[p.productId].subscriptions.push({
            nextPaymentDate: sub.nextPaymentDate,
            interval: sub.interval,
            period: sub.period,
            total: +p.price,
          });

          let nextPaymentDate = sub.nextPaymentDate;
    
          while (nextPaymentDate <= maxSubscriptionDate) {
            nextPaymentDate = moment(new Date(nextPaymentDate)).add(sub.interval, sub.period).valueOf();
    
            productSubscriptionMap[p.productId].totalRevenueForPeriod += (+p.price * +p.quantity);
            productSubscriptionMap[p.productId].totalProductsForPeriod += +p.quantity;
          }
        }
      });
    });

    const productSubscriptionList = [];

    for (let key in productSubscriptionMap) {
      const subDateMap = {};
      const updatedSubscriptions = [];
      let min = Infinity;
      let max = 0;

      productSubscriptionMap[key].subscriptions.forEach(sub => {
        updatedSubscriptions.push({
          nextPaymentDate: sub.nextPaymentDate,
          total: sub.total,
        });

        let nextPaymentDate = sub.nextPaymentDate;
  
        while (nextPaymentDate <= maxSubscriptionDate) {
          nextPaymentDate = moment(new Date(nextPaymentDate)).add(sub.interval, sub.period).valueOf();
  
          if (nextPaymentDate <= maxSubscriptionDate) {
            updatedSubscriptions.push({
              nextPaymentDate,
              total: sub.total,
            });
          }
        }
      });

      updatedSubscriptions.forEach(sub => {
        const millis = moment(new Date(sub.nextPaymentDate)).startOf(dateInterval).valueOf();
        const dateStr = `${millis}`;

        if (millis > maxSubscriptionDate) {
          return;
        }
  
        if (millis > max) {
          max = millis;
        }
  
        if (millis < min) {
          min = millis;
        }
        
        if (!subDateMap[dateStr]) {
          subDateMap[dateStr] = {
            count: 1,
            revenue: +sub.total,
          };
        } else {
          subDateMap[dateStr].count++;
          subDateMap[dateStr].revenue += +sub.total;
        }
      });

      let current = min;
  
      while (current <= max) {
        if (!subDateMap[`${current}`]) {
          subDateMap[`${current}`] = {
            count: 0,
            revenue: 0,
          };
        }
  
        const millis = moment(new Date(+current)).add(1, dateInterval).startOf(dateInterval).valueOf();
        current = millis;
      }
  
      const dataArr = [];
  
      for (let key in subDateMap) {
        dataArr.push([new Date(+key), subDateMap[key].count, subDateMap[key].revenue]);
      }
  
      dataArr.sort((a, b) => {
        return a[0] - b[0];
      });

      productSubscriptionList.push({
        ...productSubscriptionMap[key],
        dataArr,
        id: key,
      });
    }

    const productData = [];
    const productQuantities = [];

    productSubscriptionList.forEach((p, i) => {
      p.dataArr.forEach((d, j) => {
        if (!productData[j]) {
          productData[j] = [d[0]];
        }

        productData[j].push(d[2]);

        if (!productQuantities[j]) {
          productQuantities[j] = [d[0]];
        }

        productQuantities[j].push(d[1]);
      });
    });

    setSubscriptionsByProduct(productSubscriptionList);
    setProductNamesToDisplay(productSubscriptionList.map(p => p.name));
    setProductDataToDisplay(productData);
    setProductQuantitiesToDisplay(productQuantities);
  }, [selectedProducts, subscriptions, startDate, endDate]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="SubscriptionForecastByProduct">
      <div className="date-pickers-container">
        <div className="date-picker-container">
          <Typography>
            <strong>
              <small>Start</small>
            </strong>
          </Typography>
          <DatePicker
            popperPlacement="top-start"
            selected={startDate}
            onChange={(date) => {
              const firstDay = date;
              firstDay.setHours(0, 0, 0, 0);
              setStartDate(firstDay);
            }}
            minDate={start}
          />
        </div>
        <div className="date-divider" style={{marginTop: 14}}>-</div>
        <div className="date-picker-container">
          <Typography>
            <strong>
              <small>End</small>
            </strong>
          </Typography>
          <DatePicker
            popperPlacement="top-end"
            selected={endDate}
            onChange={(date) => {
              const lastDay = date;
              lastDay.setHours(23, 59, 59, 999);
              setEndDate(lastDay);
            }}
            minDate={start}
          />
        </div>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            {type: 'date', label: dateInterval.toUpperCase()},
            ...productNamesToDisplay,
          ],
          ...productDataToDisplay,
        ]}
        options={{
          hAxis: {
            title: 'Date',
          },
          chart: {
            title:
              'Product Revenue by Day',
          },
          vAxis: {
            title: 'Revenue',
          },
          width: '100%',
          height: 500,
          series: {
            1: { curveType: 'function' },
          },
        }}
      />

      <div style={{margin: '40px 0'}}>
        <Divider/>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            {type: 'date', label: dateInterval.toUpperCase()},
            ...productNamesToDisplay,
          ],
          ...productQuantitiesToDisplay,
        ]}
        options={{
          hAxis: {
            title: 'Date',
          },
          chart: {
            title:
              'Product Units by Day',
          },
          vAxis: {
            title: 'Units',
          },
          width: '100%',
          height: 500,
          series: {
            1: { curveType: 'function' },
          },
        }}
      />

      <TableContainer component={Paper}>
        <Table aria-label="products table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Product</strong></TableCell>
              <TableCell><strong>Forecasted Revenue</strong></TableCell>
              <TableCell><strong>Forecasted Units</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsByProduct.map((p, i) => (
              <TableRow key={p.id}>
                <TableCell>
                  <div><strong>{p.name}</strong></div>
                  <div style={{marginTop: 5}}><strong>ID:</strong> {p.id}</div>
                </TableCell>
                <TableCell>{formatter.format(p.totalRevenueForPeriod)}</TableCell>
                <TableCell>{p.totalProductsForPeriod}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SubscriptionForecastByProduct;
