import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TypeformSendlaneFields() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [fields, setFields] = useState([]);
  const [editField, setEditField] = useState({
    field: '',
    sendlaneId: '',
    notes: '',
  });
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const fieldsConfigSnapshot = await firebase.firestore().collection('config').doc('typeform-sendlane-fields').get();

        setFields(fieldsConfigSnapshot.data().fields);
        setLoading(false);
      } catch (e) {
        console.log(e)
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching fields. Refresh the page and try again.');
      }
    };

    fetchFields();
  }, []);

  const saveField = async () => {
    setLoading(true);

    try {
      const fieldsCopy = [ ...fields ];
      fieldsCopy[editIndex] = editField;

      await firebase.firestore().collection('config').doc('typeform-sendlane-fields').update({
        fields: fieldsCopy,
      });

      setEditIndex(-1);
      setFields(fieldsCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred saving this field, please try again.');
    }
  };

  const deleteField = async () => {
    const index = deleteIndex;

    setDeleteIndex(-1);
    setLoading(true);

    try {
      const fieldsCopy = [ ...fields ];
      fieldsCopy.splice(index, 1);

      await firebase.firestore().collection('config').doc('typeform-sendlane-fields').update({
        fields: fieldsCopy,
      });

      setFields(fieldsCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this field, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <>
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Typeform Sendlane Fields Map
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setEditField({
                field: '',
                sendlaneId: '',
                notes: '',
              });
              setEditIndex(fields.length);
            }}
            aria-label="Add"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {fields.map((f, i) => {
            return (
              <div key={`field-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={<span>
                      <strong>From: </strong>
                      <span style={{marginRight: 10}}>{f.field}</span>
                      <strong>To: </strong>
                      <span style={{marginRight: 10}}>{f.sendlaneId}</span>
                    </span>}
                    secondary={`Notes: ${f.notes}`}
                  />

                  <IconButton
                    edge="start"
                    style={{marginRight: 10}}
                    color="inherit"
                    onClick={() => {
                      setEditField({
                        ...f,
                      });
                      setEditIndex(i);
                    }}
                    aria-label="Edit"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      setDeleteIndex(i);
                    }}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog
        open={editIndex !== -1}
        onClose={() => setEditIndex(-1)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Manage Field</DialogTitle>
        <DialogContent>
          <TextField
            label="Typeform Question"
            value={editField.field}
            onChange={(e) => {
              setEditField({
                ...editField,
                field: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Sendlane ID"
            value={editField.sendlaneId}
            onChange={(e) => {
              setEditField({
                ...editField,
                sendlaneId: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Notes (optional)"
            value={editField.notes}
            onChange={(e) => {
              setEditField({
                ...editField,
                notes: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            multiline
            rows="4"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveField} color="primary" disabled={!editField.field || !editField.sendlaneId}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => setEditIndex(-1)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteIndex !== -1}
        onClose={() => setDeleteIndex(-1)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Delete Field?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteField} color="secondary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TypeformSendlaneFields;
