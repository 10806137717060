import React, { useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';

import Modal from './shared/modal';
import EditModal from './shared/editModal';
import upArrow from '../icons/up.png';
import downArrow from '../icons/down.png';
import editArrow from '../icons/edit.png';
import deleteIcon from '../icons/delete.png';
import moveIcon from '../icons/move.png';
import copyIcon from '../icons/copy.png';

const styles = {
  container: {
    padding: '5px',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: 'lightblue',
  },
  toolbarItem: {
    height: 15,
    width: 15,
    margin: 5,
    cursor: 'pointer',
  },
  image: {
    height: '100%',
  },
};

function Button({ content, updateContent, upClicked, downClicked, deleteItem, copyClicked }) {
  const [, drag] = useDrag({
    item: { ...content },
    end(item, monitor) {
      if (!monitor.didDrop()) {
        return;
      }

      deleteItem();
    },
  });
  const innerRef = useRef(null);
  const [showTools, setShowTools] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.onmouseenter = (e) => {
        setShowTools(true);
      };

      innerRef.current.onmouseleave = (e) => {
        setShowTools(false);
      };
    }
  }, [innerRef]);

  const delayedDelete = () => {
    setTimeout(() => {
      deleteItem();
    }, 50);
  };

  const renderButtonTypeFields = () => {
    if (content.clickAction === 'link') {
      return (
        <>
          <div className="input-container">
            <label>Link</label>
            <input
              value={content.link}
              onChange={(e) => {
                updateContent({
                  ...content,
                  link: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Open Link In New Tab</label>
            <select
              value={content.openLinkInNewTab}
              onChange={(e) => {
                updateContent({
                  ...content,
                  openLinkInNewTab: e.target.value,
                });
              }}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="input-container">
            <label>Link No Follow</label>
            <select
              value={content.linkNoFollow}
              onChange={(e) => {
                updateContent({
                  ...content,
                  linkNoFollow: e.target.value,
                });
              }}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </>
      );
    }

    if (content.clickAction === 'modal') {
      return (
        <div className="input-container" style={{ width: '100%' }}>
          <label>Modal HTML content</label>
          <textarea
            style={{ width: '100%', resize: 'none' }}
            rows={5}
            value={content.html}
            onChange={(e) => {
              updateContent({
                ...content,
                html: e.target.value,
              });
            }}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ ...styles.container }}>
      <div
        ref={innerRef}
      >
        {!showTools ? null :
          <div style={styles.inner}>
            <div style={styles.toolbar}>
              <div style={styles.toolbarItem} ref={drag}>
                <img style={styles.image} src={moveIcon} alt="move" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { copyClicked() }}>
                <img style={styles.image} src={copyIcon} alt="copy" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={upArrow} onClick={upClicked} alt="up arrow" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={downArrow} onClick={downClicked} alt="down arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setEditModalOpen(true) }}>
                <img style={styles.image} src={editArrow} alt="edit" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setDeleteModalOpen(true) }}>
                <img style={styles.image} src={deleteIcon} alt="delete" />
              </div>
            </div>
          </div>
        }
        <div style={{ textAlign: content.styles.alignSelf ? content.styles.alignSelf : 'left' }}>
          <button
            style={{
              ...content.styles,
            }}
          >
            {content.text}
          </button>
        </div>
      </div>

      <EditModal
        open={editModalOpen}
        close={() => { setEditModalOpen(false) }}
        title={`Edit ${content.title}`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            setEditModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setEditModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div>
            <div className="input-container">
              <label>Click Action</label>
              <select
                value={content.clickAction}
                onChange={(e) => {
                  updateContent({
                    ...content,
                    clickAction: e.target.value,
                  });
                }}
              >
                <option value={'link'}>Link</option>
                <option value={'modal'}>Modal</option>
                <option value={'button'}>Button</option>
              </select>
            </div>

            {renderButtonTypeFields()}
          </div>
          <div className="input-container">
            <label>Text</label>
            <input
              value={content.text}
              onChange={(e) => {
                updateContent({
                  ...content,
                  text: e.target.value,
                });
              }}
            />
          </div>
          {content.editableStyles.map((editable, i) => {
            return (
              <div key={`editable-${i}`} className="input-container">
                <label>{editable.label}</label>
                {editable.inputType === 'select' ?
                  <select
                    value={content.styles[editable.type]}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  >
                    {editable.options.map((option, i) => {
                      return <option key={i} value={option}>{option}</option>;
                    })}
                  </select> :
                  <input
                    value={content.styles[editable.type]}
                    placeholder={editable.placeholder}
                    type={editable.inputType || 'text'}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  />
                }
              </div>
            );
          })}
          <div className="input-container">
            <label>Displayed Device Widths</label>
            <select
              value={content.visibilityClassName}
              onChange={(e) => {
                updateContent({
                  ...content,
                  visibilityClassName: e.target.value
                });
              }}
            >
              <option value={'page-builder-always-visible'}>Always</option>
              <option value={'page-builder-large-visible'}>Large Devices Only</option>
              <option value={'page-builder-large-medium-visible'}>Large & Medium Devices Only</option>
              <option value={'page-builder-medium-visible'}>Medium Devices Only</option>
              <option value={'page-builder-medium-small-visible'}>Medium & Small Devices Only</option>
              <option value={'page-builder-small-visible'}>Small Devices Only</option>
            </select>
          </div>
          <div className="input-container">
            <label>Class Names</label>
            <input
              value={content.customClasses}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customClasses: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Inline Styles</label>
            <input
              value={content.customStyles}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customStyles: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>ID</label>
            <input
              value={content.htmlID}
              onChange={(e) => {
                updateContent({
                  ...content,
                  htmlID: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </EditModal>

      <Modal
        open={deleteModalOpen}
        close={() => { setDeleteModalOpen(false) }}
        title={`Delete ${content.title}?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            delayedDelete();
            setDeleteModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this?</div>
        </div>
      </Modal>
    </div>
  );
};

export default Button;
