import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ChipInput from 'material-ui-chip-input';

import './shippingInsurance.scss';
import DeletableChip from '../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ruleTypes = [
  'Always',
  'Coupon Code',
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Subtotal is Less Than',
  'Subtotal is Greater Than',
];
const showProductTypes = [
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
];
const showCategoryTypes = [
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
];
const showTagTypes = [
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ShippingInsurance(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [settings, setSettings] = useState({
    enabled: false,
    imageUrl: '',
    price: 0,
    priceTiers: [
      {
        minimumOrderAmount: 0,
        price: 0,
      },
    ],
    rules: [],
    title: '',
    tooltip: '',
    detailsUrl: '',
    showProductOnCart: false,
    showProductOnCheckout: false,
    showToggleOnCart: false,
    showToggleOnCheckout: false,
  });
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [priceTierDeleteIndex, setPriceTierDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchShippingInsuranceSettings();
  }, []);

  const fetchShippingInsuranceSettings = async () => {
    try {
      const shippingInsuranceSnapshot = await firebase.firestore().collection('config').doc('shipping-insurance').get();
      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();
      const shippingInsuranceData = shippingInsuranceSnapshot.data();
      const shopSettingsData = shopSettingsSnapshot.data();
      const tags = sortResults(contentQuerySnapshot.data().tags || []);
      const shopSettingsDataSimplifiedProducts = sortResults(shopSettingsData.simplifiedProducts || [], 'name');
      const shopSettingsDataProductCategories = sortResults(shopSettingsData.productCategories || []);

      setSettings(shippingInsuranceData);
      setSimplifiedProducts(shopSettingsDataSimplifiedProducts);
      setProductCategories(shopSettingsDataProductCategories);
      setProductTags(tags);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the shipping insurance settings. Please try again.');
    }
  };

  const sortResults = (results, key) => {
    results.sort((a, b) => {
      const first = key ? a[key] : a;
      const second = key ? b[key] : b;

      if (first < second) {
        return -1;
      }

      if (first > second) {
        return 1;
      }

      return 0;
    });

    return results;
  };

  const save = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('config').doc('shipping-insurance').update({
        ...settings,
        priceTiers: settings.priceTiers.map(tier => {
          return {
            minimumOrderAmount: parseFloat(tier.minimumOrderAmount),
            price: parseFloat(tier.price),
          };
        }),
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the shipping insurance settings. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ShippingInsurance">
      {renderLoading()}

      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Shipping Insurance
        </Typography>

        <Button
          color="secondary"
          size="small"
          onClick={save}
          variant="contained"
          disabled={!settings.title || !settings.priceTiers.length || !settings.imageUrl || !settings.detailsUrl || !settings.tooltip || !settings.rules.length}
        >
          Save
        </Button>
      </Toolbar>

      <div className="content-container">
        <FormControlLabel
          control={
            <Switch
              checked={settings.enabled}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  enabled: e.target.checked,
                });
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Enabled"
        />

        <div>
          <FormControlLabel
            control={
              <Switch
                checked={settings.showProductOnCart}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    showProductOnCart: e.target.checked,
                  });
                }}
                name="showProductOnCart"
                color="primary"
              />
            }
            label="Show Product On Cart"
          />

          <FormControlLabel
            control={
              <Switch
                checked={settings.showProductOnCheckout}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    showProductOnCheckout: e.target.checked,
                  });
                }}
                name="showProductOnCheckout"
                color="primary"
              />
            }
            label="Show Product On Checkout"
          />

          <FormControlLabel
            control={
              <Switch
                checked={settings.showToggleOnCart}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    showToggleOnCart: e.target.checked,
                  });
                }}
                name="showToggleOnCart"
                color="primary"
              />
            }
            label="Show Toggle On Cart"
          />

          <FormControlLabel
            control={
              <Switch
                checked={settings.showToggleOnCheckout}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    showToggleOnCheckout: e.target.checked,
                  });
                }}
                name="showToggleOnCheckout"
                color="primary"
              />
            }
            label="Show Toggle On Checkout"
          />
        </div>

        <div className="input-row">
          <TextField
            label="Title"
            value={settings.title}
            onChange={(e) => {
              setSettings({
                ...settings,
                title: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 8, marginBottom: 4}}
          />

          <TextField
            label="Image URL"
            value={settings.imageUrl}
            onChange={(e) => {
              setSettings({
                ...settings,
                imageUrl: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 8, marginBottom: 4}}
          />
        </div>

        <div>
          <TextField
            label="Details Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={settings.detailsUrl}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setSettings({
                ...settings,
                detailsUrl: value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 20, marginBottom: 4}}
          />
        </div>

        <div className="section-container">
          <div className="section-label">Pricing Tiers</div>

          <div className="price-tiers-add-button-container">
            <Button
              color="primary"
              size="small"
              onClick={() => {
                const priceTiers = [ ...settings.priceTiers ];

                priceTiers.push({
                  minimumOrderAmount: 0,
                  price: 0,
                });

                setSettings({
                  ...settings,
                  priceTiers,
                });
              }}
              variant="contained"
            >
              Add Tier
            </Button>
          </div>

          {settings.priceTiers.map((tier, i) => {
            return (
              <div key={`price-tier-${i}`} className="price-tier-row">
                <div className="input-row">
                  <FormControl className="day-text-field" variant="outlined" style={{marginTop: 8, marginBottom: 4, width: '100%'}}>
                    <InputLabel>Minimum Order Amount</InputLabel>
                    <OutlinedInput
                      label="Minimum Order Amount"
                      value={tier.minimumOrderAmount}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, '');
                        const priceTiers = [ ...settings.priceTiers ];

                        priceTiers[i] = {
                          ...priceTiers[i],
                          minimumOrderAmount: value,
                        };

                        setSettings({
                          ...settings,
                          priceTiers,
                        });
                      }}
                      margin="dense"
                      variant="outlined"
                      type="number"
                      className="day-text-field"
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                  </FormControl>

                  <FormControl className="day-text-field" variant="outlined" style={{marginTop: 8, marginBottom: 4, width: '100%'}}>
                    <InputLabel>Price</InputLabel>
                    <OutlinedInput
                      label="Price"
                      value={tier.price}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, '');
                        const priceTiers = [ ...settings.priceTiers ];

                        priceTiers[i] = {
                          ...priceTiers[i],
                          price: value,
                        };

                        setSettings({
                          ...settings,
                          priceTiers,
                        });
                      }}
                      margin="dense"
                      variant="outlined"
                      type="number"
                      className="day-text-field"
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                  </FormControl>
                </div>

                <IconButton
                  onClick={() => {
                    setPriceTierDeleteIndex(i);
                  }}
                  className="delete-price-tier-icon"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
        </div>

        <TextField
          label="Tooltip Text"
          minRows={2}
          multiline
          value={settings.tooltip}
          onChange={(e) => {
            setSettings({
              ...settings,
              tooltip: e.target.value,
            });
          }}
          margin="dense"
          variant="outlined"
          type="text"
          className="day-text-field"
          style={{marginTop: 8, marginBottom: 4}}
        />

        <div className="section-container">
          <div className="section-label">Rules</div>
          <div className="rules-button-row">
            <Button color="primary" onClick={() => {
              const rules = [ ...settings.rules ];

              rules.push({
                type: 'group',
                rules: [{
                  type: ruleTypes[0],
                  products: [],
                  cartSubTotal: '',
                  tags: [],
                  categories: [],
                  couponCodes: [],
                }],
              });

              setSettings({
                ...settings,
                rules,
              });
            }} variant="contained">
              Add Rule Group
            </Button>
          </div>

          {settings.rules.map((group, i) => {
            return (
              <div key={`rule-group-${i}`}>
                {i !== 0 && <div className="condition-text">OR</div>}

                <div className="rule-group">
                  {settings.rules.length > 1 &&
                    <Button color="secondary" size="small" onClick={() => {
                      const rules = [ ...settings.rules ];

                      rules.splice(i, 1);

                      setSettings({
                        ...settings,
                        rules,
                      });
                    }} variant="contained">
                      Remove Group
                    </Button>
                  }
                  {group.rules.map((rule, j) => {
                    return (
                      <div key={`rule-${i}-${j}`}>
                        {j !== 0 && <div className="condition-text">AND</div>}
                        <div className="form-row">
                          {group.rules.length > 1 &&
                            <IconButton
                              onClick={() => {
                                const rules = [ ...group.rules ];

                                rules.splice(j, 1);

                                const settingsRules = [ ...settings.rules ];
                                settingsRules[i].rules = rules;

                                setSettings({
                                  ...settings,
                                  rules: settingsRules,
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                            <InputLabel>Rule Type</InputLabel>
                            <Select
                              value={rule.type}
                              onChange={(e) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  type: e.target.value,
                                };

                                const settingsRules = [ ...settings.rules ];
                                settingsRules[i].rules = rules;

                                setSettings({
                                  ...settings,
                                  rules: settingsRules,
                                });
                              }}
                              label="Rule Type"
                            >
                              {ruleTypes.map((ruleType, k) => {
                                return <MenuItem key={`rule-type-${i}-${j}-${k}`} value={ruleType}>{ruleType}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>

                          {rule.type.includes('Subtotal') &&
                            <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                              <OutlinedInput
                                value={rule.cartSubTotal}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    cartSubTotal: e.target.value,
                                  };

                                  const settingsRules = [ ...settings.rules ];
                                  settingsRules[i].rules = rules;
  
                                  setSettings({
                                    ...settings,
                                    rules: settingsRules,
                                  });
                                }}
                                margin="dense"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{maxWidth: 180, marginRight: 15}}
                              />
                            </FormControl>
                          }

                          {(showProductTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Products</InputLabel>
                              <Select
                                multiple
                                value={rule.products}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    products: e.target.value,
                                  };

                                  const settingsRules = [ ...settings.rules ];
                                  settingsRules[i].rules = rules;
  
                                  setSettings({
                                    ...settings,
                                    rules: settingsRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value.id}
                                        label={value.name}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.products.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const products = [ ...rule.products ];

                                          products.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            products,
                                          };

                                          const settingsRules = [ ...settings.rules ];
                                          settingsRules[i].rules = rules;
          
                                          setSettings({
                                            ...settings,
                                            rules: settingsRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {simplifiedProducts.filter(product => {
                                  const found = rule.products.find(p => p.id === product.id);
                                  return !found;
                                }).map(product => (
                                  <MenuItem key={product.id} value={product}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showCategoryTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Categories</InputLabel>
                              <Select
                                multiple
                                value={rule.categories}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    categories: e.target.value,
                                  };

                                  const settingsRules = [ ...settings.rules ];
                                  settingsRules[i].rules = rules;
  
                                  setSettings({
                                    ...settings,
                                    rules: settingsRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.categories.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const categories = [ ...rule.categories ];

                                          categories.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            categories,
                                          };

                                          const settingsRules = [ ...settings.rules ];
                                          settingsRules[i].rules = rules;
          
                                          setSettings({
                                            ...settings,
                                            rules: settingsRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {productCategories.filter(category => {
                                  return !rule.categories.includes(category);
                                }).map(category => (
                                  <MenuItem key={category} value={category}>
                                    {category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showTagTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Tags</InputLabel>
                              <Select
                                multiple
                                value={rule.tags}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    tags: e.target.value,
                                  };

                                  const settingsRules = [ ...settings.rules ];
                                  settingsRules[i].rules = rules;
  
                                  setSettings({
                                    ...settings,
                                    rules: settingsRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.tags.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const tags = [ ...rule.tags ];

                                          tags.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            tags,
                                          };

                                          const settingsRules = [ ...settings.rules ];
                                          settingsRules[i].rules = rules;
          
                                          setSettings({
                                            ...settings,
                                            rules: settingsRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {productTags.filter(tag => {
                                  return !rule.tags.includes(tag);
                                }).map(tag => (
                                  <MenuItem key={tag} value={tag}>
                                    {tag}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(rule.type === 'Coupon Code') &&
                            <ChipInput
                              style={{flex: 1}}
                              defaultValue={rule.couponCodes}
                              onChange={(chips) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  couponCodes: chips,
                                };

                                const settingsRules = [ ...settings.rules ];
                                settingsRules[i].rules = rules;

                                setSettings({
                                  ...settings,
                                  rules: settingsRules,
                                });
                              }}
                              margin="dense"
                              allowDuplicates={false}
                              fullWidth={true}
                              label="Coupon Codes"
                              helperText='Press "enter" after each code as you type'
                            />
                          }
                        </div>
                      </div>
                    );
                  })}

                  <Button color="primary" size="small" onClick={() => {
                    const rules = [ ...group.rules ];

                    rules.push({
                      type: ruleTypes[0],
                      products: [],
                      cartSubTotal: 0,
                      tags: [],
                      categories: [],
                      couponCodes: [],
                    });

                    const settingsRules = [ ...settings.rules ];
                    settingsRules[i].rules = rules;

                    setSettings({
                      ...settings,
                      rules: settingsRules,
                    });
                  }} variant="contained">
                    Add Condition
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog
        open={priceTierDeleteIndex !== -1}
        onClose={() => { setPriceTierDeleteIndex(-1) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Price Tier</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this price tier?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const priceTiers = [ ...settings.priceTiers ];

            priceTiers.splice(priceTierDeleteIndex, 1);

            setSettings({
              ...settings,
              priceTiers,
            });
            setPriceTierDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setPriceTierDeleteIndex(-1) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShippingInsurance;
