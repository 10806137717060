import { useFieldArray } from 'react-hook-form';

export const useStatisticsFields = (control) => {
  const {
    append,
    remove,
    fields: statisticsItems,
    move: moveStatisticItem,
  } = useFieldArray({
    control,
    name: 'statisticsSection.items',
  });

  const appendStatisticItem = () => {
    append({
      quantity: 0,
      title: '',
      isRating: false,
      imageUrl: '',
    });
  };

  const removeStatisticItem = (index) => {
    remove(index);
  };

  return {
    statisticsItems,
    appendStatisticItem,
    removeStatisticItem,
    moveStatisticItem,
  };
};
