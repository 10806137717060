import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Typography,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  DialogActions,
  Button,
  FormHelperText,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import './products.scss';

import { emptyProduct } from './emptyProduct';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Products(props) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [newProduct, setNewProduct] = useState(emptyProduct);
  const [showNewProduct, setShowNewProduct] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const db = firebase.firestore();

    try {
      const productsQuerySnapshot = await db.collection('products-v2').get();

      const productData = productsQuerySnapshot.docs.map(p => {
        return {
          ...p.data(),
          id: p.id,
        };
      });

      setProducts(productData);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      window.alert('Error loading products. Please refresh and try again.');
    }
  };

  const getActiveMessage = (active) => {
    if (active) {
      return <span className="stock-status" style={{color: '#7ED242'}}>Active</span>;
    }

    return <span className="stock-status" style={{color: 'orange'}}>Inactive</span>;
  };

  const getStockMessage = (stockQuantity, lowStockThreshold) => {
    if (+stockQuantity <= 0) {
      return <span className="stock-status" style={{color: 'red'}}>Out of stock</span>;
    } else if (+stockQuantity <= +lowStockThreshold) {
      return <span className="stock-status" style={{color: 'orange'}}>Low stock</span>;
    } else {
      return <span className="stock-status" style={{color: '#7ED242'}}>In stock</span>;
    }
  };

  const saveNewProduct = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowNewProduct(false);

    try {
      let product;

      if (!newProduct.id) {
        product = await db.collection('products-v2').add(newProduct);
      } else {
        await db.collection('products-v2').doc(newProduct.id).set(newProduct);
        product = {
          id: newProduct.id,
        };
      }

      await db.collection('committed-product-counts').doc(product.id).set({
        count: 0,
      });

      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const simplifiedProducts = shopSettingsSnapshot.data().simplifiedProducts;
      simplifiedProducts.push({
        id: product.id,
        name: newProduct.name,
        shopPageName: newProduct.shopPageName || '',
        path: newProduct.path,
        price: newProduct.price,
        salePrice: newProduct.salePrice,
        isOnSale: newProduct.isOnSale,
        lowestSubscriptionPrice: null,
        addToSubscriptionPrice: null,
        image: null,
        sku: newProduct.sku,
        active: false,
        group: '',
        groupedThumbnail: '',
        isDigital: newProduct.isDigital,
        showOnShopPage: true,
        showInSearch: true,
        createProductPage: true,
        shippingClass: '',
        categories: [],
        filters: [],
        costOfGood: 0,
        isGiftCard: newProduct.isGiftCard || false,
        isCustomizableBundle: newProduct.isCustomizableBundle || false,
        useCustomizableBundleProductsPricing: false,
        isSubscriptionBox: newProduct.isSubscriptionBox || false,
      });

      await db.collection('config').doc('shop-settings').update({
        simplifiedProducts,
      });

      if (!newProduct.isCustomizableBundle && !newProduct.isSubscriptionBox) {
        try {
          await axios.post(`${config.functions}/webApi/update-ordoro-product`, {
            product: {
              ...newProduct,
              id: product.id,
            },
          });
        } catch (e) {
          setLoading(false);
          window.alert('Product was created but not added to Ordoro. Make sure this product ends up in Ordoro.');
        }
      }

      setLoading(false);
      props.history.push(`/shop/products/${product.id}`);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      window.alert('There was an error creating the product. Please try again.');
    }
  };

  const isNewUrlUnique = () => {
    return products.findIndex(p => p.path === newProduct.path) === -1;
  };

  const isNewSKUUnique = () => {
    return products.findIndex(p => p.sku === newProduct.sku) === -1;
  };

  const isNewIDUnique = () => {
    if (!newProduct.id) {
      return true;
    }

    return products.findIndex(p => p.id === newProduct.id) === -1;
  };

  const deleteProduct = async () => {
    const storageRef = firebase.storage().ref();
    const db = firebase.firestore();
    setLoading(true);

    try {
      const media = products[deleteIndex].media;

      if (media.length) {
        for (let i = 0; i < media.length; i++) {
          if (media[i].storagePath) {
            const child = media[i].storagePath;
            const imageRef = storageRef.child(child);
      
            await imageRef.delete();
          }
        }
      }

      await db.collection('products-v2').doc(products[deleteIndex].id).delete();

      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const simplifiedProducts = shopSettingsSnapshot.data().simplifiedProducts.filter(p => {
        return p.id !== products[deleteIndex].id;
      });

      await db.collection('config').doc('shop-settings').update({
        simplifiedProducts,
      });

      const wholesaleProductsSnapshot = await db.collection('wholesale').doc('productData').get();
      const wholesaleProducts = wholesaleProductsSnapshot.data().products.filter(p => {
        return p.id !== products[deleteIndex].id;
      });

      await db.collection('wholesale').doc('productData').update({
        products: wholesaleProducts,
      });

      const productsCopy = [ ...products ];
      
      productsCopy.splice(deleteIndex, 1);
      
      setDeleteIndex(-1);
      setProducts(productsCopy);
    } catch (e) {
      console.log('e', e);
      window.alert('There was an error deleting this product. Please try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Products">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/shop/shop-page">
          <Button variant="contained" color="primary" size="small">
            Edit Shop Page
          </Button>
        </Link>
        <Link to="/shop/products/reorder">
          <Button variant="contained" color="primary" size="small" style={{marginRight: 10}}>
            Reorder Products
          </Button>
        </Link>
      </div>
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Products
        </Typography>
        <IconButton edge="start" color="inherit" onClick={() => {
          setNewProduct(emptyProduct);
          setShowNewProduct(true);
        }} aria-label="Delete">
          <AddIcon />
        </IconButton>
      </Toolbar>

      <TableContainer component={Paper}>
        <Table aria-label="regions table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>SKU</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Stock</strong></TableCell>
              <TableCell><strong>Categories</strong></TableCell>
              <TableCell padding="checkbox"></TableCell>
              <TableCell padding="checkbox"></TableCell>
              <TableCell padding="checkbox"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, i) => (
              <TableRow key={product.id}>
                <TableCell>
                  <div>{product.name}</div>
                  <div><small><strong>ID:</strong> {product.id}</small></div>
                </TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{getActiveMessage(product.active)}</TableCell>
                <TableCell>
                  {(product.isCustomizableBundle || product.isSubscriptionBox) ?
                    <span className="stock-status">{product.isCustomizableBundle ? 'Custom Bundle' : 'Subscription Box'}</span> :
                    <span>{getStockMessage(product.stockQuantity, product.lowStockThreshold)} ({product.stockQuantity})</span>
                  }
                </TableCell>
                <TableCell>{product.categories.length ? product.categories.join(', ') : <span style={{color: 'orange'}}>None Specified</span>}</TableCell>
                <TableCell padding="checkbox">
                  <Link to={`/shop/products/${product.id}`}>
                    <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="Delete">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => {
                    setNewProduct({
                      ...product,
                      id: '',
                      name: `${product.name} (Copy)`,
                      sku: '',
                      path: '',
                      memberships: [],
                    });
                    setShowNewProduct(true);
                  }} aria-label="Delete">
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete {deleteIndex !== -1 && products[deleteIndex].name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteProduct} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showNewProduct} onClose={() => { setShowNewProduct(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newProduct.name}
            onChange={(e) => {
              setNewProduct({
                ...newProduct,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <div className="path-container">
            <FormControl variant="outlined" style={{marginTop: 18, marginBottom: 4, width: '100%'}}>
              <InputLabel>URL Path</InputLabel>
              <OutlinedInput
                value={newProduct.path}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                  setNewProduct({
                    ...newProduct,
                    path: value,
                  });
                }}
                margin="dense"
                label="URL Path"
                variant="outlined"
                type="text"
                error={!isNewUrlUnique()}
                startAdornment={<InputAdornment style={{marginRight: 0}} position="start">product/</InputAdornment>}
              />
              {isNewUrlUnique() ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
            </FormControl>
          </div>

          <TextField
            label="SKU"
            value={newProduct.sku}
            onChange={(e) => {
              setNewProduct({
                ...newProduct,
                sku: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            helperText={isNewSKUUnique() ? '': 'SKU must be unique'}
            error={!isNewSKUUnique()}
            style={{marginTop: 15}}
          />

          <TextField
            label="ID"
            value={newProduct.id}
            onChange={(e) => {
              setNewProduct({
                ...newProduct,
                id: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            helperText={isNewIDUnique() ? 'If not specified, an ID will be automatically generated' : 'ID must be unique'}
            error={!isNewIDUnique()}
            style={{marginTop: 15}}
          />

          <FormControlLabel
            control={
              <Switch
                checked={newProduct.isCustomizableBundle || false}
                onChange={(e) => {
                  setNewProduct({
                    ...newProduct,
                    isCustomizableBundle: e.target.checked,
                    isSubscriptionBox: false,
                  });
                }}
                name="isCustomizableBundle"
                color="primary"
              />
            }
            label="This Product is a Customizable Bundle"
          />

          <FormControlLabel
            control={
              <Switch
                checked={newProduct.isSubscriptionBox || false}
                onChange={(e) => {
                  setNewProduct({
                    ...newProduct,
                    isSubscriptionBox: e.target.checked,
                    isCustomizableBundle: false,
                  });
                }}
                name="isSubscriptionBox"
                color="primary"
              />
            }
            label="This Product is a Subscription Box"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewProduct} color="secondary" disabled={!newProduct.name || !newProduct.path || !newProduct.sku || !isNewIDUnique() || !isNewSKUUnique() || !isNewUrlUnique()}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowNewProduct(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Products;
