import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Section from './section';

function MultiSection(props) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { props.moveNestedSectionUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { props.moveNestedSectionDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  return(
    <div>
      <TextField
        style={{width: '100%'}}
        label="Section Title"
        value={props.sectionTitle}
        onChange={(e) => { props.handleSectionTitleChange(e.target.value) }}
        margin="normal"
        variant="outlined"
        className="day-text-field"
      />

      <Button 
        variant="contained"
        color="primary"
        style={{marginRight: '10px', marginBottom: '5px'}}
        onClick={props.addSection}
      >
        Add Section
      </Button>
      {props.sections.map((section, i) => {
        return (
          <div key={`section-${i}`} style={{position: 'relative'}}>
            <div style={{ position: 'absolute', right: '45px', zIndex: 1, display: 'flex' }}>
              {renderUpArrow(i)}

              {renderDownArrow(i, props.sections.length - 1)}

              <IconButton color="inherit" onClick={(e) => { props.setNestedSectionDelete(e, i) }} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </div>
            <Accordion style={{borderWidth: '1px', borderStyle: 'solid', borderColor: '#eee'}} key={`expansion-${i}`} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
              <AccordionSummary
                style={{backgroundColor: '#eee'}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography style={{color: 'rgba(0, 0, 0, 0.54)'}}>{section.sectionTitle}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{width: '100%'}}>
                  <Section
                    section={section}
                    products={props.products}
                    handleTitleChange={(value) => { props.handleTitleChange(value, i) }}
                    handleChange={(value, index) => { props.handleChange(value, i, index) }}
                    addDetail={(type) => { props.addDetail(type, i) }}
                    handleSectionImageStyleChange={(value, index, type) => { props.handleSectionImageStyleChange(value, type, i, index) }}
                    handleVideoThumbnailChange={(value, index) => { props.handleVideoThumbnailChange(value, i, index) }}
                    addTextTrack={(index) => { props.addTextTrack(i, index) }}
                    handleTextTrackFieldChange={(type, value, j, k) => { props.handleTextTrackFieldChange(type, value, i, j, k) }}
                    removeTextTrack={(j, k) => { props.removeTextTrack(i, j, k) }}
                    removeSectionItem={(j) => { props.removeSectionItem(i, j) }}
                    moveItemUp={(j) => { props.moveItemUp(i, j) }}
                    moveItemDown={(j) => { props.moveItemDown(i, j) }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}

export default MultiSection;
