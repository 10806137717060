import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { red } from '@mui/material/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './groupPosts.scss';
import { config } from '../../../../config';
import GroupPostMenu from './groupPostMenu';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function GroupPosts({ history, match }) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [group, setGroup] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [posts, setPosts] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [postToDisapproveIndex, setPostToDisapproveIndex] = useState(-1);
  const [postToMarkLiveIndex, setPostToMarkLiveIndex] = useState(-1);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const id = match.params.id;

        if (id === undefined) {
          history.replace('/community/groups');
          return;
        }

        const token = await firebase.auth().currentUser.getIdToken();
        const response = await axios(`${config.gateway}/community-service/api/v1/groups/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.data.id) {
          history.replace('/community/groups');
          return;
        }

        setGroup(response.data);
        setCurrentPage(1);
      } catch (e) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching post content. Refresh the page and try again.');
      }
    };

    fetchGroup();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      try {
        const id = match.params.id;
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await axios(`${config.gateway}/community-service/api/v1/posts/group/${id}/admin?count=${pageCount}&page${currentPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        console.log(response.data)

        setNumberOfPages(Math.floor(response.data.count / pageCount));
        setPosts(response.data.posts);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching posts. Refresh the page and try again.');
      }
    };

    if (currentPage) {
      fetchPosts();
    }
  }, [currentPage]);

  const updatePostStatus = async (status) => {
    setLoading(true);
    const index = status === 'live' ? postToMarkLiveIndex : postToDisapproveIndex;

    try {
      const postId = posts[index].id;
      const token = await firebase.auth().currentUser.getIdToken();
      await axios(`${config.gateway}/community-service/api/v1/posts/${postId}/status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          status,
        },
      });

      const postsCopy = [ ...posts ];
      postsCopy[index] = {
        ...postsCopy[index],
        status,
      };

      setPosts(postsCopy);
      setPostToMarkLiveIndex(-1);
      setPostToDisapproveIndex(-1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred updating the status of this post, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="GroupPosts">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/community/groups">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Groups
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            <strong>{group.name || 'Unnamed Group'}</strong> Posts
          </Typography>
        </Toolbar>

        <div className="content">
          <div className="posts-container">
            {posts.map((post, i) => {
              return (
                <Card
                  key={`post-${post.id}`}
                  className="post-container"
                >
                  <CardHeader
                    avatar={
                      post.user.profilePictureUrl ?
                      <img
                        src={post.user.profilePictureUrl}
                        className="post-profile-picture"
                      /> :
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="user">
                        {post.user.name[0]}
                      </Avatar>
                    }
                    action={
                      <GroupPostMenu
                        postId={post.id}
                        groupId={match.params.id}
                        isLive={post.status === 'live'}
                        onStatusChangeClicked={() => {
                          if (post.status === 'live') {
                            setPostToDisapproveIndex(i);
                          } else {
                            setPostToMarkLiveIndex(i);
                          }
                        }}
                      />
                    }
                    title={post.user.name}
                    subheader={moment(post.created).format('lll')}
                  />

                  {!post.text ? null :
                    <>
                      <Divider/>
                      <CardContent>
                        <Typography variant="body2">
                          {post.text}
                        </Typography>
                      </CardContent>
                    </>
                  }

                  {!(post.videoThumbnailUrl && post.videoUrl) ? null :
                    <div className="video-container">
                      <iframe
                        src={`${post.videoUrl.replace('https://iframe.mediadelivery.net/play/', 'https://iframe.mediadelivery.net/embed/')}?autoplay=false&responsive=true`}
                        loading="lazy"
                        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
                        allowFullScreen={true}
                      ></iframe>
                    </div>
                  }

                  {!post.imageUrl ? null :
                    <CardMedia
                      component="img"
                      image={post.imageUrl}
                      alt={post.user.name}
                    />
                  }

                  <Divider/>

                  <CardActions>
                    <div><strong>Comments:</strong> {post._count.comments}</div>

                    <div><strong>Likes:</strong> {post._count.likes}</div>

                    <div><strong>Status:</strong> <span className="post-status">{post.status.split('-').join(' ')}</span></div>
                  </CardActions>
                </Card>
              );
            })}

            {!numberOfPages ? null :
              <div className="pagination-container">
                <div>
                  <Button disabled={currentPage === 1} variant="contained" size="small" onClick={() => setCurrentPage(currentPage - 1)} color="primary">
                    Back
                  </Button>
                  <span className="page-text">Page <strong>{currentPage}</strong> of <strong>{numberOfPages}</strong></span>
                  <Button disabled={currentPage === numberOfPages} variant="contained" size="small" onClick={() => setCurrentPage(currentPage + 1)} color="primary">
                    Next
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={postToDisapproveIndex !== -1} onClose={() => setPostToDisapproveIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Disapprove Post?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to disapprove this post?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => updatePostStatus('disapproved')} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setPostToDisapproveIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={postToMarkLiveIndex !== -1} onClose={() => setPostToMarkLiveIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Mark Post Live?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to mark this post as live?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => updatePostStatus('live')} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setPostToMarkLiveIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GroupPosts;
