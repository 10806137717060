import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { ColorPicker } from '../../../../colorPicker/colorPicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CommunityLinks } from './communityLinks/communityLinks';
import './blogForm.scss';

export const BlogForm = () => {
  const form = useFormContext();
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Blog</strong>
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        style={{
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
        }}
      >
        <Accordion className="external-accordion-container">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>Recent Blogs</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-container">
            <Controller
              name="blogSection.recentBlogs.title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.recentBlogs?.title)}
                  helperText={errors.blogSection?.recentBlogs?.title?.message}
                />
              )}
            />
            <Controller
              name="blogSection.recentBlogs.subtitle"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Subtitle"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.recentBlogs?.subtitle)}
                  helperText={
                    errors.blogSection?.recentBlogs?.subtitle?.message
                  }
                />
              )}
            />

            <Controller
              name="blogSection.recentBlogs.titleColor"
              control={control}
              render={({ field }) => {
                return (
                  <ColorPicker
                    field={field}
                    label="Title Color"
                    error={Boolean(errors.blogSection?.recentBlogs?.titleColor)}
                    helperText={
                      errors.blogSection?.recentBlogs?.titleColor?.message
                    }
                  />
                );
              }}
            />

            <Controller
              name="blogSection.recentBlogs.blogUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Blog Url"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.recentBlogs?.blogUrl)}
                  helperText={errors.blogSection?.recentBlogs?.blogUrl?.message}
                />
              )}
            />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion className="external-accordion-container">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>Blog Categories</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-container">
            <Controller
              name="blogSection.blogCategories.title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.blogCategories?.title)}
                  helperText={
                    errors.blogSection?.blogCategories?.title?.message
                  }
                />
              )}
            />
            <Controller
              name="blogSection.blogCategories.subtitle"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.blogCategories?.subtitle)}
                  helperText={
                    errors.blogSection?.blogCategories?.subtitle?.message
                  }
                />
              )}
            />

            <Controller
              name="blogSection.blogCategories.titleColor"
              control={control}
              render={({ field }) => {
                return (
                  <ColorPicker
                    field={field}
                    error={Boolean(
                      errors.blogSection?.blogCategories?.titleColor,
                    )}
                    helperText={
                      errors.blogSection?.blogCategories?.titleColor?.message
                    }
                  />
                );
              }}
            />

            <BlogCategories control={control} errors={errors} />
          </AccordionDetails>
        </Accordion> */}

        <Accordion className="external-accordion-container">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>Community Links</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-container">
            <Controller
              name="blogSection.communityLinks.title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.communityLinks?.title)}
                  helperText={
                    errors.blogSection?.communityLinks?.title?.message
                  }
                />
              )}
            />
            <Controller
              name="blogSection.communityLinks.subtitle"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Subtitle"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.blogSection?.communityLinks?.subtitle)}
                  helperText={
                    errors.blogSection?.communityLinks?.subtitle?.message
                  }
                />
              )}
            />

            <Controller
              name="blogSection.communityLinks.titleColor"
              control={control}
              render={({ field }) => {
                return (
                  <ColorPicker
                    field={field}
                    label="Title Color"
                    error={Boolean(
                      errors.blogSection?.communityLinks?.titleColor,
                    )}
                    helperText={
                      errors.blogSection?.communityLinks?.titleColor?.message
                    }
                  />
                );
              }}
            />
            <CommunityLinks control={control} errors={errors} />
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};
