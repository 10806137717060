import React from 'react';

import './saveRowModal.scss';

function SaveRowModal({ open, close, title, children, buttons }) {
  if (!open) {
    return <div></div>;
  }

  return (
    <div className="SaveRowModal">
      <div className="modal-body">
        <div className="modal-inner">
          <div className="heading">
            <div className="close-button" onClick={close}>
              <i>×</i>
            </div>

            {!title ? null : <h3 className="title">{title}</h3>}
          </div>

          <div className="modal-content" key="children-container">{children}</div>
          {!buttons ? null : <div className="buttons-container">{buttons}</div>}
        </div>
      </div>
    </div>
  );
}

export default SaveRowModal;
