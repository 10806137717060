import React from 'react';
import './menuItems.scss';
import { useMenuItemsFields } from './useMenuItemsFields';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { SubmenuItems } from './submenuItems/submenuItems';

export const MenuItems = ({ control, menuIndex, errors }) => {
  const { menuItems, appendMenuItem, removeMenuItem, moveMenuItem } =
    useMenuItemsFields(control, menuIndex);

  return (
    <div className="section-container">
      <div className="section-label">Menu Items</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 8px',
        }}
      >
        {menuItems.map((item, index) => (
          <Card
            key={item.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              alignItems: 'center',
            }}
            variant="outlined"
          >
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>
                <strong>Menu Item {index + 1}</strong>
              </span>

              <Box
                sx={{
                  gap: '8px',
                  display: 'flex',
                }}
              >
                {index !== menuItems.length - 1 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveMenuItem(index, index + 1)}
                  >
                    <ArrowDownIcon />
                  </IconButton>
                ) : null}

                {index !== 0 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveMenuItem(index, index - 1)}
                  >
                    <ArrowUpIcon />
                  </IconButton>
                ) : null}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  {index === menuItems.length - 1 ? (
                    <IconButton
                      color="inherit"
                      onClick={() => appendMenuItem()}
                      aria-label="Add Menu Item"
                    >
                      <AddIcon />
                    </IconButton>
                  ) : null}

                  {index !== 0 ? (
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      onClick={() => removeMenuItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
            </Toolbar>

            <Divider />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  width: '100%',
                }}
              >
                <Controller
                  name={`shopMenus.${menuIndex}.menuItems.${index}.name`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      error={Boolean(
                        errors.shopMenus?.[menuIndex]?.menuItems?.[index]?.name,
                      )}
                      helperText={
                        errors.shopMenus?.[menuIndex]?.menuItems?.[index]?.name
                          ?.message
                      }
                    />
                  )}
                />
                <Controller
                  name={`shopMenus.${menuIndex}.menuItems.${index}.url`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Url"
                      variant="outlined"
                      margin="dense"
                      error={Boolean(
                        errors.shopMenus?.[menuIndex]?.menuItems?.[index]?.url,
                      )}
                      helperText={
                        errors.shopMenus?.[menuIndex]?.menuItems?.[index]?.url
                          ?.message
                      }
                    />
                  )}
                />

                <SubmenuItems
                  control={control}
                  menuIndex={menuIndex}
                  menuItemIndex={index}
                />
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
};
