import { useFieldArray } from 'react-hook-form';

export const useSubmenuItemsFields = (control, menuIndex, menuItemIndex) => {
  const {
    append,
    remove,
    fields: submenuItems,
    move: moveSubmenuItem,
  } = useFieldArray({
    control: control,
    name: `shopMenus.${menuIndex}.menuItems.${menuItemIndex}.submenuItems`,
  });

  const appendSubmenuItem = () => {
    append({
      name: '',
      url: '',
    });
  };

  const removeSubmenuItem = (index) => {
    remove(index);
  };

  return {
    submenuItems,
    appendSubmenuItem,
    removeSubmenuItem,
    moveSubmenuItem,
  };
};
