import { useFieldArray } from 'react-hook-form';

export const useProductsCategoriesFields = (control) => {
  const {
    append,
    remove,
    fields: productsCategoriesItems,
    move: moveProductCategoryItem,
  } = useFieldArray({
    control,
    name: 'productCategoriesSection.categories.items',
  });

  const appendProductCategoryItem = () => {
    append({
      name: '',
      description: '',
      color: '#ffffff',
    });
  };

  const removeProductCategoryItem = (index) => {
    remove(index);
  };

  return {
    productsCategoriesItems,
    appendProductCategoryItem,
    removeProductCategoryItem,
    moveProductCategoryItem,
  };
};
