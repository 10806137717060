import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const cloneDeep = require('lodash.clonedeep');

function MembershipProductPurchaseDetails(props) {
  const [showSync, setShowSync] = useState(false);
  const [syncProductIndex, setSyncProductIndex] = useState(0);
  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [product, updateProduct] = useState({
    purchaseDetails: [],
    regularPrice: 0,
    salePrice: 0,
  });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('membership-content').get();
        const products = querySnapshot.docs.map(doc => {
          return doc.data();
        });

        const product = products.find(p => p.id === props.match.params.id);

        setProducts(products);
        updateProduct(product);
        setPurchaseDetails(product.purchaseDetails);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    fetchProducts();
  }, []);

  const saveProduct = async () => {
    setLoading(true);
    const productCopy = cloneDeep(product);
    productCopy.purchaseDetails = purchaseDetails;
    updateProduct(productCopy);
    const db = firebase.firestore();
    await db.collection('membership-content').doc(product.id).update(productCopy);
    setLoading(false);
  };

  const addSection = () => {
    const details = cloneDeep(purchaseDetails);
    details.push({ sections: [] });
    setPurchaseDetails(details);
  };

  const addCTAButton = () => {
    const details = cloneDeep(purchaseDetails);
    details.push('');
    setPurchaseDetails(details);
  };

  const removeSection = (i) => {
    const details = cloneDeep(purchaseDetails);
    details.splice(i, 1);
    setPurchaseDetails(details);
  };

  const addDetail = (i, type) => {
    const detailsCopy = cloneDeep(purchaseDetails);
    const details = {
      type,
      value: '',
    };

    if (type === 'image') {
      details.marginBottom = 0;
      details.marginTop = 0;
      details.width = 0;
    }

    if (type === 'video') {
      details.thumbnail = '';
    }

    detailsCopy[i].sections.push(details);
    setPurchaseDetails(detailsCopy);
  };

  const handlePriceChange = (type) => event => {
    const productCopy = cloneDeep(product);
    productCopy[type] = event.target.value;
    updateProduct(productCopy);
  };

  const handleCTATextChange = (i) => event => {
    const details = cloneDeep(purchaseDetails);
    details[i] = event.target.value;
    setPurchaseDetails(details);
  };

  const handleSectionChange = (i, j) => event => {
    const details = cloneDeep(purchaseDetails);
    details[i].sections[j].value = event.target.value;
    setPurchaseDetails(details);
  };

  const handleVideoThumbnailChange = (i, j) => event => {
    const details = cloneDeep(purchaseDetails);
    details[i].sections[j].thumbnail = event.target.value;
    setPurchaseDetails(details);
  };

  const handleSectionImageStyleChange = (i, j, type) => event => {
    const details = cloneDeep(purchaseDetails);
    details[i].sections[j][type] = event.target.value;
    setPurchaseDetails(details);
  };

  const renderSectionInput = (s, i, j) => {
    if (s.type === 'title' || s.type === 'subtitle') {
      return (
        <TextField
          label={s.type.toUpperCase()}
          value={purchaseDetails[i].sections[j].value}
          onChange={handleSectionChange(i, j)}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'video') {
      return (
        <div className="day-text-field">
          <TextField
            label={s.type.toUpperCase() + ' URL'}
            value={purchaseDetails[i].sections[j].value}
            onChange={handleSectionChange(i, j)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            style={{width: '49%', marginRight: '2%'}}
          />
          <TextField
            label={'THUMBNAIL'}
            value={purchaseDetails[i].sections[j].thumbnail}
            onChange={handleVideoThumbnailChange(i, j)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            style={{width: '49%'}}
          />
        </div>
      );
    } else if (s.type === 'image') {
      return (
        <div>
          <TextField
            label={s.type.toUpperCase() + ' URL'}
            value={purchaseDetails[i].sections[j].value}
            onChange={handleSectionChange(i, j)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label={'Width (percentage of screen width)'}
            value={purchaseDetails[i].sections[j].width}
            onChange={handleSectionImageStyleChange(i, j, 'width')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%'}}
          />
          <TextField
            label={'Margin Top'}
            value={purchaseDetails[i].sections[j].marginTop}
            onChange={handleSectionImageStyleChange(i, j, 'marginTop')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%', marginLeft: '2%'}}
          />
          <TextField
            label={'Margin Bottom'}
            value={purchaseDetails[i].sections[j].marginBottom}
            onChange={handleSectionImageStyleChange(i, j, 'marginBottom')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%', marginLeft: '2%'}}
          />
        </div>
      );
    } else if (s.type === 'paragraph') {
      return (
        <TextField
          label={s.type.toUpperCase()}
          value={purchaseDetails[i].sections[j].value}
          onChange={handleSectionChange(i, j)}
          margin="normal"
          variant="outlined"
          className="day-text-field"
          multiline
          rows="4"
        />
      );
    }
  };

  const moveItemUp = (i, j) => {
    const details = cloneDeep(purchaseDetails);
    const itemToMoveUp = details[i].sections[j];
    const itemToMoveDown = details[i].sections[j - 1];
    details[i].sections[j] = itemToMoveDown;
    details[i].sections[j - 1] = itemToMoveUp;
    setPurchaseDetails(details);
  };

  const moveItemDown = (i, j) => {
    const details = cloneDeep(purchaseDetails);
    const itemToMoveDown = details[i].sections[j];
    const itemToMoveUp = details[i].sections[j + 1];
    details[i].sections[j] = itemToMoveUp;
    details[i].sections[j + 1] = itemToMoveDown;
    setPurchaseDetails(details);
  };

  const renderUpArrow = (i, j) => {
    if (j !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i, j) }} aria-label="up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, j, last) => {
    if (j !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i, j) }} aria-label="down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const removeSectionItem = (i, j) => {
    const details = cloneDeep(purchaseDetails);
    details[i].sections.splice(j, 1);
    setPurchaseDetails(details);
  };

  const handleSyncProductChange = event => {
    setSyncProductIndex(event.target.value);
  };

  const syncWithSelectedProduct = () => {
    const details = cloneDeep(products[syncProductIndex].purchaseDetails);
    setPurchaseDetails(details);
    setShowSync(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (!products.length) {
      return null;
    }

    return (
      <p>
        <Link to={`/academy-content/member-content`} style={{color: 'blue', textDecoration: 'none'}}>Academy Content</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        <Link to={`/academy-content/member-content/${product.id}`} style={{color: 'blue', textDecoration: 'none'}}>{product.name}</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        Purchase Details
      </p>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        {renderBreadcrumbs()}
      </div>
      <Card className="drag-card" style={{marginTop: '10px', display: 'inline-block', textAlign: 'left'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {product.name} Purchase Details
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveProduct() }}
          >
            Save
          </Button>
        </Toolbar>

        <CardContent>
          <TextField
            label={'Displayed Regular Price'}
            value={product.regularPrice}
            onChange={handlePriceChange('regularPrice')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '49%', marginRight: '2%'}}
          />

          <TextField
            label={'Displayed Sale Price'}
            value={product.salePrice}
            onChange={handlePriceChange('salePrice')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '49%'}}
          />

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addSection() }}
          >
            Add Section
          </Button>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addCTAButton() }}
          >
            Add CTA Button
          </Button>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { setShowSync(true) }}
          >
            Sync With Another Product
          </Button>

          {purchaseDetails.map((d, i) => {
            if (typeof d === 'string') {
              return (
                <div key={`edit-section-${i}`} style={{marginTop: '25px'}}>
                  <hr/>
                  <h3>CTA</h3>

                  <TextField
                    label="CTA BUTTON TEXT"
                    value={purchaseDetails[i]}
                    onChange={handleCTATextChange(i)}
                    margin="normal"
                    variant="outlined"
                    className="day-text-field"
                  />
                </div>
              );
            }

            return (
              <div key={`edit-section-${i}`} style={{marginTop: '25px'}}>
                <hr/>
                <h3>Section {i + 1}</h3>
                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addDetail(i, 'title') }}
                >
                  Add Title
                </Button>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addDetail(i, 'subtitle') }}
                >
                  Add Subtitle
                </Button>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addDetail(i, 'paragraph') }}
                >
                  Add Paragraph
                </Button>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addDetail(i, 'image') }}
                >
                  Add Image
                </Button>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addDetail(i, 'video') }}
                >
                  Add Video
                </Button>

                <Button 
                  variant="contained"
                  color="secondary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { removeSection(i) }}
                >
                  Remove Section
                </Button>

                <List>
                  {purchaseDetails[i].sections.map((s, j) => {
                    return (
                      <ListItem key={`edit-section-${i}-${j}`} style={{borderTopWidth: '1px', borderTopColor: '#bbb', borderTopStyle: 'solid'}}>
                        {renderSectionInput(s, i, j)}

                        {renderUpArrow(i, j)}

                        {renderDownArrow(i, j, purchaseDetails[i].sections.length - 1)}

                        <IconButton edge="start" color="inherit" onClick={() => { removeSectionItem(i, j) }} aria-label="Delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            );
          })}
        </CardContent>
      </Card>

      <Dialog open={showSync} onClose={() => { setShowSync(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sync Purchase Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a product you would like to sync with. Notice, this will overwrite any existing purchase content this product currently has.
          </DialogContentText>
          <FormControl style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel>
              Product
            </InputLabel>
            <Select
              value={syncProductIndex}
              onChange={handleSyncProductChange}
            >
              {products.map((p, i) => {
                return (
                  <MenuItem
                    value={i}
                    key={`sync-products-${i}`}
                  >
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={syncWithSelectedProduct} color="primary" variant="contained">
            Sync
          </Button>
          <Button onClick={() => { setShowSync(false) }} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MembershipProductPurchaseDetails;
