import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Toolbar,
  Typography,
  Divider,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DatePicker from 'react-datepicker';

import './formReports.scss';
import { config } from '../../../../config';
import FormInsights from './formInsights/formInsights';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

function FormReports(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [startTime, setStartTime] = useState(moment().subtract(30, 'days').startOf('day').toDate());
  const [endTime, setEndTime] = useState(moment().endOf('day').toDate());
  const [form, setForm] = useState({});
  const [formInsights, setFormInsights] = useState([]);

  useEffect(() => {
    fetchForm();
  }, []);

  useEffect(() => {
    if (form.created) {
      fetchFormInsights(moment(startTime).startOf('day').valueOf(), moment(endTime).endOf('day').valueOf());
    }
  }, [form.created]);

  const fetchForm = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/marketing-tools/forms');
        return;
      }

      const querySnapshot = await db.collection('forms').doc(id).get();
      const data = querySnapshot.data();

      if (!data) {
        props.history.replace('/marketing-tools/forms');
        return;
      }

      const formData = {
        ...data,
        id: querySnapshot.id,
      };

      setForm(formData);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching this form. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const fetchFormInsights = async (start, end) => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/form/${form.id}/insights?start=${start}&end=${end}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setFormInsights(response.data);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching form insights. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="FormReports">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/marketing-tools/forms">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Forms
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            <strong>{form.title || 'Unnamed Form'} Report</strong>
          </Typography>
        </Toolbar>

        <div className="content-container">
          <div className="dates-row">
            <div className="date-picker-container">
              <Typography>
                <small>Start</small>
              </Typography>
              <DatePicker
                selected={startTime}
                onChange={(date) => { setStartTime(date) }}
                maxDate={endToday}
              />
            </div>
            <div className="date-divider" style={{marginTop: 14}}>-</div>
            <div className="date-picker-container">
              <Typography>
                <small>End</small>
              </Typography>
              <DatePicker
                selected={endTime}
                onChange={(date) => { setEndTime(date) }}
                maxDate={endToday}
              />
            </div>

            <div className="dates-row-item-container">
              <Button
                variant="contained"
                onClick={() => {
                  fetchFormInsights(moment(startTime).startOf('day').valueOf(), moment(endTime).endOf('day').valueOf());
                }}
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>

          <Divider />

          <div className="data-container">
            <FormInsights
              formInsights={formInsights}
              startDate={startTime}
              endDate={endTime}
              formId={form.id}
            />
          </div>
        </div>
      </div>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormReports;
