import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Paper,
  TableContainer,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditBehavior = (props) => {
  const [popup, setPopup] = useState(props.popup);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [showAddModal, setShowAddModal] = useState({articles: false, module: false, products: false});
  const [newItem, setNewItem] = useState({});

  const initialModalState = {articles:false, module:false, products:false};

  const handleChange = (e, type) => {
    setPopup({
      ...popup,
      [type]: e.target.value,
    });
  };

  const addItem = (type) =>{
    const newPopup = cloneDeep(popup);

    if(type === 'modules'){  
      newPopup.module.push(newItem);
      setPopup(newPopup);
    }

    if(type === 'articles'){  
      newPopup.articles.push(newItem);
      setPopup(newPopup);
    }

    if(type === 'products'){  
      newPopup.products.push(newItem);
      setPopup(newPopup);
    }

    setShowAddModal(initialModalState);
  };

  const removeItem = (type, i) => {
    const newPopup = cloneDeep(popup);

    if (type === 'articles') {
      newPopup.articles.splice(i, 1);
      setPopup(newPopup);
    }

    if (type === 'modules') {
      newPopup.module.splice(i, 1);
      setPopup(newPopup);
    }

    if (type === 'products') {
      newPopup.products.splice(i, 1);
      setPopup(newPopup);
    }
  };

  return (
    <div>
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.closeModal(); }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {popup.title}
          </Typography>
          <Button
            color="secondary"
            onClick={() => {
              props.savePopup(popup, 'behaviors');
            }}
            disabled={!props.isNewUrlUnique(popup.urlPath)}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{padding: 20}}>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <TextField
            label="title"
            value={popup.title}
            onChange={(e) => { handleChange(e, 'title'); }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <TextField
            label="description"
            value={popup.description}
            onChange={(e) => { handleChange(e, 'description'); }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
        <FormControl variant="outlined" style={{marginTop: 20, marginBottom: 20, width: '100%'}}>
          <InputLabel>URL Path</InputLabel>
          <OutlinedInput
            value={popup.urlPath}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setPopup({
                ...popup,
                urlPath: value.toLowerCase(),
              });
            }}
            label="URL Path"
            variant="outlined"
            type="text"
            error={!props.isNewUrlUnique(popup.urlPath)}
            startAdornment={<InputAdornment style={{marginRight: 0}} position="start">browse/</InputAdornment>}
          />
          {props.isNewUrlUnique(popup.urlPath) ? null :
            <FormHelperText>
              <small style={{color: 'red'}}>URL {!popup.urlPath ? 'is required' : 'must be unique'}</small>
            </FormHelperText>
          }
        </FormControl>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <TextField
            label="Image Url"
            value={popup.imageUrl}
            onChange={(e) => { handleChange(e, 'imageUrl'); }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <TextField
            label="Video Id"
            value={popup.videoid}
            onChange={(e) => { handleChange(e, 'videoid'); }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
        <TableContainer component={Paper} style={{marginBottom: 20}}>
          <Toolbar>
            <Typography variant="h6" style={{
              flex: 1,
            }}>
              Modules
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewItem(props.simplifiedData.modules[0] || {});
                setShowAddModal({articles:false, module:true, products:false});     
              }}
            >
              Add Module
            </Button>
          </Toolbar>
          <Divider />
          {popup.module.length === 0 ?
            <Toolbar>
              <Typography>No Modules have been added.</Typography>
            </Toolbar> :
            <Table aria-label={'modules table'} style={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popup.module.map((module, i) => (
                  <TableRow key={`module-${module.courseId}${Math.random()}`}>
                    <TableCell>{module.title}</TableCell>
                    <TableCell>{module.courseId}</TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.module[i];
                          const item2 = newPopup.module[i - 1];
                          
                          newPopup.module[i] = item2;
                          newPopup.module[i - 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowUpIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === popup.module.length - 1 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.module[i];
                          const item2 =newPopup.module[i + 1];
                          
                          newPopup.module[i] = item2;
                          newPopup.module[i + 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowDownIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => removeItem('modules', i)} 
                        aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>

        <TableContainer component={Paper} style={{marginBottom: 20}}>
          <Toolbar>
            <Typography variant="h6" style={{
              flex: 1,
            }}>
              Articles
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewItem(props.simplifiedData.articles[0] || {});
                setShowAddModal({articles: true, module:false, products:false});     
              }}
            >
              Add Article
            </Button>
          </Toolbar>
          <Divider />
          {popup.articles.length === 0 ?
            <Toolbar>
              <Typography>No Articles have been added.</Typography>
            </Toolbar> :
            <Table aria-label={'articles table'} style={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popup.articles.map((article, i) => (
                  <TableRow key={`articles-${article.courseId}${Math.random()}`}>
                    <TableCell>{article.title}</TableCell>
                    <TableCell>{article.firestoreId}</TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.articles[i];
                          const item2 = newPopup.articles[i - 1];
                          
                          newPopup.articles[i] = item2;
                          newPopup.articles[i - 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowUpIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === popup.articles.length - 1 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.articles[i];
                          const item2 = newPopup.articles[i + 1];
                          
                          newPopup.articles[i] = item2;
                          newPopup.articles[i + 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowDownIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => removeItem('articles', i)} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>

        <TableContainer component={Paper} style={{marginBottom: 20}}>
          <Toolbar>
            <Typography variant="h6" style={{
              flex: 1,
            }}>
              Products
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewItem(props.simplifiedData.products[0] || {});
                setShowAddModal({products: true, module:false, articles:false});     
              }}
            >
              Add Product
            </Button>
          </Toolbar>
          <Divider />
          {popup.products.length === 0 ?
            <Toolbar>
              <Typography>No Products have been added.</Typography>
            </Toolbar> :
            <Table aria-label={'products table'} style={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popup.products.map((product, i) => (
                  <TableRow key={`products-${product.courseId}${Math.random()}`}>
                    <TableCell>{product.name || product.shopPageName}</TableCell>
                    <TableCell>{product.id}</TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.products[i];
                          const item2 = newPopup.products[i - 1];
                          
                          newPopup.products[i] = item2;
                          newPopup.products[i - 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowUpIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === popup.products.length - 1 ? null :
                        <IconButton color="inherit" onClick={() => {
                          const newPopup = cloneDeep(popup);
                          const item1 = newPopup.products[i];
                          const item2 = newPopup.products[i + 1];
                          
                          newPopup.products[i] = item2;
                          newPopup.products[i + 1] = item1;

                          setPopup(newPopup);
                        }} aria-label="Move Up">
                          <ArrowDownIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => removeItem('products', i)} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>

        <Dialog fullWidth open={showAddModal.module} onClose={() => {setShowAddModal(initialModalState);  }} TransitionComponent={Transition}>
          <DialogTitle>Add Module</DialogTitle>
          <DialogContent>
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel>
              Module
              </InputLabel>
              <Select
                style={{width: '100%'}}
                value={newItem}
                onChange={(e) => { setNewItem(e.target.value);}}
                label="Module"
              >
                {(props.simplifiedData.modules || []).map((m, i) => {
                  return <MenuItem key={`module-option-${i}`} value={m}>{m.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=>{ addItem('modules');}} color="secondary">
            Add
            </Button>
            <Button variant="contained" onClick={() => { setShowAddModal(initialModalState);}} color="primary">
            Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={showAddModal.articles} onClose={() => {setShowAddModal(initialModalState);}} TransitionComponent={Transition}>
          <DialogTitle>Add Article</DialogTitle>
          <DialogContent>
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel>
              Article
              </InputLabel>
              <Select
                style={{width: '100%'}}
                value={newItem}
                onChange={(e) => { setNewItem(e.target.value);}}
                label="Article"
              >
                {(props.simplifiedData.articles || []).map((article) => {
                  return <MenuItem key={`option-${article.firestoreId}`} value={article}>{article.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=>{ addItem('articles');}} color="secondary">
            Add
            </Button>
            <Button variant="contained" onClick={() => { setShowAddModal(initialModalState); }} color="primary">
            Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={showAddModal.products} onClose={() => {setShowAddModal(initialModalState);}} TransitionComponent={Transition}>
          <DialogTitle>Add Product</DialogTitle>
          <DialogContent>
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel>
              Product
              </InputLabel>
              <Select
                style={{width: '100%'}}
                value={newItem}
                onChange={(e) => { setNewItem(e.target.value);}}
                label="Product"
              >
                {(props.simplifiedData.products || []).map((product) => {
                  return <MenuItem key={`option-${product.id}`} value={product}>{product.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=>{ addItem('products');}} color="secondary">
            Add
            </Button>
            <Button variant="contained" onClick={() => { setShowAddModal(initialModalState); }} color="primary">
            Cancel
            </Button>
          </DialogActions>
        </Dialog>   
      </div>
    </div>
  );
};

export default EditBehavior;