import {
  SET_WHOLESALE_PRODUCTS,
} from '../actions/types';

const defaultState = {
  products: [],
  initialized: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_WHOLESALE_PRODUCTS:
      return {
        initialized: true,
        products: action.payload,
      };
    default:
      return state;
  }
};
