import { useFieldArray } from 'react-hook-form';

export const useAcademyPlusItemsFields = (control) => {
  const {
    append,
    remove,
    fields: academyPlusItems,
    move: moveAcademyPlusItem,
  } = useFieldArray({
    control: control,
    name: 'academyPlusSection.courses',
  });

  const appendAcademyPlusItem = () => {
    append({
      name: '',
      url: '',
    });
  };

  const removeAcademyPlusItem = (index) => {
    remove(index);
  };

  return {
    academyPlusItems,
    appendAcademyPlusItem,
    removeAcademyPlusItem,
    moveAcademyPlusItem,
  };
};
