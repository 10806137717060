import React from 'react';
import StatisticItem from './statistic-item';
import './statistics-section.scss';

export const StatisticsSection = ({ data }) => {
  const { title, content, backgroundColor, items } = data;

  return (
    <section className="StatisticContainer" style={{ backgroundColor }}>
      <div className="contentContainer">
        <h3 className="title">{title}</h3>
        <p className="content">{content}</p>
      </div>
      <div className="itemsContainer">
        {items.map((item) => (
          <StatisticItem key={item.title} data={item} />
        ))}
      </div>
    </section>
  );
};
