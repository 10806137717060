import React, { useState } from 'react';
import {
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';

import './formConditions.scss';
import FormConditionEditor from './formConditionEditor/formConditionEditor';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const conditionTypes = [
  {
    name: 'Hide Field',
    description: 'Hide field(s) if certain conditions are met.',
    type: 'hide-field',
  },
  {
    name: 'Hide Page',
    description: 'Hide page(s) if certain conditions are met.',
    type: 'hide-page',
  },
  {
    name: 'Require Field',
    description: 'Require field(s) if certain conditions are met.',
    type: 'require-field',
  },
  {
    name: 'Disable Field',
    description: 'Disable field(s) if certain conditions are met.',
    type: 'disable-field',
  },
  // {
  //   name: 'Calculate Field',
  //   description: 'Update the value of a field based on the value of other fields.',
  //   type: 'calculate-field',
  // },
];

function FormConditions({ pages, conditions, updateConditions }) {
  const [currentConditionIndex, setCurrentConditionIndex] = useState(0);
  const [conditionDeleteIndex, setConditionDeleteIndex] = useState(-1);
  const [addConditionTypeIndex, setAddConditionTypeIndex] = useState(0);
  const [addConditionOpen, setAddConditionOpen] = useState(false);

  const addFormCondition = () => {
    const type = conditionTypes[addConditionTypeIndex].type;

    updateConditions([
      ...conditions,
      {
        conditionsToMeet: [{
          field: '',
          state: '',
          target: 'value',
          value: '',
          targetField: '',
        }],
        meetAllConditions: true,
        applyTo: [''],
        type,
      },
    ]);
    setCurrentConditionIndex(conditions.length);
    setAddConditionOpen(false);
  };

  return (
    <div className="FormConditions">
      <div className="pages-container">
        <div className="left-container">
          {conditions.map((c, i) => {
            return (
              <div key={`condition-${i}`} className="form-page-container">
                <div
                  className="delete-button"
                  onClick={() => { setConditionDeleteIndex(i) }}
                >
                  <p>×</p>
                </div>
                <div
                  className={`page-flow-container form-page${currentConditionIndex === i ? ' selected' : ''}`}
                  onClick={() => {
                    setCurrentConditionIndex(i);
                  }}
                >
                  <p className="capitalize">
                    <strong>{c.type.split('-').join(' ')}</strong>
                  </p>
                </div>
              </div>
            );
          })}

          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ width: '100%' }}
            onClick={() => {
              setAddConditionTypeIndex(0);
              setAddConditionOpen(true);
            }}
          >
            Add Condition
          </Button>
        </div>

        {!conditions.length ? null :
          <div className="right-container">
            <FormConditionEditor
              condition={conditions[currentConditionIndex]}
              updateCondition={(condition) => {
                const conditionsCopy = [ ...conditions ];

                conditionsCopy[currentConditionIndex] = condition;

                updateConditions(conditionsCopy);
              }}
              conditionTypes={conditionTypes}
              pages={pages}
            />
          </div>
        }
      </div>

      <Dialog fullWidth maxWidth="sm" open={conditionDeleteIndex !== -1} onClose={() => { setConditionDeleteIndex(-1) }}>
        <DialogTitle>Delete Condition?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this condition?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (currentConditionIndex === (conditions.length - 1) && currentConditionIndex !== 0) {
                setCurrentConditionIndex(currentConditionIndex - 1);
              }

              const updatedConditions = [ ...conditions ];
              updatedConditions.splice(conditionDeleteIndex, 1);

              updateConditions(updatedConditions);
              setConditionDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setConditionDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addConditionOpen} onClose={() => { setAddConditionOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Select Condition Type</DialogTitle>
        <DialogContent>
          <div className="add-form-condition-modal">
            {conditionTypes.map((type, i) => {
              return (
                <div
                  key={`add-form-condition-type-${i}`}
                  className={`add-form-condition-modal-item${addConditionTypeIndex === i ? ' selected' : ''}`}
                  onClick={() => setAddConditionTypeIndex(i)}
                >
                  <p className="add-form-condition-modal-item-title">{type.name}</p>
                  <p className="add-form-condition-modal-item-text">{type.description}</p>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addFormCondition} color="primary">
            Add
          </Button>
          <Button variant="contained" onClick={() => { setAddConditionOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormConditions;
