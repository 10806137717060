import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ProductOptionsForm } from './productPageForm';
import 'firebase/firestore';
import 'firebase/storage';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';

const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        textAlign: 'center',
      }}
    >
      <CircularProgress
        style={{ color: '#fff', top: '50%', position: 'absolute' }}
      />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  buttonAddCart: {
    borderColor: '',
    backgroundColor: '',
    textColor: '',
  },
  buttonBuyNow: {
    borderColor: '',
    backgroundColor: '',
    textColor: '',
  },
};

export const ProductOptions = () => {
  const [defaultValues, setDefaultValues] = useState();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const getProductOptionsData = async () => {
    setIsDataLoading(true);

    try {
      const productOptions = await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('product-page-options')
        .get();

      const defaultData = productOptions.data();

      console.log({ defaultData });

      let parsedData = initialValues;

      if (defaultData) {
        parsedData = {
          buttonAddCart: {
            borderColor: defaultData?.buttonAddCart?.borderColor ?? '',
            backgroundColor: defaultData?.buttonAddCart?.backgroundColor ?? '',
            textColor: defaultData?.buttonAddCart?.textColor ?? '',
          },
          buttonBuyNow: {
            borderColor: defaultData?.buttonBuyNow?.borderColor ?? '',
            backgroundColor: defaultData?.buttonBuyNow?.backgroundColor ?? '',
            textColor: defaultData?.buttonBuyNow?.textColor ?? '',
          },
        };
      }

      console.log({ parsedData });

      setDefaultValues(parsedData);
    } catch (error) {
      window.alert(
        'An error occurred fetching header content. Refresh the page and try again.',
      );
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    if (!defaultValues) {
      getProductOptionsData();
    }
  }, [defaultValues]);

  const onSubmitForm = async (formData) => {
    setIsDataLoading(true);

    const productOptionsData = {
      buttonAddCart: {
        borderColor: formData.buttonAddCart.borderColor ?? '',
        backgroundColor: formData.buttonAddCart.backgroundColor ?? '',
        textColor: formData.buttonAddCart.textColor ?? '',
      },
      buttonBuyNow: {
        borderColor: formData.buttonBuyNow.borderColor ?? '',
        backgroundColor: formData.buttonBuyNow.backgroundColor ?? '',
        textColor: formData.buttonBuyNow.textColor ?? '',
      },
    };

    try {
      await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('product-page-options')
        .update(productOptionsData);

      setModalTitle('Success!');
      setModalText(
        'The header shop menus have been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (error) {
      console.log({ error });
      setModalTitle('Error:');
      setModalText(
        'There was an error saving the header shop menus, please try again.',
      );
    } finally {
      setIsDataLoading(false);
    }
  };

  return (
    <article>
      {isDataLoading ? <Loader /> : null}

      <Link to="/website-content/dashboard">
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginRight: '10px', marginBottom: '15px' }}
          startIcon={<ArrowBackIcon />}
        >
          Dashboard
        </Button>
      </Link>
      <Card variant="outlined">
        <ProductOptionsForm
          onSubmit={onSubmitForm}
          defaultValues={defaultValues}
        />
      </Card>

      <Dialog
        open={Boolean(modalText)}
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </article>
  );
};
