import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { z } from 'zod';

const item = z.object({
  name: z.string().min(1),
  url: z.string().min(1),
});

const menuItem = z.object({
  name: z.string().min(1),
  url: z.string().min(1),
  submenuItems: z.array(item).optional(),
});

const shopMenu = z.object({
  title: z.string().min(1),
  subtitle: z.string().min(1),
  titleColor: z.string().min(1).default('#ffffff'),
  shopAllUrl: z.string().optional(),
  isLive: z.boolean(),
  menuItems: z.array(menuItem),
});

const menuSchema = z.object({
  shopMenus: z.array(shopMenu).min(1),
  academySection: z.object({
    title: z.string().min(1),
    subtitle: z.string().min(1),
    courses: z.array(item).min(1).max(5),
    viewAllUrl: z.string().min(1),
  }),
  academyPlusSection: z.object({
    title: z.string().min(1),
    subtitle: z.string().min(1),
    courses: z.array(item).min(1).max(15),
    viewAllUrl: z.string().min(1),
  }),
  academyUrl: z.string().min(1),
  blogSection: z.object({
    recentBlogs: z.object({
      title: z.string().min(1),
      subtitle: z.string().min(1),
      titleColor: z.string().min(1).default('#ffffff'),
      blogUrl: z.string().min(1),
      // items: z.array(item).min(1),
    }),
    // blogCategories: z.object({
    //   title: z.string().min(1),
    //   subtitle: z.string().min(1),
    //   titleColor: z.string().min(1).default('#ffffff'),
    //   items: z.array(item).min(1),
    // }),
    communityLinks: z.object({
      title: z.string().min(1),
      subtitle: z.string().min(1),
      titleColor: z.string().min(1).default('#ffffff'),
      items: z.array(item).min(1),
    }),
  }),
});

const headerFormResolver = zodResolver(menuSchema, undefined, {
  raw: true,
});

export const useHeaderForm = (defaultValues) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: headerFormResolver,
    defaultValues: defaultValues,
  });

  const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: 'shopMenus',
  });

  const addItem = () => {
    append({
      title: '',
      subtitle: '',
      color: '',
      shopAllUrl: '',
      isLive: false,
      menuItems: [
        {
          name: '',
          url: '',
        },
      ],
    });
  };

  const removeItem = (index) => {
    remove(index);
  };

  return { ...form, fields, addItem, removeItem, moveItem: move };
};
