import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './serviceCategory.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ServiceCategory({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [serviceCategory, setServiceCategory] = useState({
    title: '',
    description: '',
    path: '',
    imageUrl: '',
    live: false,
    services: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [serviceIndex, setServiceIndex] = useState(-1);
  const [service, setService] = useState({
    title: '',
    description: '',
    path: '',
    imageUrl: '',
    details: '',
    price: '',
    type: 'affiliate-link',
    affiliateLink: '',
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchServiceCategory();
  }, []);

  const fetchServiceCategory = async () => {
    try {
      const id = match.params.id;

      if (id === undefined) {
        history.replace('/services/service-categories');
        return;
      }

      const querySnapshot = await firebase.firestore().collection('service-categories').doc(id).get();
      const data = querySnapshot.data();

      if (!data) {
        history.replace('/services/service-categories');
        return;
      }

      setLoading(false);
      setServiceCategory(data);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred fetching this service category. Refresh the page and try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      const id = match.params.id;
      const updatedServiceCategory = {
        ...serviceCategory,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('service-categories').doc(id).update(updatedServiceCategory);

      setServiceCategory(updatedServiceCategory);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('This service category was saved successfully. To make these changes live on the website, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred saving this service category. Refresh the page and try again.');
    }
  };

  const saveService = async () => {
    setLoading(true);
    
    try {
      const serviceCategoryId = match.params.id;
      const updatedService = {
        ...service,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };
      const updatedServices = [ ...serviceCategory.services ];

      updatedServices[serviceIndex] = updatedService;

      const updatedServiceCategory = {
        ...serviceCategory,
        services: updatedServices,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('service-categories').doc(serviceCategoryId).update(updatedServiceCategory);

      setServiceCategory(updatedServiceCategory);
      setLoading(false);
      setServiceIndex(-1);
      setModalTitle('Success!');
      setModalText('The service was saved successfully. To make these changes live on the website, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred saving this service. Refresh the page and try again.');
    }
  };

  const closeService = () => {
    setServiceIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ServiceCategory">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/services/service-categories">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<ArrowBackIcon />}
          >
            All Service Categories
          </Button>
        </Link>
      </div>

      <div className="last-modified-container">
        <span>
          <strong>Last Modified:</strong> <span style={{marginRight: 10}}>{moment(serviceCategory.updated).calendar()}</span>
          <strong>Last Modified By:</strong> {serviceCategory.lastUpdatedBy}
        </span>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>{serviceCategory.title || 'Unnamed Service Category'}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={save}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <FormControlLabel
            control={
              <Switch
                checked={serviceCategory.live}
                onChange={(e) => {
                  setServiceCategory({
                    ...serviceCategory,
                    live: e.target.checked,
                  });
                }}
                name="live"
                color="primary"
              />
            }
            label="Live"
          />

          <TextField
            label="Title"
            value={serviceCategory.title}
            onChange={(e) => {
              setServiceCategory({
                ...serviceCategory,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label="Service Description"
            value={serviceCategory.description}
            onChange={(e) => {
              setServiceCategory({
                ...serviceCategory,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label="Image URL"
            value={serviceCategory.imageUrl}
            onChange={(e) => {
              setServiceCategory({
                ...serviceCategory,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={serviceCategory.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setServiceCategory({
                ...serviceCategory,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <div className="services-header">
            <Typography className="services-header-title">Services</Typography>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setService({
                  title: '',
                  description: '',
                  path: '',
                  imageUrl: '',
                  details: '',
                  price: '',
                  type: 'affiliate-link',
                  affiliateLink: '',
                  live: false,
                  created: Date.now(),
                  updated: Date.now(),
                  lastUpdatedBy: firebase.auth().currentUser.email,
                });
                setServiceIndex(serviceCategory.services.length);
              }}
            >
              Add Service
            </Button>
          </div>

          <List>
            {serviceCategory.services.map((service, i) => {
              return (
                <div key={`service-${i}`}>
                  {i !== 0 ? null : <Divider/>}
                  <ListItem>
                    <ListItemText
                      primary={service.title}
                    />
                    {i === 0 ? null :
                      <IconButton edge="start" color="inherit" style={{ marginRight: 10 }} onClick={() => {
                        const services = [ ...serviceCategory.services ];

                        const itemToMoveUp = services[i];
                        const itemToMoveDown = services[i - 1];

                        services[i] = itemToMoveDown;
                        services[i - 1] = itemToMoveUp;

                        setServiceCategory({
                          ...serviceCategory,
                          services,
                        });
                      }} aria-label="Move Up">
                        <ArrowUpIcon />
                      </IconButton>
                    }
                    {(i === serviceCategory.services.length - 1) ? null :
                      <IconButton edge="start" color="inherit" style={{ marginRight: 10 }} onClick={() => {
                        const services = [ ...serviceCategory.services ];

                        const itemToMoveUp = services[i];
                        const itemToMoveDown = services[i + 1];

                        services[i] = itemToMoveDown;
                        services[i + 1] = itemToMoveUp;

                        setServiceCategory({
                          ...serviceCategory,
                          services,
                        });
                      }} aria-label="Move Up">
                        <ArrowDownIcon />
                      </IconButton>
                    }
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="Edit"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setService({ ...service });
                        setServiceIndex(i);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="Delete"
                      onClick={() => setDeleteIndex(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  <Divider/>
                </div>
              );
            })}
          </List>
        </div>
      </div>

      <Dialog fullScreen open={serviceIndex !== -1} onClose={closeService} TransitionComponent={Transition}>
        <DialogTitle>{serviceCategory.services[serviceIndex] ? 'Edit' : 'Add'} Service</DialogTitle>
        <DialogContent style={{ borderColor: 'rgba(0, 0, 0, 0.12)', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0 }}>
          <FormControlLabel
            control={
              <Switch
                checked={service.live}
                onChange={(e) => {
                  setService({
                    ...service,
                    live: e.target.checked,
                  });
                }}
                name="live"
                color="primary"
              />
            }
            label="Live"
          />

          <TextField
            label="Title"
            value={service.title}
            onChange={(e) => {
              setService({
                ...service,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label="Description"
            value={service.description}
            onChange={(e) => {
              setService({
                ...service,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label="Image URL"
            value={service.imageUrl}
            onChange={(e) => {
              setService({
                ...service,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <FormControl variant="outlined" className="day-text-field" style={{marginTop: 20}}>
            <InputLabel>Price</InputLabel>
            <OutlinedInput
              value={service.price}
              onChange={(e) => {
                setService({
                  ...service,
                  price: e.target.value,
                });
              }}
              margin="dense"
              label="Price"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              className="day-text-field"
            />
          </FormControl>

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={service.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setService({
                ...service,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <TextField
            label='CTA Button Text (defaults to "Book Service")'
            value={service.ctaButtonText || ''}
            onChange={(e) => {
              setService({
                ...service,
                ctaButtonText: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <FormControl variant="outlined" className="day-text-field" style={{marginTop: 20}}>
            <InputLabel>
              Affiliate Type
            </InputLabel>
            <Select
              className="day-text-field"
              margin="dense"
              label="Affiliate Type"
              variant="outlined"
              value={service.type}
              onChange={(e) => {
                setService({
                  ...service,
                  type: e.target.value,
                });
              }}
            >
              <MenuItem value="affiliate-link">Affiliate Link</MenuItem>
            </Select>
          </FormControl>

          {service.type !== 'affiliate-link' ? null :
            <TextField
              label="Affiliate Link"
              value={service.affiliateLink}
              onChange={(e) => {
                setService({
                  ...service,
                  affiliateLink: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
              style={{ marginTop: 20 }}
            />
          }

          <div style={{marginTop: 10, marginBottom: 100}}>
            <Typography style={{marginBottom: 5, fontSize: 13, color: 'rgba(0, 0, 0, 0.54)'}}>Details</Typography>
            <ReactQuill
              theme="snow"
              value={service.details}
              onChange={(details) => {
                setService({
                  ...service,
                  details,
                });
              }}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['link'],
                ],
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet',
                'link',
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveService} color="primary" disabled={!service.title || !service.description || !service.imageUrl || !service.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={closeService} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Service?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete <strong>{deleteIndex === -1 ? '' : serviceCategory.services[deleteIndex].title}</strong>?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const updatedServices = [ ...serviceCategory.services ];
              updatedServices.splice(deleteIndex, 1);

              setServiceCategory({
                ...serviceCategory,
                services: updatedServices,
              });
              setDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ServiceCategory;
