import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DatePicker from 'react-datepicker';
import {
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';

import './oneClickUpsellsPerformance.scss';

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

function OneClickUpsellsPerformance(props) {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async (newStart, newEnd) => {
    const end = newEnd || endToday;
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date(end.getTime() - (7 * 24 * 60 * 60 * 1000));
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);

    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection('orders-v2')
        .where('created', '>=', start)
        .where('created', '<=', end)
        .where('hasOneClickUpsell', '==', true)
        .get();

      const totalCountsMap = {};

      querySnapshot.docs.forEach(doc => {
        const data = doc.data();

        data.products.forEach(p => {
          if (p.isOneClickUpsell && !totalCountsMap[p.id]) {
            totalCountsMap[p.id] = {
              name: p.name,
              quantity: p.quantity,
              orderCount: 1,
              amount: p.quantity * +p.paidPrice,
              totalOrdersValue: +data.total,
            };
          } else if (p.isOneClickUpsell) {
            totalCountsMap[p.id].quantity += p.quantity;
            totalCountsMap[p.id].orderCount += 1;
            totalCountsMap[p.id].amount += (p.quantity * +p.paidPrice);
            totalCountsMap[p.id].totalOrdersValue += +data.total;
          }
        });

        return data;
      });

      const totalCountsArray = [];

      for (let key in totalCountsMap) {
        totalCountsArray.push({
          ...totalCountsMap[key],
        });
      }

      setSummaryData(totalCountsArray);
      setLoading(false);
      props.setOrdersLength(querySnapshot.docs.length);
    } catch (e) {
      console.log(e.toString());
      setLoading(false);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="OneClickUpsellsPerformance">
      <div style={{display: 'flex', position: 'relative', zIndex: 1000, marginBottom: 30}}>
        <div className="date-picker-container">
          <Typography>
            <small>Start</small>
          </Typography>
          <DatePicker
            popperPlacement="top-start"
            selected={startDate}
            onChange={(date) => { fetchOrders(date, endDate) }}
            maxDate={endToday}
          />
        </div>
        <div className="date-divider" style={{marginTop: 14}}>-</div>
        <div className="date-picker-container">
          <Typography>
            <small>End</small>
          </Typography>
          <DatePicker
            popperPlacement="top-end"
            selected={endDate}
            onChange={(date) => { fetchOrders(startDate, date) }}
            maxDate={endToday}
          />
        </div>
      </div>

      <div className="card-row">
        {summaryData.map(d => {
          return (
            <div className="card-container" key={d.name}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    {d.name}
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="subtitle1">
                    <strong>Number or Orders:</strong> {d.orderCount}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Revenue Added:</strong> {formatter.format(d.amount)}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Average Order Value:</strong> {formatter.format(d.totalOrdersValue / d.orderCount)}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OneClickUpsellsPerformance;
