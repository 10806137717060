import {
  SET_NOTIFICATIONS,
} from '../actions/types';

const defaultState = {
  blogNotificationCount: 0,
  pendingBlogNotificationCount: 0,
  failedOrdoroOrderCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
