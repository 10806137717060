import React from 'react';
import './product-item.scss';

export const ProductItem = ({ data }) => {
  const { title, imageUrl, linkText, linkColor } = data;

  return (
    <article className="ProductItemContainer">
      <img className="ProductItemImage" src={imageUrl} alt={imageUrl} />
      <div className="ProductContentContainer">
        <p className="ProductItemTitle">{title}</p>
        <button
          className="ProductButton"
          style={{ backgroundColor: linkColor, borderColor: 'linkColor' }}
        >
          {linkText}
        </button>
      </div>
    </article>
  );
};
