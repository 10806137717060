import React from 'react';
import DeleteIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';

function DeletableChip(props) {
  const { onDelete, value } = props;

  return (
    Boolean(onDelete) ? (
    <Chip 
      value={value}
      deleteIcon={
        <div onMouseDown={(event) => {
          if (!props.disabled && event.button === 0) {
            event.stopPropagation()
            onDelete(value)
          }
        }}>
          <DeleteIcon className="MuiChip-deleteIcon" style={{margin: '0px 5px 0px 0px', color: '#fff'}} />
        </div>
      }
      {...props}
    />
    ) : ( <Chip {...props}/> )
  );
}

export default DeletableChip;
