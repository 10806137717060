import React, { useState } from 'react';
import Papa from 'papaparse';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import './giftCards.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GiftCards({}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [giftCardAmount, setGiftCardAmount] = useState(10);
  const [numberOfCardsToGenerate, setNumberOfCardsToGenerate] = useState(1);
  const [giftCards, setGiftCards] = useState([]);

  const generateCodes = async () => {
    setLoading(true);

    try {
      const generatedGiftCards = [];

      for (let i = 0; i < parseInt(numberOfCardsToGenerate); i++) {
        const card = await firebase.firestore().collection('gift-cards').add({
          activity: [],
          amount: parseFloat(giftCardAmount),
          cardImage: '',
          cardMessage: '',
          deliveryDate: 32503705200000,
          orderId: '',
          recipientEmail: 'admin-generated',
          recipientName: 'admin-generated',
          senderName: 'admin-generated',
        });

        generatedGiftCards.push({
          code: card.id,
          date: moment().format('lll'),
          amount: `$${(+giftCardAmount).toFixed(2)}`,
        });
      }

      setLoading(false);
      setGiftCards(generatedGiftCards);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error generating the gift cards. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const downloadCSV = () => {
    const csvString = Papa.unparse(giftCards);

    const blob = new Blob([csvString]);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, 'gift-cards.csv');
    } else{
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, {type: 'text/csv;charset=utf-8;'});
      a.download = 'gift-cards.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className="GiftCards">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Create Gift Cards
          </Typography>
        </Toolbar>

        <Divider/>

        <div className="content-container">
          <div className="input-row">
            <FormControl
              variant="outlined"
              style={{marginTop: 8, marginBottom: 4, marginRight: 5, flex: 1}}
              className="day-text-field"
            >
              <InputLabel>Gift Card Amount</InputLabel>
              <OutlinedInput
                value={giftCardAmount}
                onChange={(e) => {
                  setGiftCardAmount(e.target.value);
                }}
                margin="dense"
                label="Gift Card Amount"
                variant="outlined"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                className="day-text-field"
              />
            </FormControl>

            <TextField
              style={{marginTop: 8, marginBottom: 4, marginLeft: 5, flex: 1}}
              label="Number of Codes"
              value={numberOfCardsToGenerate}
              onChange={(e) => {
                setNumberOfCardsToGenerate(e.target.value);
              }}
              variant="outlined"
              margin="dense"
              type="number"
              className="day-text-field"
            />
          </div>

          <Button
            style={{marginTop: 8, marginBottom: 4}}
            variant="contained"
            onClick={generateCodes}
            color="primary"
            className="day-text-field"
            disabled={!giftCardAmount || !numberOfCardsToGenerate}
          >
            Generate Gift Cards
          </Button>
        </div>

        {!giftCards.length ? null :
          <div>
            <div className="download-button-row">
              <Button
                variant="contained"
                onClick={downloadCSV}
                color="secondary"
                size="small"
              >
                Download CSV
              </Button>
            </div>

            <TableContainer component={Paper}>
              <Table aria-label="generated gift cards table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Code</strong></TableCell>
                    <TableCell><strong>Amount</strong></TableCell>
                    <TableCell><strong>Date Generated</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {giftCards.map((c, i) => (
                    <TableRow key={c.code}>
                      <TableCell>
                        <strong>{c.code}</strong>
                      </TableCell>
                      <TableCell>{c.amount}</TableCell>
                      <TableCell>{c.date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GiftCards;
