import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AcademyItems } from './academyItems/academyItems';

import './trainingForm.scss';
import { AcademyPlusItems } from './academyPlusItems/academyPlusItems';

export const TrainingForm = () => {
  const form = useFormContext();
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Training</strong>
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        style={{
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
        }}
      >
        <Controller
          name={'academyUrl'}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="text-field"
              label="Academy Url"
              variant="outlined"
              margin="dense"
              fullWidth
              error={Boolean(errors.academyUrl)}
              helperText={errors.academyUrl?.message}
            />
          )}
        />

        <Accordion className="external-accordion-container">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>Academy Courses</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-container">
            <Controller
              name={'academySection.title'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academySection?.title)}
                  helperText={errors.academySection?.title?.message}
                />
              )}
            />

            <Controller
              name={'academySection.subtitle'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Subtitle"
                  className="text-field"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academySection?.subtitle)}
                  helperText={errors.academySection?.subtitle?.message}
                />
              )}
            />

            <Controller
              name={'academySection.viewAllUrl'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="View All Url"
                  className="text-field"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academySection?.viewAllUrl)}
                  helperText={errors.academySection?.viewAllUrl?.message}
                />
              )}
            />

            <AcademyItems control={control} errors={errors} />
          </AccordionDetails>
        </Accordion>
        <Accordion className="external-accordion-container">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>Academy Plus Courses</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-container">
            <Controller
              name={'academyPlusSection.title'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="text-field"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academyPlusSection?.title)}
                  helperText={errors.academyPlusSection?.title?.message}
                />
              )}
            />

            <Controller
              name={'academyPlusSection.subtitle'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Subtitle"
                  className="text-field"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academyPlusSection?.subtitle)}
                  helperText={errors.academyPlusSection?.subtitle?.message}
                />
              )}
            />

            <Controller
              name={'academyPlusSection.viewAllUrl'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="View All Url"
                  className="text-field"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.academyPlusSection?.viewAllUrl)}
                  helperText={errors.academyPlusSection?.viewAllUrl?.message}
                />
              )}
            />

            <AcademyPlusItems control={control} errors={errors} />
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};
