import React, { useState } from 'react';
import { useHomePageForm } from './useHomePageForm';
import { HeroSection } from './heroSection/heroSection';
import { ProductsSection } from './productsSection/productsSection';
import { FormProvider } from 'react-hook-form';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { GetStartedSection } from './getStartedSection/getStartedSection';
import { GuaranteeSection } from './guaranteeSection/guaranteeSection';
import { StatisticsSection } from './statisticsSection/statisticsSection';
import { ProductsCategoriesSection } from './productCategoriesSection/productsCategoriesSection';
import { AppsUrlsSection } from './appsUrlsSection/appsUrlsSection';
import { AcademySection } from './academySection/academySection';
import { HomePagePreview } from '../homePagePreviewV2/homePagePreview';
import { PageDetailsSection } from './pageDetailsSection/pageDetailsSection';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import firebase from 'firebase/app';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HomePageForm = ({ defaultValues }) => {
  const formMethods = useHomePageForm(defaultValues);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  const { handleSubmit } = formMethods;

  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      await firebase
        .firestore()
        .collection('config')
        .doc('home-page-v2')
        .update(formData);

      setModalTitle('Success!');
      setModalText(
        'This page has been successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (e) {
      console.log(e?.message);
      setModalTitle('Error:');
      setModalText('There was an error saving this page. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 10000,
              backgroundColor: 'rgba(0, 0, 0, .5)',
              textAlign: 'center',
            }}
          >
            <CircularProgress
              style={{ color: '#fff', top: '50%', position: 'absolute' }}
            />
          </div>
        ) : null}
        <Toolbar
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            <strong>Home Page</strong>
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 10 }}
              onClick={() => setShowPreview(true)}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              type="submit"
            >
              Save
            </Button>
          </div>
        </Toolbar>

        <FormProvider {...formMethods}>
          <PageDetailsSection />
          <HeroSection />
          <ProductsSection />
          <GetStartedSection />
          <StatisticsSection />
          <GuaranteeSection />
          <ProductsCategoriesSection />
          <AppsUrlsSection />
          <AcademySection />
        </FormProvider>
      </form>

      <Dialog
        open={!!modalText}
        onClose={() => {
          setModalText('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
        TransitionComponent={Transition}
      >
        <HomePagePreview
          onClose={() => {
            setShowPreview(false);
          }}
        />
      </Dialog>
    </div>
  );
};
