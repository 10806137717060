import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Toolbar,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './wholesaleProduct.scss';
import { setWholesaleProducts } from '../../../actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WholesaleProduct(props) {
  const dispatch = useDispatch();
  const wholesaleProductsInitialized = useSelector(state => state.wholesaleProducts.initialized);
  const wholesaleProducts = useSelector(state => state.wholesaleProducts.products);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (wholesaleProducts.length && !product.id) {
      const foundProductIndex = wholesaleProducts.findIndex(p => p.id === props.match.params.id);
      
      if (foundProductIndex === -1) {
        props.history.replace('/wholesale/products');
        return;
      }

      setProduct({
        ...wholesaleProducts[foundProductIndex],
      });
    }
  }, [wholesaleProducts, product]);

  const fetchProducts = async () => {
    const id = props.match.params.id;

    if (id === undefined) {
      props.history.replace('/wholesale/products');
      return;
    }

    try {
      if (wholesaleProductsInitialized) {
        setLoading(false);
        return;
      }

      const wholesaleProductsSnapshot = await firebase.firestore().collection('wholesale').doc('productData').get();
      const wholesaleProductsData = wholesaleProductsSnapshot.data();

      dispatch(setWholesaleProducts(wholesaleProductsData.products));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching this product. Refresh and try again.');
    }
  };

  const handleChange = (e, type) => {
    setProduct({
      ...product,
      [type]: e.target.value,
    });
  };

  const save = async () => {
    setLoading(true);

    try {
      const productIndex = wholesaleProducts.findIndex(p => p.id === product.id);

      if (productIndex === -1) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error saving this product, please try again.');
        return;
      }

      const products = [ ...wholesaleProducts ];
      products[productIndex] = {
        ...product,
      };

      await firebase.firestore().collection('wholesale').doc('productData').update({
        products,
      });

      setLoading(false);
      dispatch(setWholesaleProducts(products));
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this product, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="WholesaleProduct">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/wholesale/products">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Wholesale Products
          </Button>
        </Link>
      </div>

      {!product.id ? null :
        <>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="h6">
              {product.name}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={save}
            >
              Save
            </Button>
          </Toolbar>

          <div className="content-container">
            <FormControlLabel
              control={
                <Switch
                  checked={product.active}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      active: e.target.checked,
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />

            <div className="pricing-row">
              <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 4, marginRight: 5, flex: 1}}>
                <InputLabel htmlFor="outlined-adornment-amount">MSRP</InputLabel>
                <OutlinedInput
                  value={product.msrp}
                  onChange={(e) => { handleChange(e, 'msrp') }}
                  margin="dense"
                  label="MSRP"
                  variant="outlined"
                  type="number"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </FormControl>

              <FormControl variant="outlined" style={{marginTop: 15, marginBottom: 4, marginLeft: 5, flex: 1}}>
                <InputLabel htmlFor="outlined-adornment-amount">Wholesale Price</InputLabel>
                <OutlinedInput
                  value={product.price}
                  onChange={(e) => { handleChange(e, 'price') }}
                  margin="dense"
                  label="Wholesale Price"
                  variant="outlined"
                  type="number"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </FormControl>
            </div>
          </div>
        </>
      }

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WholesaleProduct;
