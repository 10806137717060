import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Chart } from 'react-google-charts';

import './canceledSubscriptionHistory.scss';

function CanceledSubscriptionHistory({ reports }) {
  const [total, setTotal] = useState(0);
  const [canceledSubRevenue, setTotalCanceledSubRevenue] = useState(0);
  const [totalCanceledSubCostOfGoods, setTotalCanceledSubCostOfGoods] = useState(0);
  const [totalCanceledSubShipping, setTotalCanceledSubShipping] = useState(0);
  const [totalCanceledSubTax, setTotalCanceledSubTax] = useState(0);
  const [canceledSubRevenueArr, setCanceledSubRevenueArr] = useState([]);
  const [canceledSubOrderCountArr, setCanceledSubOrderCountArr] = useState([]);
  const [canceledSubProductSales, setCanceledSubProductSales] = useState([]);
  const [byCanceledSubProductValue, setByCanceledSubProductValue] = useState('Revenue');
  const [showCanceledSubOrderProductTable, setShowCanceledSubOrderProductTable] = useState(false);
  const [canceledSubCategorySales, setCanceledSubCategorySales] = useState([]);
  const [byCanceledSubCategoryValue, setByCanceledSubCategoryValue] = useState('Revenue');
  const [showCanceledSubOrderCategoryTable, setShowCanceledSubOrderCategoryTable] = useState(false);

  useEffect(() => {
    let total = 0;
    let canceledSubRevenue = 0;
    let canceledSubCostOfGoods = 0;
    let canceledSubShipping = 0;
    let canceledSubTax = 0;

    const canceledSubRevenueTotalArr = [];
    const canceledSubOrdersTotalArr = [];
    const canceledSubProductSalesMap = {};
    let canceledSubProductSalesArr = [];
    const canceledSubCategoriesSalesMap = {};
    let canceledSubCategoriesSalesArr = [];

    reports.forEach(r => {
      const date = moment(r.canceled).toDate();
      date.setHours(23, 59, 59, 999);

      for (let key in r.canceledSubProductSalesMap) {
        if (!canceledSubProductSalesMap.hasOwnProperty(key)) {
          canceledSubProductSalesMap[key] = r.canceledSubProductSalesMap[key];
        } else {
          canceledSubProductSalesMap[key].amount += r.canceledSubProductSalesMap[key].amount;
          canceledSubProductSalesMap[key].costOfGoods += r.canceledSubProductSalesMap[key].costOfGoods;
          canceledSubProductSalesMap[key].quantity += r.canceledSubProductSalesMap[key].quantity;
        }
      }

      for (let key in r.canceledSubCategoriesSalesMap) {
        if (!canceledSubCategoriesSalesMap.hasOwnProperty(key)) {
          canceledSubCategoriesSalesMap[key] = r.canceledSubCategoriesSalesMap[key];
        } else {
          canceledSubCategoriesSalesMap[key].amount += r.canceledSubCategoriesSalesMap[key].amount;
          canceledSubCategoriesSalesMap[key].costOfGoods += r.canceledSubCategoriesSalesMap[key].costOfGoods;
          canceledSubCategoriesSalesMap[key].quantity += r.canceledSubCategoriesSalesMap[key].quantity;
        }
      }

      canceledSubRevenueTotalArr.push([date, r.canceledSubRevenue]);
      canceledSubOrdersTotalArr.push([date, r.canceledSubscriptionCount]);

      total += +r.canceledSubscriptionCount;
      canceledSubRevenue += +r.canceledSubRevenue;
      canceledSubCostOfGoods += r.canceledSubTotalCostOfGoods;
      canceledSubShipping += r.canceledSubShipping;
      canceledSubTax += r.canceledSubTax;
    });

    for (let key in canceledSubProductSalesMap) {
      const p = canceledSubProductSalesMap[key];
      canceledSubProductSalesArr.push([p.name, p.amount, p.quantity, p.costOfGoods]);
    }

    canceledSubProductSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    for (let key in canceledSubCategoriesSalesMap) {
      const p = canceledSubCategoriesSalesMap[key];
      canceledSubCategoriesSalesArr.push([key, p.amount, p.quantity, p.costOfGoods]);
    }

    canceledSubCategoriesSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    setTotal(total);
    setCanceledSubRevenueArr(canceledSubRevenueTotalArr);
    setCanceledSubOrderCountArr(canceledSubOrdersTotalArr);
    setTotalCanceledSubRevenue(canceledSubRevenue);
    setTotalCanceledSubCostOfGoods(canceledSubCostOfGoods);
    setTotalCanceledSubShipping(canceledSubShipping);
    setTotalCanceledSubTax(canceledSubTax);
    setCanceledSubProductSales(canceledSubProductSalesArr);
    setCanceledSubCategorySales(canceledSubCategoriesSalesArr);
  }, [reports]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="CanceledSubscriptionHistory">
      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Total
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {total}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(canceledSubRevenue)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Shipping
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalCanceledSubShipping)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Tax
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalCanceledSubTax)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Cost Of Goods
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalCanceledSubCostOfGoods)}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{margin: '30px 0 40px'}}>
        <Divider/>
      </div>

      <div>
        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Revenue',
            ],
            ...canceledSubRevenueArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Revenue',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Revenue' },
            },
            axes: {
              y: {
                Revenue: { label: 'Revenue ($)' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div style={{margin: '40px 0'}}>
          <Divider/>
        </div>

        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Canceled Subscriptions',
            ],
            ...canceledSubOrderCountArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Number of Canceled Subscriptions',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Subscriptions' },
            },
            axes: {
              y: {
                Subscriptions: { label: 'No. Subscriptions' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Product Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byCanceledSubProductValue}
            exclusive
            onChange={(e, canceledValue) => {
              const canceledSubProductSalesCopy = [ ...canceledSubProductSales ];
              canceledSubProductSalesCopy.sort((a, b) => {
                let index = 1;

                if (canceledValue === 'Quantity') {
                  index = 2;
                }

                if (canceledValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setCanceledSubProductSales(canceledSubProductSalesCopy);
              setByCanceledSubProductValue(canceledValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Product', byCanceledSubProductValue],
              ...canceledSubProductSales.map(p => {
                let index = 1;

                if (byCanceledSubProductValue === 'Quantity') {
                  index = 2;
                }

                if (byCanceledSubProductValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byCanceledSubProductValue} by Product`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showCanceledSubOrderProductTable}
              onChange={(e) => {
                setShowCanceledSubOrderProductTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showCanceledSubOrderProductTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="products table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {canceledSubProductSales.map((p, i) => (
                  <TableRow key={`canceledSubProductSales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Category Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byCanceledSubCategoryValue}
            exclusive
            onChange={(e, canceledValue) => {
              const canceledSubCategorySalesCopy = [ ...canceledSubCategorySales ];
              canceledSubCategorySalesCopy.sort((a, b) => {
                let index = 1;

                if (canceledValue === 'Quantity') {
                  index = 2;
                }

                if (canceledValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setCanceledSubCategorySales(canceledSubCategorySalesCopy);
              setByCanceledSubCategoryValue(canceledValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Category', byCanceledSubCategoryValue],
              ...canceledSubCategorySales.map(p => {
                let index = 1;

                if (byCanceledSubCategoryValue === 'Quantity') {
                  index = 2;
                }

                if (byCanceledSubCategoryValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byCanceledSubCategoryValue} by Category`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showCanceledSubOrderCategoryTable}
              onChange={(e) => {
                setShowCanceledSubOrderCategoryTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showCanceledSubOrderCategoryTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="categories table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {canceledSubCategorySales.map((p, i) => (
                  <TableRow key={`canceledSubCategorySales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>
    </div>
  );
}

export default CanceledSubscriptionHistory;
