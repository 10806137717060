import { containerItems, textItems } from './shared';

export const link = {
  title: 'Link',
  ...containerItems,
  className: 'page-builder-always-visible',
  href: '',
  target: '_self', // _self or _blank
  styles: {
    ...containerItems.styles,
    display: 'flex',
    width: '100%',
  },
  editableStyles: [
    ...containerItems.editableStyles,
    {
      type: 'width',
      label: 'Width',
      placeholder: 'Enter width',
    },
  ],
};
