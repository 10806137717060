import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import DateAdapter from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TaskManager() {
  const [createNewTask, setCreateNewTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [newTask, setNewTask] = useState({
    name: '',
    dateTime: Date.now(),
    type: '',
    id: '',
    action: '',
    status: 'scheduled'
  });
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [tasks, setTasks] = useState([]);
  const [deleteTaskOpen, setDeleteTaskOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);

  const getTasks = async () => {
    const db = firebase.firestore();
    const tasks = [];

    try {
      const dataSnapshot = await db.collection('scheduled-tasks').doc('data').get();
      const tasksData = dataSnapshot.data();
      const taskPages = Math.ceil(tasksData.count / resultsPerPage);
      setPage(1);
      setNumberOfPages(taskPages || 1);
      
      const querySnapshot = await db.collection('scheduled-tasks').orderBy('created', "desc").limit(resultsPerPage).get();
      querySnapshot.forEach(task => {
        const taskWithId = {
          data: {...task.data()},
          docId: task.id
        }
        tasks.push(taskWithId);
      });
      setTasks(tasks);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.log(error);
      setModalTitle('Error');
      setModalText('Could not retrieve scheduled tasks, please try again.');
    }
    setLoading(false);
  }

  useEffect(() => {
    getTasks();
  }, [resultsPerPage]);

  const scheduleTask = async () => {
    setLoading(true);
    const timeStampedTask = {...newTask}
    timeStampedTask.created = Date.now();
    try {
      const db = firebase.firestore();
      const result = await db.collection('scheduled-tasks').add(timeStampedTask);
      await db.collection('scheduled-tasks').doc('data').update({
        count: firebase.firestore.FieldValue.increment(1)
      });

      const updatedTasks = tasks.slice(0);
      updatedTasks.unshift({ data: newTask, docId: result.id});
      setTasks(updatedTasks);
    } catch (error) {
      console.log('error', error);
      setModalTitle('Error');
      setModalText('Failed to schedule task, please try again.');
    }
    setLoading(false);
    handleCreateTaskModalClose();
  }

  const cancelTask = async (docId) => {
    setLoading(true);

    try {
      await firebase.firestore().collection('scheduled-tasks').doc(docId).update({
        status: 'cancelled'
      });

      const updatedTasks = [];
      tasks.forEach((task) => {
        const newTask = {};
        
        if (task.docId === docId) {
          newTask.data = {...task.data, status: 'cancelled'};
          newTask.docId = docId;
        } else {
          newTask.data = {...task.data};
          newTask.docId = task.docId;
        }

        updatedTasks.push(newTask);
      });
      setTasks(updatedTasks);
    } catch (error) {
      console.log('error', error);
      setModalTitle('Error');
      setModalText('Failed to cancel task, please try again.');
    }
    setLoading(false);
  }

  const deleteTask = async (docId) => {
    setLoading(true);
    const db = firebase.firestore();
    try {
      await db.collection('scheduled-tasks').doc(docId).delete();
      await db.collection('scheduled-tasks').doc('data').update({
        count: firebase.firestore.FieldValue.increment(-1)
      });
      await getTasks();
    } catch (error) {
      console.log(error);
      setModalTitle('Error');
      setModalText('Could not retrieve scheduled tasks, please try again.');
    }
    setLoading(false);
    handleDeleteTaskModalClose();
  }

  const updateTask = async (docId) => {
    setLoading(true);
    try {
      const updatedTask = { ...newTask, status: 'scheduled' };
      await firebase.firestore().collection('scheduled-tasks').doc(docId).update(updatedTask);
      
      const updatedTasks = [];
      tasks.forEach((task) => {
        const newTask = {};
        
        if (task.docId === docId) {
          newTask.data = {...updatedTask};
          newTask.docId = docId;
        } else {
          newTask.data = {...task.data};
          newTask.docId = task.docId;
        }

        updatedTasks.push(newTask);
      });
      setTasks(updatedTasks);
    } catch (error) {
      console.log(error);
      setModalTitle('Error');
      setModalText('Could not update task, please try again.');
    }
    setLoading(false);
    handleCreateTaskModalClose();
  }


  const handleCreateTaskModalClose = () => {
    setCreateNewTask(false);
    setNewTask({
      name: '',
      dateTime: Date.now(),
      type: '',
      id: '',
      action: '',
      status: 'scheduled'
    });
  }

  const handleDeleteTaskModalClose = () => {
    setDeleteTaskOpen(false);
    setNewTask({
      name: '',
      dateTime: Date.now(),
      type: '',
      id: '',
      action: '',
      status: 'scheduled'
    });
  }

  const handlePageChange = async (value) => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      let querySnapshot;
      let newPage = page;
      const nextPageTasks = [];

      if (value === 'next') {
        querySnapshot = await db.collection('scheduled-tasks').orderBy('created').startAfter(last).limit(resultsPerPage).get();
        newPage += 1;
      } else {
        querySnapshot = await db.collection('scheduled-tasks').orderBy('created').endBefore(first).limitToLast(resultsPerPage).get();
        newPage -= 1;
      }

      querySnapshot.forEach(task => {
        const taskWithId = {
          data: {...task.data()},
          docId: task.id
        }
         nextPageTasks.push(taskWithId);
      });
      setTasks(nextPageTasks);
      setPage(newPage);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.log('Could not get tasks', error);
      setModalTitle("Error");
      setModalText("Error retrieving data. Please try again");
    }

    setLoading(false);
  }

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <>
      {renderLoading()}
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Task Manager
          </Typography>
          <IconButton
              edge="start"
              color="inherit"
              onClick={(event) => {
                setIsUpdate(false);
                setCreateNewTask(true);
              }}
              aria-label="Add"
            >
              <AddIcon />
            </IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right"><strong>Status</strong></TableCell>
                <TableCell align="right"><strong>Scheduled Date</strong></TableCell>
                <TableCell align="right"><strong>Type/Resource</strong></TableCell>
                <TableCell align="right"><strong>Action</strong></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, i) => (
                <TableRow
                  key={task.docId + i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {task.data.name}
                  </TableCell>
                  <TableCell align="right">{task.data.status.charAt(0).toUpperCase() + task.data.status.slice(1)}</TableCell>
                  <TableCell align="right">{moment(task.data.dateTime).format("MM-DD-YYYY - HH:mm")}</TableCell>
                  <TableCell align="right">{task.data.type.charAt(0).toUpperCase() + task.data.type.slice(1)}</TableCell>
                  <TableCell align="right">{task.data.action ? "Activate" : "Deactivate"}</TableCell>
                  <TableCell align="right">
                    <Stack direction='row' spacing={1} justifyContent="flex-end">
                      <Button
                        onClick={() => {
                          setIsUpdate(true);
                          setNewTask(task.data);
                          setCurrentTaskId(task.docId);
                          setCreateNewTask(true);
                        }}
                        size='small'
                        variant='outlined'
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          setNewTask(task.data);
                          setCurrentTaskId(task.docId);
                          setDeleteTaskOpen(true)
                        }}
                        size='small'
                        variant='outlined'
                      >
                        Delete
                      </Button>
                      {task.data.status !== 'cancelled' && task.data.status !== 'completed' ?
                        <Button
                          
                          onClick={() => {
                            cancelTask(task.docId);
                          }}
                          size='small'
                          variant='outlined'
                        >
                          Cancel
                        </Button> :
                        ''
                      }
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{margin: '20px'}}>
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <Box sx={{ marginRight: '10px', minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="results-per-page-select">Rows per page</InputLabel>
                <Select
                  labelId="results-per-page-select"
                  id="results-per-page"
                  value={resultsPerPage}
                  label="Rows per page"
                  onChange={(e) => {
                    setResultsPerPage(e.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button disabled={page === 1} onClick={() => handlePageChange('back')} size='small' variant='contained'>Back</Button>
            <span>Page {page} of {numberOfPages}</span>
            <Button disabled={page === numberOfPages} onClick={() => handlePageChange('next')} size='small' variant='contained'>Next</Button>
          </Stack>
        </Box>
      </Card>

      <Dialog
        open={createNewTask}
        onClose={handleCreateTaskModalClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{isUpdate ? 'Update Task' : 'Create Task'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Task Name"
              value={newTask.name}
              onChange={(e) => {
                setNewTask({
                  ...newTask,
                  name: e.target.value
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
            />
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Scheduled Date & Time"
                value={newTask.dateTime}
                onChange={(value) => {
                  setNewTask({
                    ...newTask,
                    dateTime: value.valueOf()
                  })
                }}
              />
            </LocalizationProvider>
            <TextField
              label="Resource ID"
              value={newTask.id}
              onChange={(e) => {
                setNewTask({
                  ...newTask,
                  id: e.target.value
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
            />
            <FormControl style={{width: '100%'}}>
              <InputLabel id="resource-type-select">Resource Type</InputLabel>
              <Select
                label="Resource Type"
                labelId="resource-type-select"
                id="resource-type"
                value={newTask.type}
                onChange={(e) => {
                  setNewTask({
                    ...newTask,
                    type: e.target.value
                  })
                }}
              >
                <MenuItem value={"blog post"}>Blog Post</MenuItem>
                <MenuItem value={"page"}>Page</MenuItem>
                <MenuItem value={"product"}>Product</MenuItem>
                <MenuItem value={"coupon code"}>Coupon Code</MenuItem>
                <MenuItem value={"free product"}>Free Product</MenuItem>
                <MenuItem value={"order bump"}>Order Bump</MenuItem>
                <MenuItem value={"one click upsell"}>One Click Upsell</MenuItem>
                <MenuItem value={"featured"}>Featured Content</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{width: '100%'}}>
              <InputLabel id="action-select">Action</InputLabel>
              <Select
                label="Action"
                labelId="action-select"
                id="action"
                value={newTask.action}
                onChange={(e) => {
                  setNewTask({
                    ...newTask,
                    action: e.target.value
                  })
                }}
              >
                <MenuItem value={true}>Activate</MenuItem>
                <MenuItem value={false}>Deactivate</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateTaskModalClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              !isUpdate ? scheduleTask() : updateTask(currentTaskId);
            }}
            color="primary"
            disabled={!newTask.name || !newTask.dateTime || typeof newTask.action !== "boolean" || !newTask.id || !newTask.type}
          >
            Schedule
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteTaskOpen} onClose={handleDeleteTaskModalClose} TransitionComponent={Transition}>
        <DialogTitle>Deleting Task</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to permanently delete this task?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleDeleteTaskModalClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={() => deleteTask(currentTaskId)}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TaskManager;
