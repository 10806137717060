import React, { useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';

import './formResponses.scss';
import { config } from '../../../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FormResponses({
  startDate,
  endDate,
  formId,
  numberOfStarts,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [sheetUrl, setSheetUrl] = useState('');

  const exportResponses = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const start = moment(startDate).startOf('day').valueOf();
      const end = moment(endDate).endOf('day').valueOf();

      const response = await axios(`${config.gateway}/reports-service/v1/form/${formId}/spreadsheet?start=${start}&end=${end}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (response.data.sheet) {
        setSheetUrl(response.data.sheet);
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving the responses, please try again.');
      }
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error exporting the responses, please try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="FormResponses">
      {renderLoading()}
      {!numberOfStarts ? null :
        <Button
          onClick={exportResponses}
          variant="contained"
          color="secondary"
          size="small"
        >
          Export Responses
        </Button>
      }

      <Dialog open={!!sheetUrl} onClose={() => { setSheetUrl('') }} TransitionComponent={Transition}>
        <DialogTitle>Responses for {moment(startDate).format('l')}-{moment(endDate).format('l')}</DialogTitle>
        <DialogContent>
          <DialogContentText>You can view the form responses here: <a href={sheetUrl} target="_blank" style={{color: 'blue'}}>{sheetUrl}</a></DialogContentText>
          <DialogContentText><strong>Please make a copy and delete the tab.</strong> The Google Sheets limit on tabs is 200.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setSheetUrl('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormResponses;
