import React from 'react'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@material-ui/core'
import { Column } from './column'
import { TYPE_COLUMNS } from './tabsContent'

const imageType = {
    image: ''
}

const titleContentType = {
    title: '',
    content: ''
}

const titleContentOrderListType = {
    title: '',
    content: '',
    listTitle: '',
    list: []
}

const videoType = {
    video: ''
}

const setColumnType = (type) => {
    switch (type) {
        case TYPE_COLUMNS[0]:
            return imageType
        case TYPE_COLUMNS[1]:
            return titleContentType
        case TYPE_COLUMNS[2]:
            return titleContentOrderListType
        case TYPE_COLUMNS[3]:
            return videoType
        default:
            return {}
    }
}

export const TreatActions = (props) => {
    const { product, setProduct } = props
    return (
        <div className="section-container">
            <div className="section-label">Treat Actions</div>
            <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                    <Switch
                        checked={product.enableTreatActions || false}
                        onChange={(e) => {
                            setProduct({
                                ...product,
                                enableTreatActions: e.target.checked,
                            });

                        }}
                        name="hasCustomThankYouPage"
                        color="primary"
                    />
                }
                label="Show Treat Actions"
            />

            <div className="section-container">
                <div className="section-label">Column 1</div>
                <FormControl
                    style={{ marginTop: 8, marginBottom: 4 }}
                    variant="outlined"
                    className="day-text-field"
                >
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={product.treatActions.firstColumn.type.toLowerCase() || 'image'}
                        onChange={(e) => {

                            const text = e.target.value

                            const newType = setColumnType(text)

                            setProduct({
                                ...product,
                                treatActions: {
                                    ...product.treatActions,
                                    firstColumn: { ...newType, type: text }
                                },
                            })

                        }}
                        margin="dense"
                        label="Align Text"
                    >
                        {TYPE_COLUMNS.map(category => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                    <Column
                        type={product.treatActions.firstColumn.type}
                        column={product.treatActions.firstColumn}
                        onChange={(value) => {
                            setProduct({
                                ...product,
                                treatActions: {
                                    ...product.treatActions,
                                    firstColumn: { ...value, type: product.treatActions.firstColumn.type }
                                },
                            })
                        }} />
                </FormControl>
            </div>
            <div className="section-container">
                <div className="section-label">Column 2</div>
                <FormControl
                    style={{ marginTop: 8, marginBottom: 4 }}
                    variant="outlined"
                    className="day-text-field"
                >
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={product.treatActions.secondColumn.type.toLowerCase() || 'image'}
                        onChange={(e) => {
                            const text = e.target.value
                            const newTabType = setColumnType(text)

                            setProduct({
                                ...product,
                                treatActions: {
                                    ...product.treatActions,
                                    secondColumn: { ...newTabType, type: text }
                                }
                            })
                        }}
                        margin="dense"
                        label="Align Text"
                    >
                        {TYPE_COLUMNS.map(category => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                    <Column
                        type={product.treatActions.secondColumn.type}
                        column={product.treatActions.secondColumn}
                        onChange={(value) => setProduct({
                            ...product,
                            treatActions: {
                                ...product.treatActions,
                                secondColumn: { ...value, type: product.treatActions.secondColumn.type }
                            }
                        })} />
                </FormControl>
            </div>
        </div>
    )
}

