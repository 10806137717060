import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  CardContent,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './featuredContentEdit.scss';

const contentTypes = [
  'free-course',
  'paid-course',
  'blog-post',
  'external-url',
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FeaturedContentEdit(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [contentId, setContentId] = useState('');
  const [content, setContent] = useState({
    title: '',
    live: false,
    backgroundImage: '',
    videoUrl: '',
    ctaButtonText: 'View More',
    type: 'free-course',
    resourceId: '',
    externalUrl: '',
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });

  useEffect(() => {
    if (props.match.params.id) {
      setContentId(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (contentId) {
      fetchContent();
    }
  }, [contentId]);

  const fetchContent = async () => {
    try {
      const snapshot = await firebase.firestore().collection('featured-content').doc(contentId).get();
      const data = snapshot.data();

      if (!data) {
        setLoading(false);
        props.history.replace('/academy-content/featured-content');
        return;
      }

      setContent(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving featured content, please try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      const updatedContent = {
        ...content,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('featured-content').doc(contentId).update(updatedContent);

      setContent(updatedContent);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The featured content has been saved.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving featured content, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="FeaturedContentEdit">
      {renderLoading()}
      <Link to="/academy-content/featured-content">
        <Button 
          variant="contained"
          color="primary"
          size="small"
          style={{marginBottom: '15px'}}
          startIcon={<ArrowBackIcon />}
        >
          All Featured Content
        </Button>
      </Link>

      <div>
        <strong>Last Modified:</strong> <span style={{marginRight: 10}}>{moment(content.updated).calendar()}</span>
        <strong>Last Modified By:</strong> {content.lastUpdatedBy}
      </div>
      <Card>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Edit Featured Content
          </Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={save}
              style={{marginRight: '10px'}}
            >
              Save
            </Button>
          </div>
        </Toolbar>
        <Divider/>
        <CardContent>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={content.live}
                  onChange={(e) => setContent({ ...content, live: e.target.checked })}
                  name="live"
                  color="primary"
                />
              }
              label="Live"
            />
          </div>

          <div className="input-row">
            <TextField
              label="Title"
              value={content.title}
              onChange={(e) => setContent({ ...content, title: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="input-field"
            />

            <TextField
              label="CTA Button Text"
              value={content.ctaButtonText}
              onChange={(e) => setContent({ ...content, ctaButtonText: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="input-field"
            />
          </div>

          <div className="input-row">
            <TextField
              label="Background Image URL"
              value={content.backgroundImage}
              onChange={(e) => setContent({ ...content, backgroundImage: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="input-field"
            />

            <TextField
              label="Video URL"
              value={content.videoUrl}
              onChange={(e) => setContent({ ...content, videoUrl: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="input-field"
            />
          </div>

          <FormControl
            style={{marginTop: 20}}
            variant="outlined"
            margin="dense"
            className="day-text-field"
          >
            <InputLabel>
              Type
            </InputLabel>
            <Select
              label="Type"
              style={{width: '100%'}}
              value={content.type}
              onChange={(e) => setContent({ ...content, type: e.target.value })}
            >
              {contentTypes.map((type) => {
                return (
                  <MenuItem key={`content-type-${type}`} value={type}>
                    <span style={{textTransform: 'capitalize'}}>{type.split('-').join(' ')}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {content.type === 'external-url' ?
            <TextField
              style={{marginTop: 20}}
              label="External URL"
              value={content.externalUrl}
              onChange={(e) => setContent({ ...content, externalUrl: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            /> :
            <TextField
              style={{marginTop: 20}}
              label="Resource ID"
              value={content.resourceId}
              onChange={(e) => setContent({ ...content, resourceId: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          }
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FeaturedContentEdit;
