import React, { useState, useEffect } from "react";
import firebase from 'firebase/app';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  Button,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteTemplate() {
  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [pagesModal, setPagesModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [pages, setPages] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [templatesThatCannotBeDeleted, setTemplatesThatCannotBeDeleted] = useState([]);
  const [showDeleteTemplatesConfirmation, setShowDeleteTemplatesConfirmation] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('global-templates').get();
      const content = querySnapshot.docs.map(doc => {
        const docData = doc.data();

        return {
          ...docData,
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created - a.created;
      });
      setTemplate(content);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching templates. Please try again.');
    }
  };

  const deleteTemplate = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('global-templates').doc(template[deleteIndex].id).delete();

      const updatedTemplate = [...template];
      updatedTemplate.splice(deleteIndex, 1);

      setTemplate(updatedTemplate);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this template. Please try again.');
    }
  };

  const checkPages = async (i) => {
    setLoading(true);

    try {
      const pagesSnapshot = await firebase.firestore().collection('pages').where('sharedTemplates', 'array-contains', template[i].id).get();

      const content = pagesSnapshot.docs.map(doc => {
        const docData = doc.data();

        return {
          ...docData,
          id: doc.id,
        };
      })

      if (content.length > 0) {
        setPages(content)
        setPagesModal(true);
        setDeleteIndex(-1);
        setLoading(false);
      } else {
        setDeleteIndex(i);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this template. Please try again.');
    }
  };

  const deleteTemplates = async () => {
    setLoading(true);

    try {
      const queries = [];
      const deletedIds = [];

      selectedTemplates.forEach(id => {
        if (!templatesThatCannotBeDeleted.includes(id)) {
          queries.push(firebase.firestore().collection('global-templates').doc(id).delete());
          deletedIds.push(id);
        }
      });

      await Promise.all(queries);

      const updatedTemplates = [ ...template ];

      deletedIds.forEach(id => {
        const foundIndex = template.findIndex(t => t.id === id);

        if (foundIndex !== -1) {
          updatedTemplates.splice(foundIndex, 1);
        }
      });

      setTemplate(updatedTemplates);
      setLoading(false);
      setSelectedTemplates([]);
      setTemplatesThatCannotBeDeleted([]);
      setShowDeleteTemplatesConfirmation(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setTemplatesThatCannotBeDeleted([]);
      setShowDeleteTemplatesConfirmation(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting the selected templates. Please try again.');
    }
  };

  const checkPagesForSelectedTemplates = async () => {
    setLoading(true);

    try {
      const templatesBeingUsed = [];

      for (let i = 0; i < selectedTemplates.length; i++) {
        const pagesSnapshot = await firebase.firestore().collection('pages').where('sharedTemplates', 'array-contains', selectedTemplates[i]).get();
        
        if (pagesSnapshot.docs.length) {
          templatesBeingUsed.push(selectedTemplates[i]);
        }
      }

      setLoading(false);

      if (templatesBeingUsed.length === selectedTemplates.length) {
        setModalTitle('Notice!');
        setModalText('All of the selected templates are currently being used in pages and cannot be deleted.');
        return;
      }

      setTemplatesThatCannotBeDeleted(templatesBeingUsed);
      setShowDeleteTemplatesConfirmation(true);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting these templates. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div>
      {renderLoading()}
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Delete Templates
          </Typography>

          <Button
            variant="contained"
            onClick={() => checkPagesForSelectedTemplates()}
            color="secondary"
            disabled={!selectedTemplates.length}
          >
            Delete Selected
          </Button>
        </Toolbar>
        <List>
          {template.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTemplates.includes[p.id]}
                        onChange={(e) => {
                          const selectedTemplatesCopy = [ ...selectedTemplates ];
                          const checked = e.target.checked;

                          if (checked) {
                            selectedTemplatesCopy.push(p.id);
                          } else {
                            const foundIndex = selectedTemplatesCopy.findIndex(id => id === p.id);

                            if (foundIndex !== -1) {
                              selectedTemplatesCopy.splice(foundIndex, 1);
                            }
                          }

                          setSelectedTemplates(selectedTemplatesCopy);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="primary"
                      />
                    }
                  />
                  <ListItemText
                    primary={p.title}
                    secondary={
                      <span>
                        <span style={{ marginRight: 10 }}><strong>ID:</strong> {p.id}</span>
                        <span style={{ marginRight: 10 }}><strong>Last updated:</strong> {moment(p.updated).calendar()}</span>
                        <span style={{ marginRight: 10 }}><strong>Last updated by:</strong> {p.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                  <IconButton edge="start" color="inherit" onClick={() => checkPages(i)} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Template?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete <strong>{deleteIndex === -1 ? '' : template[deleteIndex].title}</strong>?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => deleteTemplate()} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteTemplatesConfirmation}
        onClose={() => {
          setTemplatesThatCannotBeDeleted([]);
          setShowDeleteTemplatesConfirmation(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Templates?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete the selected templates?</DialogContentText>

          {!templatesThatCannotBeDeleted.length ? null :
            <>
              <DialogContentText><strong>Notice: The following templates are being used in pages and will not be deleted.</strong></DialogContentText>
              <ul>
              {templatesThatCannotBeDeleted.map(id => {
                const foundIndex = template.findIndex(t => t.id === id);
                let templateName = id;

                if (foundIndex !== -1) {
                  templateName = template[foundIndex].title;
                }

                return (
                  <li key={id}>
                    <DialogContentText>{templateName}</DialogContentText>
                  </li>
                );
              })}
              </ul>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => deleteTemplates()} color="secondary">
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setTemplatesThatCannotBeDeleted([]);
              setShowDeleteTemplatesConfirmation(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pagesModal === true} onClose={() => setPagesModal(false)} TransitionComponent={Transition}>
        <DialogTitle style={{ color: 'red' }}>Deleting Error</DialogTitle>
        <DialogContent>
          <DialogContentText>This template can't be deleted because it is being used in the following pages:</DialogContentText>
          <List>
            {pages.map((p) => {
              return (
                <div key={p.id}>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={p.title} />
                  </ListItem>
                </div>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setPagesModal(false); setDeleteIndex(-1) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
