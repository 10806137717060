import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  List,
  Divider,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './forms.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function Forms(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState({});
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [addNewFormOpen, setAddNewFormOpen] = useState(false);
  const [newForm, setNewForm] = useState({
    title: '',
    description: '',
    path: '',
    content: '',
    conditions: [],
    live: false,
    requireAuth: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    const db = firebase.firestore();

    try {
      const dataSnapshot = await db.collection('forms').doc('data').get();
      const querySnapshot = await db.collection('forms').orderBy('created', 'desc').limit(pageCount).get();

      const formData = dataSnapshot.data();
      const formPages = Math.ceil(formData.count / 10);
      const mappedForms = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setNumberOfPages(formPages);
      setFormData(formData);
      setForms(mappedForms);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setPage(1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching forms. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let querySnapshot;
      let newPage = page;

      if (value === 'next') {
        querySnapshot = await db.collection('forms').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        querySnapshot = await db.collection('forms').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedForms = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setForms(mappedForms);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching forms. Refresh the page and try again.');
    }
  };

  const saveNewForm = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingFormQuerySnapshot = await db.collection('forms').where('path', '==', newForm.path).get();

      if (existingFormQuerySnapshot.docs.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing form with the same URL path.');
        return;
      }

      const createdForm = await db.collection('forms').add({
        ...newForm,
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
      setAddNewFormOpen(false);
      props.history.push(`/marketing-tools/forms/${createdForm.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred creating the new form. Please try again.');
    }
  };

  const deleteForm = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      await db.collection('forms').doc(forms[deleteIndex].id).delete();

      setDeleteIndex(-1);
      fetchForms();
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting this form. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Forms">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Forms
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setNewForm({
                title: '',
                description: '',
                path: '',
                content: '',
                conditions: [],
                live: false,
                requireAuth: false,
                created: Date.now(),
                updated: Date.now(),
                lastUpdatedBy: '',
              });
              setAddNewFormOpen(true);
            }}
            aria-label="Add"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {forms.map((form, i) => {
            return (
              <div key={form.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={form.title}
                    secondary={
                      <span>
                        <span style={{marginTop: 5, marginBottom: 5, display: 'block', marginRight: 10}}>
                          <span><span><span style={{fontWeight: 'bold'}}>Status:</span> <span style={{color: form.live ? 'rgb(126, 210, 66)' : 'red', marginRight: 10}}>{form.live ? 'Live' : 'Inactive'}</span></span> <span style={{fontWeight: 'bold'}}>Last Modified:</span> <span style={{marginRight: 10}}>{moment(form.updated).calendar()}</span> <span style={{fontWeight: 'bold'}}>Last Modified By:</span> {form.lastUpdatedBy}</span>
                        </span>
                        
                        <span style={{marginRight: 10}}>
                          <span style={{fontWeight: 'bold'}}>ID:</span> {form.id}
                        </span>
                      </span>
                    }
                  />

                  <Link to={`/marketing-tools/forms/${form.id}/form-reports`} style={{color: '#000', textDecoration: 'none'}}>
                    <IconButton color="inherit" aria-label="Reports">
                      <AssessmentIcon />
                    </IconButton>
                  </Link>

                  <Link edge="start" to={`/marketing-tools/forms/${form.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>

                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      setDeleteIndex(i);
                    }}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>

        <Divider/>

        {!numberOfPages ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog fullWidth maxWidth="sm" open={addNewFormOpen} onClose={() => { setAddNewFormOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Create Form</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newForm.title}
            onChange={(e) => {
              setNewForm({
                ...newForm,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={newForm.description}
            onChange={(e) => {
              setNewForm({
                ...newForm,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newForm.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewForm({
                ...newForm,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewForm} color="primary" disabled={!newForm.title || !newForm.description || !newForm.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddNewFormOpen(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Form?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete <strong>{deleteIndex === -1 ? '' : forms[deleteIndex].title}</strong>? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteForm} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Forms;
