import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  Toolbar,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import PageBuilder from '../../../shared/pageBuilder';

import './editPage.scss';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditPage(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editPageContentOpen, setEditPageContentOpen] = useState(false);
  const [page, setPage] = useState({
    title: '',
    description: '',
    path: '',
    editorData: {
      type: 'top-layer',
      children: [],
    },
    customCSS: '',
    settings: {
      hideFooter: false,
      hideHeader: false,
    },
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [globalTemplates, setGlobalTemplates] = useState([]);

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/website-content/pages');
        return;
      }

      const querySnapshot = await db.collection('pages').doc(id).get();
      const templatesQuerySnapshot = await db.collection('global-templates').get();

      const templates = templatesQuerySnapshot.docs.map(doc => {
        const data = doc.data();

        if (data.children && typeof data.children === 'string') {
          data.children = JSON.parse(data.children);
        }

        return data;
      });

      const pageData = {
        ...querySnapshot.data(),
        id: querySnapshot.id,
      };
      const onlyGlobalTemplates = templates.filter(t => {
        return t.global;
      });

      if (typeof pageData.editorData === 'string') {
        pageData.editorData = JSON.parse(pageData.editorData);
      }

      const updatedEditorData = updateGlobalTemplates(pageData.editorData, onlyGlobalTemplates);

      pageData.editorData = updatedEditorData;

      setGlobalTemplates(templates);
      setPage(pageData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.alert('An error occurred fetching page content. Refresh the page and try again.');
    }
  };

  const updateGlobalTemplates = (data, templates) => {
    if (data.global) {
      const foundIndex = templates.findIndex(t => {
        return t.id === data.id;
      });

      if (foundIndex !== -1) {
        const templateCopy = cloneDeep(templates[foundIndex]);
        for (let key in templateCopy) {
          data[key] = templateCopy[key];
        }
      }
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        updateGlobalTemplates(children[i], templates);
      }
    }

    return data;
  };

  const findAndSaveGlobalTemplates = (data, templatesToSave) => {
    if (data.global) {
      const dataToSave = {
        ...data,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      if (!dataToSave.created) {
        dataToSave.created = dataToSave.updated;
      }

      templatesToSave[dataToSave.id] = dataToSave;
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        findAndSaveGlobalTemplates(children[i], templatesToSave);
      }
    }

    return templatesToSave;
  };

  const savePage = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const id = page.id;

      const existingPageQuerySnapshot = await db.collection('pages').where('path', '==', page.path).get();
      const existingBlogQuerySnapshot = await db.collection('blog-posts').where('path', '==', page.path).get();
      const existingAcademyPagesQuerySnapshot = await db.collection('academy-plus-landing-pages').where('path', '==', page.path).get();
      const pageMatches = existingPageQuerySnapshot.docs.filter(p => {
        return p.id !== id;
      });

      if (pageMatches.length || existingBlogQuerySnapshot.docs.length || existingAcademyPagesQuerySnapshot.docs.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      const templatesToSave = findAndSaveGlobalTemplates(page.editorData, {});
      const sharedTemplates = [];

      for (let key in templatesToSave) {
        sharedTemplates.push(key);
        try {
          let globalTemplatesCopy = [ ...globalTemplates ];
          const foundIndex = globalTemplates.findIndex(t => {
            return `${t.id}` === `${key}`;
          });

          if (foundIndex !== -1) {
            globalTemplatesCopy[foundIndex] = templatesToSave[key];
          } else {
            globalTemplatesCopy.push(templatesToSave[key]);
            globalTemplatesCopy = [ templatesToSave[key], ...globalTemplatesCopy ];
          }

          setGlobalTemplates(globalTemplatesCopy);

          await firebase.firestore().collection('global-templates').doc(templatesToSave[key].id).set({
            ...templatesToSave[key],
            children: JSON.stringify(templatesToSave[key].children),
          });
        } catch (e) {
          console.log('error saving template', e);
        }
      }

      const pageDataToSave = {
        ...page,
        updated: Date.now(),
        sharedTemplates,
        editorData: JSON.stringify(page.editorData),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await db.collection('pages').doc(id).update(pageDataToSave);
      const savedData = (await db.collection('pages').doc(id).get()).data();

      if (typeof savedData.editorData === 'string') {
        savedData.editorData = JSON.parse(savedData.editorData);
      }

      const onlyGlobalTemplates = [];

      for (let key in templatesToSave) {
        onlyGlobalTemplates.push(templatesToSave[key]);
      }

      const updatedEditorData = updateGlobalTemplates(savedData.editorData, onlyGlobalTemplates);

      setPage({
        ...savedData,
        id: id,
        editorData: updatedEditorData,
      });
      setLoading(false);
      setModalTitle('Success!');
      setModalText('This page has been successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this page. Please try again.');
    }
  };

  const saveModule = async (moduleToSave) => {
    const dataToSave = {
      ...moduleToSave,
      created: Date.now(),
      updated: Date.now(),
      lastUpdatedBy: firebase.auth().currentUser.email,
    };

    // Don't save globals it here. Only save globals on page save
    if (dataToSave.global) {
      setGlobalTemplates([ dataToSave, ...globalTemplates ]);
      setModalTitle('Success!');
      setModalText(`The ${moduleToSave.type} was added an will be saved when saving the page.`);
    } else {
      console.log('module', moduleToSave);
      const db = firebase.firestore();
      setLoading(true);

      try {
        await db.collection('global-templates').doc(dataToSave.id).set({
          ...dataToSave,
          children: JSON.stringify(dataToSave.children),
        });

        setGlobalTemplates([ dataToSave, ...globalTemplates ]);

        setLoading(false);
        setModalTitle('Success!');
        setModalText(`The ${moduleToSave.type} was saved successfully.`);
      } catch (e) {
        console.log('error', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`There was an error saving this ${moduleToSave.type}. Please try again.`);
      }
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditPage">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/website-content/pages">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Pages
          </Button>
        </Link>

        <Button 
          variant="contained"
          color="primary"
          size="small"
          style={{marginBottom: '15px'}}
          onClick={() => {
            setEditPageContentOpen(true);
          }}
        >
          Open Page Builder
        </Button>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>{page.title || 'Unnamed Page'}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={savePage}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={page.live}
                  onChange={(e) => {
                    setPage({
                      ...page,
                      live: e.target.checked,
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Live"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={page.settings.hideHeader}
                  onChange={(e) => {
                    setPage({
                      ...page,
                      settings: {
                        ...page.settings,
                        hideHeader: e.target.checked,
                      },
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Hide Header"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={page.settings.hideFooter}
                  onChange={(e) => {
                    setPage({
                      ...page,
                      settings: {
                        ...page.settings,
                        hideFooter: e.target.checked,
                      },
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Hide Footer"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={page.noIndex || false}
                  onChange={(e) => {
                    setPage({
                      ...page,
                      noIndex: e.target.checked,
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="No-Index"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Title"
              value={page.title}
              onChange={(e) => {
                setPage({
                  ...page,
                  title: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Page Description"
              value={page.description}
              onChange={(e) => {
                setPage({
                  ...page,
                  description: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Page Path"
              helperText="Only letters, numbers and dashes allowed"
              value={page.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setPage({
                  ...page,
                  path: value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>
      </div>

      <Dialog fullScreen open={editPageContentOpen} onClose={() => { setEditPageContentOpen(false) }} TransitionComponent={Transition}>
        <PageBuilder
          content={page.editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            setPage({
              ...page,
              editorData: content,
            });
          }}
          close={() => { setEditPageContentOpen(false) }}
          css={page.customCSS}
          updateCSS={(customCSS) => {
            setPage({
              ...page,
              customCSS,
            });
          }}
        />
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditPage;
