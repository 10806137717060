import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UnpublishedContentWhitelist() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addEmail, setAddEmail] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const doc = await db.collection('config').doc('member-content-admin-whitelist').get();
        const list = doc.data().list;

        setList(list);
        setLoading(false);
      } catch (e) {
        console.log(e.toString());
      }
    }
    fetchProducts();
  }, []);

  const addUser = async () => {
    setLoading(true);

    try {
      const userList = list.slice();
      userList.push(addEmail);
      const db = firebase.firestore();
      await db.collection('config').doc('member-content-admin-whitelist').update({
        list: userList,
      });
      setList(userList);
    } catch (e) {
      window.alert('Notice: An error occurred adding to whitelist.');
    }

    setAddEmail('');
    setLoading(false);
    setShowAddModal(false);
  };

  const deleteEntry = async () => {
    setLoading(true);

    try {
      const userList = list.slice();
      userList.splice(deleteIndex, 1);
      const db = firebase.firestore();
      await db.collection('config').doc('member-content-admin-whitelist').update({
        list: userList,
      });
      setList(userList);
    } catch (e) {
      window.alert('Notice: An error occurred removing from whitelist.');
    }

    setLoading(false);
    setDeleteIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Admin Whitelist
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            onClick={() => { setShowAddModal(true) }}
          >
            Add User
          </Button>
        </Toolbar>
        <List>
          {list.map((item, i) => {
            return (
              <div key={i}>
                <Divider />
                <ListItem>
                  <ListItemText primary={item} />

                  <IconButton onClick={() => { setDeleteIndex(i) }} key={`delete-${i}`} color="inherit" aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={showAddModal} onClose={() => { setShowAddModal(false) }} TransitionComponent={Transition}>
        <Card style={{width: '350px'}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Add User
            </Typography>
            <TextField
              style={{minWidth: '225px'}}
              label="Email"
              value={addEmail}
              onChange={(e) => { setAddEmail(e.target.value) }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
          </CardContent>
          <CardActions>
            <Button
              style={{marginRight: '10px'}}
              variant="contained"
              color="primary"
              disabled={!addEmail}
              onClick={addUser}
            >Add</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { setShowAddModal(false) }}
            >cancel</Button>
          </CardActions>
        </Card>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { deleteIndex(-1) }} TransitionComponent={Transition}>
        <Card style={{width: '350px'}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Remove Email?
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Are you sure you want to remove {list[deleteIndex]}?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              style={{marginRight: '10px'}}
              variant="contained"
              color="primary"
              onClick={deleteEntry}
            >Confirm</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { setDeleteIndex(-1) }}
            >cancel</Button>
          </CardActions>
        </Card>
      </Dialog>
    </div>
  );
}

export default UnpublishedContentWhitelist;
