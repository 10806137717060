export const emptyProduct = {
  id: '',
  isCustomizableBundle: false,
  name: '',
  title: '',
  metaDescription: '',
  path: '',
  active: false,
  isDigital: false,
  isGiftCard: false,
  categories: [],
  filters: [],
  description: '',
  sku: '',
  shippingClass: '',
  price: 0,
  isOnSale: false,
  salePrice: 0,
  costOfGood: 0,
  stockQuantity: 0,
  lowStockThreshold: 0,
  taxCode: '',
  enableSubscriptions: false,
  subscriptionOptions: [],
  subscriptionOnly: false,
  group: '',
  tags: [],
  media: [],
  relatedProducts: [],
  hasCustomThankYouPage: false,
  customThankYouPagePath: '',
  customThankYouPagePriority: 1,
  groupedThumbnail: '',
  descriptionSections: [],
  enableDescriptionSections: false,
  editorData: {
    type: 'top-layer',
    children: [],
  },
  tabsContent: [
    {
      firstColumn: {
        type: 'image',
        image: '',
      },
      secondColumn: {
        type: 'image',
        image: '',
      }
    },
    {
      firstColumn: {
        type: 'image',
        image: '',
      },
      secondColumn: {
        type: 'image',
        image: '',
      }
    },
    {
      firstColumn: {
        type: 'image',
        image: '',
      },
      secondColumn: {
        type: 'image',
        image: '',
      }
    }
  ],
  treatActions: {
    firstColumn: {
      type: 'image',
      image: '',
    },
    secondColumn: {
      type: 'image',
      image: '',
    }
  },
  treatBanner: {
    title: '',
    titleColor: '#FFF',
    colorBanner: '#FFF',
    icons: [],
  },
  backgroundColorMediaSection: 'transparent',
  enableTabsContent: false,
  sharedTemplates: [],
  customCSS: '',
  memberships: [],
  weight: 0,
  showOnShopPage: true,
  showInSearch: true,
  createProductPage: true,
  noIndexProductPage: false,
  enableAdditionalInfoFeatures: false,
  enableTreatBanner: false,
  enableTreatActions: false,
  enableMainProductInfo: false,
};
