import React, { useState } from 'react';
import {
  ColorPicker as ColorPickerComponent,
  useColor,
} from '../shared/colorPalette';
import { Popover } from '@material-ui/core';
import './colorPicker.scss';

export const ColorPicker = ({ field, error, helperText, label }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [defaultColor, setDefaultColor] = useColor(
    'hex',
    field.value ?? '#ffffff',
  );

  return (
    <>
      <div
        className="color-container"
        style={{
          border: `1px solid ${error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'}`,
        }}
        onClick={(e) => {
          setAnchorElement(e.currentTarget);
        }}
      >
        <p>{label}</p>

        <div
          className="color-preview"
          style={{
            backgroundColor: field.value,
          }}
        />
      </div>
      {helperText ? (
        <p
          style={{
            margin: 0,
            fontSize: '0.75rem',
            marginTop: '3px',
            textAlign: 'left',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            color: '#f44336',
          }}
        >
          {helperText}
        </p>
      ) : null}

      <Popover
        id="title-color-popover"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {defaultColor ? (
          <ColorPickerComponent
            width={250}
            height={125}
            color={defaultColor}
            onChange={setDefaultColor}
            onChangeComplete={(color) => field.onChange(color.hex)}
            hideHSV
            hideRGB
            alpha
          />
        ) : null}
      </Popover>
    </>
  );
};
