import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';

import './settings.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Settings({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [courses, setCourses] = useState([]);
  const [settings, setSettings] = useState({
    includedCourses: [], // First course in list is "featured"
  });
  const [addCourseModalOpen, setAddCourseModalOpen] = useState(false);
  const [selectedCourseToAdd, setSelectedCourseToAdd] = useState('');
  const [courseDeleteIndex, setCourseDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const db = firebase.firestore();

    try {
      const settingsSnapshot = await db.collection('config').doc('retailer-microsite').get();
      const freeCoursesSnapshot = await db.collection('free-courses').get();
      const paidCoursesSnapshot = await db.collection('membership-content').get();

      const combinedCourses = [];

      freeCoursesSnapshot.docs.forEach(doc => {
        const data = doc.data();

        combinedCourses.push({
          name: data.name,
          description: data.description,
          backgroundImage: data.backgroundImage,
          id: doc.id,
        });
      });

      paidCoursesSnapshot.docs.forEach(doc => {
        const data = doc.data();

        combinedCourses.push({
          name: data.name,
          description: data.description,
          backgroundImage: data.backgroundImage,
          id: doc.id,
        });
      });

      combinedCourses.sort((a, b) => a.name.localeCompare(b.name));

      setSettings(settingsSnapshot.data());
      setCourses(combinedCourses);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching microsite settings. Refresh the page and try again.');
    }
  };

  const saveSettings = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      await db.collection('config').doc('retailer-microsite').update(settings);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving microsite settings. Please try again.');
    }
  };

  const removeIncludedCourse = () => {
    const includedCourses = [ ...settings.includedCourses ];

    includedCourses.splice(courseDeleteIndex, 1);

    setSettings({
      ...settings,
      includedCourses,
    });
    setCourseDeleteIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="RetailMicrositeSettings">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Settings
        </Typography>

        <Button size="small" variant="contained" onClick={saveSettings} color="secondary">
          Save
        </Button>
      </Toolbar>

      <div className="content">
        <div className="row">
          <div>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
              <Typography variant="subtitle1">
                Courses
              </Typography>

              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  const includedCourses = settings.includedCourses.map(c => c.id);
                  let courseId = '';

                  for (let i = 0; i < courses.length; i++) {
                    const currentCourseId = courses[i].id;

                    if (!includedCourses.includes(currentCourseId)) {
                      courseId = currentCourseId;
                      break;
                    }
                  }

                  setSelectedCourseToAdd(courseId);
                  setAddCourseModalOpen(true);
                }}
              >
                Add Course
              </Button>
            </Toolbar>
            <TableContainer>
              <Table aria-label="settings table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Description</strong></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.includedCourses.map((course, i) => (
                    <TableRow key={`setting-row-${i}`}>
                      <TableCell>
                        <div className="course-image-container">
                          <div>
                            <img
                              src={course.backgroundImage || 'https://pupford.b-cdn.net/assets%2F1602111918861-pupford-thumb.png?alt=media&token=3e2e031f-40c8-4b88-b4ab-06f8dad8a165'}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <strong>{course.name}</strong>
                        {i !== 0 ? null : <div style={{ color: '#7ED242' }}>Featured</div>}
                      </TableCell>
                      <TableCell>{course.description}</TableCell>
                      <TableCell padding="checkbox">
                        {(i === 0 || settings.includedCourses.length < 2) ? null :
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                              const includedCourses = [ ...settings.includedCourses ];
                              const currentCourse = { ...course };
                              const previousCourse = { ...settings.includedCourses[i - 1] };

                              includedCourses[i - 1] = currentCourse;
                              includedCourses[i] = previousCourse;

                              setSettings({
                                ...settings,
                                includedCourses,
                              });
                            }}
                            aria-label="Move Up"
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        }
                      </TableCell>
                      <TableCell>
                        {(i === settings.includedCourses.length - 1 || settings.includedCourses.length < 2) ? null :
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                              const includedCourses = [ ...settings.includedCourses ];
                              const currentCourse = { ...course };
                              const nextCourse = { ...settings.includedCourses[i + 1] };

                              includedCourses[i + 1] = currentCourse;
                              includedCourses[i] = nextCourse;

                              setSettings({
                                ...settings,
                                includedCourses,
                              });
                            }}
                            aria-label="Move Down"
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        }
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton color="inherit" onClick={() => setCourseDeleteIndex(i)} aria-label="Delete Course">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={addCourseModalOpen} onClose={() => setAddCourseModalOpen(false)} TransitionComponent={Transition}>
        <DialogTitle>Add Course</DialogTitle>
        <DialogContent>
          <FormControl
            style={{ marginTop: 10, marginBottom: 10 }}
            variant="outlined"
            margin="dense"
            className="day-text-field"
          >
            <InputLabel id="action-type">
              Type
            </InputLabel>
            <Select
              value={selectedCourseToAdd}
              onChange={(e) => setSelectedCourseToAdd(e.target.value)}
              label="Type"
            >
              {courses.filter(c => {
                const includedCourses = settings.includedCourses.map(c => c.id);

                return !includedCourses.includes(c.id);
              }).map(c => {
                return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const course = courses.find(c => c.id === selectedCourseToAdd);

            if (course) {
              setSettings({
                ...settings,
                includedCourses: [
                  ...settings.includedCourses,
                  course,
                ],
              });
            }
            setAddCourseModalOpen(false);
          }} color="primary">
            Add
          </Button>
          <Button variant="contained" onClick={() => setAddCourseModalOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={courseDeleteIndex !== -1} onClose={() => setCourseDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Remove Course?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove <strong>{!settings.includedCourses[courseDeleteIndex] ? '' : settings.includedCourses[courseDeleteIndex].name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={removeIncludedCourse} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setCourseDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Settings;
