import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

function AcademyBonus(props) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('academy-bonus').get();

        const postsData = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        setPosts(postsData);
        setLoading(false);
      } catch (e) {
        console.log(e.toString());
      }
    }

    fetchPosts();
  }, []);

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const addPost = async () => {
    setLoading(true);
    
    try {
      const db = firebase.firestore();
      const doc = await db.collection('academy-bonus').add({
        title: 'New Post',
        imageUrl: '',
        description: '',
        sections: [],
        backgroundImage: '',
        enabled: false,
        category: '',
        tags: [],
        date: firebase.firestore.Timestamp.fromDate(new Date()),
      });

      console.log('result', doc);

      setPosts([
        ...posts,
        {
          id: doc.id,
          title: 'New Post',
        },
      ]);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
      window.alert('An error occurred adding new post.');
    }
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Academy Bonus
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={addPost}
          >
            Add Item
          </Button>
        </Toolbar>
        <List>
          {posts.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.title} secondary={`ID: ${p.id}`} />
                  <Link to={`/academy-content/academy-bonus/${p.id}`} style={{color: 'black'}}>
                    <IconButton edge="start" color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>
    </div>
  );
}

export default AcademyBonus;
