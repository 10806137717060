import {
  SET_NOTIFICATIONS,
} from './types';

export const setNotifications = (notificationData) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notificationData,
  };
};
