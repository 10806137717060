import React from 'react';

import './apps-urls-section.scss';

export const AppsUrlsSection = ({ data }) => {
  const { title, content, imageUrl } = data;

  return (
    <section
      className="AppsUrlsContainer"
      style={{ backgroundImage: `url("${imageUrl}")` }}
    >
      <div className="overlay" />
      <div className="content">
        <h2 className="title">{title}</h2>
        <h3 className="subtitle">{content}</h3>
        <div className="buttonsContainer">
          <button className="button">
            <span className="buttonText">App Store</span>
          </button>
          <button className="button">
            <span className="buttonText">Play Store</span>
          </button>
        </div>
      </div>
    </section>
  );
};
