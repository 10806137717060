import React, { Component } from 'react';

import Course from './course';

class Behavior extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Course isBehavior={true} />
    );
  }
}

export default Behavior;
