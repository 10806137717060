import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
  Input,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ChipInput from 'material-ui-chip-input';

import './editSubscriptionDownsell.scss';
import DeletableChip from '../../../shared/deletableChip';

const ruleTypes = [
  'Always',
  'Coupon Code',
  'Subscription Contains These Products',
  'Subscription Does Not Contain These Products',
  'Subscription Contains Product in These Categories',
  'Subscription Does Not Contain Product in These Categories',
  'Subscription Contains Product with These Tags',
  'Subscription Does Not Contain Product with These Tags',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Subtotal is Less Than',
  'Subtotal is Greater Than',
];
const showProductTypes = [
  'Subscription Contains These Products',
  'Subscription Does Not Contain These Products',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
];
const showCategoryTypes = [
  'Subscription Contains Product in These Categories',
  'Subscription Does Not Contain Product in These Categories',
];
const showTagTypes = [
  'Subscription Contains Product with These Tags',
  'Subscription Does Not Contain Product with These Tags',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditSubscriptionDownsell({
  close,
  save,
  editDownsell,
  simplifiedProducts,
  productCategories,
  productTags,
}) {
  const [downsell, setDownsell] = useState(cloneDeep(editDownsell));

  return (
    <div className="EditSubscriptionDownsell">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Subscription Downsell
          </Typography>
          <Button color="inherit" onClick={() => save(downsell)}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={downsell.active}
              onChange={(e) => {
                setDownsell({
                  ...downsell,
                  active: e.target.checked,
                });
              }}
              name="active"
              color="primary"
            />
          }
          label="Active"
        />

        <FormControlLabel
          control={
            <Switch
              checked={downsell.updateShipping}
              onChange={(e) => {
                setDownsell({
                  ...downsell,
                  updateShipping: e.target.checked,
                });
              }}
              name="updateShipping"
              color="primary"
            />
          }
          label="Charge shipping"
        />

        <TextField
          label="Title"
          value={downsell.title}
          onChange={(e) => {
            setDownsell({
              ...downsell,
              title: e.target.value,
            });
          }}
          margin="dense"
          variant="outlined"
          type="text"
          className="day-text-field"
          style={{marginTop: 8, marginBottom: 4}}
        />

        <TextField
          label="Description"
          value={downsell.description}
          onChange={(e) => {
            setDownsell({
              ...downsell,
              description: e.target.value,
            });
          }}
          margin="dense"
          variant="outlined"
          type="text"
          className="day-text-field"
          style={{marginTop: 8, marginBottom: 4}}
        />

        <div className="input-row">
          <FormControl
            variant="outlined"
            style={{marginTop: 8, marginBottom: 4, width: '49%'}}
            className="day-text-field"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
            <OutlinedInput
              value={downsell.price}
              onChange={(e) => {
                setDownsell({
                  ...downsell,
                  price: e.target.value,
                });
              }}
              margin="dense"
              label="Price"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>

          <TextField
            label="Priority"
            value={downsell.priority}
            onChange={(e) => {
              setDownsell({
                ...downsell,
                priority: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 8, marginBottom: 4, width: '49%'}}
          />
        </div>

        <div className="input-row">
          <TextField
            label="Downsell Product"
            style={{marginTop: 8, marginBottom: 4, width: '49%'}}
            margin="dense"
            value={downsell.selectedProduct}
            onChange={(e) => {
              setDownsell({
                ...downsell,
                selectedProduct: e.target.value,
              });
            }}
            variant="outlined"
            select
          >
            {simplifiedProducts.map((simplifiedProduct, i) => {
              return <MenuItem key={`featured-product-${i}`} value={simplifiedProduct.id}>{simplifiedProduct.name}</MenuItem>;
            })}
          </TextField>

          <TextField
            label="Number of Renewals"
            style={{marginTop: 8, marginBottom: 4, width: '49%'}}
            margin="dense"
            value={downsell.numberOfRenewals}
            onChange={(e) => {
              setDownsell({
                ...downsell,
                numberOfRenewals: e.target.value,
              });
            }}
            variant="outlined"
            select
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(value => {
              return <MenuItem key={`number-of-renewals-option-${value}`} value={value}>{value}</MenuItem>;
            })}
          </TextField>
        </div>

        <div className="section-container">
          <div className="section-label">Rules</div>
          <div className="rules-button-row">
            <Button color="primary" onClick={() => {
              const rules = [ ...downsell.rules ];

              rules.push({
                type: 'group',
                rules: [{
                  type: ruleTypes[0],
                  products: [],
                  cartSubTotal: '',
                  tags: [],
                  categories: [],
                  couponCodes: [],
                }],
              });

              setDownsell({
                ...downsell,
                rules,
              });
            }} variant="contained">
              Add Rule Group
            </Button>
          </div>

          {downsell.rules.map((group, i) => {
            return (
              <div key={`rule-group-${i}`}>
                {i !== 0 && <div className="condition-text">OR</div>}

                <div className="rule-group">
                  {downsell.rules.length > 1 &&
                    <Button color="secondary" size="small" onClick={() => {
                      const rules = [ ...downsell.rules ];

                      rules.splice(i, 1);

                      setDownsell({
                        ...downsell,
                        rules,
                      });
                    }} variant="contained">
                      Remove Group
                    </Button>
                  }
                  {group.rules.map((rule, j) => {
                    return (
                      <div key={`rule-${i}-${j}`}>
                        {j !== 0 && <div className="condition-text">AND</div>}
                        <div className="form-row">
                          {group.rules.length > 1 &&
                            <IconButton
                              onClick={() => {
                                const rules = [ ...group.rules ];

                                rules.splice(j, 1);

                                const downsellRules = [ ...downsell.rules ];
                                downsellRules[i].rules = rules;

                                setDownsell({
                                  ...downsell,
                                  rules: downsellRules,
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                            <InputLabel>Rule Type</InputLabel>
                            <Select
                              value={rule.type}
                              onChange={(e) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  type: e.target.value,
                                };

                                const downsellRules = [ ...downsell.rules ];
                                downsellRules[i].rules = rules;

                                setDownsell({
                                  ...downsell,
                                  rules: downsellRules,
                                });
                              }}
                              label="Rule Type"
                            >
                              {ruleTypes.map((ruleType, k) => {
                                return <MenuItem key={`rule-type-${i}-${j}-${k}`} value={ruleType}>{ruleType}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>

                          {rule.type.includes('Subtotal') &&
                            <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                              <OutlinedInput
                                value={rule.cartSubTotal}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    cartSubTotal: e.target.value,
                                  };

                                  const downsellRules = [ ...downsell.rules ];
                                  downsellRules[i].rules = rules;
  
                                  setDownsell({
                                    ...downsell,
                                    rules: downsellRules,
                                  });
                                }}
                                margin="dense"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{maxWidth: 180, marginRight: 15}}
                              />
                            </FormControl>
                          }

                          {(showProductTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Products</InputLabel>
                              <Select
                                multiple
                                value={rule.products}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    products: e.target.value,
                                  };

                                  const downsellRules = [ ...downsell.rules ];
                                  downsellRules[i].rules = rules;
  
                                  setDownsell({
                                    ...downsell,
                                    rules: downsellRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value.id}
                                        label={value.name}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.products.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const products = [ ...rule.products ];

                                          products.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            products,
                                          };

                                          const downsellRules = [ ...downsell.rules ];
                                          downsellRules[i].rules = rules;
          
                                          setDownsell({
                                            ...downsell,
                                            rules: downsellRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {simplifiedProducts.filter(product => {
                                  const found = rule.products.find(p => p.id === product.id);
                                  return !found;
                                }).map(product => (
                                  <MenuItem key={product.id} value={product}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showCategoryTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Categories</InputLabel>
                              <Select
                                multiple
                                value={rule.categories}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    categories: e.target.value,
                                  };

                                  const downsellRules = [ ...downsell.rules ];
                                  downsellRules[i].rules = rules;
  
                                  setDownsell({
                                    ...downsell,
                                    rules: downsellRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.categories.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const categories = [ ...rule.categories ];

                                          categories.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            categories,
                                          };

                                          const downsellRules = [ ...downsell.rules ];
                                          downsellRules[i].rules = rules;
          
                                          setDownsell({
                                            ...downsell,
                                            rules: downsellRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {productCategories.filter(category => {
                                  return !rule.categories.includes(category);
                                }).map(category => (
                                  <MenuItem key={category} value={category}>
                                    {category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(showTagTypes.includes(rule.type)) &&
                            <FormControl style={{flex: 1}}>
                              <InputLabel>Tags</InputLabel>
                              <Select
                                multiple
                                value={rule.tags}
                                onChange={(e) => {
                                  const rules = [ ...group.rules ];

                                  rules[j] = {
                                    ...rules[j],
                                    tags: e.target.value,
                                  };

                                  const downsellRules = [ ...downsell.rules ];
                                  downsellRules[i].rules = rules;
  
                                  setDownsell({
                                    ...downsell,
                                    rules: downsellRules,
                                  });
                                }}
                                input={<Input />}
                                renderValue={selected => (
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value}
                                        label={value}
                                        style={{margin: 2}}
                                        color="primary"
                                        onDelete={() => {
                                          const index = rule.tags.indexOf(value);
              
                                          if (index === -1) {
                                            return;
                                          }

                                          const rules = [ ...group.rules ];
                                          const tags = [ ...rule.tags ];

                                          tags.splice(index, 1);

                                          rules[j] = {
                                            ...rules[j],
                                            tags,
                                          };

                                          const downsellRules = [ ...downsell.rules ];
                                          downsellRules[i].rules = rules;
          
                                          setDownsell({
                                            ...downsell,
                                            rules: downsellRules,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {productTags.filter(tag => {
                                  return !rule.tags.includes(tag);
                                }).map(tag => (
                                  <MenuItem key={tag} value={tag}>
                                    {tag}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }

                          {(rule.type === 'Coupon Code') &&
                            <ChipInput
                              style={{flex: 1}}
                              defaultValue={rule.couponCodes}
                              onChange={(chips) => {
                                const rules = [ ...group.rules ];

                                rules[j] = {
                                  ...rules[j],
                                  couponCodes: chips,
                                };

                                const downsellRules = [ ...downsell.rules ];
                                downsellRules[i].rules = rules;

                                setDownsell({
                                  ...downsell,
                                  rules: downsellRules,
                                });
                              }}
                              margin="dense"
                              allowDuplicates={false}
                              fullWidth={true}
                              label="Coupon Codes"
                              helperText='Press "enter" after each code as you type'
                            />
                          }
                        </div>
                      </div>
                    );
                  })}

                  <Button color="primary" size="small" onClick={() => {
                    const rules = [ ...group.rules ];

                    rules.push({
                      type: ruleTypes[0],
                      products: [],
                      cartSubTotal: 0,
                      tags: [],
                      categories: [],
                      couponCodes: [],
                    });

                    const downsellRules = [ ...downsell.rules ];
                    downsellRules[i].rules = rules;

                    setDownsell({
                      ...downsell,
                      rules: downsellRules,
                    });
                  }} variant="contained">
                    Add Condition
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditSubscriptionDownsell;
