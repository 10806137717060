import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TrainingTools(props) {
  const [tools, setTools] = useState([]);
  const [mainTools, setMainTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [toolName, setToolName] = useState('');
  const [toolImage, setToolImage] = useState('');
  const [toolUrl, setToolUrl] = useState('');
  const [toolId, setToolId] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    async function fetchTrainingTools() {
      const db = firebase.firestore();

      try {
        const querySnapshot = await db.collection('training-tools').doc('training-tools').get();
        const trainingTools = querySnapshot.data();

        setTools(trainingTools.tools);
        setMainTools(trainingTools.mainTools);
        setLoading(false);
      } catch (e) {
        window.alert(`Error: ${e.toString()}`);
      }
    }
    fetchTrainingTools();
  }, []);

  const openEdit = (i, toolType) => {
    const editTools = toolType === 'tools' ? tools : mainTools;

    setEditIndex(i);
    setToolName(editTools[i].name);
    setToolImage(editTools[i].image);
    setToolUrl(editTools[i].url);
    setToolId(editTools[i].id);
    setShowAddModal(true);
  };

  const submit = async () => {
    setLoading(true);

    const db = firebase.firestore();
    const updatedTools = cloneDeep(type === 'tools' ? tools : mainTools);
    const dataToAdd = {
      name: toolName,
      image: toolImage,
      url: toolUrl,
      id: toolId,
    };

    try {
      if (editIndex !== -1) {
        updatedTools[editIndex] = dataToAdd;

        let ref = db.collection('training-tools').doc('training-tools');
        await ref.update({
          [type]: updatedTools,
        });

        if (type === 'tools') {
          setTools(updatedTools);
        } else {
          setMainTools(updatedTools);
        }
      } else {
        updatedTools.push(dataToAdd);

        let ref = db.collection('training-tools').doc('training-tools');
        await ref.update({
          [type]: updatedTools,
        });

        if (type === 'tools') {
          setTools(updatedTools);
        } else {
          setMainTools(updatedTools);
        }
      }
    } catch (e) {
      console.log('e', e);
      window.alert('Notice: An error occurred. Please try again.');
    }
    setLoading(false);
    cancelAdd();
  };

  const cancelAdd = () => {
    setToolName('');
    setToolImage('');
    setToolUrl('');
    setToolId('');
    setEditIndex(-1);
    setShowAddModal(false);
  };

  const deleteTool = async () => {
    setLoading(true);

    const db = firebase.firestore();
    const updatedTools = cloneDeep(type === 'tools' ? tools : mainTools);

    try {
      updatedTools.splice(deleteIndex, 1);

      let ref = db.collection('training-tools').doc('training-tools');
      await ref.update({
        [type]: updatedTools,
      });

      if (type === 'tools') {
        setTools(updatedTools);
      } else {
        setMainTools(updatedTools);
      }
    } catch (e) {
      window.alert('Notice: An error occurred. Please try again.');
    }

    setLoading(false);
    setDeleteIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Sign Up Training Tools
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              setType('tools');
              setShowAddModal(true);
            }}
          >
            Add Training Tool
          </Button>
        </Toolbar>
        <List>
          {tools.map((tool, i) => {
            return (
              <div key={i}>
                <Divider />
                <ListItem>
                  <ListItemText primary={tool.name} secondary={tool.url} />

                  <IconButton onClick={() => {
                    setType('tools');
                    openEdit(i, 'tools');
                  }} key={`edit-${i}`} color="inherit" aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setType('tools');
                    setDeleteIndex(i);
                  }} key={`delete-${i}`} color="inherit" aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Home Screen Training Tools
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              setType('mainTools');
              setShowAddModal(true);
            }}
          >
            Add Training Tool
          </Button>
        </Toolbar>
        <List>
          {mainTools.map((tool, i) => {
            return (
              <div key={`main-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={tool.name} secondary={tool.url} />

                  <IconButton onClick={() => {
                    setType('mainTools');
                    openEdit(i, 'mainTools');
                  }} key={`main-edit-${i}`} color="inherit" aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setType('mainTools');
                    setDeleteIndex(i);
                  }} key={`main-delete-${i}`} color="inherit" aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={showAddModal} onClose={() => { cancelAdd() }} TransitionComponent={Transition}>
        <Card style={{overflow: 'scroll'}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {editIndex === -1 ? 'Add' : 'Edit'} Training Tool
            </Typography>
            <TextField
              label="Name"
              value={toolName}
              onChange={(e) => { setToolName(e.target.value) }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              label="Product ID"
              value={toolId}
              onChange={(e) => { setToolId(e.target.value) }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              label="Image URL"
              value={toolImage}
              onChange={(e) => { setToolImage(e.target.value) }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              label="Product URL"
              value={toolUrl}
              onChange={(e) => { setToolUrl(e.target.value) }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
          </CardContent>
          <CardActions>
            <Button
              style={{marginRight: '10px'}}
              variant="contained"
              color="primary"
              disabled={!toolName || !toolImage || !toolUrl || !toolId}
              onClick={submit}
            >Submit</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { cancelAdd() }}
            >cancel</Button>
          </CardActions>
        </Card>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Delete Training Tool
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Are you sure you want to delete {type === 'tools' ?(tools[deleteIndex] && tools[deleteIndex].name) : (mainTools[deleteIndex] && mainTools[deleteIndex].name)}?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              style={{marginRight: '10px'}}
              variant="contained"
              color="secondary"
              onClick={deleteTool}
            >Delete</Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => { setDeleteIndex(-1) }}
            >cancel</Button>
          </CardActions>
        </Card>
      </Dialog>
    </div>
  );
}

export default TrainingTools;
