import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import CompletionPopupEdit from './completionPopupEdit';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CompletionPopups(props) {
  const [popups, setPopups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [currentEditPopup, setCurrentEditPopup] = useState({});
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchPopups();
  }, []);

  const fetchPopups = async () => {
    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection('course-completion-ads').doc('ads').get();
      console.log(querySnapshot.data().data)

      setPopups(querySnapshot.data().data);
    } catch (e) {
      console.log(e.toString());
    }

    setLoading(false);
  };

  const addPopup = () => {
    setCurrentEditPopup({
      internalName: '',
      headline: '',
      type: 'physical',
      productId: '',
      items: [],
      backgroundImage: '',
      backgroundOverlayColor: '',
      textColor: '',
      id: uuidv4(),
      webURL: '',
    });
    setCurrentEditIndex(-1);
    setEditOpen(true);
  };

  const savePopup = async (popup) => {
    setEditOpen(false);
    setLoading(true);
    const db = firebase.firestore();

    try {
      const popupsCopy = [ ...popups ];

      if (currentEditIndex === -1) {
        popupsCopy.push(popup);
      } else {
        popupsCopy[currentEditIndex] = {
          ...popup,
        };
      }

      await db.collection('course-completion-ads').doc('ads').update({
        data: popupsCopy,
      });
      setPopups(popupsCopy);
    } catch (e) {
      console.log(e)
      window.alert('Error saving Ad. Please try again.');
    }

    setLoading(false);
    closeEditModal();
  };

  const closeEditModal = () => {
    setEditOpen(false);
    setCurrentEditIndex(-1);
    setCurrentEditPopup({
      internalName: '',
      headline: '',
      type: 'physical',
      productId: '',
      items: [],
      backgroundImage: '',
      backgroundOverlayColor: '',
      textColor: '',
      id: uuidv4(),
      webURL: '',
    });
  };

  const deletePopup = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const popupsCopy = [ ...popups ];
      popupsCopy.splice(deleteIndex, 1);

      await db.collection('course-completion-ads').doc('ads').update({
        data: popupsCopy,
      });

      setPopups(popupsCopy);
    } catch (e) {
      console.log(e)
      window.alert('Error deleting Ad. Please try again.');
    }

    setLoading(false);
    setDeleteIndex(-1);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Completion Ads
          </Typography>
          <div>
            {/* <Link to="/academy-content/completion-ads/analytics">
              <Button variant="contained" color="secondary" onClick={addPopup} style={{marginRight: '10px'}}>
                Analytics
              </Button>
            </Link> */}
            <Button variant="contained" color="primary" onClick={addPopup} style={{marginRight: '10px'}}>
              Add New
            </Button>
          </div>
        </Toolbar>
        <List>
          {popups.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.internalName} secondary={`ID: ${p.id}`} />
                  <IconButton onClick={() => {
                    setCurrentEditPopup(p);
                    setEditOpen(true);
                    setCurrentEditIndex(i);
                  }} edge="start" color="inherit" aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setDeleteIndex(i);
                  }} edge="start" color="inherit" aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={editOpen} onClose={closeEditModal} TransitionComponent={Transition}>
        <CompletionPopupEdit
          closeModal={closeEditModal}
          popup={currentEditPopup}
          savePopup={savePopup}
        ></CompletionPopupEdit>
      </Dialog>
      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <Card style={{width: '350px', padding: 10}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Remove Ad?
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Are you sure you want to remove <strong>{popups[deleteIndex] && popups[deleteIndex].internalName || 'ad'}</strong>?
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              style={{marginRight: '10px'}}
              variant="contained"
              color="primary"
              onClick={deletePopup}
            >Confirm</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { setDeleteIndex(-1) }}
            >cancel</Button>
          </CardActions>
        </Card>
      </Dialog>
    </div>
  );
}

export default CompletionPopups;
