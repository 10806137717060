import React, {
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';

import ProductEdit from './productEdit';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductList() {
  const [products, setProducts] = useState([]);
  const [docId, setDocId] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [currentEditProduct, setCurrentEditProduct] = useState({});
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [importProductId, setImportProductId] = useState('');
  const [importing, setImporting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [importMessage, setImportMessage] = useState('');

  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('products').get();
        const products = querySnapshot.docs[0].data().detailed;

        setProducts(products);
        setDocId(querySnapshot.docs[0].id);
      } catch (e) {
        console.log(e.toString());
      }
    }
    fetchProducts();
  }, []);

  const openEditModal = (i) => {
    setEditOpen(true);
    setCurrentEditProduct(cloneDeep(products[i]));
    setCurrentEditIndex(i);
  };

  const closeEditModal = () => {
    setEditOpen(false);
    setCurrentEditIndex(-1);
  };

  const saveProduct = async (product) => {
    const db = firebase.firestore();
    const copiedProducts = cloneDeep(products);

    copiedProducts[currentEditIndex] = product;

    const simplified = copiedProducts.map(p => {
      const simple = {
        section: p.section,
        name: p.name,
        id: `${p.id}`,
        image: p.images.length ? p.images[0].src : '',
      };

      return simple;
    });

    db.collection('products').doc(docId).update({
      'detailed': copiedProducts,
      simplified,
    });

    setProducts(copiedProducts);
    closeEditModal();
  };

  const addImportedProduct = (product) => {
    const copiedProducts = cloneDeep(products);

    const images = product.media.filter(m => {
      return m.hasOwnProperty('contentType') && m.contentType.includes('image');
    }).map(image => {
      return {
        id: image.timeCreated,
        src: image.url,
        name: image.name,
      };
    });

    const productToAdd = {
      variations: [],
      details: [],
      section: 'unassigned',
      stock_status: 'instock',
      name: product.name,
      images,
      price: product.isOnSale ? product.salePrice : product.price,
      sale_price: product.isOnSale ? product.salePrice : '',
      regular_price: product.price,
      id: product.id,
      stock_quantity: +product.stockQuantity,
      shipping_class: product.shippingClass,
      meta_data: [{
        key: '_satt_data',
        value: {
          subscription_schemes: [],
        },
      }],
    };

    copiedProducts.unshift(productToAdd);
    setProducts(copiedProducts);
    return copiedProducts;
  };

  const importProduct = async () => {
    setShowImportModal(false);
    setImporting(true);

    try {
      const snapshot = await firebase.firestore().collection('products-v2').doc(importProductId).get();
      const product = snapshot.data();

      if (!product) {
        setImportMessage('Unable to import product');
      } else {
        const copiedProducts = addImportedProduct({
          ...product,
          id: snapshot.id,
        });

        const db = firebase.firestore();
        const simplified = copiedProducts.map(p => {
          const simple = {
            section: p.section,
            name: p.name,
            id: `${p.id}`,
            image: p.images.length ? p.images[0].src : '',
          };

          return simple;
        });

        db.collection('products').doc(docId).update({
          'detailed': copiedProducts,
          simplified,
        });

        setImportMessage('Product Imported');
        setImportProductId('');
      }

      setImporting(false);
      setSnackbarOpen(true);
    } catch (e) {
      setImportMessage('Unable to import product');
      setSnackbarOpen(true);
    }
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Products
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={importing}
            onClick={() => { setShowImportModal(true) }}
          >
            import
          </Button>
          { importing && <CircularProgress size={24} style={{position: 'absolute', right: '56px'}} /> }
        </Toolbar>
        <List>
          {products.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.name} secondary={`ID: ${p.id}`} />
                  <IconButton edge="start" color="inherit" onClick={() => { openEditModal(i) }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton edge="start" color="inherit" onClick={() => {  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={editOpen} onClose={closeEditModal} TransitionComponent={Transition}>
        <ProductEdit
          closeModal={closeEditModal}
          product={currentEditProduct}
          saveProduct={saveProduct}
          products={products}
        ></ProductEdit>
      </Dialog>

      <Dialog open={showImportModal} onClose={() => { setShowImportModal(false) }} TransitionComponent={Transition}>
        <div style={{padding: '20px'}}>
          <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Import Product</p>
          <TextField
            style={{minWidth: '225px'}}
            label="Product ID"
            value={importProductId}
            onChange={(e) => { setImportProductId(e.target.value) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <hr/>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!importProductId}
            onClick={importProduct}
          >import</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowImportModal(false) }}
          >cancel</Button>
        </div>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => { setSnackbarOpen(false) }}
        message={importMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { setSnackbarOpen(false) }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default ProductList;
