import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

import './weeklyChallenges.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WeeklyChallenges({ history }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [weeklyChallenges, setWeeklyChallenges] = useState([]);
  const [addNewChallengeOpen, setAddNewChallengeOpen] = useState(false);
  const [newChallenge, setNewChallenge] = useState({
    title: '',
    description: '',
    imageUrl: '',
    videoUrl: '',
    live: false,
    endDate: moment().endOf('week').valueOf(),
    webPath: '',
    steps: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchWeeklyChallenges();
  }, []);

  const fetchWeeklyChallenges = async () => {
    try {
      const challengesSnapshot = await firebase.firestore().collection('weekly-challenges').get();
      const challenges = challengesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setWeeklyChallenges(challenges);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the weekly challenges, please try again.');
    }
  };

  const saveNewChallenge = async () => {
    setLoading(true);

    try {
      const createdChallenge = await firebase.firestore().collection('weekly-challenges').add({
        ...newChallenge,
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
      setAddNewChallengeOpen(false);
      history.push(`/academy-content/weekly-challenges/${createdChallenge.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new weekly challenge. Please try again.');
    }
  };

  const deleteChallenge = async () => {
    const challengeIndex = deleteIndex;

    setDeleteIndex(-1);
    setLoading(true);

    try {
      await firebase.firestore().collection('weekly-challenges').doc(weeklyChallenges[challengeIndex].id).delete();

      const challengesCopy = [ ...weeklyChallenges ];
      challengesCopy.splice(challengeIndex, 1);

      setWeeklyChallenges(challengesCopy);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting challenge. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="WeeklyChallenges">
      {renderLoading()}
      <Card style={{width: '100%'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Monthly Challenges
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewChallenge({
                  title: '',
                  description: '',
                  imageUrl: '',
                  videoUrl: '',
                  live: false,
                  endDate: Date.now(),
                  webPath: '',
                  steps: [],
                  created: Date.now(),
                  updated: Date.now(),
                  lastUpdatedBy: '',
                });
                setAddNewChallengeOpen(true);
              }}
            >
              Add New
            </Button>
          </div>
        </Toolbar>

        <List>
          {weeklyChallenges.map((challenge, i) => {
            return (
              <div key={challenge.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={challenge.title || 'Title not set'}
                    secondary={
                      <span>
                        <span style={{marginRight: 10}}><strong>ID:</strong> {challenge.id}</span>
                        <span style={{marginRight: 10}}><strong>Last updated:</strong> {moment(challenge.updated).calendar()}</span>
                        <span style={{marginRight: 10}}><strong>Last updated by:</strong> {challenge.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                  <Link edge="start" to={`/academy-content/weekly-challenges/${challenge.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton edge="start" color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>

                  <Link edge="start" to={`/academy-content/weekly-challenges/${challenge.id}/posts`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Posts">
                      <DynamicFeedIcon />
                    </IconButton>
                  </Link>

                  <IconButton onClick={() => {
                    setDeleteIndex(i);
                  }} color="inherit" aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={addNewChallengeOpen} onClose={() => { setAddNewChallengeOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Challenge</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newChallenge.title}
            onChange={(e) => {
              setNewChallenge({
                ...newChallenge,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={newChallenge.description}
            onChange={(e) => {
              setNewChallenge({
                ...newChallenge,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Image URL"
            value={newChallenge.imageUrl}
            onChange={(e) => {
              setNewChallenge({
                ...newChallenge,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Video URL"
            value={newChallenge.videoUrl}
            onChange={(e) => {
              setNewChallenge({
                ...newChallenge,
                videoUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Web Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newChallenge.webPath}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewChallenge({
                ...newChallenge,
                webPath: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewChallenge} color="primary" disabled={!newChallenge.title || !newChallenge.description || !newChallenge.imageUrl || !newChallenge.videoUrl || !newChallenge.webPath}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddNewChallengeOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Challenge?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {deleteIndex === -1 ? 'this challenge?' : <strong>{weeklyChallenges[deleteIndex].title}</strong>}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteChallenge} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WeeklyChallenges;
