import React, { useEffect, useState } from 'react';
import { HomePageForm } from './homePageForm/homePageForm';
import { Card, CircularProgress } from '@material-ui/core';
import { defaultValues } from './constants';
import firebase from 'firebase';

const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        textAlign: 'center',
      }}
    >
      <CircularProgress
        style={{ color: '#fff', top: '50%', position: 'absolute' }}
      />
    </div>
  );
};

export const HomePageV2 = () => {
  const [defaultData, setDefaultData] = useState();
  const [isHomePageDataLoading, setIsHomePageDataLoading] = useState(false);

  const getHomePageData = async () => {
    setIsHomePageDataLoading(true);
    try {
      const homePageData = await firebase
        .firestore()
        .collection('config')
        .doc('home-page-v2')
        .get();

      const data = homePageData.data();

      let initialValues = defaultValues;

      if (data?.heroSection) {
        initialValues = {
          pageDetailsSection: data.pageDetailsSection,
          heroSection: data.heroSection,
          productsSection: {
            ...data.productsSection,
            items:
              data.productsSection.items ?? defaultValues.productsSection.items,
          },
          getStartedSection: {
            ...data.getStartedSection,
            words:
              data.getStartedSection.words ??
              defaultValues.getStartedSection.words,
          },
          statisticsSection: {
            ...data.statisticsSection,
            items:
              data.statisticsSection.items ??
              defaultValues.statisticsSection.items,
          },
          guaranteeSection: {
            ...data.guaranteeSection,
          },
          productCategoriesSection: {
            ...data.productCategoriesSection,
            categories:
              data.productCategoriesSection.categories ??
              defaultValues.productCategoriesSection.categories,
          },
          appsUrlsSection: {
            ...data.appsUrlsSection,
          },
          academySection: {
            ...data.academySection,
          },
        };
      }

      setDefaultData(initialValues);
    } catch (error) {
    } finally {
      setIsHomePageDataLoading(false);
    }
  };

  useEffect(() => {
    if (!defaultData) {
      getHomePageData();
    }
  }, [defaultData]);

  if (isHomePageDataLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Card variant="outlined">
        <HomePageForm defaultValues={defaultData} />
      </Card>
    </div>
  );
};
