import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrdersToday from './ordersToday/ordersToday';
import Orders from './orders/orders';
import Wholesale from './wholesale/wholesale';
import SubscriptionForecast from './subscriptionForecast/subscriptionForecast';
import SubscriptionRetention from './subscriptionRetention/subscriptionRetention';
import NewSubscriptions from './newSubscriptions/newSubscriptions';
import CanceledSubscriptions from './canceledSubscriptions/canceledSubscriptions';
import RenewedSubscriptions from './renewedSubscriptions/renewedSubscriptions';
import Chewy from './chewy/chewy';
import Amazon from './amazon/amazon';
import CustomerLTV from './customerLTV/customerLTV';

function Reports({}) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/reports/orders-today" component={OrdersToday} />
        <Route path="/reports/orders" component={Orders} />
        <Route path="/reports/wholesale" component={Wholesale} />
        <Route path="/reports/new-subscriptions" component={NewSubscriptions} />
        <Route path="/reports/renewed-subscriptions" component={RenewedSubscriptions} />
        <Route path="/reports/canceled-subscriptions" component={CanceledSubscriptions} />
        <Route path="/reports/subscription-forecast" component={SubscriptionForecast} />
        <Route path="/reports/subscription-retention" component={SubscriptionRetention} />
        <Route path="/reports/chewy" component={Chewy} />
        <Route path="/reports/amazon" component={Amazon} />
        <Route path="/reports/customer-ltv" component={CustomerLTV} />
        <Route component={OrdersToday}/>
      </Switch>
    </div>
  );
}

export default Reports;
