import { useState } from "react";
import { toColor } from "../utils/toColor.util";

export function useColor(model, initColor) {
  const [color, setColor] = useState(() => {
    switch (model) {
      case "hex":
        return toColor("hex", initColor);
      case "rgb":
        return toColor("rgb", initColor);
      case "hsv":
        return toColor("hsv", initColor);
      default:
        return toColor("hex", "#121212");
    }
  });

  return [color, setColor];
}
