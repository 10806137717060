import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import EditCouponCode from './editCouponCode/editCouponCode';
import './couponCodes.scss';
import { codeTypes } from './codeConfig';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function CouponCodes(props) {
  const [loading, setLoading] = useState(true);
  const [couponCodes, setCouponCodes] = useState([]);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [editCode, setEditCode] = useState({});
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [page, setPage] = useState(1);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchCouponCodes();
  }, []);

  const fetchCouponCodes = async () => {
    setLoading(true);

    try {
      const countSnapshot = await firebase.firestore().collection('counters').doc(`coupon-codes`).get();
      const querySnapshot = await firebase.firestore().collection('coupon-codes').orderBy('created', 'desc').limit(pageCount).get();
      const settingsQuerySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();

      setNumberOfPages(Math.ceil(countSnapshot.data().count / pageCount));

      const codes = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let expirationDate = new Date();

        if (data.expirationDate && data.expirationDate.seconds && typeof data.expirationDate.seconds === 'number') {
          expirationDate = new Date((data.expirationDate.seconds * 1000));
        }

        return {
          ...data,
          id: doc.id,
          expirationDate,
        };
      });

      setPage(1);
      setCouponCodes(codes);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setSimplifiedProducts(settingsQuerySnapshot.data().simplifiedProducts);
      setProductCategories(settingsQuerySnapshot.data().productCategories);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
      window.alert('There was an error fetching coupon codes. Please refresh and try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let codesSnapshot;
      let newPage = page;

      if (value === 'next') {
        codesSnapshot = await db.collection('coupon-codes').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        codesSnapshot = await db.collection('coupon-codes').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedCodes = codesSnapshot.docs.map(doc => {
        const data = doc.data();
        let expirationDate = new Date();

        if (data.expirationDate && data.expirationDate.seconds && typeof data.expirationDate.seconds === 'number') {
          expirationDate = new Date((data.expirationDate.seconds * 1000));
        }

        return {
          ...data,
          id: doc.id,
          expirationDate,
        };
      });

      setPage(newPage);
      setCouponCodes(mappedCodes);
      setFirst(codesSnapshot.docs[0]);
      setLast(codesSnapshot.docs[codesSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      window.alert('There was an error fetching coupon codes. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const search = async () => {
    setLoading(true);

    try {
      const querySnapshot = await firebase.firestore().collection('coupon-codes').where('code', '==', searchText).get();
      const codes = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let expirationDate = new Date();

        if (data.expirationDate && data.expirationDate.seconds && typeof data.expirationDate.seconds === 'number') {
          expirationDate = new Date((data.expirationDate.seconds * 1000));
        }

        return {
          ...data,
          id: doc.id,
          expirationDate,
        };
      });

      setCouponCodes(codes);
      setLoading(false);
      setSearchOpen(false);
      setSearchTerm(searchText);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      window.alert('There was an error searching coupon codes. Please try again.');
    }
  };

  const saveCode = async (codeToSave) => {
    const code = { ...codeToSave };
    setEditing(false);
    setLoading(true);
    const codesCopy = [ ...couponCodes ];
    const failedCodes = [];

    try {
      if (editIndex === -1) {
        const codeStrings = code.code.split(/\r?\n|\r|\n/g);
        const updatedCodeStrings = codeStrings.map(codeString => {
          return codeString.trim().toLowerCase();
        }).filter(codeString => codeString);
        let createdCodesCount = 0;

        for (let i = 0; i < updatedCodeStrings.length; i++) {
          try {
            const codeToCreate = cloneDeep(code);
            codeToCreate.code = updatedCodeStrings[i];
            const createdCode = await firebase.firestore().collection('coupon-codes').add(codeToCreate);
            codeToCreate.id = createdCode.id;
            codesCopy.push(codeToCreate);
            createdCodesCount++;
          } catch (e) {
            failedCodes.push(updatedCodeStrings[i]);
          }
        }

        await firebase.firestore().collection('counters').doc('coupon-codes').update({
          count: firebase.firestore.FieldValue.increment(createdCodesCount),
        });
      } else {
        code.code = code.code.toLowerCase();
        const copiedCode = { ...code };
        delete copiedCode.id;
        await firebase.firestore().collection('coupon-codes').doc(code.id).update({
          ...copiedCode,
          updated: Date.now(),
        });
        codesCopy[editIndex] = code;
      }

      setCouponCodes(codesCopy);
      setEditIndex(-1);
      setLoading(false);

      if (searchTerm) {
        setSearchTerm('');
        fetchCouponCodes();
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setEditIndex(-1);
      window.alert('An error occurred saving coupon code. Please try again.');
    }
  };

  const deleteCode = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('coupon-codes').doc(couponCodes[deleteIndex].id).delete();

      const codesCopy = [ ...couponCodes ];

      await firebase.firestore().collection('counters').doc('coupon-codes').update({
        count: firebase.firestore.FieldValue.increment(-1),
      });

      codesCopy.splice(deleteIndex, 1);
      setCouponCodes(codesCopy);
      setLoading(false);
      setDeleteIndex(-1);

      if (searchTerm) {
        setSearchTerm('');
        fetchCouponCodes();
      }
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      console.log(e);
      window.alert('An error occurred deleting the Coupon Code. Refresh the page and try again.');
    }
  };

  return (
    <div className="CouponCodes">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Coupon Codes
          </Typography>

          {searchTerm ?
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                fetchCouponCodes();
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div> :
            <div>
              <IconButton edge="start" color="inherit" onClick={() => {
                const expirationDate = new Date();
                expirationDate.setHours(23, 59, 59, 999);

                setEditCode({
                  code: '',
                  active: false,
                  description: '',
                  type: codeTypes[0],
                  amount: 5,
                  freeShipping: false,
                  hasExpirationDate: false,
                  expirationDate,
                  minCartSubtotal: '',
                  maxCartSubtotal: '',
                  canBeUsedWithOtherCoupons: false,
                  excludeOnSaleProducts: false,
                  includedProducts: [],
                  excludedProducts: [],
                  includedProductCategories: [],
                  excludedProductCategories: [],
                  allowedEmails: [],
                  usageLimitPerCoupon: '',
                  timesUsed: 0,
                  limitToNumberOfItems: '',
                  usageLimitPerUser: '',
                  created: Date.now(),
                  updated: Date.now(),
                });
                setEditIndex(-1);
                setEditing(true);
              }} aria-label="Delete">
                <AddIcon />
              </IconButton>

              <IconButton style={{marginLeft: 10}} edge="start" color="inherit" onClick={() => {
                setSearchText('');
                setSearchOpen(true);
              }} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm && !couponCodes.length ?
          <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
            No results match your search
          </div> : null
        }

        <List>
          {couponCodes.map((code, i) => {
            return (
              <div key={`coupon-code-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={code.code}
                    secondary={
                      <span>
                        Status:
                        <span
                          className="code-detail-spacing"
                          style={{color: code.active ? 'rgb(126, 210, 66)' : 'red'}}>
                            {code.active ? ' Active' : ' Disabled'}
                        </span>
                        <span className="code-detail-spacing">
                          Times Used: {code.timesUsed}
                        </span>
                        {code.hasExpirationDate ?
                          <span className="code-detail-spacing">
                            Expiration Date: {code.expirationDate ? moment(new Date(code.expirationDate)).format('MMMM Do, YYYY') : 'None Specified'}
                          </span> : 
                          null
                        }
                        <span className="code-detail-spacing">
                          ID: {code.id}
                        </span>
                      </span>
                    }
                  />
                  <IconButton edge="start" style={{marginRight: 15}} color="inherit" onClick={() => {
                    setEditCode(code);
                    setEditIndex(i);
                    setEditing(true);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>

        <Divider/>

        {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog fullScreen open={editing} onClose={() => {
        setEditing(false);
        setEditIndex(-1);
      }} TransitionComponent={Transition}>
        <EditCouponCode
          closeModal={() => {
            setEditing(false);
            setEditIndex(-1);
          }}
          save={saveCode}
          code={editCode}
          simplifiedProducts={simplifiedProducts}
          productCategories={productCategories}
          isEdit={editIndex !== -1}
        ></EditCouponCode>
      </Dialog>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Codes</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Coupon Code?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this coupon code?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteCode} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CouponCodes;
