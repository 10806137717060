import React from 'react';

function Welcome(props) {
  return (
    <div style={{marginTop: 70, marginLeft: 10}}>
      <p style={{marginBottom: 3}}><strong>Welcome to the Pupford Dashboard.</strong></p>
      <p style={{marginTop: 0}}>Select a tab from the left to get started.</p>
    </div>
  );
}

export default Welcome;
