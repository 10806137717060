import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const itemTypes = ['title', 'subtitle', 'paragraph', 'button', 'image', 'video', 'textOverlayImage', 'review'];
const simpleTypes = ['title', 'subtitle', 'paragraph'];

function AcademyLandingModal(props) {
  const [content, setContent] = useState(props.landingPageContent);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);

  const addDetail = (type) => {
    const contentCopy = [ ...content ];

    const details = {
      type,
      value: '',
    };

    if (simpleTypes.includes(type)) {
      details.textAlign = 'left';
    }

    if (type === 'image') {
      details.marginBottom = 0;
      details.marginTop = 0;
      details.width = 0;
    }

    if (type === 'video') {
      details.thumbnail = '';
      details.autoplay = false;
      details.muted = false;
    }

    if (type === 'textOverlayImage') {
      details.title = '';
      details.paragraph = '';
      details.textAlign = 'center';
      details.hasOverlayFilter = false;
      details.overlayFilterColor = '';
      details.textColor = '';
    }

    if (type === 'review') {
      details.name = '';
    }

    contentCopy.push(details);

    setContent(contentCopy);
  };

  const handleItemChange = (e, i, type) => {
    const contentCopy = [ ...content ];
    const itemCopy = {
      ...contentCopy[i],
      [type]: e.target.value,
    };

    contentCopy[i] = itemCopy;

    setContent(contentCopy);
  };

  const renderItem = (item, i) => {
    if (simpleTypes.includes(item.type)) {
      return (
        <div style={{display: 'flex', flex: 1}}>
          <TextField
            style={{marginRight: 20}}
            label={`${item.type.toUpperCase()} TEXT`}
            value={item.value}
            onChange={(e) => { handleItemChange(e, i, 'value') }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <FormControl style={{ width: '49%', marginRight: '2%' }}>
            <InputLabel id="product-variation-stock">
              Align Text
            </InputLabel>
            <Select
              value={item.textAlign}
              onChange={(e) => { handleItemChange(e, i, 'textAlign') }}
            >
              <MenuItem value={'left'}>Left</MenuItem>
              <MenuItem value={'center'}>Center</MenuItem>
              <MenuItem value={'right'}>Right</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    }

    if (item.type === 'button') {
      return (
        <div style={{display: 'flex', flex: 1}}>
          <TextField
            style={{marginRight: 20}}
            label={`${item.type.toUpperCase()} TEXT`}
            value={item.value}
            onChange={(e) => { handleItemChange(e, i, 'value') }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
      );
    }

    if (item.type === 'image') {
      return (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginRight: 20}}>
          <div style={{width: '100%'}}>
            <TextField
              label="IMAGE URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, 'value') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <TextField
              style={{marginRight: 20}}
              label="MARGIN TOP"
              value={item.marginTop}
              onChange={(e) => { handleItemChange(e, i, 'marginTop') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{marginRight: 20}}
              label="MARGIN BOTTOM"
              value={item.marginBottom}
              onChange={(e) => { handleItemChange(e, i, 'marginBottom') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
            <TextField
              label="IMAGE WIDTH"
              value={item.width}
              onChange={(e) => { handleItemChange(e, i, 'width') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>
      );
    }

    if (item.type === 'video') {
      return (
        <div style={{flex: 1}}>
          <div style={{display: 'flex', flex: 1}}>
            <TextField
              style={{marginRight: 20}}
              label="VIDEO URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, 'value') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{marginRight: 20}}
              label="VIDEO THUMBNAIL"
              value={item.thumbnail}
              onChange={(e) => { handleItemChange(e, i, 'thumbnail') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
          </div>
          <div style={{marginRight: 20}}>
            <FormControlLabel
              control={
                <Switch
                  checked={item.autoplay}
                  onChange={(e) => {
                    const contentCopy = [ ...content ];
                    const itemCopy = {
                      ...contentCopy[i],
                      autoplay: e.target.checked,
                    };

                    contentCopy[i] = itemCopy;

                    setContent(contentCopy);
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Autoplay"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={item.muted}
                  onChange={(e) => {
                    const contentCopy = [ ...content ];
                    const itemCopy = {
                      ...contentCopy[i],
                      muted: e.target.checked,
                    };

                    contentCopy[i] = itemCopy;

                    setContent(contentCopy);
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Muted"
            />
          </div>
        </div>
      );
    }

    if (item.type === 'textOverlayImage') {
      return (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <TextField
              style={{marginRight: 20}}
              label="IMAGE URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, 'value') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
            <FormControl style={{ width: '49%', marginRight: 20 }}>
              <InputLabel id="product-variation-stock">
                Align Text
              </InputLabel>
              <Select
                value={item.textAlign}
                onChange={(e) => { handleItemChange(e, i, 'textAlign') }}
              >
                <MenuItem value={'center'}>Center</MenuItem>
                <MenuItem value={'left'}>Left</MenuItem>
                <MenuItem value={'right'}>Right</MenuItem>
                <MenuItem value={'top'}>Top</MenuItem>
                <MenuItem value={'bottom'}>Bottom</MenuItem>
                <MenuItem value={'top-left'}>Top Left</MenuItem>
                <MenuItem value={'top-right'}>Top Right</MenuItem>
                <MenuItem value={'bottom-left'}>Bottom Left</MenuItem>
                <MenuItem value={'bottom-right'}>Bottom Right</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <TextField
              style={{marginRight: 20}}
              label="TITLE TEXT"
              value={item.title}
              onChange={(e) => { handleItemChange(e, i, 'title') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{marginRight: 20}}
              label="Text Color (use hex, rgb or rgba)"
              value={item.textColor}
              onChange={(e) => { handleItemChange(e, i, 'textColor') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <TextField
              style={{marginRight: 20}}
              label="PARAGRAPH TEXT"
              value={item.paragraph}
              onChange={(e) => { handleItemChange(e, i, 'paragraph') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <FormControlLabel
              control={
                <Switch
                  checked={item.hasOverlayFilter}
                  onChange={(e) => {
                    const contentCopy = [ ...content ];
                    const itemCopy = {
                      ...contentCopy[i],
                      hasOverlayFilter: e.target.checked,
                    };
                
                    contentCopy[i] = itemCopy;
                
                    setContent(contentCopy);
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enable Overlay Filter"
            />
            {!item.hasOverlayFilter ? null : <TextField
              style={{marginRight: 20}}
              label="OVERLAY FILTER COLOR (use rgba, e.g. rgba(0, 0, 0, 0.5))"
              value={item.overlayFilterColor}
              onChange={(e) => { handleItemChange(e, i, 'overlayFilterColor') }}
              variant="outlined"
              type="text"
              className="day-text-field"
            />}
          </div>
        </div>
      );
    }

    if (item.type === 'review') {
      return (
        <div style={{flex: 1}}>
          <TextField
            style={{marginRight: 20}}
            label="Review Text"
            value={item.value}
            onChange={(e) => { handleItemChange(e, i, 'value') }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
          <TextField
            style={{marginRight: 20, marginTop: 20}}
            label="Reviewer Name"
            value={item.name}
            onChange={(e) => { handleItemChange(e, i, 'name') }}
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </div>
      );
    }
  };

  const moveItemDown = (i) => {
    const contentCopy = [ ...content ];
    const itemToMoveDown = content[i];
    const itemToMoveUp = content[i + 1];

    contentCopy[i + 1] = itemToMoveDown;
    contentCopy[i] = itemToMoveUp;

    setContent(contentCopy);
  };

  const moveItemUp = (i) => {
    const contentCopy = [ ...content ];
    const itemToMoveDown = content[i - 1];
    const itemToMoveUp = content[i];

    contentCopy[i - 1] = itemToMoveUp;
    contentCopy[i] = itemToMoveDown;

    setContent(contentCopy);
  };

  const renderReorderArrows = (i) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemUp(i) }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    } else {
      arrowItems.push(<div key={`up-arrow-${i}`} style={{width: 48, display: 'inline-block'}}></div>);
    }

    if (i !== content.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemDown(i) }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    } else {
      arrowItems.push(<div key={`down-arrow-${i}`} style={{width: 48, display: 'inline-block'}}></div>);
    }

    return arrowItems;
  };

  const deleteSection = () => {
    const contentCopy = [ ...content ];

    contentCopy.splice(deleteIndex, 1);

    setContent(contentCopy);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteIndex(-1);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-confirmation' : undefined;

  return (
    <div>
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{padding: '10px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Delete Item?</div>
          <Button 
            variant="contained"
            color="secondary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { deleteSection() }}
          >
            Delete
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginBottom: '5px'}}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Popover>

      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.closeModal() }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Academy Landing Page
          </Typography>
          <Button color="inherit" onClick={() => { props.saveContent(content) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div style={{padding: 20, paddingTop: 0, marginBottom: 20}}>
        <div style={{marginTop: 20, marginBottom: 20}}>
          {itemTypes.map((type, i) => {
            return (
              <Button 
                variant="contained"
                color="primary"
                style={{marginRight: '10px', marginBottom: '5px'}}
                onClick={() => { addDetail(type) }}
                key={`button-${type}`}
              >
                Add {type === 'textOverlayImage' ? 'Text Overlay Image' : type}
              </Button>
            );
          })}
        </div>

        <List>
          {content.map((item, i) => {
            return (
              <div key={`product-item-${i}`}>
                <Divider />

                <h4>{item.type === 'textOverlayImage' ? 'TEXT OVERLAY IMAGE' : item.type.toUpperCase()}</h4>

                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {renderItem(item, i)}
                  <div>
                    {renderReorderArrows(i)}
                    <IconButton onClick={(event) => {
                      setDeleteIndex(i);
                      setAnchorEl(event.currentTarget);
                    }} edge="start" color="inherit" aria-label="Edit">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>
    </div>
  );
}

export default AcademyLandingModal;
