import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Input,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'react-datepicker';
import ChipInput from 'material-ui-chip-input';

import 'react-datepicker/dist/react-datepicker.css';

import DeletableChip from '../../../shared/deletableChip';
import './editCouponCode.scss';
import { codeTypes } from '../codeConfig';

const couponCodePlaceholder = 
`couponcode1
couponcode2
couponcode3
couponcode4
couponcode5`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditCouponCode(props) {
  const [code, setCode] = useState(props.code);

  const handleChange = (e, type) => {
    setCode({
      ...code,
      [type]: e.target.value,
    });
  };

  const handleErrorMessageChange = (e, type) => {
    setCode({
      ...code,
      customErrorMessages: {
        ...code.customErrorMessages,
        [type]: e.target.value,
      }
    });
  };

  const handleChecked = (e, type) => {
    setCode({
      ...code,
      [type]: e.target.checked,
    });
  };

  return (
    <div className="EditCouponCode">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {props.isEdit ? (code.code || '') : 'Create Codes'}
          </Typography>
          <Button color="inherit" onClick={() => { props.save(code) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container">
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={code.active}
                onChange={(e) => { handleChecked(e, 'active') }}
                name="active"
                color="primary"
              />
            }
            label="Active"
          />
        </div>

        <div style={{marginTop: 20}}>
          <FormControlLabel
            control={
              <Switch
                checked={code.disableOnWebsite || false}
                onChange={(e) => { handleChecked(e, 'disableOnWebsite') }}
                name="disableOnWebsite"
                color="primary"
              />
            }
            label="Disable on Website"
          />
        </div>

        <div style={{marginTop: 20}}>
          <FormControlLabel
            control={
              <Switch
                checked={code.canBeUsedWithOtherCoupons}
                onChange={(e) => { handleChecked(e, 'canBeUsedWithOtherCoupons') }}
                name="canBeUsedWithOtherCoupons"
                color="primary"
              />
            }
            label="Can Be Used With Other Coupons"
          />
        </div>

        <div style={{marginTop: 20}}>
          <FormControlLabel
            control={
              <Switch
                checked={code.excludeOnSaleProducts}
                onChange={(e) => { handleChecked(e, 'excludeOnSaleProducts') }}
                name="excludeOnSaleProducts"
                color="primary"
              />
            }
            label="Exclude Products That Are On Sale"
          />
        </div>

        <div style={{marginTop: 20}}>
          <FormControlLabel
            control={
              <Switch
                checked={code.hasExpirationDate}
                onChange={(e) => { handleChecked(e, 'hasExpirationDate') }}
                name="hasExpirationDate"
                color="primary"
              />
            }
            label="Has Expiration Date"
          />

          {!code.hasExpirationDate ? null :
            <div className="date-picker-container">
              <DatePicker
                selected={code.expirationDate}
                onChange={(expirationDate) => {
                  setCode({
                    ...code,
                    expirationDate,
                  });
                }}
              />
            </div>
          }
        </div>

        {props.isEdit ?
          <TextField
            style={{marginTop: 20}}
            label="Coupon Code"
            value={code.code}
            onChange={(e) => {
              setCode({
                ...code,
                code: e.target.value.toLowerCase(),
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          /> :
          <TextField
            style={{marginTop: 20}}
            label="Coupon Codes"
            value={code.code}
            onChange={(e) => {
              setCode({
                ...code,
                code: e.target.value.toLowerCase(),
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            placeholder={couponCodePlaceholder}
            multiline
            rows={20}
          />
        }

        <TextField
          style={{marginTop: 20}}
          label="Description"
          value={code.description}
          onChange={(e) => { handleChange(e, 'description') }}
          variant="outlined"
          margin="dense"
          type="text"
          multiline
          rows={4}
          className="day-text-field"
        />

        <div style={{marginTop: 20}}>
          <FormControl margin="dense" variant="outlined" style={{marginRight: 20}}>
            <InputLabel id="featured-product-select">Discount type</InputLabel>
            <Select
              labelId="featured-product-select"
              value={code.type}
              onChange={(e) => { handleChange(e, 'type') }}
              label="Discount type"
            >
              {codeTypes.map((type, i) => {
                return <MenuItem key={`code-types-${type}`} value={type}>{type}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, marginRight: 10}}>
            <InputLabel>Coupon Amount</InputLabel>
            <OutlinedInput
              value={code.amount}
              onChange={(e) => { handleChange(e, 'amount') }}
              margin="dense"
              label="Coupon Amount"
              variant="outlined"
              type="number"
              startAdornment={code.type.toLowerCase().includes('percent') ? false : <InputAdornment position="start">$</InputAdornment>}
              endAdornment={!code.type.toLowerCase().includes('percent') ? false : <InputAdornment position="start">%</InputAdornment>}
              style={{maxWidth: 180, marginRight: 15}}
            />
          </FormControl>

          <FormControlLabel
            style={{marginTop: 8, marginBottom: 4}}
            control={
              <Switch
                checked={code.freeShipping}
                onChange={(e) => { handleChecked(e, 'freeShipping') }}
                name="freeShipping"
                color="primary"
              />
            }
            label="Free shipping"
          />

          <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, marginRight: 10}}>
            <InputLabel>Minimum Cart Subtotal</InputLabel>
            <OutlinedInput
              value={code.minCartSubtotal}
              onChange={(e) => { handleChange(e, 'minCartSubtotal') }}
              margin="dense"
              label="Minimum Cart Subtotal"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              style={{maxWidth: 180, marginRight: 15}}
            />
          </FormControl>

          <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, marginRight: 10}}>
            <InputLabel>Maximum Cart Subtotal</InputLabel>
            <OutlinedInput
              value={code.maxCartSubtotal}
              onChange={(e) => { handleChange(e, 'maxCartSubtotal') }}
              margin="dense"
              label="Maximum Cart Subtotal"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              style={{maxWidth: 180, marginRight: 15}}
            />
          </FormControl>

          <TextField
            style={{marginRight: 10}}
            label="Usage Limit Per Coupon"
            value={code.usageLimitPerCoupon}
            onChange={(e) => { handleChange(e, 'usageLimitPerCoupon') }}
            variant="outlined"
            margin="dense"
            type="number"
          />

          {/* {!(code.type === 'Percentage discount' || code.type === 'Fixed product discount') ? null : <TextField
            style={{marginRight: 10}}
            label="Limit to Number of Items"
            value={code.limitToNumberOfItems}
            onChange={(e) => { handleChange(e, 'limitToNumberOfItems') }}
            variant="outlined"
            margin="dense"
            type="number"
          />} */}

          <TextField
            style={{marginRight: 10}}
            label="Usage Limit Per User"
            value={code.usageLimitPerUser}
            onChange={(e) => { handleChange(e, 'usageLimitPerUser') }}
            variant="outlined"
            margin="dense"
            type="number"
          />
        </div>

        {code.type !== 'Subscription discount percent' ? null :
          <div className="section-container">
            <div className="section-label">Subscription Options</div>

            <FormControlLabel
              control={
                <Switch
                  checked={code.isIntroOffer || false}
                  onChange={(e) => {
                    handleChecked(e, 'isIntroOffer');
                    const updatedCode = {
                      ...code,
                      isIntroOffer: e.target.checked,
                    };

                    if (e.target.checked && !code.introOfferNumberOfIntervals) {
                      updatedCode.introOfferNumberOfIntervals = 1;
                    }

                    setCode(updatedCode);
                  }}
                  name="isIntroOffer"
                  color="primary"
                />
              }
              label="Is Intro Offer"
            />

            {!code.isIntroOffer ? null :
              <>
                <div style={{marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormControl margin="dense" variant="outlined" style={{ width: '200px', marginRight: 20 }}>
                    <InputLabel id="length-of-intro-offer-select">Length of Intro Offer</InputLabel>
                    <Select
                      labelId="length-of-intro-offer-select"
                      value={code.introOfferNumberOfIntervals}
                      onChange={(e) => { handleChange(e, 'introOfferNumberOfIntervals') }}
                      label="Length of Intro Offer"
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => {
                        return <MenuItem key={`length-of-intro-offer-${num}`} value={num}>{num}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                  <Typography>Intervals</Typography>
                </div>
              </>
            }
          </div>
        }

        <FormControl style={{width: '100%', marginTop: 20}}>
          <InputLabel>Included Products</InputLabel>
          <Select
            multiple
            value={code.includedProducts}
            onChange={(e) => { handleChange(e, 'includedProducts') }}
            input={<Input />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value.id}
                    label={value.name}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = code.includedProducts.indexOf(value);

                      if (index === -1) {
                        return;
                      }

                      const products = [ ...code.includedProducts ];

                      products.splice(index, 1);

                      setCode({
                        ...code,
                        includedProducts: products,
                      });
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.simplifiedProducts.filter(product => {
              const found = code.includedProducts.find(p => p.id === product.id);
              return !found;
            }).map(product => (
              <MenuItem key={product.id} value={product}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{width: '100%', marginTop: 20}}>
          <InputLabel>Excluded Products</InputLabel>
          <Select
            multiple
            value={code.excludedProducts}
            onChange={(e) => { handleChange(e, 'excludedProducts') }}
            input={<Input />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value.id}
                    label={value.name}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = code.excludedProducts.indexOf(value);

                      if (index === -1) {
                        return;
                      }

                      const products = [ ...code.excludedProducts ];

                      products.splice(index, 1);

                      setCode({
                        ...code,
                        excludedProducts: products,
                      });
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.simplifiedProducts.filter(product => {
              const found = code.excludedProducts.find(p => p.id === product.id);
              return !found;
            }).map(product => (
              <MenuItem key={product.id} value={product}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{width: '100%', marginTop: 20}}>
          <InputLabel>Included Product Categories</InputLabel>
          <Select
            multiple
            value={code.includedProductCategories}
            onChange={(e) => { handleChange(e, 'includedProductCategories') }}
            input={<Input />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value}
                    label={value}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = code.includedProductCategories.indexOf(value);

                      if (index === -1) {
                        return;
                      }

                      const products = [ ...code.includedProductCategories ];

                      products.splice(index, 1);

                      setCode({
                        ...code,
                        includedProductCategories: products,
                      });
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.productCategories.filter(category => {
              return !code.includedProductCategories.includes(category);
            }).map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{width: '100%', marginTop: 20}}>
          <InputLabel>Excluded Product Categories</InputLabel>
          <Select
            multiple
            value={code.excludedProductCategories}
            onChange={(e) => { handleChange(e, 'excludedProductCategories') }}
            input={<Input />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value}
                    label={value}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = code.excludedProductCategories.indexOf(value);

                      if (index === -1) {
                        return;
                      }

                      const products = [ ...code.excludedProductCategories ];

                      products.splice(index, 1);

                      setCode({
                        ...code,
                        excludedProductCategories: products,
                      });
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.productCategories.filter(category => {
              return !code.excludedProductCategories.includes(category);
            }).map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ChipInput
          style={{marginTop: 20}}
          defaultValue={code.allowedEmails}
          onChange={(chips) => {
            setCode({
              ...code,
              allowedEmails: chips,
            });
          }}
          margin="dense"
          allowDuplicates={false}
          fullWidth={true}
          label="Allowed Emails"
          helperText='Press "enter" after each email address as you type'
        />

        <div style={{marginTop: 40}}>
          <FormControlLabel
            control={
              <Switch
                checked={code.enableCustomErrorMessages || false}
                onChange={(e) => {
                  const updatedCode = {
                    ...code,
                    enableCustomErrorMessages: e.target.checked,
                  };

                  updatedCode.customErrorMessages = !e.target.checked ? null : {
                    active: '',
                    signIn: '',
                    allowedEmails: '',
                    canBeUsedWithOtherCoupons: '',
                    hasExpirationDate: '',
                    maxCartSubtotal: '',
                    minCartSubtotal: '',
                    usageLimitPerCoupon: '',
                    usageLimitPerUser: '',
                  };

                  setCode(updatedCode);
                }}
                name="enableCustomErrorMessages"
                color="primary"
              />
            }
            label="Enable Custom Error Messages"
          />
        </div>

        {!code.enableCustomErrorMessages ? null :
          <>
            <TextField
              label="Inactive Error Message"
              value={code.customErrorMessages.active}
              onChange={(e) => { handleErrorMessageChange(e, 'active') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Sign In Error Message"
              value={code.customErrorMessages.signIn}
              onChange={(e) => { handleErrorMessageChange(e, 'signIn') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Allowed Emails Error Message"
              value={code.customErrorMessages.allowedEmails}
              onChange={(e) => { handleErrorMessageChange(e, 'allowedEmails') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Other Coupons Error Message"
              value={code.customErrorMessages.canBeUsedWithOtherCoupons}
              onChange={(e) => { handleErrorMessageChange(e, 'canBeUsedWithOtherCoupons') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Expired Error Message"
              value={code.customErrorMessages.hasExpirationDate}
              onChange={(e) => { handleErrorMessageChange(e, 'hasExpirationDate') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Max Cart Subtotal Error Message"
              value={code.customErrorMessages.maxCartSubtotal}
              onChange={(e) => { handleErrorMessageChange(e, 'maxCartSubtotal') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Min Cart Subtotal Error Message"
              value={code.customErrorMessages.minCartSubtotal}
              onChange={(e) => { handleErrorMessageChange(e, 'minCartSubtotal') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Usage Limit Per Coupon Error Message"
              value={code.customErrorMessages.usageLimitPerCoupon}
              onChange={(e) => { handleErrorMessageChange(e, 'usageLimitPerCoupon') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Usage Limit Per User Error Message"
              value={code.customErrorMessages.usageLimitPerUser}
              onChange={(e) => { handleErrorMessageChange(e, 'usageLimitPerUser') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </>
        }
      </div>
    </div>
  );
}

export default EditCouponCode;
