export const formInputTypes = [
  'text-input',
  'date',
  'select-dropdown',
  'multi-select',
  'single-select',
  'rating',
  'file-upload',
];

export const textInputStates = [
  'is-equal-to',
  'is-not-equal-to',
  'contains',
  'does-not-contain',
  'starts-with',
  'does-not-start-with',
  'ends-with',
  'does-not-end-with',
  'is-empty',
  'is-not-empty',
];

export const multiSelectStates = [
  'includes',
  'does-not-include',
  'is-empty',
  'is-not-empty',
];

export const ratingStates = [
  'is-equal-to',
  'is-not-equal-to',
  'is-less-than',
  'is-greater-than',
  'is-less-than-or-equal-to',
  'is-greater-than-or-equal-to',
];

export const fileUploadStates = [
  'is-empty',
  'is-not-empty',
];

export const dateUploadStates = [
  ...ratingStates,
  ...fileUploadStates,
];

export const valueFields = [
  'is-equal-to',
  'is-not-equal-to',
  'contains',
  'does-not-contain',
  'starts-with',
  'does-not-start-with',
  'ends-with',
  'does-not-end-with',
  'is-less-than',
  'is-greater-than',
  'is-less-than-or-equal-to',
  'is-greater-than-or-equal-to',
  'includes',
  'does-not-include',
];

export const formTypesDataMap = {
  heading: {
    text: '',
    fontSize: 24,
    fontWeight: 'bold',
  },
  paragraph: {
    text: '',
    fontSize: 16,
    fontWeight: 'normal',
  },
  'text-input': {
    label: '',
    placeholder: '',
    helperText: '',
    required: false,
    inputType: 'text',
    saveAnswerAsVariable: false,
    variableName: '',
  },
  date: {
    label: '',
    helperText: '',
    required: false,
    saveAnswerAsVariable: false,
    variableName: '',
  },
  'select-dropdown': {
    label: '',
    helperText: '',
    options: [],
    required: false,
    saveAnswerAsVariable: false,
    variableName: '',
  },
  'multi-select': {
    label: '',
    helperText: '',
    options: [],
    required: false,
  },
  'single-select': {
    label: '',
    helperText: '',
    options: [],
    required: false,
    saveAnswerAsVariable: false,
    variableName: '',
  },
  rating: {
    label: '',
    helperText: '',
    iconType: 'star',
    outOf: 5,
    required: false,
    saveAnswerAsVariable: false,
    variableName: '',
  },
  'file-upload': {
    label: '',
    helperText: '',
    maxFiles: 5,
    required: false,
  },
};
