import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import { red } from '@mui/material/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './groupPost.scss';
import { config } from '../../../../../config';
import GroupPostMenu from '../groupPostMenu';
import GroupPostCommentMenu from './groupPostCommentMenu/groupPostCommentMenu';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GroupPost({ history, match }) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentCommunityUser, setCurrentCommunityUser] = useState({});
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [parentCommentId, setParentCommentId] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [showCreateCommunityUserProfileModal, setShowCreateCommunityUserProfileModal] = useState(false);
  const [createCommunityUserName, setCreateCommunityUserName] = useState('');
  const [createCommunityUserProfilePictureUrl, setCreateCommunityUserProfilePictureUrl] = useState('');
  const [commentToUpdateStatus, setCommentToUpdateStatus] = useState({});

  useEffect(() => {
    const getPostDetails = async () => {
      setLoading(true);

      try {
        const groupId = match.params.id;
        const postId = match.params.postId;

        if (groupId === undefined) {
          history.replace('/community/groups');
          return;
        }

        if (postId === undefined) {
          history.replace(`/community/groups/${groupId}/posts`);
          return;
        }

        const token = await firebase.auth().currentUser.getIdToken();
        const postResponse = await axios(`${config.gateway}/community-service/api/v1/posts/${postId}/admin`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!postResponse.data.id) {
          history.replace(`/community/groups/${groupId}/posts`);
          return;
        }

        const commentsResponse = await axios(`${config.gateway}/community-service/api/v1/posts/${postId}/comments/admin`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const communityUserResponse = await axios(`${config.gateway}/community-service/api/v1/users/current-user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setPost(postResponse.data);
        setComments(commentsResponse.data);
        setCurrentCommunityUser(communityUserResponse.data || {});
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching post content. Refresh the page and try again.');
      }
    };

    getPostDetails();
  }, []);

  const getTotalComments = (data, comments) => {
    comments.forEach(comment => {
      data.count += 1;

      if (comment.replies) {
        getTotalComments(data, comment.replies);
      }
    });

    return data.count;
  };

  const updateCommentStatus = (commentId, status, comments) => {
    for (let comment of comments) {
      if (comment.id === commentId) {
        comment.status = status;
        return;
      }

      updateCommentStatus(commentId, status, comment.replies);
    }
  };

  const submitUpdateCommentStatus = async () => {
    setLoading(true);

    try {
      const status = commentToUpdateStatus.status === 'live' ? 'disapproved' : 'live';
      const token = await firebase.auth().currentUser.getIdToken();
      const createdUserResponse = await axios(`${config.gateway}/community-service/api/v1/comments/${commentToUpdateStatus.id}/status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          status,
        },
      });

      const commentsCopy = cloneDeep(comments);

      updateCommentStatus(commentToUpdateStatus.id, status, commentsCopy);
      setComments(commentsCopy);
      setCommentToUpdateStatus({});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred updating the status of this comment, please try again.');
    }
  };

  const submitCreateCommunityUser = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const createdUserResponse = await axios(`${config.gateway}/community-service/api/v1/users/admin`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          name: createCommunityUserName,
          profilePictureUrl: createCommunityUserProfilePictureUrl,
        },
      });

      setCurrentCommunityUser(createdUserResponse.data);
      setShowCreateCommunityUserProfileModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred creating your community profile. Refresh the page and try again.');
    }
  };

  const addCommentReply = (commentId, reply, comments) => {
    for (let comment of comments) {
      if (comment.id === commentId) {
        comment.replies = [
          ...comment.replies,
          {
            ...reply,
            user: {
              name: currentCommunityUser.name,
              profilePictureUrl: currentCommunityUser.profilePictureUrl,
            },
            replies: [],
            _count: {
              likes: 0,
            },
          },
        ];
        return;
      }

      addCommentReply(commentId, reply, comment.replies);
    }
  };

  const submitComment = async () => {
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      if (!currentCommunityUser) {
        const uid = firebase.auth().currentUser.uid;
        const userSnapshot = await firebase.firestore().collection('users').doc(uid).get();
        const userData = userSnapshot.data();

        if (userData.userName && userData.profileImage && userData.profileImage.url) {
          const createdUserResponse = await axios(`${config.gateway}/community-service/api/v1/users/admin`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            data: {
              name: userData.userName,
              profilePictureUrl: userData.profileImage.url,
            },
          });

          setCurrentCommunityUser(createdUserResponse.data);
        } else {
          setLoading(false);
          setShowCreateCommunityUserProfileModal(true);
          return;
        }
      }

      const createdPostResponse = await axios(`${config.gateway}/community-service/api/v1/comments`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          postId: post.id,
          text: commentText,
          parentId: parentCommentId,
        },
      });

      const commentsCopy = cloneDeep(comments);
      const comment = createdPostResponse.data;

      addCommentReply(parentCommentId, comment, commentsCopy);
      setComments(commentsCopy);
      setParentCommentId(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred submitting this response, please try again.');
    }
  };

  const renderComments = (comments, numberOfParents) => {
    const marginLeft = !numberOfParents ? 0 :
      numberOfParents > 8 ? 0 : 20;

    return (
      <>
        {comments.map(comment => {
          console.log(comment);
          return (
            <div key={`comment-${comment.id}`} className="comment" style={{ marginLeft }}>
              <div className="comment-top-container">
                <div className="comment-image-container">
                  <Link to={`/manage-users/users/${comment.user.firebaseUserId || ''}`}>
                    {comment.user.profilePictureUrl ?
                      <img
                        src={comment.user.profilePictureUrl}
                        className="post-profile-picture"
                      /> :
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="user">
                        {comment.user.name[0]}
                      </Avatar>
                    }
                  </Link>
                </div>

                <div className="comment-top-details">
                  <Link to={`/manage-users/users/${comment.user.firebaseUserId || ''}`}>
                    <p className="comment-user-name">{comment.user.name}{!comment.user.isModerator ? null : ' - Moderator'}</p>
                  </Link>
                  <p className="comment-created-date">Posted {moment(comment.created).fromNow()}</p>
                </div>

                <GroupPostCommentMenu
                  isLive={comment.status === 'live'}
                  onStatusChangeClicked={() => setCommentToUpdateStatus(comment)}
                />
              </div>

              <div className="comment-text">{comment.text}</div>

              <div className="actions-row">
                <p
                  className="reply-button-text"
                  onClick={() => {
                    setCommentText('');
                    setParentCommentId(comment.id);
                  }}
                >
                  Reply
                </p>

                <p className="likes-text">Likes: {comment._count.likes}</p>
              </div>

              {renderComments(comment.replies, numberOfParents + 1)}
            </div>
          );
        })}
      </>
    );
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="GroupPost">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to={`/community/groups/${match.params.id}/posts`}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Group Posts
          </Button>
        </Link>
      </div>

      {!post.id ? null :
        <div className="content">
          <div className="post-container">
            <Card className="card">
              <CardHeader
                avatar={
                  <Link to={`/manage-users/users/${post.user.firebaseUserId || ''}`}>
                    {post.user.profilePictureUrl ?
                      <img
                        src={post.user.profilePictureUrl}
                        className="post-profile-picture"
                      /> :
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="user">
                        {post.user.name[0]}
                      </Avatar>
                    }
                  </Link>
                }
                action={
                  <GroupPostMenu
                    postId={post.id}
                    groupId={match.params.id}
                    isLive={post.status === 'live'}
                    onStatusChangeClicked={() => {
                      console.log('handle this');
                    }}
                    hideViewPost
                  />
                }
                title={
                  <Link to={`/manage-users/users/${post.user.firebaseUserId || ''}`}>
                    {post.user.name}
                  </Link>
                }
                subheader={moment(post.created).format('lll')}
              />

              {!post.text ? null :
                <>
                  <Divider/>
                  <CardContent>
                    <Typography variant="body2">
                      {post.text}
                    </Typography>
                  </CardContent>
                </>
              }

              {!(post.videoThumbnailUrl && post.videoUrl) ? null :
                <div className="video-container">
                  <iframe
                    src={`${post.videoUrl.replace('https://iframe.mediadelivery.net/play/', 'https://iframe.mediadelivery.net/embed/')}?autoplay=false&responsive=true`}
                    loading="lazy"
                    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              }

              {!post.imageUrl ? null :
                <CardMedia
                  component="img"
                  image={post.imageUrl}
                  alt={post.user.name}
                />
              }

              <Divider/>

              <CardActions>
                <div><strong>Comments:</strong> {post._count.comments}</div>

                <div><strong>Likes:</strong> {post._count.likes}</div>

                <div><strong>Status:</strong> <span className="post-status">{post.status.split('-').join(' ')}</span></div>
              </CardActions>

              <div className="comments-container">
                {renderComments(comments, 0)}
              </div>
            </Card>
          </div>
        </div>
      }

      <Dialog fullWidth maxWidth="sm" open={!!commentToUpdateStatus.id} onClose={() => setCommentToUpdateStatus({})} TransitionComponent={Transition}>
        <DialogTitle>{commentToUpdateStatus.status === 'live' ? 'Disapprove Comment?' : 'Mark Comment Live?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {commentToUpdateStatus.status === 'live' ?
              'Are you sure you want to disapprove this comment?' :
              'Are you sure you want to mark this comment as live?'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitUpdateCommentStatus} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => setCommentToUpdateStatus({})} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={showCreateCommunityUserProfileModal} onClose={() => setShowCreateCommunityUserProfileModal(false)} TransitionComponent={Transition}>
        <DialogTitle>Create Community Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>Create a community user profile. Once your profile has been created, you can submit your response.</DialogContentText>
          <TextField
            label="Display Name"
            value={createCommunityUserName}
            onChange={(e) => setCreateCommunityUserName(e.target.value)}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
          <TextField
            label="Profile Image URL"
            value={createCommunityUserProfilePictureUrl}
            onChange={(e) => setCreateCommunityUserProfilePictureUrl(e.target.value)}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitCreateCommunityUser}
            color="primary"
            disabled={!createCommunityUserName || !createCommunityUserProfilePictureUrl}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={() => setShowCreateCommunityUserProfileModal(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!parentCommentId} onClose={() => setParentCommentId(0)} TransitionComponent={Transition}>
        <DialogTitle>Reply to Comment</DialogTitle>
        <DialogContent>
          <TextField
            label="Response"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            multiline
            minRows="4"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitComment} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => setParentCommentId(0)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GroupPost;
