import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import './formInsights.scss';
import QuestionByQuestion from './questionByQuestion/questionByQuestion';
import FormResponses from './formResponses/formResponses';

function FormInsights({
  formInsights,
  startDate,
  endDate,
  formId,
}) {
  const [views, setViews] = useState(0);
  const [starts, setStarts] = useState(0);
  const [completions, setCompletions] = useState(0);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    setStarts(formInsights.starts || 0);
    setCompletions(formInsights.completions || 0);
    setViews(formInsights.views || 0);
    setQuestions(formInsights.questions || []);
  }, [formInsights]);

  return (
    <div className="FormInsights">
      <FormResponses
        startDate={startDate}
        endDate={endDate}
        formId={formId}
        numberOfStarts={starts}
      />

      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Views
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {views.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Starts
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {starts.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Submissions
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {completions.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Completion Rate
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {completions === 0 ? 0 : ((completions / starts) * 100).toFixed(1)}%
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <QuestionByQuestion
        questions={questions}
      />
    </div>
  );
}

export default FormInsights;
