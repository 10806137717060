import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Toolbar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Popover,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Section from './section';
import MultiSection from './multiSection';
import { config } from '../../../../config';

const cloneDeep = require('lodash.clonedeep');

function Module(props) {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [productModule, setProductModule] = useState({
    title: '',
    imageUrl: '',
    description: '',
    sections: [],
    categories: [''],
    backgroundImage: '',
    adId: '',
    adIdv2: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [nestedSectionDeleteIndex, setNestedSectionDeleteIndex] = useState(-1);
  const [collection, setCollection] = useState('');
  const [legacyAds, setLegacyAds] = useState([]);
  const [ads, setAds] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const collectionName = props.match.path.includes('free-courses') ? 'free-courses' : 'membership-content';
    setCollection(collectionName);
  }, [props.match.path]);

  useEffect(() => {
    if (collection) {
      async function fetchProduct() {
        const db = firebase.firestore();
        try {
          const doc = await db.collection(collection).doc(props.match.params.id).get();
          const product = doc.data();
          const productModule = product.modules[props.match.params.moduleIndex];
  
          if (!productModule) {
            if (collection === 'membership-content') {
              props.history.push(`/academy-content/member-content/${props.match.params.id}/details`);
            } else {
              props.history.push(`/academy-content/free-courses/${props.match.params.id}`);
            }
            return;
          }

          const categoryRef = await db.collection('categories').doc('behaviors').get();
          const categories = categoryRef.data();
          setCategories(categories.behaviors);

          if (collection === 'free-courses') {
            const legacyQuerySnapshot = await db.collection('course-completion-ads').doc('ads').get();
            const mappedLegacyAds = legacyQuerySnapshot.data().data.map(ad => {
              return {
                id: ad.id,
                internalName: ad.internalName,
              };
            });

            setLegacyAds(mappedLegacyAds);
            
            const querySnapshot = await db.collection('course-completion-ads').doc('ads-v2').get();
            const mappedAds = querySnapshot.data().data.map(ad => {
              return {
                id: ad.id,
                internalName: ad.internalName,
              };
            });

            setAds(mappedAds);
          }

          const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
          const products = shopSettingsSnapshot.data().simplifiedProducts.filter(p => {
            return !p.isDigital;
          }).map(p => {
            return {
              displayName: p.name || '',
              name: p.name,
              url: `https://${config.domain}.com/product/${p.path}/`,
              value: p.price || '',
              image: p.image ? p.image.url : '',
              id: p.id,
            };
          }).sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
  
          if ( !productModule.backgroundImage ) {
            productModule.backgroundImage = '';
          }

          setProductModule(cloneDeep(productModule));
          setProduct(product);
          setProducts(products);
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
      fetchProduct();
    }
  }, [collection]);

  const saveProduct = async () => {
    setLoading(true);
    
    try {
      const productCopy = cloneDeep(product);
      productCopy.modules[props.match.params.moduleIndex] = productModule;
      setProduct(productCopy);
      const db = firebase.firestore();
      await db.collection(collection).doc(product.id).update(productCopy);

      const token = await firebase.auth().currentUser.getIdToken();
      const method = productCopy.enabled ? 'POST' : 'DELETE';
      await axios(`${config.gateway}/course-service/v1/courses/${product.id}/search`, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log('error', e);
    }

    setLoading(false);
  };

  const handleTextChange = (name) => event => {
    const productModuleCopy = cloneDeep(productModule);
    productModuleCopy[name] = event.target.value;
    setProductModule(productModuleCopy);
  };

  const addCategory = (category) => {
    const productModuleCopy = cloneDeep(productModule);
    productModuleCopy.categories = [category];
    setProductModule(productModuleCopy);
  }

  const addSection = (type) => {
    const productModuleCopy = cloneDeep(productModule);
    productModuleCopy.sections.push(type);
    setProductModule(productModuleCopy);
  };

  const addNestedSection = (i) => {
    const productModuleCopy = cloneDeep(productModule);
    productModuleCopy.sections[i].entries.push({ sectionTitle: 'Section Title', entries: [] });
    setProductModule(productModuleCopy);
  };

  const handleChange = (value, i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      productModuleCopy.sections[i].entries[j].entries[k].value = value;
    } else {
      productModuleCopy.sections[i].entries[j].value = value;
    }
    setProductModule(productModuleCopy);
  };

  const handleTitleChange = (value, i, j) => {
    const productModuleCopy = cloneDeep(productModule);
    if (j >= 0) {
      productModuleCopy.sections[i].entries[j].sectionTitle = value;
    } else {
      productModuleCopy.sections[i].sectionTitle = value;
    }
    setProductModule(productModuleCopy);
  };

  const handleSectionImageStyleChange = (value, type, i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      productModuleCopy.sections[i].entries[j].entries[k][type] = value;
    } else {
      productModuleCopy.sections[i].entries[j][type] = value;
    }
    setProductModule(productModuleCopy);
  };

  const handleVideoThumbnailChange = (value, i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      productModuleCopy.sections[i].entries[j].entries[k].thumbnail = value;
    } else {
      productModuleCopy.sections[i].entries[j].thumbnail = value;
    }
    setProductModule(productModuleCopy);
  };

  const addTextTrack = (i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    const newTextTrack = {
      title: "English CC",
      language: "en",
      type: 'text/vtt',
      uri: ''
    };

    if (k >= 0) {
      const textTracks = (productModuleCopy.sections[i].entries[j].entries[k].textTracks && productModuleCopy.sections[i].entries[j].entries[k].textTracks.length) ?
        [ ...productModuleCopy.sections[i].entries[j].entries[k].textTracks, newTextTrack ] :
        [ newTextTrack ];
      productModuleCopy.sections[i].entries[j].entries[k].textTracks = textTracks;
    } else {
      const textTracks = (productModuleCopy.sections[i].entries[j].textTracks && productModuleCopy.sections[i].entries[j].textTracks.length) ?
        [ ...productModuleCopy.sections[i].entries[j].textTracks, newTextTrack ] :
        [ newTextTrack ];
      productModuleCopy.sections[i].entries[j].textTracks = textTracks;
    }

    setProductModule(productModuleCopy);
  };

  const handleTextTrackFieldChange = (type, value, i, j, k, l) => {
    const productModuleCopy = cloneDeep(productModule);
    if (l >= 0) {
      productModuleCopy.sections[i].entries[j].entries[k].textTracks[l][type] = value;
    } else {
      productModuleCopy.sections[i].entries[j].textTracks[k][type] = value;
    }
    setProductModule(productModuleCopy);
  };

  const removeTextTrack = (i, j, k, l) => {
    const productModuleCopy = cloneDeep(productModule);
    if (l >= 0) {
      productModuleCopy.sections[i].entries[j].entries[k].textTracks.splice(l, 1);
    } else {
      productModuleCopy.sections[i].entries[j].textTracks.splice(k, 1);
    }
    setProductModule(productModuleCopy);
  };

  const addDetail = (type, i, j) => {
    const productModuleCopy = cloneDeep(productModule);
    const value = {type, value: ''};

    if (type === 'image') {
      value.width = '';
      value.marginTop = '';
      value.marginBottom = '';
    }

    if (type === 'video') {
      value.thumbnail = '';
    }

    if (type === 'products') {
      value.value = [];
    }

    if (j >= 0) {
      productModuleCopy.sections[i].entries[j].entries.push(value);
    } else {
      productModuleCopy.sections[i].entries.push(value);
    }
    setProductModule(productModuleCopy);
  };

  const removeSectionItem = (i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      productModuleCopy.sections[i].entries[j].entries.splice(k, 1);
    } else {
      productModuleCopy.sections[i].entries.splice(j, 1);
    }
    setProductModule(productModuleCopy);
  };

  const moveItemUp = (i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      const itemToMoveUp = productModuleCopy.sections[i].entries[j].entries[k];
      const itemToMoveDown = productModuleCopy.sections[i].entries[j].entries[k - 1];
      productModuleCopy.sections[i].entries[j].entries[k] = itemToMoveDown;
      productModuleCopy.sections[i].entries[j].entries[k - 1] = itemToMoveUp;
    } else {
      const itemToMoveUp = productModuleCopy.sections[i].entries[j];
      const itemToMoveDown = productModuleCopy.sections[i].entries[j - 1];
      productModuleCopy.sections[i].entries[j] = itemToMoveDown;
      productModuleCopy.sections[i].entries[j - 1] = itemToMoveUp;
    }
    setProductModule(productModuleCopy);
  };

  const moveItemDown = (i, j, k) => {
    const productModuleCopy = cloneDeep(productModule);
    if (k >= 0) {
      const itemToMoveUp = productModuleCopy.sections[i].entries[j].entries[k];
      const itemToMoveDown = productModuleCopy.sections[i].entries[j].entries[k + 1];
      productModuleCopy.sections[i].entries[j].entries[k] = itemToMoveDown;
      productModuleCopy.sections[i].entries[j].entries[k + 1] = itemToMoveUp;
    } else {
      const itemToMoveUp = productModuleCopy.sections[i].entries[j];
      const itemToMoveDown = productModuleCopy.sections[i].entries[j + 1];
      productModuleCopy.sections[i].entries[j] = itemToMoveDown;
      productModuleCopy.sections[i].entries[j + 1] = itemToMoveUp;
    }
    setProductModule(productModuleCopy);
  };

  const moveNestedSectionUp = (i, j) => {
    const productModuleCopy = cloneDeep(productModule);
    const itemToMoveUp = productModuleCopy.sections[i].entries[j];
    const itemToMoveDown = productModuleCopy.sections[i].entries[j - 1];
    productModuleCopy.sections[i].entries[j] = itemToMoveDown;
    productModuleCopy.sections[i].entries[j - 1] = itemToMoveUp;
    setProductModule(productModuleCopy);
  };

  const moveNestedSectionDown = (i, j) => {
    const productModuleCopy = cloneDeep(productModule);
    const itemToMoveUp = productModuleCopy.sections[i].entries[j];
    const itemToMoveDown = productModuleCopy.sections[i].entries[j + 1];
    productModuleCopy.sections[i].entries[j] = itemToMoveDown;
    productModuleCopy.sections[i].entries[j + 1] = itemToMoveUp;
    setProductModule(productModuleCopy);
  };

  const renderBreadcrumbs = () => {
    if (!product.name) {
      return null;
    }

    if (collection === 'membership-content') {
      return (
        <p>
          <Link to={`/academy-content/member-content`} style={{color: 'blue', textDecoration: 'none'}}>Academy Content</Link>
          <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
          <Link to={`/academy-content/member-content/${product.id}`} style={{color: 'blue', textDecoration: 'none'}}>{product.name}</Link>
          <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
          <Link to={`/academy-content/member-content/${props.match.params.id}/details`} style={{color: 'blue', textDecoration: 'none'}}>Modules</Link>
          <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
          {productModule.title}
        </p>
      );
    } else {
      return (
        <p>
          <Link to={`/academy-content/free-courses`} style={{color: 'blue', textDecoration: 'none'}}>Free Courses</Link>
          <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
          <Link to={`/academy-content/free-courses/${product.id}`} style={{color: 'blue', textDecoration: 'none'}}>{product.name}</Link>
          <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
          {productModule.title}
        </p>
      );
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const moveSectionUp = (i) => {    
    const productModuleCopy = cloneDeep(productModule);
    const itemToMoveUp = productModuleCopy.sections[i];
    const itemToMoveDown = productModuleCopy.sections[i - 1];
    productModuleCopy.sections[i] = itemToMoveDown;
    productModuleCopy.sections[i - 1] = itemToMoveUp;
    setProductModule(productModuleCopy);
  };

  const moveSectionDown = (i) => {
    const productModuleCopy = cloneDeep(productModule);
    const itemToMoveDown = productModuleCopy.sections[i];
    const itemToMoveUp = productModuleCopy.sections[i + 1];
    productModuleCopy.sections[i] = itemToMoveUp;
    productModuleCopy.sections[i + 1] = itemToMoveDown;
    setProductModule(productModuleCopy);
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveSectionUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveSectionDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const setNestedSectionDelete = (event, i, j) => {
    setDeleteIndex(i);
    setNestedSectionDeleteIndex(j);
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event, i) => {
    setDeleteIndex(i);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNestedSectionDeleteIndex(-1);
    setDeleteIndex(-1);
  };

  const deleteSection = () => {
    const productModuleCopy = cloneDeep(productModule);

    if (nestedSectionDeleteIndex === -1) {
      productModuleCopy.sections.splice(deleteIndex, 1);
    } else {
      productModuleCopy.sections[deleteIndex].entries.splice(nestedSectionDeleteIndex, 1);
    }

    setProductModule(productModuleCopy);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-confirmation' : undefined;

  const category = productModule.categories ? productModule.categories[0] : '';

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{padding: '10px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Delete Item?</div>
          <Button 
            variant="contained"
            color="secondary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { deleteSection() }}
          >
            Delete
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginBottom: '5px'}}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Popover>
      {renderLoading()}
      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        {renderBreadcrumbs()}
      </div>
      <Card className="drag-card" style={{marginTop: '10px', display: 'inline-block', textAlign: 'left'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {product.name && productModule.title ? `${product.name} - ${productModule.title}` : 'Loading Module...'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveProduct() }}
          >
            Save
          </Button>
        </Toolbar>

        <CardContent>
          <div style={{width: '100%', marginBottom: '24px'}}>
            <TextField
              style={{width: '100%'}}
              label="Module Title"
              value={productModule.title}
              onChange={handleTextChange('title')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Background Image"
              value={productModule.imageUrl}
              onChange={handleTextChange('imageUrl')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Header Image"
              value={productModule.backgroundImage}
              onChange={handleTextChange('backgroundImage')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Header Video (mobile)"
              value={productModule.headerVideo || ''}
              onChange={handleTextChange('headerVideo')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Description"
              value={productModule.description}
              onChange={handleTextChange('description')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />

            <TextField
              style={{width: '100%'}}
              label="Header Illustration URL"
              value={productModule.illustrationUrl || ''}
              onChange={handleTextChange('illustrationUrl')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />

            <FormControl variant="outlined" style={{width: '100%', marginTop: 15}}>
              <InputLabel id="primary-category">
                Primary Category
              </InputLabel>
              <Select
                label="Primary category"
                value={category}
                onChange={(event) => addCategory(event.target.value)}
              >
                {categories.map(category => {
                  return <MenuItem key={`category-${category}`} value={category}>{category}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {collection !== 'free-courses' ? null :
              <>
                <FormControl variant="outlined" style={{width: '100%', marginTop: 15}}>
                  <InputLabel id="completion-ads-legacy">
                    Ad to show on completion
                  </InputLabel>
                  <Select
                    label="Ad to show on completion"
                    value={productModule.adId || ''}
                    onChange={handleTextChange('adId')}
                  >
                    <MenuItem value="">None</MenuItem>
                    {legacyAds.map(ad => {
                      return <MenuItem key={ad.id} value={ad.id}>{ad.internalName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" style={{width: '100%', marginTop: 15}}>
                  <InputLabel id="completion-ads">
                    Completion Ad
                  </InputLabel>
                  <Select
                    label="Completion Ad"
                    value={productModule.adIdv2 || ''}
                    onChange={handleTextChange('adIdv2')}
                  >
                    <MenuItem value="">None</MenuItem>
                    {ads.map(ad => {
                      return <MenuItem key={ad.id} value={ad.id}>{ad.internalName}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}

                <div style={{marginBottom: 30, marginTop: 25}}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={productModule.showEntryNotification || false}
                          onChange={(e) => {
                            setProductModule({
                              ...productModule,
                              showEntryNotification: e.target.checked,
                            });
                          }}
                          name="showEntryNotification"
                          color="primary"
                        />
                      }
                      label="Show Notification When Entering Module"
                    />
                  </FormGroup>

                  {!productModule.showEntryNotification ? null :
                    <>
                      <TextField
                        style={{width: '100%'}}
                        label="Entry Notification Title"
                        value={productModule.entryNotificationTitle || ''}
                        onChange={handleTextChange('entryNotificationTitle')}
                        margin="normal"
                        variant="outlined"
                        className="day-text-field"
                      />
                      <TextField
                        style={{width: '100%'}}
                        label="Entry Notification Description"
                        value={productModule.entryNotificationDescription || ''}
                        onChange={handleTextChange('entryNotificationDescription')}
                        margin="normal"
                        variant="outlined"
                        className="day-text-field"
                      />
                    </>
                  }
                </div>
              </>
            }
          </div>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addSection({ sectionTitle: 'Section Title', entries: [{ sectionTitle: 'Section Title', entries: [] }]}) }}
            // onClick={() => { addSection([[{ type: 'Section Title', value: 'Section Title' }]]) }}
          >
            Add Section With Sections
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addSection({ sectionTitle: 'Section Title', entries: [] }) }}
          >
            Add Single Section
          </Button>
        </CardContent>

        {productModule.sections.map((section, i) => {
          if (!section.entries[0] || !section.entries[0].entries) {
            return (
              <Card key={`section-${i}`} style={{marginTop: 5, marginBottom: 5}}>
                <CardContent>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <h4>{section.sectionTitle}</h4>
                    <div style={{alignSelf: 'center', display: 'flex'}}>
                      {renderUpArrow(i)}

                      {renderDownArrow(i, productModule.sections.length - 1)}

                      <IconButton color="inherit" onClick={(e) => { handleClick(e, i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Section
                    section={section}
                    products={products}
                    handleChange={(value, index) => { handleChange(value, i, index) }}
                    handleTitleChange={(value) => { handleTitleChange(value, i) }}
                    handleSectionImageStyleChange={(value, index, type) => { handleSectionImageStyleChange(value, type, i, index) }}
                    handleVideoThumbnailChange={(value, index) => { handleVideoThumbnailChange(value, i, index) }}
                    addTextTrack={(index) => { addTextTrack(i, index) }}
                    handleTextTrackFieldChange={(type, value, j, k) => { handleTextTrackFieldChange(type, value, i, j, k) }}
                    removeTextTrack={(j, k) => { removeTextTrack(i, j, k) }}
                    addDetail={(type) => { addDetail(type, i) }}
                    removeSectionItem={(j) => { removeSectionItem(i, j) }}
                    moveItemUp={(j) => { moveItemUp(i, j) }}
                    moveItemDown={(j) => { moveItemDown(i, j) }}
                  />
                </CardContent>
              </Card>
            );
          }
          return (
            <Card key={`sections-${i}`} style={{marginTop: 5, marginBottom: 5}}>
              <CardContent>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <h4>{section.sectionTitle}</h4>
                  <div style={{alignSelf: 'center', display: 'flex'}}>
                    {renderUpArrow(i)}

                    {renderDownArrow(i, productModule.sections.length - 1)}

                    <IconButton color="inherit" onClick={(e) => { handleClick(e, i) }} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                <MultiSection
                  sectionTitle={section.sectionTitle}
                  sections={section.entries}
                  products={products}
                  handleSectionTitleChange={(value) => { handleTitleChange(value, i) }}
                  handleChange={(value, index, j) => { handleChange(value, i, index, j) }}
                  handleTitleChange={(value, index) => { handleTitleChange(value, i, index) }}
                  handleSectionImageStyleChange={(value, type, index, j) => { handleSectionImageStyleChange(value, type, i, index, j) }}
                  handleVideoThumbnailChange={(value, index, j) => { handleVideoThumbnailChange(value, i, index, j) }}
                  addTextTrack={(index, j) => { addTextTrack(i, index, j) }}
                  handleTextTrackFieldChange={(type, value, j, k, l) => { handleTextTrackFieldChange(type, value, i, j, k, l) }}
                  removeTextTrack={(j, k, l) => { removeTextTrack(i, j, k, l) }}
                  addSection={() => { addNestedSection(i) }}
                  addDetail={(type, j) => { addDetail(type, i, j) }}
                  removeSectionItem={(j, k) => { removeSectionItem(i, j, k) }}
                  moveItemUp={(j, k) => { moveItemUp(i, j, k) }}
                  moveItemDown={(j, k) => { moveItemDown(i, j, k) }}
                  moveNestedSectionUp={(j) => { moveNestedSectionUp(i, j) }}
                  moveNestedSectionDown={(j) => { moveNestedSectionDown(i, j) }}
                  setNestedSectionDelete={(e, j) => { setNestedSectionDelete(e, i, j) }}
                />
              </CardContent>
            </Card>
          );
        })}
      </Card>
    </div>
  );
}

export default Module;
