import React, { useEffect, useCallback, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { ColorPicker, useColor, toColor } from '../../shared/colorPalette';
import '../../shared/colorPalette/css/styles.css';
import {
  Typography,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  TextField,
  Popover,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

import './shopPage.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const productTypeKeys = [
  'pupFavorites',
  'trainProducts',
  'engageProducts',
  'rewardProducts',
  'healthProducts',
  'homeProducts',
];
const productTypeTitlesMap = {
  pupFavorites: 'Pup Favorite',
  engageProducts: 'Engage Product',
  healthProducts: 'Health Product',
  rewardProducts: 'Reward Product',
  trainProducts: 'Train Product',
  homeProducts: 'Home Product',
};

function ShopPage({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [shopPageData, setShopPageData] = useState(null);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addProductType, setAddProductType] = useState('');
  const [currentDealsBackgroundColor, setCurrentDealsBackgroundColor] =
    useColor('hex', '#ffb4ab');
  const [currentDealsFontColor, setCurrentDealsFontColor] = useColor(
    'hex',
    '#592e2c',
  );
  const [appCurrentDealsBackgroundColor, setAppCurrentDealsBackgroundColor] =
    useColor('hex', '#ffb4ab');
  const [appCurrentDealsFontColor, setAppCurrentDealsFontColor] = useColor(
    'hex',
    '#592e2c',
  );
  const [
    currentDealsBackgroundColorAnchorElem,
    setCurrentDealsBackgroundColorAnchorElem,
  ] = useState(null);
  const [currentDealsFontColorAnchorElem, setCurrentDealsFontColorAnchorElem] =
    useState(null);
  const [
    appCurrentDealsBackgroundColorAnchorElem,
    setAppCurrentDealsBackgroundColorAnchorElem,
  ] = useState(null);
  const [
    appCurrentDealsFontColorAnchorElem,
    setAppCurrentDealsFontColorAnchorElem,
  ] = useState(null);

  const fetchShopPageData = useCallback(async () => {
    try {
      const shopPageDataSnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('shop-page-data')
        .get();
      const shopSettingsSnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('shop-settings')
        .get();
      const shopPageData = shopPageDataSnapshot.data();

      if (shopPageData.currentDealsBackgroundColor) {
        setCurrentDealsBackgroundColor(
          toColor('hex', shopPageData.currentDealsBackgroundColor),
        );
      }

      if (shopPageData.currentDealsFontColor) {
        setCurrentDealsFontColor(
          toColor('hex', shopPageData.currentDealsFontColor),
        );
      }

      if (shopPageData.appCurrentDealsBackgroundColor) {
        setAppCurrentDealsBackgroundColor(
          toColor('hex', shopPageData.appCurrentDealsBackgroundColor),
        );
      }

      if (shopPageData.appCurrentDealsFontColor) {
        setAppCurrentDealsFontColor(
          toColor('hex', shopPageData.appCurrentDealsFontColor),
        );
      }

      setShopPageData(shopPageData);
      setSimplifiedProducts(
        shopSettingsSnapshot
          .data()
          .simplifiedProducts.filter((p) => {
            return p.active;
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'There was an error retrieving the shop page data, refresh the page and try again.',
      );
    }
  }, [
    setAppCurrentDealsBackgroundColor,
    setAppCurrentDealsFontColor,
    setCurrentDealsBackgroundColor,
    setCurrentDealsFontColor,
  ]);

  const handleAddProductClick = (type) => {
    let product = {};

    for (let i = 0; i < simplifiedProducts.length; i++) {
      const simplified = simplifiedProducts[i];
      const foundIndex = shopPageData[type].findIndex(
        (p) => p.id === simplified.id,
      );

      if (foundIndex === -1) {
        product = simplified;
        break;
      }
    }
    setSelectedProduct(product);
    setAddProductType(type);
  };

  const addProduct = () => {
    const products = [...shopPageData[addProductType], { ...selectedProduct }];

    setShopPageData({
      ...shopPageData,
      [addProductType]: products,
    });
    setSelectedProduct('');
    setAddProductType('');
  };

  const removeProduct = (type, i) => {
    const products = [...shopPageData[type]];
    products.splice(i, 1);

    setShopPageData({
      ...shopPageData,
      [type]: products,
    });
  };

  const save = async () => {
    setLoading(true);

    try {
      await firebase
        .firestore()
        .collection('config')
        .doc('shop-page-data')
        .update(shopPageData);

      setLoading(false);
      setModalTitle('Success!');
      setModalText(
        'The Shop Page products have been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'There was an error saving the Shop Page products, please try again.',
      );
    }
  };

  const renderTables = () => {
    const tables = [];

    for (let i = 0; i < productTypeKeys.length; i++) {
      const key = productTypeKeys[i];
      tables.push(
        <TableContainer
          component={Paper}
          key={key}
          style={{ marginBottom: 20 }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flex: 1,
              }}
            >
              {productTypeTitlesMap[key]}s
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddProductClick(key)}
            >
              Add {productTypeTitlesMap[key]}
            </Button>
          </Toolbar>
          <Divider />
          {!shopPageData[key].length ? (
            <Toolbar>
              <Typography>
                No {productTypeTitlesMap[key]}s have been added.
              </Typography>
            </Toolbar>
          ) : (
            <Table
              aria-label={`${productTypeTitlesMap[key]} table`}
              style={{ width: '100%' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>SKU</strong>
                  </TableCell>
                  <TableCell>
                    <strong>ID</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Categories</strong>
                  </TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shopPageData[key].map((product, i) => (
                  <TableRow key={`${key}-${product.id}`}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>{product.id}</TableCell>
                    <TableCell>
                      {product.categories.length ? (
                        product.categories.join(', ')
                      ) : (
                        <span style={{ color: 'orange' }}>None Specified</span>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const products = [...shopPageData[key]];

                            const itemToMoveUp = product;
                            const itemToMoveDown = products[i - 1];

                            products[i] = itemToMoveDown;
                            products[i - 1] = itemToMoveUp;

                            setShopPageData({
                              ...shopPageData,
                              [key]: products,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowUpIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === shopPageData[key].length - 1 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const products = [...shopPageData[key]];

                            const itemToMoveDown = product;
                            const itemToMoveUp = products[i + 1];

                            products[i] = itemToMoveUp;
                            products[i + 1] = itemToMoveDown;

                            setShopPageData({
                              ...shopPageData,
                              [key]: products,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowDownIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        edge="start"
                        style={{ marginLeft: 10 }}
                        color="inherit"
                        onClick={() => removeProduct(key, i)}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>,
      );
    }

    return tables;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textAlign: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#fff', top: '50%', position: 'absolute' }}
        />
      </div>
    );
  };

  const currentDealsBackgroundColorOpen = Boolean(
    currentDealsBackgroundColorAnchorElem,
  );
  const currentDealsBackgroundColorId = currentDealsBackgroundColorOpen
    ? 'current-deals-background-color-popover'
    : undefined;
  const currentDealsFontColorOpen = Boolean(currentDealsFontColorAnchorElem);
  const currentDealsFontColorId = currentDealsFontColorOpen
    ? 'current-deals-font-color-popover'
    : undefined;
  const appCurrentDealsBackgroundColorOpen = Boolean(
    appCurrentDealsBackgroundColorAnchorElem,
  );
  const appCurrentDealsBackgroundColorId = appCurrentDealsBackgroundColorOpen
    ? 'app-current-deals-background-color-popover'
    : undefined;
  const appCurrentDealsFontColorOpen = Boolean(
    appCurrentDealsFontColorAnchorElem,
  );
  const appCurrentDealsFontColorId = appCurrentDealsFontColorOpen
    ? 'app-current-deals-font-color-popover'
    : undefined;

  useEffect(() => {
    fetchShopPageData();
  }, [fetchShopPageData]);

  return (
    <div className="ShopPage">
      {renderLoading()}
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          borderColor: 'rgba(0, 0, 0, 0.12)',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <Typography variant="h6">Shop Page</Typography>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={save}
        >
          Save
        </Button>
      </Toolbar>

      {!shopPageData ? null : (
        <div className="content-container">
          <div className="section-container">
            <div className="section-label">Current Deals</div>

            <FormControlLabel
              control={
                <Switch
                  checked={shopPageData.currentDealsEnabled || false}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      currentDealsEnabled: e.target.checked,
                    });
                  }}
                  name="currentDealsEnabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!shopPageData.currentDealsEnabled ? null : (
              <>
                <TextField
                  label="Title"
                  value={shopPageData.currentDealsTitle || ''}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      currentDealsTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <TextField
                  label="Subtitle"
                  value={shopPageData.currentDealsSubtitle || ''}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      currentDealsSubtitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  multiline
                  rows="4"
                />

                <div className="input-row">
                  <TextField
                    label="Link URL"
                    value={shopPageData.currentDealsLinkUrl || ''}
                    onChange={(e) => {
                      setShopPageData({
                        ...shopPageData,
                        currentDealsLinkUrl: e.target.value,
                      });
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="half-width-text-field"
                  />

                  <TextField
                    label="Image URL"
                    value={shopPageData.currentDealsImageUrl || ''}
                    onChange={(e) => {
                      setShopPageData({
                        ...shopPageData,
                        currentDealsImageUrl: e.target.value,
                      });
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="half-width-text-field"
                  />
                </div>

                <div className="input-row">
                  <div
                    className="color-container"
                    onClick={(e) =>
                      setCurrentDealsBackgroundColorAnchorElem(e.currentTarget)
                    }
                  >
                    <p>Background Color</p>

                    <div
                      className="color-preview"
                      style={{
                        backgroundColor:
                          shopPageData.currentDealsBackgroundColor || '#ffb4ab',
                      }}
                    ></div>
                  </div>

                  <Popover
                    id={currentDealsBackgroundColorId}
                    open={currentDealsBackgroundColorOpen}
                    anchorEl={currentDealsBackgroundColorAnchorElem}
                    onClose={() =>
                      setCurrentDealsBackgroundColorAnchorElem(null)
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ColorPicker
                      width={250}
                      height={125}
                      color={currentDealsBackgroundColor}
                      onChange={setCurrentDealsBackgroundColor}
                      onChangeComplete={(color) =>
                        setShopPageData({
                          ...shopPageData,
                          currentDealsBackgroundColor: color.hex,
                        })
                      }
                      hideHSV
                      hideRGB
                      alpha
                    />
                  </Popover>

                  <div
                    className="color-container last"
                    onClick={(e) =>
                      setCurrentDealsFontColorAnchorElem(e.currentTarget)
                    }
                  >
                    <p>Text Color</p>

                    <div
                      className="color-preview"
                      style={{
                        backgroundColor:
                          shopPageData.currentDealsFontColor || '#592e2c',
                      }}
                    ></div>
                  </div>

                  <Popover
                    id={currentDealsFontColorId}
                    open={currentDealsFontColorOpen}
                    anchorEl={currentDealsFontColorAnchorElem}
                    onClose={() => setCurrentDealsFontColorAnchorElem(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ColorPicker
                      width={250}
                      height={125}
                      color={currentDealsFontColor}
                      onChange={setCurrentDealsFontColor}
                      onChangeComplete={(color) =>
                        setShopPageData({
                          ...shopPageData,
                          currentDealsFontColor: color.hex,
                        })
                      }
                      hideHSV
                      hideRGB
                      alpha
                    />
                  </Popover>
                </div>
              </>
            )}
          </div>

          <div className="section-container">
            <div className="section-label">App Current Deals</div>

            <FormControlLabel
              control={
                <Switch
                  checked={shopPageData.appCurrentDealsEnabled || false}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      appCurrentDealsEnabled: e.target.checked,
                    });
                  }}
                  name="appCurrentDealsEnabled"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!shopPageData.appCurrentDealsEnabled ? null : (
              <>
                <TextField
                  label="Title"
                  value={shopPageData.appCurrentDealsTitle || ''}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      appCurrentDealsTitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <TextField
                  label="Subtitle"
                  value={shopPageData.appCurrentDealsSubtitle || ''}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      appCurrentDealsSubtitle: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  multiline
                  rows="4"
                />

                <TextField
                  label="Image URL"
                  value={shopPageData.appCurrentDealsImageUrl || ''}
                  onChange={(e) => {
                    setShopPageData({
                      ...shopPageData,
                      appCurrentDealsImageUrl: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                />

                <div className="input-row">
                  <div
                    className="color-container"
                    onClick={(e) =>
                      setAppCurrentDealsBackgroundColorAnchorElem(
                        e.currentTarget,
                      )
                    }
                  >
                    <p>Background Color</p>

                    <div
                      className="color-preview"
                      style={{
                        backgroundColor:
                          shopPageData.appCurrentDealsBackgroundColor ||
                          '#ffb4ab',
                      }}
                    ></div>
                  </div>

                  <Popover
                    id={appCurrentDealsBackgroundColorId}
                    open={appCurrentDealsBackgroundColorOpen}
                    anchorEl={appCurrentDealsBackgroundColorAnchorElem}
                    onClose={() =>
                      setAppCurrentDealsBackgroundColorAnchorElem(null)
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ColorPicker
                      width={250}
                      height={125}
                      color={appCurrentDealsBackgroundColor}
                      onChange={setAppCurrentDealsBackgroundColor}
                      onChangeComplete={(color) =>
                        setShopPageData({
                          ...shopPageData,
                          appCurrentDealsBackgroundColor: color.hex,
                        })
                      }
                      hideHSV
                      hideRGB
                      alpha
                    />
                  </Popover>

                  <div
                    className="color-container last"
                    onClick={(e) =>
                      setAppCurrentDealsFontColorAnchorElem(e.currentTarget)
                    }
                  >
                    <p>Text Color</p>

                    <div
                      className="color-preview"
                      style={{
                        backgroundColor:
                          shopPageData.appCurrentDealsFontColor || '#592e2c',
                      }}
                    ></div>
                  </div>

                  <Popover
                    id={appCurrentDealsFontColorId}
                    open={appCurrentDealsFontColorOpen}
                    anchorEl={appCurrentDealsFontColorAnchorElem}
                    onClose={() => setAppCurrentDealsFontColorAnchorElem(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ColorPicker
                      width={250}
                      height={125}
                      color={appCurrentDealsFontColor}
                      onChange={setAppCurrentDealsFontColor}
                      onChangeComplete={(color) =>
                        setShopPageData({
                          ...shopPageData,
                          appCurrentDealsFontColor: color.hex,
                        })
                      }
                      hideHSV
                      hideRGB
                      alpha
                    />
                  </Popover>
                </div>

                <TableContainer component={Paper} style={{ marginTop: 10 }}>
                  <Toolbar>
                    <Typography
                      variant="h6"
                      style={{
                        flex: 1,
                      }}
                    >
                      Current Deal Products
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleAddProductClick('currentDealProducts')
                      }
                    >
                      Add Current Deal
                    </Button>
                  </Toolbar>
                  <Divider />
                  {!shopPageData.currentDealProducts.length ? (
                    <Toolbar>
                      <Typography>
                        No Current Deal Products have been added.
                      </Typography>
                    </Toolbar>
                  ) : (
                    <Table
                      aria-label="Current Deal table"
                      style={{ width: '100%' }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Name</strong>
                          </TableCell>
                          <TableCell>
                            <strong>SKU</strong>
                          </TableCell>
                          <TableCell>
                            <strong>ID</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Categories</strong>
                          </TableCell>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell padding="checkbox"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shopPageData.currentDealProducts.map((product, i) => (
                          <TableRow key={`currentDealProducts-${product.id}`}>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>{product.sku}</TableCell>
                            <TableCell>{product.id}</TableCell>
                            <TableCell>
                              {product.categories.length ? (
                                product.categories.join(', ')
                              ) : (
                                <span style={{ color: 'orange' }}>
                                  None Specified
                                </span>
                              )}
                            </TableCell>
                            <TableCell padding="checkbox">
                              {i === 0 ? null : (
                                <IconButton
                                  color="inherit"
                                  onClick={() => {
                                    const products = [
                                      ...shopPageData.currentDealProducts,
                                    ];

                                    const itemToMoveUp = product;
                                    const itemToMoveDown = products[i - 1];

                                    products[i] = itemToMoveDown;
                                    products[i - 1] = itemToMoveUp;

                                    setShopPageData({
                                      ...shopPageData,
                                      currentDealProducts: products,
                                    });
                                  }}
                                  aria-label="Move Up"
                                >
                                  <ArrowUpIcon />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell padding="checkbox">
                              {i ===
                              shopPageData.currentDealProducts.length -
                                1 ? null : (
                                <IconButton
                                  color="inherit"
                                  onClick={() => {
                                    const products = [
                                      ...shopPageData.currentDealProducts,
                                    ];

                                    const itemToMoveDown = product;
                                    const itemToMoveUp = products[i + 1];

                                    products[i] = itemToMoveUp;
                                    products[i + 1] = itemToMoveDown;

                                    setShopPageData({
                                      ...shopPageData,
                                      currentDealProducts: products,
                                    });
                                  }}
                                  aria-label="Move Up"
                                >
                                  <ArrowDownIcon />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell padding="checkbox">
                              <IconButton
                                edge="start"
                                style={{ marginLeft: 10 }}
                                color="inherit"
                                onClick={() =>
                                  removeProduct('currentDealProducts', i)
                                }
                                aria-label="Delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </>
            )}
          </div>

          <div className="section-container">
            <div className="section-label">Number of columns per page</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={shopPageData.numberOfColumnsPerPage}
                onChange={(e) => {
                  setShopPageData({
                    ...shopPageData,
                    numberOfColumnsPerPage: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3 Columns"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4 Columns"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {renderTables()}
        </div>
      )}

      <Dialog
        fullWidth
        open={!!addProductType}
        onClose={() => {
          setAddProductType('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add {productTypeTitlesMap[addProductType]}</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel>Product</InputLabel>
            <Select
              style={{ width: '100%' }}
              value={selectedProduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
              }}
              label="Product"
            >
              {simplifiedProducts
                .filter((product) => {
                  if (!addProductType) {
                    return false;
                  }

                  const foundIndex = shopPageData[addProductType].findIndex(
                    (p) => p.id === product.id,
                  );

                  return foundIndex === -1;
                })
                .map((product, i) => {
                  return (
                    <MenuItem key={`option-${i}`} value={product}>
                      {product.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addProduct} color="secondary">
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddProductType('');
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={!!modalText}
        onClose={() => {
          setModalText('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShopPage;
