import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './academyPlusCourseLandingPages.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AcademyPlusCourseLandingPages({ history }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [pages, setPages] = useState([]);
  const [addNewPageOpen, setAddNewPageOpen] = useState(false);
  const [newPage, setNewPage] = useState({
    title: '',
    description: '',
    path: '',
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const pagesSnapshot = await firebase.firestore().collection('academy-plus-landing-pages').get();
      const pagesData = pagesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPages(pagesData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the landing pages, please try again.');
    }
  };

  const saveNewPage = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingPageQuerySnapshot = await db.collection('pages').where('path', '==', newPage.path).get();
      const existingBlogQuerySnapshot = await db.collection('blog-posts').where('path', '==', newPage.path).get();
      const existingAcademyPagesQuerySnapshot = await db.collection('academy-plus-landing-pages').where('path', '==', newPage.path).get();

      if (existingPageQuerySnapshot.docs.length || existingBlogQuerySnapshot.docs.length || existingAcademyPagesQuerySnapshot.docs.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      const createdPage = await db.collection('academy-plus-landing-pages').add(newPage);

      setLoading(false);
      setAddNewPageOpen(false);
      history.push(`/academy-content/academy-plus-course-landing-pages/${createdPage.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new page. Please try again.');
    }
  };

  const deletePage = async () => {
    const pageIndex = deleteIndex;

    setDeleteIndex(-1);
    setLoading(true);

    try {
      await firebase.firestore().collection('academy-plus-landing-pages').doc(pages[pageIndex].id).delete();

      const pagesCopy = [ ...pages ];
      pagesCopy.splice(pageIndex, 1);

      setPages(pagesCopy);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred deleting the page. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="AcademyPlusCourseLandingPages">
      {renderLoading()}
      <Card style={{width: '100%'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Academy+ Landing Pages
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewPage({
                  title: '',
                  description: '',
                  path: '',
                  live: false,
                  created: Date.now(),
                  updated: Date.now(),
                  lastUpdatedBy: firebase.auth().currentUser.email,
                });
                setAddNewPageOpen(true);
              }}
              style={{marginRight: '10px'}}
            >
              Add New
            </Button>
          </div>
        </Toolbar>

        <List>
          {pages.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={p.title || 'Title not set'}
                    secondary={
                      <span>
                        <span style={{marginRight: 10}}><strong>ID:</strong> {p.id}</span>
                        <span style={{marginRight: 10}}><strong>Last updated:</strong> {moment(p.updated).calendar()}</span>
                        <span style={{marginRight: 10}}><strong>Last updated by:</strong> {p.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                  <Link edge="start" to={`/academy-content/academy-plus-course-landing-pages/${p.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton edge="start" color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton onClick={() => {
                    setDeleteIndex(i);
                  }} edge="start" color="inherit" aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={addNewPageOpen} onClose={() => { setAddNewPageOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Page</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newPage.title}
            onChange={(e) => {
              setNewPage({
                ...newPage,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Description"
            value={newPage.description}
            onChange={(e) => {
              setNewPage({
                ...newPage,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newPage.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewPage({
                ...newPage,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewPage} color="primary" disabled={!newPage.title || !newPage.description || !newPage.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddNewPageOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Page?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {deleteIndex === -1 ? 'this page?' : <strong>{pages[deleteIndex].title}</strong>}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deletePage} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AcademyPlusCourseLandingPages;
