import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DeletableChip from '../../shared/deletableChip';

import './memberships.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Memberships(props) {
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [editMembership, setEditMembership] = useState({
    name: '',
    description: '',
    products: [],
  });
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);

  useEffect(() => {
    getMemberships();
  }, []);

  const getMemberships = async () => {
    try {
      const membershipsSnapshot = await firebase.firestore().collection('memberships').get();
      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();

      const membershipData = membershipsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const simplifiedProductData = shopSettingsSnapshot.data().simplifiedProducts.map(p => {
        return {
          name: p.name,
          id: p.id,
        };
      });

      setMemberships(membershipData);
      setSimplifiedProducts(simplifiedProductData);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      window.alert('There was an error loading memberships. Please refresh and try again.');
    }
  };

  const deleteMembership = async () => {
    setLoading(true);

    try {
      const membershipId = memberships[deleteIndex].id;
      const productsToRemoveMembershipFrom = [ ...memberships[deleteIndex].products ];
      await firebase.firestore().collection('memberships').doc(membershipId).delete();

      const membershipsCopy = [ ...memberships ];
      membershipsCopy.splice(deleteIndex, 1);

      setDeleteIndex(-1);
      setMemberships(membershipsCopy);

      updateProductMemberships([], productsToRemoveMembershipFrom, membershipId);
    } catch (e) {
      console.log('error deleting membership', e);
      setLoading(false);
      window.alert('There was an error deleting the membership. Please try again.');
    }
  };

  const saveMembership = async () => {
    setLoading(true);
    const membershipsCopy = [ ...memberships ];
    let productsToAddMembershipTo = [];
    let productsToRemoveMembershipFrom = [];
    let membershipId = '';

    try {
      if (editIndex === memberships.length) {
        const membership = await firebase.firestore().collection('memberships').add(editMembership);

        membershipsCopy.push({
          ...editMembership,
          id: membership.id,
        });

        membershipId = membership.id;

        productsToAddMembershipTo = editMembership.products.map(p => {
          return p.id;
        });
      } else {
        await firebase.firestore().collection('memberships').doc(memberships[editIndex].id).update({
          ...editMembership,
        });

        membershipsCopy[editIndex] = {
          ...editMembership,
          id: memberships[editIndex].id,
        };

        const updatedProductIds = editMembership.products.map(p => {
          return p.id;
        });

        const originalProductIds = memberships[editIndex].products.map(p => {
          if (!updatedProductIds.includes(p.id)) {
            productsToRemoveMembershipFrom.push(p.id);
          }

          return p.id;
        });

        editMembership.products.forEach(p => {
          if (!originalProductIds.includes(p.id)) {
            productsToAddMembershipTo.push(p.id);
          }
        });

        membershipId = memberships[editIndex].id;
      }

      setMemberships(membershipsCopy);
      setEditIndex(-1);
      updateProductMemberships(productsToAddMembershipTo, productsToRemoveMembershipFrom, membershipId);
    } catch (e) {
      console.log('error saving', e);
      setLoading(false);
      window.alert('There was an error saving the membership. Please try again.');
    }
  };

  const updateProductMemberships = async (productsToAddMembershipTo, productsToRemoveMembershipFrom, membershipId) => {
    for (let i = 0; i < productsToAddMembershipTo.length; i++) {
      try {
        await firebase.firestore().collection('products-v2').doc(productsToAddMembershipTo[i]).update({
          memberships: firebase.firestore.FieldValue.arrayUnion(membershipId),
        });
      } catch (e) {
        console.log('error adding membership to ', productsToAddMembershipTo[i]);
      }
    }

    for (let i = 0; i < productsToRemoveMembershipFrom.length; i++) {
      try {
        await firebase.firestore().collection('products-v2').doc(productsToRemoveMembershipFrom[i]).update({
          memberships: firebase.firestore.FieldValue.arrayRemove(membershipId),
        });
      } catch (e) {
        console.log('error removing membership from ', productsToRemoveMembershipFrom[i]);
      }
    }

    setLoading(false);
  };

  const cancelEditMembership = () => {
    setEditIndex(-1);
    setEditMembership({
      name: '',
      description: '',
      products: [],
    });
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Memberships">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between',}}>
          <Typography variant="h6">
            Memberships
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => {
            setEditMembership({
              name: '',
              description: '',
              products: [],
            });
            setEditIndex(memberships.length);
          }} aria-label="Delete">
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {memberships.map((m, i) => {
            return (
              <div key={m.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={m.name} secondary={<span><strong>ID:</strong> {m.id}</span>} />
                  <IconButton edge="start" style={{marginRight: 15}} color="inherit" onClick={() => {
                    setEditMembership({
                      name: m.name,
                      description: m.description,
                      products: [ ...m.products ],
                    });
                    setEditIndex(i);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={editIndex !== -1} onClose={cancelEditMembership} TransitionComponent={Transition}>
        <DialogTitle>Edit Membership</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={editMembership.name}
            onChange={(e) => {
              setEditMembership({
                ...editMembership,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={editMembership.description}
            onChange={(e) => {
              setEditMembership({
                ...editMembership,
                description: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Included Products</InputLabel>
            <Select
              multiple
              value={editMembership.products}
              onChange={(e) => {
                setEditMembership({
                  ...editMembership,
                  products: e.target.value,
                });
              }}
              input={<Input />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value.id}
                      label={value.name}
                      style={{margin: 2}}
                      color="primary"
                      onDelete={() => {
                        const index = editMembership.products.indexOf(value);

                        const products = [ ...editMembership.products ];

                        products.splice(index, 1);

                        setEditMembership({
                          ...editMembership,
                          products,
                        });
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {simplifiedProducts.filter(simpleProduct => {
                const found = editMembership.products.find(p => p.id === simpleProduct.id);
                return !found;
              }).map(simpleProduct => (
                <MenuItem key={simpleProduct.id} value={simpleProduct}>
                  {simpleProduct.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveMembership} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={cancelEditMembership} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Membership?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this membership?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteMembership} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Memberships;
