import React from 'react';
import { ItemTypes } from '../shared/itemTypes';

import Row from './row';
import Column from './column';
import Link from './link';
import Button from './button';
import Accordion from './accordion';
import Tabs from './tabs';
import DangerouslySetHtmlContent from '../shared/DangerouslySetHtmlContent';

import './previewStyles.scss';

const headerTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const regex = /([\w-]*)\s*:\s*([^;]*)/g;
const count = {count: 0};

function Preview({ items }) {

  const renderItem = (content, i, j, k) => {
    let match, properties = {};
    count.count++;
    while(match=regex.exec(content.customStyles)) properties[match[1]] = match[2].trim();

    if (content.type === ItemTypes.ROW) {
      return (
        <Row key={`child-${count.count}`} content={content} customStyles={properties}>
          {content.children.map((item, j) => {
            return renderItem(item, i, j);
          })}
        </Row>
      );
    }

    if (content.type === ItemTypes.COLUMN) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{
          zIndex: 1,
          flex: content.styles.flex || 1,
          alignSelf: content.styles.alignSelf,
          width: '100%',
        }}>
          {content.isLink ?
            <a
              href={content.url}
              target='_blank'
            >
              <Column content={content} customStyles={properties}>
                {content.children.map((item, k) => {
                  return renderItem(item, i, j, k);
                })}
              </Column>
            </a> :
            <Column content={content} customStyles={properties}>
              {content.children.map((item, k) => {
                return renderItem(item, i, j, k);
              })}
            </Column>
          }
        </div>
      );
    }

    if (headerTypes.includes(content.type)) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <content.type style={{ ...content.styles, whiteSpace: 'pre-wrap', ...properties }} className={content.customClasses} id={content.htmlID}>{content.text}</content.type>
        </div>
      );
    }

    if (content.type === ItemTypes.TEXT) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <div style={{ ...content.styles, ...properties }} dangerouslySetInnerHTML={{__html: content.text}} className={content.customClasses} id={content.htmlID} />
        </div>
      );
    }

    if (content.type === ItemTypes.BUTTON) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Button content={content} customStyles={properties} />
        </div>
      );
    }

    if (content.type === ItemTypes.IMAGE) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <img src={content.src || 'https://via.placeholder.com/500x500.png&text=Image&bold'} alt={content.alt} style={{ ...content.styles, ...properties }} className={content.customClasses} id={content.htmlID} />
        </div>
      );
    }

    if (content.type === ItemTypes.CODE) {
      return (
        <div key={`child-${count.count}`} className={`${content.visibilityClassName} ${content.customClasses}`} id={content.htmlID} style={{zIndex: 1, position: 'relative'}}>
          <DangerouslySetHtmlContent html={content.html} />
        </div>
      );
    }

    if (content.type === ItemTypes.ACCORDION) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Accordion content={content} customStyles={properties} />
        </div>
      );
    }

    if (content.type === ItemTypes.TABS) {
      return (
        <div key={`child-${count.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Tabs content={content} customStyles={properties} />
        </div>
      );
    }

    if (content.type === ItemTypes.LINK) {
      return (
        <Link key={`child-${count.count}`} content={content} customStyles={properties}>
          {content.children.map((item, j) => {
            return renderItem(item, i, j);
          })}
        </Link>
      );
    }

    return <div></div>;
  };

  return (
    <div>
      {items.map((item, i) => {
        return (
          <div key={`row-${i}`}>
            {renderItem(item, i)}
          </div>
        )
      })}
    </div>
  );
}

export default Preview;
