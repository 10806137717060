import React, { useState, useEffect } from 'react';
import firebase, { firestore } from 'firebase/app';
import 'firebase/functions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Input } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './dashboard.scss';
import DeletableChip from '../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Dashboard(props) {
  const [loading, setLoading] = useState(false);
  const [showDeployingMessage, setShowDeployingMessage] = useState(false);
  const [showDeployConfirmation, setShowDeployConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [notifyEmails, setNotifyEmails] = useState([]);
  const [notifyPhones, setNotifyPhones] = useState([]);
  const [editEmails, setEditEmails] = useState([]);
  const [editPhones, setEditPhones] = useState([]);
  const [searchItems, setSearchItems] = useState({ items: [] });
  const [newTag, setNewTag] = useState();
  const [editEmailsOpen, setEditEmailsOpen] = useState(false);
  const [editPhonesOpen, setEditPhonesOpen] = useState(false);
  const [addTagOpen, setAddTagOpen] = useState(false);

  useEffect(() => {
    getBuildData();
  }, []);

  const getBuildData = async () => {
    try {
      const snapshot = await firestore().collection('builds').doc('data').get();
      const data = snapshot.data();

      if (data.notifyEmails && data.notifyPhones) {
        setNotifyEmails(data.notifyEmails);
        setNotifyPhones(data.notifyPhones);
      } else {
        setModalTitle('Error:');
        setModalText(
          'There was an error fetching build notification settings. Please refresh and try again.',
        );
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText(
        'There was an error fetching build notification settings. Please refresh and try again.',
      );
    }
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('search-suggestions')
        .get();
      const items = querySnapshot.data();
      setSearchItems(items);
    } catch (e) {
      console.log(e.toString());
    }
  };

  const deploySite = async () => {
    setShowDeployConfirmation(false);
    setLoading(true);

    try {
      const deploySite = firebase.functions().httpsCallable('deploySite');
      const result = await deploySite();
      console.log('result', result);

      setLoading(false);

      if (result && result.data && result.data.hasOwnProperty('error')) {
        window.alert(`Error: ${JSON.stringify(result.data.error)}`);
        return;
      }

      setShowDeployingMessage(true);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      window.alert(e);
    }
  };

  const cancelEditEmails = () => {
    setEditEmails([]);
    setEditEmailsOpen(false);
  };

  const cancelEditPhones = () => {
    setEditPhones([]);
    setEditPhonesOpen(false);
  };

  const saveNotificationEmails = async () => {
    setLoading(true);

    try {
      await firestore().collection('builds').doc('data').update({
        notifyEmails: editEmails,
      });

      setNotifyEmails(editEmails);
      setLoading(false);
      cancelEditEmails();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'There was an error updating the build failure notification settings. Please refresh and try again.',
      );
    }
  };

  const saveNotificationPhones = async () => {
    setLoading(true);

    try {
      await firestore().collection('builds').doc('data').update({
        notifyPhones: editPhones,
      });

      setNotifyPhones(editPhones);
      setLoading(false);
      cancelEditPhones();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'There was an error updating the build failure notification settings. Please refresh and try again.',
      );
    }
  };

  const addNewTag = async () => {
    setLoading(true);

    try {
      const updateState = {
        ...searchItems,
        items: [...searchItems.items, newTag],
      };
      await firebase
        .firestore()
        .collection('config')
        .doc('search-suggestions')
        .update(updateState);
      setSearchItems(updateState);
      setLoading(false);
      setAddTagOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error');
      setModalText(
        'There was an error updating Search Suggestions Tags. Please refresh and try again',
      );
    }
  };

  const removeTag = async (value) => {
    const index = searchItems.items.indexOf(value);

    if (index === -1) {
      return;
    }

    try {
      setLoading(true);
      const tagsCopy = searchItems.items.slice();
      tagsCopy.splice(index, 1);
      const updateState = { ...searchItems, items: tagsCopy };
      await firebase
        .firestore()
        .collection('config')
        .doc('search-suggestions')
        .update(updateState);
      setSearchItems(updateState);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error');
      setModalText(
        'There was an error updating Search Suggestions Tags. Please refresh and try again',
      );
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textAlign: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#fff', top: '50%', position: 'absolute' }}
        />
      </div>
    );
  };

  return (
    <div className="WebsiteDashboard">
      {renderLoading()}
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          borderColor: 'rgba(0, 0, 0, 0.12)',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <Typography variant="h6">Dashboard</Typography>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDeployConfirmation(true);
            }}
            style={{ marginRight: 10 }}
            disabled={
              !(
                props.access.includes('super-admin') ||
                props.access.includes('deploy')
              )
            }
          >
            Deploy Website
          </Button>
        </div>
      </Toolbar>

      <div className="content">
        <Link
          style={{ textDecoration: 'none' }}
          to="/website-content/dashboard/header-v2"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginRight: 10 }}
          >
            Edit Header
          </Button>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          to="/website-content/dashboard/footer"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginRight: 10 }}
          >
            Edit Footer
          </Button>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          to="/website-content/dashboard/product-page-options"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginRight: 10 }}
          >
            Edit Product Options
          </Button>
        </Link>

        <div className="section-container">
          <div className="section-label">Build Failure Notifications</div>
          <div className="contact-container">
            <Typography variant="subtitle1">
              Emails
              <IconButton
                aria-label="Edit Emails"
                size="small"
                onClick={() => {
                  setEditEmails([...notifyEmails]);
                  setEditEmailsOpen(true);
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>

            <div>
              {notifyEmails.length
                ? notifyEmails.join(', ')
                : 'There are no notification emails set up.'}
            </div>
          </div>

          <div className="contact-container">
            <Typography variant="subtitle1">
              Phones
              <IconButton
                aria-label="Edit Phones"
                size="small"
                onClick={() => {
                  setEditPhones([...notifyPhones]);
                  setEditPhonesOpen(true);
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>

            <div>
              {notifyPhones.length
                ? notifyPhones.join(', ')
                : 'There are no notification phones set up.'}
            </div>
          </div>
        </div>
        <div className="section-container">
          <div className="section-label">Search Suggestion Tags</div>

          <div className="categories-container">
            <FormControl style={{ marginTop: -22, width: '99%' }}>
              <InputLabel htmlFor="select-multiple-chip"></InputLabel>
              <Select
                multiple
                value={searchItems.items}
                onChange={(e) => {
                  setSearchItems({
                    ...searchItems,
                    items: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <DeletableChip
                        key={value}
                        label={value}
                        style={{ margin: 2 }}
                        color="primary"
                        onDelete={() => {
                          removeTag(value);
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {searchItems.items
                  .filter((tag) => {
                    return !searchItems.items.includes(tag);
                  })
                  .map((tag) => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div
              className="add-category-button"
              onClick={() => {
                setAddTagOpen(true);
                setNewTag('');
              }}
            >
              <small>+ Add new tag</small>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={editEmailsOpen}
        onClose={cancelEditEmails}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Notify Emails</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            onClick={() => {
              const emailCopies = [...editEmails];
              emailCopies.push('');
              setEditEmails(emailCopies);
            }}
            color="primary"
            style={{ marginBottom: 20 }}
          >
            Add
          </Button>
          {editEmails.map((email, i) => {
            return (
              <div
                key={`edit-notify-email-${i}`}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <TextField
                  label={`Email ${i + 1}`}
                  value={email}
                  onChange={(e) => {
                    const emailCopies = [...editEmails];
                    emailCopies[i] = e.target.value;
                    setEditEmails(emailCopies);
                  }}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  className="day-text-field"
                />

                <IconButton
                  aria-label="Delete email"
                  onClick={() => {
                    const emailCopies = [...editEmails];
                    emailCopies.splice(i, 1);
                    setEditEmails(emailCopies);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={saveNotificationEmails}
            color="secondary"
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={cancelEditEmails}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editPhonesOpen}
        onClose={cancelEditPhones}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Notify Phones</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            onClick={() => {
              const phoneCopies = [...editPhones];
              phoneCopies.push('');
              setEditPhones(phoneCopies);
            }}
            color="primary"
            style={{ marginBottom: 20 }}
          >
            Add
          </Button>
          {editPhones.map((phone, i) => {
            return (
              <div
                key={`edit-notify-phone-${i}`}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <TextField
                  label={`Phone ${i + 1}`}
                  value={phone}
                  onChange={(e) => {
                    const phoneCopies = [...editPhones];
                    phoneCopies[i] = e.target.value;
                    setEditPhones(phoneCopies);
                  }}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  className="day-text-field"
                />

                <IconButton
                  aria-label="Delete email"
                  onClick={() => {
                    const phoneCopies = [...editPhones];
                    phoneCopies.splice(i, 1);
                    setEditPhones(phoneCopies);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={saveNotificationPhones}
            color="secondary"
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={cancelEditPhones}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeployConfirmation}
        onClose={() => {
          setShowDeployConfirmation(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{'Deploy Website?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deploy a new version of the website?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deploySite} color="secondary">
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeployConfirmation(false);
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeployingMessage}
        onClose={() => {
          setShowDeployingMessage(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{'Deploy Initiated!'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This may take a few minutes. Any new changes will show up once the
            deploy has finished.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeployingMessage(false);
            }}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!modalText}
        onClose={() => {
          setModalText('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addTagOpen}
        onClose={() => {
          setAddTagOpen(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          <TextField
            label="Tag Name"
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={addNewTag}
            color="secondary"
            disabled={!newTag || searchItems.items.includes(newTag)}
          >
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddTagOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    access: state.currentUser.access,
  };
};

export default connect(mapStateToProps, {})(Dashboard);
