import React, {
  useState,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { config } from '../../../config';

function EditMembershipProduct(props) {
  const [product, setProduct] = useState({
    enabled: false,
    name: '',
    description: '',
    backgroundImage: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProduct() {
      const db = firebase.firestore();
      try {
        const doc = await db.collection('membership-content').doc(props.match.params.id).get();
        const product = doc.data();

        setProduct(product);
        setLoading(false);
      } catch (e) {
        console.log(e.toString());
      }
    }
    fetchProduct();
  }, []);

  const handleEnableToggle = event => {
    setProduct({
      ...product,
      enabled: event.target.checked
    });
  };

  const handleChange = name => event => {
    setProduct({
      ...product,
      [name]: event.target.value
    });
  };

  const saveProduct = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      await db.collection('membership-content').doc(product.id).update(product);

      const token = await firebase.auth().currentUser.getIdToken();
      const method = product.enabled ? 'POST' : 'DELETE';
      await axios(`${config.gateway}/course-service/v1/courses/${product.id}/search`, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log('error', e);
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        <p>
          <Link to={`/academy-content/member-content`} style={{color: 'blue', textDecoration: 'none'}}>Academy Content</Link> / {product.name}
        </p>
      </div>
      <Card className="drag-card" style={{marginTop: '10px', display: 'inline-block', textAlign: 'left'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {product.name}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveProduct() }}
          >
            Save
          </Button>
        </Toolbar>

        <CardContent>
          <div style={{marginBottom: '10px'}}>
            <Link style={{textDecoration: 'none'}} to={`/academy-content/member-content/${product.id}/purchase-details`}>
              <Button
                variant="contained"
                color="primary"
              >
                Edit Purchase Details
              </Button>
            </Link>

            <Link style={{textDecoration: 'none'}} to={`/academy-content/member-content/${product.id}/details`}>
              <Button
                variant="contained"
                color="primary"
                style={{marginLeft: '10px'}}
              >
                Edit Course Content
              </Button>
            </Link>
          </div>

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={product.enabled}
                  onChange={handleEnableToggle}
                  name="enabled"
                  color="primary"
                />
              }
              label="Product Enabled"
            />
          </FormGroup>

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={product.includedInEarthWiseBaseCourses || false}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      includedInEarthWiseBaseCourses: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Include in EarthWise Base Courses"
            />
          </FormGroup>

          <TextField
            label="Title"
            value={product.name}
            onChange={handleChange('name')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Description"
            value={product.description}
            onChange={handleChange('description')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Background Image URL"
            value={product.backgroundImage}
            onChange={handleChange('backgroundImage')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          <TextField
            label="Landing Page URL"
            value={product.landingPageURL || ''}
            onChange={handleChange('landingPageURL')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          <TextField
            label="Landing Page Illustration URL"
            value={product.illustrationURL || ''}
            onChange={handleChange('illustrationURL')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />  
        </CardContent>
      </Card>
    </div>
  );
}

export default EditMembershipProduct;
