import React, { useState, useRef, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-coy.css';
// import 'prismjs/themes/prism-solarizedlight.css';

import Container from './components/container';
import addIcon from './icons/plus.png';
import fullscreenIcon from './icons/enter-fullscreen.png';
import exiFullscreenIcon from './icons/exit-fullscreen.png';
import Box from './components/box';
import Preview from './components/preview';

import { Items } from './components/shared/itemTypes';

import './pageBuilder.scss';

const styles = {
  header: {
    position: 'fixed',
    zIndex: 3,
    top: 0,
    left: 0,
    right: 0,
    height: 45,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 10px',
  },
  headerSpacer: {
    height: 50,
  },
  resizeHandle: {
    padding: 2,
    border: '1px solid rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    height: 80,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'col-resize',
    right: -7,
  },
  resizeHandleInner: {
    height: '100%',
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  leftHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  previewButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    height: '100%',
    width: 165,
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: 5,
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    border: 'none',
    fontSize: '16px',
    paddingTop: 7.5,
  },
  previewText: {
    marginTop: '8px',
    color: '#fff',
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  addButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    height: '100%',
    width: 32,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  addImage: {
    height: '90%',
    margin: '5%',
  },
  saveButton: {
    backgroundColor: '#28a745',
    height: '100%',
    width: 60,
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: 5,
    textAlign: 'center',
  },
  saveText: {
    marginTop: '8px',
    color: '#fff',
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  itemsContainer: {
    position: 'fixed',
    zIndex: 10,
    top: 45,
    right: 0,
    backgroundColor: '#fff',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'scroll',
  },
  itemsTabsContainer: {
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 5,
  },
  itemsTab: {
    flex: 1,
    textAlign: 'center',
    padding: 5,
    cursor: 'pointer',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    fontWeight: 'bold',
  },
  fullScreenToggle: {
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    zIndex: 2,
  },
  fullscreenIcon: {
    width: 15,
  },
  defaultPreview: {
    width: '100%',
    position: 'relative',
  },
  fullScreenPreview: {
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    top: 0,
    bottom: 0,
    zIndex: 100,
    overflowY: 'auto',
  },
};

function Page({ content, updateContent, close, saveModule, globalTemplates, css, updateCSS }) {
  const draggableRef = useRef(null);
  const showItemsRef = useRef(null);
  const [showItems, setShowItems] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewFlex, setPreviewFlex] = useState(50);
  const [editorFlex, setEditorFlex] = useState(50);
  const [previewFullScreen, setPreviewFullScreen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (draggableRef.current) {
      draggableRef.current.addEventListener('mousedown', dragStart);
    } else {
      draggableRef.current.removeEventListener('mousedown', dragStart);
    }
    // eslint-disable-next-line
  }, [draggableRef]);

  const dragStart = () => {
    document.addEventListener('mouseup', dragEnd);
    document.addEventListener('mousemove', dragging);
  };

  const dragEnd = () => {
    document.removeEventListener('mouseup', dragEnd);
    document.removeEventListener('mousemove', dragging);
  };

  const dragging = (e) => {
    const windowWidth = window.innerWidth;
    const width = showItemsRef.current ? windowWidth - 300 : windowWidth;
    const left = ((e.clientX / width) * 100);
    const right = 100 - left;

    setPreviewFlex(left);
    setEditorFlex(right);
  };

  const renderCurrentTab = () => {
    if (selectedTab === 0) {
      return (
        <div>
          {Items.map((item, i) => {
            return (
              <Box
                key={`item-${i}`}
                item={{...item}}
              />
            );
          })}
        </div>
      );
    }

    if (selectedTab === 1) {
      return (
        <div>
          {[ ...(globalTemplates || []) ].sort((a, b) => b.created - a.created).map((item, i) => {
            return (
              <Box
                key={`template-${i}`}
                item={{...item}}
              />
            );
          })}
        </div>
      );
    }

    if (selectedTab === 2) {
      return (
        <div>
          <Editor
            value={css}
            onValueChange={code => {
              updateCSS(code);
            }}
            highlight={code => highlight(code, languages.css, 'css')}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: 'whitesmoke',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              minHeight: 'calc(100vh - 105px)',
            }}
          />
        </div>
      );
    }

    return <div></div>
  };

  return (
    <div className="PageBuilder">
      <style>{css}</style>

      <DndProvider backend={HTML5Backend}>
        <div style={styles.header}>
          <div style={styles.leftHeaderContainer}>
            <button style={styles.previewButton} onClick={() => { setShowPreview(!showPreview) }}>
              {showPreview ? 'Hide' : 'Show'} Preview
            </button>
          </div>
          <div style={styles.actionsContainer}>
            <div style={styles.addButton} onClick={() => {
              const shouldShowItems = !showItems;
              setShowItems(shouldShowItems);

              showItemsRef.current = shouldShowItems;
            }}>
              <img src={addIcon} style={{ ...styles.addImage, transform: `rotate(${showItems ? 45 : 0}deg)` }} alt="tools toggle" />
            </div>

            <div style={styles.saveButton} onClick={close}>
              <div style={styles.saveText}>DONE</div>
            </div>
          </div>
        </div>

        <div
          className="page-builder-drag-items-container"
          style={{
            ...styles.itemsContainer,
            width: showItems ? 300 : 0,
            padding: showItems ? 10 : 0,
            borderWidth: showItems ? 1 : 0,
          }}
        >
          <div>
            <div style={styles.itemsTabsContainer}>
              <div
                style={{...styles.itemsTab, borderBottomColor: selectedTab === 0 ? '#00a0d2' : '#fff', color: selectedTab === 0 ? '#00a0d2' : '#000'}}
                onClick={() => { setSelectedTab(0) }}
              >
                General
              </div>
              <div
                style={{...styles.itemsTab, borderBottomColor: selectedTab === 1 ? '#00a0d2' : '#fff', color: selectedTab === 1 ? '#00a0d2' : '#000'}}
                onClick={() => { setSelectedTab(1) }}
              >
                Templates
              </div>
              <div
                style={{...styles.itemsTab, borderBottomColor: selectedTab === 2 ? '#00a0d2' : '#fff', color: selectedTab === 2 ? '#00a0d2' : '#000'}}
                onClick={() => { setSelectedTab(2) }}
              >
                CSS
              </div>
            </div>
            {renderCurrentTab()}
          </div>
        </div>

        <div style={styles.headerSpacer}></div>
        <div id="contentContainer">
          <div className={`${showItems ? 'items-open' : 'items-closed'}`} style={{position: 'relative', display: 'flex', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: 5}}>
            <div style={{display: showPreview ? 'flex' : 'none', flex: previewFlex, borderRight: '1px solid rgba(0, 0, 0, 0.1)', alignSelf: 'stretch', position: 'relative'}}>
              <div style={!previewFullScreen ? styles.defaultPreview : styles.fullScreenPreview}>
                <div style={styles.fullScreenToggle} onClick={() => { setPreviewFullScreen(!previewFullScreen) }}>
                  <img src={previewFullScreen ? exiFullscreenIcon : fullscreenIcon} style={styles.fullscreenIcon} alt="fullscreen" />
                </div>
                <Preview items={content.children} />
              </div>
              <div ref={draggableRef} style={styles.resizeHandle}>
                <div style={styles.resizeHandleInner}></div>
              </div>
            </div>
            <div style={{flex: showPreview ? editorFlex : 1}}>
              <Container
                content={content.children}
                saveModule={saveModule}
                updateContent={(updated) => {
                  updateContent({
                    ...content,
                    children: [ ...updated ],
                  });
                }}
              />
            </div>
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default Page;
