import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { ColorPicker, useColor, toColor } from '../../../shared/colorPalette';
import '../../../shared/colorPalette/css/styles.css';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './editAppBanner.scss';

const contentTypes = [
  'blog-post',
  'external-url',
  'academy'
];

function EditAppBanner({
  closeModal,
  save,
  bannerData,
}) {
  const [banner, setBanner] = useState({
    name: '',
    backgroundColor: '#FAF7E8',
    text: '',
    textColor: '#294634',
    buttonText: '',
    buttonTextColor: '#FAF7E8',
    buttonBackgroundColor: '#294634',
    type: 'external-url',
    externalUrl: '',
    resourceId: '',
    live: false,
  });
  const [backgroundColor, setBackgroundColor] = useColor('hex', '#294634');
  const [fontColor, setFontColor] = useColor('hex', '#FAF7E8');
  const [buttonBackgroundColor, setButtonBackgroundColor] = useColor('hex', '#FAF7E8');
  const [buttonFontColor, setButtonFontColor] = useColor('hex', '#294634');
  const [backgroundColorAnchorElem, setBackgroundColorAnchorElem] = useState(null);
  const [fontColorAnchorElem, setFontColorAnchorElem] = useState(null);
  const [buttonBackgroundColorAnchorElem, setButtonBackgroundColorAnchorElem] = useState(null);
  const [buttonFontColorAnchorElem, setButtonFontColorAnchorElem] = useState(null);

  useEffect(() => {
    setBanner(cloneDeep(bannerData));
    setBackgroundColor(toColor('hex', bannerData.backgroundColor));
    setFontColor(toColor('hex', bannerData.textColor));
    setButtonBackgroundColor(toColor('hex', bannerData.buttonBackgroundColor));
    setButtonFontColor(toColor('hex', bannerData.buttonTextColor));
  }, [bannerData]);


  const backgroundColorOpen = Boolean(backgroundColorAnchorElem);
  const backgroundColorId = backgroundColorOpen ? 'background-color-popover' : undefined;
  const fontColorOpen = Boolean(fontColorAnchorElem);
  const fontColorId = fontColorOpen ? 'font-color-popover' : undefined;
  const buttonBackgroundColorOpen = Boolean(buttonBackgroundColorAnchorElem);
  const buttonBackgroundColorId = buttonBackgroundColorOpen ? 'button-background-color-popover' : undefined;
  const buttonFontColorOpen = Boolean(buttonFontColorAnchorElem);
  const buttonFontColorId = buttonFontColorOpen ? 'button-font-color-popover' : undefined;

  return (
    <div className="EditHeaderBanner">
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            Edit Banner
          </Typography>
          <Button color="inherit" onClick={() => save(banner)}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container">
        <FormControlLabel
          control={
            <Switch
              checked={banner.live}
              onChange={(e) => setBanner({ ...banner, live: e.target.checked })}
              name="live"
              color="primary"
            />
          }
          label="Live"
        />

        <TextField
          label="Name (for internal use)"
          style={{ marginTop: 20 }}
          value={banner.name}
          onChange={(e) => setBanner({ ...banner, name: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Banner Text"
          style={{ marginTop: 20 }}
          value={banner.text}
          onChange={(e) => setBanner({ ...banner, text: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Button Text"
          style={{ marginTop: 20 }}
          value={banner.buttonText}
          onChange={(e) => setBanner({ ...banner, buttonText: e.target.value })}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />



        <div className="banner-settings-container">


          <div
            className="color-container"
            onClick={(e) => setBackgroundColorAnchorElem(e.currentTarget)}
          >
            <p>Background Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.backgroundColor }}
            ></div>
          </div>

          <Popover
            id={backgroundColorId}
            open={backgroundColorOpen}
            anchorEl={backgroundColorAnchorElem}
            onClose={() => setBackgroundColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={backgroundColor}
              onChange={setBackgroundColor}
              onChangeComplete={(color) => setBanner({ ...banner, backgroundColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>

          <div
            className="color-container last"
            onClick={(e) => setFontColorAnchorElem(e.currentTarget)}
          >
            <p>Text Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.textColor }}
            ></div>
          </div>

          <Popover
            id={fontColorId}
            open={fontColorOpen}
            anchorEl={fontColorAnchorElem}
            onClose={() => setFontColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={fontColor}
              onChange={setFontColor}
              onChangeComplete={(color) => setBanner({ ...banner, textColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>
        </div>

        <div className="banner-settings-container">


          <div
            className="color-container"
            onClick={(e) => setButtonBackgroundColorAnchorElem(e.currentTarget)}
          >
            <p>Button Background Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.buttonBackgroundColor }}
            ></div>
          </div>

          <Popover
            id={buttonBackgroundColorId}
            open={buttonBackgroundColorOpen}
            anchorEl={buttonBackgroundColorAnchorElem}
            onClose={() => setButtonBackgroundColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={buttonBackgroundColor}
              onChange={setButtonBackgroundColor}
              onChangeComplete={(color) => setBanner({ ...banner, buttonBackgroundColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>

          <div
            className="color-container last"
            onClick={(e) => setButtonFontColorAnchorElem(e.currentTarget)}
          >
            <p>Button Text Color</p>

            <div
              className="color-preview"
              style={{ backgroundColor: banner.buttonTextColor }}
            ></div>
          </div>

          <Popover
            id={buttonFontColorId}
            open={buttonFontColorOpen}
            anchorEl={buttonFontColorAnchorElem}
            onClose={() => setButtonFontColorAnchorElem(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ColorPicker
              width={250}
              height={125}
              color={buttonFontColor}
              onChange={setButtonFontColor}
              onChangeComplete={(color) => setBanner({ ...banner, buttonTextColor: color.hex })}
              hideHSV
              hideRGB
              alpha
            />
          </Popover>

        </div>

        <FormControl
          style={{ marginTop: 20 }}
          variant="outlined"
          margin="dense"
          className="day-text-field"
        >
          <InputLabel>
            Type
          </InputLabel>
          <Select
            label="Type"
            style={{ width: '100%' }}
            value={banner.type}
            onChange={(e) => setBanner({ ...banner, type: e.target.value })}
          >
            {contentTypes.map((type) => {
              return (
                <MenuItem key={`content-type-${type}`} value={type}>
                  <span style={{ textTransform: 'capitalize' }}>{type.split('-').join(' ')}</span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {banner.type === 'academy' ? null :

          banner.type === 'external-url' ?
            <TextField
              style={{ marginTop: 20 }}
              label="External URL"
              value={banner.externalUrl}
              onChange={(e) => setBanner({ ...banner, externalUrl: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            /> :
            <TextField
              style={{ marginTop: 20 }}
              label="Resource ID"
              value={banner.resourceId}
              onChange={(e) => setBanner({ ...banner, resourceId: e.target.value })}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
        }
      </div>
    </div>
  );
}

export default EditAppBanner
