import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './reorderProducts.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReorderProducts(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchSimplifiedProducts();
  }, []);

  const fetchSimplifiedProducts = async () => {
    const db = firebase.firestore();

    try {
      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const simplifiedProducts = shopSettingsSnapshot.data().simplifiedProducts;

      console.log('simplifiedProducts', simplifiedProducts);

      setProducts(simplifiedProducts);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error fetching products. Reload the page and try again.');
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedProducts = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    setProducts(reorderedProducts);
  };

  const save = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      await db.collection('config').doc('shop-settings').update({
        simplifiedProducts: products,
      });

      setLoading(false);
      setModalTitle('Success!');
      setModalText('Product ordering has been updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.')
    } catch (e) {
      console.log('error saving', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ReorderProducts">
      <div className="top-buttons-container">
        <Link to="/shop/products">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Products
          </Button>
        </Link>

        <Button 
          variant="contained"
          color="secondary"
          size="small"
          style={{marginRight: '10px', marginBottom: '15px'}}
          onClick={save}
        >
          Save
        </Button>
      </div>
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Reorder Products
        </Typography>
        <Typography variant="body2">
          Drag and drop to reorder
        </Typography>
      </Toolbar>

      <div className="content-container">
        <List style={{padding: 0}}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {products.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Divider />
                          <ListItem>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </div>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReorderProducts;
