import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  Button,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import './customThankYouPages.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomThankYouPages(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [pages, setPages] = useState([]);
  const [addNewPage, setAddNewPage] = useState(false);
  const [addModalTitle, setAddModalTitle] = useState('Add New');
  const [newPage, setNewPage] = useState({
    title: '',
    description: '',
    path: '',
    editorData: {
      type: 'top-layer',
      children: [],
    },
    sharedTemplates: [],
    customCSS: '',
    settings: {
      hideFooter: false,
      hideHeader: false,
    },
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });

  useEffect(() => {
    fetchPages(); // TODO:  paginate this
  }, []);

  const fetchPages = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('custom-thank-you-pages').get();
      const content =  querySnapshot.docs.map(doc => {
        const docData = doc.data();

        return {
          ...docData,
          id: doc.id,
        };
      }).sort((a, b) => {
        return b.created - a.created;
      });

      setPages(content);
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.alert('An error occurred fetching page content. Refresh the page and try again.');
    }
  };

  const saveNewPage = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingPageQuerySnapshot = await db.collection('custom-thank-you-pages').where('path', '==', newPage.path).get();

      if (existingPageQuerySnapshot.docs.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      const createdPage = await db.collection('custom-thank-you-pages').add(newPage);

      setLoading(false);
      closeAddNewPage();
      props.history.push(`/shop/custom-thank-you-pages/${createdPage.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new page. Please try again.');
    }
  };

  const closeAddNewPage = () => {
    setAddNewPage(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Pages">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Pages
          </Typography>
          <IconButton edge="start" color="inherit" onClick={(event) => {
            setNewPage({
              title: '',
              description: '',
              path: '',
              editorData: {
                type: 'top-layer',
                children: [],
              },
              sharedTemplates: [],
              customCSS: '',
              settings: {
                hideFooter: false,
                hideHeader: false,
              },
              live: false,
              created: Date.now(),
              updated: Date.now(),
              lastUpdatedBy: firebase.auth().currentUser.email,
            });
            setAddModalTitle('Add New');
            setAddNewPage(true);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>
        <List>
          {pages.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={p.title}
                    secondary={
                      <span>
                        <span style={{marginRight: 10}}><strong>ID:</strong> {p.id}</span>
                        <span style={{marginRight: 10}}><strong>Last updated:</strong> {moment(p.updated).calendar()}</span>
                        <span style={{marginRight: 10}}><strong>Last updated by:</strong> {p.lastUpdatedBy}</span>
                      </span>
                    }
                  />
                  <Link edge="start" to={`/shop/custom-thank-you-pages/${p.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton
                    color="inherit"
                    aria-label="Copy"
                    onClick={() => {
                      setNewPage({
                        ...p,
                        title: `${p.title} Copy`,
                        live: false,
                        created: Date.now(),
                        updated: Date.now(),
                        lastUpdatedBy: firebase.auth().currentUser.email,
                      });
                      setAddModalTitle('Copy');
                      setAddNewPage(true);
                    }}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                  {/* <IconButton edge="start" color="inherit" onClick={() => {  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={addNewPage} onClose={closeAddNewPage} TransitionComponent={Transition}>
        <DialogTitle>{addModalTitle} Page</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newPage.title}
            onChange={(e) => {
              setNewPage({
                ...newPage,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Description"
            value={newPage.description}
            onChange={(e) => {
              setNewPage({
                ...newPage,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newPage.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewPage({
                ...newPage,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewPage} color="primary" disabled={!newPage.title || !newPage.description || !newPage.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={closeAddNewPage} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomThankYouPages;
