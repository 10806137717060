import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Chart } from 'react-google-charts';

import './newSubscriptionHistory.scss';

function NewSubscriptionHistory({ reports }) {
  const [total, setTotal] = useState(0);
  const [newSubRevenue, setTotalNewSubRevenue] = useState(0);
  const [totalNewSubCostOfGoods, setTotalNewSubCostOfGoods] = useState(0);
  const [totalNewSubShipping, setTotalNewSubShipping] = useState(0);
  const [totalNewSubTax, setTotalNewSubTax] = useState(0);
  const [newSubRevenueArr, setNewSubRevenueArr] = useState([]);
  const [newSubOrderCountArr, setNewSubOrderCountArr] = useState([]);
  const [newSubProductSales, setNewSubProductSales] = useState([]);
  const [byNewSubProductValue, setByNewSubProductValue] = useState('Revenue');
  const [showNewSubOrderProductTable, setShowNewSubOrderProductTable] = useState(false);
  const [newSubCategorySales, setNewSubCategorySales] = useState([]);
  const [byNewSubCategoryValue, setByNewSubCategoryValue] = useState('Revenue');
  const [showNewSubOrderCategoryTable, setShowNewSubOrderCategoryTable] = useState(false);

  useEffect(() => {
    let total = 0;
    let newSubRevenue = 0;
    let newSubCostOfGoods = 0;
    let newSubShipping = 0;
    let newSubTax = 0;

    const newSubRevenueTotalArr = [];
    const newSubOrdersTotalArr = [];
    const newSubProductSalesMap = {};
    let newSubProductSalesArr = [];
    const newSubCategoriesSalesMap = {};
    let newSubCategoriesSalesArr = [];

    reports.forEach(r => {
      const date = new Date(r.created);
      date.setHours(23, 59, 59, 999);

      for (let key in r.newSubProductSalesMap) {
        if (!newSubProductSalesMap.hasOwnProperty(key)) {
          newSubProductSalesMap[key] = r.newSubProductSalesMap[key];
        } else {
          newSubProductSalesMap[key].amount += r.newSubProductSalesMap[key].amount;
          newSubProductSalesMap[key].costOfGoods += r.newSubProductSalesMap[key].costOfGoods;
          newSubProductSalesMap[key].quantity += r.newSubProductSalesMap[key].quantity;
        }
      }

      for (let key in r.newSubCategoriesSalesMap) {
        if (!newSubCategoriesSalesMap.hasOwnProperty(key)) {
          newSubCategoriesSalesMap[key] = r.newSubCategoriesSalesMap[key];
        } else {
          newSubCategoriesSalesMap[key].amount += r.newSubCategoriesSalesMap[key].amount;
          newSubCategoriesSalesMap[key].costOfGoods += r.newSubCategoriesSalesMap[key].costOfGoods;
          newSubCategoriesSalesMap[key].quantity += r.newSubCategoriesSalesMap[key].quantity;
        }
      }

      newSubRevenueTotalArr.push([date, r.newSubRevenue]);
      newSubOrdersTotalArr.push([date, r.newSubscriptionCount]);

      total += +r.newSubscriptionCount;
      newSubRevenue += +r.newSubRevenue;
      newSubCostOfGoods += r.newSubTotalCostOfGoods;
      newSubShipping += r.newSubShipping;
      newSubTax += r.newSubTax;
    });

    for (let key in newSubProductSalesMap) {
      const p = newSubProductSalesMap[key];
      newSubProductSalesArr.push([p.name, p.amount, p.quantity, p.costOfGoods]);
    }

    newSubProductSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    for (let key in newSubCategoriesSalesMap) {
      const p = newSubCategoriesSalesMap[key];
      newSubCategoriesSalesArr.push([key, p.amount, p.quantity, p.costOfGoods]);
    }

    newSubCategoriesSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    setTotal(total);
    setNewSubRevenueArr(newSubRevenueTotalArr);
    setNewSubOrderCountArr(newSubOrdersTotalArr);
    setTotalNewSubRevenue(newSubRevenue);
    setTotalNewSubCostOfGoods(newSubCostOfGoods);
    setTotalNewSubShipping(newSubShipping);
    setTotalNewSubTax(newSubTax);
    setNewSubProductSales(newSubProductSalesArr);
    setNewSubCategorySales(newSubCategoriesSalesArr);
  }, [reports]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="NewSubscriptionHistory">
      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Total
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {total}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(newSubRevenue)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Shipping
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalNewSubShipping)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Tax
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalNewSubTax)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Cost Of Goods
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalNewSubCostOfGoods)}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{margin: '30px 0 40px'}}>
        <Divider/>
      </div>

      <div>
        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Revenue',
            ],
            ...newSubRevenueArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Revenue',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Revenue' },
            },
            axes: {
              y: {
                Revenue: { label: 'Revenue ($)' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div style={{margin: '40px 0'}}>
          <Divider/>
        </div>

        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'New Subscriptions',
            ],
            ...newSubOrderCountArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Number of New Subscriptions',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Subscriptions' },
            },
            axes: {
              y: {
                Subscriptions: { label: 'No. Subscriptions' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Product Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byNewSubProductValue}
            exclusive
            onChange={(e, newValue) => {
              const newSubProductSalesCopy = [ ...newSubProductSales ];
              newSubProductSalesCopy.sort((a, b) => {
                let index = 1;

                if (newValue === 'Quantity') {
                  index = 2;
                }

                if (newValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setNewSubProductSales(newSubProductSalesCopy);
              setByNewSubProductValue(newValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Product', byNewSubProductValue],
              ...newSubProductSales.map(p => {
                let index = 1;

                if (byNewSubProductValue === 'Quantity') {
                  index = 2;
                }

                if (byNewSubProductValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byNewSubProductValue} by Product`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showNewSubOrderProductTable}
              onChange={(e) => {
                setShowNewSubOrderProductTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showNewSubOrderProductTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="products table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newSubProductSales.map((p, i) => (
                  <TableRow key={`newSubProductSales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Category Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byNewSubCategoryValue}
            exclusive
            onChange={(e, newValue) => {
              const newSubCategorySalesCopy = [ ...newSubCategorySales ];
              newSubCategorySalesCopy.sort((a, b) => {
                let index = 1;

                if (newValue === 'Quantity') {
                  index = 2;
                }

                if (newValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setNewSubCategorySales(newSubCategorySalesCopy);
              setByNewSubCategoryValue(newValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Category', byNewSubCategoryValue],
              ...newSubCategorySales.map(p => {
                let index = 1;

                if (byNewSubCategoryValue === 'Quantity') {
                  index = 2;
                }

                if (byNewSubCategoryValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byNewSubCategoryValue} by Category`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showNewSubOrderCategoryTable}
              onChange={(e) => {
                setShowNewSubOrderCategoryTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showNewSubOrderCategoryTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="categories table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newSubCategorySales.map((p, i) => (
                  <TableRow key={`newSubCategorySales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>
    </div>
  );
}

export default NewSubscriptionHistory;
