import React from 'react';
import RatingStars from './ratingStars';

import './statistic-item.scss';

const formatQuantity = (number) => {
  if (number < 1000000) {
    return new Intl.NumberFormat().format(number);
  } else {
    const result = number / 1000000;

    return result.toLocaleString() + ' Million';
  }
};

export const StatisticItem = ({ data }) => {
  const { title, imageUrl, quantity, isRating, ratingValue } = data;

  return (
    <article className="StatisticItemContainer">
      <img className="StatisticItemImage" src={imageUrl} alt={imageUrl} />
      <div className="StatisticContentContainer">
        <p className="StatisticItemQuantity">{formatQuantity(quantity)}+</p>

        {title ? <p className="StatisticItemTitle">{title}</p> : null}

        {isRating && ratingValue ? (
          <RatingStars
            numberOfStars={5}
            rating={Number(ratingValue)}
            starRatedColor="#f7be1b"
            starEmptyColor="#FAF7E8"
            starDimension="17px"
            starSpacing="0px"
          />
        ) : null}

        {isRating && ratingValue ? (
          <p className="StatisticItemStarsQuantity">
            {ratingValue}-star reviews
          </p>
        ) : null}
      </div>
    </article>
  );
};
