import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import EditFreeProduct from './editFreeProduct/editFreeProduct';
import './freeProducts.scss';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FreeProducts(props) {
  const [loading, setLoading] = useState(true);
  const [freeProducts, setFreeProducts] = useState([]);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    getFreeProducts();
  }, []);

  const getFreeProducts = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();

      setFreeProducts(querySnapshot.data().freeProducts);
      setSimplifiedProducts(querySnapshot.data().simplifiedProducts);
      setProductCategories(querySnapshot.data().productCategories);
      setProductTags(contentQuerySnapshot.data().tags);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching Free Products. Refresh the page and try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const saveProduct = async (product) => {
    if (product.addToSubscription) {
      let hasDigitalProduct = false;

      product.productOptions.forEach(p => {
        if (p.isDigital) {
          hasDigitalProduct = true;
        }
      });

      if (hasDigitalProduct) {
        setModalTitle('Notice:');
        setModalText('Digital products cannot be added to subscriptions. Turn off "Add to Subscription" or remove digital production options to proceed.');
        return;
      }
    }

    setEditing(false);
    setLoading(true);

    const products = [ ...freeProducts ];

    if (editIndex !== -1) {
      products[editIndex] = product;
    } else {
      products.push({
        ...product,
        id: uuidv4(),
      });
    }

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        freeProducts: products,
      });

      setFreeProducts(products);
      setLoading(false);
      setEditIndex(-1);
    } catch (e) {
      setLoading(false);
      setEditIndex(-1);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('An error occurred saving Free Products. Refresh the page and try again.');
    }
  };

  const deleteProduct = async () => {
    setLoading(true);

    const products = [ ...freeProducts ];

    products.splice(deleteIndex, 1);

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        freeProducts: products,
      });

      setFreeProducts(products);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred deleting the Free Product. Refresh the page and try again.');
    }
  };

  return (
    <div className="FreeProducts">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Free Products
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => {
            setEditProduct({
              active: false,
              automaticallyAddToCart: false,
              productOptions: [],
              rules: [],
            });
            setEditIndex(-1);
            setEditing(true);
          }} aria-label="Delete">
            <AddIcon />
          </IconButton>
        </Toolbar>
        <List>
          {freeProducts.map((p, i) => {
            return (
              <div key={`free-product-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={p.productOptions.map(o => o.name).join(', ')}
                    secondary={
                      <span>Status:
                        <span style={{color: p.active ? 'rgb(126, 210, 66)' : 'red'}}>
                          {p.active ? ' Active' : ' Disabled'}
                        </span>
                        <span style={{marginLeft: 10}}>
                          ID: {p.id}
                        </span>
                      </span>
                    }
                  />
                  <IconButton edge="start" style={{marginRight: 15}} color="inherit" onClick={() => {
                    setEditProduct(p);
                    setEditIndex(i);
                    setEditing(true);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={editing} onClose={() => {
        setEditing(false);
        setEditIndex(-1);
      }} TransitionComponent={Transition}>
        <EditFreeProduct
          closeModal={() => {
            setEditing(false);
            setEditIndex(-1);
          }}
          save={saveProduct}
          product={editProduct}
          simplifiedProducts={simplifiedProducts}
          productCategories={productCategories}
          productTags={productTags}
        ></EditFreeProduct>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Free Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this free product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteProduct} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FreeProducts;
