import { useFieldArray } from 'react-hook-form';

export const useProductsFields = (control) => {
  const {
    append,
    remove,
    fields: productsItems,
    move: moveProductItem,
  } = useFieldArray({
    control,
    name: 'productsSection.items',
  });

  const appendProductItem = () => {
    append({
      title: '',
      content: '',
      imageUrl: '',
      linkText: '',
      linkUrl: '',
    });
  };

  const removeProductItem = (index) => {
    remove(index);
  };

  return {
    productsItems,
    appendProductItem,
    removeProductItem,
    moveProductItem,
  };
};
