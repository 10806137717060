import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './editMembershipProductV2.scss';
import { config } from '../../../../config';
import CourseModules from '../../shared/courseModules';
import CourseSections from '../../shared/courseSections';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditMembershipProductV2({ match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [course, setCourse] = useState({
    id: '',
    name: '',
    backgroundImageSmall: '',
    backgroundImageMedium: '',
    backgroundImageLarge: '',
    backgroundImageWithText: '',
    description: '',
    modules: [],
    enabled: false,
    position: 0,
  });
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    try {
      const courseId = match.params.id;
      const snapshot = await firebase.firestore().collection('membership-content-v2').doc(courseId).get();
      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const categoryRef = await firebase.firestore().collection('categories').doc('behaviors').get();
      const categories = categoryRef.data();
      const products = shopSettingsSnapshot.data().simplifiedProducts.filter(p => {
        return !p.isDigital;
      }).map(p => {
        return {
          displayName: p.name || '',
          name: p.name,
          url: `https://${config.domain}.com/product/${p.path}/`,
          value: p.price || '',
          image: p.image ? p.image.url : '',
          id: p.id,
        };
      }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      
      const currentCourse = snapshot.data();

      if (!currentCourse) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('This course was not found, please try again.');
        return;
      }

      setCategories(categories.behaviors);
      setProducts(products);
      setCourse({
        ...currentCourse,
        id: courseId,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this course, please try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      const courseId = match.params.id;
      await firebase.firestore().collection('membership-content-v2').doc(courseId).update(course);

      setLoading(false);
      setModalTitle('Success!');
      setModalText('This course has been saved!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this course, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditMembershipProductV2">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/academy-content/member-content-v2">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Courses
          </Button>
        </Link>
      </div>

      <Card style={{marginTop: '10px'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {course.name}
          </Typography>

          <Button 
            style={{ marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => save()}
          >
            Save
          </Button>
        </Toolbar>

        <Divider/>

        <CardContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={course.enabled}
                  onChange={(e) => {
                    setCourse({
                      ...course,
                      enabled: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </FormGroup>

          <TextField
            label="Course Name"
            value={course.name}
            onChange={(e) => {
              setCourse({
                ...course,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Small Background Image URL"
            value={course.backgroundImageSmall}
            onChange={(e) => {
              setCourse({
                ...course,
                backgroundImageSmall: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Medium Background Image URL"
            value={course.backgroundImageMedium}
            onChange={(e) => {
              setCourse({
                ...course,
                backgroundImageMedium: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Large Background Image URL"
            value={course.backgroundImageLarge}
            onChange={(e) => {
              setCourse({
                ...course,
                backgroundImageLarge: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Background Image With Text URL"
            value={course.backgroundImageWithText}
            onChange={(e) => {
              setCourse({
                ...course,
                backgroundImageWithText: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Description"
            value={course.description}
            onChange={(e) => {
              setCourse({
                ...course,
                description: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <CourseSections
            sections={course.sections || []}
            updateSections={(sections) => {
              setCourse({
                ...course,
                sections,
              });
            }}
            modules={course.modules}
          />

          <CourseModules
            modules={course.modules}
            updateModules={(modules) => {
              setCourse({
                ...course,
                modules,
              });
            }}
            sections={course.sections || []}
            categories={categories}
            products={products}
            isAcademyPlus={true}
          />
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditMembershipProductV2;
