import React, { useState, useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Toolbar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './wholesaler.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Wholesaler(props) {
  const [loading, setLoading] = useState(true);
  const [wholesaler, setWholesaler] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [disableWholesalerOpen, setDisableWholesalerOpen] = useState(false);

  useEffect(() => {
    fetchWholesaler();
  }, []);

  const fetchWholesaler = async () => {
    const id = props.match.params.id;

    if (id === undefined) {
      props.history.replace('/wholesale/wholesalers');
      return;
    }

    try {
      const db = firebase.firestore();
      const userSnapshot = await db.collection('users').doc(id).get();
      const userData = userSnapshot.data();

      setWholesaler({
        ...userData,
        id,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching the wholesaler. Refresh and try again.');
    }
  };

  const removeWholesaleAccess = async () => {
    setDisableWholesalerOpen(false);
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/roles/${wholesaler.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: [],
      });

      await firebase.firestore().collection('users').doc(wholesaler.id).update({
        roles: [],
      });

      setLoading(false);
      props.history.replace('/wholesale/wholesalers');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error removing wholesale access from this user, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Wholesaler">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/wholesale/wholesalers">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Wholesalers
          </Button>
        </Link>
      </div>

      {!wholesaler ? null :
        <div>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography style={{marginRight: 10}}>
              Name: <strong>{wholesaler.userName || 'Name not set'}</strong>
            </Typography>

            <Button 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => { setDisableWholesalerOpen(true) }}
            >
              Remove Wholesale Access
            </Button>
          </Toolbar>
        </div>
      }

      <Dialog open={disableWholesalerOpen} onClose={() => { setDisableWholesalerOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Remove Wholesaler?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove wholesale access for this user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={removeWholesaleAccess} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDisableWholesalerOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Wholesaler;
