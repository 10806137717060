import React, {
  useEffect,
  useState
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async (newStart, newEnd) => {
    const end = newEnd || new Date();
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date(end.getTime() - (7 * 24 * 60 * 60 * 1000));
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);

    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection('orders')
        .where('createdAt', '>=', start.getTime())
        .where('createdAt', '<=', end.getTime())
        .get();
      
      const v2QuerySnapshot = await db.collection('orders-v2')
        .where('created', '>=', firebase.firestore.Timestamp.fromDate(start))
        .where('created', '<=', firebase.firestore.Timestamp.fromDate(end))
        .where('thankYouPage', '==', 'app-purchase')
        .get();

      let revenue = 0;
      let orders = querySnapshot.docs.map(doc => {
        const data = doc.data();

        revenue += +data.total;

        return data;
      });

      const statusesToSkip = [
        'PENDING_PAYMENT',
        'READY_FOR_PAYPAL_PAYMENT',
        'READY_FOR_STRIPE_PAYMENT',
        'PAYMENT_FAILED',
      ];

      const v2Orders = v2QuerySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).filter(order => {
        return !statusesToSkip.includes(order.status);
      });

      v2Orders.forEach(order => {
        revenue += +order.total;

        orders.push({
          subscriptions: order.subscriptions,
          email: order.email,
          total: order.total,
          createdAt: order.created.seconds * 1000,
        });
      });

      orders = orders.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });

      setTotalRevenue(revenue.toFixed(2));
      setOrders(orders);
    } catch (e) {
      console.log(e.toString());
    }
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <div style={{marginTop: '90px', display: 'flex', paddingLeft: '20px', position: 'relative', zIndex: 1000}}>
        <div className="date-picker-container">
          <DatePicker
            selected={startDate}
            onChange={(date) => { fetchOrders(date, endDate) }}
          />
        </div>
        <div className="date-divider">-</div>
        <div className="date-picker-container">
          <DatePicker
            selected={endDate}
            onChange={(date) => { fetchOrders(startDate, date) }}
          />
        </div>
      </div>

      <div className="order-cards-container" style={{textAlign: 'left', marginTop: '20px', paddingLeft: '15px', paddingRight: '15px'}}>
        <div className="order-cards">
          <Card>
            <CardContent>
              <div style={{fontWeight: 'bold'}}>Transactions:</div>
              <p style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px'}}>
                {orders.length}
              </p>
            </CardContent>
          </Card>
        </div>
        <div className="order-cards">
          <Card>
            <CardContent>
              <div style={{fontWeight: 'bold'}}>Total Revenue:</div>
              <p style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px'}}>
                ${totalRevenue}
              </p>
            </CardContent>
          </Card>
        </div>
        <div className="order-cards">
          <Card>
            <CardContent>
              <div style={{fontWeight: 'bold'}}>Avg. Order Value:</div>
              <p style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px'}}>
                ${totalRevenue && (totalRevenue / orders.length).toFixed(2)}
              </p>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{marginTop: 20, marginLeft: 5, marginRight: 5, paddingLeft: '15px', paddingRight: '15px'}}>
        <MUIDataTable
          data={orders.map(o => {
            const hasSubscriptions = o.subscriptions.length ? 'Yes' : 'No';
            return [o.email, `$${o.total}`, hasSubscriptions, moment(o.createdAt).format('MMMM Do YYYY, h:mm a')];
          })}
          columns={['Email', 'Total', 'Subscriptions', 'Date']}
        />
      </div>
    </div>
  );
}

export default Orders;
