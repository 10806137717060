import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import './editSubscriptionBox.scss';
import DeletableChip from '../../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const subscriptionIntervals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function EditSubscriptionBox({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptionBox, setSubscriptionBox] = useState({
    name: '',
    orders: [],
    interval: 1,
    period: 'month',
    hasFixedPrice: false,
    price: 0,
    isOnSale: false,
    salePrice: 0,
    created: Date.now(),
    lastModified: Date.now(),
    lastModifiedBy: firebase.auth().currentUser.email,
  });
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);

  useEffect(() => {
    fetchSubscriptionBox();
  }, []);

  const fetchSubscriptionBox = async () => {
    try {
      const id = match.params.id;

      if (id === undefined) {
        history.replace('/shop/subscription-boxes');
        return;
      }

      const subscriptionBoxSnapshot = await firebase.firestore().collection('subscription-boxes').doc(id).get();
      const subscriptionBoxData = subscriptionBoxSnapshot.data();

      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const shopSettingsData = shopSettingsSnapshot.data();

      setSubscriptionBox(subscriptionBoxData);
      setSimplifiedProducts(shopSettingsData.simplifiedProducts);
      setLoading(false);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching the subscription box. Please try again.');
    }
  };

  const saveSubscriptionBox = async () => {
    setLoading(true);

    try {
      const id = match.params.id;
      const modifiedData = {
        ...subscriptionBox,
        lastModified: Date.now(),
        lastModifiedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('subscription-boxes').doc(id).update(modifiedData);

      setSubscriptionBox(modifiedData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the subscription box. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditSubscriptionBox">
      {renderLoading()}
      <Link to="/shop/subscription-boxes">
        <Button 
          variant="contained"
          color="primary"
          size="small"
          style={{marginRight: '10px', marginBottom: '15px'}}
          startIcon={<ArrowBackIcon />}
        >
          All Subscription Boxes
        </Button>
      </Link>

      <Typography variant="body2">
        <span className="updated-detail-spacing">
          <strong>Last Updated:</strong> {moment(subscriptionBox.lastModified).format('lll')}
        </span>
        <span className="updated-detail-spacing">
          <strong>Last Updated By:</strong> {subscriptionBox.lastModifiedBy}
        </span>
      </Typography>

      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Subscription Box: <strong>{subscriptionBox.name}</strong>
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={saveSubscriptionBox}
        >
          Save
        </Button>
      </Toolbar>

      <div className="content-container">
        <TextField
          label="Name"
          value={subscriptionBox.name}
          onChange={(e) => {
            setSubscriptionBox({
              ...subscriptionBox,
              name: e.target.value,
            });
          }}
          margin="dense"
          variant="outlined"
          type="text"
          className="day-text-field"
        />

        <div className="section-container">
          <div className="section-label">Price Settings</div>
          {/* <div className="section-label">Fixed Price Settings</div>

          <FormControlLabel
            style={{marginTop: 8, marginBottom: 4}}
            control={
              <Switch
                checked={subscriptionBox.hasFixedPrice}
                onChange={(e) => {
                  setSubscriptionBox({
                    ...subscriptionBox,
                    hasFixedPrice: e.target.checked,
                  });
                }}
                name="hasFixedPrice"
                color="primary"
              />
            }
            label="Fixed price across renewals"
          /> */}

          {!subscriptionBox.hasFixedPrice ? null :
            <div>
              <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                <OutlinedInput
                  value={subscriptionBox.price}
                  onChange={(e) => {
                    setSubscriptionBox({
                      ...subscriptionBox,
                      price: e.target.value,
                    });
                  }}
                  margin="dense"
                  label="Price"
                  variant="outlined"
                  type="number"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  style={{maxWidth: 300, marginRight: 15}}
                />
              </FormControl>

              <div>
                <FormControlLabel
                  style={{marginTop: 8, marginBottom: 4}}
                  control={
                    <Switch
                      checked={subscriptionBox.isOnSale}
                      onChange={(e) => {
                        setSubscriptionBox({
                          ...subscriptionBox,
                          isOnSale: e.target.checked,
                        });
                      }}
                      name="isOnSale"
                      color="primary"
                    />
                  }
                  label="Is On Sale"
                />
              </div>

              {!subscriptionBox.isOnSale ? null :
                <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                  <InputLabel htmlFor="outlined-adornment-amount">Sale Price</InputLabel>
                  <OutlinedInput
                    value={subscriptionBox.salePrice}
                    onChange={(e) => {
                      setSubscriptionBox({
                        ...subscriptionBox,
                        salePrice: e.target.value,
                      });
                    }}
                    margin="dense"
                    label="Sale Price"
                    variant="outlined"
                    type="number"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    style={{maxWidth: 300, marginRight: 15}}
                  />
                </FormControl>
              }
            </div>
          }
        </div>

        <div className="section-container">
          <div className="section-label">Time Between Renewals</div>

          <FormControl
            style={{marginTop: 8, marginBottom: 4, width: 75}}
            variant="outlined"
          >
            <Select
              value={subscriptionBox.interval}
              onChange={(e) => {
                setSubscriptionBox({
                  ...subscriptionBox,
                  interval: e.target.value,
                });
              }}
              margin="dense"
            >
              {subscriptionIntervals.map((value) => {
                return <MenuItem key={`interval-${value}`} value={value}>{value}</MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{marginTop: 8, marginBottom: 4, width: 125}}
            variant="outlined"
          >
            <Select
              value={subscriptionBox.period}
              onChange={(e) => {
                setSubscriptionBox({
                  ...subscriptionBox,
                  period: e.target.value,
                });
              }}
              margin="dense"
            >
              {['day', 'week', 'month', 'year'].map((value) => {
                return <MenuItem key={value} value={value}><span style={{textTransform: 'capitalize'}}>{value}{subscriptionBox.interval > 1 ? 's' : ''}</span></MenuItem>
              })}
            </Select>
          </FormControl>
        </div>

        <div className="section-container">
          <div className="section-label">Order Settings</div>

          <div className="row">
            <div></div>

            <Button 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSubscriptionBox({
                  ...subscriptionBox,
                  orders: [
                    ...subscriptionBox.orders,
                    {
                      products: [],
                      price: 0,
                      isOnSale: false,
                      salePrice: 0,
                    },
                  ],
                });
              }}
            >
              Add Order
            </Button>
          </div>

          {subscriptionBox.orders.map((order, i) => {
            return (
              <div key={`order-${i}`} className="product-row">
                <div style={{flex: 1}}>
                  <Typography variant="subtitle1" style={{marginBottom: 10}}><strong>Order {i + 1}</strong></Typography>
                  {subscriptionBox.hasFixedPrice ? null :
                    <>
                      <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                          value={order.price}
                          onChange={(e) => {
                            const orders = [ ...subscriptionBox.orders ];

                            orders[i] = {
                              ...orders[i],
                              price: e.target.value,
                            };

                            setSubscriptionBox({
                              ...subscriptionBox,
                              orders,
                            });
                          }}
                          margin="dense"
                          label="Price"
                          variant="outlined"
                          type="number"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          style={{maxWidth: 300, marginRight: 15}}
                        />
                      </FormControl>

                      <div>
                        <FormControlLabel
                          style={{marginTop: 8, marginBottom: 4}}
                          control={
                            <Switch
                              checked={order.isOnSale}
                              onChange={(e) => {
                                const orders = [ ...subscriptionBox.orders ];

                                orders[i] = {
                                  ...orders[i],
                                  isOnSale: e.target.checked,
                                };

                                setSubscriptionBox({
                                  ...subscriptionBox,
                                  orders,
                                });
                              }}
                              name="isOnSale"
                              color="primary"
                            />
                          }
                          label="Is On Sale"
                        />
                      </div>

                      {!order.isOnSale ? null :
                        <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                          <InputLabel htmlFor="outlined-adornment-amount">Sale Price</InputLabel>
                          <OutlinedInput
                            value={order.salePrice}
                            onChange={(e) => {
                              const orders = [ ...subscriptionBox.orders ];

                              orders[i] = {
                                ...orders[i],
                                salePrice: e.target.value,
                              };

                              setSubscriptionBox({
                                ...subscriptionBox,
                                orders,
                              });
                            }}
                            margin="dense"
                            label="Sale Price"
                            variant="outlined"
                            type="number"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            style={{maxWidth: 300, marginRight: 15}}
                          />
                        </FormControl>
                      }
                    </>
                  }

                  <FormControl style={{ marginTop: 10, marginBottom: 10, width: '100%' }}>
                    <InputLabel htmlFor="select-multiple-bundle-product-chip">Products</InputLabel>
                    <Select
                      multiple
                      value={order.products}
                      onChange={(e) => {
                        const orders = [ ...subscriptionBox.orders ];

                        orders[i] = {
                          ...orders[i],
                          products: e.target.value,
                        };

                        setSubscriptionBox({
                          ...subscriptionBox,
                          orders,
                        });
                      }}
                      input={<Input id="select-multiple-bundle-product-chip" />}
                      renderValue={selected => (
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          {selected.map(value => (
                            <DeletableChip
                              key={value.id}
                              label={value.name}
                              style={{margin: 2}}
                              color="primary"
                              onDelete={() => {
                                const index = order.products.indexOf(value);

                                if (index === -1) {
                                  return;
                                }

                                const productsCopy = order.products.slice();
                                productsCopy.splice(index, 1);

                                const orders = [ ...subscriptionBox.orders ];
                                orders[i] = {
                                  ...orders[i],
                                  products: productsCopy,
                                };

                                setSubscriptionBox({
                                  ...subscriptionBox,
                                  orders,
                                });
                              }}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {simplifiedProducts.filter(product => {
                        const found = order.products.find(p => p.id === product.id);
                        return !found && !product.isDigital && !product.isGiftCard;
                      }).sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                      }).map(product => (
                        <MenuItem key={product.id} value={product}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <IconButton
                  edge="start"
                  style={{marginLeft: 10}}
                  color="inherit"
                  onClick={() => {
                    const orders = [ ...subscriptionBox.orders ];

                    orders.splice(i, 1);

                    setSubscriptionBox({
                      ...subscriptionBox,
                      orders,
                    });
                  }}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditSubscriptionBox;
