import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './editFreeCourse.scss';
import { config } from '../../../../config';

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditFreeCourse(props) {
  const [loading, setLoading] = useState(true);
  const [takenUrls, setTakenUrls] = useState([]);
  const [hasTakenUrl, setHasTakenUrl] = useState(false);
  const [course, setCourse] = useState({
    name: '',
    enabled: false,
    description: '',
    backgroundImage: '',
    webDomesticListId: '',
    webForeignListId: '',
    appDomesticListId: '',
    appForeignListId: '',
    webPath: '',
    layout: 'list',
    modules: [],
  });
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchCourse();
  }, []);
  
  const fetchCourse = async () => {
    const db = firebase.firestore();

    try {
      const courseId = props.match.params.id;
      const snapshot = await db.collection('free-courses').get();

      let currentCourse = null;
      const otherCourseUrls = [];

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (doc.id === courseId) {
          currentCourse = {
            ...data,
            id: doc.id,
          };
        } else {
          otherCourseUrls.push(data.webPath);
        }
      });

      if (!currentCourse) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('This course was not found, please try again.');
        return;
      }

      setTakenUrls(otherCourseUrls);
      setCourse(currentCourse);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this course, please try again.');
    }
  };

  const handleChange = name => event => {
    setCourse({
      ...course,
      [name]: event.target.value,
    });
  };

  const addModuleHeading = () => {
    const modules = cloneDeep(course.modules);

    modules.push('');

    const updatedCourse = {
      ...course,
      modules,
    };

    setCourse(updatedCourse);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);

      if (hasTakenUrl) {
        setModalTitle('Notice:');
        setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
      } else {
        save(updatedCourse);
      }
    }, 0);
  };

  const addModule = () => {
    const modules = cloneDeep(course.modules);

    modules.push({
      title: 'Module Title',
      imageUrl: '',
      backgroundImage: '',
      description: '',
      adId: '',
      sections: [],
      categories: [''],
      id: uuidv4(),
    });

    const updatedCourse = {
      ...course,
      modules,
    };

    setCourse(updatedCourse);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);

      if (hasTakenUrl) {
        setModalTitle('Notice:');
        setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
      } else {
        save(updatedCourse);
      }
    }, 0);
  };

  const handleHeadingChange = (value, i) => {
    const modules = cloneDeep(course.modules);
    modules[i] = value;

    setCourse({
      ...course,
      modules,
    });
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const moveItemUp = (i) => {    
    const modules = cloneDeep(course.modules);
    const itemToMoveUp = modules[i];
    const itemToMoveDown = modules[i - 1];

    modules[i] = itemToMoveDown;
    modules[i - 1] = itemToMoveUp;

    const updatedCourse = {
      ...course,
      modules,
    };

    setCourse(updatedCourse);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const moveItemDown = (i) => {
    const modules = cloneDeep(course.modules);
    const itemToMoveDown = modules[i];
    const itemToMoveUp = modules[i + 1];

    modules[i] = itemToMoveUp;
    modules[i + 1] = itemToMoveDown;

    const updatedCourse = {
      ...course,
      modules,
    };

    setCourse(updatedCourse);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const confirmDelete = () => {
    const modules = cloneDeep(course.modules);
    modules.splice(deleteIndex, 1);

    const updatedCourse = {
      ...course,
      modules,
    };

    setCourse(updatedCourse);
    setDeleteIndex(-1);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const save = async (updated) => {
    setLoading(true);

    const courseToSave = updated || course;

    try {
      await firebase.firestore().collection('free-courses').doc(courseToSave.id).update(courseToSave);

      const token = await firebase.auth().currentUser.getIdToken();
      const method = courseToSave.enabled ? 'POST' : 'DELETE';
      await axios(`${config.gateway}/course-service/v1/courses/${courseToSave.id}/search`, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setLoading(false);
      setModalTitle('Success!');
      setModalText('This course has been saved!');
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this course, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditFreeCourse">
      {renderLoading()}

      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        <p>
          <Link to={`/academy-content/free-courses`} style={{color: 'blue', textDecoration: 'none'}}>Free Courses</Link> / {course.name}
        </p>
      </div>

      <Card className="drag-card" style={{marginTop: '10px', marginLeft: '20px'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {course.name}
          </Typography>

          <Button 
            style={{ marginRight: 10 }}
            variant="contained"
            color="primary"
            disabled={hasTakenUrl}
            onClick={() => save()}
          >
            Save
          </Button>
        </Toolbar>

        <Divider/>

        <CardContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={course.enabled}
                  onChange={(e) => {
                    setCourse({
                      ...course,
                      enabled: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </FormGroup>

          <TextField
            label="Course Name"
            value={course.name}
            onChange={handleChange('name')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            style={{width: '100%'}}
            label="Video Header (mobile)"
            value={course.videoHeader || ''}
            onChange={handleChange('videoHeader')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={course.description}
            onChange={handleChange('description')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Background Image URL"
            value={course.backgroundImage}
            onChange={handleChange('backgroundImage')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <div className="input-row">
            <FormControl variant="outlined" className="row-item">
              <InputLabel>URL Path</InputLabel>
              <OutlinedInput
                value={course.webPath}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();

                  setHasTakenUrl(takenUrls.includes(value));

                  setCourse({
                    ...course,
                    webPath: value,
                  });
                }}
                margin="dense"
                label="URL Path"
                variant="outlined"
                type="text"
                error={hasTakenUrl}
                startAdornment={<InputAdornment style={{marginRight: 0}} position="start">course/</InputAdornment>}
              />
              {!hasTakenUrl ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
            </FormControl>

            <FormControl variant="outlined" className="row-item">
              <InputLabel>
                Layout
              </InputLabel>
              <Select
                label="Layout"
                margin="dense"
                value={course.layout}
                onChange={handleChange('layout')}
              >
                <MenuItem value={'calendar'}>Calendar</MenuItem>
                <MenuItem value={'list'}>List</MenuItem>
              </Select>
            </FormControl>
          </div>

          <TextField
            label="Landing Page URL"
            value={course.landingPageURL || ''}
            onChange={handleChange('landingPageURL')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Landing Page Illustration URL"
            value={course.illustrationUrl || ''}
            onChange={handleChange('illustrationUrl')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <div className="section-container">
            <div className="section-label">Klaviyo Website List IDs</div>
            <div className="input-row">
              <TextField
                label="Domestic"
                value={course.webDomesticListId}
                onChange={handleChange('webDomesticListId')}
                margin="dense"
                variant="outlined"
                className="row-item"
              />

              <TextField
                label="Foreign"
                value={course.webForeignListId}
                onChange={handleChange('webForeignListId')}
                margin="dense"
                variant="outlined"
                className="row-item"
              />
            </div>
          </div>

          <div className="section-container">
            <div className="section-label">Klaviyo App List IDs</div>
            <div className="input-row">
              <TextField
                label="Domestic"
                value={course.appDomesticListId}
                onChange={handleChange('appDomesticListId')}
                margin="dense"
                variant="outlined"
                className="row-item"
              />

              <TextField
                label="Foreign"
                value={course.appForeignListId}
                onChange={handleChange('appForeignListId')}
                margin="dense"
                variant="outlined"
                className="row-item"
              />
            </div>
          </div>

          <div className="modules-container">
            <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="subtitle2">
                Modules
              </Typography>

              <div>
                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={addModuleHeading}
                >
                  Add Module Heading
                </Button>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={addModule}
                >
                  Add Module
                </Button>
              </div>
            </Toolbar>

            <List>
              {course.modules.map((m, i) => {
                const isHeading = typeof m === 'string';

                return (
                  <div key={`module-${i}`}>
                    <Divider />
                    <ListItem>
                      {
                        isHeading ?
                        <TextField
                          style={{width: '100%'}}
                          label="Module Heading"
                          value={m}
                          onChange={(e) => { handleHeadingChange(e.target.value, i) }}
                          margin="dense"
                          variant="outlined"
                          className="day-text-field condensed-text-field"
                        /> :
                        <ListItemText primary={m.title} secondary={m.description} />
                      }

                      {renderUpArrow(i)}

                      {renderDownArrow(i, course.modules.length - 1)}

                      {isHeading ? null : <Link to={`/academy-content/free-courses/${props.match.params.id}/${i}`} style={{color: 'rgba(0, 0, 0, .5)', textDecoration: 'none'}}>
                        <IconButton color="inherit" aria-label="Edit Module">
                          <EditIcon />
                        </IconButton>
                      </Link>}

                      <IconButton color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Module?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this module?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="primary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditFreeCourse;
