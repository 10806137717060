import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './redirectManager.scss';

function RedirectManager(props) {
  const [loading, setLoading] = useState(true);
  const [redirects, setRedirects] = useState([]);
  const [noticeTitle, setNoticeTitle] = useState('');
  const [notice, setNotice] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editRedirect, setEditRedirect] = useState({
    destination: '',
    notes: '',
    source: '',
    statusCode: 301,
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchRedirects();
  }, []);

  const fetchRedirects = async () => {
    try {
      const redirectsSnapshot = await firebase.firestore().collection('website-redirects').get();
      const redirectData = redirectsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setRedirects(redirectData);
    } catch (e) {
      console.log('Error: ', e);
      window.alert('There was an error fetching redirect data. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const handleChange = (e, type) => {
    setEditRedirect({
      ...editRedirect,
      [type]: e.target.value,
    });
  };

  const cancelEdit = () => {
    setEditIndex(-1);
    setEditRedirect({
      destination: '',
      notes: '',
      source: '',
      statusCode: 301,
    });
  };

  const saveRedirect = async () => {
    setLoading(true);
    const db = firebase.firestore();

    const editRedirectCopy = { ...editRedirect };

    try {
      if (!editRedirect.id) {
        const createdRedirect = await db.collection('website-redirects').add(editRedirect);
        editRedirectCopy.id = createdRedirect.id;
      } else {
        await db.collection('website-redirects').doc(editRedirect.id).update({
          destination: editRedirect.destination,
          notes: editRedirect.notes,
          source: editRedirect.source,
          statusCode: editRedirect.statusCode,
        });
      }

      const redirectsCopy = [ ...redirects ];

      redirectsCopy[editIndex] = editRedirectCopy;

      setRedirects(redirectsCopy);
      cancelEdit();
      setLoading(false);
      setNoticeTitle('Success!');
      setNotice('Redirects were successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      cancelEdit();
      setNoticeTitle('Error:');
      setNotice('There was and error updating redirects. Please try again.');
    }
  };

  const deleteRedirect = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('website-redirects').doc(redirects[deleteIndex].id).delete();

      const redirectsCopy = [ ...redirects ];

      redirectsCopy.splice(deleteIndex, 1);

      setRedirects(redirectsCopy);
      setLoading(false);
      setDeleteIndex(-1);
      setNoticeTitle('Success!');
      setNotice('The redirect has been deleted.');
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      setNoticeTitle('Error:');
      setNotice('There was and error deleting the redirect. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="RedirectManager">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
          Redirect Manager
          </Typography>
          <IconButton edge="start" color="inherit" onClick={(event) => {
            setEditRedirect({
              destination: '',
              notes: '',
              source: '',
              statusCode: 301,
            });
            setEditIndex(redirects.length);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {redirects.map((r, i) => {
            return (
              <div key={`redirect-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={<span>
                      <strong>From: </strong>
                      <span style={{marginRight: 10}}>{r.source}</span>
                      <strong>To: </strong>
                      <span style={{marginRight: 10}}>{r.destination}</span>
                      <strong>Code: </strong>
                      <span>{r.statusCode}</span>
                    </span>}
                    secondary={`Notes: ${r.notes}`}
                  />

                  <IconButton edge="start" style={{marginRight: 10}} color="inherit" onClick={(event) => {
                    setEditRedirect(r);
                    setEditIndex(i);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>

                  <IconButton edge="start" color="inherit" onClick={(event) => {
                    setDeleteIndex(i);
                  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog
        open={!!(editIndex !== -1)}
        onClose={cancelEdit}
      >
        <DialogTitle>Manage Redirect</DialogTitle>
        <DialogContent>
          <TextField
            style={{width: '100%'}}
            label="From"
            value={editRedirect.source}
            onChange={(e) => { handleChange(e, 'source') }}
            variant="outlined"
            margin="dense"
            type="text"
          />

          <TextField
            style={{width: '100%', marginTop: 20}}
            label="To"
            value={editRedirect.destination}
            onChange={(e) => { handleChange(e, 'destination') }}
            variant="outlined"
            margin="dense"
            type="text"
          />

          <FormControl variant="outlined" margin="dense" style={{width: '100%', marginTop: 20}}>
            <InputLabel id="demo-simple-select-outlined-label">Status Code:</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={editRedirect.statusCode}
              onChange={handleChange}
              label="Status Code:"
            >
              <MenuItem value={301}>301 Moved Permanently</MenuItem>
              <MenuItem value={302}>302 Found</MenuItem>
              <MenuItem value={303}>303 See Other</MenuItem>
              <MenuItem value={307}>307 Temporary Redirect</MenuItem>
              <MenuItem value={403}>403 Forbidden</MenuItem>
              <MenuItem value={404}>404 Not Found</MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={{width: '100%', marginTop: 20}}
            label="Notes (optional)"
            value={editRedirect.notes}
            onChange={(e) => { handleChange(e, 'notes') }}
            variant="outlined"
            margin="dense"
            type="text"
            multiline
            rows="4"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveRedirect} color="primary" disabled={!editRedirect.source || !editRedirect.destination}>
            Submit
          </Button>
          <Button variant="contained" onClick={cancelEdit} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteIndex !== -1}
        onClose={() => { setDeleteIndex(-1) }}
      >
        <DialogTitle>Delete Redirect?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this redirect?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteRedirect} color="secondary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!notice}
        onClose={() => {
          setNotice('');
          setNoticeTitle('');
        }}
      >
        <DialogTitle>{noticeTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {notice}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            setNotice('');
            setNoticeTitle('');
          }} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RedirectManager;
