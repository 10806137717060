import { hex2rgb, hsv2rgb, rgb2hex, rgb2hsv, toHex } from "./convert.util";

export function toColor(model, color) {
  let hex = toHex("#121212");
  let rgb = hex2rgb(hex);
  let hsv = rgb2hsv(rgb);

  if (model === "hex") {
    const value = color;

    hex = toHex(value);
    rgb = hex2rgb(hex);
    hsv = rgb2hsv(rgb);
  } else if (model === "rgb") {
    const value = color;

    rgb = value;
    hex = rgb2hex(rgb);
    hsv = rgb2hsv(rgb);
  } else if (model === "hsv") {
    const value = color;

    hsv = value;
    rgb = hsv2rgb(hsv);
    hex = rgb2hex(rgb);
  }

  return { hex, rgb, hsv };
}
