import { useFieldArray } from 'react-hook-form';

export const useAcademyItemsFields = (control) => {
  const {
    append,
    remove,
    fields: academyItems,
    move: moveAcademyItem,
  } = useFieldArray({
    control: control,
    name: 'academySection.courses',
  });

  const appendAcademyItem = () => {
    append({
      name: '',
      url: '',
    });
  };

  const removeAcademyItem = (index) => {
    remove(index);
  };

  return {
    academyItems,
    appendAcademyItem,
    removeAcademyItem,
    moveAcademyItem,
  };
};
