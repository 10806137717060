import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import Users from './users/users';
import User from './users/user/user';
import RolesAndPermissions from './rolesAndPermissions/rolesAndPermissions';
import ActionVerification from './actionVerification/actionVerification';

function UserManager(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/manage-users/dashboard" component={Dashboard} />
        <Route path="/manage-users/users/:id" component={User} />
        <Route path="/manage-users/users" component={Users} />
        <Route path="/manage-users/roles-and-permissions" component={RolesAndPermissions} />
        <Route path="/manage-users/action-verification" component={ActionVerification} />
        <Route component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default UserManager;
