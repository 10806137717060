import React, { useRef } from "react";
import { clamp } from "../utils/clamp.util";

export const Interactive = ({ className, style, onChange, children }) => {
  const divRef = useRef(null);

  const move = (e, complete) => {
    if (divRef.current) {
      const { current: div } = divRef;
      const { width, height, left, top } = div.getBoundingClientRect();

      const x = clamp(e.clientX - left, width, 0);
      const y = clamp(e.clientY - top, height, 0);

      onChange({ x, y });

      if (complete) onChange({ x, y, complete: true });
    }
  };

  const onMouseDown = (e) => {
    if (e.button !== 0) return;

    move(e);

    const onMouseMove = (e) => {
      move(e);
    };

    const onMouseUp = (e) => {
      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);

      move(e, true);
    };

    document.addEventListener("mousemove", onMouseMove, false);
    document.addEventListener("mouseup", onMouseUp, false);
  };

  return (
    <div ref={divRef} className={className} style={style} onMouseDown={onMouseDown}>
      {children}
    </div>
  );
};
