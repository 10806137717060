import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Divider,
  InputAdornment,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';

import DeletableChip from '../../../../shared/deletableChip';
import './editShippingZone.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const cloneDeep = require('lodash.clonedeep');

function EditShippingZone(props) {
  const [zone, setZone] = useState(cloneDeep(props.zone));
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [discountDeleteIndex, setDiscountDeleteIndex] = useState(-1);

  useEffect(() => {
    const regionsResult = [];
    const selectedRegionsResult = [];

    for (let key in props.regions) {
      if (props.regions[key].states) {
        for (let state in props.regions[key].states) {
          regionsResult.push(`${props.regions[key].states[state]} (${state}), ${props.regions[key].name} (${key})`);
        }
      }
    }

    for (let key in props.zone.regions) {
      if (props.zone.regions[key].states) {
        for (let state in props.zone.regions[key].states) {
          selectedRegionsResult.push(`${props.zone.regions[key].states[state]} (${state}), ${props.zone.regions[key].name} (${key})`);
        }
      }
    }

    setRegions(regionsResult);
    setSelectedRegions(selectedRegionsResult);
  }, [props.regions, props.zone.regions]);

  const saveZone = () => {
    const regionsMap = {};

    selectedRegions.forEach(region => {
      const r = {};

      const regionArr = region.split(', ');
      const stateArr = regionArr[0].split(' (');
      const countryArr = regionArr[1].split(' (');

      r.state = stateArr[0];
      r.stateCode = stateArr[1].replace(')', '');
      r.country = countryArr[0];
      r.countryCode = countryArr[1].replace(')', '');

      if (!regionsMap[r.countryCode]) {
        regionsMap[r.countryCode] = {
          name: r.country,
          states: {},
        }
      }

      if (!regionsMap[r.countryCode].states[r.stateCode]) {
        regionsMap[r.countryCode].states[r.stateCode] = r.state;
      }
    });

    props.saveZone({
      ...zone,
      regions: regionsMap,
    });
  };

  const addShippingClass = () => {
    const classes = [ ...zone.classes ];

    classes.push({
      selectedClass: (props.classes[0] && props.classes[0].id) || '',
      min: 1,
      max: 999,
      break: false,
      abort: false,
      rowCost: 0,
      itemCost: 0,
    });

    setZone({
      ...zone,
      classes,
    });
  };

  const handleClassValueChange = (e, type, i, isCheckbox) => {
    const classes = [ ...zone.classes ];

    const value = isCheckbox ? e.target.checked : e.target.value;

    const classCopy = {
      ...classes[i],
      [type]: value,
    };

    classes[i] = classCopy;

    setZone({
      ...zone,
      classes,
    });
  };

  const addDiscountTier = () => {
    const shippingDiscountTiers = [ ...zone.shippingDiscountTiers ];

    shippingDiscountTiers.push({
      minimumSubtotalAmount: 50,
      discountPercentage: 100,
      active: true,
    });

    setZone({
      ...zone,
      shippingDiscountTiers,
    });
  };

  const handleDiscountValueChange = (e, type, i, isCheckbox) => {
    const shippingDiscountTiers = [ ...zone.shippingDiscountTiers ];

    const value = isCheckbox ? e.target.checked : e.target.value;

    const tierCopy = {
      ...shippingDiscountTiers[i],
      [type]: value,
    };

    shippingDiscountTiers[i] = tierCopy;

    setZone({
      ...zone,
      shippingDiscountTiers,
    });
  };

  return (
    <div className="EditShippingZone">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.close() }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {props.zone.name}
          </Typography>
          <Button color="inherit" onClick={saveZone}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <div className="edit-content-container">
        <TextField
          label="Name"
          value={zone.name}
          onChange={(e) => {
            setZone({
              ...zone,
              name: e.target.value,
            });
          }}
          margin="normal"
          variant="outlined"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Method (example Standard(2-4 Days))"
          value={zone.methodTitle}
          onChange={(e) => {
            setZone({
              ...zone,
              methodTitle: e.target.value,
            });
          }}
          margin="normal"
          variant="outlined"
          type="text"
          className="day-text-field"
        />

        <FormControl variant="outlined" className="day-text-field" style={{marginTop: 16, marginBottom: 8}}>
          <InputLabel htmlFor="select-multiple-chip">Priority (If address matches more than one shipping zone)</InputLabel>
          <Select
            value={zone.priority || 10}
            onChange={(e) => {
              setZone({
                ...zone,
                priority: e.target.value,
              });
            }}
            margin="normal"
            className="day-text-field"
            label="Priority (If address matches more than one shipping zone)"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => {
              return <MenuItem key={`shipping-priority-${value}`} value={value}>{value}</MenuItem>
            })}
          </Select>
        </FormControl>

        <FormControl style={{ margin: 5, width: '99%'}}>
          <InputLabel htmlFor="select-multiple-chip">Shipping Regions</InputLabel>
          <Select
            multiple
            value={selectedRegions}
            onChange={(e) => {
              setSelectedRegions(e.target.value);
            }}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {selected.map(value => (
                  <DeletableChip
                    key={value}
                    label={value}
                    style={{margin: 2}}
                    color="primary"
                    onDelete={() => {
                      const index = selectedRegions.indexOf(value);

                      if (index === -1) {
                        return;
                      }

                      const regionsCopy = selectedRegions.slice();
                      regionsCopy.splice(index, 1);
                      setSelectedRegions(regionsCopy);
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {regions.filter(region => {
              return !selectedRegions.includes(region);
            }).map(region => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="table-container">
          <TableContainer component={Paper}>
            <Toolbar>
              <Typography variant="h6" style={{
                flex: 1,
              }}>
                Discount Tiers
              </Typography>
              <Button variant="contained" color="primary" onClick={addDiscountTier}>
                Add Discount Tier
              </Button>
            </Toolbar>
            <Divider />
            <Table aria-label="discount tiers table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Active</strong></TableCell>
                  <TableCell><strong>Minimum Order Subtotal</strong></TableCell>
                  <TableCell><strong>Shipping Discount Percentage</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zone.shippingDiscountTiers.map((t, i) => (
                  <TableRow key={`shipping-tier-${i}`}>
                    <TableCell>
                      <Checkbox
                        checked={t.active}
                        onChange={(e) => { handleDiscountValueChange(e, 'active', i, true) }}
                        inputProps={{ 'aria-label': 'tier active' }}
                      />
                    </TableCell>
                    <TableCell>
                      <OutlinedInput
                        value={t.minimumSubtotalAmount}
                        onChange={(e) => { handleDiscountValueChange(e, 'minimumSubtotalAmount', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      />
                    </TableCell>
                    <TableCell>
                      <OutlinedInput
                        value={t.discountPercentage}
                        onChange={(e) => { handleDiscountValueChange(e, 'discountPercentage', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        endAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton color="inherit" onClick={() => { setDiscountDeleteIndex(i) }} aria-label="Delete Discount Tier">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="table-container last">
          <TableContainer component={Paper}>
            <Toolbar>
              <Typography variant="h6" style={{
                flex: 1,
              }}>
                Shipping Classes
              </Typography>
              <Button variant="contained" color="primary" onClick={addShippingClass}>
                Add Shipping Class
              </Button>
            </Toolbar>
            <Divider />
            <Table aria-label="classes table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell><strong>Shipping Class</strong></TableCell>
                  <TableCell><strong>Min</strong></TableCell>
                  <TableCell><strong>Max</strong></TableCell>
                  <TableCell><strong>Break</strong></TableCell>
                  <TableCell><strong>Abort</strong></TableCell>
                  <TableCell><strong>Row Cost</strong></TableCell>
                  <TableCell><strong>Item Cost</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zone.classes.map((c, i) => (
                  <TableRow key={`${c.selectedClass}-${i}`}>
                    <TableCell padding="checkbox">
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined">
                        <Select
                          value={c.selectedClass}
                          onChange={(e) => { handleClassValueChange(e, 'selectedClass', i) }}
                          margin="dense"
                        >
                          {props.classes.map((c, i) => {
                            return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={c.min}
                        onChange={(e) => { handleClassValueChange(e, 'min', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        style={{maxWidth: 80}}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={c.max}
                        onChange={(e) => { handleClassValueChange(e, 'max', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        style={{maxWidth: 80}}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={c.break}
                        onChange={(e) => { handleClassValueChange(e, 'break', i, true) }}
                        inputProps={{ 'aria-label': 'class break' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={c.abort}
                        onChange={(e) => { handleClassValueChange(e, 'abort', i, true) }}
                        inputProps={{ 'aria-label': 'class abort' }}
                      />
                    </TableCell>
                    <TableCell>
                      <OutlinedInput
                        value={c.rowCost}
                        onChange={(e) => { handleClassValueChange(e, 'rowCost', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        style={{maxWidth: 115}}
                      />
                    </TableCell>
                    <TableCell>
                      <OutlinedInput
                        value={c.itemCost}
                        onChange={(e) => { handleClassValueChange(e, 'itemCost', i) }}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        style={{maxWidth: 115}}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null : <IconButton color="inherit" onClick={() => {
                        const classes = [ ...zone.classes ];

                        const itemToMoveUp = c;
                        const itemToMoveDown = zone.classes[i - 1];

                        classes[i] = itemToMoveDown;
                        classes[i - 1] = itemToMoveUp;

                        setZone({
                          ...zone,
                          classes,
                        });
                      }} aria-label="Move Up">
                        <ArrowUpIcon />
                      </IconButton>}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {(i === zone.classes.length - 1) ? null : <IconButton color="inherit" onClick={() => {
                        const classes = [ ...zone.classes ];

                        const itemToMoveDown = c;
                        const itemToMoveUp = zone.classes[i + 1];

                        classes[i] = itemToMoveUp;
                        classes[i + 1] = itemToMoveDown;

                        setZone({
                          ...zone,
                          classes,
                        });
                      }} aria-label="Move Up">
                        <ArrowDownIcon />
                      </IconButton>}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete Shipping Class">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Dialog
        open={deleteIndex !== -1}
        onClose={() => { setDeleteIndex(-1) }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this shipping class?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const classes = [ ...zone.classes ];
            
            classes.splice(deleteIndex, 1);

            setZone({
              ...zone,
              classes,
            });
            setDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={discountDeleteIndex !== -1}
        onClose={() => { setDiscountDeleteIndex(-1) }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this shipping discount tier?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const shippingDiscountTiers = [ ...zone.shippingDiscountTiers ];

            shippingDiscountTiers.splice(discountDeleteIndex, 1);

            setZone({
              ...zone,
              shippingDiscountTiers,
            });

            setDiscountDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDiscountDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditShippingZone;
