import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core';

import StyledDropzone from '../../../fileManager/styledDropzone';
import './shippingRegions.scss';

import cloneDeep from 'lodash.clonedeep';

function ShippingRegions(props) {
  const [showShippingRegion, setShowShippingRegion] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [regions, setRegions] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    const regionsResult = [];

    for (let key in props.regions) {
      if (props.regions[key].states) {
        for (let state in props.regions[key].states) {
          regionsResult.push({
            country: props.regions[key].name,
            countryCode: key,
            state: props.regions[key].states[state],
            stateCode: state,
            selected: false,
          });
        }
      }
    }

    setRegions(regionsResult);
  }, [props.regions]);

  const importRegions = () => {
    if (!csvFile.type.includes('csv')) {
      setShowShippingRegion(false);
      setCSVFile(null);
      window.alert('Shipping regions can only be imported from a CSV file.');
      return;
    }

    props.setLoading(true);

    Papa.parse(csvFile, {
      complete: function(results) {
        if (!results.data) {
          setShowShippingRegion(false);
          setCSVFile(null);
          props.setLoading(false);
          window.alert('There was an error importing regions. Please try again');
          return;
        }

        const regionsMap = cloneDeep(props.regions);

        results.data.forEach(r => {
          if (r.length !== 4) {
            return;
          }

          if (!regionsMap[r[1]]) {
            regionsMap[r[1]] = {
              name: r[0],
              states: {},
            }
          }

          if (!regionsMap[r[1]].states[r[3]]) {
            regionsMap[r[1]].states[r[3]] = r[2];
          }
        });

        setShowShippingRegion(false);
        setCSVFile(null);
        props.save(regionsMap);
      },
    });
  };

  const deleteRegions = () => {
    props.setLoading(true);
    const regionsCopy = regions.filter(r => !r.selected);

    const regionsMap = {};

    regionsCopy.forEach(r => {
      if (!regionsMap[r.countryCode]) {
        regionsMap[r.countryCode] = {
          name: r.country,
          states: {},
        }
      }

      if (!regionsMap[r.countryCode].states[r.stateCode]) {
        regionsMap[r.countryCode].states[r.stateCode] = r.state;
      }
    });

    props.save(regionsMap);
  };

  return (
    <div className="ShippingRegions">
      <div className="add-container">
        <Button 
          variant="contained"
          color="primary"
          style={{marginRight: '10px', marginBottom: '5px'}}
          onClick={() => {
            setShowShippingRegion(true);
          }}
        >
          Import From CSV
        </Button>

        <Button 
          variant="contained"
          color="secondary"
          style={{marginRight: '10px', marginBottom: '5px'}}
          onClick={deleteRegions}
          disabled={!regions.find((r) => r.selected)}
        >
          Delete
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="regions table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={allChecked}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    setAllChecked(checked);

                    const regionsCopy = regions.map(r => {
                      return {
                        ...r,
                        selected: checked,
                      };
                    });

                    setRegions(regionsCopy);
                  }}
                  inputProps={{ 'aria-label': 'select all regions' }}
                />
              </TableCell>
              <TableCell><strong>Country</strong></TableCell>
              <TableCell><strong>Country Code</strong></TableCell>
              <TableCell><strong>State</strong></TableCell>
              <TableCell><strong>State Code</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((region, i) => (
              <TableRow key={region.state}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={region.selected}
                    onChange={(e) => {
                      const regionsCopy = [ ...regions ];
                      const targetRegion = { ...region };
                      
                      targetRegion.selected = e.target.checked;
                      regionsCopy[i] = targetRegion;

                      if (!e.target.checked) {
                        setAllChecked(false);
                      }

                      setRegions(regionsCopy);
                    }}
                    inputProps={{ 'aria-label': 'select region' }}
                  />
                </TableCell>
                <TableCell>{region.country}</TableCell>
                <TableCell>{region.countryCode}</TableCell>
                <TableCell>{region.state}</TableCell>
                <TableCell>{region.stateCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showShippingRegion}
        onClose={() => {
          setShowShippingRegion(false);
          setCSVFile(null);
        }}
      >
        <DialogTitle>Import Shipping Regions</DialogTitle>
        <DialogContent>
          {!csvFile ? <StyledDropzone singleFile onDrop={(files) => { setCSVFile(files[0]) }} /> : csvFile.name}
        </DialogContent>
        <DialogActions>
          <Button disabled={!csvFile} variant="contained" onClick={importRegions} color="primary">
            Import
          </Button>
          <Button variant="contained" onClick={() => {
            setShowShippingRegion(false);
            setCSVFile(null);
          }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShippingRegions;
