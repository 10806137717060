import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';

import './formActionEditor.scss';
import {
  formInputTypes,
} from '../../formTypesDataMap';

function FormActionEditor({ action, updateAction, actionTypes, pages }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (action && action.type) {
      const foundActionType = actionTypes.find(c => c.type === action.type);

      if (foundActionType) {
        setTitle(foundActionType.name);
        setDescription(foundActionType.description);
      } else {
        setTitle('');
        setDescription('');
      }
    }
  }, [action]);

  useEffect(() => {
    const availableQuestions = [];
    const availableQuestionIds = [];
    const availablePageIds = [];

    pages.forEach((p, i) => {
      availablePageIds.push(p.id);

      p.questions.forEach((q, j) => {
        if (formInputTypes.includes(q.type)) {
          availableQuestions.push({
            ...q,
            page: i,
            index: j,
          });
          availableQuestionIds.push(q.internalId);
        }
      });
    });

    setQuestions(availableQuestions);

    if (action.type === 'add-to-klaviyo' || action.type === 'add-to-sendlane') {
      let shouldUpdateAction = false;
      const updatedAction = { ...action };
      const hasSelectedEmailField = availableQuestionIds.includes(action.emailField);

      if (action.emailField && !hasSelectedEmailField) {
        shouldUpdateAction = true;
        updatedAction.emailField = '';
      }

      updatedAction.additionalFields = action.additionalFields.map(f => {
        const hasField = availableQuestionIds.includes(f.formField);

        if (!hasField) {
          shouldUpdateAction = true;
        }

        return {
          ...f,
          formField: hasField ? f.formField : '',
        };
      });

      if (shouldUpdateAction) {
        updateAction(updatedAction);
      }
    }
  }, [pages]);

  return (
    <div className="FormActionEditor">
      <p className="form-action-title">{title}</p>
      <p className="form-action-description">{description}</p>

      {action.type === 'redirect-to-page' ?
        <>
          <TextField
            label="Redirect Page URL"
            value={action.pageURL}
            onChange={(e) => {
              updateAction({
                ...action,
                pageURL: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </> :
        <>
          {action.type === 'add-to-sendlane' ?
            <>
              <TextField
                label="List ID"
                value={action.listId}
                onChange={(e) => {
                  updateAction({
                    ...action,
                    listId: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <FormControl
                variant="outlined"
                margin="dense"
                className="day-text-field"
              >
                <InputLabel>
                  Email Field
                </InputLabel>
                <Select
                  value={action.emailField}
                  onChange={(e) => {
                    updateAction({
                      ...action,
                      emailField: e.target.value,
                    });
                  }}
                  label="Email Field"
                >
                  {questions.map((q, i) => {
                    return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <Toolbar className="form-items-heading">
                <Typography variant="subtitle1">
                  Custom Fields
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const additionalFields = [ ...action.additionalFields ];

                    additionalFields.push({
                      customSendlaneFieldId: '',
                      formField: '',
                    });

                    updateAction({
                      ...action,
                      additionalFields,
                    });
                  }}
                >
                  Add Custom Field
                </Button>
              </Toolbar>

              {action.additionalFields.map((field, i) => {
                return (
                  <div className="additional-field-row">
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      className="day-text-field"
                    >
                      <InputLabel>
                        Form Field
                      </InputLabel>
                      <Select
                        value={field.formField}
                        onChange={(e) => {
                          const updatedAdditionalFields = [ ...action.additionalFields ];

                          updatedAdditionalFields[i] = {
                            ...updatedAdditionalFields[i],
                            formField: e.target.value,
                          };

                          updateAction({
                            ...action,
                            additionalFields: updatedAdditionalFields,
                          });
                        }}
                        label="Form Field"
                      >
                        {questions.map((q, i) => {
                          return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>

                    <TextField
                      label="Sendlane Field ID"
                      value={field.customSendlaneFieldId}
                      onChange={(e) => {
                        const updatedAdditionalFields = [ ...action.additionalFields ];

                        updatedAdditionalFields[i] = {
                          ...updatedAdditionalFields[i],
                          customSendlaneFieldId: e.target.value,
                        };

                        updateAction({
                          ...action,
                          additionalFields: updatedAdditionalFields,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                    />

                    <div>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          const updatedAdditionalFields = [ ...action.additionalFields ];

                          updatedAdditionalFields.splice(i, 1);

                          updateAction({
                            ...action,
                            additionalFields: updatedAdditionalFields,
                          });
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </> :
            <>
              <TextField
                label="List ID"
                value={action.listId}
                onChange={(e) => {
                  updateAction({
                    ...action,
                    listId: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />
    
              <FormControl
                variant="outlined"
                margin="dense"
                className="day-text-field"
              >
                <InputLabel>
                  Email Field
                </InputLabel>
                <Select
                  value={action.emailField}
                  onChange={(e) => {
                    updateAction({
                      ...action,
                      emailField: e.target.value,
                    });
                  }}
                  label="Email Field"
                >
                  {questions.map((q, i) => {
                    return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                  })}
                </Select>
              </FormControl>
    
              <Toolbar className="form-items-heading">
                <Typography variant="subtitle1">
                  Custom Fields
                </Typography>
    
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const additionalFields = [ ...action.additionalFields ];
    
                    additionalFields.push({
                      customKlaviyoField: '',
                      formField: '',
                    });
    
                    updateAction({
                      ...action,
                      additionalFields,
                    });
                  }}
                >
                  Add Custom Field
                </Button>
              </Toolbar>
    
              {action.additionalFields.map((field, i) => {
                return (
                  <div className="additional-field-row">
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      className="day-text-field"
                    >
                      <InputLabel>
                        Form Field
                      </InputLabel>
                      <Select
                        value={field.formField}
                        onChange={(e) => {
                          const updatedAdditionalFields = [ ...action.additionalFields ];
    
                          updatedAdditionalFields[i] = {
                            ...updatedAdditionalFields[i],
                            formField: e.target.value,
                          };
    
                          updateAction({
                            ...action,
                            additionalFields: updatedAdditionalFields,
                          });
                        }}
                        label="Form Field"
                      >
                        {questions.map((q, i) => {
                          return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
    
                    <TextField
                      label="Klaviyo Field Name"
                      value={field.customKlaviyoField}
                      onChange={(e) => {
                        const updatedAdditionalFields = [ ...action.additionalFields ];
    
                        updatedAdditionalFields[i] = {
                          ...updatedAdditionalFields[i],
                          customKlaviyoField: e.target.value,
                        };
    
                        updateAction({
                          ...action,
                          additionalFields: updatedAdditionalFields,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                    />
    
                    <div>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          const updatedAdditionalFields = [ ...action.additionalFields ];
    
                          updatedAdditionalFields.splice(i, 1);
    
                          updateAction({
                            ...action,
                            additionalFields: updatedAdditionalFields,
                          });
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </>
          }
        </>
      }
    </div>
  );
}

export default FormActionEditor;
