import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Toolbar,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './managePartner.scss';
import { config } from '../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ManagePartner(props) {
  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [updatedPartnerName, setUpdatedPartnerName] = useState('');
  const [editFreeTrainingUrlOpen, setEditFreeTrainingUrlOpen] = useState(false);
  const [updatedFreeTrainingUrl, setUpdatedFreeTrainingUrl] = useState('');
  const [editLogoOpen, setEditLogoOpen] = useState(false);
  const [updatedLogoURL, setUpdatedLogoURL] = useState('');
  const [partnerUsers, setPartnerUsers] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [userEmailToAdd, setUserEmailToAdd] = useState('');
  const [addAsAdmin, setAddAsAdmin] = useState(true);
  const [removeAccessIndex, setRemoveAccessIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [editUser, setEditUser] = useState({
    userName: '',
    roles: [],
  });
  const [trainingCourses, setTrainingCourses] = useState([]);

  useEffect(() => {
    fetchPartner();
  }, []);

  const fetchPartner = async () => {
    const id = props.match.params.id;

    if (id === undefined) {
      props.history.replace('/partners/manage-partners');
      return;
    }

    try {
      const db = firebase.firestore();
      const partnerSnapshot = await db.collection('partners').doc(id).get();
      const settingsSnapshot = await db.collection('config').doc('retailer-microsite').get();
      const partnerUsersSnapshot = await db.collection('users').where('partner', '==', id).get();
      const partnerData = partnerSnapshot.data();
      const partnerUsersData = partnerUsersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const includedCourses = settingsSnapshot.data().includedCourses;
      const courses = [];
      
      for (let i = 0; i < includedCourses.length; i++) {
        const courseId = includedCourses[i].id;
        const freeCourseSnapshot = await db.collection('free-courses').doc(courseId).get();
        const freeCourse = freeCourseSnapshot.data();

        if (freeCourse) {
          courses.push({
            id: freeCourse.id,
            name: freeCourse.name,
            modules: freeCourse.modules.filter(m => {
              return typeof m !== 'string';
            }).map(m => {
              return {
                id: m.id,
                title: m.title,
              };
            }),
          });
        } else {
          const paidCourseSnapshot = await db.collection('membership-content').doc(courseId).get();
          const paidCourse = paidCourseSnapshot.data();

          if (paidCourse) {
            courses.push({
              id: paidCourse.id,
              name: paidCourse.name,
              modules: paidCourse.modules.filter(m => {
                return typeof m !== 'string';
              }).map(m => {
                return {
                  id: m.id,
                  title: m.title,
                };
              }),
            });
          }
        }
      }

      setPartner({
        ...partnerData,
        id: partnerSnapshot.id,
      });
      setPartnerUsers(partnerUsersData);
      setTrainingCourses(courses);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching the partner. Refresh and try again.');
    }
  };

  const submitUpdatedPartnerName = async () => {
    setLoading(true);

    try {
      const fieldsToUpdate = {
        name: updatedPartnerName,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('partners').doc(partner.id).update(fieldsToUpdate);

      setPartner({
        ...partner,
        ...fieldsToUpdate,
      });
      setLoading(false);
      setEditNameOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the partner. Please try again.');
    }
  };

  const submitUpdatedFreeTrainingUrl = async () => {
    setLoading(true);

    try {
      const partnersSnapshot = await firebase.firestore().collection('partners').where('freeTrainingUrl', '==', updatedFreeTrainingUrl).get();

      if (partnersSnapshot.docs.length && partnersSnapshot.docs[0].id !== partner.id) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is another partner with this free training URL. Please select a different URL.');
        return;
      }

      const fieldsToUpdate = {
        freeTrainingUrl: updatedFreeTrainingUrl,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('partners').doc(partner.id).update(fieldsToUpdate);

      setPartner({
        ...partner,
        ...fieldsToUpdate,
      });
      setLoading(false);
      setEditFreeTrainingUrlOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the free training URL. Please try again.');
    }
  };

  const submitUpdatedPartnerLogo = async () => {
    setLoading(true);

    try {
      const fieldsToUpdate = {
        logoUrl: updatedLogoURL,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('partners').doc(partner.id).update(fieldsToUpdate);

      setPartner({
        ...partner,
        ...fieldsToUpdate,
      });
      setLoading(false);
      setEditLogoOpen(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the partner Logo. Please try again.');
    }
  };

  const addPartnerUser = async () => {
    setLoading(true);

    const newUserEmailToAdd = userEmailToAdd;
  
    try {
      const db = firebase.firestore();
      const snapshot = await db.collection('users').where('email', '==', newUserEmailToAdd).get();

      if (!snapshot.docs.length) {
        setLoading(false);
        setAddUserOpen(false);
        setModalTitle('Error:');
        setModalText('There specified user was not found.');
        return;
      }

      const userData = {
        ...snapshot.docs[0].data(),
        id: snapshot.docs[0].id,
      };

      if (userData.roles && (userData.roles.includes('partner-admin') || userData.roles.includes('partner-associate'))) {
        setLoading(false);
        setAddUserOpen(false);
        setModalTitle('Error:');
        setModalText('There specified user already has partner access.');
        return;
      }

      const role = addAsAdmin ? 'partner-admin' : 'partner-associate';
      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/partner/${partner.id}/user/${userData.id}/add-access`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: [ role ],
      });

      const userRef = db.collection('users').doc(userData.id);
      const partnerRef = db.collection('partners').doc(partner.id);
      let updatedPartnerCount = 0;
      await db.runTransaction(async (t) => {
        const partnerSnapshot = await t.get(partnerRef);

        const partnerCount = (partnerSnapshot.data().partnerCount || 0) + 1;
        updatedPartnerCount = partnerCount;

        t.update(userRef, {
          roles: [ role ],
          partner: partner.id,
        });

        t.update(partnerRef, {
          partnerCount,
        });
      });

      setPartner({ ...partner, partnerCount: updatedPartnerCount });
      setLoading(false);
      setUserEmailToAdd('');
      setPartnerUsers([
        ...partnerUsers,
        {
          ...userData,
          roles: [ role ],
          partner: partner.id,
        },
      ]);
      setAddUserOpen(false);
      setModalTitle('Success!');
      setModalText(`${newUserEmailToAdd} has been given ${addAsAdmin ? 'admin' : 'associate'} access to ${partner.name}.`);
    } catch (e) {
      setLoading(false);
      setAddUserOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error adding access to the selected user. Please try again.');
    }
  };

  const removePartnerUser = async () => {
    setLoading(true);

    const userToRemoveId = partnerUsers[removeAccessIndex].id;

    try {
      const db = firebase.firestore();
      const snapshot = await db.collection('users').doc(userToRemoveId).get();
      const userDocData = snapshot.data();

      if (!userDocData) {
        setLoading(false);
        setRemoveAccessIndex(-1);
        setModalTitle('Error:');
        setModalText('There specified user was not found.');
        return;
      }

      const userData = {
        ...userDocData,
        id: snapshot.id,
      };

      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/partner/${partner.id}/user/${userData.id}/remove-access`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {},
      });

      const userRef = db.collection('users').doc(userData.id);
      const partnerRef = db.collection('partners').doc(partner.id);
      let updatedPartnerCount = 0;
      await db.runTransaction(async (t) => {
        const partnerSnapshot = await t.get(partnerRef);

        const partnerCount = (partnerSnapshot.data().partnerCount || 0) - 1;
        updatedPartnerCount = partnerCount;

        t.update(userRef, {
          roles: [],
          partner: null,
        });

        t.update(partnerRef, {
          partnerCount,
        });
      });

      const updatedPartnerUsers = [ ...partnerUsers ];
      updatedPartnerUsers.splice(removeAccessIndex, 1);

      setPartner({ ...partner, partnerCount: updatedPartnerCount });
      setLoading(false);
      setRemoveAccessIndex(-1);
      setPartnerUsers(updatedPartnerUsers);
      setModalTitle('Success!');
      setModalText(`${userData.userName || userData.email || 'Selected user'} has been removed from ${partner.name}.`);
    } catch (e) {
      console.log(e)
      setLoading(false);
      setRemoveAccessIndex(-1);
      setModalTitle('Error:');
      setModalText('There was an error removing access from the selected user. Please try again.');
    }
  };

  const closeEditUser = () => {
    setEditIndex(-1);
    setEditUser({
      userName: '',
      roles: [],
    });
  };

  const updateUser = async () => {
    setLoading(true);

    try {
      if (editUser.roles[0] !== partnerUsers[editIndex].roles[0]) {
        const token = await firebase.auth().currentUser.getIdToken();

        await axios(`${config.gateway}/user-service/api/v1/admin/partner/${partner.id}/user/${editUser.id}/add-access`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: editUser.roles,
        });
      }

      await firebase.firestore().collection('users').doc(editUser.id).update({
        roles: editUser.roles,
        userName: editUser.userName,
      });

      const updatedPartnerUsers = [ ...partnerUsers ];

      updatedPartnerUsers[editIndex] = {
        ...updatedPartnerUsers[editIndex],
        userName: editUser.userName,
        roles: editUser.roles,
      };
      setLoading(false);
      setPartnerUsers(updatedPartnerUsers);
      closeEditUser();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this user. Please try again.');
    }
  };

  const saveCourseModuleProducts = async () => {
    setLoading(true);

    try {
      const fieldsToUpdate = {
        courseProducts: partner.courseProducts,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('partners').doc(partner.id).update(fieldsToUpdate);

      setPartner({
        ...partner,
        ...fieldsToUpdate,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the course module products. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ManagePartner">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/partners/manage-partners">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Partners
          </Button>
        </Link>
      </div>

      {!partner ? null :
        <div>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <div style={{marginRight: 10}}>
              <Typography style={{marginBottom: 10, marginTop: 10}}>
                <strong>Name:</strong> <span>{partner.name || 'Name not set'} </span>
                <EditIcon style={{fontSize: 14, cursor: 'pointer'}} onClick={() => {
                  setEditNameOpen(true);
                  setUpdatedPartnerName(partner.name || '');
                }} />
              </Typography>

              <Typography style={{marginBottom: 10}}>
                <strong>Free Training URL:</strong> <span>{partner.freeTrainingUrl || 'URL not set'} </span>
                <EditIcon style={{fontSize: 14, cursor: 'pointer'}} onClick={() => {
                  setEditFreeTrainingUrlOpen(true);
                  setUpdatedFreeTrainingUrl(partner.freeTrainingUrl || '');
                }} />
              </Typography>

              <Typography>
                <strong>Logo:</strong> <span>{!partner.logoUrl ? 'Logo not set' : ''} </span>
                <EditIcon style={{fontSize: 14, cursor: 'pointer'}} onClick={() => {
                  setEditLogoOpen(true);
                  setUpdatedLogoURL(partner.logoUrl || '');
                }} />
              </Typography>
              <div style={{marginBottom: 10}}>
                {!partner.logoUrl ? null :
                  <img width="100px" src={partner.logoUrl} />
                }
              </div>
            </div>

            {/* <Button 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
            >
              Delete Partner
            </Button> */}
          </Toolbar>

          <div className="content-container">

            <div>
              <div className="users-header-container">
                <Typography variant="subtitle1" style={{marginBottom: 10}}>
                  <strong>Partner Users:</strong>
                </Typography>

                <Button 
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setUserEmailToAdd('');
                    setAddAsAdmin(true);
                    setAddUserOpen(true);
                  }}
                >
                  Add Partner User
                </Button>
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="orders table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>Email</strong></TableCell>
                      <TableCell><strong>Roles</strong></TableCell>
                      <TableCell><strong>ID</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partnerUsers.map((u, i) => (
                      <TableRow key={u.id}>
                        <TableCell>{u.userName || 'None specified'}</TableCell>
                        <TableCell>{u.email || 'None specified'}</TableCell>
                        <TableCell>
                          <span className="capitalize">
                            {((u.roles || []).map(role => role.split('-').join(' ')).join(', '))}
                          </span>
                        </TableCell>
                        <TableCell>{u.id}</TableCell>
                        <TableCell padding="checkbox">
                          <IconButton color="inherit" onClick={() => {
                            setEditUser({ ...u });
                            setEditIndex(i);
                          }} aria-label="Delete">
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <IconButton color="inherit" onClick={() => { setRemoveAccessIndex(i) }} aria-label="Delete">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="course-modules-products">
              <div className="course-modules-products-header">
                <Typography variant="subtitle1" style={{marginBottom: 10}}>
                  <strong>Course Modules Products Display:</strong>
                </Typography>

                <Button 
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => saveCourseModuleProducts()}
                >
                  Save Course Module Products
                </Button>
              </div>

              {trainingCourses.map((c, i) => {
                return (
                  <div
                    key={`training-course-${i}`}
                    className="training-course"
                  >
                    <div className="training-course-header">
                      <p>{c.name}</p>
                    </div>

                    {c.modules.map((m, j) => {
                      const coursesMap = partner.courseProducts || {};
                      const modulesMap = coursesMap[c.id] || {};
                      const products = modulesMap[m.id] || [];

                      return (
                        <div
                          key={`training-course-module-${i}-${j}`}
                          className="training-module"
                        >
                          <div className="training-module-header">
                            <p>{m.title}</p>

                            <Button 
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                const courseProducts = { ...(partner.courseProducts || {}) };
                                const modules = { ...(courseProducts[c.id] || {}) };

                                if (!modules[m.id]) {
                                  modules[m.id] = [];
                                } else {
                                  modules[m.id] = [ ...modules[m.id] ];
                                }

                                modules[m.id].push({
                                  imageUrl: '',
                                  name: '',
                                  enableLink: false,
                                  linkUrl: '',
                                });

                                courseProducts[c.id] = modules;

                                setPartner({
                                  ...partner,
                                  courseProducts,
                                });
                              }}
                            >
                              Add Product
                            </Button>
                          </div>

                          {products.map((p, k) => {
                            return (
                              <div
                                key={`training-course-module-product-${i}-${j}-${k}`}
                                className="training-module-product"
                              >
                                <div className="training-module-product-header">
                                  <p>Product {k + 1}</p>

                                  <IconButton
                                    color="inherit"
                                    onClick={() => {
                                      const courseProducts = { ...(partner.courseProducts || {}) };
                                      const modules = { ...(courseProducts[c.id] || {}) };

                                      modules[m.id] = [ ...modules[m.id] ];

                                      modules[m.id].splice(k, 1);

                                      courseProducts[c.id] = modules;

                                      setPartner({
                                        ...partner,
                                        courseProducts,
                                      });
                                    }}
                                    aria-label="Delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>

                                <div className="training-module-product-input-row">
                                  <TextField
                                    label="Name"
                                    value={p.name}
                                    onChange={(e) => {
                                      const courseProducts = { ...(partner.courseProducts || {}) };
                                      const modules = { ...(courseProducts[c.id] || {}) };

                                      modules[m.id] = [ ...modules[m.id] ];

                                      modules[m.id][k] = {
                                        ...modules[m.id][k],
                                        name: e.target.value,
                                      };

                                      courseProducts[c.id] = modules;

                                      setPartner({
                                        ...partner,
                                        courseProducts,
                                      });
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    type="text"
                                    className="day-text-field"
                                  />

                                  <TextField
                                    label="Image URL"
                                    value={p.imageUrl}
                                    onChange={(e) => {
                                      const courseProducts = { ...(partner.courseProducts || {}) };
                                      const modules = { ...(courseProducts[c.id] || {}) };

                                      modules[m.id] = [ ...modules[m.id] ];

                                      modules[m.id][k] = {
                                        ...modules[m.id][k],
                                        imageUrl: e.target.value,
                                      };

                                      courseProducts[c.id] = modules;

                                      setPartner({
                                        ...partner,
                                        courseProducts,
                                      });
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    type="text"
                                    className="day-text-field"
                                  />
                                </div>

                                <div className="training-module-product-input-row">
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={p.enableLink}
                                        onChange={(e) => {
                                          const courseProducts = { ...(partner.courseProducts || {}) };
                                          const modules = { ...(courseProducts[c.id] || {}) };
    
                                          modules[m.id] = [ ...modules[m.id] ];
    
                                          modules[m.id][k] = {
                                            ...modules[m.id][k],
                                            enableLink: e.target.checked,
                                          };
    
                                          courseProducts[c.id] = modules;
    
                                          setPartner({
                                            ...partner,
                                            courseProducts,
                                          });
                                        }}
                                        name="enableLink"
                                        color="primary"
                                      />
                                    }
                                    label="Enable Product Link"
                                  />

                                  {!p.enableLink ? null :
                                    <TextField
                                      label="Link URL"
                                      value={p.linkUrl}
                                      onChange={(e) => {
                                        const courseProducts = { ...(partner.courseProducts || {}) };
                                        const modules = { ...(courseProducts[c.id] || {}) };

                                        modules[m.id] = [ ...modules[m.id] ];

                                        modules[m.id][k] = {
                                          ...modules[m.id][k],
                                          linkUrl: e.target.value,
                                        };

                                        courseProducts[c.id] = modules;

                                        setPartner({
                                          ...partner,
                                          courseProducts,
                                        });
                                      }}
                                      margin="dense"
                                      variant="outlined"
                                      type="text"
                                      className="link-url-input"
                                    />
                                  }
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editNameOpen}
        onClose={() => { setEditNameOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Update Partner Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={updatedPartnerName}
            onChange={(e) => {
              setUpdatedPartnerName(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitUpdatedPartnerName}
            color="primary"
            disabled={!updatedPartnerName}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => { setEditNameOpen(false) }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editFreeTrainingUrlOpen}
        onClose={() => { setEditFreeTrainingUrlOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Update Free Training URL</DialogTitle>
        <DialogContent>
          <TextField
            label="URL Path"
            value={updatedFreeTrainingUrl}
            helperText="Only letters, numbers and dashes allowed"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setUpdatedFreeTrainingUrl(value.toLowerCase());
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitUpdatedFreeTrainingUrl}
            color="primary"
            disabled={!updatedFreeTrainingUrl}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => { setEditFreeTrainingUrlOpen(false) }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editLogoOpen}
        onClose={() => { setEditLogoOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Update Partner Logo</DialogTitle>
        <DialogContent>
          <TextField
            label="Logo Image URL"
            value={updatedLogoURL}
            onChange={(e) => {
              setUpdatedLogoURL(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitUpdatedPartnerLogo}
            color="primary"
            disabled={!updatedLogoURL}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => { setEditLogoOpen(false) }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={addUserOpen}
        onClose={() => { setAddUserOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Partner User</DialogTitle>
        <DialogContent>
          <Typography>Enter the email address associated with the account you would like to give partner access to. If the user does not already have an account, you can create one for them in the "Users" tab.</Typography>

          <TextField
            label="User Email"
            value={userEmailToAdd}
            onChange={(e) => {
              setUserEmailToAdd(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />

          <FormControlLabel
            control={
              <Switch
                checked={addAsAdmin}
                onChange={(e) => {
                  setAddAsAdmin(e.target.checked);
                }}
                name="addAsAdmin"
                color="primary"
              />
            }
            label="Give Admin Access"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addPartnerUser} disabled={!emailRegex.test(userEmailToAdd)} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddUserOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={removeAccessIndex !== -1}
        onClose={() => { setRemoveAccessIndex(-1) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Remove User Access</DialogTitle>
        <DialogContent>
          {removeAccessIndex === -1 ? null :
            <Typography>Are you sure you want to remove <strong>{partnerUsers[removeAccessIndex].userName || partnerUsers[removeAccessIndex].email}</strong> from <strong>{partner.name}</strong>?</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={removePartnerUser} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setRemoveAccessIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editIndex !== -1}
        onClose={closeEditUser}
        TransitionComponent={Transition}
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            label="User Name"
            value={editUser.userName}
            onChange={(e) => {
              setEditUser({
                ...editUser,
                userName: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />

          <FormControlLabel
            control={
              <Switch
                checked={editUser.roles.includes('partner-admin')}
                onChange={(e) => {
                  const roles = [];

                  if (e.target.checked) {
                    roles.push('partner-admin');
                  } else {
                    roles.push('partner-associate');
                  }

                  setEditUser({
                    ...editUser,
                    roles,
                  });
                }}
                name="adminAccess"
                color="primary"
              />
            }
            label="Admin Access"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={updateUser} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={closeEditUser} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManagePartner;
