import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SubscriptionBoxes({ history }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
  const [newSubscriptionBox, setNewSubscriptionBox] = useState({
    name: '',
    orders: [],
    interval: 1,
    period: 'month',
    hasFixedPrice: true,
    price: 0,
    isOnSale: false,
    salePrice: 0,
    created: Date.now(),
    lastModified: Date.now(),
    lastModifiedBy: firebase.auth().currentUser.email,
  });
  const [showAddSubscriptionBox, setShowAddSubscriptionBox] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchSubscriptionBoxes();
  }, []);

  const fetchSubscriptionBoxes = async () => {
    const db = firebase.firestore();

    try {
      const subscriptionBoxesSnapshot = await db.collection('subscription-boxes').get();

      const subscriptionBoxesData = subscriptionBoxesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setSubscriptionBoxes(subscriptionBoxesData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the subscription boxes, please try again.');
    }
  };

  const saveNewSubscriptionBox = async () => {
    setLoading(true);

    try {
      const addedSubscriptionBox = await firebase.firestore().collection('subscription-boxes').add(newSubscriptionBox);

      setLoading(false);
      history.push(`/shop/subscription-boxes/${addedSubscriptionBox.id}`);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this subscription box, please try again.');
    }
  };

  const deleteSubscriptionBox = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('subscription-boxes').doc(subscriptionBoxes[deleteIndex].id).delete();

      const updatedSubscriptionBoxes = [ ...subscriptionBoxes ];
      updatedSubscriptionBoxes.splice(deleteIndex, 1);

      setSubscriptionBoxes(updatedSubscriptionBoxes);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this subscription box, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SubscriptionBoxes">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Subscription Boxes
        </Typography>
        <IconButton edge="start" color="inherit" onClick={() => {
          setNewSubscriptionBox({
            name: '',
            orders: [],
            interval: 1,
            period: 'month',
            hasFixedPrice: true,
            price: 0,
            isOnSale: false,
            salePrice: 0,
            created: Date.now(),
            lastModified: Date.now(),
            lastModifiedBy: firebase.auth().currentUser.email,
          });
          setShowAddSubscriptionBox(true);
        }} aria-label="Add">
          <AddIcon />
        </IconButton>
      </Toolbar>

      <TableContainer component={Paper}>
        <Table aria-label="regions table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Created</strong></TableCell>
              <TableCell><strong>Last Modified</strong></TableCell>
              <TableCell><strong>Renewal Info</strong></TableCell>
              <TableCell padding="checkbox"></TableCell>
              <TableCell padding="checkbox"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionBoxes.map((subscriptionBox, i) => (
              <TableRow key={subscriptionBox.id}>
                <TableCell>
                  <div>{subscriptionBox.name}</div>
                  <div><small><strong>ID:</strong> {subscriptionBox.id}</small></div>
                </TableCell>
                <TableCell>{moment(subscriptionBox.created).format('lll')}</TableCell>
                <TableCell>
                  <div>{moment(subscriptionBox.lastModified).format('lll')}</div>
                  <div><small><strong>By:</strong> {subscriptionBox.lastModifiedBy}</small></div>
                </TableCell>
                <TableCell>
                  Renews every{+subscriptionBox.interval === 1 ? '' : ` ${subscriptionBox.interval}`} {subscriptionBox.period}{+subscriptionBox.interval === 1 ? '' : 's'} for a total of {subscriptionBox.orders.length} orders
                </TableCell>
                <TableCell padding="checkbox">
                  <Link to={`/shop/subscription-boxes/${subscriptionBox.id}`}>
                    <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Subscription Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete {subscriptionBoxes[deleteIndex] && subscriptionBoxes[deleteIndex].name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteSubscriptionBox} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={showAddSubscriptionBox} onClose={() => { setShowAddSubscriptionBox(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add New Subscription Box</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newSubscriptionBox.name}
            onChange={(e) => {
              setNewSubscriptionBox({
                ...newSubscriptionBox,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewSubscriptionBox} color="secondary" disabled={!newSubscriptionBox.name}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddSubscriptionBox(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionBoxes;
