import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './dashboard/homePage/homePage';
import Footer from './dashboard/footer/footer';
import Header from './dashboard/header/header';
import Dashboard from './dashboard/dashboard';
import Pages from './pages/pages';
import RedirectManager from './redirectManager/redirectManager';
import EditPage from './pages/editPage/editPage';
import GlobalBlocks from './globalBlocks/globalBlocks';
import DeleteTemplate from './deleteTemplate/deleteTemplate';
import Sounds from './sounds';
import TaskManager from './taskManager';
import PushNotifications from './pushNotifications';
import HeaderBanners from './headerBanners/headerBanners';
import FeedContent from './feedContent/feedContent';
import FeedContentEdit from './feedContent/feedContentEdit/feedContentEdit';
import AppBanner from './appBanner/appBanner';
import HeaderV2 from './dashboard/header-v2/header-v2';
import { HomePageV2 } from './dashboard/home-page-v2/home-page-v2';
import { ProductOptions } from './dashboard/product-page/productOptions';

function WebsiteContent(props) {
  return (
    <div
      style={{
        marginBottom: 50,
        marginTop: '90px',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Switch>
        <Route path="/website-content/home-page" component={HomePage} />
        <Route path="/website-content/home-page-v2" component={HomePageV2} />
        <Route path="/website-content/dashboard/footer" component={Footer} />
        <Route path="/website-content/dashboard/header" component={Header} />
        <Route path="/website-content/dashboard/product-page-options" component={ProductOptions} />
        <Route
          path="/website-content/dashboard/header-v2"
          component={HeaderV2}
        />
        <Route path="/website-content/dashboard" component={Dashboard} />
        <Route path="/website-content/pages/:id" component={EditPage} />
        <Route path="/website-content/pages" component={Pages} />
        <Route path="/website-content/global-blocks" component={GlobalBlocks} />
        <Route
          path="/website-content/delete-templates"
          component={DeleteTemplate}
        />
        <Route
          path="/website-content/feed-content/:id"
          component={FeedContentEdit}
        />
        <Route path="/website-content/feed-content" component={FeedContent} />
        <Route
          path="/website-content/redirect-manager"
          component={RedirectManager}
        />
        <Route path="/website-content/sounds" component={Sounds} />
        <Route path="/website-content/task-manager" component={TaskManager} />
        <Route
          path="/website-content/push-notifications"
          component={PushNotifications}
        />
        <Route
          path="/website-content/header-banners"
          component={HeaderBanners}
        />
        <Route path="/website-content/app-banner" component={AppBanner} />
        <Route component={Dashboard} />
      </Switch>
    </div>
  );
}

export default WebsiteContent;
