import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './course.scss';

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Course(props) {
  const [loading, setLoading] = useState(true);
  const [takenUrls, setTakenUrls] = useState([]);
  const [hasTakenUrl, setHasTakenUrl] = useState(false);
  const [course, setCourse] = useState({
    id: '',
    name: '',
    webPath: '',
    backgroundImage: '',
    description: '',
    sections: [],
    enabled: false,
    position: 0,
  });
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [addSectionTitle, setAddSectionTitle] = useState('');
  const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchCourse();
  }, []);
  
  const fetchCourse = async () => {
    const db = firebase.firestore();

    try {
      const courseId = props.match.params.id;
      const snapshot = await db.collection('associate-training-courses').get();

      let currentCourse = null;
      const otherCourseUrls = [];

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (doc.id === courseId) {
          currentCourse = {
            ...data,
            id: doc.id,
          };
        } else {
          otherCourseUrls.push(data.webPath);
        }
      });

      if (!currentCourse) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('This course was not found, please try again.');
        return;
      }

      setTakenUrls(otherCourseUrls);
      setCourse(currentCourse);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this course, please try again.');
    }
  };

  const handleChange = name => event => {
    setCourse({
      ...course,
      [name]: event.target.value,
    });
  };

  const save = async (updated) => {
    setLoading(true);

    const courseToSave = updated || course;

    try {
      await firebase.firestore().collection('associate-training-courses').doc(courseToSave.id).update(courseToSave);

      setLoading(false);
      setModalTitle('Success!');
      setModalText('This course has been saved!');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this course, please try again.');
    }
  };

  const submitAddSection = async () => {
    const sections = cloneDeep(course.sections);

    sections.push({
      title: addSectionTitle,
      imageUrl: '',
      backgroundImage: '',
      description: '',
      modules: [],
      categories: [''],
      id: uuidv4(),
    });

    const updatedCourse = {
      ...course,
      sections,
    };

    setCourse(updatedCourse);
    setAddSectionModalOpen(false);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);

      if (hasTakenUrl) {
        setModalTitle('Notice:');
        setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
      } else {
        save(updatedCourse);
      }
    }, 0);
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const moveItemUp = (i) => {    
    const sections = cloneDeep(course.sections);
    const itemToMoveUp = sections[i];
    const itemToMoveDown = sections[i - 1];

    sections[i] = itemToMoveDown;
    sections[i - 1] = itemToMoveUp;

    const updatedCourse = {
      ...course,
      sections,
    };

    setCourse(updatedCourse);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const moveItemDown = (i) => {
    const sections = cloneDeep(course.sections);
    const itemToMoveDown = sections[i];
    const itemToMoveUp = sections[i + 1];

    sections[i] = itemToMoveUp;
    sections[i + 1] = itemToMoveDown;

    const updatedCourse = {
      ...course,
      sections,
    };

    setCourse(updatedCourse);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const confirmDelete = () => {
    const sections = cloneDeep(course.sections);
    sections.splice(deleteIndex, 1);

    const updatedCourse = {
      ...course,
      sections,
    };

    setCourse(updatedCourse);
    setDeleteIndex(-1);

    if (hasTakenUrl) {
      setModalTitle('Notice:');
      setModalText('This course has a URL that is already taken. To save changes made to this course, change the provided URL and manually save the course.');
    } else {
      save(updatedCourse);
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Course">
      {renderLoading()}

      <div>
        <p>
          <Link to={`/partners/courses`} style={{color: 'blue', textDecoration: 'none'}}>All Courses</Link> / {course.name}
        </p>
      </div>

      <Card>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {course.name}
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            disabled={hasTakenUrl}
            onClick={() => save()}
          >
            Save
          </Button>
        </Toolbar>

        <Divider/>

        <CardContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={course.enabled}
                  onChange={(e) => {
                    setCourse({
                      ...course,
                      enabled: e.target.checked,
                    });
                  }}
                  name="enabled"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </FormGroup>

          <TextField
            label="Course Name"
            value={course.name}
            onChange={handleChange('name')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Description"
            multiline
            rows="4"
            value={course.description}
            onChange={handleChange('description')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Background Image URL"
            value={course.backgroundImage}
            onChange={handleChange('backgroundImage')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <div className="input-row">
            <FormControl variant="outlined" className="row-item">
              <InputLabel>URL Path</InputLabel>
              <OutlinedInput
                value={course.webPath}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();

                  setHasTakenUrl(takenUrls.includes(value));

                  setCourse({
                    ...course,
                    webPath: value,
                  });
                }}
                margin="dense"
                label="URL Path"
                variant="outlined"
                type="text"
                error={hasTakenUrl}
                startAdornment={<InputAdornment style={{marginRight: 0}} position="start">course/</InputAdornment>}
              />
              {!hasTakenUrl ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
            </FormControl>
          </div>

          <div style={{marginTop: 25}}>
            <Divider/>
          </div>

          <div className="sections-container">
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="subtitle2">
                <span style={{fontSize: 20}}>Sections</span>
              </Typography>

              <Button 
                variant="contained"
                color="primary"
                style={{marginBottom: '5px'}}
                onClick={() => {
                  setAddSectionTitle('');
                  setAddSectionModalOpen(true);
                }}
              >
                Add Section
              </Button>
            </div>

            <List>
              {course.sections.map((s, i) => {
                return (
                  <div key={`section-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText primary={s.title} secondary={s.description} />

                      {renderUpArrow(i)}

                      {renderDownArrow(i, course.sections.length - 1)}

                      <Link to={`/partners/courses/${props.match.params.id}/section/${i}`} style={{color: 'rgba(0, 0, 0, .5)', textDecoration: 'none'}}>
                        <IconButton color="inherit" aria-label="Edit Module">
                          <EditIcon />
                        </IconButton>
                      </Link>

                      <IconButton color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Section?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this section?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="primary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={addSectionModalOpen}
        onClose={() => { setAddSectionModalOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Section</DialogTitle>
        <DialogContent>
          <TextField
            label="Section Title"
            value={addSectionTitle}
            onChange={(e) => {
              setAddSectionTitle(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitAddSection}
            color="secondary"
            disabled={!addSectionTitle}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => { setAddSectionModalOpen(false) }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Course;
