import React, { useState, useEffect } from 'react';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DatePicker from 'react-datepicker';
import {
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';

import './subscriptionDownsellPerformance.scss';

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

function SubscriptionDownsellPerformance({ setSubscriptionsLength }) {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async (newStart, newEnd) => {
    const end = newEnd || endToday;
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date(end.getTime() - (7 * 24 * 60 * 60 * 1000));
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);

    try {
      const querySnapshot = await firebase.firestore().collection('subscriptions')
        .where('downsellAddedDate', '>=', moment(start).valueOf())
        .where('downsellAddedDate', '<=', moment(end).valueOf())
        .get();

      const totalCountsMap = {};

      querySnapshot.docs.forEach(doc => {
        const data = doc.data();

        data.products.forEach(p => {
          if (p.downsellId && !totalCountsMap[p.downsellId]) {
            totalCountsMap[p.downsellId] = {
              name: p.name,
              quantity: p.quantity,
              subscriptionCount: 1,
              amount: p.quantity * +p.price,
              totalSubscriptionsValue: +data.total,
            };
          } else if (p.downsellId) {
            totalCountsMap[p.downsellId].quantity += p.quantity;
            totalCountsMap[p.downsellId].subscriptionCount += 1;
            totalCountsMap[p.downsellId].amount += (p.quantity * +p.price);
            totalCountsMap[p.downsellId].totalSubscriptionsValue += +data.total;
          }
        });

        return data;
      });

      const totalCountsArray = [];

      for (let key in totalCountsMap) {
        totalCountsArray.push({
          ...totalCountsMap[key],
          downsellId: key,
        });
      }

      setSummaryData(totalCountsArray);
      setLoading(false);
      setSubscriptionsLength(querySnapshot.docs.length);
    } catch (e) {
      console.log(e.toString());
      setLoading(false);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="SubscriptionDownsellPerformance">
      <div style={{display: 'flex', position: 'relative', zIndex: 1000, marginBottom: 30}}>
        <div className="date-picker-container">
          <Typography>
            <small>Start</small>
          </Typography>
          <DatePicker
            popperPlacement="top-start"
            selected={startDate}
            onChange={(date) => { fetchSubscriptions(date, endDate) }}
            maxDate={endToday}
          />
        </div>
        <div className="date-divider" style={{marginTop: 14}}>-</div>
        <div className="date-picker-container">
          <Typography>
            <small>End</small>
          </Typography>
          <DatePicker
            popperPlacement="top-end"
            selected={endDate}
            onChange={(date) => { fetchSubscriptions(startDate, date) }}
            maxDate={endToday}
          />
        </div>
      </div>

      <div className="card-row">
        {summaryData.map(d => {
          return (
            <div className="card-container" key={d.name}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    {d.name}
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="subtitle1">
                    <strong>Number or Subscriptions:</strong> {d.subscriptionCount}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Revenue Added:</strong> {formatter.format(d.amount)}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Average Subscription Value:</strong> {formatter.format(d.totalSubscriptionsValue / d.subscriptionCount)}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SubscriptionDownsellPerformance;
