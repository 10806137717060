import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Popover from '@material-ui/core/Popover';

import Section from '../membershipProducts/courseContent/section';
import MultiSection from '../membershipProducts/courseContent/multiSection';

const cloneDeep = require('lodash.clonedeep');

function AcademyBonusPost(props) {
  const [post, setPost] = useState({
    title: '',
    imageUrl: '',
    description: '',
    sections: [],
    backgroundImage: '',
    enabled: false,
    category: '',
    tags: [],
  });
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [nestedSectionDeleteIndex, setNestedSectionDeleteIndex] = useState(-1);

  useEffect(() => {
    async function fetchProduct() {
      const db = firebase.firestore();
      try {
        const doc = await db.collection('academy-bonus').doc(props.match.params.id).get();
        const postData = doc.data();

        if (!postData) {
          props.history.push(`/academy-content/academy-bonus`);
          return;
        }

        setPost(postData);
        setLoading(false);
      } catch (e) {
        console.log(e.toString());
      }
    }

    fetchProduct();
  }, []);

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const savePost = async () => {
    setLoading(true);
    const db = firebase.firestore();
    await db.collection('academy-bonus').doc(props.match.params.id).update(post);
    setLoading(false);
  };

  const handleTextChange = (name) => event => {
    const postCopy = {
      ...post,
      [name]: event.target.value,
    };

    setPost(postCopy);
  };

  const addSection = (type) => {
    const postCopy = {
      ...post,
      sections: [ ...post.sections, type ],
    };

    setPost(postCopy);
  };

  const setNestedSectionDelete = (event, i, j) => {
    setDeleteIndex(i);
    setNestedSectionDeleteIndex(j);
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event, i) => {
    setDeleteIndex(i);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNestedSectionDeleteIndex(-1);
    setDeleteIndex(-1);
  };

  const deleteSection = () => {
    const sectionsCopy = cloneDeep(post.sections);

    if (nestedSectionDeleteIndex === -1) {
      sectionsCopy.splice(deleteIndex, 1);
    } else {
      sectionsCopy[deleteIndex].entries.splice(nestedSectionDeleteIndex, 1);
    }

    setPost({
      ...post,
      sections: sectionsCopy,
    });
    handleClose();
  };

  const moveSectionUp = (i) => {    
    const postCopy = cloneDeep(post);
    const itemToMoveUp = postCopy.sections[i];
    const itemToMoveDown = postCopy.sections[i - 1];
    postCopy.sections[i] = itemToMoveDown;
    postCopy.sections[i - 1] = itemToMoveUp;
    setPost(postCopy);
  };

  const moveSectionDown = (i) => {
    const postCopy = cloneDeep(post);
    const itemToMoveDown = postCopy.sections[i];
    const itemToMoveUp = postCopy.sections[i + 1];
    postCopy.sections[i] = itemToMoveUp;
    postCopy.sections[i + 1] = itemToMoveDown;
    setPost(postCopy);
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveSectionUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveSectionDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const handleChange = (value, i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      sectionsCopy[i].entries[j].entries[k].value = value;
    } else {
      sectionsCopy[i].entries[j].value = value;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const handleTitleChange = (value, i, j) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (j >= 0) {
      sectionsCopy[i].entries[j].sectionTitle = value;
    } else {
      sectionsCopy[i].sectionTitle = value;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const handleSectionImageStyleChange = (value, type, i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      sectionsCopy[i].entries[j].entries[k][type] = value;
    } else {
      sectionsCopy[i].entries[j][type] = value;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const handleVideoThumbnailChange = (value, i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      sectionsCopy[i].entries[j].entries[k].thumbnail = value;
    } else {
      sectionsCopy[i].entries[j].thumbnail = value;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const addTextTrack = (i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    const newTextTrack = {
      title: "English CC",
      language: "en",
      type: 'text/vtt',
      uri: ''
    };

    if (k >= 0) {
      const textTracks = (sectionsCopy[i].entries[j].entries[k].textTracks && sectionsCopy[i].entries[j].entries[k].textTracks.length) ?
        [ ...sectionsCopy[i].entries[j].entries[k].textTracks, newTextTrack ] :
        [ newTextTrack ];
      sectionsCopy[i].entries[j].entries[k].textTracks = textTracks;
    } else {
      const textTracks = (sectionsCopy[i].entries[j].textTracks && sectionsCopy[i].entries[j].textTracks.length) ?
        [ ...sectionsCopy[i].entries[j].textTracks, newTextTrack ] :
        [ newTextTrack ];
      sectionsCopy[i].entries[j].textTracks = textTracks;
    }

    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const handleTextTrackFieldChange = (type, value, i, j, k, l) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (l >= 0) {
      sectionsCopy[i].entries[j].entries[k].textTracks[l][type] = value;
    } else {
      sectionsCopy[i].entries[j].textTracks[k][type] = value;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const removeTextTrack = (i, j, k, l) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (l >= 0) {
      sectionsCopy[i].entries[j].entries[k].textTracks.splice(l, 1);
    } else {
      sectionsCopy[i].entries[j].textTracks.splice(k, 1);
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const addDetail = (type, i, j) => {
    const sectionsCopy = cloneDeep(post.sections);
    const value = {type, value: ''};

    if (type === 'image') {
      value.width = '';
      value.marginTop = '';
      value.marginBottom = '';
    }

    if (type === 'video') {
      value.thumbnail = '';
    }

    if (j >= 0) {
      sectionsCopy[i].entries[j].entries.push(value);
    } else {
      sectionsCopy[i].entries.push(value);
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const removeSectionItem = (i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      sectionsCopy[i].entries[j].entries.splice(k, 1);
    } else {
      sectionsCopy[i].entries.splice(j, 1);
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const moveItemUp = (i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      const itemToMoveUp = sectionsCopy[i].entries[j].entries[k];
      const itemToMoveDown = sectionsCopy[i].entries[j].entries[k - 1];
      sectionsCopy[i].entries[j].entries[k] = itemToMoveDown;
      sectionsCopy[i].entries[j].entries[k - 1] = itemToMoveUp;
    } else {
      const itemToMoveUp = sectionsCopy[i].entries[j];
      const itemToMoveDown = sectionsCopy[i].entries[j - 1];
      sectionsCopy[i].entries[j] = itemToMoveDown;
      sectionsCopy[i].entries[j - 1] = itemToMoveUp;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const moveItemDown = (i, j, k) => {
    const sectionsCopy = cloneDeep(post.sections);
    if (k >= 0) {
      const itemToMoveUp = sectionsCopy[i].entries[j].entries[k];
      const itemToMoveDown = sectionsCopy[i].entries[j].entries[k + 1];
      sectionsCopy[i].entries[j].entries[k] = itemToMoveDown;
      sectionsCopy[i].entries[j].entries[k + 1] = itemToMoveUp;
    } else {
      const itemToMoveUp = sectionsCopy[i].entries[j];
      const itemToMoveDown = sectionsCopy[i].entries[j + 1];
      sectionsCopy[i].entries[j] = itemToMoveDown;
      sectionsCopy[i].entries[j + 1] = itemToMoveUp;
    }
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const moveNestedSectionUp = (i, j) => {
    const sectionsCopy = cloneDeep(post.sections);
    const itemToMoveUp = sectionsCopy[i].entries[j];
    const itemToMoveDown = sectionsCopy[i].entries[j - 1];
    sectionsCopy[i].entries[j] = itemToMoveDown;
    sectionsCopy[i].entries[j - 1] = itemToMoveUp;
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const moveNestedSectionDown = (i, j) => {
    const sectionsCopy = cloneDeep(post.sections);
    const itemToMoveUp = sectionsCopy[i].entries[j];
    const itemToMoveDown = sectionsCopy[i].entries[j + 1];
    sectionsCopy[i].entries[j] = itemToMoveDown;
    sectionsCopy[i].entries[j + 1] = itemToMoveUp;
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const addNestedSection = (i) => {
    const sectionsCopy = cloneDeep(post.sections);
    sectionsCopy[i].entries.push({ sectionTitle: 'Section Title', entries: [] });
    setPost({
      ...post,
      sections: sectionsCopy,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-confirmation' : undefined;

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{padding: '10px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Delete Item?</div>
          <Button 
            variant="contained"
            color="secondary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { deleteSection() }}
          >
            Delete
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginBottom: '5px'}}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Popover>
      {renderLoading()}
      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        <p>
          <Link to={`/academy-content/academy-bonus`} style={{color: 'blue', textDecoration: 'none'}}>Academy Bonus</Link> / {post.title || 'Loading Module...'}
        </p>
      </div>
      <Card className="drag-card" style={{marginTop: '10px', display: 'inline-block', textAlign: 'left'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {post.title || 'Loading Module...'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { savePost() }}
          >
            Save
          </Button>
        </Toolbar>
        <CardContent>
          <div style={{width: '100%'}}>

            <FormGroup row style={{marginBottom: '15px', display: 'flex', justifyContent: 'space-between'}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={post.enabled}
                    onChange={(e) => {
                      setPost({
                        ...post,
                        enabled: e.target.checked,
                      });
                    }}
                    name="enabled"
                    color="primary"
                  />
                }
                label="Enabled"
              />
              <FormControl style={{width: '150px'}}>
                <InputLabel>
                  Category
                </InputLabel>
                <Select
                  style={{width: '100%'}}
                  value={post.category}
                  onChange={(e) => {
                    setPost({
                      ...post,
                      category: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  <MenuItem value={'video'}>Video</MenuItem>
                  <MenuItem value={'podcast'}>Podcast</MenuItem>
                  <MenuItem value={'article'}>Article</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>

          {/* sections: [],
          tags: [], */}
            <TextField
              style={{width: '100%'}}
              label="Module Title"
              value={post.title}
              onChange={handleTextChange('title')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Background Image"
              value={post.imageUrl}
              onChange={handleTextChange('imageUrl')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Header Image"
              value={post.backgroundImage}
              onChange={handleTextChange('backgroundImage')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Video Header (mobile)"
              value={post.videoHeader || ''}
              onChange={handleTextChange('videoHeader')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
            <TextField
              style={{width: '100%'}}
              label="Description"
              value={post.description}
              onChange={handleTextChange('description')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />
          </div>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addSection({ sectionTitle: 'Section Title', entries: [{ sectionTitle: 'Section Title', entries: [] }]}) }}
          >
            Add Section With Sections
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addSection({ sectionTitle: 'Section Title', entries: [] }) }}
          >
            Add Single Section
          </Button>
        </CardContent>

        {post.sections.map((section, i) => {
          if (!section.entries[0] || !section.entries[0].entries) {
            return (
              <Card key={`section-${i}`} style={{marginTop: 5, marginBottom: 5}}>
                <CardContent>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <h4>{section.sectionTitle}</h4>
                    <div style={{alignSelf: 'center', display: 'flex'}}>
                      {renderUpArrow(i)}

                      {renderDownArrow(i, post.sections.length - 1)}

                      <IconButton color="inherit" onClick={(e) => { handleClick(e, i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Section
                    section={section}
                    handleChange={(value, index) => { handleChange(value, i, index) }}
                    handleTitleChange={(value) => { handleTitleChange(value, i) }}
                    handleSectionImageStyleChange={(value, index, type) => { handleSectionImageStyleChange(value, type, i, index) }}
                    handleVideoThumbnailChange={(value, index) => { handleVideoThumbnailChange(value, i, index) }}
                    addTextTrack={(index) => { addTextTrack(i, index) }}
                    handleTextTrackFieldChange={(type, value, j, k) => { handleTextTrackFieldChange(type, value, i, j, k) }}
                    removeTextTrack={(j, k) => { removeTextTrack(i, j, k) }}
                    addDetail={(type) => { addDetail(type, i) }}
                    removeSectionItem={(j) => { removeSectionItem(i, j) }}
                    moveItemUp={(j) => { moveItemUp(i, j) }}
                    moveItemDown={(j) => { moveItemDown(i, j) }}
                  />
                </CardContent>
              </Card>
            );
          }
          return (
            <Card key={`sections-${i}`} style={{marginTop: 5, marginBottom: 5}}>
              <CardContent>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <h4>{section.sectionTitle}</h4>
                  <div style={{alignSelf: 'center', display: 'flex'}}>
                    {renderUpArrow(i)}

                    {renderDownArrow(i, post.sections.length - 1)}

                    <IconButton color="inherit" onClick={(e) => { handleClick(e, i) }} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                <MultiSection
                  sectionTitle={section.sectionTitle}
                  sections={section.entries}
                  handleSectionTitleChange={(value) => { handleTitleChange(value, i) }}
                  handleChange={(value, index, j) => { handleChange(value, i, index, j) }}
                  handleTitleChange={(value, index) => { handleTitleChange(value, i, index) }}
                  handleSectionImageStyleChange={(value, type, index, j) => { handleSectionImageStyleChange(value, type, i, index, j) }}
                  handleVideoThumbnailChange={(value, index, j) => { handleVideoThumbnailChange(value, i, index, j) }}
                  addTextTrack={(index, j) => { addTextTrack(i, index, j) }}
                  handleTextTrackFieldChange={(type, value, j, k, l) => { handleTextTrackFieldChange(type, value, i, j, k, l) }}
                  removeTextTrack={(j, k, l) => { removeTextTrack(i, j, k, l) }}
                  addSection={() => { addNestedSection(i) }}
                  addDetail={(type, j) => { addDetail(type, i, j) }}
                  removeSectionItem={(j, k) => { removeSectionItem(i, j, k) }}
                  moveItemUp={(j, k) => { moveItemUp(i, j, k) }}
                  moveItemDown={(j, k) => { moveItemDown(i, j, k) }}
                  moveNestedSectionUp={(j) => { moveNestedSectionUp(i, j) }}
                  moveNestedSectionDown={(j) => { moveNestedSectionDown(i, j) }}
                  setNestedSectionDelete={(e, j) => { setNestedSectionDelete(e, i, j) }}
                />
              </CardContent>
            </Card>
          );
        })}
      </Card>
    </div>
  );
}

export default AcademyBonusPost;
