import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import PageBuilder from '../../../shared/pageBuilder';
import PagePreview from '../../../shared/pageBuilder/components/preview';

import './footer.scss';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Footer(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editPageContentOpen, setEditPageContentOpen] = useState(false);
  const [page, setPage] = useState({
    editorData: {
      type: 'top-layer',
      children: [],
    },
    customCSS: '',
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [globalTemplates, setGlobalTemplates] = useState([]);

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('website-layout-content').doc('footer').get();
      const templatesQuerySnapshot = await db.collection('global-templates').get();

      const templates = templatesQuerySnapshot.docs.map(doc => {
        const data = doc.data();

        if (data.children && typeof data.children === 'string') {
          data.children = JSON.parse(data.children);
        }

        return data;
      });

      const pageData = querySnapshot.data();
      const onlyGlobalTemplates = templates.filter(t => {
        return t.global;
      });
      const updatedEditorData = updateGlobalTemplates(pageData.editorData, onlyGlobalTemplates);

      pageData.editorData = updatedEditorData;

      setGlobalTemplates(templates);
      setPage(pageData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.alert('An error occurred fetching page content. Refresh the page and try again.');
    }
  };

  const updateGlobalTemplates = (data, templates) => {
    if (data.global) {
      const foundIndex = templates.findIndex(t => {
        return t.id === data.id;
      });

      if (foundIndex !== -1) {
        const templateCopy = cloneDeep(templates[foundIndex]);
        for (let key in templateCopy) {
          data[key] = templateCopy[key];
        }
      }
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        updateGlobalTemplates(children[i], templates);
      }
    }

    return data;
  };

  const findAndSaveGlobalTemplates = (data, templatesToSave) => {
    if (data.global) {
      const dataToSave = {
        ...data,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      if (!dataToSave.created) {
        dataToSave.created = dataToSave.updated;
      }

      templatesToSave[dataToSave.id] = dataToSave;
    }
    
    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        findAndSaveGlobalTemplates(children[i], templatesToSave);
      }
    }

    return templatesToSave;
  };

  const savePage = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const templatesToSave = findAndSaveGlobalTemplates(page.editorData, {});
      const sharedTemplates = [];

      for (let key in templatesToSave) {
        sharedTemplates.push(key);
        try {
          let globalTemplatesCopy = [ ...globalTemplates ];
          const foundIndex = globalTemplates.findIndex(t => {
            return `${t.id}` === `${key}`;
          });
    
          if (foundIndex !== -1) {
            globalTemplatesCopy[foundIndex] = templatesToSave[key];
          } else {
            globalTemplatesCopy.push(templatesToSave[key]);
            globalTemplatesCopy = [ templatesToSave[key], ...globalTemplatesCopy ];
          }
    
          setGlobalTemplates(globalTemplatesCopy);

          await firebase.firestore().collection('global-templates').doc(templatesToSave[key].id).set({
            ...templatesToSave[key],
            children: JSON.stringify(templatesToSave[key].children),
          });
        } catch (e) {
          console.log('error saving template', e);
        }
      }

      const pageDataToSave = {
        ...page,
        sharedTemplates,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await db.collection('website-layout-content').doc('footer').update(pageDataToSave);
      const savedData = (await db.collection('website-layout-content').doc('footer').get()).data();

      const onlyGlobalTemplates = [];

      for (let key in templatesToSave) {
        onlyGlobalTemplates.push(templatesToSave[key]);
      }

      const updatedEditorData = updateGlobalTemplates(savedData.editorData, onlyGlobalTemplates);

      setPage({
        ...savedData,
        editorData: updatedEditorData,
      });
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The footer has been successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the footer. Please try again.');
    }
  };

  const saveModule = async (moduleToSave) => {
    const dataToSave = {
      ...moduleToSave,
      created: Date.now(),
      updated: Date.now(),
      lastUpdatedBy: firebase.auth().currentUser.email,
    };

    // Don't save globals it here. Only save globals on page save
    if (dataToSave.global) {
      setGlobalTemplates([ dataToSave, ...globalTemplates ]);
      setModalTitle('Success!');
      setModalText(`The ${moduleToSave.type} was added an will be saved when saving the footer.`);
    } else {
      console.log('module', moduleToSave);
      const db = firebase.firestore();
      setLoading(true);

      try {
        await db.collection('global-templates').doc(dataToSave.id).set({
          ...dataToSave,
          children: JSON.stringify(dataToSave.children),
        });

        setGlobalTemplates([ dataToSave, ...globalTemplates ]);

        setLoading(false);
        setModalTitle('Success!');
        setModalText(`The ${moduleToSave.type} was saved successfully.`);
      } catch (e) {
        console.log('error', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`There was an error saving this ${moduleToSave.type}. Please try again.`);
      }
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Footer">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/website-content/dashboard">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            Dashboard
          </Button>
        </Link>

        <Button 
          variant="contained"
          color="primary"
          size="small"
          style={{marginBottom: '15px'}}
          onClick={() => {
            setEditPageContentOpen(true);
          }}
        >
          Open Page Builder
        </Button>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>Footer</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={savePage}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <h3>Footer Preview</h3>

          <div className="footer-container">
            <style>{page.customCSS}</style>
            <PagePreview items={page.editorData.children} />
          </div>
        </div>
      </div>

      <Dialog fullScreen open={editPageContentOpen} onClose={() => { setEditPageContentOpen(false) }} TransitionComponent={Transition}>
        <PageBuilder
          content={page.editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            setPage({
              ...page,
              editorData: content,
            });
          }}
          close={() => { setEditPageContentOpen(false) }}
          css={page.customCSS}
          updateCSS={(customCSS) => {
            setPage({
              ...page,
              customCSS,
            });
          }}
        />
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Footer;
