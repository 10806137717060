import React, { useState } from 'react';

import DangerouslySetHtmlContent from '../shared/DangerouslySetHtmlContent';

const styles = {
  tabContainer: {
    overflow: 'hidden',
    display: 'flex',
  },
};

function Tabs({ content, customStyles }) {
  const [currentShownIndex, setCurrentShownIndex] = useState(0);

  return (
    <>
      <div style={{ ...content.containerStyles, ...customStyles }} className={content.customClasses} id={content.htmlID}>
        <div style={{...styles.tabContainer, borderTopLeftRadius: content.contentContainerStyles.borderRadius || 0, borderTopRightRadius: content.contentContainerStyles.borderRadius || 0}}>
          {content.items.map((item, i) => {
            const additionalStyles = i === currentShownIndex ?
              { backgroundColor: content.tabStyles.activeBackgroundColor || content.tabStyles.backgroundColor, color: content.tabStyles.activeColor || content.tabStyles.color } :
              {};
            return (
              <div key={`tab-title-${i}`} style={{ flex: 1 }}>
                <div
                  style={{...content.tabStyles, ...additionalStyles, borderLeft: i === 0 ? '' : '1px solid rgba(0, 0, 0, 0.1)'}}
                  onClick={() => {
                    setCurrentShownIndex(i);
                  }}
                >
                  <div style={{ marginRight: 10, alignSelf: 'center' }}>{item.title}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{...content.contentStyles, borderBottomLeftRadius: content.contentContainerStyles.borderRadius || 0, borderBottomRightRadius: content.contentContainerStyles.borderRadius || 0}}>
          <DangerouslySetHtmlContent html={content.items[currentShownIndex].content} />
        </div>
      </div>
    </>
  );
}

export default Tabs;
