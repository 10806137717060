import React from 'react';
import { Switch, Route } from 'react-router-dom';

import WholesaleProduct from './wholesaleProduct/wholesaleProduct';
import WholesaleProducts from './wholesaleProducts/wholesaleProducts';
import Wholesaler from './wholesaler/wholesaler';
import Wholesalers from './wholesalers/wholesalers';
import WholesaleNotifications from './wholesaleNotifications/wholesaleNotifications';

function Wholesale(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/wholesale/products/:id" component={WholesaleProduct} />
        <Route path="/wholesale/products" component={WholesaleProducts} />
        <Route path="/wholesale/wholesalers/:id" component={Wholesaler} />
        <Route path="/wholesale/wholesalers" component={Wholesalers} />
        <Route path="/wholesale/notifications" component={WholesaleNotifications} />
        <Route component={WholesaleProducts}/>
      </Switch>
    </div>
  );
}

export default Wholesale;
