import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import './questionByQuestion.scss';

function QuestionByQuestion({ questions }) {
  return (
    <div className="QuestionByQuestion">
      <Typography variant="h6" className="question-by-question-title">Question By Question</Typography>

      <TableContainer component={Paper}>
        <Table aria-label="question insights table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Question</strong></TableCell>
              <TableCell><strong>Views</strong></TableCell>
              <TableCell><strong>Completions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((q, i) => (
              <TableRow key={`question-results-${i}`}>
                <TableCell>{q.name}</TableCell>
                <TableCell>{q.views.toLocaleString()}</TableCell>
                <TableCell>{q.completions.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default QuestionByQuestion;
