import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ResponsiveLine } from '@nivo/line';

import './dashboard.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

const earningTypes = [
  {
    value: 'all',
    displayName: 'All',
  },
  {
    value: 'referral',
    displayName: 'Referral',
  },
  {
    value: 'purchase',
    displayName: 'Purchase',
  },
  {
    value: 'action',
    displayName: 'Action',
  },
];

function Dashboard(props) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [earningsStartDate, setEarningsStartDate] = useState('');
  const [earningsEndDate, setEarningsEndDate] = useState('');
  const [earningType, setEarningType] = useState('all');
  const [earnings, setEarnings] = useState([
    {
      id: 'earnings',
      color: 'hsl(75, 70%, 50%)',
      data: [{
        x: moment().utc().add(1, 'day').format('YYYY-MM-DD'),
        y: 0,
      }],
    },
  ]);
  const [spendingsStartDate, setSpendingsStartDate] = useState('');
  const [spendingsEndDate, setSpendingsEndDate] = useState('');
  const [spendingType, setSpendingType] = useState('all');
  const [spendings, setSpendings] = useState([
    {
      id: 'earnings',
      color: 'hsl(75, 70%, 50%)',
      data: [{
        x: moment().utc().add(1, 'day').format('YYYY-MM-DD'),
        y: 0,
      }],
    },
  ]);

  useEffect(() => {
    const end = endToday;
    end.setHours(23, 59, 59, 999);

    const start = new Date();
    start.setHours(0, 0, 0, 0);

    setEarningsStartDate(start);
    setEarningsEndDate(end);
    fetchEarnings(start, end);

    setSpendingsStartDate(start);
    setSpendingsEndDate(end);
    fetchSpendings(start, end);
  }, []);

  const getToken = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      return token;
    } catch (e) {
      return;
    }
  };

  const fetchEarnings = async (start, end) => {
    start = new Date(start);
    start.setHours(0, 0, 0, 0);

    end = new Date(end);
    end.setHours(23, 59, 59, 999);

    setLoading(true);

    const token = await getToken();

    if (!token) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching auth token. Refresh the page and try again.');
      return;
    }

    try {
      const result = await axios(`${config.gateway}/rewards-analytics-service/api/v1/earnings/dates/${moment(start).valueOf()}/${moment(end).valueOf()}${earningType === 'all' ? '' : `/type/${earningType}`}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const earningsData = result.data.map(earning => {
        return {
          x: moment(earning.date).utc().add(1, 'day').format('YYYY-MM-DD'),
          y: earning.earnings,
        };
      });

      setEarnings([
        {
          id: 'earnings',
          color: 'hsl(75, 70%, 50%)',
          data: earningsData,
        },
      ]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching earnings. Refresh the page and try again.');
      return;
    }
  };

  const fetchSpendings = async (start, end) => {
    start = new Date(start);
    start.setHours(0, 0, 0, 0);

    end = new Date(end);
    end.setHours(23, 59, 59, 999);

    setLoading(true);

    const token = await getToken();

    if (!token) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching auth token. Refresh the page and try again.');
      return;
    }

    try {
      const result = await axios(`${config.gateway}/rewards-analytics-service/api/v1/spendings/dates/${moment(start).valueOf()}/${moment(end).valueOf()}${spendingType === 'all' ? '' : `/type/${spendingType}`}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const spendingsData = result.data.map(spending => {
        return {
          x: moment(spending.date).utc().add(1, 'day').format('YYYY-MM-DD'),
          y: spending.spendings,
        };
      });

      setSpendings([
        {
          id: 'earnings',
          color: 'hsl(75, 70%, 50%)',
          data: spendingsData,
        },
      ]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching reward spendings. Refresh the page and try again.');
      return;
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="RewardsDashboard">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Dashboard
        </Typography>
      </Toolbar>

      <div className="content">
        <Toolbar className="sub-title-container" style={{marginTop: -21}}>
          <Typography variant="subtitle1">
            Reward Earnings
          </Typography>
        </Toolbar>

        <div className="dates-row">
          <div className="date-picker-container">
            <Typography>
              <small>Start</small>
            </Typography>
            <DatePicker
              selected={earningsStartDate}
              onChange={(date) => { setEarningsStartDate(date) }}
              maxDate={endToday}
            />
          </div>
          <div className="date-divider" style={{marginTop: 14}}>-</div>
          <div className="date-picker-container">
            <Typography>
              <small>End</small>
            </Typography>
            <DatePicker
              selected={earningsEndDate}
              onChange={(date) => { setEarningsEndDate(date) }}
              maxDate={endToday}
            />
          </div>

          <div className="dates-row-item-container">
            <FormControl variant="outlined" margin="dense" className="earning-type-select">
              <InputLabel>Earning Type</InputLabel>
              <Select
                value={earningType}
                onChange={(e) => {
                  setEarningType(e.target.value);
                }}
                label="Earning Type"
              >
                {earningTypes.map((type, i) => {
                  return <MenuItem key={`earning-type-${i}`} value={type.value}>{type.displayName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>

          <div className="dates-row-item-container">
            <Button variant="contained" onClick={() => { fetchEarnings(earningsStartDate, earningsEndDate) }} color="primary">
              Submit
            </Button>
          </div>
        </div>

        <div style={{height: 500}}>
          <ResponsiveLine
            data={earnings}
            colors={{ scheme: 'accent' }}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              legend: 'Date',
              legendOffset: -12,
              format: "%b %d",
              tickRotation: 50,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Total Amount ($)',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
            tooltip={(props) => (
              <div style={{ backgroundColor: '#fff', padding: 5, border: '1px solid rgba(0, 0, 0, 0.5)' }}>
                <strong>Date:</strong> {props.point.data.xFormatted}
                <strong style={{marginLeft: 10}}>Amount:</strong> ${props.point.data.yFormatted}
              </div>
            )}
          />
        </div>

        <Toolbar className="sub-title-container">
          <Typography variant="subtitle1">
            Reward Spending
          </Typography>
        </Toolbar>

        <div className="dates-row">
          <div className="date-picker-container">
            <Typography>
              <small>Start</small>
            </Typography>
            <DatePicker
              selected={spendingsStartDate}
              onChange={(date) => { setSpendingsStartDate(date) }}
              maxDate={endToday}
            />
          </div>
          <div className="date-divider" style={{marginTop: 14}}>-</div>
          <div className="date-picker-container">
            <Typography>
              <small>End</small>
            </Typography>
            <DatePicker
              selected={spendingsEndDate}
              onChange={(date) => { setSpendingsEndDate(date) }}
              maxDate={endToday}
            />
          </div>

          <div className="dates-row-item-container">
            <FormControl variant="outlined" margin="dense" className="earning-type-select">
              <InputLabel>Spending Type</InputLabel>
              <Select
                value={spendingType}
                onChange={(e) => {
                  setSpendingType(e.target.value);
                }}
                label="Spending Type"
              >
                {earningTypes.map((type, i) => {
                  return <MenuItem key={`earning-type-${i}`} value={type.value}>{type.displayName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>

          <div className="dates-row-item-container">
            <Button variant="contained" onClick={() => { fetchSpendings(spendingsStartDate, spendingsEndDate) }} color="primary">
              Submit
            </Button>
          </div>
        </div>

        <div style={{height: 500}}>
          <ResponsiveLine
            data={spendings}
            colors={{ scheme: 'accent' }}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              legend: 'Date',
              legendOffset: -12,
              format: "%b %d",
              tickRotation: 50,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Total Amount ($)',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
            tooltip={(props) => (
              <div style={{ backgroundColor: '#fff', padding: 5, border: '1px solid rgba(0, 0, 0, 0.5)' }}>
                <strong>Date:</strong> {props.point.data.xFormatted}
                <strong style={{marginLeft: 10}}>Amount:</strong> ${props.point.data.yFormatted}
              </div>
            )}
          />
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
