import React, { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axios from 'axios';
import debounce from 'lodash.debounce';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Slide,
  Switch,
  TextField,
  TableContainer,
  Toolbar,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './settings.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Settings(props) {
  const delayedSaveShippingDiscountNudge = useCallback(debounce((d) => saveShippingDiscountNudge(d), 500), []);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [settings, setSettings] = useState({
    stripeEnabled: false,
    paypalEnabled: false,
  });
  const [settingConfirm, setSettingConfirm] = useState('');
  const [showSettingChangedConfirmation, setShowSettingChangedConfirmation] = useState(false);
  const [shippingDiscountNudge, setShippingDiscountNudge] = useState({
    active: false,
    tiers: [],
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [nexusRegions, setNexusRegions] = useState([]);
  const [notifyEmails, setNotifyEmails] = useState([]);
  const [editEmails, setEditEmails] = useState([]);
  const [editEmailsOpen, setEditEmailsOpen] = useState(false);

  useEffect(() => {
    getShopSettings();
  }, []);

  const getShopSettings = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('config').doc('shop-settings').get();
      const shippingDiscountNudgeSnapshot = await db.collection('config').doc('shipping-discount-nudge').get();
      const taxQuerySnapshot = await db.collection('tax-config').doc('config').get();
      const outOfStockEmailsSnapshot = await db.collection('out-of-stock-emails').doc('admins').get();

      setSettings(querySnapshot.data());
      setShippingDiscountNudge(shippingDiscountNudgeSnapshot.data());
      setNexusRegions(taxQuerySnapshot.data().nexusRegions);
      setNotifyEmails(outOfStockEmailsSnapshot.data().list);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching shop config. Please refresh and try again.');
    }
  };

  const cancelSettingChange = () => {
    setSettings({
      ...settings,
      [settingConfirm]: !settings[settingConfirm],
    });

    setSettingConfirm('');
  };

  const confirmSettingChange = async () => {
    const db = firebase.firestore();

    setLoading(true);

    try {
      await db.collection('config').doc('shop-settings').update({
        [settingConfirm]: settings[settingConfirm],
      });

      setSettingConfirm('');
      setShowSettingChangedConfirmation(true);
    } catch (e) {
      cancelSettingChange();
      window.alert('Error saving settings. Please try again.');
    }

    setLoading(false);
  };

  const syncNexusRegions = async () => {
    setLoading(true);
    try {
      const result = await axios.get(`${config.functions}/webApi/nexus-regions`);

      if (result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      if (!result.data.regions && !Array.isArray(result.data.regions)) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error syncing Nexus Regions. Please try again.');
        return;
      }

      await firebase.firestore().collection('tax-config').doc('config').update({
        nexusRegions: result.data.regions,
      });

      setNexusRegions(result.data.regions);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error syncing Nexus Regions. Please try again.');
    }
  };

  const cancelEditEmails = () => {
    setEditEmails([]);
    setEditEmailsOpen(false);
  };

  const saveNotificationEmails = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('out-of-stock-emails').doc('admins').update({
        list: editEmails,
      });

      setNotifyEmails(editEmails);
      setLoading(false);
      cancelEditEmails();
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the Product Out of Stock notification settings. Please refresh and try again.');
    }
  };

  const saveShippingDiscountNudge = async (data) => {
    try {
      await firebase.firestore().collection('config').doc('shipping-discount-nudge').update(data);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error saving the Cart Shipping Discount Nudge settings. Please refresh and try again.');
    }
  };

  const handleShippingDiscountValueChange = (e, type, i, isCheckbox) => {
    const tiers = [ ...shippingDiscountNudge.tiers ];

    const value = isCheckbox ? e.target.checked : e.target.value;

    const tierCopy = {
      ...tiers[i],
      [type]: value,
    };

    tiers[i] = tierCopy;

    const updatedShippingDiscountNudge = {
      ...shippingDiscountNudge,
      tiers,
    };

    setShippingDiscountNudge(updatedShippingDiscountNudge);
    delayedSaveShippingDiscountNudge(updatedShippingDiscountNudge);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ShopSettings">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Shop Settings
        </Typography>
      </Toolbar>
      <div className="content">
        <div className="section-container">
          <div className="section-label">Settings</div>
          <Typography variant="subtitle1">
            Payments
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={settings.stripeEnabled}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    stripeEnabled: e.target.checked,
                  });
                  setSettingConfirm('stripeEnabled');
                }}
                name="enabled"
                color="primary"
              />
            }
            label="Stripe Enabled"
          />
          <FormControlLabel
            control={
              <Switch
                checked={settings.paypalEnabled}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    paypalEnabled: e.target.checked,
                  });
                  setSettingConfirm('paypalEnabled');
                }}
                name="enabled"
                color="primary"
              />
            }
            label="PayPal Enabled"
          />
        </div>
      </div>

      <div className="content">
        <div className="section-container">
          <div className="section-label">Product Out of Stock Notifications</div>
          <div className="notifications-container">
            <Typography variant="subtitle1">
              Emails
              <IconButton
                aria-label="Edit Emails"
                size="small"
                onClick={() => {
                  setEditEmails([ ...notifyEmails ]);
                  setEditEmailsOpen(true);
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>

            <div>{notifyEmails.length ? notifyEmails.join(', ') : 'There are no notification emails set up.'}</div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="section-container">
          <div className="section-label">Cart Shipping Discount Nudge</div>

          <div className="shipping-discount-nudge-top-container">
            <FormControlLabel
              control={
                <Switch
                  checked={shippingDiscountNudge.active}
                  onChange={(e) => {
                    const updatedShippingDiscountNudge = {
                      ...shippingDiscountNudge,
                      active: e.target.checked,
                    };

                    setShippingDiscountNudge(updatedShippingDiscountNudge);
                    delayedSaveShippingDiscountNudge(updatedShippingDiscountNudge);
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Enabled"
            />

            {!shippingDiscountNudge.active ? null :
              <Button
                onClick={() => {
                  const tiers = [ ...shippingDiscountNudge.tiers ];

                  tiers.push({
                    minimumSubtotalAmount: 50,
                    discountPercentage: 100,
                    active: true,
                  });

                  const updatedShippingDiscountNudge = {
                    ...shippingDiscountNudge,
                    tiers,
                  };

                  setShippingDiscountNudge(updatedShippingDiscountNudge);
                  delayedSaveShippingDiscountNudge(updatedShippingDiscountNudge);
                }}
                variant="contained"
                size="small"
                color="primary"
              >
                Add Tier
              </Button>
            }
          </div>

          {!shippingDiscountNudge.active ? null :
            <>
              <TableContainer component={Paper}>
                <Table aria-label="discount tiers table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Active</strong></TableCell>
                      <TableCell><strong>Minimum Order Subtotal</strong></TableCell>
                      <TableCell><strong>Shipping Discount Percentage</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shippingDiscountNudge.tiers.map((t, i) => ( // TODO: add ability to save these
                      <TableRow key={`shipping-tier-${i}`}>
                        <TableCell>
                          <Checkbox
                            checked={t.active}
                            onChange={(e) => { handleShippingDiscountValueChange(e, 'active', i, true) }}
                            inputProps={{ 'aria-label': 'tier active' }}
                          />
                        </TableCell>
                        <TableCell>
                          <OutlinedInput
                            value={t.minimumSubtotalAmount}
                            onChange={(e) => { handleShippingDiscountValueChange(e, 'minimumSubtotalAmount', i) }}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                        </TableCell>
                        <TableCell>
                          <OutlinedInput
                            value={t.discountPercentage}
                            onChange={(e) => { handleShippingDiscountValueChange(e, 'discountPercentage', i) }}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            endAdornment={<InputAdornment position="start">%</InputAdornment>}
                          />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              const tiers = [ ...shippingDiscountNudge.tiers ];

                              tiers.splice(i, 1);

                              const updatedShippingDiscountNudge = {
                                ...shippingDiscountNudge,
                                tiers,
                              };

                              setShippingDiscountNudge(updatedShippingDiscountNudge);
                              delayedSaveShippingDiscountNudge(updatedShippingDiscountNudge);
                            }}
                            aria-label="Delete Discount Tier"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </div>
      </div>

      <div className="content">
        <div className="section-container">
          <div className="section-label">Tax</div>
          <div className="nexus-regions-row">
            <Typography variant="subtitle1">
              Nexus Regions
            </Typography>

            <Button variant="contained" size="small" onClick={syncNexusRegions} color="primary">
              Sync Nexus Regions
            </Button>
          </div>

          <List>
            {nexusRegions.map((r, i) => {
              return (
                <div key={`nexus-region-${i}`}>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={`${r.region} - ${r.country}`} />
                  </ListItem>
                </div>
              );
            })}
            <Divider />
          </List>
        </div>
      </div>

      <Dialog
        open={editEmailsOpen}
        onClose={cancelEditEmails}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Notify Emails</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            onClick={() => {
              const emailCopies = [ ...editEmails ];
              emailCopies.push('');
              setEditEmails(emailCopies);
            }}
            color="primary"
            style={{marginBottom: 20}}
          >
            Add
          </Button>
          {editEmails.map((email, i) => {
            return (
              <div key={`edit-notify-email-${i}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  label={`Email ${i + 1}`}
                  value={email}
                  onChange={(e) => {
                    const emailCopies = [ ...editEmails ];
                    emailCopies[i] = e.target.value;
                    setEditEmails(emailCopies);
                  }}
                  variant="outlined"
                  margin="dense"
                  type="text"
                  className="day-text-field"
                />

                <IconButton
                  aria-label="Delete email"
                  onClick={() => {
                    const emailCopies = [ ...editEmails ];
                    emailCopies.splice(i, 1);
                    setEditEmails(emailCopies);
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNotificationEmails} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={cancelEditEmails} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!settingConfirm}
        onClose={() => { cancelSettingChange() }}
      >
        <DialogTitle>{`${settingConfirm === 'stripeEnabled' ?
          (settings.stripeEnabled ? 'Enable' : 'Disable') :
          (settings.paypalEnabled ? 'Enable' : 'Disable')} ${settingConfirm === 'stripeEnabled' ? 'Stripe' : 'Paypal'}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {`${settingConfirm === 'stripeEnabled' ?
          (settings.stripeEnabled ? 'Enable' : 'Disable') :
          (settings.paypalEnabled ? 'Enable' : 'Disable')} ${settingConfirm === 'stripeEnabled' ? 'Stripe' : 'Paypal'}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { confirmSettingChange() }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { cancelSettingChange() }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSettingChangedConfirmation}
        onClose={() => { setShowSettingChangedConfirmation(false) }}
      >
        <DialogTitle>Success!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Shop settings have been updated. To publish these changes on the website, go to the Website Content dashboard and hit Deploy Website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setShowSettingChangedConfirmation(false) }} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!modalText}
        onClose={() => { setModalText('') }}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modalText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Settings;
