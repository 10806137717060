import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import EditFeaturedProduct from './editFeaturedProduct/editFeaturedProduct';
import './cartFeaturedProducts.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CartFeaturedProducts(props) {
  const [loading, setLoading] = useState(true);
  const [featuredCartProducts, setFeaturedCartProducts] = useState([]);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editOpen, setEditOpen] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    getFeaturedCartProducts();
  }, []);

  const getFeaturedCartProducts = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();

      setFeaturedCartProducts(querySnapshot.data().featuredCartProducts);
      setSimplifiedProducts(querySnapshot.data().simplifiedProducts);
      setProductCategories(querySnapshot.data().productCategories);
      setProductTags(contentQuerySnapshot.data().tags);
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.alert('An error occurred fetching Featured Cart Products. Refresh the page and try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const saveProduct = async (product) => {
    setEditOpen(false);
    setLoading(true);

    const products = [ ...featuredCartProducts ];

    if (editIndex !== -1) {
      products[editIndex] = product;
    } else {
      products.push(product);
    }

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        featuredCartProducts: products,
      });

      setFeaturedCartProducts(products);
      setLoading(false);
      setEditIndex(-1);
    } catch (e) {
      setLoading(false);
      setEditIndex(-1);
      console.log(e);
      window.alert('An error occurred saving Featured Cart Products. Refresh the page and try again.');
    }
  };

  const deleteProduct = async () => {
    setLoading(true);

    const products = [ ...featuredCartProducts ];

    products.splice(deleteIndex, 1);

    try {
      await firebase.firestore().collection('config').doc('shop-settings').update({
        featuredCartProducts: products,
      });

      setFeaturedCartProducts(products);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      console.log(e);
      window.alert('An error occurred deleting the Featured Product. Refresh the page and try again.');
    }
  };

  return (
    <div className="CartFeaturedProducts">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between',}}>
          <Typography variant="h6">
            Featured Cart Products
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => {
            if (!simplifiedProducts.length) {
              return;
            }

            setEditProduct({
              active: false,
              selectedProduct: simplifiedProducts[0],
              rules: [],
            });
            setEditIndex(-1);
            setEditOpen(true);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>
        <List>
          {featuredCartProducts.map((p, i) => {
            return (
              <div key={`cart-product-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.selectedProduct.name} secondary={<span>Status: <span style={{color: p.active ? 'rgb(126, 210, 66)' : 'red'}}>{p.active ? 'Active' : 'Disabled'}</span></span>} />
                  <IconButton edge="start" style={{marginRight: 15}} color="inherit" onClick={() => {
                    setEditProduct(p);
                    setEditIndex(i);
                    setEditOpen(true);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={editOpen} onClose={() => {
        setEditOpen(false);
        setEditIndex(-1);
      }} TransitionComponent={Transition}>
        <EditFeaturedProduct
          closeModal={() => {
            setEditOpen(false);
            setEditIndex(-1);
          }}
          save={saveProduct}
          product={editProduct}
          simplifiedProducts={simplifiedProducts}
          productCategories={productCategories}
          productTags={productTags}
        ></EditFeaturedProduct>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Featured Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this featured product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteProduct} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CartFeaturedProducts;
