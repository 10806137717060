import React, { useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import DangerouslySetHtmlContent from './shared/DangerouslySetHtmlContent';

import Modal from './shared/modal';
import EditModal from './shared/editModal';
import upArrow from '../icons/up.png';
import downArrow from '../icons/down.png';
import editArrow from '../icons/edit.png';
import deleteIcon from '../icons/delete.png';
import moveIcon from '../icons/move.png';
import copyIcon from '../icons/copy.png';

const styles = {
  container: {
    padding: '5px',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: 'lightblue',
  },
  toolbarItem: {
    height: 15,
    width: 15,
    margin: 5,
    cursor: 'pointer',
  },
  image: {
    height: '100%',
  },
};

function CustomCode({ content, updateContent, upClicked, downClicked, deleteItem, copyClicked }) {
  const [, drag] = useDrag({
    item: { ...content },
    end(item, monitor) {
      if (!monitor.didDrop()) {
        return;
      }

      deleteItem();
    },
  });
  const innerRef = useRef(null);
  const [showTools, setShowTools] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.onmouseenter = (e) => {
        setShowTools(true);
      };

      innerRef.current.onmouseleave = (e) => {
        setShowTools(false);
      };
    }
  }, [innerRef]);

  const delayedDelete = () => {
    setTimeout(() => {
      deleteItem();
    }, 50);
  };

  return (
    <div style={{ ...styles.container }}>
      <div
        ref={innerRef}
      >
        {!showTools ? null :
          <div style={styles.inner}>
            <div style={styles.toolbar}>
              <div style={styles.toolbarItem} ref={drag}>
                <img style={styles.image} src={moveIcon} alt="move" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { copyClicked() }}>
                <img style={styles.image} src={copyIcon} alt="copy" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={upArrow} onClick={upClicked} alt="up arrow" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={downArrow} onClick={downClicked} alt="down arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setEditModalOpen(true) }}>
                <img style={styles.image} src={editArrow} alt="edit" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setDeleteModalOpen(true) }}>
                <img style={styles.image} src={deleteIcon} alt="delete" />
              </div>
            </div>
          </div>
        }
        Edit HTML
        {!content.html ? null :
          <DangerouslySetHtmlContent html={content.html} />
        }
      </div>

      {/* <div style="width: 100%; padding-top: 56.25%; position: relative;">
        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #000;">
          <iframe src="https://player.vimeo.com/video/403858191?autoplay=1" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div> */}

      <EditModal
        open={editModalOpen}
        close={() => { setEditModalOpen(false) }}
        title={`Edit ${content.title}`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            setEditModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setEditModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="input-container" style={{ width: '100%' }}>
            <label>HTML</label>
            <textarea
              style={{ width: '100%', resize: 'none' }}
              rows={5}
              value={content.html}
              onChange={(e) => {
                updateContent({
                  ...content,
                  html: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </EditModal>

      <Modal
        open={deleteModalOpen}
        close={() => { setDeleteModalOpen(false) }}
        title={`Delete ${content.title}?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            delayedDelete();
            setDeleteModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this?</div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomCode;
