import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import Blog from './blog/blog';
import EditBlogPost from './blog/editBlogPost/editBlogPost';
import SideBars from './sideBars/sideBars';
import EditSideBar from './sideBars/editSideBar/editSideBar';
import Comments from './comments/comments';

function BlogContent(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/blog-content/dashboard" component={Dashboard} />
        <Route path="/blog-content/blog-posts/:id" component={EditBlogPost} />
        <Route path="/blog-content/blog-posts" component={Blog} />
        <Route path="/blog-content/comments" component={Comments} />
        <Route path="/blog-content/side-bars/:id" component={EditSideBar} />
        <Route path="/blog-content/side-bars" component={SideBars} />
        <Route component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default BlogContent;
