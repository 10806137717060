import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Divider,
  TextField,
  Toolbar,
} from '@material-ui/core';

export const GetStartedItems = ({ control, errors }) => {
  return (
    <div className="section-container" style={{ margin: 0 }}>
      <div className="section-label">Categories Items</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 8px',
        }}
      >
        {Array.from({ length: 5 })
          .fill('_')
          ?.map((item, index) => (
            <Card
              key={item.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
              }}
              variant="outlined"
            >
              <Toolbar
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  <strong>Item {index + 1}</strong>
                </span>
              </Toolbar>

              <Divider />
              <CardContent>
                <Controller
                  name={`getStartedSection.words.${index}.text`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Title"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                        error={Boolean(
                          errors.getStartedSection?.words[index]?.text,
                        )}
                        helperText={
                          errors.getStartedSection?.words[index]?.text?.message
                        }
                      />
                    );
                  }}
                />
              </CardContent>
            </Card>
          ))}
      </Box>
    </div>
  );
};
