import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  CircularProgress,
  Button,
  Toolbar,
  Typography,
  TextField,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';

import './homePage.scss';
import HomePagePreview from './homePagePreview/homePagePreview';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomePage(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [addBannerItem, setAddBannerItem] = useState(false);
  const [newBannerItem, setNewBannerItem] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [page, setPage] = useState({
    headerBanner: {
      timeBetweenTransitionsSeconds: 0,
      items: [
        {
          slideLabel: 'New Slide',
          type: '',
          backgroundColor: '',
          h1: '',
          h1Color: '',
          h1Position: 'top-screen',
          h2: '',
          h2Color: '',
          p: '',
          pColor: '',
          buttonText: '',
          buttonBackgroundColor: '',
          buttonTextColor: '',
          buttonLinkUrl: '',
          subLinkText: '',
          subLinkColor: '',
          subLinkUrl: '',
          mediaType: '',
          imageUrl: '',
          videoEmbedCode: '',
          live: false,
        },
      ],
    },
    pageTitle: '',
    pageDescription: '',
    productSectionTitle: '',
    productSectionSubtitle: '',
    products: [],
    trainingSectionTitle: '',
    trainingSectionSubtitle: '',
    trainingSectionParagraph: '',
    trainingSectionImageUrl: '',
    trainingSectionVideoEmbed: '',
    trainingSectionPrimaryButtonText: '',
    trainingSectionPrimaryButtonUrl: '',
    trainingSectionDownloadAppsLinksVisible: true,
    reviewsBanner: {
      animationDuration: 0,
      duplicates: 0,
      backgroundColor: '',
      fontColor: '',
      items: [],
    },
    shopAllSectionTitle: '',
    shopAllSectionParagraph: '',
    shopAllSectionImageUrl: '',
    reviewsSectionTitle: '',
    reviewsSectionSubtitle: '',
    review1ImageUrl: '',
    review1Text: '',
    review1Name: '',
    review2ImageUrl: '',
    review2Text: '',
    review2Name: '',
    review3ImageUrl: '',
    review3Text: '',
    review3Name: '',
  });

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = async () => {
    try {
      const homePageSnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('home-page')
        .get();
      const homePageData = homePageSnapshot.data();
      const shopPageDataSnapshot = await firebase
        .firestore()
        .collection('config')
        .doc('shop-settings')
        .get();

      setPage(homePageData);
      setSimplifiedProducts(shopPageDataSnapshot.data().simplifiedProducts);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText(
        'An error occurred fetching page content. Refresh the page and try again.',
      );
    }
  };

  const savePage = async () => {
    setLoading(true);

    try {
      await firebase
        .firestore()
        .collection('config')
        .doc('home-page')
        .update(page);

      setLoading(false);
      setModalTitle('Success!');
      setModalText(
        'This page has been successfully updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this page. Please try again.');
    }
  };

  const addNewProduct = () => {
    const newPage = cloneDeep(page);

    setPage({ ...newPage, products: [...newPage.products, selectedProduct] });

    setAddProduct(false);
  };

  const addNewBannerItem = () => {
    const newPage = cloneDeep(page);
    const newArr = newPage.reviewsBanner.items;
    let newReviewBanner = newPage.reviewsBanner;

    newArr.push(newBannerItem);

    newReviewBanner = { ...newReviewBanner, items: newArr };

    setPage({ ...newPage, reviewsBanner: newReviewBanner });

    setNewBannerItem('');
    setAddBannerItem(false);
  };

  const removeProduct = (i) => {
    const newPage = cloneDeep(page);

    newPage.products.splice(i, 1);

    setPage({ ...newPage, products: newPage.products });
  };

  const changeType = (value, i, type) => {
    const newPage = cloneDeep(page);

    newPage.headerBanner.items[i] = {
      ...newPage.headerBanner.items[i],
      [type]: value,
    };

    setPage(newPage);
  };

  const removeItem = (i) => {
    const newPage = cloneDeep(page);

    newPage.reviewsBanner.items.splice(i, 1);

    const newReviewBanner = {
      ...newPage.reviewsBanner,
      items: newPage.reviewsBanner.items,
    };

    setPage({ ...newPage, reviewsBanner: newReviewBanner });
  };

  const addNewSlide = () => {
    const newSlides = cloneDeep(page.headerBanner.items);

    const newSlide = {
      slideLabel: 'New Slide',
      type: 'cta-right',
      backgroundColor: '',
      h1: '',
      h1Color: '',
      h1FontSize: '',
      h1Position: 'top-screen',
      h2: '',
      h2Color: '',
      p: '',
      pColor: '',
      buttonText: '',
      buttonBackgroundColor: '',
      buttonTextColor: '',
      buttonLinkUrl: '',
      subLinkText: '',
      subLinkColor: '',
      subLinkUrl: '',
      mediaType: '',
      imageUrl: '',
      videoEmbedCode: '',
      live: false,
    };

    newSlides.unshift(newSlide);

    setPage({
      ...page,
      headerBanner: { ...page.headerBanner, items: newSlides },
    });
  };

  const removeSlide = (i) => {
    const newPage = cloneDeep(page);

    newPage.headerBanner.items.splice(i, 1);

    const newHeaderBanner = {
      ...newPage.headerBanner,
      items: newPage.headerBanner.items,
    };

    setPage({ ...newPage, headerBanner: newHeaderBanner });
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textAlign: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#fff', top: '50%', position: 'absolute' }}
        />
      </div>
    );
  };

  return (
    <div className="HomePage">
      {renderLoading()}

      <div>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        >
          <Typography variant="h6">
            <strong>Home Page</strong>
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowPreview(true)}
              style={{ marginRight: 10 }}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={savePage}
            >
              Save
            </Button>
          </div>
        </Toolbar>

        <div className="content-container">
          <div className="form-row">
            <h2>Header Slide</h2>
            <TextField
              label="Transition Time"
              value={page.headerBanner.timeBetweenTransitionsSeconds}
              onChange={(e) => {
                setPage({
                  ...page,
                  headerBanner: {
                    ...page.headerBanner,
                    timeBetweenTransitionsSeconds: e.target.value,
                  },
                });
              }}
              variant="outlined"
              margin="dense"
              type="number"
              className="day-text-field"
            />
          </div>
          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flex: 1,
              }}
            ></Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addNewSlide()}
            >
              Add Slide
            </Button>
          </Toolbar>
          {page.headerBanner.items.length > 0
            ? page.headerBanner.items.map((item, i) => {
                return (
                  <div key={i}>
                    <Toolbar>
                      <Typography
                        variant="h6"
                        style={{
                          flex: 1,
                        }}
                      >
                        {`${item.slideLabel}`}
                      </Typography>

                      {i === 0 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const slides = cloneDeep(page.headerBanner.items);

                            const itemToMoveUp = slides[i];
                            const itemToMoveDown = slides[i - 1];

                            slides[i] = itemToMoveDown;
                            slides[i - 1] = itemToMoveUp;

                            setPage({
                              ...page,
                              headerBanner: {
                                ...page.headerBanner,
                                items: slides,
                              },
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowUpIcon />
                        </IconButton>
                      )}
                      {i === page.headerBanner.items.length - 1 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            const slides = cloneDeep(page.headerBanner.items);

                            const itemToMoveUp = slides[i];
                            const itemToMoveDown = slides[i + 1];

                            slides[i] = itemToMoveDown;
                            slides[i + 1] = itemToMoveUp;

                            setPage({
                              ...page,
                              headerBanner: {
                                ...page.headerBanner,
                                items: slides,
                              },
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowDownIcon />
                        </IconButton>
                      )}
                      <IconButton
                        edge="start"
                        style={{ marginLeft: 10 }}
                        color="inherit"
                        aria-label="Delete"
                        onClick={() => removeSlide(i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Toolbar>
                    <div className="form-row">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item.live ? true : false}
                            onChange={() => {
                              changeType(item.live ? false : true, i, 'live');
                            }}
                            name="active"
                            color="primary"
                          />
                        }
                        label="Live"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Slide Label (Internal Use Only)"
                        value={item.slideLabel || ''}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'slideLabel')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel>Layout</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        value={page.headerBanner.items[i].type}
                        onChange={(e) => changeType(e.target.value, i, 'type')}
                        label="Product"
                      >
                        <MenuItem key={`option`} value={'cta-left'}>
                          CTA on the Left
                        </MenuItem>
                        ;
                        <MenuItem key={`option`} value={'cta-right'}>
                          CTA on the Right
                        </MenuItem>
                        ;
                      </Select>
                    </FormControl>
                    <div className="form-row">
                      <TextField
                        label="Background Color"
                        value={page.headerBanner.items[i].backgroundColor}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'backgroundColor')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Title"
                        value={page.headerBanner.items[i].h1}
                        onChange={(e) => changeType(e.target.value, i, 'h1')}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Title Color"
                        value={page.headerBanner.items[i].h1Color}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'h1Color')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Title Font Size"
                        value={page.headerBanner.items[i].h1FontSize || ''}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'h1FontSize')
                        }
                        variant="outlined"
                        margin="dense"
                        type="number"
                        className="day-text-field"
                      />
                    </div>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel>Title Position</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        value={page.headerBanner.items[i].h1Position || ''}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'h1Position')
                        }
                        label="Product"
                      >
                        <MenuItem key={`option`} value={'top-screen'}>
                          Top of Screen
                        </MenuItem>
                        ;
                        <MenuItem key={`option`} value={'above-subtitle'}>
                          Above Subtitle
                        </MenuItem>
                        ;
                      </Select>
                    </FormControl>
                    <div className="form-row">
                      <TextField
                        label="Subtitle"
                        value={page.headerBanner.items[i].h2}
                        onChange={(e) => changeType(e.target.value, i, 'h2')}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Subtitle Color"
                        value={page.headerBanner.items[i].h2Color}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'h2Color')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Subtitle Font Size"
                        value={page.headerBanner.items[i].h2FontSize || ''}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'h2FontSize')
                        }
                        variant="outlined"
                        margin="dense"
                        type="number"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Description"
                        value={page.headerBanner.items[i].p}
                        onChange={(e) => changeType(e.target.value, i, 'p')}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Description Color"
                        value={page.headerBanner.items[i].pColor}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'pColor')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Description Font Size"
                        value={page.headerBanner.items[i].pFontSize || ''}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'pFontSize')
                        }
                        variant="outlined"
                        margin="dense"
                        type="number"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Button Text"
                        value={page.headerBanner.items[i].buttonText}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'buttonText')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Button Color"
                        value={page.headerBanner.items[i].buttonBackgroundColor}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'buttonBackgroundColor')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Button Text Color"
                        value={page.headerBanner.items[i].buttonTextColor}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'buttonTextColor')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Button URL Link"
                        value={page.headerBanner.items[i].buttonLinkUrl}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'buttonLinkUrl')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Subtitle Link Text"
                        value={page.headerBanner.items[i].subLinkText}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'subLinkText')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Subtitle Link Text Color"
                        value={page.headerBanner.items[i].subLinkColor}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'subLinkColor')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <TextField
                        label="Subtitle Link URL"
                        value={page.headerBanner.items[i].subLinkUrl}
                        onChange={(e) =>
                          changeType(e.target.value, i, 'subLinkUrl')
                        }
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>
                    <div className="form-row">
                      <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel>Media Type</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={page.headerBanner.items[i].mediaType}
                          onChange={(e) =>
                            changeType(e.target.value, i, 'mediaType')
                          }
                          label="Media Type"
                        >
                          <MenuItem key={`option`} value={'image'}>
                            Image
                          </MenuItem>
                          ;
                          <MenuItem key={`option`} value={'video'}>
                            Video
                          </MenuItem>
                          ;
                        </Select>
                      </FormControl>
                    </div>
                    {page.headerBanner.items[i].mediaType === 'image' ? (
                      <div className="form-row">
                        <TextField
                          label="Image URL"
                          value={page.headerBanner.items[i].imageUrl}
                          onChange={(e) =>
                            changeType(e.target.value, i, 'imageUrl')
                          }
                          variant="outlined"
                          margin="dense"
                          type="text"
                          className="day-text-field"
                        />
                      </div>
                    ) : (
                      <div className="form-row">
                        <TextField
                          label="Embed Video Code"
                          value={page.headerBanner.items[i].videoEmbedCode}
                          onChange={(e) =>
                            changeType(e.target.value, i, 'videoEmbedCode')
                          }
                          variant="outlined"
                          margin="dense"
                          type="text"
                          className="day-text-field"
                        />
                      </div>
                    )}
                  </div>
                );
              })
            : null}
        </div>

        <div className="content-container">
          <div className="form-row">
            <h2>Page Details</h2>
            <TextField
              label="Title"
              value={page.pageTitle}
              onChange={(e) => {
                setPage({
                  ...page,
                  pageTitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Description"
              value={page.pageDescription}
              onChange={(e) => {
                setPage({
                  ...page,
                  pageDescription: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>

        <div className="content-container">
          <div className="form-row">
            <h2>Product</h2>
            <TextField
              label="Title"
              value={page.productSectionTitle}
              onChange={(e) => {
                setPage({
                  ...page,
                  productSectionTitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Subtitle"
              value={page.productSectionSubtitle}
              onChange={(e) => {
                setPage({
                  ...page,
                  productSectionSubtitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
          <div className="form-row">
            <Toolbar>
              <Typography
                variant="h6"
                style={{
                  flex: 1,
                }}
              >
                Products
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddProduct(true)}
              >
                Add Product
              </Button>
            </Toolbar>
            <Divider />
            {!page.products ? (
              <Toolbar>
                <Typography>No Products have been added.</Typography>
              </Toolbar>
            ) : (
              <Table aria-label={`Products table`} style={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>SKU</strong>
                    </TableCell>
                    <TableCell>
                      <strong>ID</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Categories</strong>
                    </TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell padding="checkbox"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {page.products.map((product, i) => (
                    <TableRow key={`${product.id}-${i}`}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.sku}</TableCell>
                      <TableCell>{product.id}</TableCell>
                      <TableCell>
                        {product.categories.length ? (
                          product.categories.join(', ')
                        ) : (
                          <span style={{ color: 'orange' }}>
                            None Specified
                          </span>
                        )}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {i === 0 ? null : (
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              const newPage = cloneDeep(page);

                              const itemToMoveUp = newPage.products[i];
                              const itemToMoveDown = newPage.products[i - 1];

                              newPage.products[i] = itemToMoveDown;
                              newPage.products[i - 1] = itemToMoveUp;

                              setPage(newPage);
                            }}
                            aria-label="Move Up"
                          >
                            <ArrowUpIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {i === page.products.length - 1 ? null : (
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              const products = cloneDeep(page.products);

                              const itemToMoveDown = products[i];
                              const itemToMoveUp = products[i + 1];

                              products[i] = itemToMoveUp;
                              products[i + 1] = itemToMoveDown;

                              setPage({
                                ...page,
                                products: products,
                              });
                            }}
                            aria-label="Move Up"
                          >
                            <ArrowDownIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          edge="start"
                          style={{ marginLeft: 10 }}
                          color="inherit"
                          onClick={() => removeProduct(i)}
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>

      <div className="content-container">
        <div className="form-row">
          <h2>Training</h2>
          <TextField
            label="Title"
            value={page.trainingSectionTitle}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionTitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Subtitle"
            value={page.trainingSectionSubtitle}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionSubtitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Paragraph"
            value={page.trainingSectionParagraph}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionParagraph: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Image URL"
            value={page.trainingSectionImageUrl}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionImageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Embed Video"
            value={page.trainingSectionVideoEmbed}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionVideoEmbed: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Primary Button Text"
            value={page.trainingSectionPrimaryButtonText}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionPrimaryButtonText: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <div className="form-row">
          <TextField
            label="Primary Button Url"
            value={page.trainingSectionPrimaryButtonUrl}
            onChange={(e) => {
              setPage({
                ...page,
                trainingSectionPrimaryButtonUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <div className="form-row">
          <FormControlLabel
            control={
              <Switch
                checked={page.trainingSectionDownloadAppsLinksVisible}
                onChange={(e) => {
                  setPage({
                    ...page,
                    trainingSectionDownloadAppsLinksVisible: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Show Download Apps Links"
          />
        </div>
      </div>

      <div className="content-container">
        <div className="form-row">
          <h2>Shop</h2>
          <TextField
            label="Title"
            value={page.shopAllSectionTitle}
            onChange={(e) => {
              setPage({
                ...page,
                shopAllSectionTitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Paragraph"
            value={page.shopAllSectionParagraph}
            onChange={(e) => {
              setPage({
                ...page,
                shopAllSectionParagraph: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Image URL"
            value={page.shopAllSectionImageUrl}
            onChange={(e) => {
              setPage({
                ...page,
                shopAllSectionImageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
      </div>

      <div className="content-container">
        <div className="form-row">
          <h2>Reviews</h2>
          <TextField
            label="Title"
            value={page.reviewsSectionTitle}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsSectionTitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Subtitle"
            value={page.reviewsSectionSubtitle}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsSectionSubtitle: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <h2>Review 1</h2>
        <div className="form-row">
          <TextField
            label="Image URL"
            value={page.review1ImageUrl}
            onChange={(e) => {
              setPage({
                ...page,
                review1ImageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Text"
            value={page.review1Text}
            onChange={(e) => {
              setPage({
                ...page,
                review1Text: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Name"
            value={page.review1Name}
            onChange={(e) => {
              setPage({
                ...page,
                review1Name: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <h2>Review 2</h2>
        <div className="form-row">
          <TextField
            label="Image URL"
            value={page.review2ImageUrl}
            onChange={(e) => {
              setPage({
                ...page,
                review2ImageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Text"
            value={page.review2Text}
            onChange={(e) => {
              setPage({
                ...page,
                review2Text: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Name"
            value={page.review2Name}
            onChange={(e) => {
              setPage({
                ...page,
                review2Name: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <h2>Review 3</h2>
        <div className="form-row">
          <TextField
            label="Image URL"
            value={page.review3ImageUrl}
            onChange={(e) => {
              setPage({
                ...page,
                review3ImageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Text"
            value={page.review3Text}
            onChange={(e) => {
              setPage({
                ...page,
                review3Text: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Name"
            value={page.review3Name}
            onChange={(e) => {
              setPage({
                ...page,
                review3Name: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <h2>Review Banner</h2>
        <div className="form-row">
          <TextField
            label="Animation Duration"
            value={page.reviewsBanner.animationDuration}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsBanner: {
                  ...page.reviewsBanner,
                  animationDuration: e.target.value,
                },
              });
            }}
            variant="outlined"
            margin="dense"
            type="number"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Duplicates"
            value={page.reviewsBanner.duplicates}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsBanner: {
                  ...page.reviewsBanner,
                  duplicates: e.target.value,
                },
              });
            }}
            variant="outlined"
            margin="dense"
            type="number"
            className="day-text-field"
          />
        </div>

        <div className="form-row">
          <TextField
            label="Background Color"
            value={page.reviewsBanner.backgroundColor}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsBanner: {
                  ...page.reviewsBanner,
                  backgroundColor: e.target.value,
                },
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <div className="form-row">
          <TextField
            label="Font Color"
            value={page.reviewsBanner.fontColor}
            onChange={(e) => {
              setPage({
                ...page,
                reviewsBanner: {
                  ...page.reviewsBanner,
                  fontColor: e.target.value,
                },
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
        <div className="form-row">
          <Toolbar>
            <Typography
              variant="h6"
              style={{
                flex: 1,
              }}
            >
              Banner Text
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddBannerItem(true)}
            >
              Add Banner Text
            </Button>
          </Toolbar>
          <Divider />
          {!page.reviewsBanner.items ? (
            <Toolbar>
              <Typography>No Banner Text has been added.</Typography>
            </Toolbar>
          ) : (
            <Table aria-label={`Products table`} style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Text</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {page.reviewsBanner.items.map((text, i) => (
                  <TableRow key={`Text-${i}`}>
                    <TableCell>{text}</TableCell>
                    <TableCell padding="checkbox">
                      {i === 0 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            let newReviewBanner = cloneDeep(page.reviewsBanner);
                            const items = newReviewBanner.items;

                            const itemToMoveUp = items[i];
                            const itemToMoveDown = items[i - 1];

                            items[i] = itemToMoveDown;
                            items[i - 1] = itemToMoveUp;

                            newReviewBanner = {
                              ...newReviewBanner,
                              items: items,
                            };

                            setPage({
                              ...page,
                              reviewsBanner: newReviewBanner,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowUpIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {i === page.reviewsBanner.items.length - 1 ? null : (
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            let newReviewBanner = cloneDeep(page.reviewsBanner);
                            const items = newReviewBanner.items;

                            const itemToMoveUp = items[i];
                            const itemToMoveDown = items[i + 1];

                            items[i] = itemToMoveDown;
                            items[i + 1] = itemToMoveUp;

                            newReviewBanner = {
                              ...newReviewBanner,
                              items: items,
                            };

                            setPage({
                              ...page,
                              reviewsBanner: newReviewBanner,
                            });
                          }}
                          aria-label="Move Up"
                        >
                          <ArrowDownIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        edge="start"
                        style={{ marginLeft: 10 }}
                        color="inherit"
                        onClick={() => removeItem(i)}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>

      <Dialog
        fullScreen
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
        TransitionComponent={Transition}
      >
        <HomePagePreview
          close={() => {
            setShowPreview(false);
          }}
          data={page}
        />
      </Dialog>

      <Dialog
        open={!!modalText}
        onClose={() => {
          setModalText('');
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={addProduct}
        onClose={() => {
          setAddProduct(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel>Product</InputLabel>
            <Select
              style={{ width: '100%' }}
              value={selectedProduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
              }}
              label="Product"
            >
              {simplifiedProducts.map((product, i) => {
                return (
                  <MenuItem key={`option-${i}`} value={product}>
                    {product.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewProduct} color="secondary">
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddProduct(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={addBannerItem}
        onClose={() => {
          setAddBannerItem(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Banner Text</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <TextField
              label="Text"
              variant="outlined"
              value={newBannerItem}
              onChange={(e) => {
                setNewBannerItem(e.target.value);
              }}
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={addNewBannerItem}
            color="secondary"
          >
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddBannerItem(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HomePage;
