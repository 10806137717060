import React from 'react';
import './featured-on-section.scss';
import PeopleLogo from '../../../../../../images/featured-on-logos/people-logo.png';
import PurewowLogo from '../../../../../../images/featured-on-logos/purewow-logo.png';
import RoverLogo from '../../../../../../images/featured-on-logos/rover-logo.png';
import DigestLogo from '../../../../../../images/featured-on-logos/digest-logo.png';
import DogsterLogo from '../../../../../../images/featured-on-logos/dogster-logo.png';

export const FeaturedOnSection = () => {
  return (
    <section className="FeaturedContainer">
      <p className="title">Featured on:</p>
      <div className="logosContainer">
        <img className="logo" alt="logo" src={PeopleLogo} />
        <img className="logo" alt="logo" src={PurewowLogo} />
        <img className="logo" alt="logo" src={RoverLogo} />
        <img className="logo" alt="logo" src={DigestLogo} />
        <img className="logo" alt="logo" src={DogsterLogo} />
      </div>
    </section>
  );
};
