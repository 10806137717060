import React from 'react'
import '../editProduct.scss';
import { TYPE_COLUMNS } from './tabsContent';
import { Button, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const Column = (props) => {
    const { type, column, onChange } = props;

    if (type.includes(TYPE_COLUMNS[0])) {
        return (
            <div>
                <TextField
                    label="Image"
                    value={column.image}
                    onChange={(e) => {
                        const text = e.target.value
                        onChange({
                            image: text
                        })
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"

                />
            </div>
        )
    }

    if (type.includes(TYPE_COLUMNS[3])) {
        return (
            <div>
                <TextField
                    label="Video URL"
                    value={column.video}
                    onChange={(e) => {
                        const text = e.target.value
                        onChange({
                            video: text
                        })
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"

                />
            </div>
        )
    }

    if (type.includes(TYPE_COLUMNS[2])) {
        return (
            <div>
                <TextField
                    label="Title"
                    value={column.title}
                    onChange={(e) => {
                        const text = e.target.value
                        onChange({
                            ...column,
                            title: text
                        })
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"
                />
                <TextField
                    label="Content"
                    value={column.content}
                    onChange={(e) => {
                        const text = e.target.value
                        onChange({
                            ...column,
                            content: text
                        })
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"
                    multiline
                    rows='6'
                />
                <div className="section-container">
                    <div className="section-label">List</div>
                    <TextField
                        label="List Title"
                        value={column.listTitle}
                        onChange={(e) => {
                            const text = e.target.value
                            onChange({
                                ...column,
                                listTitle: text
                            })
                        }}
                        margin="dense"
                        variant="outlined"
                        type="text"
                        className="day-text-field"
                    />
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            onChange({
                                ...column,
                                list: [
                                    ...column.list,
                                    { item: '' }
                                ]
                            })

                        }}
                        color="primary"
                    >
                        Add Item
                    </Button>
                    {

                        column.list.map((item, index) => (
                            <div key={index} className="list-item" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <TextField
                                    label={`Item ${index + 1}`}
                                    value={item.item}
                                    onChange={(e) => {
                                        const text = e.target.value
                                        onChange({
                                            ...column,
                                            list: [
                                                ...column.list.slice(0, index),
                                                { item: text },
                                                ...column.list.slice(index + 1)
                                            ]
                                        })
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    type="text"
                                    className="day-text-field"
                                />
                                <div style={{ width: 30 }}>
                                    <IconButton onClick={() => {
                                        onChange({
                                            ...column,
                                            list: [
                                                ...column.list.slice(0, index),
                                                ...column.list.slice(index + 1)
                                            ]
                                        })

                                    }} edge="start" color="inherit" aria-label="Edit" style={{ maxWidth: 20, marginLeft: 8 }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        )
    }

    return (
        <div>
            <TextField
                label="Title"
                value={column.title}
                onChange={(e) => {
                    const text = e.target.value
                    onChange({
                        ...column,
                        title: text
                    })
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
            />
            <TextField
                label="Content"
                value={column.content}
                onChange={(e) => {
                    const text = e.target.value
                    onChange({
                        ...column,
                        content: text
                    })
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                multiline
                rows='6'
            />
        </div>
    )
}

