import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import './chewy.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Chewy({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [revenueResults, setRevenueResults] = useState([]);
  const [revenueByMonth, setRevenueByMonth] = useState([]);

  useEffect(() => {
    fetchChewyRevenue();
  }, []);

  const fetchChewyRevenue = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/chewy/revenue`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const revenueData = response.data;

      const monthMap = {};

      response.data.forEach(r => {
        if (r.revenue) {
          const dateArr = r.date.split('/');
          const month = `${dateArr[0]}/${dateArr[2]}`;
  
          if (monthMap[month]) {
            monthMap[month] += r.revenue;
          } else {
            monthMap[month] = r.revenue;
          }
        }
      });

      const monthlyRevenue = [];

      for (let key in monthMap) {
        const dateArr = key.split('/');
        const date = moment(`${dateArr[0]}/01/${dateArr[1]}`).toDate();
        monthlyRevenue.push([date, monthMap[key]]);
      }

      setRevenueResults(revenueData);
      setRevenueByMonth(monthlyRevenue);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred retrieving orders. Please try again.');
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Chewy">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Chewy
          </Typography>
        </Toolbar>

        <Divider/>
        
        <CardContent>
          <Chart
            width={'100%'}
            height={'500'}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={[
              [
                { type: 'date', label: 'Date' },
                'Revenue',
              ],
              ...revenueByMonth,
            ]}
            options={{
              legend: {
                position: 'none',
              },
              chart: {
                title:
                  'Revenue By Month',
              },
              width: '100%',
              height: 500,
              series: {
                0: { axis: 'Revenue' },
              },
              axes: {
                y: {
                  Revenue: { label: 'Revenue ($)' },
                },
              },
            }}
            rootProps={{ 'data-testid': '4' }}
            formatters={[
              {
                type: 'DateFormat',
                column: 0,
              },
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  prefix: '$',
                },
              },
           ]}
          />

          <div style={{margin: '40px 0'}}>
            <Divider/>
          </div>

          <TableContainer className="revenue-table">
            <Table aria-label="products table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Date</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revenueResults.filter(r => r.revenue).map(r => (
                  <TableRow key={r.date}>
                    <TableCell>{r.date}</TableCell>
                    <TableCell>{formatter.format(r.revenue)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Chewy;
