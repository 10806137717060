import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

import Login from './login';
import Authed from './authed';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      initialized: false,
      loginError: '',
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (result) => {
      let user = null;
      if (result) {
        user = result;
      }

      try {
        if (user) {
          const db = firebase.firestore();

          const userDoc = (
            await db.collection('users').doc(user.uid).get()
          ).data();

          if (userDoc.role !== 'Writer') {
            user = null;
            this.logOut();
          }
        }
      } catch (e) {
        this.logOut();
      }

      this.setState({ user, initialized: true });
    });
  }

  async logIn(email, password) {
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      try {
        if (user.user) {
          const db = firebase.firestore();

          const userDoc = (
            await db.collection('users').doc(user.user.uid).get()
          ).data();

          if (userDoc.role !== 'Writer') {
            this.logOut();
            this.setState({
              user: null,
              loginError:
                'You do not have the required permissions to enter here.',
            });
          } else {
            this.setState({ user });
          }
        } else {
          this.setState({
            user: null,
            loginError:
              'You do not have the required permissions to enter here.',
          });
        }
      } catch (e) {
        this.logOut();
        this.setState({
          user: null,
          loginError: 'You do not have the required permissions to enter here.',
        });
      }
    } catch (e) {
      this.setState({ user: null, loginError: e.toString() });
    }
  }

  async logOut() {
    try {
      await firebase.auth().signOut();
      this.setState({ user: null });
    } catch (e) {
      console.log('error signing out', e);
    }
  }

  renderAuthenticated() {
    if (this.state.user) {
      return <Authed logOut={this.logOut.bind(this)}></Authed>;
    } else if (this.state.initialized) {
      return (
        <Login
          logIn={this.logIn.bind(this)}
          loginError={this.state.loginError}
        ></Login>
      );
    } else {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
          }}
        >
          <CircularProgress />
        </div>
      );
    }
  }

  render() {
    return <div>{this.renderAuthenticated()}</div>;
  }
}

export default Main;
