import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  Button,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './headerBanners.scss';
import EditHeaderBanner from './editHeaderBanner/editHeaderBanner';
import EditHeaderBannerV2 from './editHeaderBanner/editHeaderBannerV2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HeaderBanners(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [banners, setBanners] = useState([]);
  const [editBannerIndex, setEditBannerIndex] = useState(-1);
  const [editBanner, setEditBanner] = useState({
    name: '',
    fontColor: '#FAF7E8',
    backgroundColor: '#294634',
    items: [],
    showOnAllPages: true,
    animationDuration: 100,
    duplicates: 3,
    pageUrlConditions: [],
    priority: 1,
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: firebase.auth().currentUser.email,
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);


  const [bannersV2, setBannersV2] = useState([]);
  const [editBannerIndexV2, setEditBannerIndexV2] = useState(-1);
  const [editBannerV2, setEditBannerV2] = useState({
    name: '',
    backgroundColor: '#294634',
    imageUrl: '',
    text: '',
    textColor: '#294634',
    buttonText: '',
    buttonTextColor: '#294634',
    buttonBackgroundColor: '#FAF7E8',
    buttonUrl: '',
    priority: 1,
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: firebase.auth().currentUser.email,
  });
  const [deleteIndexV2, setDeleteIndexV2] = useState(-1);

  useEffect(() => {
    fetchHeaderBanners();
  }, []);

  const fetchHeaderBanners = async () => {
    try {
      const bannersSnapshot = await firebase.firestore().collection('config').doc('header-banners').get();
      const bannersData = bannersSnapshot.data();

      setBanners(bannersData.banners || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the header banners. Please refresh the page and try again.');
    }

    try {
      const bannersSnapshot = await firebase.firestore().collection('config').doc('header-banners-v2').get();
      const bannersData = bannersSnapshot.data();

      setBannersV2(bannersData.banners || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the header banners. Please refresh the page and try again.');
    }
  };

  const saveBanner = async (updatedBanner) => {
    setLoading(true);

    try {
      const updatedBanners = [...banners];
      updatedBanners[editBannerIndex] = {
        ...updatedBanner,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('config').doc('header-banners').update({
        banners: updatedBanners,
      });

      setBanners(updatedBanners);
      setLoading(false);
      setEditBannerIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this banner, please try again.');
    }
  };

  const saveBannerV2 = async (updatedBanner) => {
    setLoading(true);
    console.log(updatedBanner)
    try {
      const updatedBanners = [...bannersV2];
      updatedBanners[editBannerIndexV2] = {
        ...updatedBanner,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('config').doc('header-banners-v2').update({
        banners: updatedBanners,
      });

      setBannersV2(updatedBanners);
      setLoading(false);
      setEditBannerIndexV2(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this banner, please try again.');
    }
  };

  const deleteBanner = async () => {
    setLoading(true);

    try {
      const updatedBanners = [...banners];
      updatedBanners.splice(deleteIndex, 1);

      await firebase.firestore().collection('config').doc('header-banners').update({
        banners: updatedBanners,
      });

      setBanners(updatedBanners);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this banner, please try again.');
    }
  };

  const deleteBannerV2 = async () => {
    setLoading(true);

    try {
      const updatedBanners = [...bannersV2];
      updatedBanners.splice(deleteIndexV2, 1);

      await firebase.firestore().collection('config').doc('header-banners-v2').update({
        banners: updatedBanners,
      });

      setBannersV2(updatedBanners);
      setLoading(false);
      setDeleteIndexV2(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this banner, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div className="HeaderBanners">
      {renderLoading()}
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Header Banners
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setEditBanner({
                name: 'New Banner',
                fontColor: '#FAF7E8',
                backgroundColor: '#294634',
                items: [],
                showOnAllPages: true,
                animationDuration: 100,
                duplicates: 3,
                pageUrlConditions: [],
                priority: 1,
                live: false,
                created: Date.now(),
                updated: Date.now(),
                lastUpdatedBy: firebase.auth().currentUser.email,
              });
              setEditBannerIndex(banners.length);
            }}
            aria-label="Add"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {banners.map((banner, i) => {
            return (
              <div key={`banner-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={banner.name || 'Unnamed Banner'}
                    secondary={
                      <span>
                        <strong>Status:</strong>
                        <span
                          className="banner-detail-spacing"
                          style={{ color: banner.live ? 'rgb(126, 210, 66)' : 'red' }}>
                          {banner.live ? ' Live' : ' Disabled'}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated:</strong> {moment(new Date(banner.updated)).format('MMMM Do, YYYY')}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated By:</strong> {banner.lastUpdatedBy}
                        </span>
                      </span>
                    }
                  />
                  <IconButton edge="start" style={{ marginRight: 15 }} color="inherit" onClick={() => {
                    setEditBanner(banner);
                    setEditBannerIndex(i);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>


      <Dialog fullScreen open={editBannerIndex !== -1} onClose={() => setEditBannerIndex(-1)} TransitionComponent={Transition}>
        <EditHeaderBanner
          closeModal={() => setEditBannerIndex(-1)}
          save={saveBanner}
          bannerData={editBanner}
        />
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Banner?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this banner?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteBanner} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Card style={{ marginTop: 100 }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Header Banners v2
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setEditBannerV2({
                name: '',
                backgroundColor: '#294634',
                imageUrl: '',
                text: '',
                textColor: '#294634',
                buttonText: '',
                buttonTextColor: '#294634',
                buttonBackgroundColor: '#FAF7E8',
                buttonUrl: '',
                priority: 1,
                live: false,
                created: Date.now(),
                updated: Date.now(),
                lastUpdatedBy: firebase.auth().currentUser.email,
              });
              setEditBannerIndexV2(bannersV2.length);
            }}
            aria-label="Add"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {bannersV2.map((banner, i) => {
            return (
              <div key={`banner-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={banner.name || 'Unnamed Banner'}
                    secondary={
                      <span>
                        <strong>Status:</strong>
                        <span
                          className="banner-detail-spacing"
                          style={{ color: banner.live ? 'rgb(126, 210, 66)' : 'red' }}>
                          {banner.live ? ' Live' : ' Disabled'}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated:</strong> {moment(new Date(banner.updated)).format('MMMM Do, YYYY')}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated By:</strong> {banner.lastUpdatedBy}
                        </span>
                      </span>
                    }
                  />
                  <IconButton edge="start" style={{ marginRight: 15 }} color="inherit" onClick={() => {
                    setEditBannerV2(banner);
                    setEditBannerIndexV2(i);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndexV2(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>


      <Dialog fullScreen open={editBannerIndexV2 !== -1} onClose={() => setEditBannerIndexV2(-1)} TransitionComponent={Transition}>
        <EditHeaderBannerV2
          closeModal={() => setEditBannerIndexV2(-1)}
          save={saveBannerV2}
          bannerData={editBannerV2}
        />
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndexV2 !== -1} onClose={() => setDeleteIndexV2(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Banner?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this banner?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteBannerV2} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndexV2(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HeaderBanners;
