import React from 'react';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { ShopItemsForm } from './shopItemsForm/shopItemsForm';
import { TrainingForm } from './trainingForm/traningForm';
import { BlogForm } from './blogForm/blogForm';
import { useMobileHeaderForm } from './useMobileHeaderForm';

export const MobileHeaderForm = ({ onSubmit, defaultValues }) => {
  const formMethods = useMobileHeaderForm(defaultValues);
  const { handleSubmit } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toolbar
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          <strong>Mobile Header</strong>
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          type="submit"
        >
          Save
        </Button>
      </Toolbar>
      <FormProvider {...formMethods}>
        <ShopItemsForm isMobile />
        <TrainingForm />
        <BlogForm />
      </FormProvider>
    </form>
  );
};
