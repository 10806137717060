import React from 'react';
import ProductItem from './product-item';
import './products-section.scss';

export const ProductsSection = ({ data }) => {
  const { title, content, items, backgroundColor } = data;

  return (
    <section className="ProdSectionContainer" style={{ backgroundColor }}>
      <div className="contentContainer">
        <h3 className="title">{title}</h3>
        <p className="content">{content}</p>
      </div>
      <div className="itemsContainer">
        {items.map((item) => (
          <ProductItem key={item.title} data={item} />
        ))}
      </div>
    </section>
  );
};
