import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import Settings from './settings/settings';

function Rewards(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/rewards/dashboard" component={Dashboard} />
        <Route path="/rewards/settings" component={Settings} />
        <Route component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default Rewards;
