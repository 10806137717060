import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { HeaderForm } from './headerForm';
import { MobileHeaderForm } from './mobileHeaderForm';

const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        textAlign: 'center',
      }}
    >
      <CircularProgress
        style={{ color: '#fff', top: '50%', position: 'absolute' }}
      />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  shopMenus: [
    {
      title: '',
      subtitle: '',
      titleColor: '',
      shopAllUrl: '',
      isLive: false,
      menuItems: [
        {
          name: '',
          url: '',
        },
      ],
    },
  ],
  academySection: {
    title: '',
    subtitle: '',
    viewAllUrl: '',
    courses: [
      {
        name: '',
        url: '',
      },
    ],
  },
  academyPlusSection: {
    title: '',
    subtitle: '',
    viewAllUrl: '',
    courses: [
      {
        name: '',
        url: '',
      },
    ],
  },
  blogSection: {
    recentBlogs: {
      title: '',
      subtitle: '',
      titleColor: '',
      blogUrl: '',
      items: [
        {
          name: '',
          url: '',
        },
      ],
    },
    communityLinks: {
      title: '',
      subtitle: '',
      titleColor: '',
      items: [
        {
          name: '',
          url: '',
        },
      ],
    },
  },
};

const HeaderV2 = () => {
  const [defaultValues, setDefaultValues] = useState();
  const [defaultMobileHeaderValues, setDefaultMobileHeaderValues] = useState();
  const [isHeaderDataLoading, setIsHeaderDataLoading] = useState(false);
  const [isMobileHeaderDataLoading, setIsMobileHeaderDataLoading] =
    useState(false);
  const [isHeaderDataSaving, setIsHeaderDataSaving] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const getHeaderData = async () => {
    setIsHeaderDataLoading(true);

    try {
      const headerData = await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('header-v2')
        .get();

      const defaultHeaderData = headerData.data();

      let parsedData = initialValues;

      if (defaultHeaderData?.shopMenus?.length > 0) {
        parsedData = {
          shopMenus: defaultHeaderData.shopMenus?.map((menu) => ({
            title: menu?.title ?? '',
            subtitle: menu?.subtitle ?? '',
            titleColor: menu?.titleColor ?? '',
            shopAllUrl: menu?.shopAllUrl ?? '',
            isLive: menu?.isLive,
            menuItems: menu?.menuItems ?? [
              {
                name: '',
                url: '',
              },
            ],
          })),
          academyUrl: defaultHeaderData?.academyUrl,
          academySection: {
            title: defaultHeaderData?.academySection?.title ?? '',
            subtitle: defaultHeaderData?.academySection?.subtitle ?? '',
            viewAllUrl: defaultHeaderData?.academySection?.viewAllUrl ?? '',
            courses: defaultHeaderData?.academySection?.courses ?? [
              {
                name: '',
                url: '',
              },
            ],
          },
          academyPlusSection: {
            title: defaultHeaderData?.academyPlusSection?.title ?? '',
            subtitle: defaultHeaderData?.academyPlusSection?.subtitle ?? '',
            viewAllUrl: defaultHeaderData?.academyPlusSection?.viewAllUrl ?? '',
            courses: defaultHeaderData?.academyPlusSection?.courses ?? [
              {
                name: '',
                url: '',
              },
            ],
          },
          blogSection: {
            recentBlogs: {
              title: defaultHeaderData?.blogSection?.recentBlogs?.title ?? '',
              subtitle:
                defaultHeaderData?.blogSection?.recentBlogs?.subtitle ?? '',
              titleColor:
                defaultHeaderData?.blogSection?.recentBlogs?.titleColor ?? '',
              blogUrl:
                defaultHeaderData?.blogSection?.recentBlogs?.blogUrl ?? '',
            },
            communityLinks: {
              title:
                defaultHeaderData?.blogSection?.communityLinks?.title ?? '',
              subtitle:
                defaultHeaderData?.blogSection?.communityLinks?.subtitle ?? '',
              titleColor:
                defaultHeaderData?.blogSection?.communityLinks?.titleColor ??
                '',
              items: defaultHeaderData?.blogSection?.communityLinks?.items ?? [
                {
                  name: '',
                  url: '',
                },
              ],
            },
          },
        };
      }

      setDefaultValues(parsedData);
    } catch (error) {
      window.alert(
        'An error occurred fetching header content. Refresh the page and try again.',
      );
    } finally {
      setIsHeaderDataLoading(false);
    }
  };

  const getMobileHeaderData = async () => {
    setIsMobileHeaderDataLoading(true);

    try {
      const headerData = await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('mobile-header-v2')
        .get();

      const defaultHeaderData = headerData.data();

      let parsedData = {
        ...initialValues,
        shopMenusMainButtonText: '',
        shopMenusMainButtonUrl: '',
      };

      if (defaultHeaderData?.shopMenus?.length > 0) {
        parsedData = {
          shopMenusMainButtonText:
            defaultHeaderData?.shopMenusMainButtonText ?? '',
          shopMenusMainButtonUrl:
            defaultHeaderData?.shopMenusMainButtonUrl ?? '',
          shopMenus: defaultHeaderData?.shopMenus?.map((menu) => ({
            title: menu?.title ?? '',
            titleBackground: menu?.titleBackground ?? '',
            subtitle: menu?.subtitle ?? '',
            titleColor: menu?.titleColor ?? '',
            shopAllUrl: menu?.shopAllUrl ?? '',
            isLive: menu?.isLive,
            menuItems: menu?.menuItems ?? [
              {
                name: '',
                url: '',
              },
            ],
          })),
          academyUrl: defaultHeaderData?.academyUrl,
          academySection: {
            title: defaultHeaderData?.academySection?.title ?? '',
            subtitle: defaultHeaderData?.academySection?.subtitle ?? '',
            viewAllUrl: defaultHeaderData?.academySection?.viewAllUrl ?? '',
            courses: defaultHeaderData?.academySection?.courses ?? [
              {
                name: '',
                url: '',
              },
            ],
          },
          academyPlusSection: {
            title: defaultHeaderData?.academyPlusSection?.title ?? '',
            subtitle: defaultHeaderData?.academyPlusSection?.subtitle ?? '',
            viewAllUrl: defaultHeaderData?.academyPlusSection?.viewAllUrl ?? '',
            courses: defaultHeaderData?.academyPlusSection?.courses ?? [
              {
                name: '',
                url: '',
              },
            ],
          },
          blogSection: {
            recentBlogs: {
              title: defaultHeaderData?.blogSection?.recentBlogs?.title ?? '',
              subtitle:
                defaultHeaderData?.blogSection?.recentBlogs?.subtitle ?? '',
              titleColor:
                defaultHeaderData?.blogSection?.recentBlogs?.titleColor ?? '',
              blogUrl:
                defaultHeaderData?.blogSection?.recentBlogs?.blogUrl ?? '',
            },
            communityLinks: {
              title:
                defaultHeaderData?.blogSection?.communityLinks?.title ?? '',
              subtitle:
                defaultHeaderData?.blogSection?.communityLinks?.subtitle ?? '',
              titleColor:
                defaultHeaderData?.blogSection?.communityLinks?.titleColor ??
                '',
              items: defaultHeaderData?.blogSection?.communityLinks?.items ?? [
                {
                  name: '',
                  url: '',
                },
              ],
            },
          },
        };
      }

      setDefaultMobileHeaderValues(parsedData);
    } catch (error) {
      window.alert(
        'An error occurred fetching header content. Refresh the page and try again.',
      );
    } finally {
      setIsMobileHeaderDataLoading(false);
    }
  };

  const onSubmitHeaderForm = async (formData) => {
    setIsHeaderDataSaving(true);

    const headerData = {
      shopMenus: formData.shopMenus,
      academySection: formData.academySection,
      academyPlusSection: formData.academyPlusSection,
      academyUrl: formData.academyUrl,
      blogSection: formData.blogSection,
    };

    try {
      await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('header-v2')
        .update(headerData);

      setModalTitle('Success!');
      setModalText(
        'The header shop menus have been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (error) {
      setModalTitle('Error:');
      setModalText(
        'There was an error saving the header shop menus, please try again.',
      );
    } finally {
      setIsHeaderDataSaving(false);
    }
  };

  const onSubmitMobileHeaderForm = async (formData) => {
    setIsHeaderDataSaving(true);

    const mobileHeaderData = {
      shopMenus: formData.shopMenus,
      shopMenusMainButtonText: formData.shopMenusMainButtonText,
      shopMenusMainButtonUrl: formData.shopMenusMainButtonUrl,
      academySection: formData.academySection,
      academyPlusSection: formData.academyPlusSection,
      academyUrl: formData.academyUrl,
      blogSection: formData.blogSection,
    };

    try {
      await firebase
        .firestore()
        .collection('website-layout-content')
        .doc('mobile-header-v2')
        .update(mobileHeaderData);

      setModalTitle('Success!');
      setModalText(
        'The header shop menus have been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.',
      );
    } catch (error) {
      setModalTitle('Error:');
      setModalText(
        'There was an error saving the header shop menus, please try again.',
      );
    } finally {
      setIsHeaderDataSaving(false);
    }
  };

  useEffect(() => {
    if (!defaultValues) {
      getHeaderData();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!defaultMobileHeaderValues) {
      getMobileHeaderData();
    }
  }, [defaultMobileHeaderValues]);

  if (isHeaderDataLoading || isMobileHeaderDataLoading) {
    return <Loader />;
  }

  return (
    <article>
      {isHeaderDataSaving ? <Loader /> : null}

      <Link to="/website-content/dashboard">
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginRight: '10px', marginBottom: '15px' }}
          startIcon={<ArrowBackIcon />}
        >
          Dashboard
        </Button>
      </Link>

      <Card variant="outlined">
        <HeaderForm
          onSubmit={onSubmitHeaderForm}
          defaultValues={defaultValues}
        />
      </Card>
      <br />
      <Card variant="outlined">
        <MobileHeaderForm
          onSubmit={onSubmitMobileHeaderForm}
          defaultValues={defaultMobileHeaderValues}
        />
      </Card>
      <Dialog
        open={Boolean(modalText)}
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalText('');
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </article>
  );
};

export default HeaderV2;
