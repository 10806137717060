import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import SubscriptionForecast from './dashboard/subscriptionForecast/subscriptionForecast';
import SubscriptionRetention from './dashboard/subscriptionRetention/subscriptionRetention';
import Products from './products/products';
import ProductTagPages from './productTagPages/productTagPages';
import ProductTagPage from './productTagPages/productTagPage/productTagPage';
import Memberships from './memberships/memberships';
import Shipping from './shipping/shipping';
import ShippingInsurance from './shippingInsurance/shippingInsurance';
import InsuranceClaims from './insuranceClaims/insuranceClaims';
import EditProduct from './products/editProduct/editProduct';
import ReorderProducts from './products/reorderProducts/reorderProducts';
import CartFeaturedProducts from './cartFeaturedProducts/cartFeaturedProducts';
import FreeProducts from './freeProducts/freeProducts';
import CouponCodes from './couponCodes/couponCodes';
import GiftCards from './giftCards/giftCards';
import OrderBumps from './orderBumps/orderBumps';
import OneClickUpsells from './oneClickUpsells/oneClickUpsells';
import EditOneClickUpsell from './oneClickUpsells/editOneClickUpsell/editOneClickUpsell';
import CustomThankYouPages from './customThankYouPages/customThankYouPages';
import EditPage from './customThankYouPages/editPage/editPage';
import Reviews from './reviews/reviews';
import CartCustomContent from './cartCustomContent/cartCustomContent';
import Orders from './orders/orders';
import EditOrder from './orders/editOrder/editOrder';
import CreateOrder from './orders/createOrder/createOrder';
import Subscriptions from './subscriptions/subscriptions';
import Subscription from './subscriptions/subscription/subscription';
import EditSubscriptionBox from './subscriptionBoxes/editSubscriptionBox/editSubscriptionBox';
import SubscriptionBoxes from './subscriptionBoxes/subscriptionBoxes';
import SubscriptionDownsells from './subscriptionDownsells/subscriptionDownsells';
import SubscriptionDownsellsV2 from './subscriptionDownsellsV2/subscriptionDownsellsV2';
import Settings from './settings/settings';
import ShopPage from './shopPage/shopPage';
import SubscriptionsPage from './subscriptionsPage/subscriptionsPage';
import AccountCredits from './accountCredits/accountCredits';

function Shop({}) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/shop/dashboard/subscription-forecast" component={SubscriptionForecast} />
        <Route path="/shop/dashboard/subscription-retention" component={SubscriptionRetention} />
        {/* <Route path="/shop/dashboard" component={Dashboard} /> */}
        <Route path="/shop/settings" component={Settings} />
        <Route path="/shop/products/reorder" component={ReorderProducts} />
        <Route path="/shop/products/:id" component={EditProduct} />
        <Route path="/shop/products" component={Products} />
        <Route path="/shop/product-tag-pages/:id" component={ProductTagPage} />
        <Route path="/shop/product-tag-pages" component={ProductTagPages} />
        <Route path="/shop/memberships" component={Memberships} />
        <Route path="/shop/shipping" component={Shipping} />
        <Route path="/shop/shipping-insurance" component={ShippingInsurance} />
        <Route path="/shop/insurance-claims" component={InsuranceClaims} />
        <Route path="/shop/cart-featured-products" component={CartFeaturedProducts} />
        <Route path="/shop/free-products" component={FreeProducts} />
        <Route path="/shop/coupon-codes" component={CouponCodes} />
        <Route path="/shop/gift-cards" component={GiftCards} />
        <Route path="/shop/order-bumps" component={OrderBumps} />
        <Route path="/shop/one-click-upsells/:id" component={EditOneClickUpsell} />
        <Route path="/shop/one-click-upsells" component={OneClickUpsells} />
        <Route path="/shop/custom-thank-you-pages/:id" component={EditPage} />
        <Route path="/shop/custom-thank-you-pages" component={CustomThankYouPages} />
        <Route path="/shop/product-reviews" component={Reviews} />
        <Route path="/shop/checkout-custom-content" component={CartCustomContent}/>
        <Route path="/shop/orders/create-order" component={CreateOrder} />
        <Route path="/shop/orders/:id" component={EditOrder} />
        <Route path="/shop/orders" component={Orders} />
        <Route path='/shop/subscriptions/:id' component={Subscription} />
        <Route path="/shop/subscriptions" component={Subscriptions} />
        <Route path="/shop/subscription-boxes/:id" component={EditSubscriptionBox} />
        <Route path="/shop/subscription-boxes" component={SubscriptionBoxes} />
        <Route path="/shop/subscription-downsells" component={SubscriptionDownsells} />
        <Route path="/shop/subscription-downsells-v2" component={SubscriptionDownsellsV2} />
        <Route path="/shop/account-credits" component={AccountCredits} />
        <Route path="/shop/shop-page" component={ShopPage} />
        <Route path="/shop/subscriptions-page" component={SubscriptionsPage} />
        <Route component={Settings}/>
      </Switch>
    </div>
  );
}

export default Shop;
