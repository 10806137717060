import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

function FormHeader({ fields, onChange }) {
  return (
    <div className="FormHeader">
      <TextField
        label="Heading Text"
        value={fields.text}
        onChange={(e) => {
          onChange({
            ...fields,
            text: e.target.value,
          });
        }}
        variant="outlined"
        margin="dense"
        type="text"
        className="day-text-field"
      />

      <div className="form-input-row">
        <TextField
          label="Font Size"
          value={fields.fontSize}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9.]/g, '');

            onChange({
              ...fields,
              fontSize: value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="number"
          className="day-text-field"
        />

        <FormControl
          variant="outlined"
          margin="dense"
          className="day-text-field"
        >
          <InputLabel>
            Font Weight
          </InputLabel>
          <Select
            value={fields.fontWeight}
            onChange={(e) => {
              onChange({
                ...fields,
                fontWeight: e.target.value,
              });
            }}
            label="Font Weight"
          >
            <MenuItem value={'lighter'}>Light</MenuItem>
            <MenuItem value={'normal'}>Normal</MenuItem>
            <MenuItem value={'bold'}>Bold</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default FormHeader;
