import { containerItems } from './shared';

export const row = {
  title: 'Row',
  ...containerItems,
  className: 'page-builder-large-stack',
  reverseColumnsWhenStacked: false,
  styles: {
    ...containerItems.styles,
    display: 'flex',
  },
  editableStyles: [
    ...containerItems.editableStyles,
  ],
};
