import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './dashboard.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [services, setServices] = useState([]);
  const [featuredService, setFeaturedService] = useState({
    imageUrl: '',
    service: '',
    updated: Date.now(),
    lastUpdatedBy: '',
  });

  useEffect(() => {
    initializeDashboard();
  }, []);

  const initializeDashboard = async () => {
    try {
      const featuredServiceSnapshot = await firebase.firestore().collection('config').doc('featured-service').get();
      const serviceCategoriesQuerySnapshot = await firebase.firestore().collection('service-categories').get();
      const allServices = [];

      serviceCategoriesQuerySnapshot.docs.forEach(doc => {
        const docData = doc.data();

        (docData.services || []).forEach(service => {
          allServices.push({
            id: `${doc.id}-${service.created}`,
            title: service.title,
          });
        });
      });

      setFeaturedService(featuredServiceSnapshot.data());
      setServices(allServices);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred initializing the services dashboard. Refresh the page and try again.');
    }
  };

  const saveFeaturedService = async () => {
    setLoading(true);

    try {
      const updatedFeaturedService = {
        ...featuredService,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('config').doc('featured-service').update(updatedFeaturedService);

      setFeaturedService(updatedFeaturedService);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The featured service was saved successfully. To make these changes live on the website, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the featured service. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ServicesDashboard">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Services Dashboard
        </Typography>
      </Toolbar>

      <div className="content-container">
        <div className="section-container">
          <div className="section-label">Featured Service</div>

          <div className="section-buttons">
            <Button variant="contained" onClick={saveFeaturedService} color="secondary" size="small">
              Save
            </Button>
          </div>

          <TextField
            label="Image URL"
            value={featuredService.imageUrl}
            onChange={(e) => {
              setFeaturedService({
                ...featuredService,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
          />

          <FormControl variant="outlined" margin="dense" className="day-text-field" style={{marginTop: 20}}>
            <InputLabel>
              Service
            </InputLabel>
            <Select
              className="day-text-field"
              margin="dense"
              label="Service"
              variant="outlined"
              value={featuredService.service}
              onChange={(e) => {
                setFeaturedService({
                  ...featuredService,
                  service: e.target.value,
                });
              }}
            >
              <MenuItem value="">
                <em>Select Service</em>
              </MenuItem>
              {services.map(service => {
                return <MenuItem key={service.id} value={service.id}>{service.title}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField
            label="Services Description"
            value={featuredService.servicesDescription || ''}
            onChange={(e) => {
              setFeaturedService({
                ...featuredService,
                servicesDescription: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            style={{ marginTop: 20 }}
            multiline
            minRows="4"
          />
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
