import React, { useState, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';

import { humanFileSize } from '../../../util';

import './editFile.scss';

function EditFile(props) {
  const imageEl = useRef(null);
  const [image, setImage] = useState(props.image);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemoveFromProduct, setIsRemoveFromProduct] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'delete-confirmation' : undefined;

  return (
    <div className="EditFile">
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{padding: '10px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '10px'}}>{!isRemoveFromProduct ? 'Delete File?' : 'Remove File?'}</div>
          <Button
            variant="contained"
            color="secondary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => {
              setAnchorEl(null);
              if (isRemoveFromProduct) {
                props.removeFromProduct();
              } else {
                props.deleteImage(isRemoveFromProduct);
              }
              setIsRemoveFromProduct(false);
            }}
          >
            Delete
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginBottom: '5px'}}
            onClick={() => {
              setIsRemoveFromProduct(false);
              setAnchorEl(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Popover>
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.closeModal() }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {image.name || 'Unnamed'}
          </Typography>
          <Button color="inherit" onClick={() => { props.saveImage(image) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div className="edit-image-body">
        <div className="image-container">
          {image.contentType.includes('image') ? <img ref={imageEl} src={image.url} /> : null}
          {image.contentType.includes('video') ? <video src={image.url} width="100%" controls poster={image.placeholder} /> : null}
        </div>
        <div className="image-info-container">
          <p><strong>Storage Location:</strong> {image.storagePath}</p>
          <p><strong>File Type:</strong> {image.contentType}</p>
          <p><strong>Created on:</strong> {moment(image.timeCreated).format('MMMM Do YYYY, h:mm a')}</p>
          {/* <p><strong>File Size:</strong> {humanFileSize(image.size, true)}</p> */}
          {image.contentType.includes('image') ? <p><strong>Dimensions:</strong> {imageEl.current && imageEl.current.naturalWidth && `${imageEl.current.naturalWidth} x `}{imageEl.current && imageEl.current.naturalHeight && `${imageEl.current.naturalHeight} pixels`}</p> : null}

          <div className="divider-spacing">
            <Divider/>
          </div>

          {!image.contentType.includes('image') ? null : <TextField
            label="Alt Text"
            value={image.alt}
            onChange={(e) => {
              setImage({
                ...image,
                alt: e.target.value,
              });
            }}
            margin="normal"
            size="small"
            variant="outlined"
            className="day-text-field"
          />}

          {!image.contentType.includes('video') ? null : <TextField
            label="Placeholder Image"
            value={image.placeholder}
            onChange={(e) => {
              setImage({
                ...image,
                placeholder: e.target.value,
              });
            }}
            margin="normal"
            size="small"
            variant="outlined"
            className="day-text-field"
          />}

          <TextField
            label="Title"
            value={image.name}
            onChange={(e) => {
              setImage({
                ...image,
                name: e.target.value,
              });
            }}
            margin="normal"
            size="small"
            variant="outlined"
            className="day-text-field"
          />

          <TextField
            label="Link"
            value={image.url}
            disabled
            margin="normal"
            size="small"
            variant="outlined"
            className="day-text-field"
          />

          <div className="delete-button-container">
            <Button
              variant="contained"
              color="secondary"
              style={{marginRight: 10}}
              onClick={(e) => { setAnchorEl(e.currentTarget); }}
            >
              Delete
            </Button>

            {!props.removeFromProduct ? null : <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIsRemoveFromProduct(true);
              }}
            >
              Remove from Product
            </Button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFile;
