import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import CourseEdit from './courseEdit';
import { config } from '../config';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docId: '',
      days: [],
      open: false,
      currentEditIndex: -1,
      isBehavior: false,
      isNewPup: false,
      currentDeleteIndex: -1,
      showDelete: false,
      products: [],
      ads: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const db = firebase.firestore();
    this.getProducts(db);
    this.getAds(db);

    if (this.props.isBehavior) {
      this.setState({
        isBehavior: true,
      }, () => {
        this.getData(db);
      });
    } else if (this.props.isNewPup) {
      this.setState({
        isNewPup: true,
      }, () => {
        this.getData(db);
      });
    } else {
      this.getData(db);
    }
  }

  async getProducts(db) {
    try {
      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const products = shopSettingsSnapshot.data().simplifiedProducts.filter(p => {
        return !p.isDigital;
      }).map(p => {
        return {
          displayName: p.name || '',
          name: p.name,
          url: `https://${config.domain}.com/product/${p.path}/`,
          value: p.price || '',
          image: p.image ? p.image.url : '',
          id: p.id,
        };
      }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      this.setState({ products });
    } catch (e) {
      console.log(e.toString());
    }
  }

  async getAds(db) {
    try {
      const querySnapshot = await db.collection('course-completion-ads').doc('ads').get();
      const ads = querySnapshot.data().data.map(ad => {
        return {
          id: ad.id,
          internalName: ad.internalName,
        };
      });

      this.setState({ ads });
    } catch (e) {
      console.log(e.toString());
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const days = reorder(
      this.state.days,
      result.source.index,
      result.destination.index
    );

    this.saveDays(days);
  }

  saveDays(days) {
    this.setState({
      days
    });

    const dataToSave = this.state.isBehavior ? 'behaviors' :
      this.state.isNewPup ? 'newPup' : 'days';
    const db = firebase.firestore();
    db.collection('days').doc(this.state.docId).update({
      [dataToSave]: days,
    });
  }

  async getData(db) {
    const dataToRetrieve = this.state.isBehavior ? 'behaviors' :
      this.state.isNewPup ? 'newPup' : 'days';

    try {
      const querySnapshot = await db.collection('days').get();

      const doc = querySnapshot.docs[0];
      const days = doc.data()[dataToRetrieve];

      this.setState({ docId: doc.id, days });
    } catch (e) {
      console.log(e.toString());
    }
  }

  saveDay(day) {
    const days = [...this.state.days];
    days[this.state.currentEditIndex] = day;
    this.saveDays(days);
    this.closeModal();
  }

  closeModal() {
    this.setState({ open: false });
  }

  handleOpen(index) {
    this.setState({ open: true, currentEditIndex: index });
  }

  handleAddDay() {
    const dayNumber = this.state.days.length + 1;
    const title = this.state.isBehavior ? 'Behavior' :
      this.state.isNewPup ? 'New Pup' : 'Day';

    const days = [
      ...this.state.days,
      {
        id: uuidv4(),
        title: `${title} ${dayNumber}`,
        subtitle: `Subtitle for ${title.toLowerCase()} ${dayNumber}`,
        videoId: '',
        videoThumbnail: '',
        videoUrl: '',
        products: [],
        steps: [],
        paragraphs: [],
        hasPDF: false,
        pdfTitle: '',
        pdfURL: '',
        adId: '',
      },
    ];

    this.saveDays(days);
    setImmediate(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  renderAddButton() {
    if (this.state.isBehavior || this.state.isNewPup) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          onClick={this.handleAddDay.bind(this)}
          aria-label="Add Item"
          style={{
            position: 'absolute',
            right: 20
          }}
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  renderDeleteButton(currentDeleteIndex) {
    if (this.state.isBehavior || this.state.isNewPup) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { this.setState({ currentDeleteIndex, showDelete: true }) }} aria-label="Edit">
          <DeleteIcon />
        </IconButton>
      );
    }
  }

  handleDelete() {
    const days = this.state.days.slice();
    days.splice(this.state.currentDeleteIndex, 1);
    this.saveDays(days);
    this.setState({ currentDeleteIndex: -1, showDelete: false });
  }

  render() {
    return (
      <div>
        <div style={{textAlign: 'center', marginBottom: 50}}>
          <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
            <Toolbar>
              <Typography variant="h6">
                {this.state.isBehavior ? 'Behaviors' : this.state.isNewPup ? 'New Pup' : 'Days'}
              </Typography>
              <Typography style={{
                marginLeft: 10,
              }}>
                (Drag and drop to reorder)
              </Typography>
              {this.renderAddButton()}
            </Toolbar>
            <List>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.days.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Divider />
                              <ListItem>
                                <ListItemText primary={item.title} secondary={item.subtitle} />
                                <IconButton edge="start" color="inherit" onClick={this.handleOpen.bind(this, index)} aria-label="Edit">
                                  <EditIcon />
                                </IconButton>
                                {this.renderDeleteButton(index)}
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </List>
          </Card>
        </div>

        <Dialog fullScreen open={this.state.open} onClose={() => { this.setState({open: false}) }} TransitionComponent={Transition}>
          <CourseEdit
            closeModal={this.closeModal.bind(this)}
            saveDay={this.saveDay.bind(this)}
            day={{...this.state.days[this.state.currentEditIndex]}}
            products={this.state.products}
            ads={this.state.ads}
          />
        </Dialog>

        <Dialog
          open={this.state.showDelete}
          onClose={() => { this.setState({showDelete: false, currentDeleteIndex: -1}) }}
          TransitionComponent={Transition} disableBackdropClick={true}>
          <DialogTitle id="alert-dialog-title">{`Delete ${this.state.isBehavior ? 'Behavior' : 'Section'}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {this.state.days[this.state.currentDeleteIndex] && this.state.days[this.state.currentDeleteIndex].title}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({showDelete: false, currentDeleteIndex: -1}) }} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDelete.bind(this)} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Course;
