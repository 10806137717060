import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Slide,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import './wholesalers.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Wholesalers(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [wholesalers, setWholesalers] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchItem, setSearchItem] = useState('id');
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedResults, setSearchedResults] = useState([]);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [newWholesalerEmail, setNewWholesalerEmail] = useState('');
  const [addWholesalerOpen, setAddWholesalerOpen] = useState(false);

  useEffect(() => {
    fetchWholesalers();
  }, []);

  const fetchWholesalers = async () => {
    const db = firebase.firestore();

    try {
      const wholesalersCountSnapshot = await db.collection('counters').doc('wholesale-users').get();
      const wholesalersCount = wholesalersCountSnapshot.data();

      setNumberOfPages(Math.ceil(wholesalersCount.count / 10));

      const usersSnapshot = await db.collection('users').where('roles', 'array-contains', 'wholesaler').get();
      const mappedUsers = usersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setWholesalers(mappedUsers);
      setFirst(usersSnapshot.docs[0]);
      setLast(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching wholesalers. Please try again.');
    }
  };

  // const handlePageChange = async (value) => {
  //   setLoading(true);
  //   const db = firebase.firestore();

  //   try {
  //     let usersSnapshot;
  //     let newPage = page;

  //     if (value === 'next') {
  //       usersSnapshot = await db.collection('users').where('roles', 'array-contains', 'wholesaler').startAfter(last).limit(pageCount).get();
  //       newPage += 1;
  //     } else {
  //       usersSnapshot = await db.collection('users').where('roles', 'array-contains', 'wholesaler').endBefore(first).limitToLast(pageCount).get();
  //       newPage -= 1;
  //     }

  //     const mappedUsers = usersSnapshot.docs.map(doc => {
  //       return {
  //         ...doc.data(),
  //         id: doc.id,
  //       };
  //     });

  //     setPage(newPage);
  //     setWholesalers(mappedUsers);
  //     setFirst(usersSnapshot.docs[0]);
  //     setLast(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
  //     setLoading(false);
  //   } catch (e) {
  //     console.log('error', e);
  //     setLoading(false);
  //     setModalTitle('Error:');
  //     setModalText('There was an error retrieving wholesalers. Please try again.');
  //   }
  // };

  const search = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      let foundUsers = [];
      let searchedItemText = '';

      if (searchItem === 'id') {
        const usersSnapshot = await db.collection('users').doc(searchText).get();
        const data = usersSnapshot.data();

        if (data && data.roles && data.roles.includes('wholesaler')) {
          foundUsers.push({
            ...data,
            id: usersSnapshot.id,
          });
        }

        searchedItemText = 'User ID';
      } else if (searchItem === 'email') {
        const usersSnapshot = await db.collection('users').where('email', '==', searchText).get();

        foundUsers = usersSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }).filter(user => {
          return user.roles && user.roles.includes('wholesaler');
        });

        searchedItemText = 'Email';
      }

      setSearchedResults(foundUsers);
      setSearchTerm(searchText);
      setSearchedItem(searchedItemText);
      setLoading(false);
      setSearchOpen(false);
    } catch (e) {
      console.log('search error', e);
      setLoading(false);
      setSearchOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error searching wholesalers. Please try again.');
    }
  };

  const addNewWholesaler = async () => {
    setLoading(true);

    const newWholesalerEmailToAdd = newWholesalerEmail;
  
    try {
      const snapshot = await firebase.firestore().collection('users').where('email', '==', newWholesalerEmailToAdd).get();

      if (!snapshot.docs.length) {
        setLoading(false);
        setAddWholesalerOpen(false);
        setModalTitle('Error:');
        setModalText('There specified user was not found.');
        return;
      }

      const userData = {
        ...snapshot.docs[0].data(),
        id: snapshot.docs[0].id,
      };

      if (userData.roles && userData.roles.includes('wholesaler')) {
        setLoading(false);
        setAddWholesalerOpen(false);
        setModalTitle('Error:');
        setModalText('There specified user is already a wholesaler.');
        return;
      }

      const token = await firebase.auth().currentUser.getIdToken();

      await axios(`${config.gateway}/user-service/api/v1/admin/roles/${userData.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: [ 'wholesaler' ],
      });

      await firebase.firestore().collection('users').doc(userData.id).update({
        roles: [ 'wholesaler' ],
      });

      setLoading(false);
      setNewWholesalerEmail('');
      setAddWholesalerOpen(false);
      setPage(1);
      fetchWholesalers();
      setModalTitle('Success!');
      setModalText(`${newWholesalerEmailToAdd} has been given wholesaler access.`);
    } catch (e) {
      setLoading(false);
      setAddWholesalerOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the wholesaler. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Wholesalers">
      {renderLoading()}
      <div style={{marginBottom: 10}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setNewWholesalerEmail('');
            setAddWholesalerOpen(true);
          }}
        >
          Add Wholesaler
        </Button>
      </div>

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Wholesalers
          </Typography>
          {!searchTerm ?
            <IconButton edge="start" color="inherit" onClick={() => {
              setSearchItem('id');
              setSearchText('');
              setSearchOpen(true);
            }} aria-label="Delete">
              <SearchIcon />
            </IconButton> :
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchedItem}: {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                setSearchedItem('');
                setSearchedResults([]);
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm ?
          <div>
            {!searchedResults.length ?
              <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
                No results match your search
              </div> :
              <TableContainer component={Paper}>
                <Table aria-label="search users table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Email</strong></TableCell>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>User ID</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResults.map((u, i) => (
                      <TableRow key={u.id}>
                        <TableCell>{u.email}</TableCell>
                        <TableCell>{u.userName}</TableCell>
                        <TableCell>{u.id}</TableCell>
                        <TableCell padding="checkbox">
                          <Link to={`/wholesale/wholesalers/${u.id}`}>
                            <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div> :
          <TableContainer component={Paper}>
            <Table aria-label="users table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>User ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wholesalers.map((u, i) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>{u.userName}</TableCell>
                    <TableCell>{u.id}</TableCell>
                    <TableCell padding="checkbox">
                      <Link to={`/wholesale/wholesalers/${u.id}`}>
                        <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Divider/>

        {/* {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        } */}
      </Card>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Wholesalers</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <FormControl variant="outlined" margin="dense" className="day-text-field">
              <InputLabel>Search Field</InputLabel>
              <Select
                value={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value)
                }}
                label="Search Field"
              >
                <MenuItem value={'id'}>User ID</MenuItem>
                <MenuItem value={'email'}>Email</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addWholesalerOpen} onClose={() => { setAddWholesalerOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Wholesaler</DialogTitle>
        <DialogContent>
          <Typography>Enter the email address associated with the account you would like to give wholesaler access to. If the user does not already have an account, you can create one for them in the "Users" tab.</Typography>

          <TextField
            label="User Email"
            value={newWholesalerEmail}
            onChange={(e) => {
              setNewWholesalerEmail(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginBottom: 15, marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewWholesaler} disabled={!emailRegex.test(newWholesalerEmail)} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddWholesalerOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Wholesalers;
