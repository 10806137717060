import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

const buttonOptions = z.object({
    borderColor: z.string().default('transparent'),
    backgroundColor: z.string().default('transparent'),
    textColor: z.string().default('transparent'),
})

const productPageOptions = z.object({
    buttonAddCart: buttonOptions,
    buttonBuyNow: buttonOptions,
})

const buttonFormResolver = zodResolver(productPageOptions, undefined, {
    raw: true,
});

export const useProductOptionsForm = (defaultValues) => {
    const form = useForm({
        mode: 'onSubmit',
        resolver: buttonFormResolver,
        defaultValues: defaultValues,
    });

    return form
}