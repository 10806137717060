import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrdoroOrderFailures from './ordoroOrderFailures/ordoroOrderFailures';

function CustomerService({}) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/customer-service/ordoro-order-failures" component={OrdoroOrderFailures} />
        <Route component={OrdoroOrderFailures}/>
      </Switch>
    </div>
  );
}

export default CustomerService;
