import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertFromRaw, convertToRaw, EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  CircularProgress,
  Button,
  Toolbar,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import DeletableChip from '../../../shared/deletableChip';
import './editBlogPost.scss';

import { toolbarOptions } from './toolbarOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditBlogPost(props) {
  const headerRef = useRef(null);
  const stickyRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState({
    title: '',
    description: '',
    path: '',
    editorData: '',
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
    tags: [],
    categories: [],
    featuredImage: '',
    sidebar: '',
  });
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [sidebars, setSidebars] = useState([]);
  const [tags, setTags] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [categories, setCategories] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [rawHtml, setRawHtml] = useState('');

  useEffect(() => {
    fetchPost();
    const toolbarElements = document.getElementsByClassName('editor-wrapper');

    if (toolbarElements.length) {
      headerRef.current = toolbarElements[0];
      stickyRef.current = toolbarElements[0].offsetTop + 25;
      window.onscroll = function() {watchToolbar()};
    }
  }, []);

  const watchToolbar = () => {
    if (headerRef.current.classList.contains('sticky')) {
      headerRef.current.children[0].style.width = `${+(headerRef.current.children[1].clientWidth) - 12}px`;
    }

    if (window.pageYOffset > stickyRef.current) {
      headerRef.current.classList.add('sticky');
    } else {
      headerRef.current.classList.remove('sticky');
    }
  };

  const fetchPost = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/blog-content/blog-posts');
        return;
      }

      const querySnapshot = await db.collection('blog-posts').doc(id).get();
      const sidebarsSnapshot = await db.collection('sidebars').get();
      const contentSnapshot = await db.collection('config').doc('content').get();
      const data = querySnapshot.data();
      const blogPost = {
        ...data,
        id: querySnapshot.id,
      };

      if (data.editorData !== '') {
        const contentState = convertFromRaw(JSON.parse(data.editorData));
        blogPost.editorData = EditorState.createWithContent(contentState);
      }

      const sidebarsData = sidebarsSnapshot.docs.map(doc => {
        return {
          id: doc.id,
          description: doc.data().description,
        };
      });

      setIsLive(blogPost.live);
      setPost(blogPost);
      setTags(contentSnapshot.data().tags);
      setCategories(contentSnapshot.data().blogCategories);
      setSidebars(sidebarsData);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching post content. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const savePost = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingPageQuerySnapshot = await db.collection('pages').where('path', '==', post.path).get();
      const existingBlogQuerySnapshot = await db.collection('blog-posts').where('path', '==', post.path).get();
      const existingAcademyPagesQuerySnapshot = await db.collection('academy-plus-landing-pages').where('path', '==', post.path).get();

      if (existingPageQuerySnapshot.docs.length || existingAcademyPagesQuerySnapshot.docs.length || (existingBlogQuerySnapshot.docs.length && existingBlogQuerySnapshot.docs[0].id !== post.id)) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      await db.collection('blog-posts').doc(post.id).update({
        ...post,
        editorData: JSON.stringify(convertToRaw(post.editorData.getCurrentContent())),
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      if (isLive !== post.live) {
        if (isLive) {
          await db.collection('blog-posts').doc('data').update({
            liveCount: firebase.firestore.FieldValue.increment(-1),
          });
        } else {
          await db.collection('blog-posts').doc('data').update({
            liveCount: firebase.firestore.FieldValue.increment(1),
          });
        }

        setIsLive(!isLive);
      }

      setLoading(false);
      setModalTitle('Success!');
      setModalText('The post was saved successfully!');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding saving the post. Please try again.');
    }
  };

  const addNewTag = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddTag(false);

    try {
      const postTags = [ ...tags ];
      postTags.push(newTagName);

      await db.collection('config').doc('content').update({
        tags: postTags,
      });

      setTags(postTags);
      setPost({
        ...post,
        tags: [ ...post.tags, newTagName ],
      });
    } catch (e) {
      console.log('e', e);
      setModalTitle('Error:');
      setModalText('An error occurred adding new tag. Please try again.');
    }

    setLoading(false);
  };

  const addNewCategory = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddCategory(false);

    try {
      const postCategories = [ ...categories ];
      postCategories.push(newCategoryName);

      await db.collection('config').doc('content').update({
        blogCategories: postCategories,
      });

      setCategories(postCategories);
      setPost({
        ...post,
        categories: [ ...post.categories, newCategoryName ],
      });
    } catch (e) {
      console.log('e', e);
      setModalTitle('Error:');
      setModalText('An error occurred adding new category. Please try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditBlogPost">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/blog-content/blog-posts">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Posts
          </Button>
        </Link>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>{post.title || 'Unnamed Post'}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={savePost}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <FormControlLabel
            control={
              <Switch
                checked={post.live}
                onChange={(e) => {
                  setPost({
                    ...post,
                    live: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Live"
          />

          <div className="form-row">
            <TextField
              label="Title"
              value={post.title}
              onChange={(e) => {
                setPost({
                  ...post,
                  title: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Post Description"
              value={post.description}
              onChange={(e) => {
                setPost({
                  ...post,
                  description: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Page Path"
              helperText="Only letters, numbers and dashes allowed"
              value={post.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setPost({
                  ...post,
                  path: value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Featured Image URL"
              value={post.featuredImage}
              onChange={(e) => {
                setPost({
                  ...post,
                  featuredImage: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel>Sidebar</InputLabel>
              <Select
                value={post.sidebar}
                onChange={(e) => {
                  setPost({
                    ...post,
                    sidebar: e.target.value,
                  });
                }}
                label="Sidebar"
              >
                {sidebars.map((sidebar, i) => {
                  return <MenuItem key={`sidebar-${i}`} value={sidebar.id}>{sidebar.description}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>

          <div className="form-row">
            <div className="categories-container">
              <FormControl style={{ marginTop: -22, width: '99%'}}>
                <InputLabel htmlFor="select-multiple-chip">Tags</InputLabel>
                <Select
                  multiple
                  value={post.tags}
                  onChange={(e) => {
                    setPost({
                      ...post,
                      tags: e.target.value,
                    });
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value}
                          label={value}
                          style={{margin: 2}}
                          color="primary"
                          onDelete={() => {
                            const index = post.tags.indexOf(value);

                            if (index === -1) {
                              return;
                            }

                            const tagsCopy = post.tags.slice();
                            tagsCopy.splice(index, 1);
                            setPost({
                              ...post,
                              tags: tagsCopy,
                            });
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {tags.filter(tag => {
                    return !post.tags.includes(tag);
                  }).map(tag => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="add-category-button" onClick={() => {
                setShowAddTag(true);
                setNewTagName('');
              }}><small>+ Add new tag</small></div>
            </div>
          </div>

          <div className="form-row">
            <div className="categories-container">
              <FormControl style={{ marginTop: -22, width: '99%'}}>
                <InputLabel htmlFor="select-multiple-chip">Categories</InputLabel>
                <Select
                  multiple
                  value={post.categories}
                  onChange={(e) => {
                    setPost({
                      ...post,
                      categories: e.target.value,
                    });
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value}
                          label={value}
                          style={{margin: 2}}
                          color="primary"
                          onDelete={() => {
                            const index = post.categories.indexOf(value);

                            if (index === -1) {
                              return;
                            }

                            const categoriesCopy = post.categories.slice();
                            categoriesCopy.splice(index, 1);
                            setPost({
                              ...post,
                              categories: categoriesCopy,
                            });
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {categories.filter(tag => {
                    return !post.categories.includes(tag);
                  }).map(category => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="add-category-button" onClick={() => {
                setShowAddCategory(true);
                setNewCategoryName('');
              }}><small>+ Add new category</small></div>
            </div>
          </div>

          {/* {!rawHtml ?
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (post.editorData) {
                  setRawHtml(draftToHtml(convertToRaw(post.editorData.getCurrentContent())));
                } else {
                  setRawHtml('');
                }
              }}
            >
              View Source
            </Button> :
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                const contentBlock = htmlToDraft(rawHtml);
                if (contentBlock) {
                  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                  const editorData = EditorState.createWithContent(contentState);
                  setPost({
                    ...post,
                    editorData,
                  });
                }
                setRawHtml('');
              }}
            >
              View Editor
            </Button>
          } */}

          <div className="form-row">
            {!rawHtml ?
              <Editor
                toolbar={toolbarOptions}
                editorState={post.editorData}
                toolbarClassName="toolbar-container"
                wrapperClassName="editor-wrapper"
                editorClassName="editor-container"
                onEditorStateChange={(data) => {
                  setPost({
                    ...post,
                    editorData: data,
                  });
                }}
              /> :
              <TextField
                label="Blog HTML Content"
                value={rawHtml}
                onChange={(e) => setRawHtml(e.target.value)}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
                multiline
                rows="25"
              />
            }
          </div>
        </div>
      </div>

      <Dialog open={showAddTag} onClose={() => { setShowAddTag(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          <TextField
            label="Tag Name"
            value={newTagName}
            onChange={(e) => { setNewTagName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewTag} color="secondary" disabled={!newTagName || tags.includes(newTagName)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddTag(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddCategory} onClose={() => { setShowAddCategory(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => { setNewCategoryName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewCategory} color="secondary" disabled={!newCategoryName || categories.includes(newCategoryName)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddCategory(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditBlogPost;
