export const tabs = {
  title: 'Tabs',
  visibilityClassName: 'page-builder-always-visible',
  customStyles: '',
  customClasses: '',
  htmlId: '',
  items: [
    {
      title: 'Tab 1',
      content: '',
    },
  ],
  contentContainerStyles: {
    borderColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '',
    marginTop: '',
    marginBottom: '5px',
    marginLeft: '',
    marginRight: '',
    fontFamily: 'BuenosAires',
  },
  editableContentContainerStyles: [
    {
      type: 'borderColor',
      label: 'Border Color',
      placeholder: 'Enter border color',
    },
    {
      type: 'borderWidth',
      label: 'Border Width',
      placeholder: 'Enter border width',
    },
    {
      type: 'borderStyle',
      label: 'Border Style',
      placeholder: 'Enter border style',
      inputType: 'select',
      options: [ 'none', 'solid', 'dotted', 'dashed' ],
    },
    {
      type: 'borderRadius',
      label: 'Border Radius',
      placeholder: 'Enter border radius',
    },
    {
      type: 'marginTop',
      label: 'Margin Top',
      placeholder: 'Enter margin top',
    },
    {
      type: 'marginBottom',
      label: 'Margin Bottom',
      placeholder: 'Enter margin bottom',
    },
    {
      type: 'marginLeft',
      label: 'Margin Left',
      placeholder: 'Enter margin left',
    },
    {
      type: 'marginRight',
      label: 'Margin Right',
      placeholder: 'Enter margin right',
    },
    {
      type: 'fontFamily',
      label: 'Font',
      inputType: 'select',
      options: [
        'BuenosAires',
        'Visby',
        'Arial',
        'Verdana',
        'Trebuchet MS',
        'Times New Roman',
        'Didot',
        'American Typewriter',
        'Andale Mono',
        'Courier',
        'Bradley Hand',
        'Luminari',
      ],
    },
  ],
  tabStyles: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#f7f7f7',
    color: '#989699',
    activeBackgroundColor: '#fff',
    activeColor: '#000',
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
  },
  editableTabStyles: [
    {
      type: 'paddingTop',
      label: 'Padding Top',
      placeholder: 'Enter padding top',
    },
    {
      type: 'paddingBottom',
      label: 'Padding Bottom',
      placeholder: 'Enter padding bottom',
    },
    {
      type: 'paddingLeft',
      label: 'Padding Left',
      placeholder: 'Enter padding left',
    },
    {
      type: 'paddingRight',
      label: 'Padding Right',
      placeholder: 'Enter padding right',
    },
    {
      type: 'backgroundColor',
      label: 'Background Color',
      placeholder: 'Enter background color',
    },
    {
      type: 'color',
      label: 'Label Color',
      placeholder: 'Enter label color',
    },
    {
      type: 'lineHeight',
      label: 'Label Line Height',
      placeholder: 'Enter label line height',
    },
    {
      type: 'activeColor',
      label: 'Active Tab Color',
      placeholder: 'Enter active tab color',
    },
    {
      type: 'activeBackgroundColor',
      label: 'Active Tab Background Color',
      placeholder: 'Enter active tab background color',
    },
    {
      type: 'fontSize',
      label: 'Label Font Size',
      placeholder: 'Enter label font size',
    },
    {
      type: 'fontWeight',
      label: 'Label Font Weight',
      placeholder: 'Enter label font weight',
    },
  ],
  contentStyles: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '',
    marginBottom: '',
    marginLeft: '',
    marginRight: '',
    backgroundColor: '#fff',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  editableContentStyles: [
    {
      type: 'marginTop',
      label: 'Margin Top',
      placeholder: 'Enter margin top',
    },
    {
      type: 'marginBottom',
      label: 'Margin Bottom',
      placeholder: 'Enter margin bottom',
    },
    {
      type: 'marginLeft',
      label: 'Margin Left',
      placeholder: 'Enter margin left',
    },
    {
      type: 'marginRight',
      label: 'Margin Right',
      placeholder: 'Enter margin right',
    },
    {
      type: 'paddingTop',
      label: 'Padding Top',
      placeholder: 'Enter padding top',
    },
    {
      type: 'paddingBottom',
      label: 'Padding Bottom',
      placeholder: 'Enter padding bottom',
    },
    {
      type: 'paddingLeft',
      label: 'Padding Left',
      placeholder: 'Enter padding left',
    },
    {
      type: 'paddingRight',
      label: 'Padding Right',
      placeholder: 'Enter padding right',
    },
    {
      type: 'backgroundColor',
      label: 'Background Color',
      placeholder: 'Enter background color',
    },
  ],
};
