import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TrainingTools from './trainingTools/trainingTools';
import Orders from './orders/orders';
import FreeCourses from './freeCourses/freeCourses';
import EditFreeCourse from './freeCourses/editFreeCourse/editFreeCourse';
import CompletionPopupsAnalytics from './completionPopups/completionPopupsAnalytics/completionPopupsAnalytics';
import CompletionPopups from './completionPopups/completionPopups';
import MembershipProducts from './membershipProducts/membershipProducts';
import EditMembershipProduct from './membershipProducts/editMembershipProduct';
import MembershipProductPurchaseDetails from './membershipProducts/membershipProductPurchaseDetails';
import MembershipProductDetails from './membershipProducts/membershipProductDetails';
import Module from './membershipProducts/courseContent/module';
import AcademyPlusCourseLandingPages from './academyPlusCourseLandingPages/academyPlusCourseLandingPages';
import EditAcademyPlusCourseLandingPage from './academyPlusCourseLandingPages/editAcademyPlusCourseLandingPage/editAcademyPlusCourseLandingPage';
import UnpublishedContentWhitelist from './unpublishedContentWhitelist/unpublishedContentWhitelist';
import AcademyBonus from './academyBonus/academyBonus';
import AcademyBonusPost from './academyBonus/academyBonusPost';
import FeaturedContent from './featuredContent/featuredContent';
import FeaturedContentEdit from './featuredContent/featuredContentEdit/featuredContentEdit';
import VisualSearch from './visualSearch/visualSearch';
import EditBehavior from './visualSearch/editBehaviors/editBehavior';
import WeeklyChallenges from './weeklyChallenges/weeklyChallenges';
import WeeklyChallengePosts from './weeklyChallenges/weeklyChallengePosts/weeklyChallengePosts';
import EditWeeklyChallenge from './weeklyChallenges/editWeeklyChallenge/editWeeklyChallenge';
import EditFreeCourseV2 from './freeCoursesV2/editFreeCourseV2/editFreeCourseV2';
import FreeCoursesV2 from './freeCoursesV2/freeCoursesV2';
import EditMembershipProductV2 from './membershipProductsV2/editMembershipProductV2/editMembershipProductV2';
import MembershipProductsV2 from './membershipProductsV2/membershipProductsV2';
import AppWelcomeMessages from './appWelcomeMessages/appWelcomeMessages';

function AcademyContent(props) {
  return (
    <div>
      <Switch>
        <Route path="/academy-content/free-courses-v2/:id" component={EditFreeCourseV2} />
        <Route path="/academy-content/free-courses-v2" component={FreeCoursesV2} />
        <Route path="/academy-content/free-courses/:id/:moduleIndex" component={Module} />
        <Route path="/academy-content/free-courses/:id" component={EditFreeCourse} />
        <Route path="/academy-content/free-courses" component={FreeCourses} />
        <Route path="/academy-content/completion-ads/analytics" component={CompletionPopupsAnalytics} />
        <Route path="/academy-content/completion-ads" component={CompletionPopups} />
        <Route path="/academy-content/member-content/:id/details/:moduleIndex" component={Module} />
        <Route path="/academy-content/member-content-v2/:id" component={EditMembershipProductV2} />
        <Route path="/academy-content/member-content-v2" component={MembershipProductsV2} />
        <Route path="/academy-content/member-content/:id/purchase-details" component={MembershipProductPurchaseDetails} />
        <Route path="/academy-content/member-content/:id/details" component={MembershipProductDetails} />
        <Route path="/academy-content/member-content/:id" component={EditMembershipProduct} />
        <Route path="/academy-content/member-content" component={MembershipProducts} />
        <Route path="/academy-content/academy-plus-course-landing-pages/:id" component={EditAcademyPlusCourseLandingPage} />
        <Route path="/academy-content/academy-plus-course-landing-pages" component={AcademyPlusCourseLandingPages} />
        <Route path="/academy-content/academy-bonus/:id" component={AcademyBonusPost} />
        <Route path="/academy-content/academy-bonus" component={AcademyBonus} />
        <Route path="/academy-content/featured-content/:id" component={FeaturedContentEdit} />
        <Route path="/academy-content/featured-content" component={FeaturedContent} />
        <Route path="/academy-content/admin-whitelist" component={UnpublishedContentWhitelist} />
        <Route path="/academy-content/training-tools" component={TrainingTools} />
        <Route path="/academy-content/orders" component={Orders} />
        <Route path="/academy-content/visual-search" component={VisualSearch} />
        <Route path="/academy-content/weekly-challenges/:id/posts" component={WeeklyChallengePosts} />
        <Route path="/academy-content/weekly-challenges/:id" component={EditWeeklyChallenge} />
        <Route path="/academy-content/weekly-challenges" component={WeeklyChallenges} />
        <Route path="/academy-content/app-welcome-messages" component={AppWelcomeMessages} />
        <Route component={FreeCourses}/>
      </Switch>
    </div>
  );
}

export default AcademyContent;
