import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  CircularProgress,
  Card,
  Toolbar,
  Divider,
  Typography,
  Button,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './appBanner.scss';
import EditAppBanner from './editAppBanner/editAppBanner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AppBanner(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [banners, setBanners] = useState([]);
  const [editBannerIndex, setEditBannerIndex] = useState(-1);
  const [editBanner, setEditBanner] = useState({
    live: true,
    name: '',
    backgroundColor: '#FAF7E8',
    text: '',
    textColor: '#294634',
    buttonText: '',
    buttonTextColor: '#294634',
    buttonBackgroundColor: '#FAF7E8',
    type: 'external-url',
    externalUrl: '',
    resourceId: '',
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: firebase.auth().currentUser.email,
  });
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchHeaderBanners();
  }, []);

  const fetchHeaderBanners = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('app-header-banners').get();

      const items = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).sort((a, b) => a.created - b.created);



      setBanners(items);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving App Banners, please try again.');
    }
  };

  const addNew = async () => {
    setLoading(true);

    try {

      const newBanner = {
        live: false,
        created: Date.now(),
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
        name: '',
        backgroundColor: '#FAF7E8',
        text: '',
        textColor: '#294634',
        buttonText: '',
        buttonTextColor: '#FAF7E8',
        buttonBackgroundColor: '#294634',
        type: 'external-url',
        externalUrl: '',
        resourceId: '',
      };

      const bannerData = await firebase.firestore().collection('app-header-banners').add(newBanner);
      const bannerToAdd = {
        ...newBanner,
        id: bannerData.id,
      };

      const updatedBanners = [...banners, bannerToAdd];

      setBanners(updatedBanners);
      setLoading(false);
      setEditBanner(bannerToAdd);
      setEditBannerIndex(banners.length);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the App Banner, please try again.');
    }
  };

  const saveBanner = async (updatedBanner) => {
    setLoading(true);

    try {
      const updatedContent = {
        ...updatedBanner,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      await firebase.firestore().collection('app-header-banners').doc(updatedBanner.id).update(updatedContent);

      const newBanners = [...banners];
      newBanners[editBannerIndex] = updatedContent;

      setBanners(newBanners);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('The banner content has been saved.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this banner, please try again.');
    }
  };

  const deleteBanner = async () => {
    setLoading(true);

    try {
      const updatedBanners = [...banners];
      const id = updatedBanners[deleteIndex].id;

      await firebase.firestore().collection('app-header-banners').doc(id).delete();

      updatedBanners.splice(deleteIndex, 1);

      setBanners(updatedBanners);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this banner, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div className="HeaderBanners">
      {renderLoading()}
      <Card>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            App Header Banner
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => { addNew() }}
            aria-label="Add"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>

        <List>
          {banners.map((banner, i) => {
            return (
              <div key={`banner-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={banner.name || 'Unnamed Banner'}
                    secondary={
                      <span>
                        <strong>Status:</strong>
                        <span
                          className="banner-detail-spacing"
                          style={{ color: banner.live ? 'rgb(126, 210, 66)' : 'red' }}>
                          {banner.live ? ' Live' : ' Disabled'}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated:</strong> {moment(new Date(banner.updated)).format('MMMM Do, YYYY')}
                        </span>
                        <span className="banner-detail-spacing">
                          <strong>Last Updated By:</strong> {banner.lastUpdatedBy}
                        </span>
                      </span>
                    }
                  />
                  <IconButton edge="start" style={{ marginRight: 15 }} color="inherit" onClick={() => {
                    setEditBanner(banner);
                    setEditBannerIndex(i);
                  }} aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={editBannerIndex !== -1} onClose={() => setEditBannerIndex(-1)} TransitionComponent={Transition}>
        <EditAppBanner
          closeModal={() => setEditBannerIndex(-1)}
          save={saveBanner}
          bannerData={editBanner}
        />
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Banner?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this banner?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteBanner} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AppBanner;
