import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Slide,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import './managePartners.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ManagePartners(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchItem, setSearchItem] = useState('id');
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedResults, setSearchedResults] = useState([]);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [newPartnerName, setNewPartnerName] = useState('');
  const [addPartnerOpen, setAddPartnerOpen] = useState(false);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    const db = firebase.firestore();

    try {
      const partnersCountSnapshot = await db.collection('partners').doc('data').get();
      const partnersCount = partnersCountSnapshot.data();

      setNumberOfPages(Math.ceil(partnersCount.count / 10));

      const partnersSnapshot = await db.collection('partners').orderBy('created', 'desc').limit(pageCount).get();
      const mappedPartners = partnersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPartners(mappedPartners);
      setFirst(partnersSnapshot.docs[0]);
      setLast(partnersSnapshot.docs[partnersSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching partners. Please try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let partnersSnapshot;
      let newPage = page;

      if (value === 'next') {
        partnersSnapshot = await db.collection('partners').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        partnersSnapshot = await db.collection('partners').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedPartners = partnersSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setPartners(mappedPartners);
      setFirst(partnersSnapshot.docs[0]);
      setLast(partnersSnapshot.docs[partnersSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving partners. Please try again.');
    }
  };

  const search = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      let foundPartners = [];
      let searchedItemText = '';

      if (searchItem === 'id') {
        const partnerSnapshot = await db.collection('partners').doc(searchText).get();
        const data = partnerSnapshot.data();

        if (data) {
          foundPartners.push({
            ...data,
            id: partnerSnapshot.id,
          });
        }

        searchedItemText = 'Partner ID';
      } else if (searchItem === 'name') {
        const partnerSnapshot = await db.collection('partners').where('name', '==', searchText).get();

        foundPartners = partnerSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        searchedItemText = 'Partner Name';
      }

      setSearchedResults(foundPartners);
      setSearchTerm(searchText);
      setSearchedItem(searchedItemText);
      setLoading(false);
      setSearchOpen(false);
    } catch (e) {
      console.log('search error', e);
      setLoading(false);
      setSearchOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error searching partners. Please try again.');
    }
  };

  const addNewPartner = async () => {
    const db = firebase.firestore();
    setLoading(true);
  
    try {
      const createdPartner = await db.collection('partners').add({
        name: newPartnerName,
        created: Date.now(),
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
        deleted: null,
      });
      await db.collection('partners').doc('data').update({
        count: firebase.firestore.FieldValue.increment(1),
      });

      setLoading(false);
      setNewPartnerName('');
      setAddPartnerOpen(false);
      props.history.push(`/partners/manage-partners/${createdPartner.id}`);
    } catch (e) {
      setLoading(false);
      setAddPartnerOpen(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the partner. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ManagePartners">
      {renderLoading()}
      <div style={{marginBottom: 10}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setNewPartnerName('');
            setAddPartnerOpen(true);
          }}
        >
          Add Partner
        </Button>
      </div>

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Partners
          </Typography>
          {!searchTerm ?
            <IconButton edge="start" color="inherit" onClick={() => {
              setSearchItem('id');
              setSearchText('');
              setSearchOpen(true);
            }} aria-label="Delete">
              <SearchIcon />
            </IconButton> :
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchedItem}: {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                setSearchedItem('');
                setSearchedResults([]);
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm ?
          <div>
            {!searchedResults.length ?
              <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
                No results match your search
              </div> :
              <TableContainer component={Paper}>
                <Table aria-label="search partners table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>ID</strong></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedResults.map((p, i) => (
                      <TableRow key={p.id}>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.id}</TableCell>
                        <TableCell padding="checkbox">
                          <Link to={`/partners/manage-partners/${p.id}`}>
                            <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div> :
          <TableContainer component={Paper}>
            <Table aria-label="partners table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partners.map((p, i) => (
                  <TableRow key={p.id}>
                    <TableCell>{p.name}</TableCell>
                    <TableCell>{p.id}</TableCell>
                    <TableCell padding="checkbox">
                      <Link to={`/partners/manage-partners/${p.id}`}>
                        <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Divider/>

        {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Partners</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <FormControl variant="outlined" margin="dense" className="day-text-field">
              <InputLabel>Search Field</InputLabel>
              <Select
                value={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value)
                }}
                label="Search Field"
              >
                <MenuItem value={'id'}>Partner ID</MenuItem>
                <MenuItem value={'name'}>Partner Name</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addPartnerOpen} onClose={() => { setAddPartnerOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Partner</DialogTitle>
        <DialogContent>
          <TextField
            label="Partner Name"
            value={newPartnerName}
            onChange={(e) => {
              setNewPartnerName(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewPartner} disabled={!newPartnerName} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddPartnerOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManagePartners;
