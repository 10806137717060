import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { useProductsFields } from './useProductsFields';
import { ColorPicker } from '../../../../../colorPicker/colorPicker';

export const ProductsItems = ({ control, errors }) => {
  const {
    productsItems,
    removeProductItem,
    appendProductItem,
    moveProductItem,
  } = useProductsFields(control);

  return (
    <div className="section-container">
      <div className="section-label">Items</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 8px',
        }}
      >
        {productsItems?.map((item, index) => (
          <Fragment key={item.id}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
              }}
              variant="outlined"
            >
              <Toolbar
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  <strong>Item {index + 1}</strong>
                </span>

                <Box
                  sx={{
                    gap: '8px',
                    display: 'flex',
                  }}
                >
                  {index !== productsItems.length - 1 ? (
                    <IconButton
                      color="inherit"
                      onClick={() => moveProductItem(index, index + 1)}
                    >
                      <ArrowDownIcon />
                    </IconButton>
                  ) : null}

                  {index !== 0 ? (
                    <IconButton
                      color="inherit"
                      onClick={() => moveProductItem(index, index - 1)}
                    >
                      <ArrowUpIcon />
                    </IconButton>
                  ) : null}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: '8px',
                    }}
                  >
                    {index === productsItems.length - 1 &&
                    productsItems.length < 3 ? (
                      <IconButton
                        color="inherit"
                        onClick={() => appendProductItem()}
                        aria-label="Add Menu Item"
                      >
                        <AddIcon />
                      </IconButton>
                    ) : null}

                    {index !== 0 ? (
                      <IconButton
                        color="inherit"
                        aria-label="Delete"
                        onClick={() => removeProductItem(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                </Box>
              </Toolbar>

              <Divider />
              <CardContent>
                <Controller
                  name={`productsSection.items.${index}.title`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Title"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                        error={Boolean(
                          errors.productsSection?.items?.[index]?.title,
                        )}
                        helperText={
                          errors.productsSection?.items?.[index]?.title?.message
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name={`productsSection.items.${index}.imageUrl`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Image Url"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                        error={Boolean(
                          errors.productsSection?.items?.[index]?.imageUrl,
                        )}
                        helperText={
                          errors.productsSection?.items?.[index]?.imageUrl
                            ?.message
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name={`productsSection.items.${index}.linkText`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Link Text"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                        error={Boolean(
                          errors.productsSection?.items?.[index]?.linkText,
                        )}
                        helperText={
                          errors.productsSection?.items?.[index]?.linkText
                            ?.message
                        }
                      />
                    );
                  }}
                />

                <Controller
                  name={`productsSection.items.${index}.linkUrl`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Link Url"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                        error={Boolean(
                          errors.productsSection?.items?.[index]?.linkUrl,
                        )}
                        helperText={
                          errors.productsSection?.items?.[index]?.linkUrl
                            ?.message
                        }
                      />
                    );
                  }}
                />

                <Controller
                  name={`productsSection.items.${index}.linkColor`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <ColorPicker
                        label="Link Color"
                        field={field}
                        error={Boolean(
                          errors.productsSection?.items?.[index]?.linkColor,
                        )}
                        helperText={
                          errors.productsSection?.items?.[index]?.linkColor
                            ?.message
                        }
                      />
                    );
                  }}
                />
              </CardContent>
            </Card>
          </Fragment>
        ))}
      </Box>
    </div>
  );
};
