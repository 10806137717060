import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { config } from '../../../config';

const cloneDeep = require('lodash.clonedeep');
const uuidv4 = require('uuid/v4');

function MembershipProductDetails(props) {
  const [modules, setModules] = useState([]);
  const [product, updateProduct] = useState({
    modules: [],
    regularPrice: 0,
    salePrice: 0,
  });
  const [products, setProducts] = useState([{ modules: [] }]);
  const [loading, setLoading] = useState(true);
  const [copyModuleOpen, setCopyModuleOpen] = useState(false);
  const [copyAllModulesOpen, setCopyAllModulesOpen] = useState(false);
  const [courseCopyIndex, setCourseCopyIndex] = useState(0);
  const [courseCopyModuleIndex, setCourseCopyModuleIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  
  useEffect(() => {
    async function fetchProducts() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('membership-content').get();
        const products = querySnapshot.docs.map(doc => {
          return doc.data();
        });

        const product = products.find(p => p.id === props.match.params.id);

        setProducts(products);
        setModules(product.modules);
        updateProduct(product);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    fetchProducts();
  }, []);

  const addModuleHeading = () => {
    const modulesClone = cloneDeep(modules);

    modulesClone.push('');
    setModules(modulesClone);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  };

  const handleHeadingChange = (value, i) => {
    const modulesClone = cloneDeep(modules);
    modulesClone[i] = value;
    setModules(modulesClone);
  };

  const addModule = () => {
    const modulesClone = cloneDeep(modules);

    modulesClone.push({
      title: 'Module Title',
      imageUrl: '',
      backgroundImage: '',
      description: '',
      sections: [],
      categories: [''],
      id: uuidv4(),
    });

    setModules(modulesClone);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      saveProduct(modulesClone);
    }, 0);
  };

  const saveProduct = async (modulesClone) => {
    setLoading(true);

    try {
      const productCopy = cloneDeep(product);
      productCopy.modules = modulesClone || modules;
      updateProduct(productCopy);
      const db = firebase.firestore();
      await db.collection('membership-content').doc(product.id).update(productCopy);

      const token = await firebase.auth().currentUser.getIdToken();
      const method = productCopy.enabled ? 'POST' : 'DELETE';
      await axios(`${config.gateway}/course-service/v1/courses/${product.id}/search`, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log('error', e);
    }

    setLoading(false);
  };

  const moveItemUp = (i) => {    
    const modulesClone = cloneDeep(modules);
    const itemToMoveUp = modulesClone[i];
    const itemToMoveDown = modulesClone[i - 1];
    modulesClone[i] = itemToMoveDown;
    modulesClone[i - 1] = itemToMoveUp;
    setModules(modulesClone);
  };

  const moveItemDown = (i) => {
    const modulesClone = cloneDeep(modules);
    const itemToMoveDown = modulesClone[i];
    const itemToMoveUp = modulesClone[i + 1];
    modulesClone[i] = itemToMoveUp;
    modulesClone[i + 1] = itemToMoveDown;
    setModules(modulesClone);
  };

  const handleCourseCopyChange = event => {
    setCourseCopyModuleIndex(0);
    setCourseCopyIndex(event.target.value);
  };

  const handleCourseCopyModuleChange = event => {
    setCourseCopyModuleIndex(event.target.value);
  };

  const copyModule = () => {
    const modulesClone = cloneDeep(modules);
    modulesClone.push(cloneDeep({ ...products[courseCopyIndex].modules[courseCopyModuleIndex], id: uuidv4() }));
    setModules(modulesClone);
    setCopyModuleOpen(false);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      saveProduct(modulesClone);
    }, 0);
  };

  const copyAllModules = () => {
    const selectedModulesClone = products[courseCopyIndex].modules.map(module => {
      if (typeof module === 'string') {
        return module;
      }

      return {
        ...module,
        id: uuidv4(),
      };
    });

    const updatedModules = [ ...modules, ...cloneDeep(selectedModulesClone) ];

    setModules(updatedModules);
    setCopyAllModulesOpen(false);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      saveProduct(updatedModules);
    }, 0);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (!products.length) {
      return null;
    }

    return (
      <p>
        <Link to={`/academy-content/member-content`} style={{color: 'blue', textDecoration: 'none'}}>Academy Content</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        <Link to={`/academy-content/member-content/${product.id}`} style={{color: 'blue', textDecoration: 'none'}}>{product.name}</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        Modules
      </p>
    );
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i) }} aria-label="Move Up">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i) }} aria-label="Move Down">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const handleClick = (event, i) => {
    setDeleteIndex(i);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteIndex(-1);
  };

  const deleteModule = () => {
    const modulesClone = cloneDeep(modules);
    modulesClone.splice(deleteIndex, 1);
    setModules(modulesClone);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-confirmation' : undefined;

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{padding: '10px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Delete Item?</div>
          <Button 
            variant="contained"
            color="secondary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { deleteModule() }}
          >
            Delete
          </Button>
          <Button 
            variant="contained"
            color="primary"
            style={{marginBottom: '5px'}}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Popover>
      {renderLoading()}
      <div style={{marginTop: '85px', marginLeft: '20px', textAlign: 'left'}}>
        {renderBreadcrumbs()}
      </div>
      <Card className="drag-card" style={{marginTop: '10px', display: 'inline-block', textAlign: 'left'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {product.name} Modules
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveProduct() }}
          >
            Save
          </Button>
        </Toolbar>

        <CardContent>
          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addModuleHeading() }}
          >
            Add Module Heading
          </Button>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { addModule() }}
          >
            Add Module
          </Button>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { setCopyModuleOpen(true) }}
          >
            Copy Module
          </Button>

          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '10px', marginBottom: '5px'}}
            onClick={() => { setCopyAllModulesOpen(true) }}
          >
            Copy All From Another Course
          </Button>
        </CardContent>
        <List>
          {modules.map((m, i) => {
            const isHeading = typeof m === 'string';

            return (
              <div key={`module-${i}`}>
                <Divider />
                <ListItem>
                  {
                    isHeading ?
                    <TextField
                      style={{width: '100%'}}
                      label="Module Heading"
                      value={m}
                      onChange={(e) => { handleHeadingChange(e.target.value, i) }}
                      margin="normal"
                      variant="outlined"
                      className="day-text-field"
                    /> :
                    <ListItemText primary={m.title} secondary={m.description} />
                  }

                  {renderUpArrow(i)}

                  {renderDownArrow(i, modules.length - 1)}

                  {isHeading ? null : <Link to={`/academy-content/member-content/${props.match.params.id}/details/${i}`} style={{color: 'rgba(0, 0, 0, .5)', textDecoration: 'none'}}>
                    <IconButton color="inherit" aria-label="Edit Module">
                      <EditIcon />
                    </IconButton>
                  </Link>}

                  <IconButton color="inherit" onClick={(e) => { handleClick(e, i) }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={copyModuleOpen} onClose={() => { setCopyModuleOpen(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Copy Product Module</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a product and module you would like to copy.
          </DialogContentText>
          <FormControl style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel>
              Product
            </InputLabel>
            <Select
              value={courseCopyIndex}
              onChange={handleCourseCopyChange}
            >
              {products.map((p, i) => {
                return (
                  <MenuItem
                    value={i}
                    key={`sync-products-${i}`}
                  >
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel>
              Module
            </InputLabel>
            <Select
              value={courseCopyModuleIndex}
              onChange={handleCourseCopyModuleChange}
            >
              {products[courseCopyIndex].modules.map((m, i) => {
                return (
                  <MenuItem
                    value={i}
                    key={`sync-products-module-${i}`}
                  >
                    {typeof m === 'string' ? m : m.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyModule} disabled={!products[courseCopyIndex].modules[0]} color="primary" variant="contained">
            Copy
          </Button>
          <Button onClick={() => { setCopyModuleOpen(false) }} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={copyAllModulesOpen} onClose={() => { setCopyAllModulesOpen(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Copy All Product Modules</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a product you would like to copy.
          </DialogContentText>
          <FormControl style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel>
              Product
            </InputLabel>
            <Select
              value={courseCopyIndex}
              onChange={handleCourseCopyChange}
            >
              {products.map((p, i) => {
                return (
                  <MenuItem
                    value={i}
                    key={`sync-products-${i}`}
                  >
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyAllModules} disabled={!products[courseCopyIndex].modules[0]} color="primary" variant="contained">
            Copy
          </Button>
          <Button onClick={() => { setCopyAllModulesOpen(false) }} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MembershipProductDetails;
