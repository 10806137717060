import React from 'react';

import './dashboard.scss';

function Dashboard({}) {
  return (
    <div className="RetailMicrositeDashboard">
      Dashboard
    </div>
  );
}

export default Dashboard;
