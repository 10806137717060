import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import SwipeableViews from 'react-swipeable-views';
import {
  Typography,
  Toolbar,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import ShippingZones from './shippingZones/shippingZones';
import ShippingRegions from './shippingRegions/shippingRegions';
import ShippingClasses from './shippingClasses/shippingClasses';
import './shipping.scss';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Shipping(props) {
  const swipeableViewsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [shipping, setShipping] = useState({
    classes: [],
    zones: [],
    regions: {},
  });

  useEffect(() => {
    fetchShipping();
  }, []);

  useEffect(() => {
    if (swipeableViewsRef.current) {
      swipeableViewsRef.current
        .getChildContext()
        .swipeableViews.slideUpdateHeight()
    }
  }, [tabValue, shipping]);

  const fetchShipping = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('config').doc('shipping').get();
      const productsQuerySnapshot = await db.collection('products-v2').get();

      const productClassCountMap = {};

      productsQuerySnapshot.docs.forEach(p => {
        const shippingClass = p.data().shippingClass;

        if (productClassCountMap[shippingClass]) {
          productClassCountMap[shippingClass] += 1;
        } else {
          productClassCountMap[shippingClass] = 1;
        }
      });

      const shippingData = querySnapshot.data();

      shippingData.classes = shippingData.classes.map(shippingClass => {
        return {
          ...shippingClass,
          productCount: productClassCountMap[shippingClass.id] || 0,
        };
      });

      setShipping(shippingData);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      window.alert('Error loading shipping data. Please refresh and try again.');
    }
  };

  const saveShippingClasses = async (shippingClass, i) => {
    const db = firebase.firestore();

    setLoading(true);

    try {
      const classes = [ ...shipping.classes ];

      classes[i] = shippingClass;

      await db.collection('config').doc('shipping').update({
        classes,
      });

      setShipping({
        ...shipping,
        classes,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error updating shipping classes. Please Try again.');
    }

    setLoading(false);
  };

  const deleteShippingClass = async (i) => {
    const db = firebase.firestore();

    setLoading(true);

    try {
      const classes = [ ...shipping.classes ];

      classes.splice(i);

      await db.collection('config').doc('shipping').update({
        classes,
      });

      setShipping({
        ...shipping,
        classes,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error deleting the shipping classes. Please Try again.');
    }

    setLoading(false);
  };

  const saveRegions = async (regions) => {
    const db = firebase.firestore();

    try {

      await db.collection('config').doc('shipping').update({
        regions,
      });

      setShipping({
        ...shipping,
        regions,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error updating regions. Please Try again.');
    }

    setLoading(false);
  };

  const saveZones = async (zones) => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      await db.collection('config').doc('shipping').update({
        zones,
      });

      setShipping({
        ...shipping,
        zones,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error updating zones. Please Try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Shipping">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Shipping
        </Typography>
      </Toolbar>

      <div className="tabs-container">
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => { setTabValue(newValue) }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="shipping tabs"
        >
          <Tab label="Shipping Zones" {...a11yProps(0)} />
          <Tab label="Shipping Regions" {...a11yProps(1)} />
          <Tab label="Shipping Classes" {...a11yProps(2)} />
        </Tabs>
        <Divider />
        <SwipeableViews
          index={tabValue}
          onChangeIndex={(index) => { setTabValue(index) }}
          ref={swipeableViewsRef}
          animateHeight={true}
        >
          <div className="container" value={tabValue} index={0}>
            <ShippingZones
              zones={shipping.zones}
              regions={shipping.regions}
              classes={shipping.classes}
              save={saveZones}
            />
          </div>
          <div className="container" value={tabValue} index={1}>
            <ShippingRegions
              setLoading={setLoading}
              regions={shipping.regions}
              save={saveRegions}
            />
          </div>
          <div className="container" value={tabValue} index={2}>
            <ShippingClasses
              classes={shipping.classes}
              save={saveShippingClasses}
              delete={deleteShippingClass}
            />
          </div>
        </SwipeableViews>
      </div>
    </div>
  );
}

export default Shipping;
