import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Groups from './groups/groups';
import EditGroup from './groups/editGroup/editGroup';
import FlaggedComments from './flaggedComments/flaggedComments';
import FlaggedPosts from './flaggedPosts/flaggedPosts';
import GroupPosts from './groups/groupPosts/groupPosts';
import GroupPost from './groups/groupPosts/groupPost/groupPost';

function Community() {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/community/groups/:id/posts/:postId" component={GroupPost} />
        <Route path="/community/groups/:id/posts" component={GroupPosts} />
        <Route path="/community/groups/:id" component={EditGroup} />
        <Route path="/community/groups" component={Groups} />
        <Route path="/community/posts/flagged" component={FlaggedPosts} />
        <Route path="/community/comments/flagged" component={FlaggedComments} />
        <Route component={Groups}/>
      </Switch>
    </div>
  );
}

export default Community;
