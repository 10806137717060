import React, { useState } from 'react';
import {
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';

import './formActions.scss';
import FormActionEditor from './formActionEditor/formActionEditor';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const actionTypes = [
  {
    name: 'Add to Klaviyo',
    description: 'Add user details to Klaviyo.',
    type: 'add-to-klaviyo',
  },
  {
    name: 'Add to Sendlane',
    description: 'Add user details to Sendlane.',
    type: 'add-to-sendlane',
  },
  {
    name: 'Redirect to Page',
    description: 'Redirect to a custom URL when the form is submitted.',
    type: 'redirect-to-page',
  },
];

function FormActions({
  pages,
  actions,
  updateActions,
}) {
  const [currentActionIndex, setCurrentActionIndex] = useState(0);
  const [actionDeleteIndex, setActionDeleteIndex] = useState(-1);
  const [addActionTypeIndex, setAddActionTypeIndex] = useState(0);
  const [addActionOpen, setAddActionOpen] = useState(false);

  const addFormAction = () => {
    const type = actionTypes[addActionTypeIndex].type;
    const newAction = {
      type,
    };

    if (type === 'add-to-klaviyo') {
      newAction.emailField = '';
      newAction.listId = '';
      newAction.additionalFields = [];
    }

    if (type === 'add-to-sendlane') {
      newAction.emailField = '';
      newAction.listId = '';
      newAction.additionalFields = [];
    }

    if (type === 'redirect-to-page') {
      newAction.pageURL = '';
    }

    updateActions([
      ...actions,
      newAction,
    ]);
    setCurrentActionIndex(actions.length);
    setAddActionOpen(false);
  };

  return (
    <div className="FormActions">
      <div className="pages-container">
        <div className="left-container">
          {actions.map((c, i) => {
            return (
              <div key={`action-${i}`} className="form-page-container">
                <div
                  className="delete-button"
                  onClick={() => { setActionDeleteIndex(i) }}
                >
                  <p>×</p>
                </div>
                <div
                  className={`page-flow-container form-page${currentActionIndex === i ? ' selected' : ''}`}
                  onClick={() => {
                    setCurrentActionIndex(i);
                  }}
                >
                  <p className="capitalize">
                    <strong>{c.type.split('-').join(' ')}</strong>
                  </p>
                </div>
              </div>
            );
          })}

          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ width: '100%' }}
            onClick={() => {
              setAddActionTypeIndex(0);
              setAddActionOpen(true);
            }}
          >
            Add Action
          </Button>
        </div>

        {!actions.length ? null :
          <div className="right-container">
            <FormActionEditor
              action={actions[currentActionIndex]}
              updateAction={(action) => {
                const actionsCopy = [ ...actions ];

                actionsCopy[currentActionIndex] = action;

                updateActions(actionsCopy);
              }}
              actionTypes={actionTypes}
              pages={pages}
            />
          </div>
        }
      </div>

      <Dialog fullWidth maxWidth="sm" open={actionDeleteIndex !== -1} onClose={() => { setActionDeleteIndex(-1) }}>
        <DialogTitle>Delete Action?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this action?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (currentActionIndex === (actions.length - 1) && currentActionIndex !== 0) {
                setCurrentActionIndex(currentActionIndex - 1);
              }

              const updatedActions = [ ...actions ];
              updatedActions.splice(actionDeleteIndex, 1);

              updateActions(updatedActions);
              setActionDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setActionDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={addActionOpen} onClose={() => { setAddActionOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Select Action Type</DialogTitle>
        <DialogContent>
          <div className="add-form-action-modal">
            {actionTypes.map((type, i) => {
              return (
                <div
                  key={`add-form-action-type-${i}`}
                  className={`add-form-action-modal-item${addActionTypeIndex === i ? ' selected' : ''}`}
                  onClick={() => setAddActionTypeIndex(i)}
                >
                  <p className="add-form-action-modal-item-title">{type.name}</p>
                  <p className="add-form-action-modal-item-text">{type.description}</p>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addFormAction} color="primary">
            Add
          </Button>
          <Button variant="contained" onClick={() => { setAddActionOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormActions;
