import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import DatePicker from 'react-datepicker';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import './customerLTV.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endYesterday = new Date();
endYesterday.setDate(endYesterday.getDate() - 1);
endYesterday.setHours(23, 59, 59, 999);

function CustomerLTV({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [report, setReport] = useState(null);
  const [subscriptionsOnly, setSubscriptionsOnly] = useState(false);
  const [monthlyValue, setMonthlyValue] = useState('totalCustomers');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchLTV();
  }, [subscriptionsOnly]);

  const fetchLTV = async (newStart, newEnd) => {
    const end = newEnd || endYesterday;
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date('2018-01-01T00:00:00');
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/customer/ltv?end=${moment(end).endOf('month').valueOf()}${newStart ? `&start=${moment(start).startOf('month').valueOf()}` : ''}${subscriptionsOnly ? '&subscriptions=1' : ''}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setReport(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e.toString());
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred retrieving LTV. Please try again.');
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CustomerLTV">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Customer LTV
          </Typography>
        </Toolbar>

        <Divider/>

        {!report ? null :
          <CardContent>
            <div className="date-picker-row">
              <div className="date-picker-container">
                <Typography>
                  <small>Start</small>
                </Typography>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    const start = new Date(date);
                    start.setHours(0, 0, 0, 0);
                    setStartDate(start);
                  }}
                  maxDate={endYesterday}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div>
              <div className="date-divider" style={{marginTop: 14}}>-</div>
              <div className="date-picker-container">
                <Typography>
                  <small>End</small>
                </Typography>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    const end = new Date(date);
                    end.setHours(23, 59, 59, 999);
                    setEndDate(end);
                  }}
                  maxDate={endYesterday}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div>

              <div className="date-picker-submit-button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => fetchLTV(startDate, endDate)}
                >
                  Submit
                </Button>
              </div>
            </div>

            <ToggleButtonGroup
              color="primary"
              value={subscriptionsOnly}
              exclusive
              onChange={(e, value) => {
                setSubscriptionsOnly(value);
              }}
              size="small"
            >
              <ToggleButton value={false}>All</ToggleButton>
              <ToggleButton value={true}>Subscriptions Only</ToggleButton>
            </ToggleButtonGroup>

            <div className="card-row">
              <div className="card-container">
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">
                      Average Lifetime Value:
                    </Typography>
                    <div className="card-spacing"></div>
                    <Typography variant="h6">
                      {formatter.format(report.totalLTV)}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="card-container">
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">
                      Average Order Value:
                    </Typography>
                    <div className="card-spacing"></div>
                    <Typography variant="h6">
                      {formatter.format(report.totalRevenue / report.totalOrders)}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="card-container">
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">
                      Average Lifetime Orders:
                    </Typography>
                    <div className="card-spacing"></div>
                    <Typography variant="h6">
                      {(report.totalOrders / report.totalCustomers).toFixed(1)}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>

            <Typography variant="h6" className="monthly-statistics-header">
              New Customer Revenue Over Time
            </Typography>

            <Chart
              width={'100%'}
              height={'500'}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  'Month',
                  'Total',
                ],
                ...report.newCustomerSpendAveragePerMonth.map((m, i) => {
                  return [i + 1, m];
                }),
              ]}
              options={{
                legend: {
                  position: 'none',
                },
                width: '100%',
                height: 500,
              }}
              rootProps={{ 'data-testid': '4' }}
              formatters={[
                {
                  type: 'NumberFormat',
                  column: 0,
                },
                {
                  type: 'NumberFormat',
                  column: 1,
                  options: {
                    prefix: '$',
                  },
                },
              ]}
            />

            <Typography variant="h6" className="monthly-totals-header">
              Monthly Statistics
            </Typography>

            <ToggleButtonGroup
              color="primary"
              value={monthlyValue}
              exclusive
              onChange={(e, value) => {
                setMonthlyValue(value);
              }}
              className="monthly-totals-buttons"
              size="small"
            >
              <ToggleButton value="totalCustomers">Total Customers</ToggleButton>
              <ToggleButton value="ordersPerCustomer">Average Orders Per Customer</ToggleButton>
              <ToggleButton value="averageOrderValue">Average Order Value</ToggleButton>
            </ToggleButtonGroup>

            <Chart
              width={'100%'}
              height={'500'}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { type: 'date' },
                  'Total',
                ],
                ...report.months.sort((a, b) => {
                  return moment(a.created).valueOf() - moment(b.created).valueOf();
                }).map(m => {
                  let value;

                  if (monthlyValue === 'totalCustomers') {
                    value = m.totalCustomers;
                  } else if (monthlyValue === 'ordersPerCustomer') {
                    value = m.totalOrders / m.totalCustomers;
                  } else if (monthlyValue === 'averageOrderValue') {
                    value = m.totalRevenue / m.totalOrders;
                  }

                  return [moment(m.created).toDate(), value];
                }),
              ]}
              options={{
                legend: {
                  position: 'none',
                },
                width: '100%',
                height: 500,
              }}
              rootProps={{ 'data-testid': '4' }}
              formatters={[
                {
                  type: 'DateFormat',
                  column: 0,
                },
                {
                  type: 'NumberFormat',
                  column: 1,
                  options: {
                    prefix: monthlyValue === 'averageOrderValue' ? '$' : '',
                  },
                },
              ]}
            />
          </CardContent>
        }
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomerLTV;
