import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submit(e) {
    e.preventDefault();
    this.props.logIn(this.state.email, this.state.password);
  }

  render() {
    return (
      <div>
        <Card style={{maxWidth: '500px', marginTop: '100px', marginLeft: '50%', transform: 'translateX(-50%)', minWidth: '325px'}}>
          <CardContent>

            <form onSubmit={this.submit.bind(this)}>
              <div>
                <TextField
                  style={{width: '100%'}}
                  label="Email"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                  variant="outlined"
                  type="email"
                />
              </div>
              
              <div>
                <TextField
                  style={{width: '100%'}}
                  label="Password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  margin="normal"
                  variant="outlined"
                  type="password"
                />
              </div>
              
              <div>
                <Button
                  style={{width: '100%'}}
                  variant="contained"
                  color="primary"
                  type="submit"
                >Sign In</Button>
              </div>
            </form>

            <div style={{color: 'darkred', fontSize: '10px', marginTop: '10px', marginBottom: '-15px'}}>
              {this.props.loginError}
            </div>

          </CardContent>
        </Card>
        
      </div>
    );
  }
}

export default Login;
