import React, { useRef, useEffect } from 'react';

import './buttonPreviewModal.scss';

function ButtonPreviewModal({ open, close, title, children, buttons }) {
  const modalEl = useRef(null);

  useEffect(() => {
    if (open) {
      document.body.append(modalEl.current);
    } else if (modalEl.current) {
      document.body.removeChild(modalEl.current);
    }
  }, [open]);

  if (!open && !modalEl.current) {
    return <div></div>;
  }

  return (
    <div ref={modalEl} className="ButtonPreviewModal">
      <div className="modal-body">
        <div className="close-button" onClick={close}>
          <i>×</i>
        </div>

        {!title ? null : <h3 className="title">{title}</h3>}
        <div className="modal-content">{children}</div>
        {!buttons ? null : <div className="buttons-container">{buttons}</div>}
      </div>
    </div>
  );
}

export default ButtonPreviewModal;
