import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Quizzes(props) {
  const [quizzes, setQuizzes] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [quizName, setQuizName] = useState('');
  const [quizContent, setQuizContent] = useState('');

  useEffect(() => {
    async function getQuizzes() {
      const db = firebase.firestore();
      try {
        const querySnapshot = await db.collection('quizzes').get();
        const quizzes = querySnapshot.docs.map(doc => {
          return doc.data();
        });

        setQuizzes(quizzes);
      } catch (e) {
        console.log(e.toString());
      }
    }
    getQuizzes();
  }, []);

  const saveQuiz = () => {
    setShowAddModal(false);
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Quizzes
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { setShowAddModal(true) }}
          >
            Add Quiz
          </Button>
        </Toolbar>
        <List>
          {quizzes.map((q, i) => {
            return (
              <div key={q.uid}>
                <Divider />
                <ListItem>
                  <ListItemText primary={q.name} secondary={`ID: ${q.uid}`} />
                  
                  {/* <IconButton edge="start" color="inherit" onClick={() => {  }} aria-label="Delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog open={showAddModal} onClose={() => { setShowAddModal(false) }} TransitionComponent={Transition}>
        <div style={{padding: '20px'}}>
          <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Add Quiz</p>
          {/* <TextField
            style={{minWidth: '225px'}}
            label="Course Name"
            value={addProductName}
            onChange={(e) => { setAddProductName(e.target.value) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            style={{minWidth: '225px'}}
            label="Course ID"
            value={addProductId}
            onChange={(e) => { setAddProductId(e.target.value) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          /> */}
          <hr/>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!quizContent || !quizName}
            onClick={saveQuiz}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddModal(false) }}
          >cancel</Button>
        </div>
      </Dialog>
    </div>
  );
}

export default Quizzes;
