import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Toolbar,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';

import './formConditionEditor.scss';
import {
  formInputTypes,
  textInputStates,
  dateUploadStates,
  multiSelectStates,
  ratingStates,
  fileUploadStates,
  valueFields,
} from '../../formTypesDataMap';

function FormConditionEditor({
  condition = {},
  updateCondition,
  conditionTypes,
  pages,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (condition && condition.type) {
      const foundConditionType = conditionTypes.find(c => c.type === condition.type);

      if (foundConditionType) {
        setTitle(foundConditionType.name);
        setDescription(foundConditionType.description);
      } else {
        setTitle('');
        setDescription('');
      }
    }
  }, [condition]);

  useEffect(() => {
    const availableQuestions = [];
    const availableQuestionIds = [];
    const availablePageIds = [];

    pages.forEach((p, i) => {
      availablePageIds.push(p.id);

      p.questions.forEach((q, j) => {
        if (formInputTypes.includes(q.type)) {
          availableQuestions.push({
            ...q,
            page: i,
            index: j,
          });
          availableQuestionIds.push(q.internalId);
        }
      });
    });

    const conditionsToMeet = condition.conditionsToMeet.map(c => {
      const hasField = availableQuestionIds.includes(c.field);

      return {
        ...c,
        field: hasField ? c.field : '',
        state: hasField ? c.state : '',
      };
    });

    let applyTo = [];

    if (condition.type.includes('field')) {
      applyTo = condition.applyTo.filter(field => {
        return availableQuestionIds.includes(field);
      });
    } else {
      applyTo = condition.applyTo.filter(page => {
        return availablePageIds.includes(page);
      });
    }

    if (applyTo.length === 0) {
      applyTo.push('');
    }

    setQuestions(availableQuestions);
    updateCondition({
      ...condition,
      conditionsToMeet,
      applyTo,
    });
  }, [pages]);

  const renderStateOptions = (field) => {
    let fieldType = '';

    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];
      if (field === q.internalId) {
        fieldType = q.type;
        break;
      }
    }

    let states = [];

    if (fieldType === 'text-input' || fieldType === 'select-dropdown' || fieldType === 'single-select') {
      states = textInputStates;
    } else if (fieldType === 'date') {
      states = dateUploadStates;
    } else if (fieldType === 'multi-select') {
      states = multiSelectStates;
    } else if (fieldType === 'rating') {
      states = ratingStates;
    } else if (fieldType === 'file-upload') {
      states = fileUploadStates;
    }

    return states.map(s => {
      return <MenuItem key={s} value={s}><span style={{ textTransform: 'capitalize' }}>{s.split('-').join(' ')}</span></MenuItem>;
    });
  };

  return (
    <div className="FormConditionEditor">
      <p className="form-condition-title">{title}</p>
      <p className="form-condition-description">{description}</p>

      <Toolbar className="form-items-heading">
        <Typography variant="subtitle1">
          Conditions To Meet
        </Typography>

        <Button 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            const conditionsToMeet = [ ...condition.conditionsToMeet ];

            conditionsToMeet.push({
              field: '',
              state: '',
              target: 'value',
              value: '',
              targetField: '',
            });

            updateCondition({
              ...condition,
              conditionsToMeet,
            });
          }}
        >
          Add Condition
        </Button>
      </Toolbar>

      {condition.conditionsToMeet.map((c, i) => {
        return (
          <div className="field-condition-container" key={`field-condition-container-${i}`}>
            {condition.conditionsToMeet.length < 2 ? null :
              <div
                className="delete-button"
                onClick={() => {
                  const conditionsToMeet = [ ...condition.conditionsToMeet ];

                  conditionsToMeet.splice(i, 1);

                  updateCondition({
                    ...condition,
                    conditionsToMeet,
                  });
                }}
              >
                <p>×</p>
              </div>
            }
            <div className="field-condition-row">
              <p className="field-condition-label">If</p>

              <FormControl
                variant="outlined"
                margin="dense"
                className="day-text-field"
              >
                <InputLabel>
                  Question
                </InputLabel>
                <Select
                  value={c.field}
                  onChange={(e) => {
                    const conditionsToMeet = [ ...condition.conditionsToMeet ];

                    conditionsToMeet[i] = {
                      ...conditionsToMeet[i],
                      field: e.target.value,
                      state: '',
                    };

                    updateCondition({
                      ...condition,
                      conditionsToMeet,
                    });
                  }}
                  label="Question"
                >
                  {questions.map((q, i) => {
                    return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>

            {!c.field ? null :
              <div className="field-condition-row">
                <p className="field-condition-label">State</p>

                <FormControl
                  variant="outlined"
                  margin="dense"
                  className="day-text-field"
                >
                  <InputLabel>
                    State
                  </InputLabel>
                  <Select
                    value={c.state}
                    onChange={(e) => {
                      const conditionsToMeet = [ ...condition.conditionsToMeet ];

                      conditionsToMeet[i] = {
                        ...conditionsToMeet[i],
                        state: e.target.value,
                      };

                      updateCondition({
                        ...condition,
                        conditionsToMeet,
                      });
                    }}
                    label="State"
                  >
                    {renderStateOptions(c.field)}
                  </Select>
                </FormControl>
              </div>
            }

            {!valueFields.includes(c.state) ? null :
              <>
                <div className="field-condition-row">
                  <p className="field-condition-label">Target</p>

                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className="day-text-field"
                  >
                    <InputLabel>
                      Target
                    </InputLabel>
                    <Select
                      value={c.target}
                      onChange={(e) => {
                        const conditionsToMeet = [ ...condition.conditionsToMeet ];
  
                        conditionsToMeet[i] = {
                          ...conditionsToMeet[i],
                          target: e.target.value,
                        };
  
                        updateCondition({
                          ...condition,
                          conditionsToMeet,
                        });
                      }}
                      label="Target"
                    >
                      <MenuItem value="value">Value</MenuItem>
                      <MenuItem value="another-field">Another Field</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {c.target === 'value' ?
                  <div className="field-condition-row">
                    <p className="field-condition-label">Value</p>

                    <TextField
                      label="Value"
                      value={c.value}
                      onChange={(e) => {
                        const conditionsToMeet = [ ...condition.conditionsToMeet ];

                        conditionsToMeet[i] = {
                          ...conditionsToMeet[i],
                          value: e.target.value,
                        };

                        updateCondition({
                          ...condition,
                          conditionsToMeet,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                    />
                  </div> :
                  <div className="field-condition-row">
                    <p className="field-condition-label">Field</p>

                    <FormControl
                      variant="outlined"
                      margin="dense"
                      className="day-text-field"
                    >
                      <InputLabel>
                        Field
                      </InputLabel>
                      <Select
                        value={c.targetField}
                        onChange={(e) => {
                          const conditionsToMeet = [ ...condition.conditionsToMeet ];

                          conditionsToMeet[i] = {
                            ...conditionsToMeet[i],
                            targetField: e.target.value,
                          };

                          updateCondition({
                            ...condition,
                            conditionsToMeet,
                          });
                        }}
                        label="Field"
                      >
                        {questions.map((q, i) => {
                          return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                }
              </>
            }
          </div>
        );
      })}

      {condition.conditionsToMeet.length < 2 ? null :
        <FormControlLabel
          control={
            <Switch
              checked={condition.meetAllConditions}
              onChange={(e) => {
                updateCondition({
                  ...condition,
                  meetAllConditions: e.target.checked,
                });
              }}
              name="meet-all-conditions"
              color="primary"
            />
          }
          label="Must Meet All Conditions"
        />
      }

      <Toolbar className="form-items-heading">
        <Typography variant="subtitle1">
          <span style={{ textTransform: 'capitalize' }}>
            {condition.type.split('-').join(' ')}
          </span>(s)
        </Typography>

        <Button 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            const applyTo = [ ...condition.applyTo ];

            applyTo.push('');

            updateCondition({
              ...condition,
              applyTo,
            });
          }}
        >
          Add Another
        </Button>
      </Toolbar>

      {condition.applyTo.map((item, i) => {
        return (
          <div className="apply-to-row" key={`apply-to-${i}`}>
            {condition.type.includes('field') ?
              <FormControl
                variant="outlined"
                margin="dense"
                className="day-text-field"
              >
                <InputLabel>
                  Field {i + 1}
                </InputLabel>
                <Select
                  value={item}
                  onChange={(e) => {
                    const applyTo = [ ...condition.applyTo ];

                    applyTo[i] = e.target.value;

                    updateCondition({
                      ...condition,
                      applyTo,
                    });
                  }}
                  label={`Field ${i + 1}`}
                >
                  {questions.map((q, i) => {
                    return <MenuItem key={q.internalId} value={q.internalId}>{q.label || `Question ${i + 1}`}</MenuItem>;
                  })}
                </Select>
              </FormControl> :
              <FormControl
                variant="outlined"
                margin="dense"
                className="day-text-field"
              >
                <InputLabel>
                  Page
                </InputLabel>
                <Select
                  value={item}
                  onChange={(e) => {
                    const applyTo = [ ...condition.applyTo ];

                    applyTo[i] = e.target.value;

                    updateCondition({
                      ...condition,
                      applyTo,
                    });
                  }}
                  label="Page"
                >
                  {pages.map((p, i) => {
                    return <MenuItem key={p.id} value={p.id}>Page {i + 1}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            }

            {condition.applyTo.length < 2 ? null :
              <div className="trash-container">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    const applyTo = [ ...condition.applyTo ];

                    applyTo.splice(i, 1);

                    updateCondition({
                      ...condition,
                      applyTo,
                    });
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            }
          </div>
        );
      })}
    </div>
  );
}

export default FormConditionEditor;
