import React from 'react';
import './hero-section.scss';

export const HeroSection = ({ data }) => {
  const {
    title,
    titleColor,
    content,
    contentColor,
    buttonText,
    buttonColor,
    buttonTextColor,
    linkText,
    backgroundColor,
    imageUrl,
    linkColor,
    linkTextColor,
  } = data;

  return (
    <section className="HeroContainer">
      <div className="heroImage">
        <img src={imageUrl} alt={imageUrl} />
      </div>
      <article className="wrapper" style={{ backgroundColor }}>
        <div className="heroContent">
          <h1 className="HeroTitle" style={{ color: titleColor }}>
            {title}
          </h1>
          <p className="HeroContent" style={{ color: contentColor }}>
            {content}
          </p>
          <div className="buttonsContainer">
            <button
              className="button"
              style={{
                border: `2px solid ${buttonColor}`,
                color: buttonTextColor,
              }}
            >
              {buttonText}
            </button>

            <button
              className="button"
              style={{
                border: `2px solid ${linkColor}`,
                backgroundColor: linkColor,
                color: linkTextColor,
              }}
            >
              {linkText}
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};
