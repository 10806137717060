import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

function OneClickUpsell() {
  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar>
          <Typography variant="h6">
            One Click Upsell
          </Typography>
        </Toolbar>
      </Card>
    </div>
  );
}

export default OneClickUpsell;
