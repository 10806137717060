import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';
import '../editProduct.scss';


import React from 'react'
import { Column } from './column';

export const TYPE_COLUMNS = ['image', 'title-content', 'title-content-orderlist', 'video']

const imageType = {
    image: ''
}

const titleContentType = {
    title: '',
    content: ''
}

const titleContentOrderListType = {
    title: '',
    content: '',
    list: []
}

const setColumnType = (type) => {
    switch (type) {
        case TYPE_COLUMNS[0]:
            return imageType
        case TYPE_COLUMNS[1]:
            return titleContentType
        case TYPE_COLUMNS[2]:
            return titleContentOrderListType
        case TYPE_COLUMNS[3]:
            return videoType
        default:
            return {}
    }
}

const videoType = {
    video: ''
}

const formatLabelOPtions = (type) => {
    switch (type) {
        case TYPE_COLUMNS[0]:
            return 'Image'
        case TYPE_COLUMNS[1]:
            return 'Title & Content'
        case TYPE_COLUMNS[2]:
            return 'Title & Content & Order List'
        case TYPE_COLUMNS[3]:
            return 'Video'
        default:
            return {}
    }
}

export const TabsContent = ({ product, setProduct }) => {


    return (
        <div className="row">
            <div className="section-container">
                <div className="section-label">Tabs Content</div>
                <FormControlLabel
                    style={{ marginTop: 8, marginBottom: 4 }}
                    control={
                        <Switch
                            checked={product.enableTabsContent || false}
                            onChange={(e) => {
                                setProduct({
                                    ...product,
                                    enableTabsContent: e.target.checked,
                                });

                            }}
                            name="hasCustomThankYouPage"
                            color="primary"
                        />
                    }
                    label="Show Tabs Content Component"
                />
                <div className="section-container">
                    <div className="section-label">Description</div>
                    <div className="section-container">
                        <div className="section-label">Column 1</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[0].firstColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {

                                    const text = e.target.value

                                    const newTabType = setColumnType(text)

                                    console.log({ newTabType })
                                    const newDescriptionTab = {
                                        ...product.tabsContent[0],
                                        firstColumn: { ...newTabType, type: text }

                                    };
                                    const restProduct = product.tabsContent.splice(1, 2)

                                    setProduct({
                                        ...product,
                                        tabsContent: [newDescriptionTab, ...restProduct],
                                    })
                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[0].firstColumn.type}
                                column={product.tabsContent[0].firstColumn}
                                onChange={(value) => setProduct({
                                    ...product,
                                    tabsContent: [{
                                        ...product.tabsContent[0],
                                        firstColumn: { ...value, type: product.tabsContent[0].firstColumn.type }
                                    }, ...product.tabsContent.splice(1, 2)]
                                })} />
                        </FormControl>
                    </div>
                    <div className="section-container">
                        <div className="section-label">Column 2</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[0].secondColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {
                                    const text = e.target.value
                                    const newTabType = setColumnType(text)
                                    const newDescriptionTab = {
                                        ...product.tabsContent[0],
                                        secondColumn: { ...newTabType, type: text }
                                    };
                                    const restProduct = product.tabsContent.splice(1, 2)

                                    setProduct({
                                        ...product,
                                        tabsContent: [newDescriptionTab, ...restProduct],
                                    })
                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[0].secondColumn.type}
                                column={product.tabsContent[0].secondColumn}
                                onChange={(value) => setProduct({
                                    ...product,
                                    tabsContent: [{
                                        ...product.tabsContent[0],
                                        secondColumn: { ...value, type: product.tabsContent[0].secondColumn.type }
                                    },
                                    ...product.tabsContent.splice(1, 2)]
                                })} />
                        </FormControl>
                    </div>
                </div>

                <div className="section-container">
                    <div className="section-label">Nutritional Facts</div>
                    <div className="section-container">
                        <div className="section-label">Column 1</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[1].firstColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {

                                    const text = e.target.value

                                    const newTabType = setColumnType(text)

                                    console.log({ newTabType })
                                    const newDescriptionTab = {
                                        ...product.tabsContent[1],
                                        firstColumn: { ...newTabType, type: text }

                                    };
                                    setProduct({
                                        ...product,
                                        tabsContent: [product.tabsContent[0], newDescriptionTab, product.tabsContent[2]],
                                    })

                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[1].firstColumn.type}
                                column={product.tabsContent[1].firstColumn}
                                onChange={(value) => {
                                    setProduct({
                                        ...product,
                                        tabsContent: [
                                            product.tabsContent[0],
                                            {
                                                ...product.tabsContent[1],
                                                firstColumn: { ...value, type: product.tabsContent[1].firstColumn.type }
                                            },
                                            product.tabsContent[2]]
                                    })
                                }} />
                        </FormControl>
                    </div>
                    <div className="section-container">
                        <div className="section-label">Column 2</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[1].secondColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {
                                    const text = e.target.value
                                    const newTabType = setColumnType(text)
                                    const newDescriptionTab = {
                                        ...product.tabsContent[1],
                                        secondColumn: { ...newTabType, type: text }

                                    };
                                    setProduct({
                                        ...product,
                                        tabsContent: [product.tabsContent[0], newDescriptionTab, product.tabsContent[2]],
                                    })
                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[1].secondColumn.type}
                                column={product.tabsContent[1].secondColumn}
                                onChange={(value) => setProduct({
                                    ...product,
                                    tabsContent: [
                                        product.tabsContent[0],
                                        {
                                            ...product.tabsContent[1],
                                            secondColumn: { ...value, type: product.tabsContent[1].secondColumn.type }
                                        },
                                        product.tabsContent[2]]
                                })} />
                        </FormControl>
                    </div>
                </div>


                <div className="section-container">
                    <div className="section-label">How to use</div>
                    <div className="section-container">
                        <div className="section-label">Column 1</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[2].firstColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {

                                    const text = e.target.value

                                    const newTabType = setColumnType(text)

                                    const newDescriptionTab = {
                                        ...product.tabsContent[2],
                                        firstColumn: { ...newTabType, type: text }

                                    };
                                    setProduct({
                                        ...product,
                                        tabsContent: [product.tabsContent[0], product.tabsContent[1], newDescriptionTab],
                                    })

                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[2].firstColumn.type}
                                column={product.tabsContent[2].firstColumn}
                                onChange={(value) => {
                                    setProduct({
                                        ...product,
                                        tabsContent: [
                                            product.tabsContent[0],
                                            product.tabsContent[1],
                                            {
                                                ...product.tabsContent[2],
                                                firstColumn: { ...value, type: product.tabsContent[2].firstColumn.type }
                                            }
                                        ]
                                    })
                                }} />
                        </FormControl>
                    </div>
                    <div className="section-container">
                        <div className="section-label">Column 2</div>
                        <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={product.tabsContent[2].secondColumn.type.toLowerCase() || 'image'}
                                onChange={(e) => {
                                    const text = e.target.value
                                    const newTabType = setColumnType(text)

                                    const newDescriptionTab = {
                                        ...product.tabsContent[2],
                                        secondColumn: { ...newTabType, type: text }

                                    };
                                    setProduct({
                                        ...product,
                                        tabsContent: [product.tabsContent[0], product.tabsContent[1], newDescriptionTab],
                                    })
                                }}
                                margin="dense"
                                label="Align Text"
                            >
                                {TYPE_COLUMNS.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {formatLabelOPtions(category)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Column
                                type={product.tabsContent[2].secondColumn.type}
                                column={product.tabsContent[2].secondColumn}
                                onChange={(value) => setProduct({
                                    ...product,
                                    tabsContent: [
                                        product.tabsContent[0],
                                        product.tabsContent[1],
                                        {
                                            ...product.tabsContent[2],
                                            secondColumn: { ...value, type: product.tabsContent[2].secondColumn.type }
                                        },
                                    ]
                                })} />
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}


