import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';

import './courseSections.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const uuidv4 = require('uuid/v4');

function CourseSections({ sections, updateSections, modules }) {
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [section, setSection] = useState({
    id: '',
    name: '',
    imageUrl: '',
  });
  const [sectionIndex, setSectionIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const moveItemUp = (i) => {
    const updatedSections = [ ...sections ];
    const itemToMoveUp = updatedSections[i];
    const itemToMoveDown = updatedSections[i - 1];
    updatedSections[i] = itemToMoveDown;
    updatedSections[i - 1] = itemToMoveUp;
    updateSections(updatedSections);
  };

  const moveItemDown = (i) => {
    const updatedSections = [ ...sections ];
    const itemToMoveUp = updatedSections[i + 1];
    const itemToMoveDown = updatedSections[i];
    updatedSections[i + 1] = itemToMoveDown;
    updatedSections[i] = itemToMoveUp;
    updateSections(updatedSections);
  };

  const renderReorderArrows = (i) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemUp(i) }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    if (i !== sections.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveItemDown(i) }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    return arrowItems;
  };

  return (
    <div className="CourseSections">
      <div className="section-container">
        <div className="section-label">Sections</div>

        <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: 10}}>
          <Button
            variant="contained"
            onClick={() => {
              setSection({
                id: uuidv4(),
                name: '',
                imageUrl: '',
              });
              setSectionIndex(sections.length);
            }}
            color="primary"
          >
            Add Section
          </Button>
        </div>

        <List>
          {sections.map((s, i) => {
            return (
              <div key={`section-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={s.name} secondary={s.imageUrl} />

                  {renderReorderArrows(i)}

                  <IconButton
                    color="inherit"
                    aria-label="Edit"
                    onClick={() => {
                      setSection({
                        ...s,
                      });
                      setSectionIndex(i);
                    }}
                  >
                    <EditIcon style={{color: '#000'}} />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      let modulesInSection = 0;

                      for (let i = 0; i < modules.length; i++) {
                        const moduleSection = modules[i].section || '';

                        if (moduleSection === s.id) {
                          modulesInSection++;
                        }
                      }

                      if (modulesInSection) {
                        setModalTitle('Notice!');
                        setModalText(`This section has ${modulesInSection} modules in it. To delete this section, first remove these modules from it.`);
                        return;
                      }

                      setDeleteIndex(i);
                    }}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={sectionIndex !== -1}
        onClose={() => setSectionIndex(-1)}
        TransitionComponent={Transition}
      >
        <DialogTitle>{sectionIndex === sections.length ? 'Add' : 'Edit'} Section</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={section.name}
            onChange={(e) => {
              setSection({
                ...section,
                name: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Image URL"
            value={section.imageUrl}
            onChange={(e) => {
              setSection({
                ...section,
                imageUrl: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const updatedSections = [ ...sections ];
              updatedSections[sectionIndex] = {
                ...section,
              };

              updateSections(updatedSections);
              setSectionIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setSectionIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Section?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {deleteIndex === -1 ? 'this section?' : <strong>{sections[deleteIndex].name}</strong>}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              const updatedSections = [ ...sections ];
              updatedSections.splice(deleteIndex, 1);
              updateSections(updatedSections);
              setDeleteIndex(-1);
            }}
            color="secondary"
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseSections;
