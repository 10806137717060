import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Button,
  List,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './feedContent.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FeedContent(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [items, setItems] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchFeedContent();
  }, []);

  const fetchFeedContent = async () => {
    try {
      const querySnapshot = await firebase.firestore().collection('home-screen-feed-content').get();
      const items = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).sort((a, b) => a.created - b.created);

      setItems(items);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving feed content, please try again.');
    }
  };

  const addNew = async () => {
    setLoading(true);

    try {
      const doc = await firebase.firestore().collection('home-screen-feed-content').add({
        title: '',
        live: true,
        imageUrl: '',
        type: 'external-url',
        resourceId: '',
        externalUrl: '',
        created: Date.now(),
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
      props.history.push(`/website-content/feed-content/${doc.id}`);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the feed content, please try again.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);

    try {
      const updatedItems = [...items];
      const id = updatedItems[deleteIndex].id;

      await firebase.firestore().collection('home-screen-feed-content').doc(id).delete();

      updatedItems.splice(deleteIndex, 1);

      setItems(updatedItems);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting the feed content, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <div className="FeedContent">
      {renderLoading()}
      <Card className="drag-card">
        <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Feed Content
          </Typography>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={addNew}
              style={{ marginRight: '10px' }}
            >
              Add New
            </Button>
          </div>
        </Toolbar>
        <List>
          {items.map((item, i) => {
            return (
              <div key={item.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={item.title || 'Title not set'}
                    secondary={
                      <span>
                        <strong>ID:</strong> {item.id}
                        <strong style={{ marginLeft: 10 }}>Status:</strong> {item.live ? <span style={{ color: 'rgb(126, 210, 66)' }}>Live</span> : <span style={{ color: 'red' }}> Inactive</span>}
                      </span>
                    }
                  />
                  <Link edge="start" to={`/website-content/feed-content/${item.id}`} style={{ color: '#000', textDecoration: 'none', marginRight: 10 }}>
                    <IconButton edge="start" color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton onClick={() => {
                    setDeleteIndex(i);
                  }} edge="start" color="inherit" aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex !== -1} onClose={() => setDeleteIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Delete Feed Content?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this feed content item?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => setDeleteIndex(-1)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FeedContent;
