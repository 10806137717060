import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { Chart } from 'react-google-charts';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './subscriptionRetention.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const end = moment().endOf('month').toDate();
end.setHours(23, 59, 59, 999);

const start = moment().subtract(1, 'month').startOf('month').toDate();
start.setHours(0, 0, 0, 0);

function SubscriptionRetention({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [rates, setRates] = useState({
    digital: [],
    physical: [],
    all: [],
    averageSubscriptionLength: 0,
    averageDigitalSubscriptionLength: 0,
    averagePhysicalSubscriptionLength: 0,
  });
  const [allFiltered, setAllFiltered] = useState([]);
  const [digitalFiltered, setDigitalFiltered] = useState([]);
  const [physicalFiltered, setPhysicalFiltered] = useState([]);

  useEffect(() => {
    getRetentionData();
  }, []);

  useEffect(() => {
    const dates = [];
    let start = moment(endDate).startOf('month');

    while (start.toDate() >= startDate) {
      dates.push(start.valueOf());
      start = start.subtract(1, 'month').startOf('month');
    }

    const { all, digital, physical } = rates;

    const allRates = [];
    const digitalRates = [];
    const physicalRates = [];

    all.forEach((item, i) => {
      if (all[i + 1]) {
        const nextItem = all[i + 1];
        const updatedMonth = moment(nextItem.month).add(10, 'days').startOf('month').valueOf();
        const displayedTimeFrame = `${moment(item.month).add(10, 'days').format('MMM yyyy')} - ${moment(nextItem.month).add(10, 'days').format('MMM yyyy')}`;

        if (dates.includes(updatedMonth)) {
          allRates.push({
            displayedTimeFrame,
            retentionRate: +(((nextItem.active - nextItem.new) / item.active) * 100).toFixed(2),
          });
        }
      }
    });

    digital.forEach((item, i) => {
      if (digital[i + 1]) {
        const nextItem = digital[i + 1];
        const updatedMonth = moment(nextItem.month).add(10, 'days').startOf('month').valueOf();
        const displayedTimeFrame = `${moment(item.month).add(10, 'days').format('MMM yyyy')} - ${moment(nextItem.month).add(10, 'days').format('MMM yyyy')}`;

        if (dates.includes(updatedMonth)) {
          digitalRates.push({
            displayedTimeFrame,
            retentionRate: +(((nextItem.active - nextItem.new) / item.active) * 100).toFixed(2),
          });
        }
      }
    });

    physical.forEach((item, i) => {
      if (physical[i + 1]) {
        const nextItem = physical[i + 1];
        const updatedMonth = moment(nextItem.month).add(10, 'days').startOf('month').valueOf();
        const displayedTimeFrame = `${moment(item.month).add(10, 'days').format('MMM yyyy')} - ${moment(nextItem.month).add(10, 'days').format('MMM yyyy')}`;

        if (dates.includes(updatedMonth)) {
          physicalRates.push({
            displayedTimeFrame,
            retentionRate: +(((nextItem.active - nextItem.new) / item.active) * 100).toFixed(2),
            periodDate: nextItem
          });
        }
      }
    });

    setAllFiltered(allRates);
    setDigitalFiltered(digitalRates);
    setPhysicalFiltered(physicalRates);
  }, [rates, startDate, endDate]);

  const getRetentionData = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/subscriptions/retention`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setRates(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred. Please refresh and try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SubscriptionRetention">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Subscription Retention
          </Typography>
        </Toolbar>

        <Divider/>

        <div className="content">
          <div className="card-row">
            <div className="card-container">
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">
                    Avg. Subscription Length
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="h6">
                    {moment.duration(rates.averageSubscriptionLength || 0, 'milliseconds').asDays().toFixed(1)} days
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <div className="card-container">
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">
                    Avg. Physical Subscription Length
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="h6">
                    {moment.duration(rates.averagePhysicalSubscriptionLength || 0, 'milliseconds').asDays().toFixed(1)} days
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <div className="card-container">
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">
                    Avg. Digital Subscription Length
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="h6">
                    {moment.duration(rates.averageDigitalSubscriptionLength || 0, 'milliseconds').asDays().toFixed(1)} days
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>

          <div style={{display: 'flex', position: 'relative', zIndex: 1000, marginBottom: 30}}>
            <div className="date-picker-container">
              <Typography>
                <strong>
                  <small>Start</small>
                </strong>
              </Typography>
              <DatePicker
                popperPlacement="top-start"
                selected={startDate}
                onChange={(date) => {
                  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  firstDay.setHours(0, 0, 0, 0);
                  setStartDate(firstDay);
                }}
                maxDate={end}
              />
            </div>
            <div className="date-divider" style={{marginTop: 14}}>-</div>
            <div className="date-picker-container">
              <Typography>
                <strong>
                  <small>End</small>
                </strong>
              </Typography>
              <DatePicker
                popperPlacement="top-end"
                selected={endDate}
                onChange={(date) => {
                  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  lastDay.setHours(23, 59, 59, 999);
                  setEndDate(lastDay);
                }}
                maxDate={end}
              />
            </div>
          </div>

          <Chart
            width={'100%'}
            height={'500'}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={[
              [
                'Date Range',
                'Retention Rate All Products',
              ],
              ...allFiltered.map(d => [d.displayedTimeFrame, d.retentionRate]),
            ]}
            options={{
              legend: {
                position: 'none',
              },
              chart: {
                title:
                  'Retention Rate All Products',
              },
              width: '100%',
              height: 500,
              series: {
                0: { axis: 'Retention Rate All Products' },
              },
              axes: {
                y: {
                  'Retention Rate All Products': { label: 'Retention Rate All Products' },
                },
              },
            }}
            rootProps={{ 'data-testid': '4' }}
          />

          <div style={{margin: '40px 0'}}>
            <Divider/>
          </div>

          <Chart
            width={'100%'}
            height={'500'}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={[
              [
                'Date Range',
                'Retention Rate Physical Products',
              ],
              ...physicalFiltered.map(d => [d.displayedTimeFrame, d.retentionRate]),
            ]}
            options={{
              legend: {
                position: 'none',
              },
              chart: {
                title:
                  'Retention Rate Physical Products',
              },
              width: '100%',
              height: 500,
              series: {
                0: { axis: 'Retention Rate Physical Products' },
              },
              axes: {
                y: {
                  'Retention Rate Physical Products': { label: 'Retention Rate Physical Products' },
                },
              },
            }}
            rootProps={{ 'data-testid': '4' }}
          />

          <div style={{margin: '40px 0'}}>
            <Divider/>
          </div>

          <Chart
            width={'100%'}
            height={'500'}
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={[
              [
                'Date Range',
                'Retention Rate Digital Products',
              ],
              ...digitalFiltered.map(d => [d.displayedTimeFrame, d.retentionRate]),
            ]}
            options={{
              legend: {
                position: 'none',
              },
              chart: {
                title:
                  'Retention Rate Digital Products',
              },
              width: '100%',
              height: 500,
              series: {
                0: { axis: 'Retention Rate Digital Products' },
              },
              axes: {
                y: {
                  'Retention Rate Digital Products': { label: 'Retention Rate Digital Products' },
                },
              },
            }}
            rootProps={{ 'data-testid': '4' }}
          />
        </div>
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionRetention;
