export function getColorCoordinates(color, width, height) {
  const { s, v } = color.hsv;

  const x = (s / 100) * width;
  const y = ((100 - v) / 100) * height;

  return [x, y];
}

export function getHueCoordinates(h, width) {
  const x = (h / 360) * width;

  return x;
}

export function getAlphaCoordinates(a, width) {
  const x = a * width;

  return x;
}
