import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Card,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';

import './reviews.scss';
import { config } from '../../../config';

const pageCount = 10;

function Reviews(props) {
  const [loading, setLoading] = useState(true);
  const [reviewData, setReviewData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [updatingReview, setUpdatingReview] = useState(false);
  const [editCommentIndex, setEditCommentIndex] = useState(-1);
  const [editComment, setEditComment] = useState({
    content: '',
    name: 'The Pupford Team',
  });
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [rewardsProgramSettings, setRewardsProgramSettings] = useState({});
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async (showDeletedMessage) => {
    const db = firebase.firestore();

    try {
      const dataSnapshot = await db.collection('product-reviews').doc('data').get();
      const reviewsSnapshot = await db.collection('product-reviews').orderBy('created', 'desc').limit(pageCount).get();
      const rewardsProgramSnapshot = await db.collection('config').doc('rewards').get();

      const reviewsData = dataSnapshot.data();
      const reviewPages = Math.ceil(reviewsData.count / 10);

      const mappedReviews = reviewsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setNumberOfPages(reviewPages);
      setReviewData(reviewsData);
      setReviews(mappedReviews);
      setFirst(reviewsSnapshot.docs[0]);
      setLast(reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]);
      setRewardsProgramSettings(rewardsProgramSnapshot.data() || {});
      setLoading(false);

      if (showDeletedMessage) {
        setModalTitle('Success!');
        setModalText('The review has been successfully deleted.');
      }
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving review data. Refresh the page and try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let reviewsSnapshot;
      let newPage = page;

      if (value === 'next') {
        reviewsSnapshot = await db.collection('product-reviews').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        reviewsSnapshot = await db.collection('product-reviews').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedReviews = reviewsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setReviews(mappedReviews);
      setFirst(reviewsSnapshot.docs[0]);
      setLast(reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving review data. Please try again.');
    }
  };

  const updateReview = async (reviewId, type, status, i, userId) => {
    if (updatingReview) {
      return;
    }

    setUpdatingReview(true);

    try {
      await firebase.firestore().collection('product-reviews').doc(reviewId).update({
        [type]: !status,
      });

      const reviewsCopy = [ ...reviews ];
      const review = {
        ...reviewsCopy[i],
        [type]: !status,
      };
      
      let shouldCheckUserPoints = false;

      reviewsCopy[i] = review;

      if (type === 'live') {
        const data = { ...reviewData[review.productId] };
        const oldAverage = data.liveAverage;

        if (status) {
          const newTotal = data.liveCount - 1;
          data.liveCount = newTotal;
          data.liveAverage = newTotal === 0 ? 0 : ((oldAverage * (newTotal + 1)) - review.rating) / (newTotal);
        } else {
          const newTotal = data.liveCount + 1;
          data.liveCount = newTotal;
          data.liveAverage = oldAverage + ((review.rating - oldAverage) / newTotal);

          if (rewardsProgramSettings && rewardsProgramSettings.enabled && rewardsProgramSettings.pointsPerReview && rewardsProgramSettings.dollarsPerReviewPoint) {
            shouldCheckUserPoints = true;
          }
        }

        await firebase.firestore().collection('product-reviews').doc('data').update({
          [review.productId]: data,
        });

        setReviewData({
          ...reviewData,
          [review.productId]: data
        });
      }

      setReviews(reviewsCopy);
      setUpdatingReview(false);

      if (shouldCheckUserPoints) {
        checkAndUpdateUserPoints(reviewId, userId);
      }
    } catch (e) {
      setUpdatingReview(false);
      setModalTitle('Error:');
      setModalText('An error occurred updating the comment. Please try again.');
    }
  };

  const checkAndUpdateUserPoints = async (reviewId, userId) => {
    try {
      const userSnapshot = await firebase.firestore().collection('users').doc(userId).get();
      const user = userSnapshot.data();

      if (!user) {
        return;
      }

      const userRewardsSnapshot = await firebase.firestore().collection('user-rewards').doc(userId).get();
      const userRewardsData = userRewardsSnapshot.data() || {
        earnings: [],
        spent: [],
      };

      let hasReceivedRewardForReview = false;

      userRewardsData.earnings.forEach(earning => {
        if (earning.reviewId && earning.reviewId === reviewId) {
          hasReceivedRewardForReview = true;
        }
      });

      if (hasReceivedRewardForReview) {
        return;
      }

      userRewardsData.earnings.push({
        id: `earnings-${userRewardsData.earnings.length}`,
        type: 'review',
        reviewId,
        points: rewardsProgramSettings.pointsPerReview,
        dollarsPerPoint: rewardsProgramSettings.dollarsPerReviewPoint,
        created: moment().valueOf(),
        expires: null,
      });

      await firebase.firestore().collection('user-rewards').doc(userId).set(userRewardsData);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('An error occurred checking user points.');
    }
  };

  const updateComment = (comment, i) => {
    if (updatingReview) {
      return;
    }

    if (!comment) {
      setEditComment({
        content: '',
        name: 'The Pupford Team',
      });
    } else {
      setEditComment({ ...comment });
    }

    setEditCommentIndex(i);
  };

  const submitComment = async () => {
    setLoading(true);

    try {
      const commentData = {
        content: editComment.content,
        name: editComment.name,
        created: editComment.created || Date.now(),
        updated: Date.now(),
        image: editComment.image || '',
        lastUpdatedBy: firebase.auth().currentUser.email, 
      };

      await firebase.firestore().collection('product-reviews').doc(reviews[editCommentIndex].id).update({
        comment: commentData,
      });

      const reviewsCopy = [ ...reviews ];
      const review = {
        ...reviewsCopy[editCommentIndex],
        comment: commentData,
      };

      reviewsCopy[editCommentIndex] = review;

      setReviews(reviewsCopy);
      setLoading(false);
      setEditCommentIndex(-1);
      setModalTitle('Success!');
      setModalText('Comment has been submitted.');
    } catch (e) {
      setLoading(false);
      setEditCommentIndex(-1);
      setModalTitle('Error:');
      setModalText('An error occurred saving the comment. Please try again.');
    }
  };

  const deleteReview = async () => {
    setLoading(true);

    try {
      const review = reviews[currentDeleteIndex];
      await firebase.firestore().collection('product-reviews').doc(review.id).delete();

      const reviewSummarySnapshot = await firebase.firestore().collection('product-reviews').doc('data').get();
      const reviewSummary = reviewSummarySnapshot.data();

      const count = reviewSummary.count - 1;
      let productReviewSummary = reviewSummary[review.productId];

      if (!productReviewSummary) {
        setLoading(false);
        setCurrentDeleteIndex(-1);
        setModalTitle('Error:');
        setModalText('An error occurred deleting the comment. Please try again.');
        return;
      } else {
        const newTotal = productReviewSummary.total - 1;
        const oldAverage = productReviewSummary.totalAverage;

        productReviewSummary.new = productReviewSummary.new - 1;
        productReviewSummary.total = newTotal;
        productReviewSummary.totalAverage = oldAverage + ((review.rating - oldAverage) / newTotal);
        productReviewSummary.totalAverage = newTotal === 0 ? 0 : ((oldAverage * (newTotal + 1)) - review.rating) / (newTotal);
      }

      const valuesToUpdate = {
        count,
      };

      valuesToUpdate[review.productId] = productReviewSummary;

      await firebase.firestore().collection('product-reviews').doc('data').update(valuesToUpdate);

      setCurrentDeleteIndex(-1);
      setPage(1);
      fetchReviews(true);
    } catch (e) {
      setLoading(false);
      setCurrentDeleteIndex(-1);
      setModalTitle('Error:');
      setModalText('An error occurred deleting the comment. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Reviews">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Product Reviews
          </Typography>
        </Toolbar>

        <List>
          {reviews.map((r, i) => {
            return (
              <div key={`upsell-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={r.user.name}
                    secondary={
                      <span>
                        <span style={{marginTop: 5, marginBottom: 5, display: 'block', marginRight: 10}}>
                          <strong>{r.title.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}</strong>
                        </span>

                        <span style={{marginRight: 10}}>
                          {r.content.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}
                        </span>

                        <span style={{marginTop: 10, display: 'block', marginRight: 10}}>
                          <span>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Status:</span> <span style={{color: r.live ? 'rgb(126, 210, 66)' : 'red', marginRight: 10}}>{r.live ? 'Live' : 'Pending'}</span>
                            </span>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Rating:</span> <span style={{marginRight: 10}}>{r.rating}</span>
                            </span>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Email Verified:</span> <span style={{marginRight: 10}}>{r.emailVerified ? 'Yes': 'No'}</span>
                            </span>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Date:</span> <span style={{marginRight: 10}}>{moment(r.created).format('MMMM Do YYYY, h:mm a')}</span>
                            </span>
                            <span>
                              <span style={{fontWeight: 'bold'}}>Product:</span> <span style={{marginRight: 10}}>{r.productId}</span>
                            </span>
                          </span>
                        </span>

                        {!r.comment ? null :
                          <span style={{marginTop: 10, display: 'block', marginRight: 10, paddingLeft: 20, borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}>
                            <span style={{marginTop: 10, marginBottom: 5, display: 'block', marginRight: 10}}>
                              <span style={{fontWeight: 'bold'}}>Response:</span>
                            </span>
                            <span style={{marginBottom: 10, display: 'block', marginRight: 10}}>
                              <span style={{}}>{r.comment.content.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}</span>
                            </span>
                            <span>
                              <span>
                                <span style={{fontWeight: 'bold'}}>By:</span> <span style={{marginRight: 10}}>{r.comment.name}</span>
                              </span>
                              <span>
                                <span style={{fontWeight: 'bold'}}>Date:</span> <span style={{marginRight: 10}}>{moment(r.comment.created).format('MMMM Do YYYY, h:mm a')}</span>
                              </span>
                            </span>
                          </span>
                        }
                      </span>
                    }
                  />
                  <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', minWidth: 150, alignSelf: 'start', marginTop: 10, marginBottom: 10 }}>
                    <Button style={{marginBottom: 10}} variant="contained" onClick={() => { updateReview(r.id, 'live', r.live, i, r.userId) }} size="small" disabled={updatingReview}>
                      {r.live ? 'Mark Pending' : 'Mark Live'}
                    </Button>
                    <Button style={{marginBottom: 10}} variant="contained" onClick={() => { updateReview(r.id, 'emailVerified', r.emailVerified, i, r.userId) }} size="small" disabled={updatingReview}>
                      {r.emailVerified ? 'Invalidate Email' : 'Verify Email'}
                    </Button>
                    <Button style={{marginBottom: 10}} variant="contained" onClick={() => { updateComment(r.comment, i) }} color="primary" size="small" disabled={updatingReview}>
                      {r.comment ? 'Edit Comment' : 'Comment'}
                    </Button>
                    {r.live ? null :
                      <Button style={{marginBottom: 10}} variant="contained" onClick={() => { setCurrentDeleteIndex(i) }} color="secondary" size="small" disabled={updatingReview}>
                        Delete
                      </Button>
                    }
                  </div>
                </ListItem>
              </div>
            );
          })}
        </List>

        <Divider/>

        {!numberOfPages ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={currentDeleteIndex !== -1} onClose={() => { setCurrentDeleteIndex(-1) }}>
        <DialogTitle>Delete Review?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the review from <strong>{currentDeleteIndex === -1 ? null : reviews[currentDeleteIndex].user.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteReview} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setCurrentDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editCommentIndex !== -1} onClose={() => { setEditCommentIndex(-1) }}>
        <DialogTitle>{reviews[editCommentIndex] && reviews[editCommentIndex].comment ? 'Edit' : 'Add'} Comment</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <TextField
              label="Display Name"
              value={editComment.name}
              onChange={(e) => {
                setEditComment({
                  ...editComment,
                  name: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Comment"
              value={editComment.content}
              onChange={(e) => {
                setEditComment({
                  ...editComment,
                  content: e.target.value,
                });
              }}
              margin="dense"
              multiline
              rows="8"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 20}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { submitComment() }} color="primary" disabled={!editComment.name || !editComment.content}>
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setEditCommentIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Reviews;
