import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import {
  CircularProgress,
  Button,
  Toolbar,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Tabs,
  Tab,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ColorPicker, useColor, toColor } from '../../../shared/colorPalette';
import '../../../shared/colorPalette/css/styles.css';

import './form.scss';
import { defaultPage } from './defaultPage';
import { formTypesDataMap } from './formTypesDataMap';
import FormHeader from './formItems/formHeader';
import FormParagraph from './formItems/formParagraph';
import FormTextInput from './formItems/formTextInput';
import FormDateInput from './formItems/formDateInput';
import FormSelectDropdown from './formItems/formSelectDropdown';
import FormMultiSelect from './formItems/formMultiSelect';
import FormSingleSelect from './formItems/formSingleSelect';
import FormRating from './formItems/formRating';
import FormFileUpload from './formItems/formFileUpload';
import FormConditions from './formConditions/formConditions';
import FormActions from './formActions/formActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Form(props) {
  const swipeableViewsRef = useRef(null);
  const [addItemAnchorEl, setAddItemAnchorEl] = useState(null);
  const [pageColorAnchorElem, setPageColorAnchorElem] = useState(null);
  const [formColorAnchorElem, setFormColorAnchorElem] = useState(null);
  const [inputColorAnchorElem, setInputColorAnchorElem] = useState(null);
  const [fontColorAnchorElem, setFontColorAnchorElem] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [form, setForm] = useState({
    title: '',
    description: '',
    path: '',
    content: [{
      ...defaultPage,
      id: `${moment().valueOf()}`,
    }],
    conditions: [],
    actions: [],
    live: false,
    requireAuth: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageDeleteIndex, setPageDeleteIndex] = useState(-1);
  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(-1);
  const [backgroundColor, setBackgroundColor] = useColor('hex', '#fafafa');
  const [formBackgroundColor, setFormBackgroundColor] = useColor('hex', '#ffffff');
  const [inputBackground, setInputBackground] = useColor('hex', '#ffffff');
  const [fontColor, setFontColor] = useColor('hex', '#000000');

  useEffect(() => {
    if (swipeableViewsRef.current) {
      setTimeout(() => {
        swipeableViewsRef.current
          .getChildContext()
          .swipeableViews.slideUpdateHeight();
      }, 10);
    }
  }, [tabValue, form, currentPageIndex]);

  useEffect(() => {
    fetchForm();
  }, []);

  const fetchForm = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/marketing-tools/forms');
        return;
      }

      const querySnapshot = await db.collection('forms').doc(id).get();
      const data = querySnapshot.data();
      const formData = {
        ...data,
        id: querySnapshot.id,
      };

      if (formData.content === '') {
        formData.content = [{
          ...defaultPage,
          id: `${moment().valueOf()}`,
        }];
      } else if (typeof formData.content === 'string') {
        formData.content = JSON.parse(formData.content);
      }

      const firstPage = formData.content[0];

      setBackgroundColor(toColor('hex', firstPage.backgroundColor));
      setFormBackgroundColor(toColor('hex', firstPage.formBackgroundColor));
      setInputBackground(toColor('hex', firstPage.inputBackground));
      setFontColor(toColor('hex', firstPage.fontColor));
      setForm(formData);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching this form. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const saveForm = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingFormQuerySnapshot = await db.collection('forms').where('path', '==', form.path).get();

      if (existingFormQuerySnapshot.docs.length && existingFormQuerySnapshot.docs[0].id !== form.id) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing form with the same URL path.');
        return;
      }

      await db.collection('forms').doc(form.id).update({
        title: form.title,
        description: form.description,
        path: form.path,
        live: form.live,
        requireAuth: form.requireAuth,
        content: !form.content.length ? '' : JSON.stringify(form.content),
        conditions: form.conditions,
        actions: form.actions || [],
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred saving the form. Please try again.');
    }

    setLoading(false);
  };

  const copyPage = (i) => {
    const pagesCopy = [ ...form.content ];

    const pageClone = cloneDeep(pagesCopy[i]);

    pagesCopy.push({
      ...pageClone,
      questions: [],
      id: `${moment().valueOf()}`,
    });

    setForm({
      ...form,
      content: pagesCopy,
    });
  };

  const addFormItem = (type) => {
    setAddItemAnchorEl(null);

    const pagesCopy = [ ...form.content ];

    const question = {
      type,
      internalId: `${moment().valueOf()}`,
      ...formTypesDataMap[type],
    };

    pagesCopy[currentPageIndex] = {
      ...pagesCopy[currentPageIndex],
      questions: [
        ...pagesCopy[currentPageIndex].questions,
        question,
      ],
    };

    setForm({
      ...form,
      content: pagesCopy,
    });
  };

  const handleQuestionDataChange = (data, i) => {
    const pagesCopy = [ ...form.content ];
    const questions = [ ...pagesCopy[currentPageIndex].questions ];

    questions[i] = data;

    pagesCopy[currentPageIndex] = {
      ...pagesCopy[currentPageIndex],
      questions,
    };

    setForm({
      ...form,
      content: pagesCopy,
    });
  };

  const renderQuestion = (item, i) => {
    switch (item.type) {
      case 'heading':
        return <FormHeader
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'paragraph':
        return <FormParagraph
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'text-input':
        return <FormTextInput
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'date':
        return <FormDateInput
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'select-dropdown':
        return <FormSelectDropdown
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'multi-select':
        return <FormMultiSelect
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'single-select':
        return <FormSingleSelect
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'rating':
        return <FormRating
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      case 'file-upload':
        return <FormFileUpload
          fields={item}
          onChange={(data) => handleQuestionDataChange(data, i)}
        />;
      default:
        return null;
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const addItemOpen = Boolean(addItemAnchorEl);
  const addItemId = addItemOpen ? 'add-item-popover' : undefined;
  const pageColorOpen = Boolean(pageColorAnchorElem);
  const pageColorId = pageColorOpen ? 'page-color-popover' : undefined;
  const formColorOpen = Boolean(formColorAnchorElem);
  const formColorId = formColorOpen ? 'form-color-popover' : undefined;
  const inputColorOpen = Boolean(inputColorAnchorElem);
  const inputColorId = inputColorOpen ? 'input-color-popover' : undefined;
  const fontColorOpen = Boolean(fontColorAnchorElem);
  const fontColorId = fontColorOpen ? 'font-color-popover' : undefined;

  return (
    <div className="Form">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/marketing-tools/forms">
          <Button 
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Forms
          </Button>
        </Link>
      </div>

      <div className="last-modified-container">
        <span>
          <strong>Last Modified:</strong> <span style={{marginRight: 10}}>{moment(form.updated).calendar()}</span>
          <strong>Last Modified By:</strong> {form.lastUpdatedBy}
        </span>
      </div>

      <div>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            Editing <strong>{form.title || 'Unnamed Form'}</strong>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={saveForm}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <FormControlLabel
            control={
              <Switch
                checked={form.live}
                onChange={(e) => {
                  setForm({
                    ...form,
                    live: e.target.checked,
                  });
                }}
                name="live"
                color="primary"
              />
            }
            label="Live"
          />

          <FormControlLabel
            control={
              <Switch
                checked={form.requireAuth}
                onChange={(e) => {
                  setForm({
                    ...form,
                    requireAuth: e.target.checked,
                  });
                }}
                name="requireAuth"
                color="primary"
              />
            }
            label="Require Auth"
          />

          <div className="form-row">
            <TextField
              label="Title"
              value={form.title}
              onChange={(e) => {
                setForm({
                  ...form,
                  title: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Description"
              value={form.description}
              onChange={(e) => {
                setForm({
                  ...form,
                  description: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div className="form-row">
            <TextField
              label="Page Path"
              helperText="Only letters, numbers and dashes allowed"
              value={form.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setForm({
                  ...form,
                  path: value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <span className="preview">
            <strong>View:</strong> <a href={`https://forms.pupford.com/${form.path}`} target="_blank" style={{color: 'blue'}}>{`https://forms.pupford.com/${form.path}`}</a>
          </span>

          <div className="tabs-container">
            <Divider />
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => { setTabValue(newValue) }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="form tabs"
            >
              <Tab label="Pages" {...a11yProps(0)} style={{borderRightWidth: 1, borderRightColor: 'rgba(0, 0, 0, 0.12)', borderRightStyle: 'solid'}} />
              <Tab label="Logic" {...a11yProps(1)} style={{borderRightWidth: 1, borderRightColor: 'rgba(0, 0, 0, 0.12)', borderRightStyle: 'solid'}} />
              <Tab label="Actions" {...a11yProps(2)} />
            </Tabs>
            <Divider />
            <SwipeableViews
              index={tabValue}
              onChangeIndex={(index) => { setTabValue(index) }}
              ref={swipeableViewsRef}
              animateHeight={true}
            >
              <div style={{ paddingBottom: 10 }} value={tabValue} index={0}>
                <div className="pages-container">
                  <div className="left-container">
                    {form.content.map((p, i) => {
                      return (
                        <div key={`page-${i}`} className="form-page-container">
                          <div className="action-buttons-container">
                            {i === form.content.length - 1 ? null :
                              <div
                                className="action-button"
                                onClick={() => {
                                  const pagesCopy = [ ...form.content ];
                                  const currentPage = pagesCopy[i];
                                  const nextPage = pagesCopy[i + 1];

                                  pagesCopy[i] = nextPage;
                                  pagesCopy[i + 1] = currentPage;
                              
                                  setForm({
                                    ...form,
                                    content: pagesCopy,
                                  });
                                }}
                              >
                                <IconButton
                                  aria-label="move-down"
                                  size="small"
                                >
                                  <ArrowDownwardIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            }

                            {i === 0 ? null :
                              <div
                                className="action-button"
                                onClick={() => {
                                  const pagesCopy = [ ...form.content ];
                                  const currentPage = pagesCopy[i];
                                  const previousPage = pagesCopy[i - 1];

                                  pagesCopy[i] = previousPage;
                                  pagesCopy[i - 1] = currentPage;
                              
                                  setForm({
                                    ...form,
                                    content: pagesCopy,
                                  });
                                }}
                              >
                                <IconButton
                                  aria-label="move-up"
                                  size="small"
                                >
                                  <ArrowUpwardIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            }

                            <div
                              className="action-button"
                              onClick={() => { copyPage(i) }}
                            >
                              <IconButton
                                aria-label="copy"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </div>

                            {form.content.length < 2 ? null :
                              <div
                                className="action-button"
                                onClick={() => { setPageDeleteIndex(i) }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            }
                          </div>
                          <div
                            className={`page-flow-container form-page${currentPageIndex === i ? ' selected' : ''}`}
                            onClick={() => {
                              setCurrentPageIndex(i);
                            }}
                          >
                            <p><strong>Page {i + 1}</strong></p>
                          </div>
                        </div>
                      );
                    })}

                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ width: '100%' }}
                      onClick={() => {
                        const pagesCopy = [ ...form.content ];

                        pagesCopy.push({
                          ...defaultPage,
                          id: `${moment().valueOf()}`,
                        });

                        setForm({
                          ...form,
                          content: pagesCopy,
                        });
                      }}
                    >
                      Add Page
                    </Button>
                  </div>

                  <div className="right-container">
                    <FormControl
                      margin="dense"
                      variant="outlined"
                      className="day-text-field"
                    >
                      <InputLabel>Layout</InputLabel>
                      <Select
                        value={form.content[currentPageIndex].layout}
                        onChange={(e) => {
                          const pagesCopy = [ ...form.content ];
                          
                          pagesCopy[currentPageIndex] = {
                            ...pagesCopy[currentPageIndex],
                            layout: e.target.value,
                          };

                          setForm({
                            ...form,
                            content: pagesCopy,
                          });
                        }}
                        label="Layout"
                      >
                        <MenuItem value={'left'}>Left</MenuItem>
                        <MenuItem value={'center'}>Center</MenuItem>
                        <MenuItem value={'right'}>Right</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={form.content[currentPageIndex].boxShadowEnabled}
                          onChange={(e) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              boxShadowEnabled: e.target.checked,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          name="shadow"
                          color="primary"
                        />
                      }
                      label="Enable Shadow"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={form.content[currentPageIndex].disablePadding || false}
                          onChange={(e) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              disablePadding: e.target.checked,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          name="padding"
                          color="primary"
                        />
                      }
                      label="Disable Padding"
                    />

                    <div className="page-input-row">
                      <TextField
                        label="Page Background Image URL"
                        value={form.content[currentPageIndex].backgroundImage}
                        onChange={(e) => {
                          const pagesCopy = [ ...form.content ];
                          
                          pagesCopy[currentPageIndex] = {
                            ...pagesCopy[currentPageIndex],
                            backgroundImage: e.target.value,
                          };

                          setForm({
                            ...form,
                            content: pagesCopy,
                          });
                        }}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />

                      <TextField
                        label="Form Background Image URL"
                        value={form.content[currentPageIndex].formBackgroundImage}
                        onChange={(e) => {
                          const pagesCopy = [ ...form.content ];
                          
                          pagesCopy[currentPageIndex] = {
                            ...pagesCopy[currentPageIndex],
                            formBackgroundImage: e.target.value,
                          };

                          setForm({
                            ...form,
                            content: pagesCopy,
                          });
                        }}
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="day-text-field"
                      />
                    </div>

                    <div className="page-input-row">
                      <div
                        className="color-container"
                        onClick={(e) => setPageColorAnchorElem(e.currentTarget)}
                      >
                        <p>Page Color</p>

                        <div
                          className="color-preview "
                          style={{ backgroundColor: form.content[currentPageIndex].backgroundColor }}
                        ></div>
                      </div>

                      <Popover
                        id={pageColorId}
                        open={pageColorOpen}
                        anchorEl={pageColorAnchorElem}
                        onClose={() => setPageColorAnchorElem(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <ColorPicker
                          width={250}
                          height={125}
                          color={backgroundColor}
                          onChange={setBackgroundColor}
                          onChangeComplete={(color) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              backgroundColor: color.hex,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          hideHSV
                          hideRGB
                          alpha
                        />
                      </Popover>

                      <div
                        className="color-container"
                        onClick={(e) => setFormColorAnchorElem(e.currentTarget)}
                      >
                        <p>Form Color</p>

                        <div
                          className="color-preview "
                          style={{ backgroundColor: form.content[currentPageIndex].formBackgroundColor }}
                        ></div>
                      </div>

                      <Popover
                        id={formColorId}
                        open={formColorOpen}
                        anchorEl={formColorAnchorElem}
                        onClose={() => setFormColorAnchorElem(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <ColorPicker
                          width={250}
                          height={125}
                          color={formBackgroundColor}
                          onChange={setFormBackgroundColor}
                          onChangeComplete={(color) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              formBackgroundColor: color.hex,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          hideHSV
                          hideRGB
                          alpha
                        />
                      </Popover>
                    </div>

                    <div className="page-input-row">
                      <div
                        className="color-container"
                        onClick={(e) => setInputColorAnchorElem(e.currentTarget)}
                      >
                        <p>Input Color</p>

                        <div
                          className="color-preview "
                          style={{ backgroundColor: form.content[currentPageIndex].inputBackground }}
                        ></div>
                      </div>

                      <Popover
                        id={inputColorId}
                        open={inputColorOpen}
                        anchorEl={inputColorAnchorElem}
                        onClose={() => setInputColorAnchorElem(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <ColorPicker
                          width={250}
                          height={125}
                          color={inputBackground}
                          onChange={setInputBackground}
                          onChangeComplete={(color) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              inputBackground: color.hex,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          hideHSV
                          hideRGB
                          alpha
                        />
                      </Popover>

                      <div
                        className="color-container"
                        onClick={(e) => setFontColorAnchorElem(e.currentTarget)}
                      >
                        <p>Font Color</p>

                        <div
                          className="color-preview "
                          style={{ backgroundColor: form.content[currentPageIndex].fontColor }}
                        ></div>
                      </div>

                      <Popover
                        id={fontColorId}
                        open={fontColorOpen}
                        anchorEl={fontColorAnchorElem}
                        onClose={() => setFontColorAnchorElem(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <ColorPicker
                          width={250}
                          height={125}
                          color={fontColor}
                          onChange={setFontColor}
                          onChangeComplete={(color) => {
                            const pagesCopy = [ ...form.content ];

                            pagesCopy[currentPageIndex] = {
                              ...pagesCopy[currentPageIndex],
                              fontColor: color.hex,
                            };

                            setForm({
                              ...form,
                              content: pagesCopy,
                            });
                          }}
                          hideHSV
                          hideRGB
                          alpha
                        />
                      </Popover>
                    </div>

                    <Toolbar className="form-items-heading">
                      <Typography variant="subtitle1">
                        Form Items
                      </Typography>

                      <Button 
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<ArrowDropDownIcon />}
                        onClick={(e) => setAddItemAnchorEl(e.currentTarget)}
                      >
                        Add Item
                      </Button>

                      <Popover
                        id={addItemId}
                        open={addItemOpen}
                        anchorEl={addItemAnchorEl}
                        onClose={() => setAddItemAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <List disablePadding style={{minWidth: 200}}>
                          <ListItem button onClick={() => addFormItem('heading')}>
                            <ListItemText primary="Heading" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('paragraph')}>
                            <ListItemText primary="Paragraph" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('text-input')}>
                            <ListItemText primary="Text Input" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('date')}>
                            <ListItemText primary="Date" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('select-dropdown')}>
                            <ListItemText primary="Select Dropdown" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('multi-select')}>
                            <ListItemText primary="Multi Select" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('single-select')}>
                            <ListItemText primary="Single Select" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('rating')}>
                            <ListItemText primary="Rating" />
                          </ListItem>

                          <ListItem button onClick={() => addFormItem('file-upload')}>
                            <ListItemText primary="File Upload" />
                          </ListItem>
                        </List>
                      </Popover>
                    </Toolbar>

                    {form.content[currentPageIndex].questions.map((item, i) => {
                      return (
                        <div className="form-item-container" key={`question-${currentPageIndex}-${i}`}>
                          <div className="form-item-header">
                            <p className="form-item-type">{item.type.split('-').join(' ')}</p>

                            <div className="form-item-actions-container">
                              {i === form.content[currentPageIndex].questions.length - 1 ? null :
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => {
                                    const pagesCopy = [ ...form.content ];
                                    const questions = [ ...pagesCopy[currentPageIndex].questions ];
                                    const currentQuestion = questions[i];
                                    const nextQuestion = questions[i + 1];

                                    questions[i] = nextQuestion;
                                    questions[i + 1] = currentQuestion;

                                    pagesCopy[currentPageIndex] = {
                                      ...pagesCopy[currentPageIndex],
                                      questions,
                                    };

                                    setForm({
                                      ...form,
                                      content: pagesCopy,
                                    });
                                  }}
                                >
                                  <ArrowDownwardIcon fontSize="inherit" />
                                </IconButton>
                              }

                              {i === 0 ? null :
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => {
                                    const pagesCopy = [ ...form.content ];
                                    const questions = [ ...pagesCopy[currentPageIndex].questions ];
                                    const currentQuestion = questions[i];
                                    const previousQuestion = questions[i - 1];

                                    questions[i] = previousQuestion;
                                    questions[i - 1] = currentQuestion;

                                    pagesCopy[currentPageIndex] = {
                                      ...pagesCopy[currentPageIndex],
                                      questions,
                                    };

                                    setForm({
                                      ...form,
                                      content: pagesCopy,
                                    });
                                  }}
                                >
                                  <ArrowUpwardIcon fontSize="inherit" />
                                </IconButton>
                              }

                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => setDeleteQuestionIndex(i)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>

                          {renderQuestion(item, i)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ padding: 10 }} value={tabValue} index={1}>
                <FormConditions
                  pages={form.content}
                  conditions={form.conditions}
                  updateConditions={(conditions) => {
                    setForm({
                      ...form,
                      conditions,
                    });
                  }}
                />
              </div>

              <div style={{ padding: 10 }} value={tabValue} index={2}>
                <FormActions
                  pages={form.content}
                  actions={form.actions || []}
                  updateActions={(actions) => {
                    setForm({
                      ...form,
                      actions,
                    });
                  }}
                />
              </div>
            </SwipeableViews>
          </div>
        </div>
      </div>

      <Dialog fullWidth maxWidth="sm" open={pageDeleteIndex !== -1} onClose={() => { setPageDeleteIndex(-1) }}>
        <DialogTitle>Delete Page?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this page?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            if (currentPageIndex === (form.content.length - 1)) {
              setCurrentPageIndex(currentPageIndex - 1);
            }

            const content = [ ...form.content ];
            content.splice(pageDeleteIndex, 1);

            setForm({
              ...form,
              content,
            });
            setPageDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setPageDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteQuestionIndex !== -1} onClose={() => { setDeleteQuestionIndex(-1) }}>
        <DialogTitle>Delete Question?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this question?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const pagesCopy = [ ...form.content ];
            const questions = [ ...pagesCopy[currentPageIndex].questions ];

            questions.splice(deleteQuestionIndex, 1);

            pagesCopy[currentPageIndex] = {
              ...pagesCopy[currentPageIndex],
              questions,
            };

            setForm({
              ...form,
              content: pagesCopy,
            });
            setDeleteQuestionIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteQuestionIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Form;
