import React from 'react';
import './get-started-section.scss';

export const GetStartedSection = ({ data }) => {
  const { title, imageUrl, buttonText } = data;
  return (
    <section
      className="GetStartedContainer"
      style={{ backgroundImage: `url("${imageUrl}")` }}
    >
      <div className="overlay" />
      <div className="content">
        <h2 className="title">{title}</h2>

        <button className="button">{buttonText}</button>
      </div>
    </section>
  );
};
