import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import './editWeeklyChallenge.scss';
import 'react-datepicker/dist/react-datepicker.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditWeeklyChallenge({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [challenge, setChallenge] = useState({
    title: '',
    description: '',
    imageUrl: '',
    videoUrl: '',
    live: false,
    endDate: moment().endOf('week').toDate(),
    webPath: '',
    steps: [],
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });
  const [stepDeleteIndex, setStepDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchWeeklyChallenge();
  }, []);

  const fetchWeeklyChallenge = async () => {
    const id = match.params.id;

    if (id === undefined) {
      history.replace('/academy-content/weekly-challenges');
      return;
    }

    try {
      const challengeSnapshot = await firebase.firestore().collection('weekly-challenges').doc(id).get();
      const challengeData = challengeSnapshot.data();

      if (!challengeData) {
        history.replace('/academy-content/weekly-challenges');
        return;
      }

      setChallengeId(id);
      setChallenge({
        ...challengeData,
        endDate: moment(challengeData.endDate).toDate(),
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this weekly challenge, please try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('weekly-challenges').doc(challengeId).update({
        ...challenge,
        updated: Date.now(),
        endDate: moment(challenge.endDate).endOf('day').valueOf(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this weekly challenge, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditWeeklyChallenge">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/academy-content/weekly-challenges">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Monthly Challenges
          </Button>
        </Link>
      </div>

      <Card style={{width: '100%'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Edit Monthly Challenge
          </Typography>

          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={save}
            >
              Save
            </Button>
          </div>
        </Toolbar>

        <Divider/>

        <CardContent>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: 5}}>
            <div style={{marginRight: 20, zIndex: 2}}>
              <Typography>
                <small>End Date</small>
              </Typography>

              <DatePicker
                selected={challenge.endDate}
                onChange={(date) => {
                  setChallenge({
                    ...challenge,
                    endDate: date,
                  });
                }}
              />
            </div>

            <FormControlLabel
              control={
                <Switch
                  checked={challenge.live}
                  onChange={(e) => {
                    setChallenge({
                      ...challenge,
                      live: e.target.checked,
                    });
                  }}
                  name="live"
                  color="primary"
                />
              }
              label="Live"
            />
          </div>

          <TextField
            label="Title"
            value={challenge.title}
            onChange={(e) => {
              setChallenge({
                ...challenge,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="normal"
            size="small"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Description"
            value={challenge.description}
            onChange={(e) => {
              setChallenge({
                ...challenge,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="normal"
            size="small"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Image URL"
            value={challenge.imageUrl}
            onChange={(e) => {
              setChallenge({
                ...challenge,
                imageUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="normal"
            size="small"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Video URL"
            value={challenge.videoUrl}
            onChange={(e) => {
              setChallenge({
                ...challenge,
                videoUrl: e.target.value,
              });
            }}
            variant="outlined"
            margin="normal"
            size="small"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Web Path"
            helperText="Only letters, numbers and dashes allowed"
            value={challenge.webPath}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setChallenge({
                ...challenge,
                webPath: value,
              });
            }}
            variant="outlined"
            margin="normal"
            size="small"
            type="text"
            className="day-text-field"
          />

          <div className="section-container">
            <div className="section-label">Steps</div>

            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setChallenge({
                    ...challenge,
                    steps: [
                      ...challenge.steps,
                      {
                        mediaType: 'image',
                        imageUrl: '',
                        videoUrl: '',
                        text: '',
                      },
                    ],
                  });
                }}
              >
                Add Step
              </Button>
            </div>

            {challenge.steps.map((step, i) => {
              return (
                <div key={`step-${i}`} style={{ marginTop: 10 }}>
                  <Typography>Step {i + 1}</Typography>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <FormControl
                        margin="normal"
                        size="small"
                        variant="outlined"
                        className="day-text-field"
                      >
                        <InputLabel>Media Type</InputLabel>
                        <Select
                          value={step.mediaType}
                          onChange={(e) => {
                            const updatedSteps = [ ...challenge.steps ];
                            const updatedStep = {
                              ...step,
                              mediaType: e.target.value,
                            };
                            updatedSteps[i] = updatedStep;

                            setChallenge({
                              ...challenge,
                              steps: updatedSteps,
                            });
                          }}
                          label="Media Type"
                        >
                          <MenuItem value={'image'}>Image</MenuItem>
                          <MenuItem value={'video'}>Video</MenuItem>
                          <MenuItem value={'none'}>None</MenuItem>
                        </Select>
                      </FormControl>

                      {step.mediaType === 'none' ? null :
                        <>
                          {step.mediaType === 'image' ?
                            <TextField
                              label="Image URL"
                              value={step.imageUrl}
                              onChange={(e) => {
                                const updatedSteps = [ ...challenge.steps ];
                                const updatedStep = {
                                  ...step,
                                  imageUrl: e.target.value,
                                };
                                updatedSteps[i] = updatedStep;

                                setChallenge({
                                  ...challenge,
                                  steps: updatedSteps,
                                });
                              }}
                              variant="outlined"
                              margin="normal"
                              size="small"
                              type="text"
                              className="day-text-field"
                            /> :
                            <TextField
                              label="Video URL"
                              value={step.videoUrl}
                              onChange={(e) => {
                                const updatedSteps = [ ...challenge.steps ];
                                const updatedStep = {
                                  ...step,
                                  videoUrl: e.target.value,
                                };
                                updatedSteps[i] = updatedStep;

                                setChallenge({
                                  ...challenge,
                                  steps: updatedSteps,
                                });
                              }}
                              variant="outlined"
                              margin="normal"
                              size="small"
                              type="text"
                              className="day-text-field"
                            />
                          }
                        </>
                      }

                      <TextField
                        label="Text"
                        value={step.text}
                        onChange={(e) => {
                          const updatedSteps = [ ...challenge.steps ];
                          const updatedStep = {
                            ...step,
                            text: e.target.value,
                          };
                          updatedSteps[i] = updatedStep;

                          setChallenge({
                            ...challenge,
                            steps: updatedSteps,
                          });
                        }}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        type="text"
                        className="day-text-field"
                        multiline
                        minRows={3}
                      />
                    </div>

                    <div style={{marginLeft: 10}}>
                      <IconButton
                        onClick={() => {
                          setStepDeleteIndex(i);
                        }}
                        color="inherit"
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={stepDeleteIndex !== -1} onClose={() => { setStepDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Step?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {stepDeleteIndex === -1 ? 'this step' : `step ${stepDeleteIndex + 1}`}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const stepsCopy = [ ...challenge.steps ];
              stepsCopy.splice(stepDeleteIndex, 1);

              setChallenge({
                ...challenge,
                steps: stepsCopy,
              });
              setStepDeleteIndex(-1);
            }}
          >
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setStepDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditWeeklyChallenge;
