import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import DatePicker from 'react-datepicker';
import {
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';

import './subscriptionForecastByCategory.scss';

const start = moment().toDate();
start.setHours(0, 0, 0, 0);

const end = moment().add(1, 'month').endOf('month').toDate();
end.setHours(23, 59, 59, 999);

function SubscriptionForecastByCategory({ selectedCategories, subscriptions }) {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dateInterval, setDateInterval] = useState('day');
  const [subscriptionsByCategory, setSubscriptionsByCategory] = useState([]);
  const [categoryNamesToDisplay, setCategoryNamesToDisplay] = useState([]);
  const [categoryDataToDisplay, setCategoryDataToDisplay] = useState([]);
  const [categoryQuantitiesToDisplay, setCategoryQuantitiesToDisplay] = useState([]);

  useEffect(() => {
    const categorySubscriptionMap = {};

    selectedCategories.forEach(c => {
      categorySubscriptionMap[c] = {
        name: c,
        subscriptions: [],
        totalRevenueForPeriod: 0,
        totalProductsForPeriod: 0,
      };
    });

    const maxSubscriptionDate = moment(endDate).valueOf();

    subscriptions.forEach(sub => {
      sub.products.forEach(p => {
        (p.categories || []).forEach(c => {
          if (categorySubscriptionMap.hasOwnProperty(c)) {
            categorySubscriptionMap[c].subscriptions.push({
              nextPaymentDate: sub.nextPaymentDate,
              interval: sub.interval,
              period: sub.period,
              total: +p.price,
            });

            let nextPaymentDate = sub.nextPaymentDate;
      
            while (nextPaymentDate <= maxSubscriptionDate) {
              nextPaymentDate = moment(new Date(nextPaymentDate)).add(sub.interval, sub.period).valueOf();
      
              categorySubscriptionMap[c].totalRevenueForPeriod += (+p.price * +p.quantity);
              categorySubscriptionMap[c].totalProductsForPeriod += +p.quantity;
            }
          }
        });
      });
    });

    const categorySubscriptionList = [];

    for (let key in categorySubscriptionMap) {
      const subDateMap = {};
      const updatedSubscriptions = [];
      let min = Infinity;
      let max = 0;

      categorySubscriptionMap[key].subscriptions.forEach(sub => {
        updatedSubscriptions.push({
          nextPaymentDate: sub.nextPaymentDate,
          total: sub.total,
        });

        let nextPaymentDate = sub.nextPaymentDate;
  
        while (nextPaymentDate <= maxSubscriptionDate) {
          nextPaymentDate = moment(new Date(nextPaymentDate)).add(sub.interval, sub.period).valueOf();
  
          if (nextPaymentDate <= maxSubscriptionDate) {
            updatedSubscriptions.push({
              nextPaymentDate,
              total: sub.total,
            });
          }
        }
      });

      updatedSubscriptions.forEach(sub => {
        const millis = moment(new Date(sub.nextPaymentDate)).startOf(dateInterval).valueOf();
        const dateStr = `${millis}`;

        if (millis > maxSubscriptionDate) {
          return;
        }
  
        if (millis > max) {
          max = millis;
        }
  
        if (millis < min) {
          min = millis;
        }
        
        if (!subDateMap[dateStr]) {
          subDateMap[dateStr] = {
            count: 1,
            revenue: +sub.total,
          };
        } else {
          subDateMap[dateStr].count++;
          subDateMap[dateStr].revenue += +sub.total;
        }
      });

      let current = min;
  
      while (current <= max) {
        if (!subDateMap[`${current}`]) {
          subDateMap[`${current}`] = {
            count: 0,
            revenue: 0,
          };
        }
  
        const millis = moment(new Date(+current)).add(1, dateInterval).startOf(dateInterval).valueOf();
        current = millis;
      }
  
      const dataArr = [];
  
      for (let key in subDateMap) {
        dataArr.push([new Date(+key), subDateMap[key].count, subDateMap[key].revenue]);
      }
  
      dataArr.sort((a, b) => {
        return a[0] - b[0];
      });

      categorySubscriptionList.push({
        ...categorySubscriptionMap[key],
        dataArr,
        id: key,
      });
    }

    const categoryData = [];
    const categoryQuantities = [];

    categorySubscriptionList.forEach((c, i) => {
      c.dataArr.forEach((d, j) => {
        if (!categoryData[j]) {
          categoryData[j] = [d[0]];
        }

        categoryData[j].push(d[2]);

        if (!categoryQuantities[j]) {
          categoryQuantities[j] = [d[0]];
        }

        categoryQuantities[j].push(d[1]);
      });
    });

    setSubscriptionsByCategory(categorySubscriptionList);
    setCategoryNamesToDisplay(categorySubscriptionList.map(p => p.name));
    setCategoryDataToDisplay(categoryData);
    setCategoryQuantitiesToDisplay(categoryQuantities);
  }, [selectedCategories, subscriptions, startDate, endDate]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="SubscriptionForecastByCategory">
      <div className="date-pickers-container">
        <div className="date-picker-container">
          <Typography>
            <strong>
              <small>Start</small>
            </strong>
          </Typography>
          <DatePicker
            popperPlacement="top-start"
            selected={startDate}
            onChange={(date) => {
              const firstDay = date;
              firstDay.setHours(0, 0, 0, 0);
              setStartDate(firstDay);
            }}
            minDate={start}
          />
        </div>
        <div className="date-divider" style={{marginTop: 14}}>-</div>
        <div className="date-picker-container">
          <Typography>
            <strong>
              <small>End</small>
            </strong>
          </Typography>
          <DatePicker
            popperPlacement="top-end"
            selected={endDate}
            onChange={(date) => {
              const lastDay = date;
              lastDay.setHours(23, 59, 59, 999);
              setEndDate(lastDay);
            }}
            minDate={start}
          />
        </div>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            {type: 'date', label: dateInterval.toUpperCase()},
            ...categoryNamesToDisplay,
          ],
          ...categoryDataToDisplay,
        ]}
        options={{
          hAxis: {
            title: 'Date',
          },
          chart: {
            title:
              'Category Revenue by Day',
          },
          vAxis: {
            title: 'Revenue',
          },
          width: '100%',
          height: 500,
          series: {
            1: { curveType: 'function' },
          },
        }}
      />

      <div style={{margin: '40px 0'}}>
        <Divider/>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            {type: 'date', label: dateInterval.toUpperCase()},
            ...categoryNamesToDisplay,
          ],
          ...categoryQuantitiesToDisplay,
        ]}
        options={{
          hAxis: {
            title: 'Date',
          },
          chart: {
            title:
              'Category Units by Day',
          },
          vAxis: {
            title: 'Units',
          },
          width: '100%',
          height: 500,
          series: {
            1: { curveType: 'function' },
          },
        }}
      />

      <TableContainer component={Paper}>
        <Table aria-label="categories table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Category</strong></TableCell>
              <TableCell><strong>Forecasted Revenue</strong></TableCell>
              <TableCell><strong>Forecasted Units</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsByCategory.map((c, i) => (
              <TableRow key={c.name}>
                <TableCell>
                  <div><strong>{c.name}</strong></div>
                </TableCell>
                <TableCell>{formatter.format(c.totalRevenueForPeriod)}</TableCell>
                <TableCell>{c.totalProductsForPeriod}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SubscriptionForecastByCategory;
