import React, { useState, useEffect, useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

import SaveRowModal from './shared/saveRowModal';
import EditModal from './shared/editModal';
import Modal from './shared/modal';
import Column from './column';
import { ItemTypes } from './shared/itemTypes';
import moveIcon from '../icons/move.png';
import upArrow from '../icons/up.png';
import downArrow from '../icons/down.png';
import editArrow from '../icons/edit.png';
import deleteIcon from '../icons/delete.png';
import saveIcon from '../icons/save.png';
import copyIcon from '../icons/copy.png';

const cloneDeep = require('lodash.clonedeep');

const styles = {
  container: {
    border: '1px dashed',
    marginBottom: '5px',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: 'lightblue',
  },
  toolbarItem: {
    height: 15,
    width: 15,
    margin: 5,
    cursor: 'pointer',
  },
  image: {
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  childrenContainer: {
    display: 'flex',
    width: '100%',
  },
};

function Row({ content, updateContent, upClicked, downClicked, deleteItem, saveModule, copyClicked }) {
  const [, drag] = useDrag({
    item: { ...content },
    end(item, monitor) {
      if (!monitor.didDrop()) {
        return;
      }

      deleteItem();
    },
  });
  const innerRef = useRef(null);
  const childRef = useRef(null);
  const [showTools, setShowTools] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [saveRowModalOpen, setSaveRowModalOpen] = useState(false);
  const [saveAsGlobal, setSaveAsGlobal] = useState(false);
  const [saveRowName, setSaveRowName] = useState('');
  const [{ isOverCurrent }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    drop(item, monitor) {
      if (monitor.didDrop()) {
        return;
      }

      updateContent({
        ...content,
        children: [
          ...content.children,
          {
            ...item,
          },
        ],
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.onmouseenter = (e) => {
        setShowTools(true);
      };

      innerRef.current.onmouseleave = (e) => {
        setShowTools(false);
      };

      childRef.current.onmouseenter = (e) => {
        setShowTools(false);
      };

      childRef.current.onmouseleave = (e) => {
        setShowTools(true);
      };
    }
  }, [innerRef, childRef]);

  const delayedDelete = () => {
    setTimeout(() => {
      deleteItem();
    }, 50);
  };

  let borderStyle = 'dashed';
  if (isOverCurrent) {
    borderStyle = 'solid';
  }

  return (
    <div
      ref={drop}
      style={{
        ...styles.container,
        borderStyle,
        borderColor: content.global ? '#f7a408' : '#00a0d2',
        padding: !content.children.length ? '15px' : '0',
      }}
    >
      <div
        ref={innerRef}
        style={{ padding: '5px' }}
      >
        {!showTools ? null :
          <div style={styles.inner}>
            <div style={styles.toolbar}>
              <div style={styles.toolbarItem} ref={drag}>
                <img style={styles.image} src={moveIcon} alt="move" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { copyClicked() }}>
                <img style={styles.image} src={copyIcon} alt="copy" />
              </div>
              <div style={styles.toolbarItem} onClick={upClicked}>
                <img style={styles.image} src={upArrow} alt="up arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={downClicked}>
                <img style={styles.image} src={downArrow} alt="down arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setEditModalOpen(true) }}>
                <img style={styles.image} src={editArrow} alt="edit" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setDeleteModalOpen(true) }}>
                <img style={styles.image} src={deleteIcon} alt="delete" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setSaveRowModalOpen(true) }}>
                <img style={styles.image} src={saveIcon} alt="save" />
              </div>
            </div>
          </div>
        }
        <div ref={childRef} style={{
          position: 'relative',
          ...content.styles,
          backgroundImage: content.styles.backgroundImage ? `url(${content.styles.backgroundImage})` : '',
        }}>
          <div style={{ ...styles.overlay, backgroundColor: content.backgroundOverlayColor || 'rgba(0, 0, 0, 0)' }}></div>
          <div ref={childRef} style={{...styles.childrenContainer}} className={`${content.className}${content.reverseColumnsWhenStacked ? ' reverse-when-stacked' : ''}`}>
            {content.children.map((column, i) => {
              return (
                <div key={`column-${i}`} style={{
                  flex: column.styles.flex || 1,
                  alignSelf: column.styles.alignSelf,
                  width: '100%',
                }}>
                  <Column
                    content={column}
                    saveModule={saveModule}
                    copyClicked={() => {
                      const children = [ ...content.children ];
                      const columnClone = cloneDeep(column);
                  
                      children.splice(i + 1, 0, columnClone);
                  
                      const updated = {
                        ...content,
                        children,
                      };
                  
                      updateContent(updated);
                    }}
                    leftClicked={() => {
                      if (i !== 0) {
                        const children = [ ...content.children ];

                        const itemToMoveUp = { ...column };
                        const itemToMoveDown = { ...children[i - 1] };

                        children[i] = itemToMoveDown;
                        children[i - 1] = itemToMoveUp;

                        const updated = {
                          ...content,
                          children,
                        };

                        updateContent(updated);
                      }
                    }}
                    rightClicked={() => {
                      if (i !== content.children.length - 1) {
                        const children = [ ...content.children ];

                        const itemToMoveDown = { ...column };
                        const itemToMoveUp = { ...children[i + 1] };

                        children[i] = itemToMoveUp;
                        children[i + 1] = itemToMoveDown;

                        const updated = {
                          ...content,
                          children,
                        };

                        updateContent(updated);
                      }
                    }}
                    updateContent={(child) => {
                      const children = [ ...content.children ];

                      children[i] = child;

                      const updated = {
                        ...content,
                        children,
                      };

                      updateContent(updated);
                    }}
                    deleteItem={() => {
                      const children = [ ...content.children ];

                      children.splice(i, 1);

                      const updated = {
                        ...content,
                        children,
                      };

                      updateContent(updated);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <SaveRowModal
        open={saveRowModalOpen}
        close={() => { setSaveRowModalOpen(false) }}
        title="Save Row"
        buttons={[
          <button key="modal-confirm" className="success" disabled={!saveRowName} onClick={() => {
            const id = `${Date.now()}`;
            const global = saveAsGlobal === 'yes' ? true : false;

            saveModule({
              ...content,
              global,
              title: saveRowName,
              id,
            });
            setSaveAsGlobal(false);
            setSaveRowName('');
            setSaveRowModalOpen(false);
            updateContent({
              ...content,
              global,
              title: saveRowName,
              id,
            });
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setSaveAsGlobal(false);
            setSaveRowName('');
            setSaveRowModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div className="input-container">
          <label>Would you like to save this row as a global block?</label>
          <select value={saveAsGlobal} onChange={(e) => { setSaveAsGlobal(e.target.value) }}>
            <option value={'no'}>No</option>
            <option value={'yes'}>Yes</option>
          </select>
        </div>

        <div className="input-container">
          <label>Name (Internal label for block)</label>
          <input value={saveRowName} onChange={(e) => { setSaveRowName(e.target.value) }} />
        </div>
      </SaveRowModal>

      <EditModal
        open={editModalOpen}
        close={() => { setEditModalOpen(false) }}
        title={`Edit ${content.title}`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            setEditModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setEditModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="input-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <input
              style={{ display: 'flex', marginRight: 7 }}
              type="checkbox"
              checked={content.reverseColumnsWhenStacked}
              onChange={(e) => {
                updateContent({
                  ...content,
                  reverseColumnsWhenStacked: e.target.checked,
                });
              }}
            />
            <label style={{ display: 'flex' }}>Reverse Stacked Content</label>
          </div>
          <div className="input-container">
            <label>Background Overlay Color</label>
            <input
              value={content.backgroundOverlayColor}
              onChange={(e) => {
                updateContent({
                  ...content,
                  backgroundOverlayColor: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Stack Children Width</label>
            <select
              value={content.className}
              onChange={(e) => {
                updateContent({
                  ...content,
                  className: e.target.value
                });
              }}
            >
              <option value={'page-builder-largest-stack'}>{'Largest (1200px)'}</option>
              <option value={'page-builder-large-stack'}>{'Large (992px)'}</option>
              <option value={'page-builder-medium-stack'}>{'Medium (768px)'}</option>
              <option value={'page-builder-small-stack'}>{'Small (567px)'}</option>
              <option value={'page-builder-no-stack'}>{'Never Stack'}</option>
            </select>
          </div>
          {content.editableStyles.map((editable, i) => {
            return (
              <div key={`editable-${i}`} className="input-container">
                <label>{editable.label}</label>
                {editable.inputType === 'select' ?
                  <select
                    value={content.styles[editable.type]}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  >
                    {editable.options.map((option, i) => {
                      return <option key={i} value={option}>{option}</option>;
                    })}
                  </select> :
                  <input
                    value={content.styles[editable.type]}
                    placeholder={editable.placeholder}
                    type={editable.inputType || 'text'}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        styles: {
                          ...content.styles,
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  />
                }
              </div>
            );
          })}
          <div className="input-container">
            <label>Displayed Device Widths</label>
            <select
              value={content.visibilityClassName}
              onChange={(e) => {
                updateContent({
                  ...content,
                  visibilityClassName: e.target.value
                });
              }}
            >
              <option value={'page-builder-always-visible'}>Always</option>
              <option value={'page-builder-large-visible'}>Large Devices Only</option>
              <option value={'page-builder-large-medium-visible'}>Large & Medium Devices Only</option>
              <option value={'page-builder-medium-visible'}>Medium Devices Only</option>
              <option value={'page-builder-medium-small-visible'}>Medium & Small Devices Only</option>
              <option value={'page-builder-small-visible'}>Small Devices Only</option>
            </select>
          </div>
          <div className="input-container">
            <label>Class Names</label>
            <input
              value={content.customClasses}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customClasses: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>Inline Styles</label>
            <input
              value={content.customStyles}
              onChange={(e) => {
                updateContent({
                  ...content,
                  customStyles: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-container">
            <label>ID</label>
            <input
              value={content.htmlID}
              onChange={(e) => {
                updateContent({
                  ...content,
                  htmlID: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </EditModal>

      <Modal
        open={deleteModalOpen}
        close={() => { setDeleteModalOpen(false) }}
        title={`Delete ${content.title}?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            delayedDelete();
            setDeleteModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this?</div>
        </div>
      </Modal>
    </div>
  );
};

export default Row;
