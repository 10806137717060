import React from 'react';
import './App.css';
import Main from './components/main';
import app from 'firebase/app';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { config } from './config';
const firebaseConfig = require('./firebaseConfig.json');

const theme = createTheme(config.theme);

function App() {
  app.initializeApp(firebaseConfig);
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Main></Main>
      </ThemeProvider>
    </div>
  );
}

export default App;
