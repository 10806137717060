import { useFieldArray } from 'react-hook-form';

export const useCommunityLinksFields = (control) => {
  const {
    append,
    remove,
    fields: linksItems,
    move: moveLinkItem,
  } = useFieldArray({
    control: control,
    name: 'blogSection.communityLinks.items',
  });

  const appendLinkItem = () => {
    append({
      name: '',
      url: '',
    });
  };

  const removeLinkItem = (index) => {
    remove(index);
  };

  return {
    linksItems,
    appendLinkItem,
    removeLinkItem,
    moveLinkItem,
  };
};
