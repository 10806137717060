import React, { useEffect, useState, Fragment } from 'react';
import firebase from 'firebase/app';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel';

function ConditionSelect(props) {
  const [eventNames, setEventNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectingParam, setSelectingParam] = useState(false);
  const [parameterMetadata, setParameterMetadata] = useState({});

  useEffect(() => {
    const eventNames = Object.keys(props.events);
    eventNames.sort((a, b) => a > b ? 1 : b > a ? -1 : 0)
    setEventNames(eventNames);
  }, []);

  useEffect(() => {
    if (props.condition.parameters) {
      getParameterMetadata(props.condition.parameters)
    }
  }, [props.condition.parameters]);

  const getParameterMetadata = async (parameters) => {
    setLoading(true);
    const db = firebase.firestore();

    const parameterKeys = Object.keys(parameters);
    for (let i = 0; i < parameterKeys.length; i++) {
      const param = parameterKeys[i];
      
      if(!parameterMetadata[param]) {
        if (param === 'screen_name') {
          const querySnapshot = await db.collection('notification-strategies').doc('screen-names').get();
          
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy['screen_name'] = querySnapshot.data().names
          setParameterMetadata(metaDataCopy);
        } else if (param === 'promotion_name' || param === 'promotion_id') {
          const querySnapshot = await db.collection('course-completion-ads').doc('ads-v2').get();
          let dataList;
          if (param === 'promotion_name') {
            dataList = querySnapshot.data().data.map((ad) => {
              return ad.internalName;
            });
          } else {
            dataList = querySnapshot.data().data.mapp((ad) => {
              return ad.id
            });
          }
    
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = dataList;
          setParameterMetadata(metaDataCopy);
        } else if (param === 'item_id' || param === 'item_name' || param === 'item_category' || param === 'product_id' || param === 'product_name') {
          const querySnapshot = await db.collection('config').doc('shop-settings').get();
          let dataList;
          if (param === 'item_id' || param === 'product_id') {
            dataList = querySnapshot.data().simplifiedProducts.map((product) => {
              return product.id;
            });
          } else if (param === 'item_name' || param === 'product_name') {
            dataList = querySnapshot.data().simplifiedProducts.map((product) => {
              return product.name;
            });
          } else {
            dataList = querySnapshot.data().productCategories;
          }
          
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = dataList;
          setParameterMetadata(metaDataCopy);
        } else if (param === 'behavior') {
          const querySnapshot = await db.collection('categories').doc('behaviors').get();
          
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = querySnapshot.data().behaviors;
          setParameterMetadata(metaDataCopy);
        } else if (param === 'star_rating') {
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = [1, 2, 3, 4, 5];
          setParameterMetadata(metaDataCopy);
        } else if (param === 'module_title' || param === 'module_id') {
          const courseModules = [];
          const freeCourseSnapshot = await db.collection('free-courses').get();
          freeCourseSnapshot.forEach((doc) => {
            const docData = doc.data();
            const modules = docData.modules;
            modules.forEach((module) => {
              if (module.title) {
                courseModules.push({
                  moduleName: docData.name,
                  label: param === 'module_title' ? module.title : module.id,
                });
              }
            });
          });
    
          const membershipCourseSnapshot = await db.collection('membership-content').get();
          membershipCourseSnapshot.forEach((doc) => {
            const docData = doc.data();
            const modules = docData.modules;
            modules.forEach((module) => {
              if (module.title) {
                courseModules.push({
                  moduleName: docData.name,
                  label: param === 'module_title' ? module.title : module.id,
                });
              }
            });
          });

          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = courseModules;
          setParameterMetadata(metaDataCopy);
        } else if (param === 'course_id' || param === 'course_name') {
          const dataList = [];
          const freeCourseSnapshot = await db.collection('free-courses').get();
          freeCourseSnapshot.forEach((doc) => {
            const docData = doc.data();
            if (param === 'course_id') {
              dataList.push(docData.id);
            } else {
              dataList.push(docData.name);
            }
          });
    
          const membershipCourseSnapshot = await db.collection('membership-content').get();
          membershipCourseSnapshot.forEach((doc) => {
            const docData = doc.data();
            if (param === 'course_id') {
              dataList.push(docData.id);
            } else {
              dataList.push(docData.name);
            }
          });

          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = dataList;
          setParameterMetadata(metaDataCopy);
        } else if (param === 'stage') {
          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = ['start', 'progress', 'complete', 'cancelled'];
          setParameterMetadata(metaDataCopy);
        } else if (param === 'blog_id') {
          const blogSnapshot = await db.collection('blog-posts').get();
          const blogIds = blogSnapshot.map((doc) => doc.id);

          const metaDataCopy = {...parameterMetadata}
          metaDataCopy[param] = blogIds;
          setParameterMetadata(metaDataCopy);
        }
      }
    }
    setLoading(false);
  }

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  return (
    <Stack sx={{width: '100%'}} direction='row' spacing={2}>
      <FormControl sx={{width: '40%'}}>
        <InputLabel id='target-event-name-label'>Target Event</InputLabel>
        {eventNames.length ?
          <Select
            labelId='target-event-name-label'
            id='target-event-name'
            value={props.condition.targetEvent}
            label='Target Event'
            onChange={(e) => {
              setUpdating(true);
              props.updateConditions('targetEvent', e.target.value);
            }}
          >
            {eventNames.map((event, index) => {
              return (
                <MenuItem key={`${event}-${index}`} value={event}>{event}</MenuItem>
              );
            })}
          </Select> :
          null
        }
      </FormControl>
      {selectingParam && props.events && props.events[props.condition.targetEvent] ?
        // Event Parameter Selection
        <Autocomplete
          id='event-param-select'
          options={props.events[props.condition.targetEvent].sort((a, b) => a > b ? 1 : b > a ? -1 : 0) || []}
          renderInput={(params) => <TextField {...params} label="Parameter" />}
          onChange={(event, value) => {
            setSelectingParam(false);
            const parametersCopy = {...props.condition.parameters}
            parametersCopy[value] = {};
            props.updateConditions('parameters', parametersCopy);
          }}
          sx={{width: '40%'}}
        /> :
        null
      }
      <Paper elevation={props.condition.parameters && Object.keys(props.condition.parameters).length ? 2 : 0} style={{padding: '10px', width: '60%'}}>
        {props.condition.parameters && Object.keys(props.condition.parameters).length ?
          <>
            {Object.keys(props.condition.parameters).map((param, index) => {
              return (
                <Stack direction="row" spacing={1} alignItems="center">
                  {parameterMetadata[param] ?
                    <Fragment>
                      {!loading ?
                        // Parameter Value Selection
                        <Autocomplete
                          id={`target-event-param-${index}`}
                          options={parameterMetadata[param]}
                          groupBy={param === 'module_title' || param === 'module_id' ? (option) => option.moduleName : () => ''}
                          isOptionEqualToValue={(option, value) => {
                            // emtpy string check prevents warnings when value is emtpy when closing audience modal 
                            if (value === '') {
                              return true;
                            }
                            if (param === 'module_title' || param === 'module_id') {
                              return option.label === value;
                            }
                            return option === value;
                          }}
                          renderInput={(params) => <TextField {...params} label={param} />}
                          onChange={(event, value) => {
                            const parametersCopy = {...props.condition.parameters};
                            parametersCopy[param] = {
                              value: param === 'module_title' || param === 'module_id' ? value.label : value
                            };
                            props.updateConditions('parameters', parametersCopy);
                          }}
                          value={props.condition.parameters[param].value ? props.condition.parameters[param].value : ''}
                          sx={{marginTop: '10px', width: '100%'}}
                        /> :
                        renderLoading()
                      }
                    </Fragment> :
                    <TextField
                      id={`target-event-param-${index}`}
                      label={param}
                      value={props.condition.parameters[param].value}
                      onChange={(e) => {
                        const parametersCopy = {...props.condition.parameters};
                        parametersCopy[param] = {
                          value: e.target.value
                        }
                        props.updateConditions('parameters', parametersCopy);
                      }}
                      style={{marginTop: '10px', width: '100%'}}
                    />
                  }
                  <IconButton
                    size="small"
                    style={{height: '40px', width: '40px'}}
                    onClick={() => {
                      const parametersCopy = {...props.condition.parameters};
                      delete parametersCopy[param];
                      props.updateConditions('parameters', parametersCopy);
                    }}
                  >
                    <CancelIcon style={{height: '24px', width: '24px'}} />
                  </IconButton>
                </Stack>
              )
            })}
          </>
          : null
        }
        {props.condition.targetEvent.length ?
          <Button
            sx={{marginTop: props.condition.parameters && Object.keys(props.condition.parameters).length ? '15px' : '0px'}}
            onClick={() => {
              if (selectingParam) {
                setSelectingParam(false);
              } else {
                setSelectingParam(true);
              }
            }}
            style={{alignSelf: 'flex-start'}}
          >
            {selectingParam ? 'Cancel' : 'Add Parameter'}
          </Button> : 
          null
        }
      </Paper>
    </Stack>
  )
}

export default ConditionSelect;
