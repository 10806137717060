import React, { useEffect, useState, Fragment } from 'react';
import firebase from 'firebase/app';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ConditionSelect from './conditionSelect';
import { FormControlLabel } from '@material-ui/core';

const eventsRequiringMetadata = [
  'screen_view',
  'ad_view',
  'product_view',
  'behavior_rating',
  'module_completed'
]

const audienceScope = [
  "snapshot",
  "sequential"
]

function PushNotifications() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState('pushNotification');
  const [createNotification, setCreateNotification] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [newStrategy, setNewStrategy] = useState({
    active: false,
    audience: '',
    content: '',
    deliveryType: 'delayedTimeDelivery',
    imageUrl: '',
    linkUrl: '',
    name: '',
    oneTimeFrequency: false,
    title: '',
  });
  const [delayHours, setDelayHours] = useState(1);
  const [delayMinutes, setDelayMinutes] = useState(0);
  const [strategies, setStrategies] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [showAddInfo, setShowAddInfo] = useState(false);
  const [newEventName, setNewEventName] = useState('');
  const [newEventNameList, setNewEventNameList] = useState([]);
  const [newScreenName, setNewScreenName] = useState('');
  const [newScreenNameList, setNewScreenNameList] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [createAudience, setCreateAudience] = useState(false);
  const [newAudience, setNewAudience] = useState({
    name: '',
    description: '',
    inclusionGroups: [],
    exclusionGroups: [],
    scope: 'snapshot',
  });
  
  const [inclusionGroups, setInclusionGroups] = useState([{
    conditions: [
      {targetEvent: '', condition: ''},
    ]
  }]);

  const [showExclusionGroup, setShowExclusionGroup] = useState(false);
  const [exclusionGroups, setExclusionGroups] = useState([]);
  const [events, setEvents] = useState({});

  useEffect(() => {
    getUserAuthToken();
    // get list of analytics and from firestore and from firebase analytics
    // if something is new in firebase analytics then add it to firestore
    initAudiences();
    initNotificationStrategies();
    initEventsAndParams();

    setLoading(false);
  }, []);

  const getUserAuthToken = async () => {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();

    setIsAdmin(idTokenResult.claims.admin);
  }

  const initAudiences = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('notification-strategies').doc('audiences').get();
      const audiencesData = querySnapshot.data().audiences;
      setAudiences(audiencesData);
    } catch (error) {
      window.alert('Error getting audiences. Please refresh the page to try again');
    }
  }

  const initNotificationStrategies = async () => {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db.collection('notification-strategies').doc('strategies').get();
      const strategiesData = querySnapshot.data().data;
      setStrategies(strategiesData);
    } catch (error) {
      window.alert('Error getting strategies. Please refresh the page to try again.');
    }
  }

  const initEventsAndParams = async () => {
    const db = firebase.firestore();
    try {
      const eventsSnapshot = await db.collection('notification-strategies').doc('events-and-params').get();
      const events = eventsSnapshot.data().eventsAndParams;
      setEvents(events);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setNewStrategy({
      ...newStrategy,
      delayHours,
      delayMinutes
    })
  }, [delayHours, delayMinutes]);

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setExpanded(false);
      return;
    }
    setExpanded(panel);
    setNewStrategy({
      ...newStrategy,
      deliveryType: 'delayedTimeDelivery'
    });
  }

  const handleShowAddInfoClose = () => {
    setShowAddInfo(false);
    setNewEventName('');
    setNewEventNameList([]);
    setNewScreenNameList([]);
  }

  const handleEventNameDelete = (eventName) => {
    const eventNameListCopy = [...newEventNameList];

    const index = eventNameListCopy.indexOf(eventName);
    eventNameListCopy.splice(index, 1);

    setNewEventNameList(eventNameListCopy);
  }

  const handleScreenNameDelete = (screenName) => {
    const screenNameListCopy = [...newScreenNameList];

    const index = screenNameListCopy.indexOf(screenName);
    screenNameListCopy.splice(index, 1);

    setNewScreenNameList(screenNameListCopy);
  }

  const addNewEventNames = async () => {
    const db = firebase.firestore();
    
    if (newEventNameList.length) {
      try {
        await db.collection('notification-strategies').doc('event-names').update({
          c: firebase.firestore.FieldValue.arrayUnion.apply(null, newEventNameList)
        });
      } catch (error) {
        window.alert('Error saving event name(s). Please try again.');
      }
    }

    if (newScreenNameList.length) {
      try {
        await db.collection('notification-strategies').doc('screen-names').update({
          names: firebase.firestore.FieldValue.arrayUnion.apply(null, newScreenNameList)
        });
      } catch (error) {
        window.alert('Error saving screen name(s). Please try again.');
      }
    }

    handleShowAddInfoClose();
  }

  const handleCreateNotificationClose = () => {
    setCreateNotification(false);
    setExpanded('pushNotification');
    setNewStrategy({
      active: false,
      deliveryType: 'delayedTimeDelivery',
      audience: '',
      name: '',
      title: '',
      content: '',
      imageUrl: '',
      linkUrl: '',
      oneTimeFrequency: false,
    });
  }

  const createNotificationStrategy = async () => {
    setCreateNotification(false);
    setLoading(true);

    const db = firebase.firestore();

    try {
      const strategiesCopy = [ ...strategies ];

      strategiesCopy.push({
        ...newStrategy,
        active: true,
        id: db.collection('strategies').doc().id
      });

      await db.collection('notification-strategies').doc('strategies').update({
        data: strategiesCopy,
      });

      setStrategies(strategiesCopy);
    } catch (error) {
      window.alert('Error saving notification strategy. Please try again.');
    }

    setLoading(false);
    handleCreateNotificationClose();
  }

  const updateNotificationStrategy = async () => {
    setCreateNotification(false);
    setLoading(true);

    const db = firebase.firestore();

    try {
      const strategiesCopy = [...strategies];

      strategiesCopy[currentEditIndex] = {
        ...newStrategy
      }

      db.collection('notification-strategies').doc('strategies').update({
        data: strategiesCopy
      });

      setStrategies(strategiesCopy);
    } catch (error) {
      window.alert('Error updating notification strategy.  Please try again.');
    }

    setLoading(false);
    handleCreateNotificationClose();
  }

  const deleteNotificationStrategy = async (index) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const strategiesCopy = [...strategies];

      strategiesCopy.splice(index, 1);

      db.collection('notification-strategies').doc('strategies').update({
        data: strategiesCopy
      });
      
      setStrategies(strategiesCopy);
    } catch (error) {
      window.alert('Error deleting notification strategy.  Please try again.');
    }

    setLoading(false);
  }

  const toggleNotificationStrategy = async (strategy, strategyIndex) => {
    const db = firebase.firestore();

    try {
      const strategiesCopy = [...strategies];
      strategiesCopy[strategyIndex] = {
        ...strategy,
        active: !strategy.active
      }
      db.collection('notification-strategies').doc('strategies').update({
        data: strategiesCopy
      });

      setStrategies(strategiesCopy);
    } catch (error) {
      window.alert('Error updating notification strategy.  Please try again.');
    }
  }
  
  // Audience Stuff
  const deleteAudience = async (index) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const audiencesCopy = [...audiences];

      audiencesCopy.splice(index, 1);

      db.collection('notification-strategies').doc('audiences').update({
        audiences: audiencesCopy
      });
      
      setAudiences(audiencesCopy);
    } catch (error) {
      window.alert('Error deleting notification strategy.  Please try again.');
    }

    setLoading(false);
  }

  const handleCreateAudienceClose = () => {
    setCreateAudience(false);
    setNewAudience({
      name: '',
      description: '',
      inclusionGroups: [],
      exclusionGroups: [],
      scope: 'snapshot'
    });
    
    setInclusionGroups([{
      conditions: [
        {targetEvent: '', condition: ''},
      ]
    }]);

    setExclusionGroups([]);

    setShowExclusionGroup(false);
  }

  const copyGroups = (groups) => {
    console.log('copy 1', groups);
    return groups.map((group) => {
      const conditionsCopy = group.conditions.map((condition, i) => {
        return {
          ...condition
        }
      });

      return {
        ...group,
        conditions: conditionsCopy
      }
    });
  }

  const updateConditions = (target, value, include, groupIndex, conditionIndex) => {
    if (include) {
      const inclusionGroupsCopy = copyGroups(inclusionGroups);

      inclusionGroupsCopy[groupIndex].conditions[conditionIndex][target] = value;

      setInclusionGroups(inclusionGroupsCopy);
    } else {
      const exclusionGroupsCopy = copyGroups(exclusionGroups);

      exclusionGroupsCopy[groupIndex].conditions[conditionIndex][target] = value;

      setExclusionGroups(exclusionGroupsCopy);
    }
  }

  const createNewAudience = async () => {
    setCreateAudience(false);
    setLoading(true);

    const db = firebase.firestore();

    try {
      const audiencesCopy = [ ...audiences ];

      const inclusionGroupsCopy = copyGroups(inclusionGroups);
      const exclusionGroupsCopy = copyGroups(exclusionGroups);

      console.log(exclusionGroupsCopy);

      const newAudienceGroups = {
        ...newAudience,
        inclusionGroups: inclusionGroupsCopy,
        exclusionGroups: exclusionGroupsCopy,
        id: db.collection('audiences').doc().id
      }

      audiencesCopy.push(newAudienceGroups);

      await db.collection('notification-strategies').doc('audiences').update({
        audiences: audiencesCopy
      });

      setAudiences(audiencesCopy);
    } catch (error) {
      window.alert('Error saving audience. Please try again.');
    }

    setLoading(false);
    handleCreateAudienceClose();
  }

  const updateAudience = async () => {
    setCreateAudience(false);
    setLoading(true);

    const db = firebase.firestore();

    try {
      const audiencesCopy = [...audiences];

      const inclusionGroupsCopy = copyGroups(inclusionGroups);
      const exclusionGroupsCopy = copyGroups(exclusionGroups);

      audiencesCopy[currentEditIndex] = {
        ...audiencesCopy[currentEditIndex],
        ...newAudience,
        inclusionGroups: inclusionGroupsCopy,
        exclusionGroups: exclusionGroupsCopy
      }

      db.collection('notification-strategies').doc('audiences').update({
        audiences: audiencesCopy
      });

      setAudiences(audiencesCopy);
    } catch (error) {
      window.alert('Error updating audience.  Please try again.');
    }

    setLoading(false);
    handleCreateAudienceClose();
  }

  return (
    <>
      {renderLoading()}
      <Accordion expanded={expanded === 'audience'} onChange={handleAccordionChange('audience')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='main-panel1-content'
          id='main-panel1-header'
        >
          <Typography variant='h6'>Audiences</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750}}>
              <TableHead>
                <TableRow>
                  <TableCell>Audience Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>
                    <Stack direction='row' spacing={2} justifyContent='right'>
                      <IconButton
                        onClick={(event) => {
                          setIsUpdate(false);
                          setCreateAudience(true);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {audiences.map((audience, i) => (
                  <TableRow key={`${audience.name}-${i}`}>
                    <TableCell>{audience.name}</TableCell>
                    <TableCell>{audience.description}</TableCell>
                    <TableCell>{audience.id}</TableCell>
                    <TableCell>
                      <Stack direction='row' spacing={2} justifyContent='flex-end'>
                        <IconButton
                          onClick={() => {
                            setIsUpdate(true);

                            const inclusionGroupsCopy = copyGroups(audience.inclusionGroups);
                            setInclusionGroups(inclusionGroupsCopy);

                            const exclusionGroupsCopy = audience.exclusionGroups.length ? copyGroups(audience.exclusionGroups) : copyGroups(exclusionGroups);
                            setExclusionGroups(exclusionGroupsCopy);

                            if (exclusionGroupsCopy.length && exclusionGroupsCopy[0].conditions[0].targetEvent) {
                              setShowExclusionGroup(true);
                            }

                            setNewAudience({
                              name: audience.name,
                              description: audience.description,
                              scope: audience.scope
                            });
                            setCurrentEditIndex(i);
                            setCreateAudience(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            deleteAudience(i);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'pushNotification'} onChange={handleAccordionChange('pushNotification')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='main-panel2-content'
          id='main-panel2-header'
        >
          <Typography variant='h6'>Push Notifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750}}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Audience</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>
                    <Stack direction='row' spacing={2} justifyContent='right'>
                      {isAdmin ? <Button variant='outlined' onClick={() => setShowAddInfo(true)}>Add Info</Button> : null}
                      <IconButton
                        onClick={() => {
                          setIsUpdate(false);
                          setCreateNotification(true);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {strategies.map((strategy, i) => {
                  const audience = audiences.find((audience) => {
                    if (audience.id === strategy.audienceId) {
                      return audience.name;
                    }
                  });
                  return (
                    <TableRow key={`${strategy.name}-${i}`}>
                      <TableCell>{strategy.name}</TableCell>
                      <TableCell>{strategy.title}</TableCell>
                      <TableCell>{audience.name}</TableCell>
                      <TableCell>{strategy.id}</TableCell>
                      <TableCell>
                        <Stack direction='row' spacing={2} justifyContent='flex-end'>
                          <IconButton
                            onClick={() => {
                              setIsUpdate(true);
                              setNewStrategy(strategy);
                              setDelayHours(strategy.delayHours);
                              setDelayMinutes(strategy.delayMinutes);
                              setCurrentEditIndex(i);
                              setCreateNotification(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              toggleNotificationStrategy(strategy, i);
                            }}
                          >
                            {strategy.active ?
                              <PauseIcon /> :
                              <PlayArrowIcon />
                            }
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              deleteNotificationStrategy(i);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={showAddInfo}
        onClose={handleShowAddInfoClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>Add Event Info</DialogTitle>
        <DialogContent>
          <Typography>Add Event Names - (names require snake case - lowercase separated by an underscore ex. ad_view)</Typography>
          <Stack spacing={2}>
            <Stack direction='row' spacing={2}>
              <TextField
                id='add-event-name'
                label='Event Name'
                value={newEventName}
                onChange={(e) => {
                  setNewEventName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setNewEventNameList([...newEventNameList, e.target.value]);
                    setNewEventName('');
                  }
                }}
                variant='outlined'
                sx={{marginTop: 1}}
                fullWidth
              />
              <Button onClick={() => {
                setNewEventNameList([...newEventNameList, newEventName]);
                setNewEventName('');
              }}>
                Add
              </Button>
            </Stack>
            <Stack direction='row' spacing={1} sx={{flexWrap: 'wrap'}}>
              {newEventNameList.map((eventName, i) => {
                return (
                  <Chip key={`${eventName}-${i}`} label={eventName} onDelete={() => handleEventNameDelete(eventName)} sx={{marginBottom: 1}}/>
                )
              })}
            </Stack>
          </Stack>
          <Typography>Add Screen Names - screen names should match their definition in the app's naviagation code</Typography>
          <Stack spacing={2}>
            <Stack direction='row' spacing={2}>
              <TextField
                id='add-screen-name'
                label='Screen Name'
                value={newScreenName}
                onChange={(e) => {
                  setNewScreenName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setNewScreenNameList([...newScreenNameList, e.target.value]);
                    setNewScreenName('');
                  }
                }}
                variant='outlined'
                sx={{marginTop: 1}}
                fullWidth
              />
              <Button onClick={() => {
                setNewScreenNameList([...newScreenNameList, newScreenName]);
                setNewScreenName('');
              }}>
                Add
              </Button>
            </Stack>
            <Stack direction='row' spacing={1} sx={{flexWrap: 'wrap'}}>
              {newScreenNameList.map((screenName, i) => {
                return (
                  <Chip key={`${screenName}-${i}`} label={screenName} onDelete={() => handleScreenNameDelete(screenName)} sx={{marginBottom: 1}}/>
                )
              })}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShowAddInfoClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              addNewEventNames();
            }}
            color="primary"
            disabled={!newEventNameList.length && !newScreenNameList.length}
          >
            Add Info
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createNotification}
        onClose={handleCreateNotificationClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>{isUpdate ? 'Update Notification Strategy' : 'Create Notification Strategy'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              id='strategy-name'
              label='Strategy Name'
              value={newStrategy.name}
              onChange={(e) => {
                setNewStrategy({
                  ...newStrategy,
                  name: e.target.value
                });
              }}
              variant='outlined'
              sx={{marginTop: 1}}
            />
            <FormControl>
              <InputLabel id='target-audience-name-label'>Target Audience</InputLabel>
              <Select
                labelId='target-audience-name-label'
                id='target-audience-name'
                value={newStrategy.audienceId}
                label='Target Audience'
                onChange={(e) => {
                  setNewStrategy({
                    ...newStrategy,
                    audienceId: e.target.value
                  });
                }}

              >
                {audiences.map((audience, index) => {
                  return (
                    <MenuItem key={`${audience.name}-${index}`} value={audience.id}>{audience.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div>
              <Typography style={{marginBottom: 10}}>Delivery Schedule</Typography>
              <Accordion expanded={true}>
                <AccordionSummary
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Stack direction='row' spacing={2} alignItems='center' sx={{width: '100%'}}>
                    <Radio
                      checked={newStrategy.deliveryType === 'delayedTimeDelivery'}
                      name='delayed-time-delivery'
                      inputProps={{'aria-label': 'delayed-time-delivery'}}
                    />
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Delayed Time Delivery</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Respond to an event after a specific amount of time.</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction='row' spacing={2}>
                    <TextField
                      label='Hours'
                      value={newStrategy.delayHours}
                      onChange={(e) => {
                        setDelayHours(parseInt(e.target.value, 10));
                      }}
                    />
                    <ButtonGroup>
                      <Button 
                        onClick={ () => { setDelayHours(prevDelayHours => prevDelayHours + 1) }}><KeyboardArrowUpIcon /></Button>
                      <Button
                        onClick={ () => { setDelayHours(prevDelayHours => prevDelayHours - 1) }}
                        disabled={delayHours === 1}
                      >
                        <KeyboardArrowDownIcon />
                      </Button>
                    </ButtonGroup>
                    <TextField
                      label='Minutes'
                      value={newStrategy.delayMinutes}
                      onChange={(e) => {
                        setDelayMinutes(parseInt(e.target.value, 10));
                      }}
                    />
                    <ButtonGroup>
                      <Button onClick={ () => { setDelayMinutes(prevDelayMinutes => prevDelayMinutes + 1) }}><KeyboardArrowUpIcon /></Button>
                      <Button
                        onClick={ () => { setDelayMinutes(prevDelayMinutes => prevDelayMinutes - 1) }}
                        disabled={delayMinutes === 0}
                      >
                        <KeyboardArrowDownIcon />
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Typography sx={{ marginTop: '10px', color: '#888888' }}>* For as soon as possible notifications or scheduled delivery one time messages use the Firebase Console.</Typography>
            </div>
            <FormControlLabel
              control={<Switch 
                checked={newStrategy.oneTimeFrequency}
                onChange={() => {
                  setNewStrategy({
                    ...newStrategy,
                    oneTimeFrequency: !newStrategy.oneTimeFrequency
                  });
                }}
                inputProps={{ 'aria-label': 'active' }}
              />}
              label='Send message once per user.'
            />
            <br></br>
            <TextField
              id='notification-title'
              label='Notification Title'
              value={newStrategy.title}
              onChange={(e) => {
                setNewStrategy({
                  ...newStrategy,
                  title: e.target.value
                });
              }}
              variant='outlined'
            />
            <TextField
              id='notification-content'
              label='Notification Content'
              value={newStrategy.content}
              onChange={(e) => {
                setNewStrategy({
                  ...newStrategy,
                  content: e.target.value
                });
              }}
              variant='outlined'
              multiline
            />
            <TextField
              id='notification-image'
              label='Notification Image'
              value={newStrategy.imageUrl}
              onChange={(e) => {
                setNewStrategy({
                  ...newStrategy,
                  imageUrl: e.target.value
                });
              }}
              variant='outlined'
            />
            <TextField
              id='notification-link'
              label='Notification Link'
              value={newStrategy.linkUrl}
              onChange={(e) => {
                setNewStrategy({
                  ...newStrategy,
                  linkUrl: e.target.value
                });
              }}
              variant='outlined'
              placeholder='Defaults to Home Page'
            />
            <FormControlLabel
              control={<Switch 
                checked={newStrategy.active}
                onChange={() => {
                  setNewStrategy({
                    ...newStrategy,
                    active: !newStrategy.active
                  });
                }}
                inputProps={{ 'aria-label': 'active' }}
              />}
              label='Active'
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNotificationClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              isUpdate ? updateNotificationStrategy() : createNotificationStrategy();
            }}
            color="primary"
            disabled={!newStrategy.name || !newStrategy.audienceId || !newStrategy.content || !newStrategy.title || !newStrategy.deliveryType}
          >
            {isUpdate ? 'Update' : 'Schedule'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createAudience}
        onClose={handleCreateAudienceClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>{isUpdate ? 'Update Audience' : 'Create Audience'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              id='audience-name'
              label='Audience Name'
              value={newAudience.name}
              onChange={(e) => {
                setNewAudience({
                  ...newAudience,
                  name: e.target.value
                });
              }}
              sx={{marginTop: 1}}
            />
            <TextField
              id='audience-description'
              label='Audience Description'
              value={newAudience.description}
              onChange={(e) => {
                setNewAudience({
                  ...newAudience,
                  description: e.target.value
                });
              }}
            />
            <FormControl>
              <InputLabel id='target-audience-scope-label'>Scope</InputLabel>
              <Select
                labelId='target-audience-scope-label'
                id='target-audience-scope'
                value={newAudience.scope}
                label='Audience Scope'
                onChange={(e) => {
                  setNewAudience({
                    ...newAudience,
                    scope: e.target.value
                  });
                }}
              >
                {audienceScope.map((scope, index) => {
                  return (
                    <MenuItem key={`${scope}-${index}`} value={scope}>{scope.charAt(0).toUpperCase() + scope.slice(1)}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Paper sx={{padding: '16px', backgroundColor: '#E6E6E6', borderLeft: '4px solid #00BFA5'}}>
              <Typography sx={{marginBottom: 1}}>Include Users when:</Typography>
              {inclusionGroups.map((group, i) => {
                return(
                  <Fragment key={`inclusion-group-${i}`}>
                    {(inclusionGroups.length > 1 && i !== 0) ?
                      <div style={{position: 'relative'}}>
                        <hr style={{borderTop: '1px solid #FFFFFF', marginTop: '16px', marginBottom: '16px'}} />
                        <Typography style={{position: 'absolute', top: -10, left: 15, zIndex: 10, paddingLeft: '5px', paddingRight: '5px', backgroundColor: '#E6E6E6', color: '#707070'}}>AND</Typography>
                      </div>
                      : null
                    }
                    <Card
                      sx={{marginBottom: 2}}
                    >
                      <CardContent>
                        <>
                          {(newAudience.scope === 'sequential' && i === 0) ?
                            <Typography style={{marginBottom: '10px'}}>Initial Target Group</Typography> :
                            null
                          }
                          {group.conditions.length ?
                            (group.conditions.map((condition, j) => {
                              return (
                                <Fragment key={`${condition.name}-${j}`}>
                                  {(group.conditions.length > 1 && j !== 0) ?
                                    <div style={{position: 'relative'}}>
                                      {condition.condition === 'and' ?
                                        <hr style={{borderTop: '1px solid #E6E6E6', marginTop: '15px', marginBottom: '19px'}} /> :
                                        <hr style={{borderTop: '1px dashed #E6E6E6', marginTop: '15px', marginBottom: '19px', marginLeft: '24px'}} />
                                      }
                                      <Typography style={{position: 'absolute', top: -10, left: 15, zIndex: 10, paddingLeft: '5px', paddingRight: '5px', backgroundColor: '#FFF', color: '#707070'}}>{condition.condition.toUpperCase()}</Typography>
                                    </div>
                                    : null
                                  }
                                  <Stack direction='row' spacing={2} sx={{marginBottom: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                                    <ConditionSelect
                                      events={events}
                                      condition={condition}
                                      updateConditions={(target, value) => updateConditions(target, value, true, i, j)}
                                    />
                                    {(group.conditions.length - 1 === j) ?
                                      <Button
                                        variant='outline'
                                        sx={{marginTop : 1}}
                                        onClick={() => {
                                          const inclusionGroupsCopy = copyGroups(inclusionGroups);
                                          inclusionGroupsCopy[i].conditions.push({targetEvent: '', condition: 'or'});
                                          setInclusionGroups(inclusionGroupsCopy);
                                        }}
                                      >
                                        Or
                                      </Button>
                                      : null
                                    }
                                    {i === 0 && j === 0 ?
                                      null :
                                      <IconButton
                                        onClick={() => {
                                          const inclusionGroupsCopy = copyGroups(inclusionGroups);

                                          inclusionGroupsCopy[i].conditions.splice(j, 1);

                                          const newInclusionGroups = inclusionGroupsCopy.filter((group) => {
                                            return !group.conditions.length ? false : true;
                                          });

                                          setInclusionGroups(newInclusionGroups);
                                        }}
                                      >
                                        <CancelIcon style={{height: '24px', width: '24px'}} />
                                      </IconButton>
                                    }
                                  </Stack>
                                </Fragment>
                              )
                            }))
                            : null
                          }
                          <Button
                            variant='outline'
                            sx={{marginTop : 1}}
                            onClick={() => {
                              const inclusionGroupsCopy = copyGroups(inclusionGroups);
                              inclusionGroupsCopy[i].conditions.push({targetEvent: '', condition: 'and'});
                              setInclusionGroups(inclusionGroupsCopy);
                            }}
                          >
                            And
                          </Button>
                        </>
                      </CardContent>
                    </Card>
                  </Fragment>
                )
              })}
              <Button
                variant='text'
                startIcon={<AddIcon />}
                sx={{marginTop : 1}}
                onClick={() => {
                  const inclusionGroupsCopy = copyGroups(inclusionGroups);

                  inclusionGroupsCopy.push({
                    conditions: [
                      {targetEvent: '', condition: ''},
                    ]
                  });
            
                  setInclusionGroups(inclusionGroupsCopy);
                }}
              >
                Add Condition Group
              </Button>
            </Paper>
            {!showExclusionGroup ?
              <Button
                variant='text'
                startIcon={<AddIcon />}
                sx={{marginTop : 2, width: '30%'}}
                onClick={() => {
                  setExclusionGroups([{
                    conditions: [
                      {targetEvent: '', condition: ''},
                    ]
                  }]);
                  setShowExclusionGroup(true);
                }}
              >
                Add Group to Exclude
              </Button>
              : null
            }
            {showExclusionGroup && exclusionGroups.length ?
              <Paper sx={{padding: '16px', backgroundColor: '#E6E6E6', borderLeft: '4px solid #FF5252'}}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography sx={{marginBottom: 1}}>Exclude Users when:</Typography>
                  <IconButton
                    onClick={() => {
                      setExclusionGroups([]);
                      setShowExclusionGroup(false);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                {exclusionGroups.map((group, i) => {
                  return (
                    <Fragment key={`exclusion-group-${i}`}>
                      {(exclusionGroups.length > 1 && i !== 0) ?
                        <div style={{position: 'relative'}}>
                          <hr style={{borderTop: '1px solid #FFFFFF', marginTop: '16px', marginBottom: '16px'}} />
                          <Typography style={{position: 'absolute', top: -10, left: 15, zIndex: 10, paddingLeft: '5px', paddingRight: '5px', backgroundColor: '#E6E6E6', color: '#707070'}}>AND</Typography>
                        </div>
                        : null
                      }
                      <Card
                        sx={{marginBottom: 2}}
                      >
                        <CardContent>
                          <>
                            {group.conditions.length ?
                              (group.conditions.map((condition, j) => {
                                return (
                                  <Fragment key={`${condition.name}-${j}`}>
                                    {(group.conditions.length > 1 && j !== 0) ?
                                    <div style={{position: 'relative'}}>
                                      {condition.condition === 'and' ?
                                        <hr style={{borderTop: '1px solid #E6E6E6', marginTop: '12px', marginBottom: '20px'}} /> :
                                        <hr style={{borderTop: '1px dashed #E6E6E6', marginTop: '12px', marginBottom: '20px', marginLeft: '24px'}} />
                                      }
                                      <Typography style={{position: 'absolute', top: -10, left: 15, zIndex: 10, paddingLeft: '5px', paddingRight: '5px', backgroundColor: '#FFF', color: '#707070'}}>{condition.condition.toUpperCase()}</Typography>
                                    </div>
                                    : null
                                  }
                                    <Stack direction='row' spacing={2} sx={{marginBottom: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                                      <ConditionSelect
                                        events={events}
                                        condition={condition}
                                        updateConditions={(target, value) => updateConditions(target, value, false, i, j)}
                                      />
                                      {(j === group.conditions.length - 1) ?
                                        <Button
                                          variant='outline'
                                          sx={{marginTop : 1}}
                                          onClick={() => {
                                            const exclusionGroupsCopy = copyGroups(exclusionGroups);
                                            exclusionGroupsCopy[i].conditions.push({targetEvent: '', condition: 'or'});
                                            setExclusionGroups(exclusionGroupsCopy);
                                          }}
                                        >
                                          Or
                                        </Button>
                                        : null
                                      }
                                      <IconButton
                                        onClick={() => {
                                          const exclusionGroupsCopy = copyGroups(exclusionGroups);

                                          if (exclusionGroupsCopy.length === 1 && exclusionGroupsCopy[i].conditions.length === 1) {
                                            setExclusionGroups([{
                                              conditions: [
                                                {targetEvent: '', condition: ''},
                                              ]
                                            }]);
                                            setShowExclusionGroup(false);
                                          }  else {
                                            exclusionGroupsCopy[i].conditions.splice(j, 1);
    
                                            const newExclusionGroups = exclusionGroupsCopy.filter((group) => {
                                              return !group.conditions.length ? false : true;
                                            });
    
                                            setExclusionGroups(newExclusionGroups);
                                          }
                                        }}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Stack>
                                  </Fragment>
                                )
                              }))
                              : null
                            }
                            <Button
                              variant='outline'
                              sx={{marginTop : 1}}
                              onClick={() => {
                                const exclusionGroupsCopy = copyGroups(exclusionGroups);

                                exclusionGroupsCopy[i].conditions.push({
                                  targetEvent: '',
                                  condition: 'and'
                                });
                          
                                setExclusionGroups(exclusionGroupsCopy);
                              }}
                            >
                              And
                          </Button>
                          </>
                        </CardContent>
                      </Card>
                    </Fragment>
                  )
                })}
                <Button
                  variant='text'
                  startIcon={<AddIcon />}
                  sx={{marginTop : 1}}
                  onClick={() => {
                    const exclusionGroupsCopy = copyGroups(exclusionGroups);

                    exclusionGroupsCopy.push({
                      conditions: [
                        {targetEvent: '', condition: ''},
                      ]
                    });
              
                    setExclusionGroups(exclusionGroupsCopy);
                  }}
                >
                  Add Condition Group
                </Button>
              </Paper>
              : null
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateAudienceClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              !isUpdate ? createNewAudience() : updateAudience();
            }}
            color="primary"
            disabled={!newAudience.name || (!inclusionGroups[0].conditions[0].targetEvent.length)}
          >
            {isUpdate ? 'Update Audience' : 'Create Audience'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PushNotifications;
