import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './completionPopupsAnalytics.scss';
import { config } from '../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endToday = new Date();
endToday.setHours(23, 59, 59, 999);

function CompletionPopupsAnalytics({}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [startTime, setStartTime] = useState(moment().subtract(7, 'days').startOf('day').toDate());
  const [endTime, setEndTime] = useState(moment().endOf('day').toDate());
  const [results, setResults] = useState([]);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    setLoading(true);

    try {
      const start = moment(startTime).valueOf() * 1000;
      const end = moment(endTime).valueOf() * 1000;
      const token = await firebase.auth().currentUser.getIdToken();
      const responseMap = {};

      const viewsResponse = await axios(`${config.gateway}/analytics-service/admin/v1/completion-ad/views?start=${start}&end=${end}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      viewsResponse.data.forEach(r => responseMap[r.name] = { views: r.count });

      const clicksResponse = await axios(`${config.gateway}/analytics-service/admin/v1/completion-ad/clicks?start=${start}&end=${end}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      clicksResponse.data.forEach(r => responseMap[r.name].clicks = r.count);

      const data = [];

      for (let key in responseMap) {
        data.push({
          name: key,
          views: responseMap[key].views,
          count: responseMap[key].count,
        });
      }

      setResults(data);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving Completion Ad analytics, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CompletionPopupsAnalytics">
      {renderLoading()}
      <div className="CompletionPopupsAnalyticsInner">
        <div>
          <Link to="/academy-content/completion-ads">
            <Button 
              variant="contained"
              color="primary"
              size="small"
              style={{marginRight: '10px', marginBottom: '15px'}}
              startIcon={<ArrowBackIcon />}
            >
              Completion Ads
            </Button>
          </Link>
        </div>

        <Card className="drag-card">
          <Toolbar className="toolbar">
            <Typography variant="h6">
              Completion Ads Analytics
            </Typography>

            <div></div>
          </Toolbar>

          <Divider/>

          <CardContent>
            <div className="dates-row">
              <div className="date-picker-container">
                <Typography>
                  <small>Start</small>
                </Typography>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => { setStartTime(date) }}
                  maxDate={endToday}
                />
              </div>
              <div className="date-divider" style={{marginTop: 14}}>-</div>
              <div className="date-picker-container">
                <Typography>
                  <small>End</small>
                </Typography>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => { setEndTime(date) }}
                  maxDate={endToday}
                />
              </div>

              <div className="dates-row-item-container">
                <Button variant="contained" onClick={fetchAnalytics} color="primary">
                  Submit
                </Button>
              </div>
            </div>

            <div className="table-container">
              <TableContainer>
                <Table aria-label="search partners table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Ad Name</strong></TableCell>
                      <TableCell><strong>Views</strong></TableCell>
                      <TableCell><strong>Clicks</strong></TableCell>
                      <TableCell><strong>Conversions</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((r, i) => (
                      <TableRow key={`result-${i}`}>
                        <TableCell><strong>{r.name}</strong></TableCell>
                        <TableCell>{r.views}</TableCell>
                        <TableCell>{r.clicks}</TableCell>
                        <TableCell>{r.conversions}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CompletionPopupsAnalytics;
