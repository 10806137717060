import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './shared/itemTypes';
import Row from './row';

const styles = {
  container: {
    padding: '5px',
    border: '1px dashed #00a0d2',
    margin: '5px 15px',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.3)',
  },
};

const cloneDeep = require('lodash.clonedeep');

function Container({ content, updateContent, saveModule }) {
  const [{ isOverCurrent }, drop] = useDrop({
    accept: ItemTypes.ROW,
    drop(item, monitor) {
      if (monitor.didDrop()) {
        return;
      }

      updateContent([
        ...content,
        {
          ...item,
        },
      ]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  let borderStyle = 'dashed';
  if (isOverCurrent) {
    borderStyle = 'solid';
  }

  return (
    <div ref={drop} style={{ ...styles.container, borderStyle, padding: !content.length ? '0 10px' : '5px 5px 0 5px' }}>
      {!content.length ? <h1 style={styles.heading}>Drop a row here to get started</h1> :
        content.map((row, i) => {
          return (
            <Row
              key={`row-${i}`}
              content={row}
              saveModule={saveModule}
              copyClicked={() => {
                const contentCopy = [ ...content ];
                const rowClone = cloneDeep(row);

                contentCopy.splice(i + 1, 0, rowClone);

                updateContent(contentCopy);
              }}
              upClicked={() => {
                if (i !== 0) {
                  const contentCopy = [ ...content ];

                  const itemToMoveUp = { ...row };
                  const itemToMoveDown = { ...contentCopy[i - 1] };

                  contentCopy[i] = itemToMoveDown;
                  contentCopy[i - 1] = itemToMoveUp;

                  updateContent(contentCopy);
                }
              }}
              downClicked={() => {
                if (i !== content.length - 1) {
                  const contentCopy = [ ...content ];

                  const itemToMoveDown = { ...row };
                  const itemToMoveUp = { ...contentCopy[i + 1] };

                  contentCopy[i] = itemToMoveUp;
                  contentCopy[i + 1] = itemToMoveDown;

                  updateContent(contentCopy);
                }
              }}
              updateContent={(updated) => {
                const contentCopy = [ ...content ];

                contentCopy[i] = updated;

                updateContent(contentCopy);
              }}
              deleteItem={() => {
                const contentCopy = [ ...content ];

                contentCopy.splice(i, 1);

                updateContent(contentCopy);
              }}
            />
          );
        })
      }
    </div>
  );
};

export default Container;
