import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { ColorPicker } from '../../../../../colorPicker/colorPicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProductsCategoriesItems } from './productsCategoriesItems';

export const ProductsCategoriesSection = () => {
  const form = useFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Products Categories Section</strong>
        </Typography>
      </AccordionSummary>
      <Divider />

      <AccordionDetails
        style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div className="form-row">
              <Controller
                name="productCategoriesSection.title"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Title"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.productCategoriesSection?.title)}
                      helperText={
                        errors.productCategoriesSection?.title?.message
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="form-row">
              <Controller
                name="productCategoriesSection.subtitle"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="SubTitle"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.productCategoriesSection?.subtitle)}
                      helperText={
                        errors.productCategoriesSection?.subtitle?.message
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="form-row">
              <Controller
                name="productCategoriesSection.content"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Content"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.productCategoriesSection?.content)}
                      helperText={
                        errors.productCategoriesSection?.content?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="productCategoriesSection.backgroundColor"
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      label="Background Color"
                      field={field}
                      error={Boolean(
                        errors.productCategoriesSection?.backgroundColor,
                      )}
                      helperText={
                        errors.productCategoriesSection?.backgroundColor
                          ?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="productCategoriesSection.categories.header"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Categories Header"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.productCategoriesSection?.categories?.header,
                      )}
                      helperText={
                        errors.productCategoriesSection?.categories?.header
                          ?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <ProductsCategoriesItems control={control} errors={errors} />

            <div className="form-row">
              <Controller
                name="productCategoriesSection.imageUrl"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Image Url"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.productCategoriesSection?.imageUrl)}
                      helperText={
                        errors.productCategoriesSection?.imageUrl?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="productCategoriesSection.buttonText"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Button Text"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.productCategoriesSection?.buttonText,
                      )}
                      helperText={
                        errors.productCategoriesSection?.buttonText?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="productCategoriesSection.buttonUrl"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Button Url"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.productCategoriesSection?.buttonUrl,
                      )}
                      helperText={
                        errors.productCategoriesSection?.buttonUrl?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <div className="section-container">
                <div className="section-label" style={{ margin: 0 }}>
                  Image Position
                </div>
                <Controller
                  name="productCategoriesSection.imagePosition"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          {...field}
                        >
                          <FormControlLabel
                            value="left"
                            control={<Radio />}
                            label="Left"
                          />
                          <FormControlLabel
                            value="right"
                            control={<Radio />}
                            label="Right"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />
              </div>
            </div>
          </Box>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
