import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './flaggedComments.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function FlaggedComments() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [flaggedComments, setFlaggedComments] = useState([]);
  const [viewIndex, setViewIndex] = useState(-1);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await axios(`${config.gateway}/community-service/api/v1/comments/reported?count=${pageCount}&page${currentPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setNumberOfPages(Math.floor(response.data.count / pageCount));
        setFlaggedComments(response.data.comments);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText('An error occurred fetching flagged comments. Refresh the page and try again.');
      }
    };

    fetchComments();
  }, [currentPage]);

  const markFlaggedCommentAsResolved = async () => {
    const index = viewIndex;

    setViewIndex(-1);
    setLoading(true);

    try {
      const flaggedComment = flaggedComments[index];
      const commentId = flaggedComment.commentId;
      const userId = flaggedComment.userId;
      const token = await firebase.auth().currentUser.getIdToken();
      await axios(`${config.gateway}/community-service/api/v1/comments/reported/resolve`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          commentId,
          userId,
        },
      });

      const updatedFlaggedComments = [ ...flaggedComments ];
      updatedFlaggedComments.splice(index, 1);

      setFlaggedComments(updatedFlaggedComments);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred resolving flagged comment, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="FlaggedComments">
      {renderLoading()}
      <Card>
        <Toolbar className="toolbar">
          <Typography variant="h6">
            Flagged Comments
          </Typography>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table aria-label="flagged comments table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Post ID</strong></TableCell>
                <TableCell><strong>User</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flaggedComments.map((c, i) => (
                <TableRow key={`${c.commentId}-${c.userId}`}>
                  <TableCell>
                    {c.comment.postId}
                  </TableCell>
                  <TableCell>
                    <Link to={`/manage-users/users/${c.user.firebaseUserId}`}>
                      {c.user.name}
                    </Link>
                  </TableCell>
                  <TableCell>{moment(c.created).format('lll')}</TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      color="default"
                      aria-label="edit"
                      style={{color: '#000'}}
                      onClick={() => setViewIndex(i)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider/>

        {!numberOfPages ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={currentPage === 1} variant="contained" size="small" onClick={() => setCurrentPage(currentPage - 1)} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{currentPage}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={currentPage === numberOfPages} variant="contained" size="small" onClick={() => setCurrentPage(currentPage + 1)} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog fullWidth maxWidth="sm" open={viewIndex !== -1} onClose={() => setViewIndex(-1)} TransitionComponent={Transition}>
        <DialogTitle>Flagged Comment Details</DialogTitle>
        <DialogContent>
          {viewIndex == -1 ? null :
            <>
              <DialogContentText><strong>Comment:</strong></DialogContentText>
              <DialogContentText>{flaggedComments[viewIndex].comment.text}</DialogContentText>
              <DialogContentText><strong>Flagged Reason:</strong></DialogContentText>
              <DialogContentText>{flaggedComments[viewIndex].reason}</DialogContentText>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setViewIndex(-1)} color="primary">
            Close
          </Button>
          <Button variant="contained" onClick={() => markFlaggedCommentAsResolved()} color="secondary">
            Mark as Resolved
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FlaggedComments;
