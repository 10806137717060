import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { useStatisticsFields } from './useStatisticsFields';

export const StatisticsItems = ({ control, errors }) => {
  const {
    statisticsItems,
    appendStatisticItem,
    removeStatisticItem,
    moveStatisticItem,
  } = useStatisticsFields(control);

  return (
    <div className="section-container">
      <div className="section-label">Items</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 8px',
        }}
      >
        {statisticsItems?.map((item, index) => (
          <Card
            key={item.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              alignItems: 'center',
            }}
            variant="outlined"
          >
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>
                <strong>Item {index + 1}</strong>
              </span>

              <Box
                sx={{
                  gap: '8px',
                  display: 'flex',
                }}
              >
                {index !== statisticsItems.length - 1 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveStatisticItem(index, index + 1)}
                  >
                    <ArrowDownIcon />
                  </IconButton>
                ) : null}

                {index !== 0 ? (
                  <IconButton
                    color="inherit"
                    onClick={() => moveStatisticItem(index, index - 1)}
                  >
                    <ArrowUpIcon />
                  </IconButton>
                ) : null}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  {index === statisticsItems.length - 1 &&
                  statisticsItems.length < 3 ? (
                    <IconButton
                      color="inherit"
                      onClick={() => appendStatisticItem()}
                      aria-label="Add Menu Item"
                    >
                      <AddIcon />
                    </IconButton>
                  ) : null}

                  {index !== 0 ? (
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      onClick={() => removeStatisticItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
            </Toolbar>

            <Divider />
            <CardContent>
              <Controller
                name={`statisticsSection.items.${index}.quantity`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Quantity"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      className="day-text-field"
                      error={Boolean(
                        errors.statisticsSection?.items?.[index]?.quantity,
                      )}
                      helperText={
                        errors.statisticsSection?.items?.[index]?.quantity
                          ?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`statisticsSection.items.${index}.title`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Title"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.statisticsSection?.items?.[index]?.title,
                      )}
                      helperText={
                        errors.statisticsSection?.items?.[index]?.title?.message
                      }
                    />
                  );
                }}
              />

              <Controller
                name={`statisticsSection.items.${index}.imageUrl`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Image Url"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(
                        errors.statisticsSection?.items?.[index]?.imageUrl,
                      )}
                      helperText={
                        errors.statisticsSection?.items?.[index]?.imageUrl
                          ?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`statisticsSection.items.${index}.isRating`}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          name="live"
                          color="primary"
                          error={Boolean(
                            errors.statisticsSection?.items?.[index]?.isRating,
                          )}
                          helperText={
                            errors.statisticsSection?.items?.[index]?.isRating
                              ?.message
                          }
                        />
                      }
                      label="Is Rating"
                    />
                  );
                }}
              />

              <Controller
                name={`statisticsSection.items.${index}.ratingValue`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Rating Value"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      className="day-text-field"
                      error={Boolean(
                        errors.statisticsSection?.items?.[index]?.ratingValue,
                      )}
                      helperText={
                        errors.statisticsSection?.items?.[index]?.ratingValue
                          ?.message
                      }
                    />
                  );
                }}
              />
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
};
