import React, { useState, useEffect } from 'react';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Button,
  Typography,
  Card,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Popover,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './actionVerification.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function ActionVerification({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [actionData, setActionData] = useState({});
  const [actions, setActions] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [currentActionIndex, setCurrentActionIndex] = useState(-1);
  const [giftCardModalOpen, setGiftCardModalOpen] = useState(false);
  const [giftCardAmount, setGiftCardAmount] = useState(5);
  const [rewardsPointsModalOpen, setRewardsPointsModalOpen] = useState(false);
  const [rewardsPointsAmount, setRewardsPointsAmount] = useState(5);

  useEffect(() => {
    fetchActions();
  }, []);

  const fetchActions = async () => {
    const db = firebase.firestore();

    try {
      const dataSnapshot = await db.collection('action-verification').doc('data').get();
      const actionsSnapshot = await db.collection('action-verification').orderBy('created', 'desc').limit(pageCount).get();

      const actionsData = dataSnapshot.data();
      const actionPages = Math.ceil(actionsData.count / 10);

      const mappedActions = actionsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setNumberOfPages(actionPages);
      setActionData(actionsData);
      setActions(mappedActions);
      setFirst(actionsSnapshot.docs[0]);
      setLast(actionsSnapshot.docs[actionsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving action verification data. Refresh the page and try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let actionsSnapshot;
      let newPage = page;

      if (value === 'next') {
        actionsSnapshot = await db.collection('action-verification').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        actionsSnapshot = await db.collection('action-verification').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedActions = actionsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setActions(mappedActions);
      setFirst(actionsSnapshot.docs[0]);
      setLast(actionsSnapshot.docs[actionsSnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving actions data. Please try again.');
    }
  };

  const approveAndSendGiftCard = async () => {
    setLoading(true);

    try {
      const card = await firebase.firestore().collection('gift-cards').add({
        activity: [],
        amount: parseFloat(giftCardAmount),
        cardImage: 'https://pupford.b-cdn.net/assets%2Fproducts%2F9hkqBJMrgv7JWfrPwgL6%2F1632930062191-1.png?alt=media&token=8d8a43a8-37e7-4d44-9e5a-6b4d25a830a5',
        cardMessage: '',
        deliveryDate: Date.now(),
        orderId: '',
        recipientEmail: actions[currentActionIndex].email,
        recipientName: actions[currentActionIndex].name,
        senderName: 'Pupford',
      });

      const action = `gift-card-sent-${card.id}`;

      await firebase.firestore().collection('action-verification').doc(actions[currentActionIndex].id).update({
        action,
      });

      const actionsCopy = [ ...actions ];
      actionsCopy[currentActionIndex] = {
        ...actionsCopy[currentActionIndex],
        action,
      };

      setActions(actionsCopy);
      setLoading(false);
      setGiftCardModalOpen(false);
      setCurrentActionIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this action. Please try again.');
    }
  };

  const approveAndSubmitRewardsDollars = async () => {
    setLoading(true);

    try {
      const currentAction = actions[currentActionIndex];
      const amount = parseFloat(rewardsPointsAmount);
      const dollarsPerPoint = 0.01;
      const points = amount / dollarsPerPoint;
      const created = moment().valueOf();

      const userRewardsSnapshot = await firebase.firestore().collection('user-rewards').doc(currentAction.userId).get();
      const userRewardsData = userRewardsSnapshot.data() || {
        earnings: [],
        spent: [],
      };

      const id = `earnings-${userRewardsData.earnings.length}`;

      userRewardsData.earnings.push({
        id,
        type: 'chewy-review',
        points,
        dollarsPerPoint,
        created,
        expires: null,
      });

      await firebase.firestore().collection('user-rewards').doc(currentAction.userId).set(userRewardsData);

      const action = `rewards-points-sent-${currentAction.userId}-${Date.now()}`;

      await firebase.firestore().collection('action-verification').doc(currentAction.id).update({
        action,
      });

      const actionsCopy = [ ...actions ];
      actionsCopy[currentActionIndex] = {
        ...actionsCopy[currentActionIndex],
        action,
      };

      setActions(actionsCopy);
      setLoading(false);
      setRewardsPointsModalOpen(false);
      setCurrentActionIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this action. Please try again.');
    }
  };

  const disapprove = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('action-verification').doc(actions[currentActionIndex].id).update({
        action: 'rejected',
      });

      const actionsCopy = [ ...actions ];
      actionsCopy[currentActionIndex] = {
        ...actionsCopy[currentActionIndex],
        action: 'rejected',
      };

      setActions(actionsCopy);
      setLoading(false);
      setCurrentActionIndex(-1);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating this action. Please try again.');
    }
  };

  const handleApproveClicked = () => {
    if (actions[currentActionIndex].type === 'points') {
      setRewardsPointsAmount(5);
      setRewardsPointsModalOpen(true);
    } else {
      setGiftCardAmount(5);
      setGiftCardModalOpen(true);
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ActionVerification">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Action Verification
          </Typography>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table aria-label="action verification table">
            <TableHead>
              <TableRow>
                <TableCell><strong>User</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Resolution</strong></TableCell>
                <TableCell><strong>Waiting Since</strong></TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.map((c, i) => (
                <TableRow key={c.id}>
                  <TableCell>
                    <div style={{marginTop: 5}}><strong>Name:</strong> {c.name}</div>
                    <div><strong>Email:</strong> {c.email}</div>
                  </TableCell>
                  <TableCell>
                    <div style={{color: c.action ? '#7ED242' : 'red'}}>
                      {c.action ? 'Resolved' : 'Waiting'}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="resolution-cell">
                      {!c.action ? 'unresolved' : c.action.split('-').join(' ')}
                    </div>
                  </TableCell>
                  <TableCell>{moment(c.created).fromNow()}</TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      style={{marginLeft: 10}}
                      color="default"
                      aria-label="edit"
                      onClick={() => setCurrentActionIndex(i)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!numberOfPages ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog fullWidth maxWidth="sm" open={currentActionIndex !== -1} onClose={() => { setCurrentActionIndex(-1) }} TransitionComponent={Transition}>
        {currentActionIndex === -1 ? null :
          <DialogContent>
            <DialogContentText><strong>Action ID: {actions[currentActionIndex].id}</strong></DialogContentText>
            <DialogContentText><strong>Date:</strong> {moment(actions[currentActionIndex].created).format('lll')}</DialogContentText>
            <DialogContentText><strong>Name:</strong> {actions[currentActionIndex].name}</DialogContentText>
            <DialogContentText><strong>Email:</strong> {actions[currentActionIndex].email}</DialogContentText>
            {actions[currentActionIndex].urls.map((url, i) => {
              return (
                <div key={`url-${i}`}>
                  <DialogContentText><strong>Image {i + 1}:</strong></DialogContentText>
                  <img
                    src={url}
                    width="100%"
                  />
                </div>
              );
            })}
          </DialogContent>
        }
        <DialogActions>
          {currentActionIndex !== -1 && actions[currentActionIndex].action ? null :
            <>
              <Button
                variant="contained"
                onClick={handleApproveClicked}
                style={{backgroundColor: '#2bb673', color: '#fff'}}
              >
                Approve
              </Button>
              <Button variant="contained" onClick={disapprove} color="secondary">
                Disapprove
              </Button>
            </>
          }
          <Button variant="contained" onClick={() => { setCurrentActionIndex(-1) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={rewardsPointsModalOpen} onClose={() => { setRewardsPointsModalOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add User Rewards Dollars</DialogTitle>
        <DialogContent>
          <FormControl
            variant="outlined"
            style={{marginTop: 8, marginBottom: 4, marginRight: 5, flex: 1}}
            className="day-text-field"
          >
            <InputLabel>Rewards Dollars Amount</InputLabel>
            <OutlinedInput
              value={rewardsPointsAmount}
              onChange={(e) => {
                setRewardsPointsAmount(e.target.value);
              }}
              margin="dense"
              label="Rewards Dollars Amount"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              className="day-text-field"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={approveAndSubmitRewardsDollars} color="primary" disabled={!giftCardAmount}>
            Submit Rewards Dollars
          </Button>
          <Button variant="contained" onClick={() => { setRewardsPointsModalOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={giftCardModalOpen} onClose={() => { setGiftCardModalOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Send User Gift Card</DialogTitle>
        <DialogContent>
          <FormControl
            variant="outlined"
            style={{marginTop: 8, marginBottom: 4, marginRight: 5, flex: 1}}
            className="day-text-field"
          >
            <InputLabel>Gift Card Amount</InputLabel>
            <OutlinedInput
              value={giftCardAmount}
              onChange={(e) => {
                setGiftCardAmount(e.target.value);
              }}
              margin="dense"
              label="Gift Card Amount"
              variant="outlined"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              className="day-text-field"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={approveAndSendGiftCard} color="primary" disabled={!giftCardAmount}>
            Send Gift Card
          </Button>
          <Button variant="contained" onClick={() => { setGiftCardModalOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ActionVerification;
