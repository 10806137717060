import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import Forms from './forms/forms';
import Form from './forms/form/form';
import FormReports from './forms/formReports/formReports';
import ConvertBoxSendlaneFields from './convertBoxSendlaneFields/convertBoxSendlaneFields';
import TypeformSendlaneFields from './typeformSendlaneFields/typeformSendlaneFields';

function MarketingTools(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        {/* <Route path="/marketing-tools/dashboard" component={Dashboard} /> */}
        <Route path="/marketing-tools/forms/:id/form-reports" component={FormReports} />
        <Route path="/marketing-tools/forms/:id" component={Form} />
        <Route path="/marketing-tools/forms" component={Forms} />
        <Route path="/marketing-tools/convert-box-sendlane-fields" component={ConvertBoxSendlaneFields} />
        <Route path="/marketing-tools/typeform-sendlane-fields" component={TypeformSendlaneFields} />
        <Route component={Forms}/>
      </Switch>
    </div>
  );
}

export default MarketingTools;
