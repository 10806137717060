import React from 'react';
import {
  TextField,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import './section.scss';
import DeletableChip from '../../../shared/deletableChip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Section(props) {

  const renderSectionInput = (s, i) => {
    if (s.type === 'title' || s.type === 'subtitle') {
      return (
        <TextField
          style={{ width: '100%' }}
          label={s.type.toUpperCase()}
          value={s.value}
          onChange={(e) => { props.handleChange(e.target.value, i) }}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'youtube') {
      return (
        <TextField
          style={{ width: '100%' }}
          label="YouTube Video ID"
          value={s.value}
          onChange={(e) => { props.handleChange(e.target.value, i) }}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'loopedVideo') {
      return (
        <div className='day-text-field'>
          <TextField
            style={{ width: '100%' }}
            label="Looped Video URL"
            value={s.value}
            onChange={(e) => { props.handleChange(e.target.value, i) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
        </div>
      );
    } else if (s.type === 'video') {
      return (
        <div className="day-text-field">
          <TextField
            label={s.type.toUpperCase() + ' URL'}
            value={s.value}
            onChange={(e) => { props.handleChange(e.target.value, i) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label={'Thumbnail'}
            value={s.thumbnail}
            onChange={(e) => { props.handleVideoThumbnailChange(e.target.value, i) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          <div className="section-container">
            <div className="section-label">Video Text Tracks</div>

            <List>
              {(s.textTracks || []).map((track, j) => {
                return (
                  <ListItem key={`track-${j}`}>
                    <TextField
                      label={`Track ${j + 1} Title`}
                      value={track.title}
                      onChange={(e) => { props.handleTextTrackFieldChange('title', e.target.value, i, j) }}
                      margin="normal"
                      variant="outlined"
                      className="day-text-field text-track-field"
                    />

                    <TextField
                      label={`Track ${j + 1} URL`}
                      value={track.uri}
                      onChange={(e) => { props.handleTextTrackFieldChange('uri', e.target.value, i, j) }}
                      margin="normal"
                      variant="outlined"
                      className="day-text-field text-track-field"
                    />

                    <IconButton edge="start" color="inherit" onClick={() => props.removeTextTrack(i, j)} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>

            <Button
              color="inherit"
              onClick={() => { props.addTextTrack(i) }}
            >
              Add Text Track
            </Button>
          </div>
        </div>
      );
    } else if (s.type === 'pdf' || s.type === 'quiz') {
      return (
        <TextField
          label={s.type.toUpperCase() + ' URL'}
          value={s.value}
          onChange={(e) => { props.handleChange(e.target.value, i) }}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'products') {
      return (
        <FormControl style={{ margin: 5, width: '99%' }}>
          <InputLabel htmlFor="select-multiple-chip">Related Products</InputLabel>
          <Select
            multiple
            value={s.value}
            onChange={(e) => { props.handleChange(e.target.value, i) }}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map(value => (
                  <DeletableChip
                    key={value.id}
                    label={value.name}
                    style={{ margin: 2 }}
                    color="primary"
                    onDelete={() => {
                      const index = s.value.indexOf(value);
                      const products = s.value.slice();

                      products.splice(index, 1);

                      props.handleChange(products, i)
                    }}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {props.products.filter(product => {
              return !s.value.includes(product.id);
            }).map(product => (
              <MenuItem key={product.id} value={product}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (s.type === 'image') {
      return (
        <div>
          <TextField
            label={s.type.toUpperCase() + ' URL'}
            value={s.value}
            onChange={(e) => { props.handleChange(e.target.value, i) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label={'Width (percentage of screen width)'}
            value={s.width}
            onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'width') }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{ width: '32%' }}
          />
          <TextField
            label={'Margin Top'}
            value={s.marginTop}
            onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'marginTop') }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{ width: '32%', marginLeft: '2%' }}
          />
          <TextField
            label={'Margin Bottom'}
            value={s.marginBottom}
            onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'marginBottom') }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{ width: '32%', marginLeft: '2%' }}
          />
          <FormControl className="day-text-field" variant="outlined" margin="normal">
            <InputLabel>HIDE ON NEWER VERSIONS (GIFS)</InputLabel>
            <Select
              label="HIDE ON NEWER VERSIONS (GIFS)"
              value={s.hideFromVersionsThatSupportLoopedVideo || false}
              defaultValue={false}
              onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'hideFromVersionsThatSupportLoopedVideo') }}
              className="day-text-field"
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    } else if (s.type === 'paragraph') {
      return (
        <div style={{ width: '100%' }}>
          <TextField
            label={s.type.toUpperCase()}
            value={s.value}
            onChange={(e) => { props.handleChange(e.target.value, i) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            multiline
            rows="4"
            style={{ width: '100%' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl style={{ marginRight: 5, width: '99%' }} variant="outlined" margin="normal">
              <InputLabel>STEP PARAGRAPH</InputLabel>
              <Select
                label={'STEP PARAGRAPH'}
                value={s.isStep || 0}
                defaultValue={0}
                onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'isStep') }}
                className="day-text-field"
              >
                <MenuItem value={1}>True</MenuItem>
                <MenuItem value={0}>False</MenuItem>
              </Select>
            </FormControl>
            {s.isStep ?
              <TextField
                label={'STEP NUMBER'}
                value={s.stepNumber || ''}
                onChange={(e) => { props.handleSectionImageStyleChange(e.target.value, i, 'stepNumber') }}
                margin="normal"
                variant="outlined"
                className="day-text-field"
                type="number"
              /> : null
            }
          </div>
        </div>
      );
    }
  };

  const renderUpArrow = (i) => {
    if (i !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { props.moveItemUp(i) }} aria-label="Delete">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{ width: '53px' }}></div>
    }
  };

  const renderDownArrow = (i, last) => {
    if (i !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { props.moveItemDown(i) }} aria-label="Delete">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{ width: '53px' }}></div>
    }
  };

  return (
    <div className="Section">
      <TextField
        style={{ width: '100%' }}
        label="Section Title"
        value={props.section.sectionTitle}
        onChange={(e) => { props.handleTitleChange(e.target.value) }}
        margin="normal"
        variant="outlined"
        className="day-text-field"
      />

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('title') }}
      >
        Add Title
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('subtitle') }}
      >
        Add Subtitle
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('paragraph') }}
      >
        Add Paragraph
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('image') }}
      >
        Add Image
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('video') }}
      >
        Add Video
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('loopedVideo') }}
      >
        Add Looped Video
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('pdf') }}
      >
        Add PDF
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('quiz') }}
      >
        Add Quiz
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('products') }}
      >
        Add Related Products
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: '10px', marginBottom: '5px' }}
        onClick={() => { props.addDetail('youtube') }}
      >
        Add YouTube Video
      </Button>

      <List>
        {props.section.entries.map((item, i) => {
          return (
            <ListItem key={`section-field-${i}`}>
              {renderSectionInput(item, i)}

              {renderUpArrow(i)}

              {renderDownArrow(i, props.section.entries.length - 1)}

              <IconButton edge="start" color="inherit" onClick={() => { props.removeSectionItem(i) }} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default Section;
