import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Button,
  Card,
  CardContent,
  Toolbar,
  Typography,
  Divider,
  TextField,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import './courseModule.scss';
import VideoModule from './videoModule';
import QuizModule from './quizModule';
import { toolbarOptions } from './toolbarOptions';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CourseModule(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [course, setCourse] = useState({
    id: '',
    name: '',
    webPath: '',
    backgroundImage: '',
    description: '',
    sections: [],
    enabled: false,
    position: 0,
  });
  const [section, setSection] = useState({
    title: '',
    imageUrl: '',
    backgroundImage: '',
    description: '',
    modules: [],
    categories: [''],
    id: '',
  });
  const [module, setModule] = useState({
    title: '',
    type: '',
    description: '',
    notes: '',
    links: [],
  });
  const [linkDeleteIndex, setLinkDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    const db = firebase.firestore();

    try {
      const courseId = props.match.params.id;
      const doc = await db.collection('associate-training-courses').doc(courseId).get();
      const course = doc.data();

      if (!course) {
        props.history.push(`/partners/courses/${props.match.params.id}`);
        return;
      }

      const currentSection = course.sections[props.match.params.sectionIndex];

      if (!currentSection) {
        props.history.push(`/partners/courses/${props.match.params.id}`);
        return;
      }

      const currentModule = currentSection.modules[props.match.params.moduleIndex];

      if (!currentModule) {
        props.history.push(`/partners/courses/${props.match.params.id}`);
        return;
      }

      const clonedModule = cloneDeep(currentModule);

      if (clonedModule.hasOwnProperty('editorData') && clonedModule.editorData !== '') {
        const contentState = convertFromRaw(JSON.parse(clonedModule.editorData));
        clonedModule.editorData = EditorState.createWithContent(contentState);
      }

      setModule(clonedModule);
      setSection(currentSection);
      setCourse(course);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this module, please try again.');
    }
  };

  const saveCourse = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      const courseCopy = cloneDeep(course);
      const sectionCopy = cloneDeep(section);
      const moduleCopy = { ...module };

      if (moduleCopy.hasOwnProperty('editorData')) {
        moduleCopy.editorData = JSON.stringify(convertToRaw(moduleCopy.editorData.getCurrentContent()));
      }

      sectionCopy.modules[props.match.params.moduleIndex] = moduleCopy;
      courseCopy.sections[props.match.params.sectionIndex] = sectionCopy;
      await db.collection('associate-training-courses').doc(props.match.params.id).update(courseCopy);

      setCourse(courseCopy);
      setSection(sectionCopy);
      setLoading(false);
      setModalTitle('Success!');
      setModalText('This module has been saved!');
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this module, please try again.');
    }
  };

  const confirmDeleteLink = () => {
    const links = [ ...module.links ];
    
    links.splice(linkDeleteIndex, 1);

    setModule({
      ...module,
      links,
    });
    setLinkDeleteIndex(-1);
  };

  const handleTextChange = (name) => event => {
    setModule({
      ...module,
      [name]: event.target.value,
    });
  };

  const renderModuleSpecificFields = () => {
    if (module.type === 'video') {
      return (
        <VideoModule
          videoUrl={module.videoUrl}
          videoThumbnail={module.videoThumbnail}
          onChange={(type, value) => {
            setModule({
              ...module,
              [type]: value,
            });
          }}
        />
      );
    }

    if (module.type === 'quiz') {
      return (
        <QuizModule
          questions={module.questions}
          passingScore={module.passingScore}
          onChange={(type, data) => {
            setModule({
              ...module,
              [type]: data,
            });
          }}
        />
      );
    }

    if (module.type === 'article') {
      return (
        <Editor
          toolbar={toolbarOptions}
          editorState={module.editorData}
          toolbarClassName="toolbar-container"
          wrapperClassName="editor-wrapper"
          editorClassName="editor-container"
          onEditorStateChange={(data) => {
            setModule({
              ...module,
              editorData: data,
            });
          }}
        />
      );
    }
  };

  const renderBreadcrumbs = () => {
    if (!course.name) {
      return null;
    }

    return (
      <p>
        <Link to={`/partners/courses`} style={{color: 'blue', textDecoration: 'none'}}>All Courses</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        <Link to={`/partners/courses/${course.id}`} style={{color: 'blue', textDecoration: 'none'}}>{course.name}</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        <Link to={`/partners/courses/${course.id}/section/${props.match.params.sectionIndex}`} style={{color: 'blue', textDecoration: 'none'}}>{section.title}</Link>
        <span style={{marginLeft: '5px', marginRight: '5px'}}>/</span>
        {module.title}
      </p>
    );
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="CourseModule">
      {renderLoading()}
      {renderBreadcrumbs()}

      <Card>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {module.title ? `Module: ${module.title}` : 'Loading Module...'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => { saveCourse() }}
          >
            Save
          </Button>
        </Toolbar>

        <Divider/>

        <CardContent>
          <TextField
            label="Section Title"
            value={module.title}
            onChange={handleTextChange('title')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Description"
            value={module.description}
            onChange={handleTextChange('description')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <TextField
            label="Notes"
            multiline
            rows="6"
            value={module.notes}
            onChange={handleTextChange('notes')}
            margin="dense"
            variant="outlined"
            className="day-text-field condensed-text-field"
          />

          <div className="section-container">
            <div className="section-label">Links</div>
            <div className="right-container">
              <Button variant="contained" onClick={() => {
                const links = [ ...module.links ];

                links.push({
                  title: '',
                  value: '',
                });

                setModule({
                  ...module,
                  links,
                });
              }} color="primary">
                Add Link
              </Button>
            </div>

            <div>
              {module.links.map((link, i) => {
                return (
                  <div key={`link-${i}`}>
                    <Divider/>

                    <div className="link-row">
                      <TextField
                        label="Link Title"
                        value={link.title}
                        onChange={(e) => {
                          const links = [ ...module.links ];
                          const updatedLink = {
                            ...link,
                            title: e.target.value,
                          };

                          links[i] = updatedLink;

                          setModule({
                            ...module,
                            links,
                          });
                        }}
                        margin="dense"
                        variant="outlined"
                        className="day-text-field link-item"
                      />

                      <TextField
                        label="Link URL"
                        value={link.value}
                        onChange={(e) => {
                          const links = [ ...module.links ];
                          const updatedLink = {
                            ...link,
                            value: e.target.value,
                          };

                          links[i] = updatedLink;

                          setModule({
                            ...module,
                            links,
                          });
                        }}
                        margin="dense"
                        variant="outlined"
                        className="day-text-field link-item"
                      />

                      <IconButton color="inherit" onClick={() => { setLinkDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {renderModuleSpecificFields()}
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={linkDeleteIndex !== -1} onClose={() => { setLinkDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Link?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this link?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDeleteLink} color="primary">
            Confirm
          </Button>

          <Button variant="contained" onClick={() => { setLinkDeleteIndex(-1) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseModule;
