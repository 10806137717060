import React from 'react';

import './retailers.scss';

function Retailers({}) {
  return (
    <div className="Retailers">
      Retailers
    </div>
  );
}

export default Retailers;
