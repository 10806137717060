import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './ordersToday.scss';
import { config } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OrdersToday({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    fetchOrdersForToday();
  }, []);

  const fetchOrdersForToday = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/orders/today`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setTotalOrders(response.data.count);
      setRevenue(response.data.totalRevenue);
      setOrders(response.data.sumArr.map(arr => {
        arr[0] = new Date(arr[0]);
        return arr;
      }));
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred retrieving orders. Please try again.');
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="OrdersToday">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            Orders Today
          </Typography>
        </Toolbar>

        <Divider/>
        
        <CardContent>
          <div className="card-row">
            <div className="card-container">
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">
                    Total Revenue:
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="h6">
                    {formatter.format(revenue)}
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <div className="card-container">
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">
                    Order Count
                  </Typography>
                  <div className="card-spacing"></div>
                  <Typography variant="h6">
                    {totalOrders}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>

          <div style={{margin: '20px 0 40px'}}>
            <Divider/>
          </div>

          <div>
            <Chart
              width={'100%'}
              height={'500'}
              chartType="Line"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { type: 'date', label: 'Time' },
                  'Revenue',
                ],
                ...orders.map(d => [d[0], d[2]]),
              ]}
              options={{
                legend: {
                  position: 'none',
                },
                chart: {
                  title:
                    'Revenue',
                },
                width: '100%',
                height: 500,
                series: {
                  0: { axis: 'Revenue' },
                },
                axes: {
                  y: {
                    Revenue: { label: 'Revenue ($)' },
                  },
                },
              }}
              rootProps={{ 'data-testid': '4' }}
            />

            <div style={{margin: '40px 0'}}>
              <Divider/>
            </div>

            <Chart
              width={'100%'}
              height={'500'}
              chartType="Line"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { type: 'date', label: 'Time' },
                  'Orders',
                ],
                ...orders.map(d => [d[0], d[1]]),
              ]}
              options={{
                legend: {
                  position: 'none',
                },
                chart: {
                  title:
                    'Number of Orders',
                },
                width: '100%',
                height: 500,
                series: {
                  0: { axis: 'Orders' },
                },
                axes: {
                  y: {
                    Orders: { label: 'No. Orders' },
                  },
                },
              }}
              rootProps={{ 'data-testid': '4' }}
            />
          </div>
        </CardContent>
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OrdersToday;
