import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const pageDetailsSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
});

const heroSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  titleColor: z.string().min(1, 'Title color is required').default('#ffffff'),
  content: z.string().min(1, 'Content is required'),
  contentColor: z
    .string()
    .min(1, 'Content color is required')
    .default('#ffffff'),
  imageUrl: z.string().url('Image URL is invalid'),
  buttonText: z.string().min(1, 'Button text is required'),
  buttonUrl: z.string().min(1, 'Button Url is required'),
  buttonColor: z.string().min(1, 'Button color is required').default('#ffffff'),
  buttonTextColor: z
    .string()
    .min(1, 'Button text scolor is required')
    .default('#ffffff'),
  linkText: z.string().min(1, 'Link text is required'),
  linkTextColor: z
    .string()
    .min(1, 'Link text color is required')
    .default('#ffffff'),
  linkUrl: z.string().min(1, 'Link Url is required'),
  linkColor: z.string().min(1, 'Link color is required').default('#ffffff'),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
});

const productsSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  content: z.string().min(1, 'Content is required'),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
  items: z
    .array(
      z.object({
        title: z.string().min(1, 'Title is required'),
        imageUrl: z.string().url('Image URL is invalid'),
        linkText: z.string().min(1, 'Link text is required'),
        linkUrl: z.string().min(1, 'Link Url is required'),
        linkColor: z
          .string()
          .min(1, 'Link Color is required')
          .default('#ffffff'),
      }),
    )
    .min(1)
    .max(3),
});

const getStartedSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  words: z.array(
    z.object({
      text: z.string().min(1, 'Word is required'),
    }),
  ),
  imageUrl: z.string().url('Image URL is invalid'),
  buttonText: z.string().min(1, 'Button text is required'),
  buttonUrl: z.string().min(1, 'Button Url is required'),
  buttonColor: z.string().min(1, 'Button color is required').default('#ffffff'),
});

const statisticsSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  content: z.string().min(1, 'Content is required'),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
  items: z.array(
    z.object({
      quantity: z.string().min(1, 'Quantity is required'),
      title: z.string().optional(),
      isRating: z.boolean().default(false),
      ratingValue: z.string().default(0),
      imageUrl: z.string().url('Image URL is invalid'),
    }),
  ),
});

const guaranteeSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  imageUrl: z.string().url('Image URL is invalid'),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
  content: z.string().min(1, 'Content is required'),
  buttonText: z.string().min(1, 'Button text is required'),
  buttonUrl: z.string().min(1, 'Button Url is required'),
});

const productCategoriesSectionSchema = z.object({
  title: z.string().optional(),
  subtitle: z.string().optional(),
  content: z.string().optional(),
  imagePosition: z.enum(['left', 'right']).default('right'),
  imageUrl: z.string().url('Image URL is invalid').optional(),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
  categories: z.object({
    header: z.string().min(1, 'Header is required'),
    items: z.array(
      z.object({
        name: z.string().min(1, 'Name is required'),
        description: z.string().min(1, 'Description is required'),
        color: z.string().min(1, 'Color is required').default('#ffffff'),
      }),
    ),
  }),
  buttonText: z.string().min(1, 'Button text is required'),
  buttonUrl: z.string().min(1, 'Button Url is required'),
});

const appsUrlsSectionSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  imageUrl: z.string().url('Image URL is invalid'),
  content: z.string().min(1, 'Content is required'),
  playStoreUrl: z.string().url('Play Store URL is invalid'),
  appStoreUrl: z.string().url('App Store URL is invalid'),
});

const academySectionSchema = z.object({
  title: z.string().optional(),
  subtitle: z.string().optional(),
  content: z.string().min(1, 'Content is required'),
  imagePosition: z.enum(['left', 'right']).default('left'),
  imageUrl: z.string().url('Image URL is invalid').optional(),
  buttonText: z.string().min(1, 'Button text is required'),
  buttonUrl: z.string().min(1, 'Button Url is required'),
  buttonColor: z.string().min(1, 'Button color is required').default('#ffffff'),
  backgroundColor: z
    .string()
    .min(1, 'Background color is required')
    .default('#ffffff'),
});

const homePageFormSchema = z.object({
  pageDetailsSection: pageDetailsSectionSchema,
  heroSection: heroSectionSchema,
  productsSection: productsSectionSchema,
  getStartedSection: getStartedSectionSchema,
  statisticsSection: statisticsSectionSchema,
  guaranteeSection: guaranteeSectionSchema,
  productCategoriesSection: productCategoriesSectionSchema,
  appsUrlsSection: appsUrlsSectionSchema,
  academySection: academySectionSchema,
});

const homePageFormResolver = zodResolver(homePageFormSchema, undefined, {
  raw: true,
});

export const useHomePageForm = (defaultValues) => {
  const form = useForm({
    resolver: homePageFormResolver,
    defaultValues: defaultValues,
  });

  return form;
};
