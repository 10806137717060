import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import {
  Divider,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function SubscriptionRevenueCountChart({ subscriptions }) {
  const [data, setData] = useState([]);
  const [dateInterval, setDateInterval] = useState('day');
  const [yrr, setYrr] = useState(0);

  useEffect(() => {
    const subDateMap = {};
    let min = Infinity;
    let max = 0;

    let yearlyRecurringRevenue = 0;

    const oneYearFromNow = moment().add(1, 'year').valueOf();
    const updatedSubscriptions = [];

    subscriptions.forEach((sub, i) => {
      updatedSubscriptions.push({
        nextPaymentDate: sub.nextPaymentDate,
        total: sub.total,
      });

      if (isNaN(sub.total)) {
        console.log(sub.id);
      }

      let nextPaymentDate = sub.nextPaymentDate;

      while (nextPaymentDate <= oneYearFromNow) {
        nextPaymentDate = moment(new Date(nextPaymentDate)).add(sub.interval, sub.period).valueOf();

        if (nextPaymentDate <= oneYearFromNow) {
          updatedSubscriptions.push({
            nextPaymentDate,
            total: sub.total,
          });
        }
      }
    });

    updatedSubscriptions.forEach(sub => {
      const millis = moment(new Date(sub.nextPaymentDate)).startOf(dateInterval).valueOf();
      const dateStr = `${millis}`;

      if (millis <= oneYearFromNow) {
        yearlyRecurringRevenue += sub.total;
      }

      if (millis > max) {
        max = millis;
      }

      if (millis < min) {
        min = millis;
      }
      
      if (!subDateMap[dateStr]) {
        subDateMap[dateStr] = {
          count: 1,
          revenue: +sub.total,
        };
      } else {
        subDateMap[dateStr].count++;
        subDateMap[dateStr].revenue += +sub.total;
      }
    });

    let current = min;

    while (current <= max) {
      if (!subDateMap[`${current}`]) {
        subDateMap[`${current}`] = {
          count: 0,
          revenue: 0,
        };
      }

      const millis = moment(new Date(+current)).add(1, dateInterval).startOf(dateInterval).valueOf();
      current = millis;
    }

    const dataArr = [];

    for (let key in subDateMap) {
      dataArr.push([new Date(+key), subDateMap[key].count, subDateMap[key].revenue]);
    }

    dataArr.sort((a, b) => {
      return a[0] - b[0];
    });

    setData(dataArr);
    setYrr(yearlyRecurringRevenue);
  }, [subscriptions, dateInterval]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Annual Recurring Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(yrr)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Monthly Recurring Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(yrr / 12)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Active Subscriptions
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {subscriptions.length}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{margin: '20px 0 40px'}}>
        <Divider/>
      </div>

      <ToggleButtonGroup
        value={dateInterval}
        exclusive
        onChange={(e, newValue) => { setDateInterval(newValue) }}
        aria-label="text alignment"
        size="small"
      >
        <ToggleButton value="day">
          Day
        </ToggleButton>
        <ToggleButton value="week">
          Week
        </ToggleButton>
        <ToggleButton value="month">
          Month
        </ToggleButton>
      </ToggleButtonGroup>

      <div style={{margin: '20px 0 40px'}}>
        <Divider/>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="Line"
        numberFormat={{ options: { prefix: '$' } }}
        loader={<div>Loading Chart</div>}
        data={[
          [
            { type: 'date', label: dateInterval.toUpperCase() },
            'Revenue',
          ],
          ...data.map(d => [d[0], d[2]]),
        ]}
        options={{
          legend: {
            position: 'none',
          },
          chart: {
            title:
              'Revenue',
          },
          width: '100%',
          height: 500,
          series: {
            0: { axis: 'Revenue' },
          },
          axes: {
            y: {
              Revenue: { label: 'Revenue ($)' },
            },
          },
        }}
        rootProps={{ 'data-testid': '4' }}
      />

      <div style={{margin: '40px 0'}}>
        <Divider/>
      </div>

      <Chart
        width={'100%'}
        height={'500'}
        chartType="Line"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { type: 'date', label: dateInterval.toUpperCase() },
            'Number of Renewals',
          ],
          ...data.map(d => [d[0], d[1]]),
        ]}
        options={{
          legend: {
            position: 'none',
          },
          chart: {
            title:
              'Number of Renewals',
          },
          width: '100%',
          height: 500,
          series: {
            0: { axis: 'Renewals' },
          },
          axes: {
            y: {
              Renewals: { label: 'No. Renewals' },
            },
          },
        }}
        rootProps={{ 'data-testid': '4' }}
      />
    </div>
  );
}

export default SubscriptionRevenueCountChart;
