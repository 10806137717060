import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/dashboard';
import ManagePartners from './managePartners/managePartners';
import ManagePartner from './managePartners/managePartner/managePartner';
import Courses from './courses/courses';
import Course from './courses/course';
import CourseSection from './courses/course/courseSection';
import CourseModule from './courses/course/courseSection/courseModule';

function Partners(props) {
  return (
    <div style={{marginBottom: 50, marginTop: '90px', paddingLeft: 20, paddingRight: 20}}>
      <Switch>
        <Route path="/partners/dashboard" component={Dashboard} />
        <Route path="/partners/manage-partners/:id" component={ManagePartner} />
        <Route path="/partners/manage-partners" component={ManagePartners} />
        <Route path="/partners/courses/:id/section/:sectionIndex/module/:moduleIndex" component={CourseModule} />
        <Route path="/partners/courses/:id/section/:sectionIndex" component={CourseSection} />
        <Route path="/partners/courses/:id" component={Course} />
        <Route path="/partners/courses" component={Courses} />
        <Route component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default Partners;
