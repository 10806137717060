import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';

import './formMultiSelect.scss';

function FormMultiSelect({ fields, onChange }) {
  return (
    <div className="FormMultiSelect">
      <div className="form-input-row">
        <TextField
          label="Label"
          value={fields.label}
          onChange={(e) => {
            onChange({
              ...fields,
              label: e.target.value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <FormControl
          variant="outlined"
          margin="dense"
          className="day-text-field"
        >
          <InputLabel>
            Required
          </InputLabel>
          <Select
            value={fields.required}
            onChange={(e) => {
              onChange({
                ...fields,
                required: e.target.value,
              });
            }}
            label="Required"
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>

      <TextField
        label="Helper Text"
        value={fields.helperText}
        onChange={(e) => {
          onChange({
            ...fields,
            helperText: e.target.value,
          });
        }}
        variant="outlined"
        margin="dense"
        type="text"
        className="day-text-field"
      />
      
      <div className="options-container">
        <div className="option-row">
          <p>Options</p>

          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                onChange({
                  ...fields,
                  options: [ ...fields.options, '' ],
                });
              }}
            >
              Add Option
            </Button>
          </div>
        </div>

        {fields.options.map((option, i) => {
          return (
            <div key={`option-${i}`} className="option-row">
              <TextField
                label={`Option ${i + 1}`}
                value={option}
                onChange={(e) => {
                  const options = [ ...fields.options ];

                  options[i] = e.target.value;

                  onChange({
                    ...fields,
                    options,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <div className="option-delete-button">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    const options = [ ...fields.options ];

                    options.splice(i, 1);

                    onChange({
                      ...fields,
                      options,
                    });
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FormMultiSelect;
