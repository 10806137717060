import React, { useState } from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';

function AddVideoFromUrlModal(props) {
  const [title, setTitle] = useState('');
  const [url, setURL] = useState('');
  const [placeholder, setPlaceholder] = useState('');

  return (
    <div style={{padding: '20px'}}>
      <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Add Video from URL</p>
      <TextField
        style={{minWidth: '225px'}}
        label="Title"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        margin="normal"
        variant="outlined"
        className="day-text-field"
      />
      <TextField
        style={{minWidth: '225px'}}
        label="Video URL"
        value={url}
        onChange={(e) => {
          setURL(e.target.value);
        }}
        margin="normal"
        variant="outlined"
        className="day-text-field"
      />
      <TextField
        style={{minWidth: '225px'}}
        label="Placeholder Image URL"
        value={placeholder}
        onChange={(e) => {
          setPlaceholder(e.target.value);
        }}
        margin="normal"
        variant="outlined"
        className="day-text-field"
      />
      <hr/>
      <Button
        style={{marginRight: '10px'}}
        variant="contained"
        color="primary"
        disabled={!url || !placeholder}
        onClick={() => {
          props.submit({
            title,
            url,
            placeholder,
          });
        }}
      >Submit</Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => { props.cancel() }}
      >cancel</Button>
    </div>
  );
}

export default AddVideoFromUrlModal;
