import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

function FormFileUpload({ fields, onChange }) {
  return (
    <div className="FormFileUpload">
      <div className="form-input-row">
        <TextField
          label="Label"
          value={fields.label}
          onChange={(e) => {
            onChange({
              ...fields,
              label: e.target.value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <FormControl
          variant="outlined"
          margin="dense"
          className="day-text-field"
        >
          <InputLabel>
            Required
          </InputLabel>
          <Select
            value={fields.required}
            onChange={(e) => {
              onChange({
                ...fields,
                required: e.target.value,
              });
            }}
            label="Required"
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="form-input-row">
        <TextField
          label="Helper Text"
          value={fields.helperText}
          onChange={(e) => {
            onChange({
              ...fields,
              helperText: e.target.value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="text"
          className="day-text-field"
        />

        <TextField
          label="Max Files"
          value={fields.maxFiles}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9.]/g, '');

            onChange({
              ...fields,
              maxFiles: value,
            });
          }}
          variant="outlined"
          margin="dense"
          type="number"
          className="day-text-field"
        />
      </div>
    </div>
  );
}

export default FormFileUpload;
