import React, { useEffect, useState } from 'react';
import HeroSection from './hero-section';
import FeaturedOnSection from './featured-on-section';
import ProductsSection from './products-section';
import GetStartedSection from './get-started-section';
import StatisticsSection from './statistics-section';
import GuaranteeSection from './guarantee-section';
import ShopsSection from './shops-section';
import ProductsCategoriesSection from './products-categories-section';
import AppsUrlsSection from './apps-urls-section';
import AcademySection from './academy-section';
import firebase from 'firebase/app';
import { AppBar, Button, CircularProgress, Toolbar } from '@material-ui/core';

export const HomePagePreview = ({ onClose }) => {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);

  const getPageData = async () => {
    setLoading(true);
    try {
      const db = firebase.firestore();
      const doc = await db.collection('config').doc('home-page-v2').get();
      const page = doc.data();
      setPage(page);
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!page) {
      getPageData();
    }
  }, [page]);

  let content = <div />;

  if (loading) {
    content = (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, .5)',
          textAlign: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#fff', top: '50%', position: 'absolute' }}
        />
      </div>
    );
  }

  if (!page) {
    content = <div>Not found</div>;
  }

  if (page) {
    content = (
      <div>
        <HeroSection data={page?.heroSection} />
        <FeaturedOnSection />
        <ProductsSection data={page?.productsSection} />
        <GetStartedSection data={page?.getStartedSection} />
        <StatisticsSection data={page?.statisticsSection} />
        <GuaranteeSection data={page?.guaranteeSection} />
        <ShopsSection />
        <ProductsCategoriesSection data={page?.productCategoriesSection} />
        <AppsUrlsSection data={page?.appsUrlsSection} />
        <AcademySection data={page?.academySection} />
      </div>
    );
  }

  return (
    <div>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Button color="inherit" onClick={() => onClose()}>
            close
          </Button>
        </Toolbar>
      </AppBar>
      {content}
    </div>
  );
};
