import {
  SET_ORDERS_COUNT,
  SET_USERS_COUNT,
  SET_SUBSCRIPTIONS_COUNT,
} from '../actions/types';

const defaultState = {
  ordersCount: 0,
  subscriptionsCount: 0,
  usersCount: 0,
  membershipsCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ORDERS_COUNT:
      return {
        ...state,
        ordersCount: action.payload,
      };
    case SET_USERS_COUNT:
      return {
        ...state,
        usersCount: action.payload,
      };
    case SET_SUBSCRIPTIONS_COUNT:
      return {
        ...state,
        subscriptionsCount: action.payload,
      };
    default:
      return state;
  }
};
