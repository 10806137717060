import React from 'react';
import {
  TextField,
  Button,
} from '@material-ui/core';

import StyledDropzone from '../styledDropzone';

function AddFilesModal(props) {
  return (
    <div style={{padding: '20px'}}>
      <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Upload Files</p>
      {!props.filesToAdd.length ? <StyledDropzone onDrop={props.addFiles} /> :
        props.filesToAdd.map((file, i) => {
          return (
            <div key={`file-to-upload-${i}`}>
              <hr/>
              <small>{file.fileName}</small>
              <TextField
                style={{minWidth: '225px'}}
                label="Title"
                value={file.name}
                onChange={(e) => {
                  const filesToAddCopy = [ ...props.filesToAdd ];
                  const updatedFile = {
                    ...file,
                  };

                  updatedFile.name = e.target.value;
                  filesToAddCopy[i] = updatedFile

                  props.setFilesToAdd(filesToAddCopy);
                }}
                margin="normal"
                variant="outlined"
                className="day-text-field"
              />
              {!file.file.type.includes('image') ? null : <TextField
                style={{minWidth: '225px'}}
                label="Alt Text"
                value={file.alt}
                onChange={(e) => {
                  const filesToAddCopy = [ ...props.filesToAdd ];
                  const updatedFile = {
                    ...file,
                  };

                  updatedFile.alt = e.target.value;
                  filesToAddCopy[i] = updatedFile

                  props.setFilesToAdd(filesToAddCopy);
                }}
                margin="normal"
                variant="outlined"
                className="day-text-field"
              />}
              {!file.file.type.includes('video') ? null :
                <TextField
                  style={{minWidth: '225px'}}
                  label="Placeholder Image"
                  value={file.placeholder}
                  onChange={(e) => {
                    const filesToAddCopy = [ ...props.filesToAdd ];
                    const updatedFile = {
                      ...file,
                    };

                    updatedFile.placeholder = e.target.value;
                    filesToAddCopy[i] = updatedFile

                    props.setFilesToAdd(filesToAddCopy);
                  }}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                />
              }
            </div>
          );
        })
      }
      <hr/>
      <Button
        style={{marginRight: '10px'}}
        variant="contained"
        color="primary"
        disabled={!props.filesToAdd.length}
        onClick={props.uploadFiles}
      >Upload</Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => { props.cancelAddFiles() }}
      >cancel</Button>
    </div>
  );
}

export default AddFilesModal;
