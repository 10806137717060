import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import SwipeableViews from 'react-swipeable-views';
import {
  CircularProgress,
  Card,
  Tabs,
  Tab,
  Slide,
  Divider,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './subscriptionDownsells.scss';
import EditSubscriptionDownsell from './editSubscriptionDownsell/editSubscriptionDownsell';
import SubscriptionDownsellPerformance from './subscriptionDownsellPerformance/subscriptionDownsellPerformance';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function SubscriptionDownsells({}) {
  const swipeableViewsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptionDownsells, setSubscriptionDownsells] = useState([]);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [editDownsell, setEditDownsell] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [hasShownPerformance, setHasShownPerformance] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [analyticsLength, setAnalyticsLength] = useState(0);

  useEffect(() => {
    getSubscriptionDownsells();
  }, []);

  useEffect(() => {
    if (swipeableViewsRef.current) {
      swipeableViewsRef.current
        .getChildContext()
        .swipeableViews.slideUpdateHeight();
    }
  }, [tabValue, subscriptionDownsells, analyticsLength]);

  const getSubscriptionDownsells = async () => {
    try {
      const subsDownsellsSnapshot = await firebase.firestore().collection('subscription-downsells').doc('content').get();
      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();

      const products = querySnapshot.data().simplifiedProducts.filter(p => {
        return !p.isDigital && p.addToSubscriptionPrice;
      }).map(p => {
        return {
          displayName: p.name || '',
          name: p.name,
          url: p.path,
          value: p.price || '',
          image: p.image ? p.image.url : '',
          id: p.id,
        };
      }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setSubscriptionDownsells(subsDownsellsSnapshot.data().downsells);
      setSimplifiedProducts(products);
      setProductCategories(querySnapshot.data().productCategories);
      setProductTags(contentQuerySnapshot.data().tags);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving subscription downsells. Please try again.');
    }
  };

  const saveDownsell = async (downsellData) => {
    const foundProduct = simplifiedProducts.find(p => p.id === downsellData.selectedProduct);

    if (!foundProduct) {
      setModalTitle('Error:');
      setModalText('There was an error finding the selected product. Please update the product selection and try again.');
      return;
    }

    setEditing(false);
    setLoading(true);

    const downsell = {
      ...downsellData,
      selectedProduct: foundProduct,
      price: parseFloat(downsellData.price),
      priority: parseInt(downsellData.priority),
    };

    const downsellsCopy = [ ...subscriptionDownsells ];

    if (editIndex !== -1) {
      downsellsCopy[editIndex] = cloneDeep(downsell);
    } else {
      downsellsCopy.unshift({
        ...downsell,
        id: uuidv4(),
      });
    }

    try {
      await firebase.firestore().collection('subscription-downsells').doc('content').update({
        downsells: downsellsCopy,
      });

      setSubscriptionDownsells(downsellsCopy);
      setLoading(false);
      setEditIndex(-1);
    } catch (e) {
      setEditIndex(-1);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the downsell. Please try again.');
    }
  };

  const deleteDownsell = async () => {
    setLoading(true);

    const downsellsCopy = [ ...subscriptionDownsells ];

    downsellsCopy.splice(deleteIndex, 1);

    try {
      await firebase.firestore().collection('subscription-downsells').doc('content').update({
        downsells: downsellsCopy,
      });

      setSubscriptionDownsells(downsellsCopy);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      setLoading(false);
      setDeleteIndex(-1);
      setModalTitle('Error:');
      setModalText('There was an error deleting the downsell. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SubscriptionDownsells">
      {renderLoading()}
      <Card>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
            if (newValue === 1 && !hasShownPerformance) {
              setHasShownPerformance(true);
            }
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="order bump tabs"
        >
          <Tab label="All Downsells" {...a11yProps(0)} />
          <Tab label="Performance" {...a11yProps(1)} />
        </Tabs>
        <Divider />
        <SwipeableViews
          index={tabValue}
          onChangeIndex={(index) => { setTabValue(index) }}
          ref={swipeableViewsRef}
          animateHeight={true}
        >
          <div value={tabValue} index={0}>
            <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h6">
                Downsells
              </Typography>
              <IconButton edge="start" color="inherit" onClick={() => {
                if (!simplifiedProducts.length) {
                  return;
                }

                setEditDownsell({
                  active: false,
                  updateShipping: false,
                  selectedProduct: simplifiedProducts[0].id,
                  rules: [],
                  title: '',
                  description: '',
                  price: 0,
                  priority: 1,
                  lastModified: Date.now(),
                  lastModifiedBy: firebase.auth().currentUser.email,
                });
                setEditIndex(-1);
                setEditing(true);
              }} aria-label="Add">
                <AddIcon />
              </IconButton>
            </Toolbar>

            <List>
              {subscriptionDownsells.map((d, i) => {
                return (
                  <div key={`downsell-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={d.title}
                        secondary={
                          <span>
                            <span style={{marginTop: 5, marginBottom: 5, display: 'block', marginRight: 10}}>
                              <span><span><span style={{fontWeight: 'bold'}}>Status:</span> <span style={{color: d.active ? 'rgb(126, 210, 66)' : 'red', marginRight: 10}}>{d.active ? 'Active' : 'Disabled'}</span></span> <span style={{fontWeight: 'bold'}}>Priority:</span> <span style={{marginRight: 10}}>{d.priority}</span> <span style={{fontWeight: 'bold'}}>Last Modified:</span> <span style={{marginRight: 10}}>{moment(d.lastModified).calendar()}</span> <span style={{fontWeight: 'bold'}}>Last Modified By:</span> {d.lastModifiedBy}</span>
                            </span>

                            <span style={{marginRight: 10}}>
                              {d.description}
                            </span>

                            <span style={{marginRight: 10}}>
                              ID: {d.id}
                            </span>
                          </span>
                        }
                      />
                      <IconButton
                        edge="start"
                        style={{marginRight: 15, color: '#000'}}
                        aria-label="Edit"
                        onClick={() => {
                          if (!simplifiedProducts.length) {
                            return;
                          }

                          setEditDownsell({
                            active: d.active,
                            updateShipping: d.updateShipping || false,
                            selectedProduct: d.selectedProduct.id,
                            rules: cloneDeep(d.rules),
                            title: d.title,
                            description: d.description,
                            price: d.price,
                            priority: d.priority,
                            lastModified: Date.now(),
                            lastModifiedBy: firebase.auth().currentUser.email,
                            id: d.id,
                          });
                          setEditIndex(i);
                          setEditing(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="start" color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </div>
          <div value={tabValue} index={1}>
            {!hasShownPerformance ? null :
              <div>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6">
                    Downsell Performance
                  </Typography>
                </Toolbar>
                <Divider/>
                <SubscriptionDownsellPerformance
                  setSubscriptionsLength={(num) => setAnalyticsLength(num)}
                />
              </div>
            }
          </div>
        </SwipeableViews>
      </Card>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Downsell?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Downsell?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteDownsell} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={editing} onClose={() => {
        setEditing(false);
        setEditIndex(-1);
      }} TransitionComponent={Transition}>
        <EditSubscriptionDownsell
          save={saveDownsell}
          close={() => {
            setEditing(false);
            setEditIndex(-1);
          }}
          editDownsell={editDownsell}
          simplifiedProducts={simplifiedProducts}
          productCategories={productCategories}
          productTags={productTags}
        />
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionDownsells;
