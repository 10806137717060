import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Chart } from 'react-google-charts';

import './renewedSubscriptionHistory.scss';

function RenewedSubscriptionHistory({ reports }) {
  const [total, setTotal] = useState(0);
  const [renewedSubRevenue, setTotalRenewedSubRevenue] = useState(0);
  const [totalRenewedSubCostOfGoods, setTotalRenewedSubCostOfGoods] = useState(0);
  const [totalRenewedSubShipping, setTotalRenewedSubShipping] = useState(0);
  const [totalRenewedSubTax, setTotalRenewedSubTax] = useState(0);
  const [renewedSubRevenueArr, setRenewedSubRevenueArr] = useState([]);
  const [renewedSubOrderCountArr, setRenewedSubOrderCountArr] = useState([]);
  const [renewedSubProductSales, setRenewedSubProductSales] = useState([]);
  const [byRenewedSubProductValue, setByRenewedSubProductValue] = useState('Revenue');
  const [showRenewedSubOrderProductTable, setShowRenewedSubOrderProductTable] = useState(false);
  const [renewedSubCategorySales, setRenewedSubCategorySales] = useState([]);
  const [byRenewedSubCategoryValue, setByRenewedSubCategoryValue] = useState('Revenue');
  const [showRenewedSubOrderCategoryTable, setShowRenewedSubOrderCategoryTable] = useState(false);

  useEffect(() => {
    let total = 0;
    let renewedSubRevenue = 0;
    let renewedSubCostOfGoods = 0;
    let renewedSubShipping = 0;
    let renewedSubTax = 0;

    const renewedSubRevenueTotalArr = [];
    const renewedSubOrdersTotalArr = [];
    const renewedSubProductSalesMap = {};
    let renewedSubProductSalesArr = [];
    const renewedSubCategoriesSalesMap = {};
    let renewedSubCategoriesSalesArr = [];

    reports.forEach(r => {
      const date = new Date(r.created);
      date.setHours(23, 59, 59, 999);

      for (let key in r.renewedSubProductSalesMap) {
        if (!renewedSubProductSalesMap.hasOwnProperty(key)) {
          renewedSubProductSalesMap[key] = r.renewedSubProductSalesMap[key];
        } else {
          renewedSubProductSalesMap[key].amount += r.renewedSubProductSalesMap[key].amount;
          renewedSubProductSalesMap[key].costOfGoods += r.renewedSubProductSalesMap[key].costOfGoods;
          renewedSubProductSalesMap[key].quantity += r.renewedSubProductSalesMap[key].quantity;
        }
      }

      for (let key in r.renewedSubCategoriesSalesMap) {
        if (!renewedSubCategoriesSalesMap.hasOwnProperty(key)) {
          renewedSubCategoriesSalesMap[key] = r.renewedSubCategoriesSalesMap[key];
        } else {
          renewedSubCategoriesSalesMap[key].amount += r.renewedSubCategoriesSalesMap[key].amount;
          renewedSubCategoriesSalesMap[key].costOfGoods += r.renewedSubCategoriesSalesMap[key].costOfGoods;
          renewedSubCategoriesSalesMap[key].quantity += r.renewedSubCategoriesSalesMap[key].quantity;
        }
      }

      renewedSubRevenueTotalArr.push([date, r.renewedSubRevenue]);
      renewedSubOrdersTotalArr.push([date, r.renewalCount]);

      total += +r.renewalCount;
      renewedSubRevenue += +r.renewedSubRevenue;
      renewedSubCostOfGoods += r.renewedSubTotalCostOfGoods;
      renewedSubShipping += r.renewedSubShipping;
      renewedSubTax += r.renewedSubTax;
    });

    for (let key in renewedSubProductSalesMap) {
      const p = renewedSubProductSalesMap[key];
      renewedSubProductSalesArr.push([p.name, p.amount, p.quantity, p.costOfGoods]);
    }

    renewedSubProductSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    for (let key in renewedSubCategoriesSalesMap) {
      const p = renewedSubCategoriesSalesMap[key];
      renewedSubCategoriesSalesArr.push([key, p.amount, p.quantity, p.costOfGoods]);
    }

    renewedSubCategoriesSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    setTotal(total);
    setRenewedSubRevenueArr(renewedSubRevenueTotalArr);
    setRenewedSubOrderCountArr(renewedSubOrdersTotalArr);
    setTotalRenewedSubRevenue(renewedSubRevenue);
    setTotalRenewedSubCostOfGoods(renewedSubCostOfGoods);
    setTotalRenewedSubShipping(renewedSubShipping);
    setTotalRenewedSubTax(renewedSubTax);
    setRenewedSubProductSales(renewedSubProductSalesArr);
    setRenewedSubCategorySales(renewedSubCategoriesSalesArr);
  }, [reports]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="RenewedSubscriptionHistory">
      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Total
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {total}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(renewedSubRevenue)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Shipping
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalRenewedSubShipping)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Tax
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalRenewedSubTax)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Cost Of Goods
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalRenewedSubCostOfGoods)}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{margin: '30px 0 40px'}}>
        <Divider/>
      </div>

      <div>
        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Revenue',
            ],
            ...renewedSubRevenueArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Revenue',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Revenue' },
            },
            axes: {
              y: {
                Revenue: { label: 'Revenue ($)' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div style={{margin: '40px 0'}}>
          <Divider/>
        </div>

        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Renewed Subscriptions',
            ],
            ...renewedSubOrderCountArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Number of Renewed Subscriptions',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Subscriptions' },
            },
            axes: {
              y: {
                Subscriptions: { label: 'No. Subscriptions' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Product Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byRenewedSubProductValue}
            exclusive
            onChange={(e, renewedValue) => {
              const renewedSubProductSalesCopy = [ ...renewedSubProductSales ];
              renewedSubProductSalesCopy.sort((a, b) => {
                let index = 1;

                if (renewedValue === 'Quantity') {
                  index = 2;
                }

                if (renewedValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setRenewedSubProductSales(renewedSubProductSalesCopy);
              setByRenewedSubProductValue(renewedValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Product', byRenewedSubProductValue],
              ...renewedSubProductSales.map(p => {
                let index = 1;

                if (byRenewedSubProductValue === 'Quantity') {
                  index = 2;
                }

                if (byRenewedSubProductValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byRenewedSubProductValue} by Product`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showRenewedSubOrderProductTable}
              onChange={(e) => {
                setShowRenewedSubOrderProductTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showRenewedSubOrderProductTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="products table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renewedSubProductSales.map((p, i) => (
                  <TableRow key={`renewedSubProductSales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Category Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byRenewedSubCategoryValue}
            exclusive
            onChange={(e, renewedValue) => {
              const renewedSubCategorySalesCopy = [ ...renewedSubCategorySales ];
              renewedSubCategorySalesCopy.sort((a, b) => {
                let index = 1;

                if (renewedValue === 'Quantity') {
                  index = 2;
                }

                if (renewedValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setRenewedSubCategorySales(renewedSubCategorySalesCopy);
              setByRenewedSubCategoryValue(renewedValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Category', byRenewedSubCategoryValue],
              ...renewedSubCategorySales.map(p => {
                let index = 1;

                if (byRenewedSubCategoryValue === 'Quantity') {
                  index = 2;
                }

                if (byRenewedSubCategoryValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byRenewedSubCategoryValue} by Category`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showRenewedSubOrderCategoryTable}
              onChange={(e) => {
                setShowRenewedSubOrderCategoryTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showRenewedSubOrderCategoryTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="categories table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renewedSubCategorySales.map((p, i) => (
                  <TableRow key={`renewedSubCategorySales-${i}`}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>
    </div>
  );
}

export default RenewedSubscriptionHistory;
