import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Chart } from 'react-google-charts';

import './amazonOrderHistory.scss';

function AmazonOrderHistory({ reports }) {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [averageOrderValue, setAverageOrderValue] = useState(0);
  const [totalCostOfGoods, setTotalCostOfGoods] = useState(0);
  const [totalShipping, setTotalShipping] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [revenueArr, setRevenueArr] = useState([]);
  const [orderCountArr, setOrderCountArr] = useState([]);
  const [productSales, setProductSales] = useState([]);
  const [byProductValue, setByProductValue] = useState('Revenue');
  const [showOrderProductTable, setShowOrderProductTable] = useState(false);
  const [categorySales, setCategorySales] = useState([]);
  const [byCategoryValue, setByCategoryValue] = useState('Revenue');
  const [showOrderCategoryTable, setShowOrderCategoryTable] = useState(false);

  useEffect(() => {
    let revenue = 0;
    let orderCount = 0;
    let calculatedAverageOrderValue = 0;
    let costOfGoods = 0;
    let shipping = 0;
    let tax = 0;

    const revenueTotalArr = [];
    const ordersTotalArr = [];
    const productSalesMap = {};
    let productSalesArr = [];
    const categoriesSalesMap = {};
    let categoriesSalesArr = [];

    reports.forEach(r => {
      const date = new Date(r.created);
      date.setHours(23, 59, 59, 999);

      for (let key in r.productSalesMap) {
        if (!productSalesMap.hasOwnProperty(key)) {
          productSalesMap[key] = r.productSalesMap[key];
        } else {
          productSalesMap[key].amount += r.productSalesMap[key].amount;
          productSalesMap[key].costOfGoods += r.productSalesMap[key].costOfGoods;
          productSalesMap[key].quantity += r.productSalesMap[key].quantity;
        }
      }

      for (let key in r.categoriesSalesMap) {
        if (!categoriesSalesMap.hasOwnProperty(key)) {
          categoriesSalesMap[key] = r.categoriesSalesMap[key];
        } else {
          categoriesSalesMap[key].amount += r.categoriesSalesMap[key].amount;
          categoriesSalesMap[key].costOfGoods += r.categoriesSalesMap[key].costOfGoods;
          categoriesSalesMap[key].quantity += r.categoriesSalesMap[key].quantity;
        }
      }

      revenueTotalArr.push([date, r.revenue]);
      ordersTotalArr.push([date, r.orderCount]);

      orderCount += r.orderCount
      revenue += +r.revenue;
      calculatedAverageOrderValue += r.averageOrderValue;
      costOfGoods += r.totalCostOfGoods;
      shipping += r.shipping;
      tax += r.tax;
    });

    for (let key in productSalesMap) {
      const p = productSalesMap[key];
      productSalesArr.push([p.name, p.amount, p.quantity, p.costOfGoods]);
    }

    productSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    for (let key in categoriesSalesMap) {
      const p = categoriesSalesMap[key];
      categoriesSalesArr.push([key, p.amount, p.quantity, p.costOfGoods]);
    }

    categoriesSalesArr.sort((a, b) => {
      return b[1] - a[1];
    });

    setRevenueArr(revenueTotalArr);
    setOrderCountArr(ordersTotalArr);
    setTotalRevenue(revenue);
    setTotalOrders(orderCount);
    setAverageOrderValue(calculatedAverageOrderValue / reports.length);
    setTotalCostOfGoods(costOfGoods);
    setTotalShipping(shipping);
    setTotalTax(tax);
    setProductSales(productSalesArr);
    setCategorySales(categoriesSalesArr);
  }, [reports]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="OrderHistory">
      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Revenue
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalRevenue)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                No. of Orders
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {totalOrders}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Avg. Order Value
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(averageOrderValue)}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="card-row">
        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Cost Of Goods
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalCostOfGoods)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Shipping
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalShipping)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-container">
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                Tax
              </Typography>
              <div className="card-spacing"></div>
              <Typography variant="h6">
                {formatter.format(totalTax)}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{margin: '30px 0 40px'}}>
        <Divider/>
      </div>

      <div>
        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Revenue',
            ],
            ...revenueArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Revenue',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Revenue' },
            },
            axes: {
              y: {
                Revenue: { label: 'Revenue ($)' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div style={{margin: '40px 0'}}>
          <Divider/>
        </div>

        <Chart
          width={'100%'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'date', label: 'Time' },
              'Orders',
            ],
            ...orderCountArr.map(d => [d[0], d[1]]),
          ]}
          options={{
            legend: {
              position: 'none',
            },
            chart: {
              title:
                'Number of Orders',
            },
            width: '100%',
            height: 500,
            series: {
              0: { axis: 'Orders' },
            },
            axes: {
              y: {
                Orders: { label: 'No. Orders' },
              },
            },
          }}
          rootProps={{ 'data-testid': '4' }}
        />

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Product Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byProductValue}
            exclusive
            onChange={(e, newValue) => {
              const productSalesCopy = [ ...productSales ];
              productSalesCopy.sort((a, b) => {
                let index = 1;

                if (newValue === 'Quantity') {
                  index = 2;
                }

                if (newValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setProductSales(productSalesCopy);
              setByProductValue(newValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Product', byProductValue],
              ...productSales.map(p => {
                let index = 1;

                if (byProductValue === 'Quantity') {
                  index = 2;
                }

                if (byProductValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byProductValue} by Product`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showOrderProductTable}
              onChange={(e) => {
                setShowOrderProductTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showOrderProductTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="products table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productSales.map((p, i) => (
                  <TableRow key={p[0]}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="sub-title-container">
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
            <Typography variant="subtitle1">
              Category Insights
            </Typography>
          </Toolbar>
        </div>

        <div>
          <ToggleButtonGroup
            value={byCategoryValue}
            exclusive
            onChange={(e, newValue) => {
              const categorySalesCopy = [ ...categorySales ];
              categorySalesCopy.sort((a, b) => {
                let index = 1;

                if (newValue === 'Quantity') {
                  index = 2;
                }

                if (newValue === 'COGS') {
                  index = 3;
                }

                return b[index] - a[index];
              });
              setCategorySales(categorySalesCopy);
              setByCategoryValue(newValue);
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="Revenue">
              Revenue
            </ToggleButton>
            <ToggleButton value="Quantity">
              Quantity
            </ToggleButton>
            <ToggleButton value="COGS">
              COGS
            </ToggleButton>
          </ToggleButtonGroup>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Category', byCategoryValue],
              ...categorySales.map(p => {
                let index = 1;

                if (byCategoryValue === 'Quantity') {
                  index = 2;
                }

                if (byCategoryValue === 'COGS') {
                  index = 3;
                }

                return [ p[0], p[index] ];
              }),
            ]}
            options={{
              title: `${byCategoryValue} by Category`,
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        </div>


        <FormControlLabel
          control={
            <Switch
              checked={showOrderCategoryTable}
              onChange={(e) => {
                setShowOrderCategoryTable(e.target.checked);
              }}
              name="enabled"
              color="primary"
            />
          }
          label="Show Table View"
        />

        <div className="sub-title-container" style={{display: showOrderCategoryTable ? 'inherit' : 'none'}}>
          <Divider/>
          <TableContainer>
            <Table aria-label="categories table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Revenue</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Cost of Goods</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categorySales.map((p, i) => (
                  <TableRow key={p[0]}>
                    <TableCell>
                      <strong>{p[0]}</strong>
                    </TableCell>
                    <TableCell>{formatter.format(p[1])}</TableCell>
                    <TableCell>{p[2]}</TableCell>
                    <TableCell>{formatter.format(p[3])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default AmazonOrderHistory;
