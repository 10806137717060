import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './courseEdit.scss';
import DeletableChip from './shared/deletableChip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CourseEdit(props) {
  const [editDay, setEditDay] = useState(props.day);
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [paragraphDeleteOpen, setParagraphDeleteOpen] = useState(false);
  const [paragraphDeleteIndex, setParagraphDeleteIndex] = useState(-1);
  const [selectedProductValues, setSelectedProductValues] = useState([]);
  const [deleteTextTrackIndex, setDeleteTextTrackIndex] = useState(-1);

  useEffect(() => {
    setSelectedProductValues(
      editDay.products.map(product => {
        return product.id;
      })
    );
  }, [editDay.products]);

  const handleChange = name => event => {
    setEditDay({
      ...editDay,
      [name]: event.target.value
    });
  };

  const handleParagraphChange = index => event => {
    const paragraphs = [ ...editDay.paragraphs ];
    paragraphs[index] = event.target.value;

    setEditDay({
      ...editDay,
      paragraphs
    });
  };

  const addParagraph = () => {
    const paragraphs = [ ...editDay.paragraphs, '' ];

    setEditDay({
      ...editDay,
      paragraphs
    });
  };

  const handleParagraphDeleteOpen = (i) => {
    setParagraphDeleteIndex(i);
    setParagraphDeleteOpen(true);
  };

  const handleParagraphDeleteClose = (deleteDay) => {
    if (deleteDay) {
      const paragraphs = [ ...editDay.paragraphs ];
      paragraphs.splice(paragraphDeleteIndex, 1);
      setEditDay({
        ...editDay,
        paragraphs
      });
    }
    setParagraphDeleteIndex(-1);
    setParagraphDeleteOpen(false);
  };

  const handleStepChange = index => event => {
    const steps = [ ...editDay.steps ];
    steps[index] = event.target.value;

    setEditDay({
      ...editDay,
      steps
    });
  };

  const addStep = () => {
    const steps = [ ...editDay.steps, '' ];

    setEditDay({
      ...editDay,
      steps
    });
  };

  const handleOpen = (i) => {
    setDeleteIndex(i);
    setOpen(true);
  };

  const handleClose = (deleteDay) => {
    if (deleteDay) {
      const steps = [ ...editDay.steps ];
      steps.splice(deleteIndex, 1);
      setEditDay({
        ...editDay,
        steps
      });
    }
    setDeleteIndex(-1);
    setOpen(false);
  };

  const handleRemoveProduct = (value) => {
    const index = editDay.products.indexOf(value);
    const products = editDay.products.slice();
    products.splice(index, 1);
    setEditDay({
      ...editDay,
      products
    });
  };

  const addTextTrack = () => {
    const newTextTrack = {
      title: "English CC",
      language: "en",
      type: 'text/vtt',
      uri: ''
    };

    const textTracks = (editDay.textTracks && editDay.textTracks.length) ?
      [ ...editDay.textTracks, newTextTrack ] :
      [ newTextTrack ];

    setEditDay({
      ...editDay,
      textTracks,
    });
  };

  const handleRemoveTextTrack = () => {
    const textTracks = [ ...editDay.textTracks ];

    textTracks.splice(deleteTextTrackIndex, 1);

    setEditDay({
      ...editDay,
      textTracks,
    });
    setDeleteTextTrackIndex(-1);
  };

  return (
    <div className="CourseEdit">
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.closeModal() }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {editDay.title ? editDay.title : ''}
          </Typography>
          <Button color="inherit" onClick={() => { props.saveDay(editDay) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        <Card className="drag-card" style={{marginTop: '20px', display: 'inline-block', marginLeft: 20}}>
          <CardContent>
          <TextField
            id="outlined-title"
            label="Title"
            value={editDay.title}
            onChange={handleChange('title')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Subtitle"
            value={editDay.subtitle}
            onChange={handleChange('subtitle')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Video ID"
            value={editDay.videoId}
            onChange={handleChange('videoId')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Video URL"
            value={editDay.videoUrl}
            onChange={handleChange('videoUrl')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Video Thumbnail"
            value={editDay.videoThumbnail}
            onChange={handleChange('videoThumbnail')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          <div className="section-container">
            <div className="section-label">Video Text Tracks</div>

            <List>
              {(editDay.textTracks || []).map((track, i) => {
                return (
                  <ListItem key={`track-${i}`}>
                    <TextField
                      label={`Track ${i + 1} Title`}
                      value={track.title}
                      onChange={(e) => {
                        const textTracks = [ ...editDay.textTracks ];
                        textTracks[i] = {
                          ...textTracks[i],
                          title: e.target.value,
                        };

                        setEditDay({
                          ...editDay,
                          textTracks,
                        });
                      }}
                      margin="normal"
                      variant="outlined"
                      className="day-text-field text-track-field"
                    />

                    <TextField
                      label={`Track ${i + 1} URL`}
                      value={track.uri}
                      onChange={(e) => {
                        const textTracks = [ ...editDay.textTracks ];
                        textTracks[i] = {
                          ...textTracks[i],
                          uri: e.target.value,
                        };

                        setEditDay({
                          ...editDay,
                          textTracks,
                        });
                      }}
                      margin="normal"
                      variant="outlined"
                      className="day-text-field text-track-field"
                    />

                    <IconButton edge="start" color="inherit" onClick={() => setDeleteTextTrackIndex(i)} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>

            <Button color="inherit" onClick={addTextTrack}>
              Add Text Track
            </Button>
          </div>

          <FormControl style={{ margin: 5, width: '99%'}}>
            <InputLabel htmlFor="select-multiple-chip">Products</InputLabel>
            <Select
              multiple
              value={editDay.products}
              onChange={handleChange('products')}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value.id}
                      label={value.name}
                      style={{margin: 2}}
                      color="primary"
                      onDelete={() => { handleRemoveProduct(value) }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {props.products.filter(product => {
                return !selectedProductValues.includes(product.id);
              }).map(product => (
                <MenuItem key={product.id} value={product}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid', marginTop: 20, borderRadius: 4}}>
            <FormGroup style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', paddingLeft: 10 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editDay.hasPDF}
                    onChange={(e) => {
                      setEditDay({
                        ...editDay,
                        hasPDF: e.target.checked,
                      });
                    }}
                    name="Include PDF"
                    color="primary"
                  />
                }
                label="Include PDF"
              />
            </FormGroup>

            {!editDay.hasPDF ? null :
              <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
                <TextField
                  label="PDF Description"
                  value={editDay.pdfTitle}
                  onChange={handleChange('pdfTitle')}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                />
                <TextField
                  label="PDF URL"
                  value={editDay.pdfURL}
                  onChange={handleChange('pdfURL')}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                />
              </FormGroup>
            }
          </div>

          <div style={{borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid', marginTop: 20, borderRadius: 4}}>
            <FormGroup style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', paddingLeft: 10 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editDay.hasQuiz || false}
                    onChange={(e) => {
                      setEditDay({
                        ...editDay,
                        hasQuiz: e.target.checked,
                      });
                    }}
                    name="Include Quiz"
                    color="primary"
                  />
                }
                label="Include Quiz"
              />
            </FormGroup>

            {!editDay.hasQuiz ? null :
              <FormGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
                <TextField
                  label="Quiz Title (optional)"
                  value={editDay.quizTitle || ''}
                  onChange={handleChange('quizTitle')}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                />
                <TextField
                  label="Quiz URL"
                  value={editDay.quizURL || ''}
                  onChange={handleChange('quizURL')}
                  margin="normal"
                  variant="outlined"
                  className="day-text-field"
                />
              </FormGroup>
            }
          </div>

          <FormControl style={{ width: '49%', marginTop: 23, marginBottom: 10 }}>
            <InputLabel id="product-variation-stock">
              Ad to show on completion
            </InputLabel>
            <Select
              value={editDay.adId}
              onChange={handleChange('adId')}
            >
              {props.ads.map(ad => {
                return <MenuItem key={ad.id} value={ad.id}>{ad.internalName}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <h2>Description</h2>
          <List>
            {editDay.paragraphs.map((step, i) => {
              return (
                <ListItem key={`step-${i}`}>
                  <TextField
                    label={`Paragraph ${i + 1}`}
                    value={editDay.paragraphs[i]}
                    onChange={handleParagraphChange(i)}
                    margin="normal"
                    variant="outlined"
                    className="day-text-field"
                  />

                  <IconButton edge="start" color="inherit" onClick={handleParagraphDeleteOpen.bind(this, i)} aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
          <Button color="inherit" onClick={addParagraph}>
            Add Paragraph
          </Button>

          <h2>Steps</h2>
          <List>
            {editDay.steps.map((step, i) => {
              return (
                <ListItem key={`step-${i}`}>
                  <TextField
                    label={`Step ${i + 1}`}
                    value={editDay.steps[i]}
                    onChange={handleStepChange(i)}
                    margin="normal"
                    variant="outlined"
                    className="day-text-field"
                  />

                  <IconButton edge="start" color="inherit" onClick={handleOpen.bind(this, i)} aria-label="Edit">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
          <Button color="inherit" onClick={addStep}>
            Add Step
          </Button>

          </CardContent>
        </Card>
      </div>

      <Dialog
        open={open}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Step?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete step {deleteIndex + 1}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose.bind(this, true)} color="primary">
            Confirm
          </Button>
          <Button onClick={handleClose.bind(this, false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={paragraphDeleteOpen}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Paragraph?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete paragraph {paragraphDeleteIndex + 1}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleParagraphDeleteClose.bind(this, true)} color="primary">
            Confirm
          </Button>
          <Button onClick={handleParagraphDeleteClose.bind(this, false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteTextTrackIndex !== -1}
        disableBackdropClick={true}
      >
        <DialogTitle>Delete Text Track?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete Text Track {deleteTextTrackIndex + 1}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveTextTrack} color="primary">
            Confirm
          </Button>
          <Button onClick={() => setDeleteTextTrackIndex(-1)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseEdit;
