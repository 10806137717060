import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { debounce } from 'lodash'
import moment from 'moment';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
  Dialog,
  Slide,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Paper,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Card,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Popover,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import AddFilesModal from '../../../fileManager/addFilesModal/addFilesModal';
import EditFile from '../../../fileManager/editFile/editFile';
import DeletableChip from '../../../shared/deletableChip';
import PageBuilder from '../../../shared/pageBuilder';
import AddVideoFromUrlModal from '../../../fileManager/addVideoFromUrlModal/addVideoFromUrlModal';
import './editProduct.scss';

import { emptyProduct } from '../emptyProduct';
import { config } from '../../../../config';
import { TabsContent } from './components/tabsContent';
import { TreatActions } from './components/treatActions';
import { TreatBanner } from './components/treatBanner';
import { ColorPicker, useColor } from '../../../shared/colorPalette';

const cloneDeep = require('lodash.clonedeep');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TAB_CONTENT = ['Description', 'Nutritional Facts', 'How to Use']

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const subscriptionIntervals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const itemTypes = ['title', 'subtitle', 'paragraph', 'image', 'video', 'textOverlayImage'];
const simpleTypes = ['title', 'subtitle', 'paragraph'];

function EditProduct(props) {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(emptyProduct);
  const [shippingClasses, setShippingClasses] = useState([]);
  const [editFileOpen, setEditFileOpen] = useState(false);
  const [editFile, setEditFile] = useState({});
  const [editFileIndex, setEditFileIndex] = useState(-1);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [tags, setTags] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [groups, setGroups] = useState([]);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [subscriptionDeleteIndex, setSubscriptionDeleteIndex] = useState(-1);
  const [showAddVideoFromUrl, setShowAddVideoFromUrl] = useState(false);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [sectionDeleteIndex, setSectionDeleteIndex] = useState(-1);
  const [dropdownItemDelete, setDropdownItemDelete] = useState([-1, -1]);
  const [pageBuilderOpen, setPageBuilderOpen] = useState(false);
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [originalSku, setOriginalSku] = useState('');
  const [shopFilters, setShopFilters] = useState([]);
  const [showAddFilterCategory, setShowAddFilterCategory] = useState(false);
  const [newFilterCategory, setNewFilterCategory] = useState('');
  const [showAddFilterCategoryTypeIndex, setShowAddFilterCategoryTypeIndex] = useState(-1);
  const [newFilterCategoryType, setNewFilterCategoryType] = useState('');
  const [showEditFilterCategoryNameIndex, setShowEditFilterCategoryNameIndex] = useState(-1);
  const [editFilterCategoryName, setEditFilterCategoryName] = useState('');
  const [defaultSubscriptionDiscountPercentage, setDefaultSubscriptionDiscountPercentage] = useState(0);
  const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
  const [hideFaqEditors, setHideFaqEditors] = useState(false);

  //Background Color

  const [backgroundColorAnchorElem, setBackgroundColorAnchorElem] = useState(null);
  const [backgroundColor, setBackgroundColor] = useColor('hex', product.backgroundColorMediaSection);

  const backgroundColorOpen = Boolean(backgroundColorAnchorElem);
  const backgroundColorId = backgroundColorOpen ? 'background-color-popover' : undefined;

  useEffect(() => {
    getProduct();
  }, []);


  const getProduct = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/shop/products');
        return;
      }

      const productSnapshot = await db.collection('products-v2').doc(id).get();
      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const contentSnapshot = await db.collection('config').doc('content').get();
      const shippingSnapshot = await db.collection('config').doc('shipping').get();
      const shopFiltersSnapshot = await db.collection('shop-filters').doc('filters').get();
      const subscriptionBoxesSnapshot = await db.collection('subscription-boxes').get();

      const shopSettingsData = shopSettingsSnapshot.data();

      const productData = productSnapshot.data();

      if (!productData) {
        props.history.replace('/shop/products');
        return;
      }

      const templatesQuerySnapshot = await db.collection('global-templates').get();

      const templates = templatesQuerySnapshot.docs.map(doc => {
        const data = doc.data();

        if (data.children && typeof data.children === 'string') {
          data.children = JSON.parse(data.children);
        }

        return data;
      });

      const onlyGlobalTemplates = templates.filter(t => {
        return t.global;
      });

      if (typeof productData.editorData === 'string') {
        productData.editorData = JSON.parse(productData.editorData);
      }

      const updatedEditorData = updateGlobalTemplates(productData.editorData, onlyGlobalTemplates);

      productData.editorData = updatedEditorData;

      const shippingData = shippingSnapshot.data();

      let selectedSubscriptionProduct = null;

      if (productData.selectedSubscriptionProduct) {
        const foundProduct = shopSettingsData.simplifiedProducts.find(p => {
          return p.id === productData.selectedSubscriptionProduct.id;
        });

        if (foundProduct) {
          selectedSubscriptionProduct = foundProduct;
        }
      }

      const subscriptionBoxesData = subscriptionBoxesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setProduct({
        ...productData,
        tabsContent: productData?.tabsContent ?? emptyProduct.tabsContent,
        treatActions: productData?.treatActions ?? emptyProduct.treatActions,
        treatBanner: productData?.treatBanner ?? emptyProduct.treatBanner,
        id: productSnapshot.id,
        selectedSubscriptionProduct,
        shippingClass: productData.shippingClass || (shippingData.classes.length ? shippingData.classes[0].id : ''),
      });
      setOriginalSku(product.sku);
      setGlobalTemplates(templates);
      setCategories(shopSettingsData.productCategories);
      setTags(contentSnapshot.data().tags);
      setGroups(shopSettingsData.productGroups);
      setSimplifiedProducts(shopSettingsData.simplifiedProducts);
      setDefaultSubscriptionDiscountPercentage(shopSettingsData.defaultSubscriptionDiscountPercentage || 0);
      setShippingClasses(shippingData.classes);
      setShopFilters(shopFiltersSnapshot.data().filters);
      setSubscriptionBoxes(subscriptionBoxesData);
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred fetching product details. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const updateGlobalTemplates = (data, templates) => {
    if (data.global) {
      const foundIndex = templates.findIndex(t => {
        return t.id === data.id;
      });

      if (foundIndex !== -1) {
        const templateCopy = cloneDeep(templates[foundIndex]);
        for (let key in templateCopy) {
          data[key] = templateCopy[key];
        }
      }
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        updateGlobalTemplates(children[i], templates);
      }
    }

    return data;
  };

  const findAndSaveGlobalTemplates = (data, templatesToSave) => {
    if (data.global) {
      const dataToSave = {
        ...data,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      if (!dataToSave.created) {
        dataToSave.created = dataToSave.updated;
      }

      templatesToSave[dataToSave.id] = dataToSave;
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        findAndSaveGlobalTemplates(children[i], templatesToSave);
      }
    }

    return templatesToSave;
  };

  const saveProduct = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const templatesToSave = findAndSaveGlobalTemplates(product.editorData, {});
      const sharedTemplates = [];

      for (let key in templatesToSave) {
        sharedTemplates.push(key);
        try {
          let globalTemplatesCopy = [...globalTemplates];
          const foundIndex = globalTemplates.findIndex(t => {
            return `${t.id}` === `${key}`;
          });

          if (foundIndex !== -1) {
            globalTemplatesCopy[foundIndex] = templatesToSave[key];
          } else {
            globalTemplatesCopy.push(templatesToSave[key]);
            globalTemplatesCopy = [templatesToSave[key], ...globalTemplatesCopy];
          }

          setGlobalTemplates(globalTemplatesCopy);

          await firebase.firestore().collection('global-templates').doc(templatesToSave[key].id).set({
            ...templatesToSave[key],
            children: JSON.stringify(templatesToSave[key].children),
          });
        } catch (e) {
          console.log('error saving template', e);
        }
      }

      await db.collection('products-v2').doc(product.id).update({
        ...product,
        updated: Date.now(),
        sharedTemplates,
        editorData: JSON.stringify(product.editorData),
        lastUpdatedBy: firebase.auth().currentUser.email,
        subscriptionOnly: product.subscriptionOnly || false,
      });
      const savedData = (await db.collection('products-v2').doc(product.id).get()).data();

      if (typeof savedData.editorData === 'string') {
        savedData.editorData = JSON.parse(savedData.editorData);
      }

      const onlyGlobalTemplates = [];

      for (let key in templatesToSave) {
        onlyGlobalTemplates.push(templatesToSave[key]);
      }

      const updatedEditorData = updateGlobalTemplates(savedData.editorData, onlyGlobalTemplates);

      setProduct({
        ...savedData,
        selectedSubscriptionProduct: product.selectedSubscriptionProduct || null,
        sharedTemplates,
        editorData: updatedEditorData,
      });

      let lowestSubscriptionPrice = 1000000000;
      let lowestSubscriptionPriceWithoutFirstTime = 1000000000;
      let addToSubscriptionPrice = 1000000000;

      if (savedData.subscriptionOptions && savedData.subscriptionOptions.length && !savedData.subscribesToDifferentProduct) {
        savedData.subscriptionOptions.forEach(o => {
          const discountPercentage = +(o.discountPercentage);
          const firstTimeDiscountPercentage = +(o.firstOrderDiscountPercentage);

          if (firstTimeDiscountPercentage) {
            const firstTimePrice = (+savedData.price * (1 - (firstTimeDiscountPercentage / 100)));

            if (firstTimePrice < lowestSubscriptionPrice) {
              lowestSubscriptionPrice = firstTimePrice;
            }
          }

          if (o.discountPercentage) {
            const discountPrice = (+savedData.price * (1 - (discountPercentage / 100)));

            if (discountPrice < lowestSubscriptionPrice) {
              lowestSubscriptionPrice = discountPrice;
            }

            if (discountPrice < lowestSubscriptionPriceWithoutFirstTime) {
              lowestSubscriptionPriceWithoutFirstTime = discountPrice;
            }
          }
        });

        let addToSubscriptionDiscountPercentage = 0;

        if (savedData.addToSubscriptionDiscountPercentage) {
          addToSubscriptionDiscountPercentage = +savedData.addToSubscriptionDiscountPercentage;
        } else if (defaultSubscriptionDiscountPercentage) {
          addToSubscriptionDiscountPercentage = +defaultSubscriptionDiscountPercentage;
        }

        if (addToSubscriptionDiscountPercentage) {
          addToSubscriptionPrice = (+savedData.price * (1 - (addToSubscriptionDiscountPercentage / 100)));
        }
      } else {
        lowestSubscriptionPrice = null;
        addToSubscriptionPrice = null;
      }

      if (lowestSubscriptionPrice === 1000000000) {
        lowestSubscriptionPrice = null;
      }

      if (addToSubscriptionPrice === 1000000000) {
        addToSubscriptionPrice = null;
      }

      if (addToSubscriptionPrice && (lowestSubscriptionPriceWithoutFirstTime < addToSubscriptionPrice)) {
        addToSubscriptionPrice = lowestSubscriptionPriceWithoutFirstTime;
      }

      const simplifiedProductsCopy = [...simplifiedProducts];
      const productIndex = simplifiedProductsCopy.findIndex(p => p.id === product.id);
      const simplifiedProduct = {
        id: product.id,
        name: product.name,
        shopPageName: product.shopPageName ? product.shopPageName.trim() : '',
        path: product.path,
        price: product.price,
        salePrice: product.salePrice,
        isOnSale: product.isOnSale,
        lowestSubscriptionPrice,
        addToSubscriptionPrice,
        image: product.media.length ? product.media[0] : null,
        sku: product.sku,
        active: product.active,
        group: product.group,
        groupedThumbnail: product.groupedThumbnail,
        isDigital: product.isDigital,
        showOnShopPage: product.showOnShopPage || false,
        showInSearch: product.showInSearch || false,
        createProductPage: product.createProductPage || false,
        shippingClass: product.shippingClass || '',
        categories: product.categories || [],
        filters: product.filters || [],
        costOfGood: product.costOfGood || 0,
        isGiftCard: product.isGiftCard || false,
        isCustomizableBundle: product.isCustomizableBundle || false,
        useCustomizableBundleProductsPricing: product.useCustomizableBundleProductsPricing || false,
        isSubscriptionBox: product.isSubscriptionBox || false,
      };

      if (product.isSubscriptionBox) {
        const subscriptionBoxIndex = subscriptionBoxes.findIndex(b => b.id === product.selectedSubscriptionBox);

        if (subscriptionBoxIndex !== -1) {
          const subscriptionBox = subscriptionBoxes[subscriptionBoxIndex];

          if (subscriptionBox.hasFixedPrice) {
            simplifiedProduct.price = (+subscriptionBox.price).toFixed(2);

            if (subscriptionBox.isOnSale) {
              simplifiedProduct.isOnSale = true;
              simplifiedProduct.salePrice = (+subscriptionBox.salePrice).toFixed(2);
            } else {
              simplifiedProduct.isOnSale = false;
              simplifiedProduct.salePrice = '';
            }
          } else {
            let lowestOrderPrice = 10000;
            let highestOrderPrice = 0;

            subscriptionBox.orders.forEach(order => {
              const price = order.isOnSale ? +order.salePrice : +order.price;

              if (price < lowestOrderPrice) {
                lowestOrderPrice = price;
              }

              if (price > highestOrderPrice) {
                highestOrderPrice = price;
              }
            });

            simplifiedProduct.price = (lowestOrderPrice).toFixed(2);
            simplifiedProduct.isOnSale = false;
            simplifiedProduct.salePrice = '';
            simplifiedProduct.lowestSubscriptionBoxPrice = (lowestOrderPrice).toFixed(2);
            simplifiedProduct.highestSubscriptionBoxPrice = (highestOrderPrice).toFixed(2);
          }
        }
      }

      if (productIndex !== -1) {
        simplifiedProductsCopy[productIndex] = simplifiedProduct;
      } else {
        simplifiedProductsCopy.push(simplifiedProduct);
      }

      await db.collection('config').doc('shop-settings').update({
        simplifiedProducts: simplifiedProductsCopy,
      });

      setSimplifiedProducts(simplifiedProductsCopy);

      if (!product.isCustomizableBundle && !product.isSubscriptionBox) {
        try {
          const ordoroData = {
            product,
          };

          if (originalSku !== product.sku) {
            ordoroData.oldSku = originalSku;
            setOriginalSku(product.sku);
          }

          const ordoroResponse = await axios.post(`${config.functions}/webApi/update-ordoro-product`, ordoroData);

          if (ordoroResponse.data.error) {
            console.log(ordoroResponse.data);
            window.alert('Product was saved but not updated in Ordoro. Make sure this product gets updated in Ordoro.');
          }
        } catch (e) {
          window.alert('Product was saved but not updated in Ordoro. Make sure this product gets updated in Ordoro.');
        }
      }
    } catch (e) {
      console.log(e);
      window.alert('There was an error saving the changes. Please try again.');
    }

    setLoading(false);
  };

  const handleChange = (e, type) => {
    setProduct({
      ...product,
      [type]: e.target.value,
    });
  };

  const closeEditImageModal = () => {
    setEditFileOpen(false);
    setEditFileIndex(-1);
    setEditFile({});
  };

  const deleteFile = async (removeFromProduct) => {
    setLoading(true);
    setEditFileOpen(false);

    const storageRef = firebase.storage().ref();
    const file = product.media[editFileIndex];

    try {
      if (file.storagePath && !removeFromProduct) {
        const child = file.storagePath;
        const imageRef = storageRef.child(child);

        await imageRef.delete();
      }

      const filesCopy = product.media.slice();

      filesCopy.splice(editFileIndex, 1);

      await firebase.firestore().collection('products-v2').doc(product.id).update({
        media: filesCopy,
      });

      setProduct({
        ...product,
        media: filesCopy,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error deleting the file.');
    }

    closeEditImageModal();
    setLoading(false);
  };

  const saveImage = async (file) => {
    setLoading(true);
    setEditFileOpen(false);

    try {
      const filesCopy = product.media.slice();

      filesCopy[editFileIndex] = {
        ...filesCopy[editFileIndex],
        name: file.name,
        alt: file.alt,
        placeholder: file.placeholder,
      };

      await firebase.firestore().collection('products-v2').doc(product.id).update({
        media: filesCopy,
      });

      setProduct({
        ...product,
        media: filesCopy,
      });
    } catch (e) {
      console.log(e);
      window.alert('There was an error updating the file.');
    }

    closeEditImageModal();
    setLoading(false);
  };

  const addFiles = async (files) => {
    try {
      const filesToUpload = [];

      for (let i = 0; i < files.length; i++) {
        filesToUpload.push({
          file: files[i],
          name: '',
          alt: '',
          placeholder: '',
          fileName: files[i].name,
        });
      }

      setFilesToAdd(filesToUpload);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelAddFiles = () => {
    setShowFileUploadModal(false);
    setFilesToAdd([]);
  };

  const uploadFiles = async () => {
    const storageRef = firebase.storage().ref();
    const filesCopy = [...product.media];

    setShowFileUploadModal(false);
    setLoading(true);

    for (let i = 0; i < filesToAdd.length; i++) {
      const child = `assets/products/${product.id}/${`${Date.now()}-${filesToAdd[i].fileName}`}`;
      const imageRef = storageRef.child(child);

      try {
        const snapshot = await imageRef.put(filesToAdd[i].file, {
          cacheControl: 'public,max-age=2592000',
        });

        let url = await imageRef.getDownloadURL();

        url = url.replace(
          `${config.storage}/o/assets`,
          `${config.cdn}/assets`,
        );

        const fileToAdd = {
          folder: `products/${product.id}`,
          storagePath: snapshot.metadata.fullPath,
          contentType: snapshot.metadata.contentType,
          size: snapshot.metadata.size,
          timeCreated: snapshot.metadata.timeCreated,
          name: filesToAdd[i].name,
          alt: filesToAdd[i].alt,
          placeholder: filesToAdd[i].placeholder,
          url,
        };

        filesCopy.push(fileToAdd);
      } catch (e) {
        console.log('e', e);
      }
    }

    try {
      await firebase.firestore().collection('products-v2').doc(product.id).update({
        media: filesCopy,
      });

      setProduct({
        ...product,
        media: filesCopy,
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding files.');
    }

    cancelAddFiles();
    setLoading(false);
  };

  const saveVideoFromURL = async (data) => {
    setShowAddVideoFromUrl(false);
    setLoading(true);
    const filesCopy = [...product.media];

    try {
      const fileToAdd = {
        folder: `products/${product.id}`,
        storagePath: '',
        contentType: 'video',
        size: 0,
        timeCreated: new Date().toISOString(),
        name: data.title,
        alt: '',
        placeholder: data.placeholder,
        url: data.url,
      };

      filesCopy.push(fileToAdd);
    } catch (e) {
      console.log('e', e);
      window.alert('There was an error adding the video. Please try again.');
    }

    try {
      await firebase.firestore().collection('products-v2').doc(product.id).update({
        media: filesCopy,
      });

      setProduct({
        ...product,
        media: filesCopy,
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding files.');
    }

    setLoading(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setLoading(true);

    const media = reorder(
      product.media,
      result.source.index,
      result.destination.index
    );

    setProduct({
      ...product,
      media,
    });

    try {
      await firebase.firestore().collection('products-v2').doc(product.id).update({
        media,
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding files.');
    }

    setLoading(false);
  };

  const addNewGroup = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddGroup(false);

    try {
      const productGroups = [...groups];
      productGroups.push(newGroupName);

      await db.collection('config').doc('shop-settings').update({
        productGroups,
      });

      setGroups(productGroups);
      setProduct({
        ...product,
        group: newGroupName,
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding new group. Please try again.');
    }

    setLoading(false);
  };

  const addNewCategory = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddCategory(false);

    try {
      const productCategories = [...categories];
      productCategories.push(newCategoryName);

      await db.collection('config').doc('shop-settings').update({
        productCategories,
      });

      setCategories(productCategories);
      setProduct({
        ...product,
        categories: [...product.categories, newCategoryName],
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding new category. Please try again.');
    }

    setLoading(false);
  };

  const addNewTag = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddTag(false);

    try {
      const productTags = [...tags];
      productTags.push(newTagName);

      await db.collection('config').doc('content').update({
        tags: productTags,
      });

      setTags(productTags);
      setProduct({
        ...product,
        tags: [...product.tags, newTagName],
      });
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding new tag. Please try again.');
    }

    setLoading(false);
  };

  const addNewFilterCategory = async () => {
    const db = firebase.firestore();
    setLoading(true);
    setShowAddFilterCategory(false);

    try {
      const filterCategories = [...shopFilters];
      filterCategories.push({
        id: Date.now(),
        name: newFilterCategory,
        options: [],
      });

      await db.collection('shop-filters').doc('filters').update({
        filters: filterCategories,
      });

      setShopFilters(filterCategories);
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding new filter category. Please try again.');
    }

    setLoading(false);
  };

  const updateFilterCategoryName = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const filterCategories = [...shopFilters];
      filterCategories[showEditFilterCategoryNameIndex] = {
        ...filterCategories[showEditFilterCategoryNameIndex],
        name: editFilterCategoryName,
      };

      await db.collection('shop-filters').doc('filters').update({
        filters: filterCategories,
      });

      setShopFilters(filterCategories);
      setShowEditFilterCategoryNameIndex(-1);
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred updating filter category name. Please try again.');
    }

    setLoading(false);
  };

  const addNewFilterCategoryType = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const filterCategories = [...shopFilters];
      const options = [...filterCategories[showAddFilterCategoryTypeIndex].options];
      options.push(newFilterCategoryType);
      filterCategories[showAddFilterCategoryTypeIndex] = {
        ...filterCategories[showAddFilterCategoryTypeIndex],
        options,
      };

      await db.collection('shop-filters').doc('filters').update({
        filters: filterCategories,
      });

      setShopFilters(filterCategories);
      setShowAddFilterCategoryTypeIndex(-1);
    } catch (e) {
      console.log('e', e);
      window.alert('An error occurred adding new filter category type. Please try again.');
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#fff', top: '50%', position: 'absolute' }} />
      </div>
    );
  };

  const updateSubscriptionItem = (e, i, type) => {
    const subscriptionOptions = [...product.subscriptionOptions];
    const option = {
      ...product.subscriptionOptions[i],
      [type]: e.target.value,
    };
    subscriptionOptions[i] = option;
    setProduct({
      ...product,
      subscriptionOptions,
    });
  };

  const hasUniqueURL = () => {
    return simplifiedProducts.findIndex(p => p.path === product.path && p.id !== product.id) === -1;
  };

  const hasUniqueSKU = () => {
    return simplifiedProducts.findIndex(p => p.sku === product.sku && p.id !== product.id) === -1;
  };

  const addDropdown = () => {
    const descriptionSections = [...product.descriptionSections];

    descriptionSections.push({
      title: 'New Dropdown Title',
      sections: [],
    });

    setProduct({
      ...product,
      descriptionSections,
    });
  };

  const handlePanelChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const addDropdownDetail = (type, i) => {
    const descriptionSections = [...product.descriptionSections];
    const sections = [...descriptionSections[i].sections];

    const details = {
      type,
      value: '',
    };

    if (simpleTypes.includes(type)) {
      details.textAlign = 'left';
    }

    if (type === 'image') {
      details.marginBottom = 0;
      details.marginTop = 0;
      details.width = 0;
    }

    if (type === 'video') {
      details.thumbnail = '';
    }

    if (type === 'textOverlayImage') {
      details.title = '';
      details.paragraph = '';
      details.textAlign = 'center';
      details.overlayFilterColor = '';
      details.textColor = '';
    }

    sections.push(details);

    descriptionSections[i] = {
      ...descriptionSections[i],
      sections,
    };

    setProduct({
      ...product,
      descriptionSections,
    });
  };

  const handleItemChange = (e, i, j, type) => {
    const descriptionSections = [...product.descriptionSections];
    const sections = [...descriptionSections[i].sections];

    const section = {
      ...sections[j],
      [type]: e.target.value,
    };

    sections[j] = section;

    descriptionSections[i] = {
      ...descriptionSections[i],
      sections,
    };

    setProduct({
      ...product,
      descriptionSections,
    });
  };

  const changeBackgroundColor = debounce((color) => {
    setProduct({
      ...product,
      backgroundColorMediaSection: color
    })
  }, 200)

  const renderItem = (item, i, j) => {
    if (simpleTypes.includes(item.type)) {
      return (
        <div style={{ display: 'flex', flex: 1 }}>
          <TextField
            style={{ marginRight: 20 }}
            label={`${item.type.toUpperCase()} TEXT`}
            value={item.value}
            onChange={(e) => { handleItemChange(e, i, j, 'value') }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
            multiline={item.type === 'paragraph'}
            rows={item.type === 'paragraph' ? 4 : 0}
          />

          <FormControl style={{ width: '49%', marginRight: '2%' }}>
            <InputLabel>
              Align Text
            </InputLabel>
            <Select
              value={item.textAlign}
              onChange={(e) => { handleItemChange(e, i, j, 'textAlign') }}
            >
              <MenuItem value={'left'}>Left</MenuItem>
              <MenuItem value={'center'}>Center</MenuItem>
              <MenuItem value={'right'}>Right</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    }

    if (item.type === 'button') {
      return (
        <div style={{ display: 'flex', flex: 1 }}>
          <TextField
            style={{ marginRight: 20 }}
            label={`${item.type.toUpperCase()} TEXT`}
            value={item.value}
            onChange={(e) => { handleItemChange(e, i, j, 'value') }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </div>
      );
    }

    if (item.type === 'image') {
      return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginRight: 20 }}>
          <div style={{ width: '100%' }}>
            <TextField
              label="IMAGE URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, j, 'value') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <TextField
              style={{ marginRight: 20 }}
              label="MARGIN TOP"
              value={item.marginTop}
              onChange={(e) => { handleItemChange(e, i, j, 'marginTop') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{ marginRight: 20 }}
              label="MARGIN BOTTOM"
              value={item.marginBottom}
              onChange={(e) => { handleItemChange(e, i, j, 'marginBottom') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
            <TextField
              label="IMAGE WIDTH"
              value={item.width}
              onChange={(e) => { handleItemChange(e, i, j, 'width') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>
      );
    }

    if (item.type === 'video') {
      return (
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', flex: 1 }}>
            <TextField
              style={{ marginRight: 20 }}
              label="VIDEO URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, j, 'value') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{ marginRight: 20 }}
              label="VIDEO THUMBNAIL"
              value={item.thumbnail}
              onChange={(e) => { handleItemChange(e, i, j, 'thumbnail') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>
      );
    }

    if (item.type === 'textOverlayImage') {
      return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              style={{ marginRight: 20 }}
              label="IMAGE URL"
              value={item.value}
              onChange={(e) => { handleItemChange(e, i, j, 'value') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
            <FormControl style={{ width: '49%', marginRight: 20 }}>
              <InputLabel id="product-variation-stock">
                Align Text
              </InputLabel>
              <Select
                value={item.textAlign}
                onChange={(e) => { handleItemChange(e, i, j, 'textAlign') }}
              >
                <MenuItem value={'center'}>Center</MenuItem>
                <MenuItem value={'left'}>Left</MenuItem>
                <MenuItem value={'right'}>Right</MenuItem>
                <MenuItem value={'top'}>Top</MenuItem>
                <MenuItem value={'bottom'}>Bottom</MenuItem>
                <MenuItem value={'top-left'}>Top Left</MenuItem>
                <MenuItem value={'top-right'}>Top Right</MenuItem>
                <MenuItem value={'bottom-left'}>Bottom Left</MenuItem>
                <MenuItem value={'bottom-right'}>Bottom Right</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <TextField
              style={{ marginRight: 20 }}
              label="TITLE TEXT"
              value={item.title}
              onChange={(e) => { handleItemChange(e, i, j, 'title') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
            <TextField
              style={{ marginRight: 20 }}
              label="Text Color (use hex, rgb or rgba)"
              value={item.textColor}
              onChange={(e) => { handleItemChange(e, i, j, 'textColor') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <TextField
              style={{ marginRight: 20 }}
              label="PARAGRAPH TEXT"
              value={item.paragraph}
              onChange={(e) => { handleItemChange(e, i, j, 'paragraph') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <TextField
              style={{ marginRight: 20 }}
              label="OVERLAY FILTER COLOR (use rgba, e.g. rgba(0, 0, 0, 0.5))"
              value={item.overlayFilterColor}
              onChange={(e) => { handleItemChange(e, i, j, 'overlayFilterColor') }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />
          </div>
        </div>
      );
    }
  };

  const moveDropdownItemDown = (i, j) => {
    const descriptionSections = [...product.descriptionSections];
    const sections = [...descriptionSections[i].sections];

    const itemToMoveDown = sections[j];
    const itemToMoveUp = sections[j + 1];

    sections[j + 1] = itemToMoveDown;
    sections[j] = itemToMoveUp;

    descriptionSections[i] = {
      ...descriptionSections[i],
      sections,
    };

    setProduct({
      ...product,
      descriptionSections,
    });
  };

  const moveDropdownItemUp = (i, j) => {
    const descriptionSections = [...product.descriptionSections];
    const sections = [...descriptionSections[i].sections];

    const itemToMoveDown = sections[j - 1];
    const itemToMoveUp = sections[j];

    sections[j - 1] = itemToMoveUp;
    sections[j] = itemToMoveDown;

    descriptionSections[i] = {
      ...descriptionSections[i],
      sections,
    };

    setProduct({
      ...product,
      descriptionSections,
    });
  };

  const renderDropdownItemReorderArrows = (i, j) => {
    const arrowItems = [];

    if (j !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { moveDropdownItemUp(i, j) }} key={`up-arrow-${i}-${j}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{ color: '#000' }} />
        </IconButton>
      );
    } else {
      arrowItems.push(<div key={`up-arrow-${i}-${j}`} style={{ width: 48, display: 'inline-block' }}></div>);
    }

    if (j !== product.descriptionSections[i].sections.length - 1) {
      arrowItems.push(
        <IconButton onClick={() => { moveDropdownItemDown(i, j) }} key={`down-arrow-${i}-${j}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{ color: '#000' }} />
        </IconButton>
      );
    } else {
      arrowItems.push(<div key={`down-arrow-${i}-${j}`} style={{ width: 48, display: 'inline-block' }}></div>);
    }

    return arrowItems;
  };

  const saveModule = async (moduleToSave) => {
    const dataToSave = {
      ...moduleToSave,
      created: Date.now(),
      updated: Date.now(),
      lastUpdatedBy: firebase.auth().currentUser.email,
    };

    // Don't save globals it here. Only save globals on page save
    if (dataToSave.global) {
      setGlobalTemplates([dataToSave, ...globalTemplates]);
      setModalTitle('Success!');
      setModalText(`The ${moduleToSave.type} was added an will be saved when saving the page.`);
    } else {
      console.log('module', moduleToSave);
      const db = firebase.firestore();
      setLoading(true);

      try {
        await db.collection('global-templates').doc(dataToSave.id).set({
          ...dataToSave,
          children: JSON.stringify(dataToSave.children),
        });

        setGlobalTemplates([dataToSave, ...globalTemplates]);

        setLoading(false);
        setModalTitle('Success!');
        setModalText(`The ${moduleToSave.type} was saved successfully.`);
      } catch (e) {
        console.log('error', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`There was an error saving this ${moduleToSave.type}. Please try again.`);
      }
    }
  };

  const renderOfferDetails = () => {
    const value = product.introOfferType;

    if (value === 'free-trial') {
      return (
        <>
          <Typography style={{ marginBottom: 5, marginTop: 8 }}>Length of free trial</Typography>

          <FormControl
            style={{ marginBottom: 4, width: 75 }}
            variant="outlined"
          >
            <Select
              value={product.introOfferDetails.firstPaymentInterval}
              onChange={(e) => {
                setProduct({
                  ...product,
                  introOfferDetails: {
                    ...product.introOfferDetails,
                    firstPaymentInterval: e.target.value,
                  },
                });
              }}
              margin="dense"
            >
              {subscriptionIntervals.map((value) => {
                return <MenuItem key={`interval-${value}`} value={value}>{value}</MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ marginBottom: 4, width: 125 }}
            variant="outlined"
          >
            <Select
              value={product.introOfferDetails.firstPaymentPeriod}
              onChange={(e) => {
                setProduct({
                  ...product,
                  introOfferDetails: {
                    ...product.introOfferDetails,
                    firstPaymentPeriod: e.target.value,
                  },
                });
              }}
              margin="dense"
            >
              {['day', 'week', 'month', 'year'].map((value) => {
                return <MenuItem key={value} value={value}><span style={{ textTransform: 'capitalize' }}>{value}{product.introOfferDetails.firstPaymentInterval > 1 ? 's' : ''}</span></MenuItem>
              })}
            </Select>
          </FormControl>
        </>
      );
    }

    if (value === 'single-payment') {
      return (
        <>
          <Typography style={{ marginBottom: 5, marginTop: 8 }}>Access length after up front payment</Typography>

          <FormControl
            style={{ marginBottom: 4, width: 75 }}
            variant="outlined"
          >
            <Select
              value={product.introOfferDetails.firstPaymentInterval}
              onChange={(e) => {
                setProduct({
                  ...product,
                  introOfferDetails: {
                    ...product.introOfferDetails,
                    firstPaymentInterval: e.target.value,
                  },
                });
              }}
              margin="dense"
            >
              {subscriptionIntervals.map((value) => {
                return <MenuItem key={`interval-${value}`} value={value}>{value}</MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ marginBottom: 4, width: 125 }}
            variant="outlined"
          >
            <Select
              value={product.introOfferDetails.firstPaymentPeriod}
              onChange={(e) => {
                setProduct({
                  ...product,
                  introOfferDetails: {
                    ...product.introOfferDetails,
                    firstPaymentPeriod: e.target.value,
                  },
                });
              }}
              margin="dense"
            >
              {['day', 'week', 'month', 'year'].map((value) => {
                return <MenuItem key={value} value={value}><span style={{ textTransform: 'capitalize' }}>{value}{product.introOfferDetails.firstPaymentInterval > 1 ? 's' : ''}</span></MenuItem>
              })}
            </Select>
          </FormControl>

          <TextField
            label="Up Front Payment Amount"
            style={{ marginTop: 12, marginBottom: 4 }}
            value={product.introOfferDetails.amount}
            onChange={(e) => {
              const value = e.target.value;

              setProduct({
                ...product,
                introOfferDetails: {
                  ...product.introOfferDetails,
                  amount: value,
                },
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </>
      );
    }

    if (value === 'discounted-recurring-payment') {
      return (
        <>
          <FormControl
            style={{ marginTop: 8, marginBottom: 4 }}
            variant="outlined"
            className="day-text-field"
          >
            <InputLabel>Number of Periods</InputLabel>
            <Select
              value={product.introOfferDetails.discountNumberOfPeriods}
              onChange={(e) => {
                setProduct({
                  ...product,
                  introOfferDetails: {
                    ...product.introOfferDetails,
                    discountNumberOfPeriods: e.target.value,
                  },
                });
              }}
              margin="dense"
              label="Number of Periods"
            >
              {subscriptionIntervals.map((value) => {
                return <MenuItem key={`interval-${value}`} value={value}>{value}</MenuItem>
              })}
            </Select>
          </FormControl>

          <TextField
            label="Discounted Amount"
            style={{ marginTop: 8, marginBottom: 4 }}
            value={product.introOfferDetails.amount}
            onChange={(e) => {
              const value = e.target.value;

              setProduct({
                ...product,
                introOfferDetails: {
                  ...product.introOfferDetails,
                  amount: value,
                },
              });
            }}
            margin="dense"
            variant="outlined"
            type="number"
            className="day-text-field"
          />
        </>
      );
    }
  };

  return (
    <div className="EditProduct">
      <div className="top-buttons-container">
        <Link to="/shop/products">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: '10px', marginBottom: '15px' }}
            startIcon={<ArrowBackIcon />}
          >
            All Products
          </Button>
        </Link>

        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginBottom: '15px' }}
          onClick={() => {
            setPageBuilderOpen(true);
          }}
        >
          Open Page Builder
        </Button>
      </div>

      <div>
        {renderLoading()}
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid' }}>
          <Typography variant="h6">
            {product.name || 'Unnamed'}
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={saveProduct}
            disabled={!hasUniqueURL() || !hasUniqueSKU() || !product.path || !product.sku}
          >
            Save
          </Button>
        </Toolbar>

        <div className="content-container">
          <div className="memberships-container">
            <strong>Product Membership IDs:</strong> {!product.memberships.length ? 'None' : product.memberships.join(', ')}
          </div>
          {!product.isCustomizableBundle ? null :
            <div className="memberships-container">
              <strong>Note:</strong> This product is a customizable bundle
            </div>
          }
          {!product.isSubscriptionBox ? null :
            <div className="memberships-container">
              <strong>Note:</strong> This product is a subscription box
            </div>
          }

          <div className="toggle-row">
            <FormControlLabel
              control={
                <Switch
                  checked={product.active}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      active: e.target.checked,
                    });
                  }}
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />

            {(product.isCustomizableBundle || product.isSubscriptionBox) ? null :
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={product.isDigital}
                      onChange={(e) => {
                        const updatedProduct = {
                          ...product,
                          isDigital: e.target.checked
                        }
                        if (product.isGiftCard) {
                          updatedProduct.isGiftCard = e.target.checked;
                        }
                        setProduct(updatedProduct);
                      }}
                      name="digital"
                      color="primary"
                    />
                  }
                  label="Digital"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={product.isGiftCard || false}
                      onChange={(e) => {
                        const updatedProduct = {
                          ...product,
                          isGiftCard: e.target.checked
                        };
                        if (!product.isDigital) {
                          updatedProduct.isDigital = e.target.checked;
                        }
                        setProduct(updatedProduct);
                      }}
                      name="giftCard"
                      color="primary"
                    />
                  }
                  label="Gift Card"
                />
              </>
            }
          </div>

          <div className="row">
            <TextField
              label="Product Name"
              value={product.name}
              onChange={(e) => { handleChange(e, 'name') }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field product-name-field"
            />

            <div className="path-container">
              <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4, width: '100%' }}>
                <InputLabel>URL Path</InputLabel>
                <OutlinedInput
                  value={product.path}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                    setProduct({
                      ...product,
                      path: value,
                    });
                  }}
                  margin="dense"
                  label="URL Path"
                  variant="outlined"
                  type="text"
                  error={!hasUniqueURL()}
                  startAdornment={<InputAdornment position="start">{product.isGiftCard ? 'gift-card' : 'product'}/</InputAdornment>}
                />
                <FormHelperText><small>URL must be unique</small></FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="row" style={{ marginTop: 15 }}>
            <TextField
              label="SKU"
              value={product.sku}
              onChange={(e) => { handleChange(e, 'sku') }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{ width: '49%' }}
              error={!hasUniqueSKU()}
              helperText="This field must be unique"
            />

            {product.isDigital ? null : <FormControl margin="dense" variant="outlined" style={{ width: '49%' }}>
              <InputLabel id="shipping-class-select">Shipping Class</InputLabel>
              <Select
                labelId="shipping-class-select"
                value={product.shippingClass}
                onChange={(e) => { handleChange(e, 'shippingClass') }}
                label="Shipping Class"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {shippingClasses.map((shippingClass, i) => {
                  return <MenuItem key={`shipping-class-${i}`} value={shippingClass.id}>{shippingClass.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText><small>Ignored for digital products</small></FormHelperText>
            </FormControl>}
          </div>

          {!product.isSubscriptionBox ? null :
            <div className="row" style={{ marginTop: 15 }}>
              <FormControl margin="dense" variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="shipping-class-select">Subscription Box</InputLabel>
                <Select
                  labelId="shipping-class-select"
                  value={product.selectedSubscriptionBox || ''}
                  onChange={(e) => { handleChange(e, 'selectedSubscriptionBox') }}
                  label="Subscription Box"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {subscriptionBoxes.map((subscriptionBox, i) => {
                    return <MenuItem key={`subscription-box-${i}`} value={subscriptionBox.id}>{subscriptionBox.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          }

          <div className="row">
            <div className="media-container">
              <div className="media-label">Background Color in Media Section</div>
              <div
                className="color-container"
                onClick={(e) => {
                  setBackgroundColorAnchorElem(e.currentTarget)
                }
                }
              >
                <p>Background Media Section</p>

                <div
                  className="color-preview"
                  style={{ backgroundColor: product.backgroundColorMediaSection }}
                ></div>
              </div>
              <Popover
                id={backgroundColorId}
                open={backgroundColorOpen}
                anchorEl={backgroundColorAnchorElem}
                onClose={() => {
                  setBackgroundColorAnchorElem(null)
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ColorPicker
                  width={250}
                  height={125}
                  color={backgroundColor}
                  onChange={setBackgroundColor}
                  onChangeComplete={(color) => {
                    changeBackgroundColor(color.hex)
                  }}
                  hideHSV
                  hideRGB
                  alpha
                />
              </Popover>
            </div>
          </div>

          <div className="row">
            <div className="media-container">
              <div className="media-label">Media (files are automatically saved)</div>

              <div className="row-reverse">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={() => { setShowAddVideoFromUrl(true) }}
                >
                  Add Video from URL
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => { setShowFileUploadModal(true) }}
                >
                  Add Files
                </Button>
              </div>

              {!product.media.length ? null : <div className="images-container">
                <div className="main-image-container">
                  <div className="image-heading">Main image</div>
                  {!product.media[0] ? null : (product.media[0].contentType.includes('image') || product.media[0].contentType.includes('video')) && <img src={product.media[0].contentType.includes('video') ? product.media[0].placeholder : product.media[0].url} />}
                </div>
                <div className="reorder-container">
                  <Card>
                    {product.media.length > 1 && <div className="image-heading">Drag and drop to reorder</div>}
                    <List>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {product.media.map((file, i) => {
                                return (
                                  <Draggable key={`file-${i}`} draggableId={`file-${i}`} index={i}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="file-container">
                                          {product.media.length > 1 && <Divider />}
                                          <ListItem>
                                            <div className="file-container-inner">
                                              <div className="file-image">
                                                <div className="image">
                                                  <img src={(file.contentType.includes('image') || file.contentType.includes('video')) && (file.contentType.includes('video') ? file.placeholder : file.url)} />
                                                </div>
                                              </div>
                                              <div className="file-details">
                                                <div className="file-title">{file.name || 'Unnamed'}</div>
                                                <p><strong>Created on:</strong> {moment(file.timeCreated).format('MMMM Do YYYY, h:mm a')}</p>
                                                <p><strong>Type:</strong> {file.contentType.includes('image') ? 'Image' : 'Video'}</p>

                                                <div className="edit-button-container">
                                                  <IconButton
                                                    color="inherit"
                                                    onClick={() => {
                                                      setEditFile(file);
                                                      setEditFileOpen(true);
                                                      setEditFileIndex(i);
                                                    }}
                                                  >
                                                    <EditIcon />
                                                  </IconButton>
                                                </div>
                                              </div>
                                            </div>
                                          </ListItem>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </List>
                  </Card>
                </div>
              </div>}
            </div>
          </div>

          {!product.isCustomizableBundle ? null :
            <div className="row">
              <div className="section-container">
                <div className="section-label">Bundled Product Selection</div>

                <div className="right-container">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      const bundledProducts = product.bundledProducts || [];
                      const bundledProductsCopy = [
                        ...bundledProducts,
                        {
                          dropdownTitle: `Product ${bundledProducts.length + 1}`,
                          selectableProducts: [],
                        },
                      ];

                      setProduct({
                        ...product,
                        bundledProducts: bundledProductsCopy,
                      });
                    }}
                    color="primary"
                  >
                    Add Product Selection
                  </Button>
                </div>

                {(product.bundledProducts || []).map((p, i) => {
                  return (
                    <Box
                      key={`bundled-product-option-${i}`}
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Box sx={{ flex: 1 }}>

                        <div className="row">
                          <div className="section-container">
                            <div className="section-label">Bundled Product {i + 1}</div>

                            <TextField
                              label="Dropdown Title"
                              value={p.dropdownTitle}
                              onChange={(e) => {
                                const bundledProducts = [...product.bundledProducts];

                                bundledProducts[i].dropdownTitle = e.target.value;

                                setProduct({
                                  ...product,
                                  bundledProducts,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              type="text"
                              className="day-text-field"
                              style={{ marginTop: 20, marginBottom: 10 }}
                            />

                            <FormControl style={{ marginTop: 10, marginBottom: 10, width: '100%' }}>
                              <InputLabel htmlFor="select-multiple-bundle-product-chip">Products</InputLabel>
                              <Select
                                multiple
                                value={p.selectableProducts}
                                onChange={(e) => {
                                  const bundledProducts = [...product.bundledProducts];
                                  bundledProducts[i].selectableProducts = e.target.value;

                                  setProduct({
                                    ...product,
                                    bundledProducts,
                                  });
                                }}
                                input={<Input id="select-multiple-bundle-product-chip" />}
                                renderValue={selected => (
                                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map(value => (
                                      <DeletableChip
                                        key={value.id}
                                        label={value.name}
                                        style={{ margin: 2 }}
                                        color="primary"
                                        onDelete={() => {
                                          const index = p.selectableProducts.indexOf(value);

                                          if (index === -1) {
                                            return;
                                          }

                                          const productsCopy = p.selectableProducts.slice();
                                          productsCopy.splice(index, 1);

                                          const bundledProducts = [...product.bundledProducts];
                                          bundledProducts[i].selectableProducts = productsCopy;

                                          setProduct({
                                            ...product,
                                            bundledProducts,
                                          });
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                              >
                                {simplifiedProducts.filter(product => {
                                  const found = p.selectableProducts.find(p => p.id === product.id);
                                  return !found && !product.isDigital && !product.isGiftCard;
                                }).sort((a, b) => {
                                  if (a.name < b.name) { return -1; }
                                  if (a.name > b.name) { return 1; }
                                  return 0;
                                }).map(product => (
                                  <MenuItem key={product.id} value={product}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </Box>

                      <Box sx={{ marginLeft: 15, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                        <IconButton onClick={() => {
                          const bundledProducts = [...product.bundledProducts];

                          bundledProducts.splice(i, 1);

                          setProduct({
                            ...product,
                            bundledProducts,
                          });
                        }} edge="start" color="inherit" aria-label="Edit">
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </div>
            </div>
          }

          {product.isGiftCard ?
            null :
            <div>
              <div className="categories-container">
                <FormControl style={{ marginTop: -22, width: '99%' }}>
                  <InputLabel id="shipping-class-select">Group</InputLabel>
                  <Select
                    labelId="shipping-class-select"
                    value={product.group}
                    onChange={(e) => { handleChange(e, 'group') }}
                    label="Group"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {groups.map((group, i) => {
                      return <MenuItem key={`group-${i}`} value={group}>{group}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <div className="add-category-button" onClick={() => {
                  setShowAddGroup(true);
                  setNewGroupName('');
                }}><small>+ Add new group</small></div>

                <TextField
                  label="Grouped Thumbnail URL (optional)"
                  value={product.groupedThumbnail}
                  onChange={(e) => { handleChange(e, 'groupedThumbnail') }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  helperText="Will default to main image url if not provided."
                  style={{ marginTop: 20 }}
                />
              </div>
            </div>
          }

          <div>
            <div className="categories-container">
              <FormControl style={{ marginTop: -22, width: '99%' }}>
                <InputLabel htmlFor="select-multiple-chip">Categories</InputLabel>
                <Select
                  multiple
                  value={product.categories}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      categories: e.target.value,
                    });
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                          color="primary"
                          onDelete={() => {
                            const index = product.categories.indexOf(value);

                            if (index === -1) {
                              return;
                            }

                            const categoriesCopy = product.categories.slice();
                            categoriesCopy.splice(index, 1);
                            setProduct({
                              ...product,
                              categories: categoriesCopy,
                            });
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {categories.filter(category => {
                    return !product.categories.includes(category);
                  }).map(category => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="add-category-button" onClick={() => {
                setShowAddCategory(true);
                setNewCategoryName('');
              }}><small>+ Add new category</small></div>
            </div>
          </div>

          <div>
            <div className="categories-container">
              <FormControl style={{ marginTop: -22, width: '99%' }}>
                <InputLabel htmlFor="select-multiple-chip">Tags</InputLabel>
                <Select
                  multiple
                  value={product.tags}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      tags: e.target.value,
                    });
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map(value => (
                        <DeletableChip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                          color="primary"
                          onDelete={() => {
                            const index = product.tags.indexOf(value);

                            if (index === -1) {
                              return;
                            }

                            const tagsCopy = product.tags.slice();
                            tagsCopy.splice(index, 1);
                            setProduct({
                              ...product,
                              tags: tagsCopy,
                            });
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {tags.filter(tag => {
                    return !product.tags.includes(tag);
                  }).map(tag => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="add-category-button" onClick={() => {
                setShowAddTag(true);
                setNewTagName('');
              }}><small>+ Add new tag</small></div>
            </div>
          </div>

          {product.isGiftCard ?
            null :
            <div>
              <div className="categories-container">
                <FormControl style={{ marginTop: -22, width: '99%' }}>
                  <InputLabel htmlFor="select-multiple-chip">Related Products</InputLabel>
                  <Select
                    multiple
                    value={product.relatedProducts}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        relatedProducts: e.target.value,
                      });
                    }}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map(value => (
                          <DeletableChip
                            key={value.id}
                            label={value.name}
                            style={{ margin: 2 }}
                            color="primary"
                            onDelete={() => {
                              const index = product.relatedProducts.indexOf(value);

                              if (index === -1) {
                                return;
                              }

                              const relatedProductsCopy = product.relatedProducts.slice();
                              relatedProductsCopy.splice(index, 1);
                              setProduct({
                                ...product,
                                relatedProducts: relatedProductsCopy,
                              });
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {simplifiedProducts.filter(simplifiedProduct => {
                      const found = product.relatedProducts.find(p => p.id === simplifiedProduct.id);
                      return !found;
                    }).sort((a, b) => {
                      if (a.name < b.name) { return -1; }
                      if (a.name > b.name) { return 1; }
                      return 0;
                    }).map(simplifiedProduct => (
                      <MenuItem key={simplifiedProduct.id} value={simplifiedProduct}>
                        {simplifiedProduct.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          }

          <div className="row">
            <div className="section-container">
              <div className="section-label">Review Settings</div>

              <div>
                <FormControlLabel
                  style={{ marginTop: 8, marginBottom: 4 }}
                  control={
                    <Switch
                      checked={product.aggregateReviews || false}
                      onChange={(e) => {
                        const updatedProduct = {
                          ...product,
                          aggregateReviews: e.target.checked,
                        };

                        if (!e.target.checked) {
                          updatedProduct.aggregateReviewsByGroup = false;
                        }

                        setProduct(updatedProduct);
                      }}
                      name="aggregateReviews"
                      color="primary"
                    />
                  }
                  label="Aggregate Reviews"
                />

                {!product.aggregateReviews ? null :
                  <FormControlLabel
                    style={{ marginTop: 8, marginBottom: 4 }}
                    control={
                      <Switch
                        checked={product.aggregateReviewsByGroup || false}
                        onChange={(e) => {
                          const updatedProduct = {
                            ...product,
                            aggregateReviewsByGroup: e.target.checked,
                          };

                          setProduct(updatedProduct);
                        }}
                        name="aggregateReviewsByGroup"
                        color="primary"
                      />
                    }
                    label="Aggregate by Group"
                  />
                }
              </div>

              {(!product.aggregateReviews || product.aggregateReviewsByGroup) ? null :
                <FormControl style={{ width: '99%' }}>
                  <InputLabel htmlFor="select-multiple-chip">Grouped Products (max 9)</InputLabel>
                  <Select
                    multiple
                    value={product.relatedProductReviews || []}
                    onChange={(e) => {
                      if (product.relatedProductReviews && product.relatedProductReviews.length >= 9) {
                        return;
                      }

                      setProduct({
                        ...product,
                        relatedProductReviews: e.target.value,
                      });
                    }}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map(value => (
                          <DeletableChip
                            key={value.id}
                            label={value.name}
                            style={{ margin: 2 }}
                            color="primary"
                            onDelete={() => {
                              const relatedProductReviews = product.relatedProductReviews || [];
                              const index = (relatedProductReviews || []).indexOf(value);

                              if (index === -1) {
                                return;
                              }

                              const relatedProductsReviewsCopy = (relatedProductReviews || []).slice();
                              relatedProductsReviewsCopy.splice(index, 1);
                              setProduct({
                                ...product,
                                relatedProductReviews: relatedProductsReviewsCopy,
                              });
                            }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {simplifiedProducts.filter(simplifiedProduct => {
                      const found = (product.relatedProductReviews || []).find(p => p.id === simplifiedProduct.id);
                      return !found && simplifiedProduct.id !== product.id;
                    }).sort((a, b) => {
                      if (a.name < b.name) { return -1; }
                      if (a.name > b.name) { return 1; }
                      return 0;
                    }).map(simplifiedProduct => (
                      <MenuItem key={simplifiedProduct.id} value={{ id: simplifiedProduct.id, name: simplifiedProduct.name }}>
                        {simplifiedProduct.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Shop Filters</div>
              <div className="add-category-button" onClick={() => {
                setShowAddFilterCategory(true);
                setNewFilterCategory('');
              }}><small>+ Add new filter category</small></div>

              {shopFilters.map((f, i) => {
                return (
                  <div key={f.id} style={{ marginTop: 35 }}>
                    <FormControl style={{ marginTop: -22, width: '99%' }}>
                      <InputLabel htmlFor="select-multiple-chip">{f.name}</InputLabel>
                      <Select
                        multiple
                        value={product.filters.find(fil => fil.id === f.id) ? (product.filters.find(fil => fil.id === f.id).selected) : []}
                        onChange={(e) => {
                          const filtersCopy = [...product.filters];
                          const foundFilterIndex = product.filters.findIndex(fil => fil.id === f.id);

                          if (foundFilterIndex === -1) {
                            filtersCopy.push({
                              name: f.name,
                              id: f.id,
                              selected: e.target.value,
                            });
                          } else {
                            filtersCopy[foundFilterIndex] = {
                              ...filtersCopy[foundFilterIndex],
                              selected: e.target.value,
                            };
                          }

                          setProduct({
                            ...product,
                            filters: filtersCopy,
                          });
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selected.map(value => (
                              <DeletableChip
                                key={value}
                                label={value}
                                style={{ margin: 2 }}
                                color="primary"
                                onDelete={() => {
                                  const filtersCopy = [...product.filters];
                                  const foundFilterIndex = product.filters.findIndex(fil => fil.id === f.id);
                                  const index = filtersCopy[foundFilterIndex].selected.indexOf(value);

                                  if (index === -1) {
                                    return;
                                  }

                                  const selectedCopy = filtersCopy[foundFilterIndex].selected.slice();
                                  selectedCopy.splice(index, 1);

                                  filtersCopy[foundFilterIndex] = {
                                    ...filtersCopy[foundFilterIndex],
                                    selected: selectedCopy,
                                  };

                                  setProduct({
                                    ...product,
                                    filters: filtersCopy,
                                  });
                                }}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {f.options.filter(option => {
                          const foundFilterIndex = product.filters.findIndex(fil => fil.id === f.id);

                          if (foundFilterIndex === -1) {
                            return true;
                          }

                          return !product.filters[foundFilterIndex].selected.includes(option);
                        }).map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div style={{ display: 'flex' }}>
                      <div className="add-category-button" style={{ marginRight: 30 }} onClick={() => {
                        setShowAddFilterCategoryTypeIndex(i);
                        setNewFilterCategoryType('');
                      }}><small>+ Add new {f.name}</small></div>

                      <div className="add-category-button" onClick={() => {
                        setShowEditFilterCategoryNameIndex(i);
                        setEditFilterCategoryName(f.name);
                      }}><small>Update category name</small></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {(product.isGiftCard || product.isSubscriptionBox) ?
            null :
            <div className="row">
              <div className="section-container">
                <div className="section-label">Pricing/Stock/Tax/Shipping</div>

                {!product.isCustomizableBundle ? null :
                  <div className="pricing-row">
                    <FormControlLabel
                      style={{ marginBottom: 4 }}
                      control={
                        <Switch
                          checked={product.useCustomizableBundleProductsPricing || false}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              useCustomizableBundleProductsPricing: e.target.checked,
                            });
                          }}
                          name="useCustomizableBundleProductsPricing"
                          color="primary"
                        />
                      }
                      label="Use Bundled Product Prices"
                    />
                  </div>
                }

                {product.useCustomizableBundleProductsPricing ?
                  <div className="pricing-row">
                    <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4 }}>
                      <InputLabel>Discount</InputLabel>
                      <OutlinedInput
                        value={product.customizableBundleProductsDiscountPercentage || ''}
                        onChange={(e) => { handleChange(e, 'customizableBundleProductsDiscountPercentage') }}
                        margin="dense"
                        label="Discount"
                        variant="outlined"
                        type="number"
                        endAdornment={<InputAdornment position="start">%</InputAdornment>}
                        style={{ maxWidth: 180, marginRight: 15 }}
                      />
                    </FormControl>
                  </div> :
                  <>
                    <div className="pricing-row">
                      <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                          value={product.price}
                          onChange={(e) => { handleChange(e, 'price') }}
                          margin="dense"
                          label="Price"
                          variant="outlined"
                          type="number"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          style={{ maxWidth: 180, marginRight: 15 }}
                        />
                      </FormControl>

                      {product.isCustomizableBundle ? null :
                        <>
                          <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4 }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Cost of Goods</InputLabel>
                            <OutlinedInput
                              value={product.costOfGood}
                              onChange={(e) => { handleChange(e, 'costOfGood') }}
                              margin="dense"
                              label="Cost of Goods"
                              variant="outlined"
                              type="number"
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              style={{ maxWidth: 180, marginRight: 15 }}
                            />
                          </FormControl>

                          <TextField
                            label="Stock Quantity"
                            disabled={!product.isDigital}
                            value={product.stockQuantity}
                            onChange={(e) => { handleChange(e, 'stockQuantity') }}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            style={{ maxWidth: 180, marginRight: 15 }}
                          />

                          <TextField
                            label="Low Stock Threshold"
                            disabled={!product.isDigital}
                            value={product.lowStockThreshold}
                            onChange={(e) => { handleChange(e, 'lowStockThreshold') }}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            style={{ maxWidth: 180, marginRight: 15 }}
                          />

                          <TextField
                            label="Weight (oz)"
                            value={product.weight}
                            onChange={(e) => { handleChange(e, 'weight') }}
                            margin="dense"
                            variant="outlined"
                            type="number"
                            style={{ maxWidth: 180, marginRight: 15 }}
                          />

                          <Typography><small>Note: Manage Stock Quantity and Low Stock Threshold in Ordoro</small></Typography>
                        </>
                      }
                    </div>

                    <div className="pricing-row">
                      <FormControlLabel
                        style={{ marginTop: 8, marginBottom: 4 }}
                        control={
                          <Switch
                            checked={product.isOnSale}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                isOnSale: e.target.checked,
                              });
                            }}
                            name="on sale"
                            color="primary"
                          />
                        }
                        label="Is On Sale"
                      />

                      {!product.isOnSale ? null : <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Sale Price</InputLabel>
                        <OutlinedInput
                          value={product.salePrice}
                          onChange={(e) => { handleChange(e, 'salePrice') }}
                          margin="dense"
                          label="Sale Price"
                          variant="outlined"
                          type="number"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          style={{ maxWidth: 180, marginRight: 15 }}
                        />
                      </FormControl>}
                    </div>
                  </>
                }

                <div className="pricing-row">
                  <TextField
                    label="Product Tax Code"
                    value={product.taxCode}
                    onChange={(e) => { handleChange(e, 'taxCode') }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    style={{ maxWidth: 180, marginRight: 15 }}
                  />
                </div>
              </div>
            </div>
          }

          <div className="row">
            <div className="section-container">
              <div className="section-label">Purchase Limits</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.newCustomersOnly || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        newCustomersOnly: e.target.checked,
                      });
                    }}
                    name="newCustomersOnly"
                    color="primary"
                  />
                }
                label="New Customer Only"
              />

              {product.newCustomersOnly ? null :
                <FormControlLabel
                  style={{ marginTop: 8, marginBottom: 4 }}
                  control={
                    <Switch
                      checked={product.preventRepeatPurchases || false}
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          preventRepeatPurchases: e.target.checked,
                        });
                      }}
                      name="preventRepeatPurchases"
                      color="primary"
                    />
                  }
                  label="Prevent Repeat Purchases"
                />
              }

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.limitOnePerOrder || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        limitOnePerOrder: e.target.checked,
                      });
                    }}
                    name="limitOnePerOrder"
                    color="primary"
                  />
                }
                label="Limit One Per Order"
              />
            </div>
          </div>

          {(product.isGiftCard || product.isCustomizableBundle || product.isSubscriptionBox) ? null :
            <>
              <div className="row">
                <div className="section-container">
                  <div className="section-label">Volume Discount Tiers</div>

                  <div className="row-center">
                    <FormControlLabel
                      style={{ marginTop: 8, marginBottom: 4 }}
                      control={
                        <Switch
                          checked={product.enableVolumeDiscountTiers || false}
                          onChange={(e) => {
                            const volumeDiscountTiers = [...(product.volumeDiscountTiers || [])];
                            setProduct({
                              ...product,
                              enableVolumeDiscountTiers: e.target.checked,
                              volumeDiscountTiers,
                            });
                          }}
                          name="enableVolumeDiscountTiers"
                          color="primary"
                        />
                      }
                      label="Enable"
                    />

                    {!product.enableVolumeDiscountTiers ? null :
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            const volumeDiscountTiers = [...(product.volumeDiscountTiers || [])];

                            volumeDiscountTiers.push({
                              quantity: '2',
                              discountPercentage: '5',
                            });

                            setProduct({
                              ...product,
                              volumeDiscountTiers,
                            });
                          }}
                          color="primary"
                        >
                          Add Tier
                        </Button>
                      </div>
                    }
                  </div>

                  {!product.enableVolumeDiscountTiers ? null :
                    <div>
                      {(product.volumeDiscountTiers || []).map((tier, i) => {
                        return (
                          <div key={`volume-discount-tier-${i}`} className="row-center" style={{ marginTop: 10 }}>
                            <TextField
                              label="Min. Purchase Quantity"
                              value={tier.quantity || ''}
                              onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                const volumeDiscountTiers = [...(product.volumeDiscountTiers || [])];

                                volumeDiscountTiers[i] = {
                                  ...volumeDiscountTiers[i],
                                  quantity: value,
                                };

                                setProduct({
                                  ...product,
                                  volumeDiscountTiers,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              type="text"
                              style={{ marginRight: 10, flex: 1 }}
                            />

                            <FormControl variant="outlined" style={{ marginRight: 10, flex: 1, marginTop: 4 }}>
                              <InputLabel>Discount %</InputLabel>
                              <OutlinedInput
                                value={tier.discountPercentage || ''}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/[^0-9]/g, '');
                                  const volumeDiscountTiers = [...(product.volumeDiscountTiers || [])];

                                  volumeDiscountTiers[i] = {
                                    ...volumeDiscountTiers[i],
                                    discountPercentage: value,
                                  };

                                  setProduct({
                                    ...product,
                                    volumeDiscountTiers,
                                  });
                                }}
                                margin="dense"
                                label="Discount %"
                                variant="outlined"
                                type="text"
                                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                style={{ width: '100%' }}
                              />
                            </FormControl>

                            <IconButton
                              color="inherit"
                              onClick={() => {
                                const volumeDiscountTiers = [...(product.volumeDiscountTiers || [])];

                                volumeDiscountTiers.splice(i, 1);

                                setProduct({
                                  ...product,
                                  volumeDiscountTiers,
                                });
                              }}
                              aria-label="Delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>

              <div className="row">
                <div className="section-container">
                  <div className="section-label">Buy X Get X Discounts</div>

                  <div className="row-center">
                    <FormControlLabel
                      style={{ marginTop: 8, marginBottom: 4 }}
                      control={
                        <Switch
                          checked={product.enableBuyXGetXDiscounts || false}
                          onChange={(e) => {
                            const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];
                            setProduct({
                              ...product,
                              enableBuyXGetXDiscounts: e.target.checked,
                              buyXGetXDiscounts,
                            });
                          }}
                          name="enableBuyXGetXDiscounts"
                          color="primary"
                        />
                      }
                      label="Enable"
                    />

                    {!product.enableBuyXGetXDiscounts ? null :
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];

                            buyXGetXDiscounts.push({
                              quantity: '1',
                              discountedQuantity: '1',
                              discountPercentage: '100',
                            });

                            setProduct({
                              ...product,
                              buyXGetXDiscounts,
                            });
                          }}
                          color="primary"
                        >
                          Add Tier
                        </Button>
                      </div>
                    }
                  </div>

                  {!product.enableBuyXGetXDiscounts ? null :
                    <div>
                      {(product.buyXGetXDiscounts || []).map((tier, i) => {
                        return (
                          <div key={`volume-discount-tier-${i}`} className="row-center" style={{ marginTop: 10 }}>
                            <TextField
                              label="Min. Purchase Quantity"
                              value={tier.quantity || ''}
                              onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];

                                buyXGetXDiscounts[i] = {
                                  ...buyXGetXDiscounts[i],
                                  quantity: value,
                                };

                                setProduct({
                                  ...product,
                                  buyXGetXDiscounts,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              type="text"
                              style={{ marginRight: 10, flex: 1 }}
                            />

                            <TextField
                              label="Discounted Quantity"
                              value={tier.discountedQuantity || ''}
                              onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];

                                buyXGetXDiscounts[i] = {
                                  ...buyXGetXDiscounts[i],
                                  discountedQuantity: value,
                                };

                                setProduct({
                                  ...product,
                                  buyXGetXDiscounts,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              type="text"
                              style={{ marginRight: 10, flex: 1 }}
                            />

                            <FormControl variant="outlined" style={{ marginRight: 10, flex: 1, marginTop: 4 }}>
                              <InputLabel>Discount %</InputLabel>
                              <OutlinedInput
                                value={tier.discountPercentage || ''}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/[^0-9]/g, '');
                                  const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];

                                  buyXGetXDiscounts[i] = {
                                    ...buyXGetXDiscounts[i],
                                    discountPercentage: value,
                                  };

                                  setProduct({
                                    ...product,
                                    buyXGetXDiscounts,
                                  });
                                }}
                                margin="dense"
                                label="Discount %"
                                variant="outlined"
                                type="text"
                                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                style={{ width: '100%' }}
                              />
                            </FormControl>

                            <IconButton
                              color="inherit"
                              onClick={() => {
                                const buyXGetXDiscounts = [...(product.buyXGetXDiscounts || [])];

                                buyXGetXDiscounts.splice(i, 1);

                                setProduct({
                                  ...product,
                                  buyXGetXDiscounts,
                                });
                              }}
                              aria-label="Delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
            </>
          }

          {(product.isGiftCard || product.isSubscriptionBox) ?
            null :
            <div className="row">
              <div className="section-container">
                <div className="section-label">Subscriptions</div>

                <FormControlLabel
                  style={{ marginTop: 8, marginBottom: 4 }}
                  control={
                    <Switch
                      checked={product.enableSubscriptions}
                      onChange={(e) => {
                        const updated = {
                          ...product,
                          enableSubscriptions: e.target.checked,
                        };

                        if (!e.target.checked) {
                          updated.subscriptionOnly = false;
                        }

                        setProduct(updated);
                      }}
                      name="enableSubscriptions"
                      color="primary"
                    />
                  }
                  label="Enable Subscriptions"
                />

                {!product.enableSubscriptions ? null : <div>
                  <FormControlLabel
                    style={{ marginTop: 8, marginBottom: 4 }}
                    control={
                      <Switch
                        checked={product.subscriptionOnly || false}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            subscriptionOnly: e.target.checked,
                          });
                        }}
                        name="subscriptionOnly"
                        color="primary"
                      />
                    }
                    label="Subscription Only"
                  />

                  {product.isCustomizableBundle ? null :
                    <>
                      <div>
                        <FormControlLabel
                          style={{ marginTop: 8, marginBottom: 4 }}
                          control={
                            <Switch
                              checked={product.subscribesToDifferentProduct || false}
                              onChange={(e) => {
                                const updatedProduct = {
                                  ...product,
                                  subscribesToDifferentProduct: e.target.checked,
                                  selectedSubscriptionProduct: null,
                                };

                                if (e.target.checked) {
                                  updatedProduct.selectedSubscriptionProduct = simplifiedProducts[0];
                                }

                                setProduct(updatedProduct);
                              }}
                              name="subscribesToDifferentProduct"
                              color="primary"
                            />
                          }
                          label="Subscribes to a Different Product"
                        />

                        {!product.subscribesToDifferentProduct ? null :
                          <div>
                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                              <InputLabel>
                                Subscription Product
                              </InputLabel>
                              <Select
                                value={product.selectedSubscriptionProduct || ''}
                                onChange={(e) => {
                                  setProduct({
                                    ...product,
                                    selectedSubscriptionProduct: e.target.value,
                                  });
                                }}
                                margin="dense"
                                label="Subscription Product"
                              >
                                {simplifiedProducts.map((product, i) => {
                                  return <MenuItem key={`subscription-product-${i}`} value={product}>{product.name}</MenuItem>
                                })}
                              </Select>
                            </FormControl>

                            <div className="pricing-row" style={{ marginTop: 0, marginBottom: 10 }}>
                              <FormControlLabel
                                style={{ marginTop: 8, marginBottom: 4 }}
                                control={
                                  <Switch
                                    checked={product.hasCustomFirstOrderShipping || false}
                                    onChange={(e) => {
                                      setProduct({
                                        ...product,
                                        hasCustomFirstOrderShipping: e.target.checked,
                                      });
                                    }}
                                    name="hasCustomFirstOrderShipping"
                                    color="primary"
                                  />
                                }
                                label="Custom First Order Shipping Enabled"
                              />

                              {!product.hasCustomFirstOrderShipping ? null :
                                <FormControl variant="outlined" style={{ marginTop: 8, marginBottom: 4 }}>
                                  <InputLabel htmlFor="outlined-adornment-amount">First Order Shipping</InputLabel>
                                  <OutlinedInput
                                    value={product.customFirstOrderShipping || ''}
                                    onChange={(e) => { handleChange(e, 'customFirstOrderShipping') }}
                                    margin="dense"
                                    label="First Order Shipping"
                                    variant="outlined"
                                    type="number"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    style={{ maxWidth: 180, marginRight: 15 }}
                                  />
                                </FormControl>
                              }
                            </div>
                          </div>
                        }
                      </div>

                      {product.subscribesToDifferentProduct ? null :
                        <div className="row">
                          <div className="section-container">
                            <div className="section-label">Intro Offer</div>
                            <FormControlLabel
                              style={{ marginTop: 8, marginBottom: 4 }}
                              control={
                                <Switch
                                  checked={product.introOfferEnabled || false}
                                  onChange={(e) => {
                                    const updatedProduct = {
                                      ...product,
                                      introOfferEnabled: e.target.checked,
                                      introOfferType: 'free-trial',
                                      introOfferDetails: {},
                                    };

                                    if (e.target.checked) {
                                      updatedProduct.introOfferDetails = {
                                        firstPaymentInterval: 1,
                                        firstPaymentPeriod: 'month',
                                      };
                                    }

                                    setProduct(updatedProduct);
                                  }}
                                  name="introOfferEnabled"
                                  color="primary"
                                />
                              }
                              label="Enable intro offer"
                            />

                            {!product.introOfferEnabled ? null :
                              <>
                                <FormControl
                                  style={{ marginTop: 8, marginBottom: 4 }}
                                  variant="outlined"
                                  className="day-text-field"
                                >
                                  <InputLabel>
                                    Offer Type
                                  </InputLabel>
                                  <Select
                                    value={product.introOfferType}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const updatedProduct = {
                                        ...product,
                                        introOfferType: value,
                                        introOfferDetails: {},
                                      };

                                      if (value === 'free-trial') {
                                        updatedProduct.introOfferDetails = {
                                          firstPaymentInterval: 1,
                                          firstPaymentPeriod: 'month',
                                        };
                                      } else if (value === 'single-payment') {
                                        updatedProduct.introOfferDetails = {
                                          firstPaymentInterval: 1,
                                          firstPaymentPeriod: 'month',
                                          amount: product.price || '',
                                        };
                                      } else if (value === 'discounted-recurring-payment') {
                                        updatedProduct.introOfferDetails = {
                                          discountNumberOfPeriods: 1,
                                          amount: product.price ? (+product.price * .9).toFixed(2) : '',
                                        };
                                      }

                                      setProduct(updatedProduct);
                                    }}
                                    margin="dense"
                                    label="Offer Type"
                                  >
                                    <MenuItem value={'free-trial'}>
                                      <div>
                                        <strong>Free Trial</strong> - Subscribers don't pay until the specified period ends
                                      </div>
                                    </MenuItem>
                                    <MenuItem value={'single-payment'}>
                                      <div>
                                        <strong>Single Payment</strong> - Subscribers pay up front for a specified period
                                      </div>
                                    </MenuItem>
                                    <MenuItem value={'discounted-recurring-payment'}>
                                      <div>
                                        <strong>Discounted Recurring Payment</strong> - Subscribers pay a discounted amount for a specified number of periods
                                      </div>
                                    </MenuItem>
                                  </Select>
                                </FormControl>

                                {renderOfferDetails()}
                              </>
                            }
                          </div>
                        </div>
                      }
                    </>
                  }

                  <TableContainer component={Paper}>
                    <Toolbar>
                      <Typography variant="h6" style={{
                        flex: 1,
                      }}>
                        Subscription Options
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => {
                        const subscriptionOptions = [...product.subscriptionOptions];
                        subscriptionOptions.push({
                          interval: 1,
                          period: 'week',
                          discountPercentage: 0,
                          firstOrderDiscountPercentage: 0,
                        });
                        setProduct({
                          ...product,
                          subscriptionOptions,
                        });
                      }}>
                        Add Subscription Option
                      </Button>
                    </Toolbar>
                    <Divider />
                    <Table aria-label="classes table">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Interval</strong></TableCell>
                          <TableCell><strong>Period</strong></TableCell>
                          <TableCell><strong>Discount %</strong></TableCell>
                          <TableCell><strong>First Order Discount %</strong></TableCell>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell padding="checkbox"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {product.subscriptionOptions.map((s, i) => (
                          <TableRow key={`subscription-item-${i}`}>
                            <TableCell>
                              <FormControl variant="outlined">
                                <Select
                                  value={s.interval}
                                  onChange={(e) => { updateSubscriptionItem(e, i, 'interval') }}
                                  margin="dense"
                                >
                                  {subscriptionIntervals.map((value) => {
                                    return <MenuItem key={`interval-${value}`} value={value}>{value}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl variant="outlined">
                                <Select
                                  value={s.period}
                                  onChange={(e) => { updateSubscriptionItem(e, i, 'period') }}
                                  margin="dense"
                                >
                                  {['day', 'week', 'month', 'year'].map((value) => {
                                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <OutlinedInput
                                value={s.discountPercentage}
                                onChange={(e) => { updateSubscriptionItem(e, i, 'discountPercentage') }}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                style={{ maxWidth: 115 }}
                              />
                            </TableCell>
                            <TableCell>
                              <OutlinedInput
                                value={s.firstOrderDiscountPercentage}
                                onChange={(e) => { updateSubscriptionItem(e, i, 'firstOrderDiscountPercentage') }}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                style={{ maxWidth: 115 }}
                              />
                            </TableCell>
                            <TableCell padding="checkbox">
                              {i === 0 ? null : <IconButton color="inherit" onClick={() => {
                                const subscriptionOptions = [...product.subscriptionOptions];

                                const itemToMoveUp = s;
                                const itemToMoveDown = product.subscriptionOptions[i - 1];

                                subscriptionOptions[i] = itemToMoveDown;
                                subscriptionOptions[i - 1] = itemToMoveUp;

                                setProduct({
                                  ...product,
                                  subscriptionOptions,
                                });
                              }} aria-label="Move Up">
                                <ArrowUpIcon />
                              </IconButton>}
                            </TableCell>
                            <TableCell padding="checkbox">
                              {(i === product.subscriptionOptions.length - 1) ? null : <IconButton color="inherit" onClick={() => {
                                const subscriptionOptions = [...product.subscriptionOptions];

                                const itemToMoveDown = s;
                                const itemToMoveUp = product.subscriptionOptions[i + 1];

                                subscriptionOptions[i] = itemToMoveUp;
                                subscriptionOptions[i + 1] = itemToMoveDown;

                                setProduct({
                                  ...product,
                                  subscriptionOptions,
                                });
                              }} aria-label="Move Up">
                                <ArrowDownIcon />
                              </IconButton>}
                            </TableCell>
                            <TableCell padding="checkbox">
                              <IconButton color="inherit" onClick={() => { setSubscriptionDeleteIndex(i) }} aria-label="Delete Shipping Class">
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    {!product.subscriptionOptions.length ? null :
                      <div className="add-to-subscription-row">
                        <strong className="add-to-subscription-label">Add To Subscription Discount (defaults to {defaultSubscriptionDiscountPercentage}%)</strong>

                        <OutlinedInput
                          value={product.addToSubscriptionDiscountPercentage || ''}
                          onChange={(e) => {
                            let value = +e.target.value;

                            if (value > 100) {
                              value = 100;
                            }

                            if (value < 0) {
                              value = 0;
                            }

                            setProduct({
                              ...product,
                              addToSubscriptionDiscountPercentage: value.toString(),
                            });
                          }}
                          margin="dense"
                          variant="outlined"
                          type="number"
                          endAdornment={<InputAdornment position="start">%</InputAdornment>}
                          style={{ maxWidth: 115 }}
                        />
                      </div>
                    }
                  </TableContainer>
                </div>}
              </div>
            </div>
          }

          <div className="row">
            <div className="section-container">
              <div className="section-label">Thank You Page</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.hasCustomThankYouPage}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        hasCustomThankYouPage: e.target.checked,
                      });
                    }}
                    name="hasCustomThankYouPage"
                    color="primary"
                  />
                }
                label="Enable Custom Thank You Page"
              />

              {!product.hasCustomThankYouPage ? null : <div>
                <TextField
                  label="Custom Thank You URL"
                  value={product.customThankYouPagePath}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                    setProduct({
                      ...product,
                      customThankYouPagePath: value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  style={{ marginTop: 20 }}
                />

                <TextField
                  label="Page Priority"
                  value={product.customThankYouPagePriority}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      customThankYouPagePriority: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="number"
                  className="day-text-field"
                  style={{ marginTop: 20 }}
                />
              </div>}
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Custom Call to Action</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.enableCustomCallToAction || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        enableCustomCallToAction: e.target.checked,
                        customCallToActionButtonText: product.customCallToActionButtonText || '',
                        customCallToActionButtonTextColor: product.customCallToActionButtonTextColor || '',
                        customCallToActionButtonColor: product.customCallToActionButtonColor || '',
                        customCallToActionLinkUrl: product.customCallToActionLinkUrl || '',
                      });
                    }}
                    name="enableCustomCallToAction"
                    color="primary"
                  />
                }
                label="Enable Custom Call to Action"
              />

              {!product.enableCustomCallToAction ? null :
                <>
                  <TextField
                    label="Button Text"
                    value={product.customCallToActionButtonText}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        customCallToActionButtonText: e.target.value,
                      });
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"
                    style={{ marginTop: 20 }}
                  />

                  <TextField
                    label="Text Color (use hex, rgb or rgba)"
                    value={product.customCallToActionButtonTextColor}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        customCallToActionButtonTextColor: e.target.value,
                      });
                    }}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    className="day-text-field"
                    style={{ marginTop: 20 }}
                  />

                  <TextField
                    label="Button Background Color (use hex, rgb or rgba)"
                    value={product.customCallToActionButtonColor}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        customCallToActionButtonColor: e.target.value,
                      });
                    }}
                    variant="outlined"
                    margin="dense"
                    type="text"
                    className="day-text-field"
                    style={{ marginTop: 20 }}
                  />

                  <TextField
                    label="Link URL"
                    value={product.customCallToActionLinkUrl}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        customCallToActionLinkUrl: e.target.value,
                      });
                    }}
                    margin="dense"
                    variant="outlined"
                    type="text"
                    className="day-text-field"
                    style={{ marginTop: 20 }}
                  />
                </>
              }
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Description Dropdowns</div>
              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.enableDescriptionSections || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        enableDescriptionSections: e.target.checked,
                      });

                    }}
                    name="hasCustomThankYouPage"
                    color="primary"
                  />
                }
                label="Show Additional Description"
              />
              <div className="row">
                <TextField
                  label="Description"
                  value={product.description}
                  onChange={(e) => { handleChange(e, 'description') }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  multiline
                  rows={12}
                  style={{ marginTop: 20 }}
                />
              </div>
              <div className="right-container">
                <Button variant="contained" onClick={addDropdown} color="primary" size="small">
                  Add Dropdown
                </Button>
              </div>
              <div>
                {product.descriptionSections.map((section, i) => {
                  return (
                    <div key={`dropdown-${i}`} className="description-section">
                      <div className="description-actions">
                        {
                          i !== 0 ?
                            <IconButton edge="start" color="inherit" onClick={() => {
                              const descriptionSections = [...product.descriptionSections];

                              const itemToMoveUp = section;
                              const itemToMoveDown = descriptionSections[i - 1];

                              descriptionSections[i] = itemToMoveDown;
                              descriptionSections[i - 1] = itemToMoveUp;

                              setProduct({
                                ...product,
                                descriptionSections,
                              });
                            }} aria-label="Move Up">
                              <ArrowUpwardIcon />
                            </IconButton> :
                            <div style={{ width: '36px' }}></div>
                        }

                        {
                          (i !== product.descriptionSections.length - 1) ?
                            <IconButton edge="start" color="inherit" onClick={() => {
                              const descriptionSections = [...product.descriptionSections];

                              const itemToMoveDown = section;
                              const itemToMoveUp = descriptionSections[i + 1];

                              descriptionSections[i] = itemToMoveUp;
                              descriptionSections[i + 1] = itemToMoveDown;

                              setProduct({
                                ...product,
                                descriptionSections,
                              });
                            }} aria-label="Move Down">
                              <ArrowDownwardIcon />
                            </IconButton> :
                            <div style={{ width: '36px' }}></div>
                        }

                        <IconButton color="inherit" onClick={() => { setSectionDeleteIndex(i) }} aria-label="Delete">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <Accordion style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#eee' }} key={`expansion-${i}`} expanded={expanded === `panel${i}`} onChange={handlePanelChange(`panel${i}`)}>
                        <AccordionSummary
                          style={{ backgroundColor: '#eee' }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{section.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ width: '100%' }}>
                            <TextField
                              label="Dropdown Title"
                              value={section.title}
                              onChange={(e) => {
                                const descriptionSections = [...product.descriptionSections];
                                const section = {
                                  ...descriptionSections[i],
                                  title: e.target.value,
                                };

                                descriptionSections[i] = section;

                                setProduct({
                                  ...product,
                                  descriptionSections,
                                });
                              }}
                              margin="dense"
                              variant="outlined"
                              type="text"
                              className="day-text-field"
                            />

                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                              {itemTypes.map((type, j) => {
                                return (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: '10px', marginBottom: '5px' }}
                                    onClick={() => { addDropdownDetail(type, i) }}
                                    key={`button-${type}-${i}`}
                                  >
                                    Add {type === 'textOverlayImage' ? 'Text Overlay Image' : type}
                                  </Button>
                                );
                              })}
                            </div>

                            <List>
                              {section.sections.map((item, j) => {
                                return (
                                  <div key={`dropdown-item-${i}-${j}`}>
                                    <Divider />

                                    <h4>{item.type === 'textOverlayImage' ? 'TEXT OVERLAY IMAGE' : item.type.toUpperCase()}</h4>

                                    <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      {renderItem(item, i, j)}
                                      <div>
                                        {renderDropdownItemReorderArrows(i, j)}
                                        <IconButton onClick={() => {
                                          setDropdownItemDelete([i, j]);
                                        }} edge="start" color="inherit" aria-label="Edit">
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </ListItem>
                                  </div>
                                );
                              })}
                            </List>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Main Product Info</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product?.enableMainProductInfo || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        enableMainProductInfo: e.target.checked,
                      });
                    }}
                    name="hasCustomThankYouPage"
                    color="primary"
                  />
                }
                label="Show Main Product Info"
              />

              <div className="right-container">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const features = product.additionalInfoFeatures || [];

                    setProduct({
                      ...product,
                      additionalInfoFeatures: [
                        ...features,
                        ''
                      ],
                    });
                  }}
                  color="primary"
                >
                  Add Product Feature
                </Button>
              </div>

              {(product.additionalInfoFeatures || []).map((item, i) => {
                return (
                  <div
                    key={`product-feature-${i}`}
                    className="product-feature-row"
                  >
                    <TextField
                      label={`Product Feature ${i + 1}`}
                      value={item}
                      onChange={(e) => {
                        const additionalInfoFeatures = [...product.additionalInfoFeatures];

                        additionalInfoFeatures[i] = e.target.value;

                        setProduct({
                          ...product,
                          additionalInfoFeatures,
                        });
                      }}
                      margin="dense"
                      variant="outlined"
                      type="text"
                      className="day-text-field"
                    />

                    <div className="delete-feature-container">
                      <IconButton onClick={() => {
                        const additionalInfoFeatures = [...product.additionalInfoFeatures];

                        additionalInfoFeatures.splice(i, 1);

                        setProduct({
                          ...product,
                          additionalInfoFeatures,
                        });
                      }} edge="start" color="inherit" aria-label="Edit">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}

              <TextField
                label="Additional Info"
                value={product.additionalInfoText || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    additionalInfoText: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                multiline
                rows="6"
              />

              <TextField
                label="Image URL"
                value={product.additionalInfoImage || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    additionalInfoImage: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Additional Product Info</div>
              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.enableAdditionalInfoFeatures || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        enableAdditionalInfoFeatures: e.target.checked,
                      });

                    }}
                    name="hasCustomThankYouPage"
                    color="primary"
                  />
                }
                label="Show Additional Info features"
              />

              <div>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const newInfo = {
                      additionalInfoText: '',
                      additionalInfoImage: '',
                      additionalInfoFeatures: [],
                      alignImage: 'left',
                    };

                    if (!product.additionalInfoExtra) {
                      setProduct({
                        ...product,
                        additionalInfoExtra: [
                          newInfo,
                        ],
                      });
                    } else {
                      setProduct({
                        ...product,
                        additionalInfoExtra: [
                          ...product.additionalInfoExtra,
                          newInfo,
                        ],
                      });
                    }
                  }}
                  color="primary"
                >
                  Add Additional Info Section
                </Button>
              </div>

              {(!product.additionalInfoExtra || !Array.isArray(product.additionalInfoExtra)) ? null :
                <>
                  {product.additionalInfoExtra.map((info, i) => {
                    return (
                      <div key={`additional-info-extra-${i}`}>
                        <div className="section-container">
                          <div className="section-label">Additional Info Section {i + 1}</div>
                          <div className="right-container" style={{ alignItems: 'center' }}>
                            <div>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  const features = [...info.additionalInfoFeatures, ''];
                                  const updatedInfo = [...product.additionalInfoExtra];

                                  updatedInfo[i] = { ...updatedInfo[i], additionalInfoFeatures: features };

                                  setProduct({
                                    ...product,
                                    additionalInfoExtra: updatedInfo,
                                  });
                                }}
                                color="primary"
                              >
                                Add Product Feature
                              </Button>
                            </div>

                            <div className="delete-feature-container">
                              <IconButton onClick={() => {
                                const updatedInfo = [...product.additionalInfoExtra];

                                updatedInfo.splice(i, 1);

                                setProduct({
                                  ...product,
                                  additionalInfoExtra: updatedInfo,
                                });
                              }} edge="start" color="inherit" aria-label="Edit">
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>

                          {(info.additionalInfoFeatures || []).map((item, m) => {
                            return (
                              <div
                                key={`product-feature-${i}-${m}`}
                                className="product-feature-row"
                              >
                                <TextField
                                  label={`Product Feature ${m + 1}`}
                                  value={item}
                                  onChange={(e) => {
                                    const additionalInfoFeatures = [...info.additionalInfoFeatures];
                                    const updatedInfo = [...product.additionalInfoExtra];

                                    additionalInfoFeatures[m] = e.target.value;

                                    updatedInfo[i] = { ...updatedInfo[i], additionalInfoFeatures: additionalInfoFeatures };

                                    setProduct({
                                      ...product,
                                      additionalInfoExtra: updatedInfo,
                                    });
                                  }}
                                  margin="dense"
                                  variant="outlined"
                                  type="text"
                                  className="day-text-field"
                                />

                                <div className="delete-feature-container">
                                  <IconButton onClick={() => {
                                    const features = [...info.additionalInfoFeatures];
                                    const updatedInfo = [...product.additionalInfoExtra];

                                    features.splice(m, 1);

                                    updatedInfo[i] = { ...updatedInfo[i], additionalInfoFeatures: features };

                                    setProduct({
                                      ...product,
                                      additionalInfoExtra: updatedInfo,
                                    });
                                  }} edge="start" color="inherit" aria-label="Edit">
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            );
                          })}

                          <TextField
                            label="Additional Info"
                            value={info.additionalInfoText || ''}
                            onChange={(e) => {
                              const updatedInfo = [...product.additionalInfoExtra];

                              updatedInfo[i] = { ...updatedInfo[i], additionalInfoText: e.target.value };

                              setProduct({
                                ...product,
                                additionalInfoExtra: updatedInfo,
                              });
                            }}
                            margin="dense"
                            variant="outlined"
                            type="text"
                            className="day-text-field"
                            multiline
                            rows="6"
                          />

                          <TextField
                            label="Image URL"
                            value={info.additionalInfoImage || ''}
                            onChange={(e) => {
                              const updatedInfo = [...product.additionalInfoExtra];

                              updatedInfo[i] = { ...updatedInfo[i], additionalInfoImage: e.target.value };

                              setProduct({
                                ...product,
                                additionalInfoExtra: updatedInfo,
                              });
                            }}
                            margin="dense"
                            variant="outlined"
                            type="text"
                            className="day-text-field"
                          />

                          <FormControl
                            style={{ marginTop: 8, marginBottom: 4 }}
                            variant="outlined"
                            className="day-text-field"
                          >
                            <InputLabel>Align Image</InputLabel>
                            <Select
                              value={info.alignImage || 'left'}
                              onChange={(e) => {
                                const updatedInfo = [...product.additionalInfoExtra];

                                updatedInfo[i] = { ...updatedInfo[i], alignImage: e.target.value };

                                setProduct({
                                  ...product,
                                  additionalInfoExtra: updatedInfo,
                                });
                              }}
                              margin="dense"
                              label="Align Image"
                            >
                              {['left', 'right'].map(category => (
                                <MenuItem key={category} value={category}>
                                  {category}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    );
                  })}
                </>
              }
            </div>
          </div>
          <TabsContent product={product} setProduct={setProduct} />

          <TreatBanner product={product} setProduct={setProduct} />

          <TreatActions product={product} setProduct={setProduct} />

          <div className="row">
            <div className="section-container">
              <div className="section-label">FAQs</div>
              <div className="right-container">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const faqs = product.faqs || [];

                    setProduct({
                      ...product,
                      faqs: [
                        ...faqs,
                        {
                          question: '',
                          answer: '',
                        },
                      ],
                    });
                  }}
                  color="primary"
                >
                  Add FAQ
                </Button>
              </div>

              {(product.faqs || []).map((item, i) => {
                return (
                  <div key={`product-faq-${i}`}>
                    {i === 0 ? null :
                      <div className="product-faq-divider">
                        <Divider />
                      </div>
                    }

                    <div className="product-faq-row">
                      <div className="product-faq-inputs-container">
                        <TextField
                          label={`Question ${i + 1}`}
                          value={item.question}
                          onChange={(e) => {
                            const faqs = [...product.faqs];

                            faqs[i] = {
                              ...faqs[i],
                              question: e.target.value,
                            };

                            setProduct({
                              ...product,
                              faqs,
                            });
                          }}
                          margin="dense"
                          variant="outlined"
                          type="text"
                          className="day-text-field"
                        />

                        {hideFaqEditors ? null :
                          <ReactQuill
                            theme="snow"
                            value={item.answer}
                            onChange={(answer) => {
                              const faqs = [...product.faqs];

                              faqs[i] = {
                                ...faqs[i],
                                answer,
                              };

                              setProduct({
                                ...product,
                                faqs,
                              });
                            }}
                            modules={{
                              toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['link'],
                              ],
                            }}
                            formats={[
                              'bold', 'italic', 'underline', 'strike', 'blockquote',
                              'list', 'bullet',
                              'link',
                            ]}
                          />
                        }
                      </div>

                      <div className="delete-faq-container">
                        <IconButton onClick={() => {
                          setHideFaqEditors(true);

                          setTimeout(() => {
                            const faqs = [...product.faqs];
                            faqs.splice(i, 1);
                            setProduct({
                              ...product,
                              faqs,
                            });
                            setHideFaqEditors(false);
                          }, 0);
                        }} edge="start" color="inherit" aria-label="Edit">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="categories-container">
            <FormControl style={{ marginTop: -22, width: '99%' }}>
              <InputLabel htmlFor="select-multiple-chip">Bundles</InputLabel>
              <Select
                multiple
                value={product.relatedBundles || []}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    relatedBundles: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <DeletableChip
                        key={value.id}
                        label={value.name}
                        style={{ margin: 2 }}
                        color="primary"
                        onDelete={() => {
                          const index = (product.relatedBundles || []).indexOf(value);

                          if (index === -1) {
                            return;
                          }

                          const relatedBundlesCopy = (product.relatedBundles || []).slice();
                          relatedBundlesCopy.splice(index, 1);
                          setProduct({
                            ...product,
                            relatedBundles: relatedBundlesCopy,
                          });
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {simplifiedProducts.filter(simplifiedProduct => {
                  const found = (product.relatedBundles || []).find(p => p.id === simplifiedProduct.id);
                  return !found;
                }).sort((a, b) => {
                  if (a.name < b.name) { return -1; }
                  if (a.name > b.name) { return 1; }
                  return 0;
                }).map(simplifiedProduct => (
                  <MenuItem key={simplifiedProduct.id} value={simplifiedProduct}>
                    {simplifiedProduct.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Suggested Training</div>

              <TextField
                label="Section Subtitle"
                value={product.suggestedTrainingSubtitle || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    suggestedTrainingSubtitle: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Video URL"
                value={product.suggestedTrainingVideoURL || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    suggestedTrainingVideoURL: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Video Thumbnail"
                value={product.suggestedTrainingVideoThumbnail || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    suggestedTrainingVideoThumbnail: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Description"
                value={product.suggestedTrainingDescription || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    suggestedTrainingDescription: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                multiline
                rows="4"
              />

              <TextField
                label="Button Link"
                value={product.suggestedTrainingButtonLink || ''}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    suggestedTrainingButtonLink: e.target.value,
                  });
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Featured Reviews</div>
              <div className="right-container">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const featuredReviews = product.featuredReviews || [];

                    setProduct({
                      ...product,
                      featuredReviews: [
                        ...featuredReviews,
                        {
                          imageURL: '',
                          content: '',
                          name: '',
                        },
                      ],
                    });
                  }}
                  color="primary"
                >
                  Add Featured Review
                </Button>
              </div>

              {(product.featuredReviews || []).map((item, i) => {
                return (
                  <div key={`product-faq-${i}`}>
                    {i === 0 ? null :
                      <div className="product-faq-divider">
                        <Divider />
                      </div>
                    }

                    <div className="product-faq-row">
                      <div className="product-faq-inputs-container">
                        <div className="row">
                          <TextField
                            label={`Review Image URL ${i + 1}`}
                            value={item.imageURL}
                            onChange={(e) => {
                              const featuredReviews = [...product.featuredReviews];

                              featuredReviews[i] = {
                                ...featuredReviews[i],
                                imageURL: e.target.value,
                              };

                              setProduct({
                                ...product,
                                featuredReviews,
                              });
                            }}
                            margin="dense"
                            variant="outlined"
                            type="text"
                            className="day-text-field product-name-field"
                          />

                          <TextField
                            label={`Reviewer Name ${i + 1}`}
                            value={item.name}
                            onChange={(e) => {
                              const featuredReviews = [...product.featuredReviews];

                              featuredReviews[i] = {
                                ...featuredReviews[i],
                                name: e.target.value,
                              };

                              setProduct({
                                ...product,
                                featuredReviews,
                              });
                            }}
                            margin="dense"
                            variant="outlined"
                            type="text"
                            className="day-text-field product-name-field"
                          />
                        </div>

                        <TextField
                          label={`Review Content ${i + 1}`}
                          value={item.content}
                          onChange={(e) => {
                            const featuredReviews = [...product.featuredReviews];

                            featuredReviews[i] = {
                              ...featuredReviews[i],
                              content: e.target.value,
                            };

                            setProduct({
                              ...product,
                              featuredReviews,
                            });
                          }}
                          margin="dense"
                          variant="outlined"
                          type="text"
                          className="day-text-field"
                        />
                      </div>

                      <div className="delete-faq-container">
                        <IconButton onClick={() => {
                          const featuredReviews = [...product.featuredReviews];

                          featuredReviews.splice(i, 1);

                          setProduct({
                            ...product,
                            featuredReviews,
                          });
                        }} edge="start" color="inherit" aria-label="Edit">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Visibility</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.showOnShopPage || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        showOnShopPage: e.target.checked,
                      });
                    }}
                    name="showOnShopPage"
                    color="primary"
                  />
                }
                label="Show on Shop Page"
              />

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.showInSearch || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        showInSearch: e.target.checked,
                      });
                    }}
                    name="showInSearch"
                    color="primary"
                  />
                }
                label="Show in Search"
              />

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.createProductPage || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        createProductPage: e.target.checked,
                      });
                    }}
                    name="createProductPage"
                    color="primary"
                  />
                }
                label="Create Product Page"
              />

              {!product.createProductPage ? null :
                <FormControlLabel
                  style={{ marginTop: 8, marginBottom: 4 }}
                  control={
                    <Switch
                      checked={product.noIndexProductPage || false}
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          noIndexProductPage: e.target.checked,
                        });
                      }}
                      name="noIndexProductPage"
                      color="primary"
                    />
                  }
                  label="No Index Product Page"
                />
              }

              {!product.showOnShopPage ? null :
                <TextField
                  label="Shop Page Display Name"
                  value={product.shopPageName || ''}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      shopPageName: e.target.value,
                    });
                  }}
                  margin="dense"
                  variant="outlined"
                  type="text"
                  className="day-text-field"
                  helperText="Leave blank to use product name"
                />
              }

            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Product Feeds</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.excludeFromGoogleFeed || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        excludeFromGoogleFeed: e.target.checked,
                      });
                    }}
                    name="excludeFromGoogleFeed"
                    color="primary"
                  />
                }
                label="Exclude From Google Merchant Feed"
              />

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.excludeFromFacebookFeed || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        excludeFromFacebookFeed: e.target.checked,
                      });
                    }}
                    name="excludeFromFacebookFeed"
                    color="primary"
                  />
                }
                label="Exclude From Facebook Product Feed"
              />
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Page Metadata</div>

              <TextField
                label="Page Title"
                value={product.title || ''}
                onChange={(e) => { handleChange(e, 'title') }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                style={{ marginTop: 20 }}
              />

              <TextField
                label="Page Description"
                value={product.metaDescription || ''}
                onChange={(e) => { handleChange(e, 'metaDescription') }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                style={{ marginTop: 20 }}
              />
            </div>
          </div>

          <div className="row">
            <div className="section-container">
              <div className="section-label">Additional Settings</div>

              <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                  <Switch
                    checked={product.googleOptimizeEnabled || false}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        googleOptimizeEnabled: e.target.checked,
                      });
                    }}
                    name="googleOptimizeEnabled"
                    color="primary"
                  />
                }
                label="Enable Google Optimize"
              />
            </div>
          </div>

        </div>
      </div>

      <Dialog fullScreen open={pageBuilderOpen} onClose={() => { setPageBuilderOpen(false) }} TransitionComponent={Transition}>
        <PageBuilder
          content={product.editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            setProduct({
              ...product,
              editorData: content,
            });
          }}
          close={() => { setPageBuilderOpen(false) }}
          css={product.customCSS}
          updateCSS={(customCSS) => {
            setProduct({
              ...product,
              customCSS,
            });
          }}
        />
      </Dialog>

      <Dialog open={showFileUploadModal} onClose={() => { setShowFileUploadModal(false) }} TransitionComponent={Transition}>
        <AddFilesModal
          filesToAdd={filesToAdd}
          addFiles={addFiles}
          setFilesToAdd={setFilesToAdd}
          uploadFiles={uploadFiles}
          cancelAddFiles={cancelAddFiles}
        />
      </Dialog>

      <Dialog fullScreen open={editFileOpen} onClose={() => { closeEditImageModal() }} TransitionComponent={Transition}>
        <EditFile
          image={editFile}
          closeModal={() => { closeEditImageModal() }}
          saveImage={saveImage}
          deleteImage={deleteFile}
          removeFromProduct={() => { deleteFile(true) }}
        />
      </Dialog>

      <Dialog open={showAddVideoFromUrl} onClose={() => { setShowAddVideoFromUrl(false) }} TransitionComponent={Transition}>
        <AddVideoFromUrlModal
          cancel={() => { setShowAddVideoFromUrl(false) }}
          submit={saveVideoFromURL}
        />
      </Dialog>

      <Dialog open={showAddGroup} onClose={() => { setShowAddGroup(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <TextField
            label="Group Name"
            value={newGroupName}
            onChange={(e) => { setNewGroupName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewGroup} color="secondary" disabled={!newGroupName || groups.includes(newGroupName)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddGroup(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddCategory} onClose={() => { setShowAddCategory(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => { setNewCategoryName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewCategory} color="secondary" disabled={!newCategoryName || categories.includes(newCategoryName)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddCategory(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddTag} onClose={() => { setShowAddTag(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          <TextField
            label="Tag Name"
            value={newTagName}
            onChange={(e) => { setNewTagName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewTag} color="secondary" disabled={!newTagName || tags.includes(newTagName)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddTag(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddFilterCategory} onClose={() => { setShowAddFilterCategory(false) }} TransitionComponent={Transition}>
        <DialogTitle>Add Filter Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Category Name"
            value={newFilterCategory}
            onChange={(e) => { setNewFilterCategory(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewFilterCategory} color="secondary" disabled={!newFilterCategory || !!shopFilters.find(f => f.name === newFilterCategory)}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddFilterCategory(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEditFilterCategoryNameIndex !== -1} onClose={() => { setShowEditFilterCategoryNameIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Edit Filter Category Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Category Name"
            value={editFilterCategoryName}
            onChange={(e) => { setEditFilterCategoryName(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={updateFilterCategoryName} color="secondary" disabled={!editFilterCategoryName || !!shopFilters.find(f => f.name === editFilterCategoryName)}>
            Update
          </Button>
          <Button variant="contained" onClick={() => { setShowEditFilterCategoryNameIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddFilterCategoryTypeIndex !== -1} onClose={() => { setShowAddFilterCategoryTypeIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Add {showAddFilterCategoryTypeIndex === -1 ? '' : shopFilters[showAddFilterCategoryTypeIndex].name}</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Category Name"
            value={newFilterCategoryType}
            onChange={(e) => { setNewFilterCategoryType(e.target.value) }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addNewFilterCategoryType} color="secondary" disabled={!newFilterCategoryType}>
            Add
          </Button>
          <Button variant="contained" onClick={() => { setShowAddFilterCategoryTypeIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={subscriptionDeleteIndex !== -1} onClose={() => { setSubscriptionDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Subscription Option?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this subscription option?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const subscriptionOptions = [...product.subscriptionOptions];
            subscriptionOptions.splice(subscriptionDeleteIndex, 1);
            setProduct({
              ...product,
              subscriptionOptions,
            });
            setSubscriptionDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setSubscriptionDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={sectionDeleteIndex !== -1} onClose={() => { setSectionDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Dropdown?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Dropdown?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const descriptionSections = [...product.descriptionSections];

            descriptionSections.splice(sectionDeleteIndex, 1);

            setProduct({
              ...product,
              descriptionSections,
            });
            setSectionDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setSectionDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dropdownItemDelete[0] !== -1 && dropdownItemDelete[1] !== -1} onClose={() => { setDropdownItemDelete([-1, -1]) }} TransitionComponent={Transition}>
        <DialogTitle>Delete Dropdown Item?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this dropdown item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const descriptionSections = [...product.descriptionSections];
            const sections = [...descriptionSections[dropdownItemDelete[0]].sections];

            sections.splice(dropdownItemDelete[1], 1);

            descriptionSections[dropdownItemDelete[0]] = {
              ...descriptionSections[dropdownItemDelete[0]],
              sections,
            };

            setProduct({
              ...product,
              descriptionSections,
            });
            setDropdownItemDelete([-1, -1]);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDropdownItemDelete([-1, -1]) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default EditProduct;
