import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  AppBar,
  TextField,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';

import './comments.scss';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Comments(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [comments, setComments] = useState([]);
  const [currentPostId, setCurrentPostId] = useState('');
  const [addCommentParent, setAddCommentParent] = useState(false);
  const [editComment, setEditComment] = useState({
    name: '',
    content: '',
  });
  const [rejectCommentCreated, setRejectCommentCreated] = useState(0);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('blog-comments').onSnapshot((commentsSnapshot) => {
      let data = {};
      let commentsArr = [];

      commentsSnapshot.docs.forEach(doc => {
        if (doc.id === 'data') {
          data = doc.data();
        } else {
          const totalComments = getTotalComments(doc.data().comments, {count: 0});

          commentsArr.push({
            ...doc.data(),
            id: doc.id,
            totalComments: totalComments.count,
          });
        }
      });

      commentsArr = commentsArr.sort((a, b) => {
        return a.created - b.created;
      });

      if (data.pendingComments && data.pendingComments.length) {
        const pendingCommentsMap = {};

        data.pendingComments.forEach(c => {
          const postId = c.split('-')[0];

          if (pendingCommentsMap.hasOwnProperty(postId)) {
            pendingCommentsMap[postId] += 1;
          } else {
            pendingCommentsMap[postId] = 1;
          }
        });

        commentsArr = commentsArr.map(c => {
          if (pendingCommentsMap.hasOwnProperty(c.id)) {
            c.pendingCount = pendingCommentsMap[c.id];
          } else {
            c.pendingCount = 0;
          }

          return c;
        });
      }

      setComments(commentsArr);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const getTotalComments = (comments, count) => {
    for (let i = 0; i < comments.length; i++) {
      count.count++;

      if (comments[i].responses) {
        getTotalComments(comments[i].responses, count);
      }
    }

    return count;
  };

  const findAndUpdateComment = (postComments, created, field) => {
    for (let i = 0; i < postComments.length; i++) {
      const comment = postComments[i];

      if (comment.created === created) {
        comment[field] = !comment[field];
        return;
      }

      if (comment.responses) {
        findAndUpdateComment(comment.responses, created, field);
      }
    }
  };

  const getPostIdex = () => {
    return comments.findIndex(c => c.id === currentPostId);
  };

  const rejectComment = async () => {
    setLoading(true);
    let commentsClone = cloneDeep(comments[getPostIdex()]);

    try {
      const db = firebase.firestore();
      const blogCommentsRef = db.collection('blog-comments').doc(commentsClone.id);

      await db.runTransaction(async (t) => {
        const commentSnapshot = await t.get(blogCommentsRef);
        commentsClone = {
          ...commentsClone,
          comments: commentSnapshot.data().comments,
        };

        const postComments = commentsClone.comments;
        findAndUpdateComment(postComments, rejectCommentCreated, 'rejected');

        t.update(blogCommentsRef, {
          comments: postComments,
        });
      });

      await db.collection('blog-comments').doc('data').update({
        pendingComments: firebase.firestore.FieldValue.arrayRemove(`${commentsClone.id}-${rejectCommentCreated}`),
      });

      commentsClone.pendingCount -= 1;

      const allCommentsClone = [ ...comments ];
      allCommentsClone[getPostIdex()] = commentsClone;

      setComments(allCommentsClone);
      setRejectCommentCreated(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error rejecting the comment. Please try again.');
    }
  };

  const updateComment = async (created, field, live) => {
    setLoading(true);
    let commentsClone = cloneDeep(comments[getPostIdex()]);

    try {
      const db = firebase.firestore();
      const blogCommentsRef = db.collection('blog-comments').doc(commentsClone.id);

      await db.runTransaction(async (t) => {
        const commentSnapshot = await t.get(blogCommentsRef);
        commentsClone = {
          ...commentsClone,
          comments: commentSnapshot.data().comments,
        };

        const postComments = commentsClone.comments;
        findAndUpdateComment(postComments, created, field);

        t.update(blogCommentsRef, {
          comments: postComments,
        });
      });

      if (field === 'live') {
        if (live) {
          await db.collection('blog-comments').doc('data').update({
            pendingComments: firebase.firestore.FieldValue.arrayUnion(`${commentsClone.id}-${created}`),
          });

          commentsClone.pendingCount += 1;
        } else {
          await db.collection('blog-comments').doc('data').update({
            pendingComments: firebase.firestore.FieldValue.arrayRemove(`${commentsClone.id}-${created}`),
          });

          commentsClone.pendingCount -= 1;
        }
      }

      const allCommentsClone = [ ...comments ];
      allCommentsClone[getPostIdex()] = commentsClone;

      setComments(allCommentsClone);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the comment. Please try again.');
    }
  };

  const findParentAndAddComment = (postComments, created, newComment) => {
    for (let i = 0; i < postComments.length; i++) {
      const comment = postComments[i];

      if (comment.created === created) {
        comment.responses.push(newComment);
        return;
      }

      if (comment.responses) {
        findParentAndAddComment(comment.responses, created, newComment);
      }
    }
  };

  const addComment = async () => {
    setLoading(true);
    let commentsClone = cloneDeep(comments[getPostIdex()]);

    try {
      const db = firebase.firestore();
      const blogCommentsRef = db.collection('blog-comments').doc(commentsClone.id);

      await db.runTransaction(async (t) => {
        const commentSnapshot = await t.get(blogCommentsRef);
        commentsClone = {
          ...commentsClone,
          comments: commentSnapshot.data().comments,
        };

        const postComments = commentsClone.comments;
        const timestamp = Date.now();

        const comment = {
          emailVerified: true,
          content: editComment.content,
          created: timestamp,
          updated: timestamp,
          deleted: false,
          downVotes: 0,
          live: true,
          email: firebase.auth().currentUser.email,
          upVotes: 0,
          userName: editComment.name,
          userId: firebase.auth().currentUser.uid,
          responses: [],
          isAdmin: true,
        };

        findParentAndAddComment(postComments, addCommentParent, comment);

        t.update(blogCommentsRef, {
          comments: postComments,
        });
      });

      commentsClone.totalComments += 1;

      const allCommentsClone = [ ...comments ];
      allCommentsClone[getPostIdex()] = commentsClone;

      setComments(allCommentsClone);
      setLoading(false);
      setAddCommentParent(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding your comment. Please try again.');
    }
  };

  const renderComments = (postComments, isChild) => {
    const commentsToRender = [];

    for (let i = 0; i < postComments.length; i++) {
      const comment = postComments[i];

      commentsToRender.push(
        <div key={`${comment.created}-${i}`}>
          <ListItem>
            <ListItemText
              primary={comment.userName}
              secondary={
                <span>

                  <span style={{marginRight: 10}}>
                    {comment.content.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}
                  </span>

                  <span style={{marginTop: 10, display: 'block', marginRight: 10}}>
                    <span>
                      <span>
                        <span style={{fontWeight: 'bold'}}>Status:</span> <span style={{color: comment.live ? 'rgb(126, 210, 66)' : 'red', marginRight: 10}}>{comment.rejected ? 'Rejected' : (comment.live ? 'Live' : 'Pending')}</span>
                      </span>
                      <span>
                        <span style={{fontWeight: 'bold'}}>Email Verified:</span> <span style={{marginRight: 10}}>{comment.emailVerified ? 'Yes': 'No'}</span>
                      </span>
                      <span>
                        <span style={{fontWeight: 'bold'}}>Date:</span> <span style={{marginRight: 10}}>{moment(comment.created).format('MMMM Do YYYY, h:mm a')}</span>
                      </span>
                    </span>
                  </span>
                </span>
              }
            />

            {comment.rejected ? null :<div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', minWidth: 150, alignSelf: 'start', marginTop: 10, marginBottom: 10 }}>
              <Button style={{marginBottom: 10}} variant="contained" onClick={() => { updateComment(comment.created, 'live', comment.live) }} size="small">
                {comment.live ? 'Mark Pending' : 'Mark Live'}
              </Button>
              <Button style={{marginBottom: 10}} variant="contained" onClick={() => { updateComment(comment.created, 'emailVerified') }} size="small">
                {comment.emailVerified ? 'Invalidate Email' : 'Verify Email'}
              </Button>
              <Button style={{marginBottom: 10}} variant="contained" onClick={() => {
                setAddCommentParent(comment.created);
                setEditComment({
                  name: 'Team Pupford',
                  content: '',
                });
              }} color="primary" size="small">
                Comment
              </Button>
              {comment.live ? null :
                <Button style={{marginBottom: 10}} variant="contained" onClick={() => { setRejectCommentCreated(comment.created) }} size="small" color="secondary">
                  Reject
                </Button>
              }
            </div>}
          </ListItem>

          <Divider />

          {!comment.responses ? null :
            <div style={{marginLeft: 50}}>
              <List>
                {renderComments(comment.responses, true)}
              </List>
            </div>
          }
        </div>
      );
    }

    return commentsToRender;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="BlogComments">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Comments
          </Typography>
        </Toolbar>

        <List>
          {comments.map((c, i) => {
            return (
              <div key={c.id}>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={c.postTitle}
                    secondary={
                      <span>
                        <span style={{marginRight: 10}}><strong>Total Comments:</strong> {c.totalComments}</span>
                        {!c.pendingCount ? null :
                          <span style={{marginRight: 10}}><strong>Pending:</strong> <span className="notification-count-container">{c.pendingCount}</span></span>
                        }
                      </span>
                    }
                  />

                  <IconButton color="inherit" aria-label="View" onClick={() => {
                    setCurrentPostId(c.id);
                  }}>
                    <VisibilityIcon />
                  </IconButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>

      <Dialog fullScreen open={getPostIdex() !== -1} onClose={() => {
        setCurrentPostId('');
      }} TransitionComponent={Transition}>
        {getPostIdex() === -1 ? null : <div className="blog-post-comments-modal-container">
          <AppBar style={{position: 'relative'}}>
            <Toolbar>
              <Typography variant="h6" style={{
                marginLeft: '10px',
                flex: 1,
                color: 'white',
              }}>
                {comments[getPostIdex()].postTitle}
              </Typography>
              <Button color="inherit" onClick={() => {
                setCurrentPostId('');
              }}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <div className="modal-container">
            <List>
              {renderComments(comments[getPostIdex()].comments)}
            </List>
          </div>
        </div>}
      </Dialog>

      <Dialog open={!!rejectCommentCreated} onClose={() => { setRejectCommentCreated(0) }} TransitionComponent={Transition}>
        <DialogTitle>Reject Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to reject this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={rejectComment} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setRejectCommentCreated(0) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!addCommentParent} onClose={() => { setAddCommentParent(false) }} TransitionComponent={Transition}>
        <DialogTitle>Respond to Comment</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <TextField
              label="Display Name"
              value={editComment.name}
              onChange={(e) => {
                setEditComment({
                  ...editComment,
                  name: e.target.value,
                });
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Comment"
              value={editComment.content}
              onChange={(e) => {
                setEditComment({
                  ...editComment,
                  content: e.target.value,
                });
              }}
              margin="dense"
              multiline
              rows="8"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 20}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { addComment() }} color="primary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddCommentParent(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, {})(Comments);
