import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { ColorPicker } from '../../../../colorPicker/colorPicker';

export const ButtonBuyNowForm = () => {
  const form = useFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Button Buy Now</strong>
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <>
              <Controller
                name="buttonBuyNow.backgroundColor"
                control={control}
                render={({ field }) => {
                  console.log({ field });

                  return (
                    <ColorPicker
                      field={field}
                      label="Background Color"
                      error={Boolean(errors?.buttonBuyNow?.backgroundColor)}
                      helperText={
                        errors?.buttonBuyNow?.backgroundColor?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                name="buttonBuyNow.textColor"
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      field={field}
                      label="Text Color"
                      error={Boolean(errors?.buttonBuyNow?.textColor)}
                      helperText={errors?.buttonBuyNow?.textColor?.message}
                    />
                  );
                }}
              />
              <Controller
                name="buttonBuyNow.borderColor"
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      field={field}
                      label="Border| Color"
                      error={Boolean(errors?.buttonBuyNow?.borderColor)}
                      helperText={errors?.buttonBuyNow?.borderColor?.message}
                    />
                  );
                }}
              />
            </>
          </Box>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
