import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './wholesaleNotifications.scss';
import DeletableChip from '../../shared/deletableChip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function WholesaleNotifications({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [wholesalers, setWholesalers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [currentEditUser, setCurrentEditUser] = useState({
    email: '',
    allWholesalers: false,
    wholesalers: [],
  });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchWholesalersAndUsers();
  }, []);

  const fetchWholesalersAndUsers = async () => {
    const db = firebase.firestore();
    try {
      const wholesalersSnapshot = await db.collection('users').where('roles', 'array-contains', 'wholesaler').get();
      const mappedWholesalers = wholesalersSnapshot.docs.map(doc => {
        const data = doc.data();
        return data.email;
      });

      const emailNotificationUsersSnapshot = await db.collection('wholesale').doc('purchaseEmailNotifications').get();
      const emailNotificationUsers = emailNotificationUsersSnapshot.data();

      console.log(mappedWholesalers);
      console.log(emailNotificationUsers.users);

      setWholesalers(mappedWholesalers);
      setUsers(emailNotificationUsers.users);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching wholesalers. Please try again.');
    }
  };

  const closeEditModal = () => {
    setCurrentEditUser({
      email: '',
      allWholesalers: false,
      wholesalers: [],
    });
    setCurrentEditIndex(-1);
    setEditOpen(false);
  };

  const save = async () => {
    setLoading(true);
    const usersCopy = [ ...users ];

    if (currentEditIndex === -1) {
      usersCopy.push(currentEditUser);
    } else {
      usersCopy[currentEditIndex] = currentEditUser
    }

    try {
      await firebase.firestore().collection('wholesale').doc('purchaseEmailNotifications').update({
        users: usersCopy,
      });

      setUsers(usersCopy);
      setLoading(false);
      closeEditModal();
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving notification settings, please try again.');
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    const usersCopy = [ ...users ];

    usersCopy.splice(deleteIndex, 1);

    try {
      await firebase.firestore().collection('wholesale').doc('purchaseEmailNotifications').update({
        users: usersCopy,
      });

      setUsers(usersCopy);
      setLoading(false);
      setDeleteIndex(-1);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting the selected user, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="WholesaleNotifications">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Notifications
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              setCurrentEditUser({
                email: '',
                allWholesalers: false,
                wholesalers: [],
              });
              setCurrentEditIndex(-1);
              setEditOpen(true);
            }}
            color="secondary"
            size="small"
          >
            Add Email
          </Button>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Receive Order Notifications For</strong></TableCell>
                <TableCell padding="checkbox"></TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((u, i) => (
                <TableRow key={`user-${i}`}>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>{u.allWholesalers ? 'All Wholesale Purchases' : u.wholesalers.join(', ')}</TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      edge="start"
                      style={{marginLeft: 10, color: '#000'}}
                      color="default"
                      aria-label="edit"
                      onClick={() => {
                        setCurrentEditUser({
                          ...u,
                          wholesalers: [ ...u.wholesalers ],
                        });
                        setCurrentEditIndex(i);
                        setEditOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={deleteIndex !== -1}
        onClose={() => { setDeleteIndex(-1) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Remove User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want remove <strong>{deleteIndex === -1 ? '' : users[deleteIndex].email}</strong> from receiving order notifications?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={editOpen}
        onClose={closeEditModal}
        TransitionComponent={Transition}
      >
        <DialogTitle>{currentEditIndex === -1 ? 'Add' : 'Edit'} Email</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={currentEditUser.email}
            onChange={(e) => {
              setCurrentEditUser({
                ...currentEditUser,
                email: e.target.value,
              });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
            style={{marginTop: 15, marginBottom: 15}}
          />

          <FormControlLabel
            control={
              <Switch
                checked={currentEditUser.allWholesalers}
                onChange={(e) => {
                  setCurrentEditUser({
                    ...currentEditUser,
                    allWholesalers: e.target.checked,
                  });
                }}
                name="active"
                color="primary"
              />
            }
            label="Notify for All Purchases"
          />

          {currentEditUser.allWholesalers ? null :
            <FormControl style={{ margin: 5, width: '99%' }}>
              <InputLabel htmlFor="select-multiple-chip">Wholesalers</InputLabel>
              <Select
                multiple
                value={currentEditUser.wholesalers}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCurrentEditUser({
                    ...currentEditUser,
                    wholesalers: e.target.value,
                  });
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {selected.map(value => (
                      <DeletableChip
                        key={value}
                        label={value}
                        style={{margin: 2}}
                        color="primary"
                        onDelete={() => {
                          const index = currentEditUser.wholesalers.indexOf(value);
                          const wholesalers = currentEditUser.wholesalers.slice();
                          wholesalers.splice(index, 1);
                          setCurrentEditUser({
                            ...currentEditUser,
                            wholesalers,
                          });
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {wholesalers.filter(wholesaler => {
                  return !currentEditUser.wholesalers.includes(wholesaler);
                }).map(wholesaler => (
                  <MenuItem key={wholesaler} value={wholesaler}>
                    {wholesaler}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={save}
            color="primary"
            disabled={!emailRegex.test(currentEditUser.email)}
          >
            Save
          </Button>
          <Button variant="contained" onClick={closeEditModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WholesaleNotifications;
