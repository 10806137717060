import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditFile from './editFile/editFile';
import AddFilesModal from './addFilesModal/addFilesModal';

import './fileManager.scss';
import folderImage from '../../images/folder.svg';
import AddVideoFromUrlModal from './addVideoFromUrlModal/addVideoFromUrlModal';
import { config } from '../../config';

const pathRegex = "^[A-Za-z0-9\-]+$";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FileManager(props) {
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [newFolderPath, setNewFolderPath] = useState('');
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [files, setFiles] = useState([]);
  const [editFileOpen, setEditFileOpen] = useState(false);
  const [editFile, setEditFile] = useState({});
  const [editFileIndex, setEditFileIndex] = useState(-1);
  const [showAddVideoFromUrl, setShowAddVideoFromUrl] = useState(false);

  useEffect(() => {
    if (props.location.pathname[props.location.pathname.length - 1] === '/') {
      props.history.replace(props.location.pathname.substring(0, props.location.pathname.length - 1));
    }

    fetchFiles();
  }, [props.location.pathname]);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      let folderSnapshot;
      let fileSnapshot;

      if (props.location.pathname === '/file-manager') {
        folderSnapshot = await firebase.firestore().collection('asset-directories').where('parentFolder', '==', '').get();
        fileSnapshot = await firebase.firestore().collection('files').where('folder', '==', '').get();
      } else {
        const search = props.location.pathname.substring(14);
        folderSnapshot = await firebase.firestore().collection('asset-directories').where('parentFolder', '==', search).get();
        fileSnapshot = await firebase.firestore().collection('files').where('folder', '==', search).get();
      }

      const maxLength = props.location.pathname.split('/').length;

      const folders = folderSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).filter(folder => {
        if (folder.path === 'products') {
          return false;
        }

        return folder.path.split('/').length < maxLength;
      });

      const filesData = fileSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).filter(file => {
        return file.folder.split('/').length < maxLength;
      }).sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated));

      setFolders(folders);
      setFiles(filesData);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const addNewFolder = async () => {
    setLoading(true);

    try {
      let folderData = {};
      const parentFolder = props.location.pathname === '/file-manager' ? '' : `${props.location.pathname.substring(14)}`;

      if (props.location.pathname === '/file-manager') {
        folderData = {
          name: newFolderName,
          path: newFolderPath,
          parentFolder,
        };
      } else {
        folderData = {
          name: newFolderName,
          path: `${props.location.pathname.substring(14)}/${newFolderPath}`,
          parentFolder,
        };
      }

      const folder = await firebase.firestore().collection('asset-directories').add(folderData);

      setFolders([ ...folders, { ...folderData, id: folder.id } ]);
    } catch (e) {
      window.alert('There was an error adding the new folder. Please try again.');
    }

    setLoading(false);
    cancelAddNewFolder();
  };

  const cancelAddNewFolder = () => {
    setNewFolderName('');
    setNewFolderPath('')
    setShowFolderModal(false);
  };

  const addFiles = async (files) => {
    try {
      const filesToUpload = [];

      for (let i = 0; i < files.length; i++) {
        filesToUpload.push({
          file: files[i],
          name: '',
          alt: '',
          placeholder: '',
          fileName: files[i].name,
        });
      }

      setFilesToAdd(filesToUpload);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelAddFiles = () => {
    setShowFileUploadModal(false);
    setFilesToAdd([]);
  };

  const uploadFiles = async () => {
    const storageRef = firebase.storage().ref();
    let pathname = props.location.pathname.substring(13);
    const filesCopy = [...files];

    setShowFileUploadModal(false);
    setLoading(true);

    if (pathname[0] !== '/') {
      pathname = `/${pathname}`;
    } else {
      pathname = `${pathname}/`;
    }

    for (let i = 0; i < filesToAdd.length; i++) {
      const child = `assets${pathname}${`${Date.now()}-${filesToAdd[i].fileName}`}`;
      const imageRef = storageRef.child(child);

      try {
        const snapshot = await imageRef.put(filesToAdd[i].file, {
          cacheControl: 'public,max-age=2592000',
        });

        let url = await imageRef.getDownloadURL();

        url = url.replace(
          `${config.storage}/o/assets`,
          `${config.cdn}/assets`,
        );

        const folder = props.location.pathname === '/file-manager' ? '' : `${props.location.pathname.substring(14)}`;

        const fileToAdd = {
          folder,
          storagePath: snapshot.metadata.fullPath,
          contentType: snapshot.metadata.contentType,
          size: snapshot.metadata.size,
          timeCreated: snapshot.metadata.timeCreated,
          name: filesToAdd[i].name,
          alt: filesToAdd[i].alt,
          placeholder: filesToAdd[i].placeholder,
          url,
        };

        const file = await firebase.firestore().collection('files').add(fileToAdd);

        fileToAdd.id = file.id;
        filesCopy.unshift(fileToAdd);
      } catch (e) {
        console.log('e', e);
      }
    }

    setFiles(filesCopy);
    cancelAddFiles();
    setLoading(false);
  };

  const renderBreadcrumbs = () => {
    const pathArr = props.location.pathname.split('/');
    pathArr.shift();

    return (
      <div className="breadcrumb-container">
        {pathArr.map((path, i) => {
          if (i === pathArr.length - 1) {
            return (
              <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
                <Typography className="breadcrumb-item">{path}</Typography>
              </div>
            );
          }

          return (
            <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
              <Link to={`/${pathArr.slice(0, i + 1).join('/')}`} className="breadcrumb-item">{path}</Link>
              <div className="breadcrumb-item">/</div>
            </div>
          );
        })}
      </div>
    );
  };

  const closeEditImageModal = () => {
    setEditFileOpen(false);
    setEditFileIndex(-1);
    setEditFile({});
  };

  const deleteFile = async () => {
    setLoading(true);
    setEditFileOpen(false);

    const storageRef = firebase.storage().ref();
    const file = files[editFileIndex];

    try {
      if (file.storagePath) {
        const child = file.storagePath;
        const imageRef = storageRef.child(child);
  
        await imageRef.delete();
      }

      await firebase.firestore().collection('files').doc(file.id).delete();

      const filesCopy = files.slice();

      filesCopy.splice(editFileIndex, 1);

      setFiles(filesCopy);
    } catch (e) {
      console.log(e);
      window.alert('There was an error deleting the file.');
    }

    closeEditImageModal();
    setLoading(false);
  };

  const saveImage = async (file) => {
    setLoading(true);
    setEditFileOpen(false);

    try {
      await firebase.firestore().collection('files').doc(file.id).update({
        name: file.name,
        alt: file.alt,
        placeholder: file.placeholder,
      });

      const filesCopy = files.slice();

      filesCopy[editFileIndex] = {
        ...filesCopy[editFileIndex],
        name: file.name,
        alt: file.alt,
        placeholder: file.placeholder,
      };

      setFiles(filesCopy);
    } catch (e) {
      console.log(e);
      window.alert('There was an error updating the file.');
    }

    closeEditImageModal();
    setLoading(false);
  };

  const saveVideoFromURL = async (data) => {
    setShowAddVideoFromUrl(false);
    setLoading(true);

    try {
      const folder = props.location.pathname === '/file-manager' ? '' : `${props.location.pathname.substring(14)}`;
  
      const fileToAdd = {
        folder,
        storagePath: '',
        contentType: 'video',
        size: 0,
        timeCreated: new Date().toISOString(),
        name: data.title,
        alt: '',
        placeholder: data.placeholder,
        url: data.url,
      };
  
      const file = await firebase.firestore().collection('files').add(fileToAdd);
      const filesCopy = [...files];

      fileToAdd.id = file.id;
      filesCopy.unshift(fileToAdd);
      setFiles(filesCopy);
    } catch (e) {
      console.log('e', e);
      window.alert('There was an error adding the video. Please try again.');
    }

    setLoading(false);
  };

  const isProductPath = () => {
    return (!props.location.pathname.substring(13) && newFolderPath === 'products');
  };

  return (
    <div className="FileManager" style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}
      <div style={{textAlign: 'left', marginTop: 70, padding: 20 }}>
        {props.location.pathname === '/file-manager' ? null : renderBreadcrumbs()}
        <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
          <Typography variant="h6">
            File Manager
          </Typography>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setShowFileUploadModal(true) }}
              style={{marginRight: 10}}
              size="small"
            >
              Add Files
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => { setShowAddVideoFromUrl(true) }}
              style={{marginRight: 10}}
              size="small"
            >
              Add Video from URL
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setShowFolderModal(true) }}
              size="small"
            >
              Add Folder
            </Button>
          </div>
          {/* { importing && <CircularProgress size={24} style={{position: 'absolute', right: '56px'}} /> } */}
        </Toolbar>
        <div style={{borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid', borderTopWidth: 0, backgroundColor: '#fff', padding: 20}}>
          {folders.map((folder) => {
            return (
              <Link to={`/file-manager/${folder.path}`} key={folder.id} className="file-container">
                <div className="file-container-inner">
                  <div className="folder-image">
                    <img src={folderImage}/>
                  </div>
                  <div className="file-name">{folder.name}</div>
                </div>
              </Link>
            );
          })}
          {files.map((file, i) => {
            return (
              <div
                key={file.id}
                className="file-container"
                onClick={() => {
                  setEditFile(file);
                  setEditFileOpen(true);
                  setEditFileIndex(i);
                }}
              >
                <div className="file-container-inner">
                  <div className="file-image">
                    {(file.contentType.includes('image') || file.contentType.includes('video')) && <img src={file.contentType.includes('video') ? file.placeholder : file.url}/>}
                  </div>
                  <div className="file-name">{file.name || 'Unnamed'}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog open={showFolderModal} onClose={() => { setShowFolderModal(false) }} TransitionComponent={Transition}>
        <div className="file-manager-modal-container">
          <p style={{fontSize: '24px', fontWeight: 'bold', lineHeight: 0}}>Add New Folder</p>
          <TextField
            style={{minWidth: '225px'}}
            label="Folder Name"
            value={newFolderName}
            onChange={(e) => { setNewFolderName(e.target.value) }}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <div className="path-field-container">
            <div className="path-prefix">{props.location.pathname.substring(13) + '/'}</div>
            <TextField
              label="Folder Path"
              value={newFolderPath}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setNewFolderPath(value);
              }}
              margin="normal"
              variant="outlined"
              className="day-text-field"
              helperText={isProductPath() ? 'This is a reserved path name.' : ''}
              error={isProductPath()}
            />
          </div>
          <hr/>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!newFolderName || !newFolderPath || !newFolderPath.match(pathRegex) || isProductPath()}
            onClick={addNewFolder}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { cancelAddNewFolder() }}
          >cancel</Button>
        </div>
      </Dialog>

      <Dialog open={showFileUploadModal} onClose={() => { setShowFileUploadModal(false) }} TransitionComponent={Transition}>
        <AddFilesModal
          filesToAdd={filesToAdd}
          addFiles={addFiles}
          setFilesToAdd={setFilesToAdd}
          uploadFiles={uploadFiles}
          cancelAddFiles={cancelAddFiles}
        />
      </Dialog>

      <Dialog open={showAddVideoFromUrl} onClose={() => { setShowAddVideoFromUrl(false) }} TransitionComponent={Transition}>
        <AddVideoFromUrlModal
          cancel={() => { setShowAddVideoFromUrl(false) }}
          submit={saveVideoFromURL}
        />
      </Dialog>

      <Dialog fullScreen open={editFileOpen} onClose={() => { closeEditImageModal() }} TransitionComponent={Transition}>
        <EditFile
          image={editFile}
          closeModal={() => { closeEditImageModal() }}
          saveImage={saveImage}
          deleteImage={deleteFile}
        />
      </Dialog>
    </div>
  );
}

export default FileManager;
