import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import './editAcademyPlusCourseLandingPage.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditAcademyPlusCourseLandingPage({ match, history }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [pageId, setPageId] = useState('');
  const [page, setPage] = useState({
    title: '',
    description: '',
    path: '',
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
  });

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = async () => {
    const id = match.params.id;

    if (id === undefined) {
      history.replace('/academy-content/academy-plus-course-landing-pages');
      return;
    }

    try {
      const pageSnapshot = await firebase.firestore().collection('academy-plus-landing-pages').doc(id).get();
      const pageData = pageSnapshot.data();

      if (!pageData) {
        history.replace('/academy-content/academy-plus-course-landing-pages');
        return;
      }

      setPageId(id);
      setPage(pageData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the landing page, please try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      const db = firebase.firestore();
      const existingPageQuerySnapshot = await db.collection('pages').where('path', '==', page.path).get();
      const existingBlogQuerySnapshot = await db.collection('blog-posts').where('path', '==', page.path).get();
      const existingAcademyPagesQuerySnapshot = await db.collection('academy-plus-landing-pages').where('path', '==', page.path).get();

      if (existingPageQuerySnapshot.docs.length || existingBlogQuerySnapshot.docs.length || (existingAcademyPagesQuerySnapshot.docs.length && existingAcademyPagesQuerySnapshot.docs[0].id !== pageId)) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      await firebase.firestore().collection('academy-plus-landing-pages').doc(pageId).update({
        ...page,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this landing page, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div className="loadingContainer">
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="EditAcademyPlusCourseLandingPage">
      {renderLoading()}
      <div className="top-buttons-container">
        <Link to="/academy-content/academy-plus-course-landing-pages">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            All Academy+ Landing Pages
          </Button>
        </Link>
      </div>

      <Card style={{width: '100%'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Edit Academy+ Landing Page
          </Typography>

          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={save}
              style={{marginRight: '10px'}}
            >
              Save
            </Button>
          </div>
        </Toolbar>

        <Divider/>

        <CardContent>
          <FormControlLabel
            control={
              <Switch
                checked={page.live}
                onChange={(e) => {
                  setPage({
                    ...page,
                    live: e.target.checked,
                  });
                }}
                name="live"
                color="primary"
              />
            }
            label="Live"
          />

          <TextField
            label="Title"
            value={page.title}
            onChange={(e) => {
              setPage({
                ...page,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Description"
            value={page.description}
            onChange={(e) => {
              setPage({
                ...page,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={page.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setPage({
                ...page,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Course ID"
            value={page.courseId || ''}
            onChange={(e) => {
              setPage({
                ...page,
                courseId: e.target.value.trim(),
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Course Overview Video ID"
            value={page.courseVideoId || ''}
            onChange={(e) => {
              setPage({
                ...page,
                courseVideoId: e.target.value.trim(),
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <div className="section-container">
            <div className="section-label">Course "What Is" Section</div>

            <TextField
              label="Subtitle"
              value={page.whatIsSubtitle || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  whatIsSubtitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Image URL"
              value={page.whatIsImageURL || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  whatIsImageURL: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Bullet Points Title"
              value={page.whatIsBulletsTitle || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  whatIsBulletsTitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <div className="list-section-header-row">
              <Typography><strong>Bullet Points</strong></Typography>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  const bullets = page.whatIsBullets || [];

                  setPage({
                    ...page,
                    whatIsBullets: [ ...bullets, '' ],
                  });
                }}
              >
                Add Bullet Point
              </Button>
            </div>

            <List>
              {(page.whatIsBullets || []).map((bullet, i) => {
                return (
                  <ListItem key={`what-is-bullet-${i}`}>
                    <TextField
                      label={`Bullet Point ${i + 1}`}
                      value={bullet}
                      onChange={(e) => {
                        const whatIsBullets = [ ...page.whatIsBullets ];

                        whatIsBullets[i] = e.target.value;

                        setPage({
                          ...page,
                          whatIsBullets,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                    />

                    <IconButton
                      onClick={() => {
                        const whatIsBullets = [ ...page.whatIsBullets ];

                        whatIsBullets.splice(i, 1);

                        setPage({
                          ...page,
                          whatIsBullets,
                        });
                      }}
                      style={{marginLeft: 10}}
                      edge="start"
                      color="inherit"
                      aria-label="Edit"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </div>

          <div className="section-container">
            <div className="section-label">Course Details Section</div>

            <TextField
              label="Subtitle"
              value={page.detailsSubtitle || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  detailsSubtitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <div className="section-container">
              <div className="section-label">Detail 1</div>

              <TextField
                label="Image URL"
                value={page.detail1ImageUrl || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail1ImageUrl: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Title"
                value={page.detail1Title || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail1Title: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Text"
                value={page.detail1Text || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail1Text: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
                multiline
                rows="4"
              />
            </div>

            <div className="section-container">
              <div className="section-label">Detail 2</div>

              <TextField
                label="Image URL"
                value={page.detail2ImageUrl || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail2ImageUrl: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Title"
                value={page.detail2Title || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail2Title: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Text"
                value={page.detail2Text || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail2Text: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
                multiline
                rows="4"
              />
            </div>

            <div className="section-container">
              <div className="section-label">Detail 3</div>

              <TextField
                label="Image URL"
                value={page.detail3ImageUrl || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail3ImageUrl: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Title"
                value={page.detail3Title || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail3Title: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
              />

              <TextField
                label="Text"
                value={page.detail3Text || ''}
                onChange={(e) => {
                  setPage({
                    ...page,
                    detail3Text: e.target.value,
                  });
                }}
                variant="outlined"
                margin="dense"
                type="text"
                className="day-text-field"
                multiline
                rows="4"
              />
            </div>
          </div>

          <div className="section-container">
            <div className="section-label">Course Benefits</div>

            <TextField
              label="Subtitle"
              value={page.benefitsSubtitle || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  benefitsSubtitle: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Image URL"
              value={page.benefitsImageURL || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  benefitsImageURL: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
            />

            <TextField
              label="Bullet Points Text"
              value={page.benefitsBulletPointsText || ''}
              onChange={(e) => {
                setPage({
                  ...page,
                  benefitsBulletPointsText: e.target.value,
                });
              }}
              variant="outlined"
              margin="dense"
              type="text"
              className="day-text-field"
              multiline
              rows="4"
            />

            <div className="list-section-header-row">
              <Typography><strong>Bullet Points</strong></Typography>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  const bullets = page.benefitsBullets || [];

                  setPage({
                    ...page,
                    benefitsBullets: [ ...bullets, '' ],
                  });
                }}
              >
                Add Bullet Point
              </Button>
            </div>

            <List>
              {(page.benefitsBullets || []).map((bullet, i) => {
                return (
                  <ListItem key={`what-is-bullet-${i}`}>
                    <TextField
                      label={`Bullet Point ${i + 1}`}
                      value={bullet}
                      onChange={(e) => {
                        const benefitsBullets = [ ...page.benefitsBullets ];

                        benefitsBullets[i] = e.target.value;

                        setPage({
                          ...page,
                          benefitsBullets,
                        });
                      }}
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                    />

                    <IconButton
                      onClick={() => {
                        const benefitsBullets = [ ...page.benefitsBullets ];

                        benefitsBullets.splice(i, 1);

                        setPage({
                          ...page,
                          benefitsBullets,
                        });
                      }}
                      style={{marginLeft: 10}}
                      edge="start"
                      color="inherit"
                      aria-label="Edit"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditAcademyPlusCourseLandingPage;
