import { Button, FormControlLabel, IconButton, Popover, Switch, TextField } from '@material-ui/core';
import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { debounce } from 'lodash'
import './styles.scss'
import { ColorPicker, useColor } from '../../../../shared/colorPalette';

export const TreatBanner = (props) => {
    const { product, setProduct } = props

    const { treatBanner, enableTreatBanner } = product

    // Title Color
    const [titleColorAnchorElem, setTitleColorAnchorElem] = useState(null);
    const [titleColor, setTitleColor] = useColor('hex', product.treatBanner.colorBanner);

    const titleColorOpen = Boolean(titleColorAnchorElem);
    const titleColorId = titleColorOpen ? 'title-color-popover' : undefined;

    //Background Color

    const [backgroundColorAnchorElem, setBackgroundColorAnchorElem] = useState(null);
    const [backgroundColor, setBackgroundColor] = useColor('hex', product.treatBanner.colorBanner);

    const backgroundColorOpen = Boolean(backgroundColorAnchorElem);
    const backgroundColorId = backgroundColorOpen ? 'background-color-popover' : undefined;


    const changeColor = debounce((color) => {
        setProduct({
            ...product,
            treatBanner: {
                ...treatBanner,
                colorBanner: color
            }
        })
    }, 100)

    const changeTitleColor = debounce((color) => {
        setProduct({
            ...product,
            treatBanner: {
                ...treatBanner,
                titleColor: color
            }
        })
    }, 100)

    return (
        <div className="section-container">
            <div className="section-label">Treat Banner</div>
            <FormControlLabel
                style={{ marginTop: 8, marginBottom: 4 }}
                control={
                    <Switch
                        checked={enableTreatBanner || false}
                        onChange={(e) => {
                            setProduct({
                                ...product,
                                enableTreatBanner: e.target.checked,
                            });
                        }}
                        name="hasCustomThankYouPage"
                        color="primary"
                    />
                }
                label="Show Treat Banner"
            />
            <TextField
                label="Title"
                value={treatBanner.title}
                onChange={(e) => {
                    const text = e.target.value
                    setProduct({
                        ...product,
                        treatBanner: {
                            ...treatBanner,
                            title: text
                        }
                    })
                }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"

            />
            <div
                className="color-container"
                onClick={(e) => setTitleColorAnchorElem(e.currentTarget)}
            >
                <p>Title Color</p>

                <div
                    className="color-preview"
                    style={{ backgroundColor: product.treatBanner.titleColor }}
                ></div>
            </div>

            <Popover
                id={titleColorId}
                open={titleColorOpen}
                anchorEl={titleColorAnchorElem}
                onClose={() => setTitleColorAnchorElem(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ColorPicker
                    width={250}
                    height={125}
                    color={titleColor}
                    onChange={setTitleColor}
                    onChangeComplete={(color) => {
                        changeTitleColor(color.hex)
                    }}
                    hideHSV
                    hideRGB
                    alpha
                />
            </Popover>

            <div
                className="color-container"
                onClick={(e) => setBackgroundColorAnchorElem(e.currentTarget)}
            >
                <p>Background Banner Color</p>

                <div
                    className="color-preview"
                    style={{ backgroundColor: product.treatBanner.colorBanner }}
                ></div>
            </div>
            <Popover
                id={backgroundColorId}
                open={backgroundColorOpen}
                anchorEl={backgroundColorAnchorElem}
                onClose={() => setBackgroundColorAnchorElem(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ColorPicker
                    width={250}
                    height={125}
                    color={backgroundColor}
                    onChange={setBackgroundColor}
                    onChangeComplete={(color) => {
                        changeColor(color.hex)
                    }}
                    hideHSV
                    hideRGB
                    alpha
                />
            </Popover>

            <div className="section-container">
                <div className="section-label">Icons</div>
                {
                    treatBanner.icons.length < 6 &&
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setProduct({
                                ...product,
                                treatBanner: {
                                    ...treatBanner,
                                    icons: [...treatBanner.icons, '']
                                }
                            })
                        }}
                        color="primary"
                    >
                        Add Item
                    </Button>}

                {
                    treatBanner.icons.map((icon, index) => (
                        <div key={index} className="list-item" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <TextField
                                key={`icon-${index}`}
                                label={`Icon ${index + 1}`}
                                value={icon}
                                onChange={(e) => {
                                    const text = e.target.value
                                    const newIcons = [...treatBanner.icons]
                                    newIcons[index] = text
                                    setProduct({
                                        ...product,
                                        treatBanner: {
                                            ...treatBanner,
                                            icons: newIcons
                                        }
                                    })
                                }}
                                margin="dense"
                                variant="outlined"
                                type="text"
                                className="day-text-field"
                            />
                            <div style={{ width: 30 }}>
                                <IconButton onClick={() => {
                                    const newIcons = [...treatBanner.icons]
                                    newIcons.splice(index, 1)
                                    setProduct({
                                        ...product,
                                        treatBanner: {
                                            ...treatBanner,
                                            icons: newIcons
                                        }
                                    })

                                }} edge="start" color="inherit" aria-label="Edit" style={{ maxWidth: 20, marginLeft: 8 }}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

