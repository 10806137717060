import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { ColorPicker } from '../../../../../colorPicker/colorPicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const AcademySection = () => {
  const form = useFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Academy Section</strong>
        </Typography>
      </AccordionSummary>
      <Divider />

      <AccordionDetails
        style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div className="form-row">
              <Controller
                name="academySection.title"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Title"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.title)}
                      helperText={errors.academySection?.title?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="academySection.subtitle"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Subtitle"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.subtitle)}
                      helperText={errors.academySection?.subtitle?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="academySection.content"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Content"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.content)}
                      helperText={errors.academySection?.content?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="form-row">
              <Controller
                name="academySection.imageUrl"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Image Url"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.imageUrl)}
                      helperText={errors.academySection?.imageUrl?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <div className="section-container" style={{ margin: 0 }}>
                <div className="section-label">Image Position</div>
                <Controller
                  name="academySection.imagePosition"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          {...field}
                        >
                          <FormControlLabel
                            value="left"
                            control={<Radio />}
                            label="Left"
                          />
                          <FormControlLabel
                            value="right"
                            control={<Radio />}
                            label="Right"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-row">
              <Controller
                name="academySection.buttonText"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Button Text"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.buttonText)}
                      helperText={errors.academySection?.buttonText?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="form-row">
              <Controller
                name="academySection.buttonUrl"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Button Url"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.academySection?.buttonUrl)}
                      helperText={errors.academySection?.buttonUrl?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="academySection.buttonColor"
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      label="Button Color"
                      field={field}
                      error={Boolean(errors.academySection?.buttonColor)}
                      helperText={errors.academySection?.buttonColor?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="form-row">
              <Controller
                name="academySection.backgroundColor"
                control={control}
                render={({ field }) => {
                  return (
                    <ColorPicker
                      label="Background Color"
                      field={field}
                      error={Boolean(errors.academySection?.backgroundColor)}
                      helperText={
                        errors.academySection?.backgroundColor?.message
                      }
                    />
                  );
                }}
              />
            </div>
          </Box>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
