import React from 'react';
import { useDrag } from 'react-dnd';

const style = {
  display: 'inline-block',
  border: '1px dashed gray',
  borderRadius: 4,
  padding: '0.5rem 1rem',
  backgroundColor: 'white',
  cursor: 'move',
  margin: '5px',
  textAlign: 'center',
};

function Box(props) {
  const [, drag] = useDrag({
    item: { ...props.item },
  });
  return (
    <div ref={drag} style={{ ...style, width: props.item.id ? 269 : 129 }}>
      {`${props.item.title}${props.item.global ? ' (global)' : ''}`}
    </div>
  );
}

export default Box;
