import React, { useState, useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Slide,
  CircularProgress,
  Card,
  Toolbar,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import EditBehavior from './editBehaviors/editBehavior';

import { config } from '../../../config';

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisualSearch = (props) => {
  const [loading, setLoading] = useState(true);
  const [simplifiedData, setSimplifiedData] = useState({
    articles: [],
    modules: [],
    products: [],
  });
  const [searchData, setSearchData] = useState({});
  const [sections, setSections] = useState([]);
  const [subSectionData, setSubSectionData] = useState([]);
  const [showAddModal, setShowAddModal] = useState({show:false, index:0});
  const [showAddBehaviorModal, setShowAddBehaviorModal] = useState(false);
  const [addBehaviorSection, setAddBehaviorSection] = useState('');
  const [addBehaviorWebPath, setAddBehaviorWebPath] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState({section: -1, item: -1});
  const [currentEditItem, setCurrentEditItem] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState({index:-1, type: ''});

  useEffect(() => {
    fetchBehaviorSections();
  }, []);

  const fetchBehaviorSections = async () => {
    try {
      const querySnapshot =  (await firebase.firestore().collection('config').doc('search').get()).data();

      const sections = querySnapshot.behaviors;
      const subSections = querySnapshot.subSections;
      const simplifiedData = {
        articles: [],
        modules: [],
        products: [],
      };

      const token = await firebase.auth().currentUser.getIdToken();
      const simplifiedBlogPostsResponse = await axios(`${config.gateway}/blog-service/api/v1/blog-posts/simplified`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const simplifiedCourseModulesResponse = await axios(`${config.gateway}/course-service/v1/courses/modules/simplified`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const shopPageDataSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();

      simplifiedData.products = shopPageDataSnapshot.data().simplifiedProducts;
      simplifiedData.articles = simplifiedBlogPostsResponse.data;
      simplifiedData.modules = simplifiedCourseModulesResponse.data;

      setSections(sections);
      setSearchData(querySnapshot);
      setSubSectionData(subSections);
      setSimplifiedData(simplifiedData);
      setLoading(false);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving behaviors sections, please try again.');
      setLoading(false);
    }
  };

  const savePopup = async (popup) => {
    setEditOpen(false);
    setLoading(true);

    try {
      
      if(currentEditIndex.type === 'behaviors'){
        const sectionsCopy = cloneDeep(sections);
        sectionsCopy.splice(currentEditIndex.index, 1, popup);

        await firebase.firestore().collection('config').doc('search').update({behaviors: sectionsCopy});
        setSections(sectionsCopy);
      } else if (currentEditIndex.type = 'subSections') {
        const subSectionDataCopy = [ ...subSectionData ];
        const subSectionDataItemsCopy = [ ...subSectionDataCopy[currentEditIndex.subIndex].items ];

        subSectionDataItemsCopy.splice(currentEditIndex.index, 1, popup);
        subSectionDataCopy[currentEditIndex.subIndex].items = subSectionDataItemsCopy;

        await firebase.firestore().collection('config').doc('search').update({subSections: subSectionDataCopy});

        setSubSectionData(subSectionDataCopy);
      }
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this section, please try again.');
    }

    setLoading(false);
    closeEditModal();
  };

  const closeEditModal = () => {
    setEditOpen(false);
    setCurrentEditIndex({index:-1, type:''});
  };
  
  const addCategorySection = async (i) => {
    setLoading(true);

    try {
      const id = uuidv4();
      const sectionToAdd = {
        id,
        urlPath: addBehaviorWebPath,
        imageUrl:'',
        title: addBehaviorSection,
        videoid: '',
        module: [],
        articles: [],
        products: [],
      };

      const newSubSectionData = cloneDeep(subSectionData);
      const updatedSection = {...newSubSectionData[i], items: [...newSubSectionData[i].items, sectionToAdd]};

      newSubSectionData.splice(i, 1, updatedSection);

      await firebase.firestore().collection('config').doc('search').update({subSections: newSubSectionData});

      setSubSectionData(newSubSectionData); 
      setAddBehaviorSection('');
      setAddBehaviorWebPath('');
      setShowAddModal({show:false, index:0});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this section, please try again.');
    }
  };

  const addBehavior = async () => {
    setLoading(true);

    try {
      const id = uuidv4();
      const sectionToAdd = {
        id,
        urlPath: addBehaviorWebPath,
        imageUrl:'',
        title: addBehaviorSection,
        videoid: '',
        module: [],
        articles: [],
        products: [],
      };

      const newSection = cloneDeep(sections);
      newSection.push(sectionToAdd);

      await firebase.firestore().collection('config').doc('search').update({behaviors: newSection});

      setSections(newSection);
      setAddBehaviorSection('');
      setAddBehaviorWebPath('')
      setShowAddBehaviorModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this section, please try again.');
    }
  };

  const addCategory = async() =>{
    setLoading(true);

    try {
      const id = uuidv4();
      const categoryToAdd = {
        id,
        title: addBehaviorSection,
        items : []
      };
     
      const updatedSection = cloneDeep(subSectionData);
      updatedSection.push(categoryToAdd);

      await firebase.firestore().collection('config').doc('search').update({subSections: updatedSection});

      setSubSectionData(updatedSection);
      setAddBehaviorSection('');
      setShowAddCategoryModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this category, please try again.');
    }
  };

  const deleteCategory = async() => {
    try {
      const newSubSection = cloneDeep(subSectionData)
      
      newSubSection.splice(deleteIndex.section, 1);

      await firebase.firestore().collection('config').doc('search').update({subSections: newSubSection});

      setSubSectionData(newSubSection)
      setDeleteIndex({section:-1, item: -1});
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this category, please try again.');
    }
  };

  const deleteItem = async() => {
    try {
      
      const newSubSectionData = cloneDeep(subSectionData)
      newSubSectionData[deleteIndex.section].items.splice(deleteIndex.item, 1);

      await firebase.firestore().collection('config').doc('search').update({subSections: newSubSectionData});

      setSubSectionData(newSubSectionData)
      setDeleteIndex({section:-1, item: -1});
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this category, please try again.');
    }
  };

  const deleteBehviorItem = async() => {
    try {
      const newSection = cloneDeep(sections)
      newSection.splice(deleteIndex.item, 1);

      await firebase.firestore().collection('config').doc('search').update({behaviors: newSection});

      setSections(newSection)
      setDeleteIndex({section:-1, item: -1});
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this behavior, please try again.');
    }
  };

  const isNewUrlUnique = (value) => {
    const path = value || addBehaviorWebPath;
    const currentPath = currentEditItem.urlPath || '';

    if (value && currentPath && editOpen && currentPath === value) {
      return true;
    }

    if (!path.length) {
      return false;
    }

    let check = true;

    sections.forEach(c =>{
      if(c.urlPath === path){
        check = false;
      }
    });

    subSectionData.forEach(section => {
      section.items.forEach(c =>{
        if(c.urlPath === path){
          check = false;
        }
      });
    });  
     
    return check;
  };

  const moveItemUp = async (i) => {
    setLoading(true);
    const updatedSection = cloneDeep(sections);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i - 1];

    updatedSection[i] = item2;
    updatedSection[i - 1] = item1;

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({behaviors: updatedSection});

      setSections(updatedSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the behaviors sections. Please refresh and try again.');
    }
  };

  const moveItemDown = async (i) => {
    setLoading(true);
    const updatedSection = cloneDeep(sections);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i + 1];

    updatedSection[i] = item2;
    updatedSection[i + 1] = item1;

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({behaviors: updatedSection});

      setSections(updatedSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the behaviors sections. Please refresh and try again.');
    }
  };

  const moveSubItemUp = async (i, index) => {
    setLoading(true);
    const updatedSection = cloneDeep(subSectionData[index].items);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i - 1];

    updatedSection[i] = item2;
    updatedSection[i - 1] = item1;

    const newSubSection = cloneDeep(subSectionData);
    newSubSection[index].items = updatedSection;

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({subSections: newSubSection});

      setSubSectionData(newSubSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the sections. Please refresh and try again.');
    }
  };

  const moveSubItemDown = async (i, index) => {
    setLoading(true);
    const updatedSection = cloneDeep(subSectionData[index].items);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i + 1];

    updatedSection[i] = item2;
    updatedSection[i + 1] = item1;

    const newSubSection = cloneDeep(subSectionData)
    newSubSection[index].items = updatedSection

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({subSections: newSubSection});

      setSubSectionData(newSubSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering the sections. Please refresh and try again.');
    }
  };

  const moveCategoryUp = async (i) => {
    setLoading(true);
    const updatedSection = cloneDeep(subSectionData);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i - 1];

    updatedSection[i] = item2;
    updatedSection[i - 1] = item1;

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({subSections: updatedSection});

      setSubSectionData(updatedSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering Categories sections. Please refresh and try again.');
    }
  };

  const moveCategoryDown = async (i) => {
    setLoading(true);
    const updatedSection = cloneDeep(subSectionData);
    const item1 = updatedSection[i];
    const item2 = updatedSection[i + 1];

    updatedSection[i] = item2;
    updatedSection[i + 1] = item1;

    try {
      const db = firebase.firestore();
      await db.collection('config').doc('search').update({subSections: updatedSection});

      setSubSectionData(updatedSection);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error reordering Categories sections. Please refresh and try again.');
    }
  };

  const renderReorderArrows = (i, index, arrayLength) => {
    const arrowItems = [];

    if (i !== 0) {
      arrowItems.push(
        <IconButton onClick={() => { index === -1 ?  moveItemUp(i) : moveSubItemUp(i, index); }} key={`up-arrow-${i}`} color="inherit" aria-label="Move Up">
          <ArrowUpwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    if (i !== arrayLength - 1) {
      arrowItems.push(
        <IconButton onClick={() => { index === -1 ?  moveItemDown(i) : moveSubItemDown(i, index); }} key={`down-arrow-${i}`} color="inherit" aria-label="Move Down">
          <ArrowDownwardIcon style={{color: '#000'}} />
        </IconButton>
      );
    }

    return arrowItems;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div style={{textAlign: 'center', marginBottom: 50}}>
      {renderLoading()}

      <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Behaviors Sections
          </Typography>

          <Button 
            variant="contained"
            color="primary"
            onClick={() => { setShowAddBehaviorModal(true); }}
          >
            Add Behaviors Section
          </Button>
        </Toolbar>

        <List>
          {sections.map((course, i) => {
            return (
              <div key={`course-${i}`}>
                <Divider />
                <ListItem>
                  <ListItemText primary={course.title} />

                  {renderReorderArrows(i, -1, sections.length)}

                  
                  <IconButton color="inherit" aria-label="Edit"onClick={() => {
                    setCurrentEditItem(course);
                    setEditOpen(true);
                    setCurrentEditIndex({index:i, type:'behaviors'});
                  }}>
                    <EditIcon style={{color: '#000'}} />
                  </IconButton>
                  <IconButton onClick={() => {
                        setDeleteIndex({section:-1, item: i});
                      }} edge="start" color="inherit" aria-label="Edit">
                        <DeleteIcon />
                      </IconButton>
                  
                </ListItem>
              </div>
            );
          })}
        </List>
      </Card>
      <Button 
        variant="contained"
        color="primary"
        onClick={() => { setShowAddCategoryModal(true); }}
        style={{marginTop:20}}
      >
       Add Category
      </Button>
      {subSectionData ? subSectionData.map((section, index) => {
        return(
          <Card className="drag-card" key={index} style={{marginTop: '50px', display: 'inline-block'}}>
            <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="h6">
                {`${section.title} Sections`}
              </Typography>
              <div>
                <Button 
                  variant="contained"
                  color="primary"
                  onClick={() => { setShowAddModal({show:true, index:index}); }}
                >
                  {`Add ${section.title} Section`}
                </Button>
                {index > 0 ?
                  <IconButton onClick={() => moveCategoryUp(index)} color="inherit" aria-label="Move Up">
                  <ArrowUpwardIcon style={{color: '#000'}} />
                </IconButton> : null}
                {index === subSectionData.length - 1 ? null :
                  <IconButton onClick={() => moveCategoryDown(index)} color="inherit" aria-label="Move Up">
                  <ArrowDownwardIcon style={{color: '#000'}} />
                </IconButton>}
                <IconButton onClick={() => {
                  setDeleteIndex({section:index, item: -1});}} 
                  edge="start"
                  color="inherit"
                  aria-label="Edit"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Toolbar>
            <List>
              {section.items.map((course, i) => {
                return (
                  <div key={`course-${i}`}>
                    <Divider />
                    <ListItem>
                      <ListItemText primary={course.title} />

                      {renderReorderArrows(i, index, section.items.length)}

                      <IconButton color="inherit" aria-label="Edit" onClick={() => {
                        setCurrentEditItem(course);
                        setEditOpen(true);
                        setCurrentEditIndex({subIndex: index, index:i, type:'subSections'});
                      }}>
                        <EditIcon style={{color: '#000'}} />
                      </IconButton>
                      <IconButton onClick={() => {
                        setDeleteIndex({section:index, item: i});
                      }} edge="start" color="inherit" aria-label="Edit">
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </Card>
        );
      }) : null }

      <Dialog fullScreen open={editOpen} onClose={() => setEditOpen(false)} TransitionComponent={Transition}>
        <EditBehavior
          closeModal={closeEditModal}
          popup={currentEditItem}
          savePopup={savePopup}
          simplifiedData={simplifiedData}
          isNewUrlUnique={isNewUrlUnique}
        ></EditBehavior>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex.section > -1 && deleteIndex.item === -1} onClose={() => { setDeleteIndex({section:-1, item: -1}); }} TransitionComponent={Transition}>
        <DialogTitle>Are you sure you want to delete this Section?</DialogTitle>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            onClick={deleteCategory}
          >Yes</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setDeleteIndex({section:-1, item: -1}); }}
          >cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={deleteIndex.item > -1} onClose={() => { setDeleteIndex({section:-1, item: -1}); }} TransitionComponent={Transition}>
        <DialogTitle>Are you sure you want to delete this Item?</DialogTitle>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            onClick={() => deleteIndex.section === -1 ? deleteBehviorItem() : deleteItem()}
          >Yes</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setDeleteIndex({section:-1, item: -1}); }}
          >cancel</Button>
        </DialogActions>
      </Dialog>
      
      <Dialog fullWidth maxWidth="sm" open={showAddBehaviorModal} onClose={() => { setShowAddBehaviorModal(false); }} TransitionComponent={Transition}>
        <DialogTitle>Add Section</DialogTitle>

        <DialogContent>
          <TextField
            label="Section Name"
            value={addBehaviorSection}
            onChange={(e) => { setAddBehaviorSection(e.target.value); }}
            margin="dense"
            variant="outlined"
            className="day-text-field"
          />
           
          <FormControl variant="outlined" style={{marginTop: 18, marginBottom: 4, width: '100%'}}>
            <InputLabel>URL Path</InputLabel>
            <OutlinedInput
              value={addBehaviorWebPath}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setAddBehaviorWebPath(value.toLowerCase());
              }}
              margin="dense"
              label="URL Path"
              variant="outlined"
              type="text"
              error={(addBehaviorWebPath !== '' && !isNewUrlUnique())}
              startAdornment={<InputAdornment style={{marginRight: 0}} position="start">browse/</InputAdornment>}
            />
            {(isNewUrlUnique() || addBehaviorWebPath === '') ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!addBehaviorSection || !addBehaviorWebPath || !isNewUrlUnique()}
            onClick={addBehavior}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddBehaviorModal(false); }}
          >cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={showAddModal.show} onClose={() => { setShowAddModal({show:false, index:0}); }} TransitionComponent={Transition}>
        <DialogTitle>Add Section</DialogTitle>

        <DialogContent>
          <TextField
            label="Section Name"
            value={addBehaviorSection}
            onChange={(e) => { setAddBehaviorSection(e.target.value); }}
            margin="dense"
            variant="outlined"
            className="day-text-field"
          />
           
          <FormControl variant="outlined" style={{marginTop: 18, marginBottom: 4, width: '100%'}}>
            <InputLabel>URL Path</InputLabel>
            <OutlinedInput
              value={addBehaviorWebPath}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                setAddBehaviorWebPath(value.toLowerCase());
              }}
              margin="dense"
              label="URL Path"
              variant="outlined"
              type="text"
              error={(addBehaviorWebPath !== '' && !isNewUrlUnique())}
              startAdornment={<InputAdornment style={{marginRight: 0}} position="start">browse/</InputAdornment>}
            />
            {(isNewUrlUnique() || addBehaviorWebPath === '') ? null : <FormHelperText><small style={{color: 'red'}}>URL must be unique</small></FormHelperText>}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!addBehaviorSection || !addBehaviorWebPath || !isNewUrlUnique()}
            onClick={() => addCategorySection(showAddModal.index)}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddModal({show:false, index:0}); }}
          >cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={showAddCategoryModal} onClose={() => { setShowAddCategoryModal(false); }} TransitionComponent={Transition} >
        <DialogTitle>Add Category</DialogTitle>

        <DialogContent>
          <TextField
            label="Category Name"
            value={addBehaviorSection}
            onChange={(e) => { setAddBehaviorSection(e.target.value); }}
            margin="dense"
            variant="outlined"
            className="day-text-field"
          />
           
        
        </DialogContent>

        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            disabled={!addBehaviorSection}
            onClick={addCategory}
          >Add</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowAddCategoryModal(false); }}
          >cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => { setModalText(''); }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText(''); }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default VisualSearch;