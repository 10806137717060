import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './shopItemsForm.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ColorPicker } from '../../../../colorPicker/colorPicker';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItems } from './menuItems/menuItems';

export const ShopItemsForm = ({ isMobile }) => {
  const form = useFormContext();

  const {
    control,
    fields,
    addItem,
    removeItem,
    moveItem,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Shop Products</strong>
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {isMobile ? (
              <>
                <Controller
                  name="shopMenusMainButtonText"
                  control={control}
                  render={({ field: inputField }) => (
                    <TextField
                      {...inputField}
                      className="text-field"
                      onClick={(e) => e.stopPropagation()}
                      label="Main Button Text"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      error={Boolean(errors.shopMenusMainButtonText)}
                      helperText={errors.shopMenusMainButtonText?.message}
                    />
                  )}
                />

                <Controller
                  name="shopMenusMainButtonUrl"
                  control={control}
                  render={({ field: inputField }) => (
                    <TextField
                      {...inputField}
                      className="text-field"
                      onClick={(e) => e.stopPropagation()}
                      label="Main Button Url"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      error={Boolean(errors.shopMenusMainButtonUrl)}
                      helperText={errors.shopMenusMainButtonUrl?.message}
                    />
                  )}
                />
              </>
            ) : null}

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => addItem()}
                type="button"
              >
                Add Menu
              </Button>
            </Box>

            <div className="menu-form">
              {fields.map((field, index) => {
                return (
                  <Card key={field.id} variant="outlined">
                    <Toolbar
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">
                        <strong>Menu {index + 1}</strong>
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '8px',
                        }}
                      >
                        {index !== fields.length - 1 ? (
                          <IconButton
                            color="inherit"
                            type="button"
                            onClick={(e) => {
                              moveItem(index, index + 1);
                            }}
                          >
                            <ArrowDownIcon />
                          </IconButton>
                        ) : null}

                        {index !== 0 ? (
                          <IconButton
                            type="button"
                            color="inherit"
                            onClick={(e) => {
                              moveItem(index, index - 1);
                            }}
                          >
                            <ArrowUpIcon />
                          </IconButton>
                        ) : null}

                        {index !== 0 ? (
                          <IconButton
                            type="button"
                            color="inherit"
                            aria-label="Delete"
                            onClick={(e) => {
                              removeItem(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Toolbar>

                    <CardContent>
                      <Accordion
                        className="accordion-container"
                        defaultExpanded
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Controller
                              name={`shopMenus.${index}.title`}
                              control={control}
                              render={({ field: inputField }) => (
                                <TextField
                                  {...inputField}
                                  className="text-field"
                                  onClick={(e) => e.stopPropagation()}
                                  label="Title"
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  error={Boolean(
                                    errors.shopMenus?.[index]?.title,
                                  )}
                                  helperText={
                                    errors.shopMenus?.[index]?.title?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              width: '100%',
                            }}
                          >
                            <Controller
                              name={`shopMenus.${index}.isLive`}
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      {...field}
                                      checked={field.value}
                                      name="live"
                                      color="primary"
                                    />
                                  }
                                  label="Live"
                                />
                              )}
                            />

                            {isMobile ? (
                              <Controller
                                name={`shopMenus.${index}.titleBackground`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <ColorPicker
                                      field={field}
                                      label="Header Section Background Color"
                                      error={Boolean(
                                        errors.shopMenus?.[index]
                                          ?.titleBackground,
                                      )}
                                      helperText={
                                        errors.shopMenus?.[index]
                                          ?.titleBackground?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            ) : null}

                            <Controller
                              name={`shopMenus.${index}.titleColor`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <ColorPicker
                                    field={field}
                                    label="Title Color"
                                    error={Boolean(
                                      errors.shopMenus?.[index]?.titleColor,
                                    )}
                                    helperText={
                                      errors.shopMenus?.[index]?.titleColor
                                        ?.message
                                    }
                                  />
                                );
                              }}
                            />

                            <Controller
                              name={`shopMenus.${index}.subtitle`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="Subtitle"
                                  className="text-field"
                                  variant="outlined"
                                  margin="dense"
                                  error={Boolean(
                                    errors.shopMenus?.[index]?.subtitle,
                                  )}
                                  helperText={
                                    errors.shopMenus?.[index]?.subtitle?.message
                                  }
                                />
                              )}
                            />

                            <Controller
                              name={`shopMenus.${index}.shopAllUrl`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="text-field"
                                  label="Shop All Url"
                                  variant="outlined"
                                  margin="dense"
                                  error={Boolean(
                                    errors.shopMenus?.[index]?.shopAllUrl,
                                  )}
                                  helperText={
                                    errors.shopMenus?.[index]?.shopAllUrl
                                      ?.message
                                  }
                                />
                              )}
                            />

                            <MenuItems
                              control={control}
                              menuIndex={index}
                              errors={errors}
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </Box>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
