import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';
import { useSubmenuItemsFields } from './useSubmenuItemsFields';
import { Controller } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import './submenuItems.scss';

export const SubmenuItems = ({ control, menuIndex, menuItemIndex }) => {
  const {
    submenuItems,
    moveSubmenuItem,
    appendSubmenuItem,
    removeSubmenuItem,
  } = useSubmenuItemsFields(control, menuIndex, menuItemIndex);

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={() => appendSubmenuItem()}
          type="button"
        >
          Add Submenu Item
        </Button>
      </Box>

      {submenuItems.length > 0 ? (
        <div className="sub-section-container">
          <div className="sub-section-label">Submenu Items</div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              padding: '12px 8px',
            }}
          >
            {submenuItems.map((item, index) => (
              <Card
                key={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'center',
                }}
                variant="outlined"
              >
                <Toolbar
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    <strong>Submenu Item {index + 1}</strong>
                  </span>

                  <Box
                    sx={{
                      gap: '8px',
                      display: 'flex',
                    }}
                  >
                    {index !== submenuItems.length - 1 ? (
                      <IconButton
                        color="inherit"
                        onClick={() => moveSubmenuItem(index, index + 1)}
                      >
                        <ArrowDownIcon />
                      </IconButton>
                    ) : null}

                    {index !== 0 ? (
                      <IconButton
                        color="inherit"
                        onClick={() => moveSubmenuItem(index, index - 1)}
                      >
                        <ArrowUpIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                </Toolbar>

                <Divider />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      width: '100%',
                    }}
                  >
                    <Controller
                      name={`shopMenus.${menuIndex}.menuItems.${menuItemIndex}.submenuItems.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Name"
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                    />
                    <Controller
                      name={`shopMenus.${menuIndex}.menuItems.${menuItemIndex}.submenuItems.${index}.url`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Url"
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: '8px',
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      onClick={() => removeSubmenuItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </div>
      ) : null}
    </Box>
  );
};
