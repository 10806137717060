import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './subscriptionsPage.scss';
import DeletableChip from '../../shared/deletableChip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SubscriptionsPage({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [subscriptionPageData, setSubscriptionPageData] = useState({
    mainImageUrl: '',
    linkToProduct: false,
    productId: '',
    recommendedProductsDiscountPercentage: '',
    recommendedProducts: [],
  });
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);

  useEffect(() => {
    fetchSubscriptionPageData();
  }, []);

  const fetchSubscriptionPageData = async () => {
    try {
      const subscriptionPageDataSnapshot = await firebase.firestore().collection('config').doc('subscriptions-page-data').get();
      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const subscriptionPageData = subscriptionPageDataSnapshot.data();
      const simplifiedProducts = shopSettingsSnapshot.data().simplifiedProducts;

      if (!subscriptionPageData || !simplifiedProducts || !simplifiedProducts.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error retrieving the shop page data, refresh the page and try again.');
        return;
      }

      setSubscriptionPageData(subscriptionPageData);
      setSimplifiedProducts(simplifiedProducts.filter(p => {
        return p.active && !p.isDigital;
      }).sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the shop page data, refresh the page and try again.');
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      await firebase.firestore().collection('config').doc('subscriptions-page-data').update(subscriptionPageData);

      setLoading(false);
      setModalTitle('Success!');
      setModalText('The Subscription Page configuration has been updated successfully. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving the Subscription Page configuration, please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="SubscriptionsPage">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Subscriptions Page
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={save}
        >
          Save
        </Button>
      </Toolbar>

      <div className="content-container">
        <div className="section-container">
          <div className="section-label">
            Main Image
          </div>

          <TextField
            label="Image URL"
            value={subscriptionPageData.mainImageUrl || ''}
            onChange={(e) => {
              setSubscriptionPageData({ ...subscriptionPageData, mainImageUrl: e.target.value });
            }}
            margin="dense"
            variant="outlined"
            type="text"
            className="day-text-field"
          />

          <FormControlLabel
            style={{ marginTop: 10 }}
            control={
              <Switch
                checked={subscriptionPageData.linkToProduct || false}
                onChange={(e) => {
                  setSubscriptionPageData({
                    ...subscriptionPageData,
                    linkToProduct: e.target.checked,
                  });
                }}
                name="linkToProduct"
                color="primary"
              />
            }
            label="Link To Product"
          />

          {!subscriptionPageData.linkToProduct ? null :
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel>
                Product
              </InputLabel>
              <Select
                style={{width: '100%'}}
                value={subscriptionPageData.productId}
                onChange={(e) => {
                  setSubscriptionPageData({ ...subscriptionPageData, productId: e.target.value });
                }}
                label="Product"
              >
                {simplifiedProducts.map((product, i) => {
                  return <MenuItem key={`option-${i}`} value={product.id}>{product.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        </div>

        <div className="section-container">
          <div className="section-label">
            Recommended Products
          </div>

          <FormControl variant="outlined" style={{marginTop: 18, marginBottom: 4}}>
            <InputLabel>Discount Percentage</InputLabel>
            <OutlinedInput
              value={subscriptionPageData.recommendedProductsDiscountPercentage || '0'}
              onChange={(e) => {
                let value = +e.target.value;

                if (value > 100) {
                  value = 100;
                }

                if (value < 0) {
                  value = 0;
                }

                setSubscriptionPageData({
                  ...subscriptionPageData,
                  recommendedProductsDiscountPercentage: value.toString(),
                });
              }}
              margin="dense"
              variant="outlined"
              type="number"
              label="Discount Percentage"
              className="day-text-field"
              endAdornment={<InputAdornment position="start">%</InputAdornment>}
            />
          </FormControl>

          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Default Recommended Products</InputLabel>
            <Select
              multiple
              value={subscriptionPageData.recommendedProducts}
              onChange={(e) => {
                setSubscriptionPageData({
                  ...subscriptionPageData,
                  recommendedProducts: e.target.value,
                });
              }}
              input={<Input />}
              renderValue={selected => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {selected.map(value => (
                    <DeletableChip
                      key={value.id}
                      label={value.name}
                      style={{margin: 2}}
                      color="primary"
                      onDelete={() => {
                        const index = subscriptionPageData.recommendedProducts.indexOf(value);

                        const recommendedProducts = [ ...subscriptionPageData.recommendedProducts ];

                        recommendedProducts.splice(index, 1);

                        setSubscriptionPageData({
                          ...subscriptionPageData,
                          recommendedProducts,
                        });
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {simplifiedProducts.filter(simpleProduct => {
                const found = subscriptionPageData.recommendedProducts.find(p => p.id === simpleProduct.id);
                return !found;
              }).map(simpleProduct => (
                <MenuItem key={simpleProduct.id} value={simpleProduct}>
                  {simpleProduct.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <Dialog fullWidth open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionsPage;
