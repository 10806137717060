import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CardContent,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const PageDetailsSection = () => {
  const form = useFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Accordion className="accordion-main-container" elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          <strong>Page Details Section</strong>
        </Typography>
      </AccordionSummary>
      <Divider />

      <AccordionDetails
        style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div className="form-row">
              <Controller
                name="pageDetailsSection.title"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Title"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.pageDetailsSection?.title)}
                      helperText={errors.pageDetailsSection?.title?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="form-row">
              <Controller
                name="pageDetailsSection.description"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Description"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      className="day-text-field"
                      error={Boolean(errors.pageDetailsSection?.description)}
                      helperText={
                        errors.pageDetailsSection?.description?.message
                      }
                    />
                  );
                }}
              />
            </div>
          </Box>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
