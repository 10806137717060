import { useFieldArray } from 'react-hook-form';

export const useMenuItemsFields = (control, menuIndex) => {
  const {
    append,
    remove,
    fields: menuItems,
    move: moveMenuItem,
  } = useFieldArray({
    control: control,
    name: `shopMenus.${menuIndex}.menuItems`,
  });

  const appendMenuItem = () => {
    append({
      name: '',
      url: '',
    });
  };

  const removeMenuItem = (index) => {
    remove(index);
  };

  return {
    menuItems,
    appendMenuItem,
    removeMenuItem,
    moveMenuItem,
  };
};
