import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  CircularProgress,
  Toolbar,
  Typography,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import './dashboard.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [config, setConfig] = useState({
    sidebar: '',
  });
  const [sidebars, setSidebars] = useState([]);
  const [updateSidebarOpen, setUpdateSidebarOpen] = useState(false);
  const [selectedSidebar, setSelectedSidebar] = useState('');

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    const db = firebase.firestore();

    try {
      const configSnapshot = await db.collection('config').doc('blog').get();
      const sidebarsSnapshot = await db.collection('sidebars').get();

      const sidebarsData = sidebarsSnapshot.docs.map(doc => {
        return {
          id: doc.id,
          description: doc.data().description,
        };
      });

      setConfig(configSnapshot.data());
      setSidebars(sidebarsData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error fetching blog dashboard data. Please try again.');
    }
  };

  const submitUpdateSidebar = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      await db.collection('config').doc('blog').update({
        sidebar: selectedSidebar,
      });

      setConfig({
        ...config,
        sidebar: selectedSidebar,
      });
      setLoading(false);
      setUpdateSidebarOpen(false);
      setModalTitle('Success!');
      setModalText('The modal sidebar has been updated. To make these changes live, go into the Website Content Dashboard and hit Deploy Website.');
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating the sidebar. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="BlogDashboard">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Blog Dashboard
        </Typography>
      </Toolbar>
      <div className="content">
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            setSelectedSidebar(config.sidebar);
            setUpdateSidebarOpen(true);
          }}
        >
          Update Blog Sidebar
        </Button>
      </div>

      <Dialog fullWidth maxWidth="sm" open={updateSidebarOpen} onClose={() => { setUpdateSidebarOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Update Sidebar</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel>Select Sidebar</InputLabel>
            <Select
              value={selectedSidebar}
              onChange={(e) => {
                setSelectedSidebar(e.target.value);
              }}
              label="Select Sidebar"
            >
              {sidebars.map((sidebar, i) => {
                return <MenuItem key={`sidebar-${i}`} value={sidebar.id}>{sidebar.description}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitUpdateSidebar} color="primary">
            Update
          </Button>
          <Button variant="contained" onClick={() => { setUpdateSidebarOpen(false) }} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
