import React, { useState, useEffect, useReducer } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress,
  Slide,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './wholesaleProducts.scss';
import { setWholesaleProducts } from '../../../actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WholesaleProducts(props) {
  const dispatch = useDispatch();
  const wholesaleProductsInitialized = useSelector(state => state.wholesaleProducts.initialized);
  const wholesaleProducts = useSelector(state => state.wholesaleProducts.products);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [addProductOpen, setAddProductOpen] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    fetchWholesaleProducts();
  }, []);

  const fetchWholesaleProducts = async () => {
    try {
      const shopSettingsSnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const shopSettingsData = shopSettingsSnapshot.data();
      const sortedSimplifiedProducts = shopSettingsData.simplifiedProducts.filter(p => {
        return !p.isDigital;
      }).map(p => {
        return {
          name: p.name,
          id: p.id,
          price: p.price,
        };
      }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
  
        return 0;
      });

      setSimplifiedProducts(sortedSimplifiedProducts);

      if (wholesaleProductsInitialized) {
        setLoading(false);
        return;
      }

      const wholesaleProductsSnapshot = await firebase.firestore().collection('wholesale').doc('productData').get();
      const wholesaleProductsData = wholesaleProductsSnapshot.data();

      dispatch(setWholesaleProducts(wholesaleProductsData.products));
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error initializing wholesale products. Please refresh the page and try again.');
    }
  };

  const addWholesaleProduct = async () => {
    setLoading(true);

    try {
      const selectedSimplifiedProduct = simplifiedProducts[selectedProductIndex];

      const products = [
        ...wholesaleProducts,
        {
          ...selectedSimplifiedProduct,
          active: false,
          msrp: selectedSimplifiedProduct.price,
        },
      ];

      await firebase.firestore().collection('wholesale').doc('productData').update({
        products,
      });

      setLoading(false);
      dispatch(setWholesaleProducts(products));
      props.history.push(`/wholesale/products/${selectedSimplifiedProduct.id}`);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the new wholesale product, lease try again.');
    }
  };

  const deleteProduct = async () => {
    setLoading(true);

    try {
      const products = [ ...wholesaleProducts ];
      products.splice(deleteIndex, 1);

      await firebase.firestore().collection('wholesale').doc('productData').update({
        products,
      });

      setDeleteIndex(-1);
      setLoading(false);
      dispatch(setWholesaleProducts(products));
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error adding the new wholesale product, lease try again.');
    }
  };

  const getActiveMessage = (active) => {
    if (active) {
      return <span className="stock-status" style={{color: '#7ED242'}}>Active</span>;
    }

    return <span className="stock-status" style={{color: 'orange'}}>Inactive</span>;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="WholesaleProducts">
      {renderLoading()}

      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Wholesale Products
          </Typography>

          <IconButton edge="start" color="inherit" onClick={() => {
            setSelectedProductIndex(0);
            setAddProductOpen(true);
          }} aria-label="Add">
            <AddIcon />
          </IconButton>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>MSRP</strong></TableCell>
                <TableCell><strong>Wholesale Price</strong></TableCell>
                <TableCell padding="checkbox"></TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wholesaleProducts.map((p, i) => (
                <TableRow key={p.id}>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>{getActiveMessage(p.active)}</TableCell>
                  <TableCell>${(+p.msrp).toFixed(2)}</TableCell>
                  <TableCell>${(+p.price).toFixed(2)}</TableCell>
                  <TableCell padding="checkbox">
                    <Link to={`/wholesale/products/${p.id}`}>
                      <IconButton edge="start" style={{marginLeft: 10, color: '#000'}} color="default" aria-label="edit">
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton edge="start" style={{marginLeft: 10}} color="inherit" onClick={() => { setDeleteIndex(i) }} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={addProductOpen}
        onClose={() => { setAddProductOpen(false) }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add Wholesale Product</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" className="day-text-field">
            <InputLabel>Select Product</InputLabel>
            <Select
              value={selectedProductIndex}
              onChange={(e) => {
                setSelectedProductIndex(e.target.value);
              }}
              label="Select Product"
            >
              {simplifiedProducts.map((p, i) => {
                return <MenuItem key={`product-option-${i}`} value={i}>{p.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addWholesaleProduct} color="secondary">
            Submit
          </Button>
          <Button variant="contained" onClick={() => { setAddProductOpen(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteIndex !== -1} onClose={() => { setDeleteIndex(-1) }} TransitionComponent={Transition}>
        <DialogTitle>Remove Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove {deleteIndex !== -1 && wholesaleProducts[deleteIndex].name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteProduct} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WholesaleProducts;
