import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Tabs,
  Tab,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  DialogContentText,
  Slide,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import ChipInput from 'material-ui-chip-input';

import DeletableChip from '../../../shared/deletableChip';
import PageBuilder from '../../../shared/pageBuilder';

import './editOneClickUpsell.scss';

const cloneDeep = require('lodash.clonedeep');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ruleTypes = [
  'Always',
  'Coupon Code',
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
  'Subtotal is Less Than',
  'Subtotal is Greater Than',
];
const showProductTypes = [
  'Cart Contains These Products',
  'Cart Does Not Contain These Products',
  'Customer Has Purchased',
  'Customer Has Not Purchased',
];
const showCategoryTypes = [
  'Cart Contains Product in These Categories',
  'Cart Does Not Contain Product in These Categories',
];
const showTagTypes = [
  'Cart Contains Product with These Tags',
  'Cart Does Not Contain Product with These Tags',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditOneClickUpsell(props) {
  const swipeableViewsRef = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [upsell, setUpsell] = useState(null);
  const [simplifiedProducts, setSimplifiedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageDeleteIndex, setPageDeleteIndex] = useState(-1);
  const [showProductSelectionModal, setShowProductSelectionModal] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [productDeleteIndex, setProductDeleteIndex] = useState(-1);
  const [pageContentEditorOpen, setPageContentEditorOpen] = useState(false);
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    if (swipeableViewsRef.current) {
      setTimeout(() => {
        swipeableViewsRef.current
          .getChildContext()
          .swipeableViews.slideUpdateHeight();
      }, 10);
    }
  }, [tabValue, upsell, currentPageIndex]);

  useEffect(() => {
    getUpsell();
  }, []);

  const getUpsell = async () => {
    const db = firebase.firestore();

    try {
      const id = props.match.params.id;

      if (id === undefined) {
        props.history.replace('/shop/one-click-upsells');
        return;
      }

      const snapshot = await db.collection('one-click-upsells').doc(id).get();

      const upsellData = {
        ...snapshot.data(),
        id: snapshot.id,
      };

      console.log(upsellData);

      if (!upsellData) {
        props.history.replace('/shop/one-click-upsells');
        return;
      }

      const querySnapshot = await firebase.firestore().collection('config').doc('shop-settings').get();
      const contentQuerySnapshot = await firebase.firestore().collection('config').doc('content').get();
      const templateQuerySnapshot = await db.collection('global-templates').get();

      const templates =  templateQuerySnapshot.docs.map(doc => {
        const data = doc.data();

        if (data.children && typeof data.children === 'string') {
          data.children = JSON.parse(data.children);
        }

        return data;
      });

      if (upsellData.pages && Array.isArray(upsellData.pages)) {
        const onlyGlobalTemplates = templates.filter(t => {
          return t.global;
        });

        upsellData.pages.forEach(p => {
          const updatedEditorData = updateGlobalTemplates(p.editorData, onlyGlobalTemplates);
      
          p.editorData = updatedEditorData;
        });
      }

      setSimplifiedProducts(querySnapshot.data().simplifiedProducts);
      setProductCategories(querySnapshot.data().productCategories);
      setProductTags(contentQuerySnapshot.data().tags);
      setGlobalTemplates(templates);
      setUpsell(upsellData);
    } catch (e) {
      console.log('error', e);
      window.alert('There was an error fetching the upsell. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const updateGlobalTemplates = (data, templates) => {
    if (data.global) {
      const foundIndex = templates.findIndex(t => {
        return t.id === data.id;
      });

      if (foundIndex !== -1) {
        const templateCopy = cloneDeep(templates[foundIndex]);
        for (let key in templateCopy) {
          data[key] = templateCopy[key];
        }
      }
    }

    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        updateGlobalTemplates(children[i], templates);
      }
    }

    return data;
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  const findAndSaveGlobalTemplates = (data, templatesToSave) => {
    if (data.global) {
      const dataToSave = {
        ...data,
        updated: Date.now(),
        lastUpdatedBy: firebase.auth().currentUser.email,
      };

      if (!dataToSave.created) {
        dataToSave.created = dataToSave.updated;
      }

      templatesToSave[dataToSave.id] = dataToSave;
    }
    
    if (data.children && data.children.length) {
      const children = data.children;
      for (let i = 0; i < children.length; i++) {
        findAndSaveGlobalTemplates(children[i], templatesToSave);
      }
    }

    return templatesToSave;
  };

  const saveUpsell = async () => {
    const db = firebase.firestore();
    setLoading(true);

    try {
      const id = upsell.id;
      const pagePaths = [];
      let templatesToSave = {};

      const mappedPages = upsell.pages.map(p => {
        pagePaths.push(p.path);

        const currentPageTemplatesToSave = findAndSaveGlobalTemplates(p.editorData, {});
        const sharedTemplates = [];

        for (let key in currentPageTemplatesToSave) {
          sharedTemplates.push(key);
        }

        templatesToSave = {
          ...templatesToSave,
          ...currentPageTemplatesToSave,
        };

        return {
          ...p,
          sharedTemplates,
        };
      });

      for (let key in templatesToSave) {
        try {
          let globalTemplatesCopy = [ ...globalTemplates ];
          const foundIndex = globalTemplates.findIndex(t => {
            return `${t.id}` === `${key}`;
          });

          if (foundIndex !== -1) {
            globalTemplatesCopy[foundIndex] = templatesToSave[key];
          } else {
            globalTemplatesCopy.push(templatesToSave[key]);
            globalTemplatesCopy = [ templatesToSave[key], ...globalTemplatesCopy ];
          }

          setGlobalTemplates(globalTemplatesCopy);

          await db.collection('global-templates').doc(templatesToSave[key].id).set({
            ...templatesToSave[key],
            children: JSON.stringify(templatesToSave[key].children),
          });
        } catch (e) {
          console.log('error saving template', e);
        }
      }

      const upsellDataToSave = {
        name: upsell.name,
        pagePaths,
        priority: +upsell.priority,
        description: upsell.description,
        active: upsell.active,
        pages: mappedPages,
        rules: upsell.rules,
        lastModified: Date.now(),
        lastModifiedBy: firebase.auth().currentUser.email,
      };

      await db.collection('one-click-upsells').doc(id).update(upsellDataToSave);

      if (mappedPages.length) {
        const savedData = (await db.collection('one-click-upsells').doc(id).get()).data();
        const onlyGlobalTemplates = [];

        for (let key in templatesToSave) {
          onlyGlobalTemplates.push(templatesToSave[key]);
        }

        savedData.pages.forEach(p => {
          const updatedEditorData = updateGlobalTemplates(p.editorData, onlyGlobalTemplates);

          p.editorData = updatedEditorData;
        });

        setUpsell({
          ...savedData,
          id,
        });
      }
    } catch (e) {
      console.log('error', e);
      window.alert('There was an error saving this Upsell. Please try again.');
    }

    setLoading(false);
  };

  const getStepOptions = (type) => {
    const options = [];
    
    for (let i = 0; i <= upsell.pages.length; i++) {
      const name = i < upsell.pages.length ? upsell.pages[i].path : 'Thank You Page';
      options.push(<MenuItem key={`${type}-${i}`} value={i}>{name}</MenuItem>);
    }

    return options;
  };

  const closePageContentEditor = () => {
    setPageContentEditorOpen(false);
  };

  const saveModule = async (moduleToSave) => {
    const dataToSave = {
      ...moduleToSave,
      created: Date.now(),
      updated: Date.now(),
      lastUpdatedBy: firebase.auth().currentUser.email,
    };

    // Don't save globals it here. Only save globals on page save
    if (dataToSave.global) {
      setGlobalTemplates([ dataToSave, ...globalTemplates ]);
      setModalTitle('Success!');
      setModalText(`The ${moduleToSave.type} was added an will be saved when saving the page.`);
    } else {
      console.log('module', moduleToSave);
      const db = firebase.firestore();
      setLoading(true);

      try {
        await db.collection('global-templates').doc(dataToSave.id).set({
          ...dataToSave,
          children: JSON.stringify(dataToSave.children),
        });

        setGlobalTemplates([ dataToSave, ...globalTemplates ]);

        setLoading(false);
        setModalTitle('Success!');
        setModalText(`The ${moduleToSave.type} was saved successfully.`);
      } catch (e) {
        console.log('error', e);
        setLoading(false);
        setModalTitle('Error:');
        setModalText(`There was an error saving this ${moduleToSave.type}. Please try again.`);
      }
    }
  };

  return (
    <div className="EditOneClickUpsell">
      {renderLoading()}
      <div className="buttons-row">
        <Link to="/shop/one-click-upsells">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{marginRight: '10px', marginBottom: '15px'}}
            startIcon={<ArrowBackIcon />}
          >
            View All
          </Button>
        </Link>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{marginBottom: '15px'}}
          onClick={saveUpsell}
        >
          Save
        </Button>
      </div>

      {!upsell ? null :
        <div>
          <div className="last-modified-container">
            <span>
              <strong>Last Modified:</strong> <span style={{marginRight: 10}}>{moment(upsell.lastModified).calendar()}</span>
              <strong>Last Modified By:</strong> {upsell.lastModifiedBy}
            </span>
          </div>

          <div className="content-container">
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={upsell.active}
                    onChange={(e) => {
                      setUpsell({
                        ...upsell,
                        active: e.target.checked,
                      });
                    }}
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />

              <TextField
                label="Priority"
                value={upsell.priority}
                onChange={(e) => { setUpsell({ ...upsell, priority: e.target.value }) }}
                margin="dense"
                variant="outlined"
                type="number"
                className="day-text-field"
              />
            </div>
            
            <div className="row">
              <TextField
                label="Name (used internally)"
                value={upsell.name}
                onChange={(e) => { setUpsell({ ...upsell, name: e.target.value }) }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
              />
            </div>

            <div className="row">
              <TextField
                label="Description (used internally)"
                value={upsell.description}
                onChange={(e) => { setUpsell({ ...upsell, description: e.target.value }) }}
                margin="dense"
                variant="outlined"
                type="text"
                className="day-text-field"
                multiline
                rows="4"
              />
            </div>

            <div className="tabs-container">
              <Divider />
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => { setTabValue(newValue) }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="shipping tabs"
              >
                <Tab label="Rules" {...a11yProps(0)}  style={{borderRightWidth: 1, borderRightColor: 'rgba(0, 0, 0, 0.12)', borderRightStyle: 'solid'}}/>
                <Tab label="Pages" {...a11yProps(1)} />
              </Tabs>
              <Divider />
              <SwipeableViews
                index={tabValue}
                onChangeIndex={(index) => { setTabValue(index) }}
                ref={swipeableViewsRef}
                animateHeight={true}
              >
                <div className="container" value={tabValue} index={0}>
                  <div className="section-container">
                    <div className="rules-button-row">
                      <Button color="primary" onClick={() => {
                        const rules = [ ...upsell.rules ];

                        rules.push({
                          type: 'group',
                          rules: [{
                            type: ruleTypes[0],
                            products: [],
                            cartSubTotal: '',
                            tags: [],
                            categories: [],
                            couponCodes: [],
                          }],
                        });

                        setUpsell({
                          ...upsell,
                          rules,
                        });
                      }} variant="contained">
                        Add Rule Group
                      </Button>
                    </div>

                    {upsell.rules.map((group, i) => {
                      return (
                        <div key={`rule-group-${i}`}>
                          {i !== 0 && <div className="condition-text">OR</div>}

                          <div className="rule-group">
                            {upsell.rules.length > 1 &&
                              <Button color="secondary" size="small" onClick={() => {
                                const rules = [ ...upsell.rules ];

                                rules.splice(i, 1);

                                setUpsell({
                                  ...upsell,
                                  rules,
                                });
                              }} variant="contained">
                                Remove Group
                              </Button>
                            }
                            {group.rules.map((rule, j) => {
                              return (
                                <div key={`rule-${i}-${j}`}>
                                  {j !== 0 && <div className="condition-text">AND</div>}
                                  <div className="form-row">
                                    {group.rules.length > 1 &&
                                      <IconButton
                                        onClick={() => {
                                          const rules = [ ...group.rules ];

                                          rules.splice(j, 1);

                                          const upsellRules = [ ...upsell.rules ];
                                          upsellRules[i].rules = rules;

                                          setUpsell({
                                            ...upsell,
                                            rules: upsellRules,
                                          });
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                    <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
                                      <InputLabel>Rule Type</InputLabel>
                                      <Select
                                        value={rule.type}
                                        onChange={(e) => {
                                          const rules = [ ...group.rules ];

                                          rules[j] = {
                                            ...rules[j],
                                            type: e.target.value,
                                          };

                                          const upsellRules = [ ...upsell.rules ];
                                          upsellRules[i].rules = rules;

                                          setUpsell({
                                            ...upsell,
                                            rules: upsellRules,
                                          });
                                        }}
                                        label="Rule Type"
                                      >
                                        {ruleTypes.map((ruleType, k) => {
                                          return <MenuItem key={`rule-type-${i}-${j}-${k}`} value={ruleType}>{ruleType}</MenuItem>;
                                        })}
                                      </Select>
                                    </FormControl>

                                    {rule.type.includes('Subtotal') &&
                                      <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4}}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                        <OutlinedInput
                                          value={rule.cartSubTotal}
                                          onChange={(e) => {
                                            const rules = [ ...group.rules ];

                                            rules[j] = {
                                              ...rules[j],
                                              cartSubTotal: e.target.value,
                                            };
                        
                                            const upsellRules = [ ...upsell.rules ];
                                            upsellRules[i].rules = rules;
                        
                                            setUpsell({
                                              ...upsell,
                                              rules: upsellRules,
                                            });
                                          }}
                                          margin="dense"
                                          label="Amount"
                                          variant="outlined"
                                          type="number"
                                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                          style={{maxWidth: 180, marginRight: 15}}
                                        />
                                      </FormControl>
                                    }

                                    {(showProductTypes.includes(rule.type)) &&
                                      <FormControl style={{flex: 1}}>
                                        <InputLabel>Products</InputLabel>
                                        <Select
                                          multiple
                                          value={rule.products}
                                          onChange={(e) => {
                                            const rules = [ ...group.rules ];

                                            rules[j] = {
                                              ...rules[j],
                                              products: e.target.value,
                                            };
                        
                                            const upsellRules = [ ...upsell.rules ];
                                            upsellRules[i].rules = rules;
                        
                                            setUpsell({
                                              ...upsell,
                                              rules: upsellRules,
                                            });
                                          }}
                                          input={<Input />}
                                          renderValue={selected => (
                                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                              {selected.map(value => (
                                                <DeletableChip
                                                  key={value.id}
                                                  label={value.name}
                                                  style={{margin: 2}}
                                                  color="primary"
                                                  onDelete={() => {
                                                    const index = rule.products.indexOf(value);
                        
                                                    if (index === -1) {
                                                      return;
                                                    }

                                                    const rules = [ ...group.rules ];
                                                    const products = [ ...rule.products ];

                                                    products.splice(index, 1);

                                                    rules[j] = {
                                                      ...rules[j],
                                                      products,
                                                    };

                                                    const upsellRules = [ ...upsell.rules ];
                                                    upsellRules[i].rules = rules;

                                                    setUpsell({
                                                      ...upsell,
                                                      rules: upsellRules,
                                                    });
                                                  }}
                                                />
                                              ))}
                                            </div>
                                          )}
                                          MenuProps={MenuProps}
                                        >
                                          {simplifiedProducts.filter(product => {
                                            const found = rule.products.find(p => p.id === product.id);
                                            return !found;
                                          }).map(product => (
                                            <MenuItem key={product.id} value={product}>
                                              {product.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    }

                                    {(showCategoryTypes.includes(rule.type)) &&
                                      <FormControl style={{flex: 1}}>
                                        <InputLabel>Categories</InputLabel>
                                        <Select
                                          multiple
                                          value={rule.categories}
                                          onChange={(e) => {
                                            const rules = [ ...group.rules ];

                                            rules[j] = {
                                              ...rules[j],
                                              categories: e.target.value,
                                            };
                        
                                            const upsellRules = [ ...upsell.rules ];
                                            upsellRules[i].rules = rules;
                        
                                            setUpsell({
                                              ...upsell,
                                              rules: upsellRules,
                                            });
                                          }}
                                          input={<Input />}
                                          renderValue={selected => (
                                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                              {selected.map(value => (
                                                <DeletableChip
                                                  key={value}
                                                  label={value}
                                                  style={{margin: 2}}
                                                  color="primary"
                                                  onDelete={() => {
                                                    const index = rule.categories.indexOf(value);
                        
                                                    if (index === -1) {
                                                      return;
                                                    }

                                                    const rules = [ ...group.rules ];
                                                    const categories = [ ...rule.categories ];

                                                    categories.splice(index, 1);

                                                    rules[j] = {
                                                      ...rules[j],
                                                      categories,
                                                    };

                                                    const upsellRules = [ ...upsell.rules ];
                                                    upsellRules[i].rules = rules;

                                                    setUpsell({
                                                      ...upsell,
                                                      rules: upsellRules,
                                                    });
                                                  }}
                                                />
                                              ))}
                                            </div>
                                          )}
                                          MenuProps={MenuProps}
                                        >
                                          {productCategories.filter(category => {
                                            return !rule.categories.includes(category);
                                          }).map(category => (
                                            <MenuItem key={category} value={category}>
                                              {category}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    }

                                    {(showTagTypes.includes(rule.type)) &&
                                      <FormControl style={{flex: 1}}>
                                        <InputLabel>Tags</InputLabel>
                                        <Select
                                          multiple
                                          value={rule.tags}
                                          onChange={(e) => {
                                            const rules = [ ...group.rules ];

                                            rules[j] = {
                                              ...rules[j],
                                              tags: e.target.value,
                                            };
                        
                                            const upsellRules = [ ...upsell.rules ];
                                            upsellRules[i].rules = rules;
                        
                                            setUpsell({
                                              ...upsell,
                                              rules: upsellRules,
                                            });
                                          }}
                                          input={<Input />}
                                          renderValue={selected => (
                                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                              {selected.map(value => (
                                                <DeletableChip
                                                  key={value}
                                                  label={value}
                                                  style={{margin: 2}}
                                                  color="primary"
                                                  onDelete={() => {
                                                    const index = rule.tags.indexOf(value);
                        
                                                    if (index === -1) {
                                                      return;
                                                    }

                                                    const rules = [ ...group.rules ];
                                                    const tags = [ ...rule.tags ];

                                                    tags.splice(index, 1);

                                                    rules[j] = {
                                                      ...rules[j],
                                                      tags,
                                                    };

                                                    const upsellRules = [ ...upsell.rules ];
                                                    upsellRules[i].rules = rules;

                                                    setUpsell({
                                                      ...upsell,
                                                      rules: upsellRules,
                                                    });
                                                  }}
                                                />
                                              ))}
                                            </div>
                                          )}
                                          MenuProps={MenuProps}
                                        >
                                          {productTags.filter(tag => {
                                            return !rule.tags.includes(tag);
                                          }).map(tag => (
                                            <MenuItem key={tag} value={tag}>
                                              {tag}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    }

                                    {(rule.type === 'Coupon Code') &&
                                      <ChipInput
                                        style={{flex: 1}}
                                        defaultValue={rule.couponCodes}
                                        onChange={(chips) => {
                                          const rules = [ ...group.rules ];

                                          rules[j] = {
                                            ...rules[j],
                                            couponCodes: chips,
                                          };

                                          const upsellRules = [ ...upsell.rules ];
                                          upsellRules[i].rules = rules;

                                          setUpsell({
                                            ...upsell,
                                            rules: upsellRules,
                                          });
                                        }}
                                        margin="dense"
                                        allowDuplicates={false}
                                        fullWidth={true}
                                        label="Coupon Codes"
                                        helperText='Press "enter" after each code as you type'
                                      />
                                    }
                                  </div>
                                </div>
                              );
                            })}

                            <Button color="primary" size="small" onClick={() => {
                              const rules = [ ...group.rules ];

                              rules.push({
                                type: ruleTypes[0],
                                products: [],
                                cartSubTotal: 0,
                                tags: [],
                                categories: [],
                                couponCodes: [],
                              });

                              const upsellRules = [ ...upsell.rules ];
                              upsellRules[i].rules = rules;

                              setUpsell({
                                ...upsell,
                                rules: upsellRules,
                              });
                            }} variant="contained">
                              Add Condition
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="container" value={tabValue} index={1}>
                  <div className="pages-container">
                    <div className="left-container">
                      <div className="page-flow-container">
                        <p>Checkout</p>
                      </div>

                      {upsell.pages.map((p, i) => {
                        return (
                          <div key={`page-${i}`} className="upsell-page-container">
                            {upsell.pages.length < 2 ? null :
                              <div
                                className="delete-button"
                                onClick={() => { setPageDeleteIndex(i) }}
                              >
                                <p>×</p>
                              </div>
                            }
                            <div
                              className={`page-flow-container upsell-page${currentPageIndex === i ? ' selected' : ''}`}
                              onClick={() => {
                                setCurrentPageIndex(i);
                              }}
                            >
                              <p><strong>Path:</strong> /offer/{p.path}</p>
                            </div>
                          </div>
                        );
                      })}

                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{width: '100%', marginBottom: 10}}
                        onClick={() => {
                          const pagesCopy = [ ...upsell.pages ];

                          pagesCopy.push({
                            path: `offer-${Date.now()}`,
                            editorData: {
                              type: 'top-layer',
                              children: [],
                            },
                            sharedTemplates: [],
                            customCSS: '',
                            products: [],
                            acceptNextStep: upsell.pages.length + 1,
                            rejectNextStep: upsell.pages.length + 1,
                          });

                          setUpsell({
                            ...upsell,
                            pages: pagesCopy,
                          });
                        }}
                      >Add Page</Button>

                      <div className="page-flow-container">
                        <p>Thank You</p>
                      </div>
                    </div>

                    <div className="right-container">
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{width: '100%', marginBottom: 20}}
                        onClick={() => {
                          setPageContentEditorOpen(true);
                        }}
                      >Edit Page Content</Button>

                      <FormControl variant="outlined" style={{marginTop: 8, marginBottom: 4, width: '100%'}}>
                        <InputLabel>URL Path</InputLabel>
                        <OutlinedInput
                          value={upsell.pages[currentPageIndex].path}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
                            const pagesCopy = [ ...upsell.pages ];
                            const page = {
                              ...pagesCopy[currentPageIndex],
                              path: value,
                            };

                            pagesCopy[currentPageIndex] = page;

                            setUpsell({
                              ...upsell,
                              pages: pagesCopy,
                            });
                          }}
                          margin="dense"
                          label="URL Path"
                          variant="outlined"
                          type="text"
                          startAdornment={<InputAdornment position="start">offer/</InputAdornment>}
                        />
                        <FormHelperText><small>URL must be unique</small></FormHelperText>
                      </FormControl>

                      <FormControl margin="dense" variant="outlined" style={{width: '100%', marginTop: 15}}>
                        <InputLabel>Next Step if Accepted</InputLabel>
                        <Select
                          value={upsell.pages[currentPageIndex].acceptNextStep}
                          onChange={(e) => {
                            const pagesCopy = [ ...upsell.pages ];
                            const page = {
                              ...pagesCopy[currentPageIndex],
                              acceptNextStep: e.target.value,
                            };

                            pagesCopy[currentPageIndex] = page;

                            setUpsell({
                              ...upsell,
                              pages: pagesCopy,
                            });
                          }}
                          label="Next Step if Accepted"
                        >
                          {getStepOptions('accept')}
                        </Select>
                      </FormControl>

                      <FormControl margin="dense" variant="outlined" style={{width: '100%', marginTop: 20}}>
                        <InputLabel>Next Step if Rejected</InputLabel>
                        <Select
                          value={upsell.pages[currentPageIndex].rejectNextStep}
                          onChange={(e) => {
                            const pagesCopy = [ ...upsell.pages ];
                            const page = {
                              ...pagesCopy[currentPageIndex],
                              rejectNextStep: e.target.value,
                            };

                            pagesCopy[currentPageIndex] = page;

                            setUpsell({
                              ...upsell,
                              pages: pagesCopy,
                            });
                          }}
                          label="Next Step if Rejected"
                        >
                          {getStepOptions('reject')}
                        </Select>
                      </FormControl>

                      <p className="reject-button-class-name">
                        <strong>Reject Button Class Name (will match page URL Path):</strong> <span>{upsell.pages[currentPageIndex].path}</span>
                      </p>

                      <h3>Products</h3>

                      <table>
                        <tbody>
                          {upsell.pages[currentPageIndex].products.map((p, i) => {
                            return (
                              <tr key={`product-row-${i}`}>
                                <td style={{maxWidth: 250}}>
                                  <img width="150" src={p.product.image.url}/>
                                  <p><strong>{p.product.name}</strong></p>
                                  <p><strong>Regular Price:</strong> ${p.product.price}</p>
                                  <p><strong>Button Class Name:</strong> <span>{p.product.path}</span></p>
                                </td>
                                <td>
                                  <FormControl variant="outlined" style={{width: 100, marginTop: 8, marginBottom: 4, marginRight: 5}}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Sale Price</InputLabel>
                                    <OutlinedInput
                                      value={p.salePrice}
                                      onChange={(e) => {
                                        const pages = [ ...upsell.pages ];
                                        const products = [ ...pages[currentPageIndex].products ];
                            
                                        products[i] = {
                                          ...p,
                                          salePrice: e.target.value,
                                        };
                            
                                        pages[currentPageIndex] = {
                                          ...pages[currentPageIndex],
                                          products,
                                        };

                                        setUpsell({
                                          ...upsell,
                                          pages,
                                        });
                                      }}
                                      margin="dense"
                                      label="Sale Price"
                                      variant="outlined"
                                      type="number"
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                  </FormControl>

                                  <TextField
                                    label="Quantity"
                                    value={p.quantity}
                                    onChange={(e) => {
                                      const pages = [ ...upsell.pages ];
                                      const products = [ ...pages[currentPageIndex].products ];
                          
                                      products[i] = {
                                        ...p,
                                        quantity: e.target.value,
                                      };
                          
                                      pages[currentPageIndex] = {
                                        ...pages[currentPageIndex],
                                        products,
                                      };
                          
                                      setUpsell({
                                        ...upsell,
                                        pages,
                                      });
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    style={{maxWidth: 80, marginRight: 5}}
                                  />

                                  <FormControl variant="outlined" style={{width: 100, marginTop: 8, marginBottom: 4}}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Shipping</InputLabel>
                                    <OutlinedInput
                                      value={p.shipping}
                                      onChange={(e) => {
                                        const pages = [ ...upsell.pages ];
                                        const products = [ ...pages[currentPageIndex].products ];
                            
                                        products[i] = {
                                          ...p,
                                          shipping: e.target.value,
                                        };
                            
                                        pages[currentPageIndex] = {
                                          ...pages[currentPageIndex],
                                          products,
                                        };
                            
                                        setUpsell({
                                          ...upsell,
                                          pages,
                                        });
                                      }}
                                      margin="dense"
                                      label="Shipping"
                                      variant="outlined"
                                      type="number"
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                  </FormControl>
                                </td>
                                <td>
                                  <IconButton onClick={() => { setProductDeleteIndex(i) }}>
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{width: '100%', marginBottom: 10}}
                        onClick={() => {
                          setSelectedProductIndex(0);
                          setShowProductSelectionModal(true);
                        }}
                      >Add Product</Button>
                    </div>
                  </div>
                </div>
              </SwipeableViews>
            </div>
          </div>
        </div>
      }

      <Dialog open={pageDeleteIndex !== -1} onClose={() => { setPageDeleteIndex(-1) }}>
        <DialogTitle>Delete Page?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this page?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            if (currentPageIndex === (upsell.pages.length - 1)) {
              setCurrentPageIndex(currentPageIndex - 1);
            }

            const pages = upsell.pages.map((p, i) => {
              const nextAccepted = (p.acceptNextStep === upsell.pages.length || i > pageDeleteIndex) ? p.acceptNextStep - 1 : p.acceptNextStep;
              const nextRejected = (p.rejectNextStep === upsell.pages.length || i > pageDeleteIndex) ? p.rejectNextStep - 1 : p.rejectNextStep;

              return {
                ...p,
                acceptNextStep: nextAccepted,
                rejectNextStep: nextRejected,
              };
            });
            pages.splice(pageDeleteIndex, 1);

            setUpsell({
              ...upsell,
              pages,
            });
            setPageDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setPageDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showProductSelectionModal} onClose={() => { setShowProductSelectionModal(false) }}>
        <DialogTitle>Select Product</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" variant="outlined" style={{marginRight: 20, flex: 1}}>
            <InputLabel>Product</InputLabel>
            <Select
              value={selectedProductIndex}
              onChange={(e) => {
                setSelectedProductIndex(e.target.value);
              }}
              label="Product"
            >
              {simplifiedProducts.map((p, i) => {
                return <MenuItem key={`product-option-${i}`} value={i}>{p.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const pages = [ ...upsell.pages ];
            const products = [ ...pages[currentPageIndex].products ];

            products.push({
              product: simplifiedProducts[selectedProductIndex],
              salePrice: 5.99,
              quantity: 1,
              shipping: 0,
            });

            pages[currentPageIndex] = {
              ...pages[currentPageIndex],
              products,
            };

            setUpsell({
              ...upsell,
              pages,
            });
            setShowProductSelectionModal(false);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setShowProductSelectionModal(false) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={productDeleteIndex !== -1} onClose={() => { setProductDeleteIndex(-1) }}>
        <DialogTitle>Remove Product?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove this product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            const pages = [ ...upsell.pages ];
            const products = [ ...pages[currentPageIndex].products ];
            products.splice(productDeleteIndex, 1);

            pages[currentPageIndex] = {
              ...pages[currentPageIndex],
              products,
            };

            setUpsell({
              ...upsell,
              pages,
            });
            setProductDeleteIndex(-1);
          }} color="secondary">
            Confirm
          </Button>
          <Button variant="contained" onClick={() => { setProductDeleteIndex(-1) }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={pageContentEditorOpen} onClose={closePageContentEditor}>
        {upsell ? <PageBuilder
          content={upsell.pages[currentPageIndex].editorData}
          saveModule={saveModule}
          globalTemplates={globalTemplates}
          updateContent={(content) => {
            const pages = [ ...upsell.pages ];

            pages[currentPageIndex] = {
              ...pages[currentPageIndex],
              editorData: content,
            };

            setUpsell({
              ...upsell,
              pages,
            });
          }}
          close={closePageContentEditor}
          css={upsell.pages[currentPageIndex].customCSS}
          updateCSS={(customCSS) => {
            const pages = [ ...upsell.pages ];

            pages[currentPageIndex] = {
              ...pages[currentPageIndex],
              customCSS,
            };

            setUpsell({
              ...upsell,
              pages,
            });
          }}
        /> : null}
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditOneClickUpsell;
