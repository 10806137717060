import React, { useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Modal from './shared/modal';
import EditModal from './shared/editModal';
import DisplayedTabs from './preview/tabs';
import upArrow from '../icons/up.png';
import downArrow from '../icons/down.png';
import editArrow from '../icons/edit.png';
import deleteIcon from '../icons/delete.png';
import moveIcon from '../icons/move.png';
import copyIcon from '../icons/copy.png';

const editModalTabs = [
  {
    title: 'Items',
  },
  {
    title: 'Container Styles',
    editableName: 'editableContentContainerStyles',
    stylesName: 'contentContainerStyles',
  },
  {
    title: 'Tab Styles',
    editableName: 'editableTabStyles',
    stylesName: 'tabStyles',
  },
  {
    title: 'Content Styles',
    editableName: 'editableContentStyles',
    stylesName: 'contentStyles',
  },
];

const styles = {
  container: {
    padding: '5px',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: 'lightblue',
  },
  toolbarItem: {
    height: 15,
    width: 15,
    margin: 5,
    cursor: 'pointer',
  },
  image: {
    height: '100%',
  },
  editModalTabs: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    marginBottom: '15px',
  },
  editModalTab: {
    flex: 1,
    textAlign: 'center',
    padding: '7px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  editModalTabActive: {
    borderBottom: '1px solid #00a0d2',
    color: '#00a0d2',
  },
  addItemButton: {
    backgroundColor: '#00a0d2',
    textAlign: 'center',
    color: '#fff',
    borderRadius: '4px',
    lineHeight: 1,
    padding: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
    cursor: 'pointer',
    borderWidth: 0,
  },
};

function Tabs({ content, updateContent, upClicked, downClicked, deleteItem, copyClicked }) {
  const [, drag] = useDrag({
    item: { ...content },
    end(item, monitor) {
      if (!monitor.didDrop()) {
        return;
      }

      deleteItem();
    },
  });
  const innerRef = useRef(null);
  const [showTools, setShowTools] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditTab, setSelectedEditTab] = useState(0);
  const [deleteItemIndex, setDeleteItemIndex] = useState(-1);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.onmouseenter = (e) => {
        setShowTools(true);
      };

      innerRef.current.onmouseleave = (e) => {
        setShowTools(false);
      };
    }
  }, [innerRef]);

  const delayedDelete = () => {
    setTimeout(() => {
      deleteItem();
    }, 50);
  };

  const renderModalTabItems = () => {
    if (selectedEditTab === 0) {
      return (
        <div>
          <button
            style={styles.addItemButton}
            onClick={() => {
              updateContent({
                ...content,
                items: [
                  ...content.items,
                  {
                    title: 'Tab',
                    content: '',
                  },
                ],
              });
            }}
          >Add Item</button>

          {content.items.map((item, i) => {
            return (
              <div key={`item-${i}`}>
                <h4 style={{marginBottom: 10}}>
                  Item {i + 1} <span onClick={() => { setDeleteItemIndex(i) }}><img style={{height: 12, marginLeft: 10, cursor: 'pointer'}} src={deleteIcon} alt="item delete" /></span>
                </h4>

                <div className="input-container">
                  <label>Label Text</label>
                  <input
                    value={item.title}
                    onChange={(e) => {
                      const items = [ ...content.items ];

                      items[i] = {
                        ...items[i],
                        title: e.target.value,
                      };

                      updateContent({
                        ...content,
                        items,
                      });
                    }}
                  />
                </div>

                <div className="input-container" style={{width: '100%'}}>
                  <label>Content</label>
                  <ReactQuill
                    theme="snow"
                    value={item.content}
                    onChange={(text) => {
                      const items = [ ...content.items ];

                      items[i] = {
                        ...items[i],
                        content: text,
                      };

                      updateContent({
                        ...content,
                        items,
                      });
                    }}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link'],
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'color', 'background',
                    ]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (selectedEditTab) {
      return (
        <div>
          {content[editModalTabs[selectedEditTab].editableName].map((editable, i) => {
            return (
              <div key={`editable-${i}`} className="input-container">
                <label>{editable.label}</label>
                {editable.inputType === 'select' ?
                  <select
                    value={content[editModalTabs[selectedEditTab].stylesName][editable.type]}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        [editModalTabs[selectedEditTab].stylesName]: {
                          ...content[editModalTabs[selectedEditTab].stylesName],
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  >
                    {editable.options.map((option, i) => {
                      return <option key={i} value={option}>{option}</option>;
                    })}
                  </select> :
                  <input
                    value={content[editModalTabs[selectedEditTab].stylesName][editable.type]}
                    placeholder={editable.placeholder}
                    type={editable.inputType || 'text'}
                    onChange={(e) => {
                      updateContent({
                        ...content,
                        [editModalTabs[selectedEditTab].stylesName]: {
                          ...content[editModalTabs[selectedEditTab].stylesName],
                          [editable.type]: e.target.value,
                        },
                      });
                    }}
                  />
                }
              </div>
            );
          })}
          {selectedEditTab !== 1 ? null :
            <div>
              <div className="input-container">
                <label>Displayed Device Widths</label>
                <select
                  value={content.visibilityClassName}
                  onChange={(e) => {
                    updateContent({
                      ...content,
                      visibilityClassName: e.target.value
                    });
                  }}
                >
                  <option value={'page-builder-always-visible'}>Always</option>
                  <option value={'page-builder-large-visible'}>Large Devices Only</option>
                  <option value={'page-builder-large-medium-visible'}>Large & Medium Devices Only</option>
                  <option value={'page-builder-medium-visible'}>Medium Devices Only</option>
                  <option value={'page-builder-medium-small-visible'}>Medium & Small Devices Only</option>
                  <option value={'page-builder-small-visible'}>Small Devices Only</option>
                </select>
              </div>
              <div className="input-container">
                <label>Class Names</label>
                <input
                  value={content.customClasses}
                  onChange={(e) => {
                    updateContent({
                      ...content,
                      customClasses: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-container">
                <label>Inline Styles</label>
                <input
                  value={content.customStyles}
                  onChange={(e) => {
                    updateContent({
                      ...content,
                      customStyles: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-container">
                <label>ID</label>
                <input
                  value={content.htmlID}
                  onChange={(e) => {
                    updateContent({
                      ...content,
                      htmlID: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          }
        </div>
      );
    }

    return <div></div>;
  }

  return (
    <div style={{ ...styles.container }}>
      <div
        ref={innerRef}
      >
        {!showTools ? null :
          <div style={styles.inner}>
            <div style={styles.toolbar}>
              <div style={styles.toolbarItem} ref={drag}>
                <img style={styles.image} src={moveIcon} alt="move" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { copyClicked() }}>
                <img style={styles.image} src={copyIcon} alt="copy" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={upArrow} onClick={upClicked} alt="up arrow" />
              </div>
              <div style={styles.toolbarItem}>
                <img style={styles.image} src={downArrow} onClick={downClicked} alt="down arrow" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setEditModalOpen(true) }}>
                <img style={styles.image} src={editArrow} alt="edit" />
              </div>
              <div style={styles.toolbarItem} onClick={() => { setDeleteModalOpen(true) }}>
                <img style={styles.image} src={deleteIcon} alt="delete" />
              </div>
            </div>
          </div>
        }
        {!content.items.length ? <div>Content Goes Here</div> :
        <DisplayedTabs content={content} />}
      </div>

      <EditModal
        open={editModalOpen}
        close={() => { setEditModalOpen(false) }}
        title={`Edit ${content.title}`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            setEditModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setEditModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div style={styles.editModalTabs}>
            {editModalTabs.map((item, i) => {
              const additionalStyles = selectedEditTab === i ? styles.editModalTabActive : {};
              return (
                <div
                  key={`tab-${i}`}
                  style={{ ...styles.editModalTab, ...additionalStyles }}
                  onClick={() => { setSelectedEditTab(i) }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>

          {renderModalTabItems()}
        </div>
      </EditModal>

      <Modal
        open={deleteModalOpen}
        close={() => { setDeleteModalOpen(false) }}
        title={`Delete ${content.title}?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            delayedDelete();
            setDeleteModalOpen(false);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this?</div>
        </div>
      </Modal>

      <Modal
        open={deleteItemIndex !== -1}
        close={() => { setDeleteItemIndex(-1) }}
        title={`Delete Item?`}
        buttons={[
          <button key="modal-confirm" className="success" onClick={() => {
            const items = [ ...content.items ];

            items.splice(deleteItemIndex, 1);

            updateContent({
              ...content,
              items,
            });
            setDeleteItemIndex(-1);
          }}>Confirm</button>,
          <button key="modal-close" className="danger" onClick={() => {
            setDeleteItemIndex(-1);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete this item?</div>
        </div>
      </Modal>
    </div>
  );
};

export default Tabs;
