import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerformanceToday from './performanceToday/performanceToday';
import OrderHistory from './orderHistory/orderHistory';
import SubscriptionHistory from './subscriptionHistory/subscriptionHistory';
import './dashboard.scss';

const endYesterday = new Date();
endYesterday.setDate(endYesterday.getDate() - 1);
endYesterday.setHours(23, 59, 59, 999);

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async (newStart, newEnd) => {
    const end = newEnd || endYesterday;
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date(end.getTime() - (7 * 24 * 60 * 60 * 1000));
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);
    setLoading(true);

    try {
      const getOrderAndSubscriptionHistory = firebase.functions().httpsCallable('getOrderAndSubscriptionHistory');
      const result = await getOrderAndSubscriptionHistory({
        start: moment(start).valueOf(),
        end: moment(end).valueOf(),
      });

      if (result && result.data && result.data.error) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText(result.data.error);
        return;
      }

      let reports = result.data.map(data => {
        return {
          ...data,
          created: moment(data.created).valueOf(),
        };
      });

      reports = reports.sort((a, b) => {
        return b.created - a.created;
      });

      setReports(reports);
      setLoading(false);
    } catch (e) {
      console.log(e.toString());
      setLoading(false);
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="ShopDashboard">
      {renderLoading()}
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="h6">
          Shop Dashboard
        </Typography>
      </Toolbar>

      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', color: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="subtitle1">
          Today
        </Typography>
      </Toolbar>

      <div className="content">
        <PerformanceToday />
      </div>

      <Toolbar style={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray', color: '#fff', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderStyle: 'solid'}}>
        <Typography variant="subtitle1">
          Order / Subscription History
        </Typography>
      </Toolbar>

      <div className="content">
        <div className="date-picker-row">
          <div className="date-picker-container">
            <Typography>
              <small>Start</small>
            </Typography>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                const start = new Date(date);
                start.setHours(0, 0, 0, 0);
                setStartDate(start);
              }}
              maxDate={endYesterday}
            />
          </div>
          <div className="date-divider" style={{marginTop: 14}}>-</div>
          <div className="date-picker-container">
            <Typography>
              <small>End</small>
            </Typography>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                const end = new Date(date);
                end.setHours(23, 59, 59, 999);
                setEndDate(end);
              }}
              maxDate={endYesterday}
            />
          </div>

          <div className="date-picker-submit-button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchOrders(startDate, endDate)}
            >
              Submit
            </Button>
          </div>
        </div>

        <OrderHistory reports={reports} />
        <SubscriptionHistory reports={reports} />
      </div>

      <div className="content">
        <Link to="/shop/dashboard/subscription-forecast" style={{marginRight: 10}}>
          <Button variant="contained" color="primary" size="small">
            Subscription Forecast
          </Button>
        </Link>

        <Link to="/shop/dashboard/subscription-retention">
          <Button variant="contained" color="primary" size="small">
            Subscription Retention
          </Button>
        </Link>
      </div>

      <Dialog
        open={!!modalText}
        onClose={() => { setModalText('') }}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modalText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
