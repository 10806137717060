import React from 'react';
import './products-categories-section.scss';

export const ProductsCategoriesSection = ({ data }) => {
  const {
    title,
    subtitle,
    backgroundColor,
    imageUrl,
    imagePosition,
    content,
    buttonText,
    categories: { header, items },
  } = data;

  return (
    <section className="ProductsContainer" style={{ backgroundColor }}>
      {title ? <h3 className="title">{title}</h3> : null}
      <div className="grid">
        <div
          className="gridItem"
          style={{ order: imagePosition === 'right' ? 0 : 1 }}
        >
          <div className="contentContainer">
            {subtitle ? <h4 className="subtitle">{subtitle}</h4> : null}
            <p className="content">{content}</p>

            <p className="categoriesHeader">{header}</p>

            <div className="categoriesItems">
              {items?.map((item) => {
                return (
                  <p key={item.name} className="categoryText">
                    <span
                      className="categoryTitle"
                      style={{ color: item?.color }}
                    >
                      {item.name} -{' '}
                    </span>
                    <span>{item.description}</span>
                  </p>
                );
              })}
            </div>

            <button className="button">{buttonText}</button>
          </div>
        </div>
        <div
          className="gridItem"
          style={{ order: imagePosition === 'right' ? 1 : 0 }}
        >
          <div className="imageContainer">
            <img className="image" src={imageUrl} alt={imageUrl} />
          </div>
        </div>
      </div>
    </section>
  );
};
