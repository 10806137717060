import {
  SET_ORDERS_COUNT,
  SET_USERS_COUNT,
  SET_SUBSCRIPTIONS_COUNT,
} from './types';

export const setOrdersCount = (count) => {
  return {
    type: SET_ORDERS_COUNT,
    payload: count,
  };
};

export const setUsersCount = (count) => {
  return {
    type: SET_USERS_COUNT,
    payload: count,
  };
};

export const setSubscriptionsCount = (count) => {
  return {
    type: SET_SUBSCRIPTIONS_COUNT,
    payload: count,
  };
};
