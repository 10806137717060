import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';

import './homePagePreview.scss';

function HomePagePreview({ data, close }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(data.headerBanner.items.filter((slide) => slide.live));
  }, [data.headerBanner.items]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, data.headerBanner.timeBetweenTransitionsSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);

  const handleSlideChange = (event) => {
    setCurrentSlide(parseInt(event.target.value, 10));
  };

  const renderTextContainers = () => {
    const textContainers = [];
    for (let i = 0; i <= +data.reviewsBanner.duplicates; i++) {
      textContainers.push(
        <span className="marqueeTextContainer" key={`text-container-${i}`}>
          {data.reviewsBanner.items.map((item, j) => {
            return (
              <span
                className="marqueeText"
                key={`text-item-${i}-${j}`}
                style={{ color: data.reviewsBanner.fontColor || '#000' }}
              >
                {item}
              </span>
            );
          })}
        </span>,
      );
    }

    return textContainers;
  };

  if (slides.length && currentSlide > slides.length - 1) {
    setCurrentSlide(0);
  }

  return (
    <div className="HomePagePreview">
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Button color="inherit" onClick={() => close()}>
            close
          </Button>
        </Toolbar>
      </AppBar>

      <div className="mainHomeContainer">
        <div className="slider">
          {slides.map((slide, index) => (
            <div
              className={`slide${index === currentSlide ? '' : ' inactive'}`}
              key={index}
              style={{ backgroundColor: slide.backgroundColor }}
            >
              {slide.h1Position === 'top-screen' ? (
                <h1
                  style={{
                    color: slide.h1Color,
                    fontSize: `${slide.h1FontSize}px`,
                  }}
                >
                  {slide.h1}
                </h1>
              ) : null}
              <div
                className={`slideMainContainer${slide.type === 'cta-right' ? '' : ' reverse'}`}
              >
                <div className="slideMediaContainer">
                  {slide.mediaType === 'image' ? (
                    <img src={slide.imageUrl} alt={slide.h2} />
                  ) : (
                    <div className="slideImageContainer">
                      <div className="slideImageContainerInner">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: slide.videoEmbedCode,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="sliderDescription">
                  {slide.h1Position === 'above-subtitle' ? (
                    <h1
                      style={{
                        color: slide.h1Color,
                        fontSize: `${slide.h1FontSize}px`,
                      }}
                    >
                      {slide.h1}
                    </h1>
                  ) : null}
                  <h2
                    style={{
                      color: slide.h2Color,
                      fontSize: `${slide.h2FontSize}px`,
                    }}
                  >
                    {slide.h2}
                  </h2>
                  <p
                    style={{
                      color: slide.pColor,
                      fontSize: `${slide.pFontSize}px`,
                    }}
                  >
                    {slide.p}
                  </p>
                  <div className="slideMediaContainerMobile">
                    {slide.mediaType === 'image' ? (
                      <img src={slide.imageUrl} alt={slide.h2} />
                    ) : (
                      <div className="slideImageContainer">
                        <div className="slideImageContainerInner">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: slide.videoEmbedCode,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <a>
                    <button
                      style={{
                        backgroundColor: slide.buttonBackgroundColor,
                        color: slide.buttonTextColor,
                      }}
                    >
                      {slide.buttonText}
                    </button>
                  </a>
                  <div className="subLink">
                    <a style={{ color: slide.subLinkColor }}>
                      {slide.subLinkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="sliderButtonsContainer">
          <div className="sliderButtons">
            {slides.map((_, index) => (
              <label key={index}>
                <input
                  type="radio"
                  value={index}
                  checked={currentSlide === index}
                  onChange={handleSlideChange}
                />
                <span></span>
              </label>
            ))}
          </div>
        </div>

        <div className="mainInfoContainer">
          <section className="productContainer">
            <h2>{data.productSectionTitle}</h2>
            <p className="productText">{data.productSectionSubtitle}</p>
            <div className="productsContainer">
              {data.products.map((product, i) => {
                return (
                  <a key={`product-${i}-${product.name}`}>
                    <div className="productImgContainer">
                      <img
                        src={product.image.url}
                        alt={`product-${i}-${product.name}`}
                      />
                      <p>{product.name}</p>
                      <button>BUY NOW</button>
                    </div>
                  </a>
                );
              })}
            </div>
          </section>
          <section className="trainingSection">
            <h1>{data.trainingSectionTitle}</h1>
            <div className="trainingDetails">
              <div className="trainingDetailsContentContainer">
                <h2>{data.trainingSectionSubtitle}</h2>
                <p className="trainingSectionParagraph">
                  {data.trainingSectionParagraph}
                </p>
                <a>
                  <button>{data.trainingSectionPrimaryButtonText}</button>
                </a>

                {data.trainingSectionDownloadAppsLinksVisible ? (
                  <>
                    <p className="downloadAppTitle">Download App</p>

                    <div className="appStoreButtonsContainer">
                      <a href="https://apps.apple.com/us/app/pupford-dog-training-videos/id1476456602?ls=1">
                        <img src="https://pupford.b-cdn.net/assets%2Fpages%2Fnew-home%2F1659031012727-app-store-button.png?alt=media&token=1a969ac2-3e68-490e-b374-d9475df9265e" />
                      </a>

                      <a href="https://play.google.com/store/apps/details?id=com.pupford.pupford">
                        <img src="https://pupford.b-cdn.net/assets%2Fpages%2Fnew-home%2F1659031014437-google-play-button.png?alt=media&token=8e44c8f0-ce83-4dda-8959-febe47cd694e" />
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="trainingDetailsImageContainer">
                <img
                  src={data.trainingSectionImageUrl}
                  alt="App and Training Treats"
                />
              </div>
            </div>

            <div className="videoOuter">
              <div className="videoContainer">
                <div className="videoBackground"></div>
                <div className="videoInner">
                  <div
                    className="trainingVideoContainer"
                    dangerouslySetInnerHTML={{
                      __html: data.trainingSectionVideoEmbed,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="banner"
          style={{
            backgroundColor: data.reviewsBanner.backgroundColor || 'gray',
          }}
        >
          <div className="bannerScroll">
            <div className="bannerScrollTitle">
              <div
                style={{
                  animationDuration: `${data.reviewsBanner.animationDuration}s`,
                }}
              >
                {renderTextContainers()}
              </div>
            </div>
          </div>
        </div>
        <div className="mainInfoContainer">
          <section className="shopAllSection">
            <div className="shopAllContentContainer">
              <h2>{data.shopAllSectionTitle}</h2>
              <p className="shopAllContentParagraph">
                {data.shopAllSectionParagraph}
              </p>
              <a>
                <button>SHOP ALL</button>
              </a>
            </div>
            <div className="shopAllImageContainer">
              <img src={data.shopAllSectionImageUrl} alt="Dog Photo" />
            </div>
          </section>
          <section className="reviewSection">
            <h2>{data.reviewsSectionTitle}</h2>
            <p className="reviewSubtitle">{data.reviewsSectionSubtitle}</p>
            <div className="reviewContainer">
              <div className="review">
                <img
                  className="reviewerImage"
                  src={data.review1ImageUrl}
                  alt="Review Image 1"
                />
                <img
                  className="ratingStars"
                  src="https://pupford.b-cdn.net/assets%2Fpages%2Fnew-home%2F1659120651728-rating-stars.png?alt=media&token=75d27f72-bf52-4da3-8f5b-0a1ea42a7018"
                  alt="5 Stars"
                />
                <p>{data.review1Text}</p>
                <p>{data.review1Name}</p>
              </div>
              <div className="review">
                <img
                  className="reviewerImage"
                  src={data.review2ImageUrl}
                  alt="Review Image 2"
                />
                <img
                  className="ratingStars"
                  src="https://pupford.b-cdn.net/assets%2Fpages%2Fnew-home%2F1659120651728-rating-stars.png?alt=media&token=75d27f72-bf52-4da3-8f5b-0a1ea42a7018"
                  alt="5 Stars"
                />
                <p>{data.review2Text}</p>
                <p>{data.review2Name}</p>
              </div>
              <div className="review">
                <img
                  className="reviewerImage"
                  src={data.review3ImageUrl}
                  alt="Review Image 3"
                />
                <img
                  className="ratingStars"
                  src="https://pupford.b-cdn.net/assets%2Fpages%2Fnew-home%2F1659120651728-rating-stars.png?alt=media&token=75d27f72-bf52-4da3-8f5b-0a1ea42a7018"
                  alt="5 Stars"
                />
                <p>{data.review3Text}</p>
                <p>{data.review3Name}</p>
              </div>
            </div>
          </section>
          <section className="instagramSection">
            <h2>Join our Community</h2>
            <p>Follow us on Instagram</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePagePreview;
