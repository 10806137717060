import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const cloneDeep = require('lodash.clonedeep');
const uniqid = require('uniqid');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function ProductEdit(props) {
  const [editProduct, setEditProduct] = useState(props.product);
  const [tabValue, setTabValue] = useState(0);
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(false);
  const [showDetailsCopyModal, setShowDetailsCopyModal] = useState(false);
  const [copyProductIndex, setCopyProductIndex] = useState(0);

  useEffect(() => {
    let variationsEnabled = false;

    for (let i = 0; i < editProduct.meta_data.length; i++) {
      if (editProduct.meta_data[i].key === '_satt_data' && editProduct.meta_data[i].value && !Array.isArray(editProduct.meta_data[i].value.subscription_schemes)) {
        variationsEnabled = true;
      }
    }

    setSubscriptionEnabled(variationsEnabled);
  }, []);

  const handleProductSectionChange = event => {
    const product = cloneDeep(editProduct);
    product.section = event.target.value;
    setEditProduct(product);
  };

  const handleChange = name => event => {
    setEditProduct({
      ...editProduct,
      [name]: event.target.value
    });
  };

  const addSection = () => {
    const product = cloneDeep(editProduct);
    product.details.push({ sections: [] });
    setEditProduct(product);
  };

  const removeSection = (i) => {
    const product = cloneDeep(editProduct);
    product.details.splice(i, 1);
    setEditProduct(product);
  };

  const removeSectionItem = (i, j) => {
    const product = cloneDeep(editProduct);
    product.details[i].sections.splice(j, 1);
    setEditProduct(product);
  };

  const addDetail = (i, type) => {
    const product = cloneDeep(editProduct);
    const details = {
      type,
      value: '',
    };

    if (type === 'image') {
      details.marginBottom = 0;
      details.marginTop = 0;
      details.width = 0;
    };

    product.details[i].sections.push(details);
    setEditProduct(product);
  };

  const handleSectionChange = (i, j) => event => {
    const product = cloneDeep(editProduct);
    product.details[i].sections[j].value = event.target.value;
    setEditProduct(product);
  };

  const handleSectionImageStyleChange = (i, j, type) => event => {
    const product = cloneDeep(editProduct);
    product.details[i].sections[j][type] = event.target.value;
    setEditProduct(product);
  };

  const handleImageChange = (type, image, index) => event => {
    image = cloneDeep(image);
    const product = cloneDeep(editProduct);

    image[type] = event.target.value;

    product.images[index] = image;
    setEditProduct(product);
  };

  const addImage = () => {
    const product = cloneDeep(editProduct);
    const image = {
      id: uniqid(),
      name: '',
      src: '',
    };

    product.images.push(image);
    setEditProduct(product);
  };

  const removeImage = (i) => {
    const product = cloneDeep(editProduct);

    product.images.splice(i, 1);

    setEditProduct(product);
  };

  const renderSectionInput = (s, i, j) => {
    if (s.type === 'title' || s.type === 'subtitle') {
      return (
        <TextField
          label={s.type.toUpperCase()}
          value={editProduct.details[i].sections[j].value}
          onChange={handleSectionChange(i, j)}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'video') {
      return (
        <TextField
          label={s.type.toUpperCase() + ' URL'}
          value={editProduct.details[i].sections[j].value}
          onChange={handleSectionChange(i, j)}
          margin="normal"
          variant="outlined"
          className="day-text-field"
        />
      );
    } else if (s.type === 'image') {
      return (
        <div>
          <TextField
            label={s.type.toUpperCase() + ' URL'}
            value={editProduct.details[i].sections[j].value}
            onChange={handleSectionChange(i, j)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label={'Width (percentage of screen width)'}
            value={editProduct.details[i].sections[j].width}
            onChange={handleSectionImageStyleChange(i, j, 'width')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%'}}
          />
          <TextField
            label={'Margin Top'}
            value={editProduct.details[i].sections[j].marginTop}
            onChange={handleSectionImageStyleChange(i, j, 'marginTop')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%', marginLeft: '2%'}}
          />
          <TextField
            label={'Margin Bottom'}
            value={editProduct.details[i].sections[j].marginBottom}
            onChange={handleSectionImageStyleChange(i, j, 'marginBottom')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            type="number"
            style={{width: '32%', marginLeft: '2%'}}
          />
        </div>
      );
    } else if (s.type === 'paragraph') {
      return (
        <TextField
          label={s.type.toUpperCase()}
          value={editProduct.details[i].sections[j].value}
          onChange={handleSectionChange(i, j)}
          margin="normal"
          variant="outlined"
          className="day-text-field"
          multiline
          rows="4"
        />
      );
    }
  };

  const moveItemUp = (i, j) => {
    const product = cloneDeep(editProduct);
    const itemToMoveUp = product.details[i].sections[j];
    const itemToMoveDown = product.details[i].sections[j - 1];
    product.details[i].sections[j] = itemToMoveDown;
    product.details[i].sections[j - 1] = itemToMoveUp;
    setEditProduct(product);
  };

  const moveItemDown = (i, j) => {
    const product = cloneDeep(editProduct);
    const itemToMoveDown = product.details[i].sections[j];
    const itemToMoveUp = product.details[i].sections[j + 1];
    product.details[i].sections[j] = itemToMoveUp;
    product.details[i].sections[j + 1] = itemToMoveDown;
    setEditProduct(product);
  };

  const renderUpArrow = (i, j) => {
    if (j !== 0) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemUp(i, j) }} aria-label="Delete">
          <ArrowUpwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const renderDownArrow = (i, j, last) => {
    if (j !== last) {
      return (
        <IconButton edge="start" color="inherit" onClick={() => { moveItemDown(i, j) }} aria-label="Delete">
          <ArrowDownwardIcon />
        </IconButton>
      );
    } else {
      return <div style={{width: '53px'}}></div>
    }
  };

  const handleVariationPriceChange = (type, i) => event => {
    const product = cloneDeep(editProduct);

    product.variations[i][type] = event.target.value;

    if (product.variations[i].sale_price) {
      product.variations[i].price = product.variations[i].sale_price;
    } else {
      product.variations[i].price = product.variations[i].regular_price;
    }

    setEditProduct(product);
  };

  const handleProductStockStatusChange = event => {
    const product = cloneDeep(editProduct);
    product.stock_status = event.target.value;
    setEditProduct(product);
  };

  const handleProductVariationStockStatusChange = (event, i) => {
    const product = cloneDeep(editProduct);
    product.variations[i].stock_status = event.target.value;
    setEditProduct(product);
  };

  const renderVariations = () => {
    if (!editProduct || !editProduct.variations || !editProduct.variations.length) {
      return <div>This product does not have any variations.</div>
    }

    return (editProduct.variations.map((p, i) => {
      let name = '';
      if (p.attributes && p.attributes[0]) {
        name = `${p.attributes[0].option}`;
        if (p.attributes.length === 2) {
          name += ` - ${p.attributes[1].option}`;
        }
      }
      return (
        <div key={`product-variation-${i}`}>
          <h4>{name}</h4>
          <FormControl style={{ width: '250px', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel id="product-variation-stock">
              Stock Status
            </InputLabel>
            <Select
              value={editProduct.variations[i].stock_status}
              onChange={(e) => { handleProductVariationStockStatusChange(e, i) } }
            >
              <MenuItem value={'instock'}>In Stock</MenuItem>
              <MenuItem value={'outofstock'}>Out of Stock</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Regular Price"
            value={editProduct.variations[i].regular_price}
            onChange={handleVariationPriceChange('regular_price', i)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />
          <TextField
            label="Sale Price"
            value={editProduct.variations[i].sale_price}
            onChange={handleVariationPriceChange('sale_price', i)}
            margin="normal"
            variant="outlined"
            className="day-text-field"
          />

          {!subscriptionEnabled ? null :
            <div>
              <div style={{marginBottom: '10px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editProduct.variations[i].overrideSubscriptionPrice}
                      onChange={(e) => {
                        const variation = {
                          ...editProduct.variations[i],
                          overrideSubscriptionPrice: e.target.checked,
                        };

                        const variations = [ ...editProduct.variations ];
                        variations[i] = variation;

                        setEditProduct({
                          ...editProduct,
                          variations,
                        });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'subscription checkbox' }}
                    />
                  }
                  label="Override Subscription Price"
                />
              </div>
              {editProduct.variations[i].overrideSubscriptionPrice ? <TextField
                label="Subscription Price Override"
                value={editProduct.variations[i].subscriptionPriceOverride}
                type="number"
                onChange={(e) => {
                  const variation = {
                    ...editProduct.variations[i],
                    subscriptionPriceOverride: e.target.value,
                  };

                  const variations = [ ...editProduct.variations ];
                  variations[i] = variation;

                  setEditProduct({
                    ...editProduct,
                    variations,
                  });
                }}
                margin="normal"
                variant="outlined"
                className="day-text-field"
              /> : null}
            </div>
          }
        </div>
      );
    }));
  };

  const handlePriceChange = (type) => event => {
    const product = cloneDeep(editProduct);

    product[type] = event.target.value;

    if (product.sale_price) {
      product.price = product.sale_price;
    } else {
      product.price = product.regular_price;
    }

    setEditProduct(product);
  };

  const renderNonVariationProductPrice = () => {
    if (!editProduct.variations.length) {
      return (
        <div style={{marginTop: '-15px'}}>
          <div style={{width: '100%'}}>
            <FormControl style={{ width: '250px', marginTop: '15px', marginBottom: '30px' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Stock Status
              </InputLabel>
              <Select
                value={editProduct.stock_status}
                onChange={handleProductStockStatusChange}
              >
                <MenuItem value={'instock'}>In Stock</MenuItem>
                <MenuItem value={'outofstock'}>Out of Stock</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Regular Price"
            value={editProduct.regular_price}
            onChange={handlePriceChange('regular_price')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            style={{width: '49%', marginRight: '2%'}}
          />
          <TextField
            label="Sale Price"
            value={editProduct.sale_price}
            onChange={handlePriceChange('sale_price')}
            margin="normal"
            variant="outlined"
            className="day-text-field"
            style={{width: '49%'}}
          />

          {!subscriptionEnabled ? null :
            <div>
              <div style={{marginBottom: '10px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editProduct.overrideSubscriptionPrice}
                      onChange={(e) => {
                        setEditProduct({
                          ...editProduct,
                          overrideSubscriptionPrice: e.target.checked,
                        });
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'subscription checkbox' }}
                    />
                  }
                  label="Override Subscription Price"
                />
              </div>
              {editProduct.overrideSubscriptionPrice ? <TextField
                label="Subscription Price Override"
                value={editProduct.subscriptionPriceOverride}
                type="number"
                onChange={(e) => {
                  setEditProduct({
                    ...editProduct,
                    subscriptionPriceOverride: e.target.value,
                  });
                }}
                margin="normal"
                variant="outlined"
                className="day-text-field"
              /> : null}
            </div>
          }
        </div>
      );
    }
  };

  const handleSubscriptionChange = event => {
    const product = cloneDeep(editProduct);
    const checked = event.target.checked;

    for (let i = 0; i < product.meta_data.length; i++) {
      if (product.meta_data[i].key === '_satt_data' && product.meta_data[i].value && product.meta_data[i].value.subscription_schemes) {
        if (checked) {
          product.meta_data[i].value.subscription_schemes = {
            '1_month': {},
            '2_month': {},
            '2_week': {},
            '6_week': {},
          };
        } else {
          product.meta_data[i].value.subscription_schemes = [];
        }
      }
    }

    product.variations.forEach(variation => {
      for (let i = 0; i < variation.meta_data.length; i++) {
        if (variation.meta_data[i].key === '_satt_data' && variation.meta_data[i].value && variation.meta_data[i].value.subscription_schemes) {
          if (checked) {
            variation.meta_data[i].value.subscription_schemes = {
              '1_month': {},
              '2_month': {},
              '2_week': {},
              '6_week': {},
            };
          } else {
            variation.meta_data[i].value.subscription_schemes = [];
          }
        }
      }
    });

    setEditProduct(product);
    setSubscriptionEnabled(checked);
  };

  const copyProductDetails = () => {
    const copyDetails = cloneDeep(props.products[copyProductIndex].details);
    const product = cloneDeep(editProduct);

    product.details = copyDetails;

    setEditProduct(product);
    setShowDetailsCopyModal(false);
  };

  return (
    <div>
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => { props.closeModal() }} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{
            marginLeft: '10px',
            flex: 1,
            color: 'white'
          }}>
            {editProduct.name}
          </Typography>
          <Button color="inherit" onClick={() => { props.saveProduct(editProduct) }}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        <Card className="drag-card" style={{marginTop: '50px', display: 'inline-block', marginLeft: '20px'}}>
          <CardContent>
            <TextField
              label="Name"
              value={editProduct.name}
              onChange={handleChange('name')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />

            <TextField
              label="Product URL"
              value={editProduct.url}
              onChange={handleChange('url')}
              margin="normal"
              variant="outlined"
              className="day-text-field"
            />

            <FormControl style={{ width: '250px', marginTop: '15px', marginBottom: '30px' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Product Section
              </InputLabel>
              <Select
                value={editProduct.section}
                onChange={handleProductSectionChange}
              >
                <MenuItem value={'unassigned'}>Unassigned</MenuItem>
                <MenuItem value={'train'}>Train</MenuItem>
                <MenuItem value={'engage'}>Engage</MenuItem>
                <MenuItem value={'reward'}>Reward</MenuItem>
                <MenuItem value={'health'}>Health</MenuItem>
                <MenuItem value={'packs'}>Packs</MenuItem>
              </Select>
            </FormControl>

            {renderNonVariationProductPrice()}

            <div style={{marginBottom: '10px'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subscriptionEnabled}
                    onChange={handleSubscriptionChange}
                    color="primary"
                    inputProps={{ 'aria-label': 'subscription checkbox' }}
                  />
                }
                label="Subscriptions Enabled"
              />
            </div>

            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => { setTabValue(newValue) }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="product tabs"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Images" {...a11yProps(1)} />
                <Tab label="Variations" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              index={tabValue}
              onChangeIndex={(index) => { setTabValue(index) }}
            >
              <div style={{marginTop: '30px'}} value={tabValue} index={0}>
                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addSection() }}
                >
                  Add Section
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { setShowDetailsCopyModal(true) }}
                >
                  Copy From Another Product
                </Button>

                {editProduct.details.map((d, i) => {
                  return (
                    <div key={`edit-section-${i}`} style={{marginTop: '25px'}}>
                      <hr/>
                      <h3>Section {i + 1}</h3>
                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { addDetail(i, 'title') }}
                      >
                        Add Title
                      </Button>

                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { addDetail(i, 'subtitle') }}
                      >
                        Add Subtitle
                      </Button>

                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { addDetail(i, 'paragraph') }}
                      >
                        Add Paragraph
                      </Button>

                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { addDetail(i, 'image') }}
                      >
                        Add Image
                      </Button>

                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { addDetail(i, 'video') }}
                      >
                        Add Video
                      </Button>

                      <Button 
                        variant="contained"
                        color="secondary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { removeSection(i) }}
                      >
                        Remove Section
                      </Button>

                      <List>
                        {editProduct.details[i].sections.map((s, j) => {
                          return (
                            <ListItem key={`edit-section-${i}-${j}`} style={{borderTopWidth: '1px', borderTopColor: '#bbb', borderTopStyle: 'solid'}}>
                              {renderSectionInput(s, i, j)}

                              {renderUpArrow(i, j)}

                              {renderDownArrow(i, j, editProduct.details[i].sections.length - 1)}

                              <IconButton edge="start" color="inherit" onClick={() => { removeSectionItem(i, j) }} aria-label="Delete">
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  );
                })}
              </div>
              <div style={{marginTop: '30px'}} value={tabValue} index={1}>

                <Button 
                  variant="contained"
                  color="primary"
                  style={{marginRight: '10px', marginBottom: '5px'}}
                  onClick={() => { addImage() }}
                >
                  Add Image
                </Button>

                {editProduct.images.map((image, i) => {
                  return (
                    <div key={`product-image-${i}`}>

                      <h3>Image {i + 1}</h3>

                      <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => { removeImage(i) }}
                      >
                        Remove Image
                      </Button>

                      <TextField
                        label="Image URL"
                        value={editProduct.images[i].src}
                        onChange={handleImageChange('src', image, i)}
                        margin="normal"
                        variant="outlined"
                        className="day-text-field"
                      />
                      <TextField
                        label="Name"
                        value={editProduct.images[i].name}
                        onChange={handleImageChange('name', image, i)}
                        margin="normal"
                        variant="outlined"
                        className="day-text-field"
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{marginTop: '30px'}} value={tabValue} index={2}>
                {renderVariations()}
              </div>
            </SwipeableViews>

          </CardContent>
        </Card>
      </div>

      <Dialog open={showDetailsCopyModal} onClose={() => { setShowDetailsCopyModal(false) }} TransitionComponent={Transition}>
        <DialogTitle id="form-dialog-title">Copy Product Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a product you would like to copy details from. Notice, this will overwrite any existing details this product currently has.
          </DialogContentText>
          <FormControl style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
            <InputLabel>
              Product
            </InputLabel>
            <Select
              value={copyProductIndex}
              onChange={(e) => { setCopyProductIndex(e.target.value); }}
            >
              {props.products.map((p, i) => {
                return (
                  <MenuItem
                    value={i}
                    key={`copy-products-${i}`}
                  >
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            style={{marginRight: '10px'}}
            variant="contained"
            color="primary"
            onClick={copyProductDetails}
          >Copy</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => { setShowDetailsCopyModal(false) }}
          >cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductEdit;
