import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';

import './newSubscriptions.scss';
import { config } from '../../../config';
import NewSubscriptionHistory from './newSubscriptionHistory/newSubscriptionHistory';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const endYesterday = new Date();
endYesterday.setDate(endYesterday.getDate() - 1);
endYesterday.setHours(23, 59, 59, 999);

function NewSubscriptions({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async (newStart, newEnd) => {
    const end = newEnd || endYesterday;
    end.setHours(23, 59, 59, 999);

    const start = newStart || new Date(end.getTime() - (7 * 24 * 60 * 60 * 1000));
    start.setHours(0, 0, 0, 0);

    setStartDate(start);
    setEndDate(end);
    setLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await axios(`${config.gateway}/reports-service/v1/subscriptions/new?start=${moment(start).valueOf()}&end=${moment(end).valueOf()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      let reports = response.data.map(data => {
        return {
          ...data,
          created: moment(data.created).valueOf(),
        };
      });

      reports = reports.sort((a, b) => {
        return b.created - a.created;
      });

      setReports(reports);
      setLoading(false);
    } catch (e) {
      console.log(e.toString());
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred retrieving subscriptions. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="NewSubscriptions">
      {renderLoading()}
      <Card>
        <Toolbar>
          <Typography variant="h6">
            New Subscriptions
          </Typography>
        </Toolbar>

        <Divider/>

        <div className="content">
          <div className="date-picker-row">
            <div className="date-picker-container">
              <Typography>
                <small>Start</small>
              </Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  const start = new Date(date);
                  start.setHours(0, 0, 0, 0);
                  setStartDate(start);
                }}
                maxDate={endYesterday}
              />
            </div>
            <div className="date-divider" style={{marginTop: 14}}>-</div>
            <div className="date-picker-container">
              <Typography>
                <small>End</small>
              </Typography>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  const end = new Date(date);
                  end.setHours(23, 59, 59, 999);
                  setEndDate(end);
                }}
                maxDate={endYesterday}
              />
            </div>

            <div className="date-picker-submit-button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchSubscriptions(startDate, endDate)}
              >
                Submit
              </Button>
            </div>
          </div>

          <NewSubscriptionHistory reports={reports} />
        </div>
      </Card>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewSubscriptions;
