import React from 'react';
import './guarantee-section.scss';

export const GuaranteeSection = ({ data }) => {
  const { title, content, backgroundColor, buttonText, imageUrl } = data;

  return (
    <section className="GuaranteeContainer" style={{ backgroundColor }}>
      <div className="contentWrapper">
        <h3 className="title">{title}</h3>
        <p className="content">{content}</p>
        <button className="button">{buttonText}</button>
      </div>

      <img className="image" alt={imageUrl} src={imageUrl} />
    </section>
  );
};
