import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Card,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  List,
  Divider,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import './blog.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pageCount = 10;

function Blog(props) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [posts, setPosts] = useState([]);
  const [addNewPost, setAddNewPost] = useState(false);
  const [addModalTitle, setAddModalTitle] = useState('Add New');
  const [newPost, setNewPost] = useState({
    title: '',
    description: '',
    path: '',
    editorData: '',
    live: false,
    created: Date.now(),
    updated: Date.now(),
    lastUpdatedBy: '',
    tags: [],
    categories: [],
    featuredImage: '',
    sidebar: '',
  });
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [blogData, setBlogData] = useState({});
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const db = firebase.firestore();

    try {
      const dataSnapshot = await db.collection('blog-posts').doc('data').get();
      const querySnapshot = await db.collection('blog-posts').orderBy('created', 'desc').limit(pageCount).get();

      const blogsData = dataSnapshot.data();
      const blogPages = Math.ceil(blogsData.count / 10);
      const blogPosts = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setNumberOfPages(blogPages);
      setBlogData(blogsData);
      setPosts(blogPosts);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setModalTitle('Error:');
      setModalText('An error occurred fetching page content. Refresh the page and try again.');
    }

    setLoading(false);
  };

  const saveNewPost = async () => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      const existingPageQuerySnapshot = await db.collection('pages').where('path', '==', newPost.path).get();
      const existingBlogQuerySnapshot = await db.collection('blog-posts').where('path', '==', newPost.path).get();
      const existingAcademyPagesQuerySnapshot = await db.collection('academy-plus-landing-pages').where('path', '==', newPost.path).get();

      if (existingPageQuerySnapshot.docs.length || existingBlogQuerySnapshot.docs.length || existingAcademyPagesQuerySnapshot.docs.length) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There is an existing page with the same URL path.');
        return;
      }

      const createdPost = await db.collection('blog-posts').add(newPost);
      await db.collection('blog-posts').doc('data').update({
        count: firebase.firestore.FieldValue.increment(1),
      });

      setLoading(false);
      closeAddNewPost();
      props.history.push(`/blog-content/blog-posts/${createdPost.id}`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('An error occurred adding the new post. Please try again.');
    }
  };

  const handlePageChange = async (value) => {
    setLoading(true);
    const db = firebase.firestore();

    try {
      let querySnapshot;
      let newPage = page;

      if (value === 'next') {
        querySnapshot = await db.collection('blog-posts').orderBy('created', 'desc').startAfter(last).limit(pageCount).get();
        newPage += 1;
      } else {
        querySnapshot = await db.collection('blog-posts').orderBy('created', 'desc').endBefore(first).limitToLast(pageCount).get();
        newPage -= 1;
      }

      const mappedPosts = querySnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setPage(newPage);
      setPosts(mappedPosts);
      setFirst(querySnapshot.docs[0]);
      setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving post data. Please try again.');
    }
  };

  const closeAddNewPost = () => {
    setAddNewPost(false);
  };

  const search = async () => {
    setLoading(true);

    try {
      const querySnapshot = await firebase.firestore().collection('blog-posts').where('title', '==', searchText).get();
      const posts = querySnapshot.docs.map(doc => {
        const data = doc.data();

        return {
          ...data,
          id: doc.id,
        };
      });

      setPosts(posts);
      setLoading(false);
      setSearchOpen(false);
      setSearchTerm(searchText);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving post data. Please try again.');
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)', textAlign: 'center'}}>
        <CircularProgress style={{color: '#fff', top: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <div className="Blog">
      {renderLoading()}
      <Card>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            Blog Posts
          </Typography>

          {searchTerm ?
            <div>
              <div style={{marginRight: 15, fontSize: 16, display: 'inline-block', fontWeight: 600}}>
                {searchTerm}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => {
                setSearchTerm('');
                fetchPosts();
              }} aria-label="Delete">
                <CloseIcon />
              </IconButton>
            </div> :
            <div>
              <IconButton edge="start" color="inherit" onClick={(event) => {
                setNewPost({
                  title: '',
                  description: '',
                  path: '',
                  editorData: '',
                  live: false,
                  created: Date.now(),
                  updated: Date.now(),
                  lastUpdatedBy: '',
                  tags: [],
                  categories: [],
                  featuredImage: '',
                  sidebar: '',
                });
                setAddModalTitle('Add New');
                setAddNewPost(true);
              }} aria-label="Add">
                <AddIcon />
              </IconButton>

              <IconButton style={{marginLeft: 10}} edge="start" color="inherit" onClick={() => {
                setSearchText('');
                setSearchOpen(true);
              }} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </div>
          }
        </Toolbar>

        {searchTerm && !posts.length ?
          <div style={{padding: '15px 28px', fontSize: 16, fontWeight: 600}}>
            No results match your search
          </div> : null
        }

        <List>
          {posts.map((p, i) => {
            return (
              <div key={p.id}>
                <Divider />
                <ListItem>
                  <ListItemText primary={p.title} secondary={`ID: ${p.id}`} />

                  <Link edge="start" to={`/blog-content/blog-posts/${p.id}`} style={{color: '#000', textDecoration: 'none', marginRight: 10}}>
                    <IconButton color="inherit" aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </ListItem>
              </div>
            );
          })}
        </List>

        <Divider/>

        {(!numberOfPages || searchTerm) ? null :
          <div className="pagination-container">
            <div>
              <Button disabled={page === 1} variant="contained" size="small" onClick={() => { handlePageChange('back') }} color="primary">
                Back
              </Button>
              <span className="page-text">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
              <Button disabled={page === numberOfPages} variant="contained" size="small" onClick={() => { handlePageChange('next') }} color="primary">
                Next
              </Button>
            </div>
          </div>
        }
      </Card>

      <Dialog open={addNewPost} onClose={closeAddNewPost} TransitionComponent={Transition}>
        <DialogTitle>{addModalTitle} Post</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newPost.title}
            onChange={(e) => {
              setNewPost({
                ...newPost,
                title: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Meta Description"
            value={newPost.description}
            onChange={(e) => {
              setNewPost({
                ...newPost,
                description: e.target.value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />

          <TextField
            label="Page Path"
            helperText="Only letters, numbers and dashes allowed"
            value={newPost.path}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '').toLowerCase();
              setNewPost({
                ...newPost,
                path: value,
              });
            }}
            variant="outlined"
            margin="dense"
            type="text"
            className="day-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={saveNewPost} color="primary" disabled={!newPost.title || !newPost.description || !newPost.path}>
            Submit
          </Button>
          <Button variant="contained" onClick={closeAddNewPost} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={searchOpen} onClose={() => { setSearchOpen(false) }} TransitionComponent={Transition}>
        <DialogTitle>Search Posts</DialogTitle>
        <DialogContent>
          <div style={{width: 560, maxWidth: '100%'}}>
            <TextField
              label="Search Text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              margin="dense"
              variant="outlined"
              type="text"
              className="day-text-field"
              style={{marginTop: 15, marginBottom: 15}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!searchText} onClick={search} color="primary">
            Search
          </Button>
          <Button variant="contained" onClick={() => { setSearchOpen(false) }} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!modalText} onClose={() => { setModalText('') }} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setModalText('') }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Blog;
